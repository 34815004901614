import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadResultAssociatedUsers(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async checkAssociatedUsers(state, item){
            let request =  API_URL + 'permissions/search';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
            let params = new URLSearchParams(formData);
            await axios({
                method: "get",
                url: request,
                params: params,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200') {
                        state.commit("loadResultAssociatedUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};