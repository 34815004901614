<template>
    <div v-show="haveData" style="padding-bottom: 12px;">
        <div class="gh-content-global">
            <div v-for="(item,index) in CalendarGroups.group_array" :key="index" style="margin-bottom: 10px;" @click="refreshView(item.id)">
                <GhDropDownField
                    :dataBlock="{
                        id: 'drop_field_professional_' + item.id,
                        title: item.name,
                    }"
                >
                    <template v-slot:content>
                        <component :ref="'ref_schedule_'+item.id" v-bind:is="calendar_group" :dataBlock="{idTab: dataBlock.id,id:item.id,name:item.name}"/>
                    </template>
                </GhDropDownField>
            </div>
        </div>
    </div>
</template>

<script>
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import calendar_group from "@/view/calendar/group/views/group_calendar.vue";
    import {mapState} from "vuex";
    export default {
        name: 'list_group',
        props:['dataBlock'],
        components: {
            GhDropDownField,
        },
        computed:{
            ...mapState(['CalendarGroups']),
        },
        data(){
            return{
                haveData: false,
                calendar_group: calendar_group,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getGroup', '', {root: true});
            this.haveData = true;
        },
        methods:{
            refreshView(id){
                this.$refs['ref_schedule_'+id][0].refresh++;
                var that = this;
                setTimeout(function(){
                    that.$refs['ref_schedule_'+id][0].resizeSchedule();
                },100);
            }
        }
    }
</script>