<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhNoDropSelector :datablock="{id: 'section_clinic_data',title: $t('clinic_data')}">
            <template v-slot:content>
                <component v-bind:is="view_clinic_data" :dataBlock="{type: view}"/>
            </template>
            <template v-slot:actions v-if="view">
                <GhAction
                    :dataction="{
                        id: 'edit_clinic_data',
                        text: $t('edit'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="changeState"
                />
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;" v-if="!view">
            <GhButton
                :datainput="{
                    id: 'btn_save_clinic_data',
                    text: $t('accept'),
                }"
                type="submit"
                @click="modifyClinic"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return_clinic_data',
                    text: $t('close'),
                }"
                @click="changeState"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import view_clinic_data from "@/view/configuration/clinic_data/views/view_clinic_data.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_clinic_data",
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigurationClinicData']),
        },
        data(){
            return {
                view: true,
                haveData: false,
                view_clinic_data: view_clinic_data,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedDepartments', '', {root:true});
            await this.$store.dispatch('getFeedSpecialties', '', {root:true});
            await this.$store.dispatch('getFeedEquipment', '', {root:true});
            await this.$store.dispatch('getConfigurationClinicData', '', {root:true});
            this.haveData = true;
        },
        methods: {
            changeState(){
                this.view = !this.view;
            },
            async modifyClinic(){
                if(this.Global.checkRequired('form_clinic_data') == 'OK') {
                    const formData = new FormData(document.getElementById('form_clinic_data'));
                    this.$store.dispatch('setConfigurationClinicData', {formData: [...formData]},{root:true});
                    this.changeState();
                }
            }
        }
    }
</script>
