<template>
    <div id="option_to_select_table" v-if="haveData">
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_' + $parent.$parent.type_item + '_table_selected',
                        class: 'T15_b',
                        subtitle: $t('select_table') + ':',
                        text: $t('load_table') + '*',
                        label: $t('load_table'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    }"
                    :datainput="{
                        id: $parent.$parent.type_item + '_table_selected',
                        name: $parent.$parent.type_item + '[table][selected]'
                    }"
                    :feed="getFeeds.tables.feeds"
                    :selected_input="table"
                    ref="RefTableSelected"
                    @accept="getSelectedTable"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('load_table') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{table !== [] ? Object.values(table).join() : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="showStructure" :key="'A' + structureRefresh">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_' + $parent.$parent.type_item + '_structure_selected',
                        class: 'T15_b',
                        subtitle: $t('select_columns') + ':',
                        text: $t('load_columns') + '*',
                        label: $t('load_columns'),
                        type: 'checkbox',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    }"
                    :datainput="{
                        id: $parent.$parent.type_item + '_structure_selected',
                        name: $parent.$parent.type_item + '[table][structure][]'
                    }"
                    :disabled_input="structure.disabled"
                    :feed="getFeeds.structure"
                    :selected_input="structure.selected"
                    ref="RefTableStructure"
                    @accept="getSelectedStructure"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('load_columns') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{structure !== [] ? Object.values(structure.selected).join() : ''}}</label>
            </template>
        </div>
        <template v-if="actualData.columns_by_table.id !== undefined">
            <div class="gh-row-content" v-for="(item, index, number) in actualData.columns_by_table" :key="'B' + number">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('column') + ' ' + (number + 1) + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 756px; max-width: 756px;">{{item}}</label>
                <template v-if="$root._route.params.view !== 'true' && index !== 'id' && index !== 'name'">
                    <GhAction
                        style="margin-left: 10px; width: max-content; white-space: nowrap"
                        :dataction="{
                            id: 'action_delete_structure_' + index,
                            text: $t('delete'),
                            icon: require('../../../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="removeColumn('columns_by_table', index)"
                    />
                </template>
            </div>
            <div :key="'C' + length + lengthCol">
                <div class="gh-row-content" v-for="(item, index) in actualData.new_columns" :key="'D' + index" style="height: 20px;">
                    <component v-bind:is="component" :dataBlock="{item: item, index: index, type: 'table', length: length}" :key="'E' + index" :ref="'RefLineOfTable' + index"/>
                </div>
            </div>
            <template v-if="$root._route.params.view !== 'true'">
                <GhAction
                    style="margin-left: 160px;"
                    :dataction="{
                        id: 'action_add_column',
                        text: $t('add_column'),
                        icon: require('../../../../../assets/svg/add_plus.svg')
                    }"
                    @click="addColumn"
                />
                <br>
            </template>
            <div class="gh-row-content">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('table_preview') + ':'}}</label>
            </div>
            <div class="gh-row-content">
                <GhTableCommon style="margin-top: 10px; margin-bottom: 10px;"
                    :extratable="{
                        id: $root._route.params.view ? 'view_table_structure_selected' : 'table_structure_selected',
                    }"
                    :header="structure.column"
                    :data="structure.row"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import include_line_of_table from "@/view/configuration/items/views/partials/includes/include_line_of_table.vue";

    export default {
        name: 'view_table',
        components: {
            GhAction,
            GhTableCommon,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return{
                haveData: false,
                table: [],
                showStructure: false,
                structure: {
                    selected: {id: 'id', name: 'name'},
                    disabled: {id: 'id', name: 'name'},
                    column: [],
                    row: []
                },
                updated: this.$parent.$parent.updated,
                component: include_line_of_table,
                actualData: {
                    columns_by_table: [],
                    new_columns: []
                },
                newColumn: {
                    column: '',
                    type: [],
                    length: ''
                },
                length: 0,
                lengthCol: 0,
                structureRefresh: 0
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.table !== undefined){
                if(this.$parent.$parent.stateData.data.table.selected !== undefined) {
                    this.table = this.$parent.$parent.stateData.data.table.selected;
                    this.showStructure = true;
                }

                if(this.$parent.$parent.stateData.data.table.structure !== undefined){
                    this.structure.selected = this.$parent.$parent.stateData.data.table.structure;
                    this.actualData.columns_by_table = this.structure.selected;
                    this.length = Object.keys(this.actualData.columns_by_table).length;
                }

                if(this.$parent.$parent.stateData.data.table.new_columns !== undefined){
                    this.actualData.new_columns = this.$parent.$parent.stateData.data.table.new_columns;
                }

                if(this.$parent.$parent.stateData.data.table.preview !== undefined){
                    this.structure.column = this.$parent.$parent.stateData.data.table.preview;
                    this.structure.row = [{}];
                }
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedTables', '', {root:true});
                await this.$store.dispatch('getFeedTypeDDBB', '', {root:true});
            }
            this.haveData = true;
        },
        updated() {
            if(!this.updated) {
                this.openTables();
            }

            this.updated = true;
        },
        methods: {
            openTables(){
                this.Global.openPopUp('label_' + this.$parent.$parent.type_item + '_table_selected_data_selector_popup');
                this.$refs.RefTableSelected.StatusPopup();
            },
            async getSelectedTable(){
                var selected = Object.keys(this.$refs.RefTableSelected.checkdata)[0];

                if(this.table !== undefined && Object.keys(this.table)[0] !== selected){
                    this.actualData = {
                        columns_by_table: [],
                        new_columns: []
                    };

                    this.structure = {
                        selected: {id: this.$t('id'), name: this.$t('name')},
                        disabled: {id: this.$t('id'), name: this.$t('name')},
                        column: [],
                        row: []
                    };
                }

                await this.$store.dispatch('getStructureSelected', {call: this.getFeeds.tables.call[selected]}, {root:true});
                this.showStructure = false;
                this.showStructure = true;
                this.structureRefresh++
                var that = this;
                setTimeout(function(){
                    that.Global.openPopUp('label_' + that.$parent.$parent.type_item + '_structure_selected_data_selector_popup');
                    that.$refs.RefTableStructure.StatusPopup();
                },30);
            },
            getSelectedStructure(){
                this.actualData.columns_by_table = this.$refs.RefTableStructure.checkdata;

                this.structure.column = [];
                this.structure.row = [{}];

                Object.entries(this.actualData.columns_by_table).map((item) => {
                    var style = '';

                    if(item[0] == 'id' || item[0] == 'name'){
                        style = item[0] === 'id' ? 'min-width: 100px; width: 100px;' : 'min-width: 180px; width: 180px;'
                    }
                    var params = {
                        text: item[1],
                        field: item[0],
                        style: style
                    }

                    this.structure.column.push(params);
                });

                if(this.actualData.new_columns !== undefined){
                    this.actualData.new_columns.forEach((item) => {
                        var params = {
                            text: item.column,
                            field: this.transformatorTextToKey(item.column),
                            style: ''
                        }

                        this.structure.column.push(params);
                    });
                }

                this.length = Object.keys(this.actualData.columns_by_table).length;
            },
            checkActualData(type){
                var paramsData = new Array();

                this.actualData.new_columns.forEach((item, key) => {
                    var array = {};
                    if(type !== undefined){
                        array = {
                            'column': this.$refs['RefLineOfTable' + key][0].$children[0].datainput.value,
                            'type': this.$refs['RefLineOfTable' + key][0].$children[1].checkdata,
                            'length': this.$refs['RefLineOfTable' + key][0].$children[2].datainput.value,
                            'disabled': type.disabled,
                            'max': type.max,
                            'min': type.min,
                            'type_input': type.type_input
                        }
                    }else{
                        array = {
                            'column': this.$refs['RefLineOfTable' + key][0].$children[0].datainput.value,
                            'type': this.$refs['RefLineOfTable' + key][0].$children[1].checkdata,
                            'length': this.$refs['RefLineOfTable' + key][0].$children[2].datainput.value,
                            'disabled': item.disabled,
                            'max': item.max,
                            'min': item.min,
                            'type_input': item.type_input
                        }
                    }

                    paramsData.push(array);
                });

                this.actualData.new_columns = [];
                if(paramsData[0] !== undefined) {
                    paramsData.forEach((item) => {
                        this.actualData.new_columns.push(item);
                    });
                }
            },
            removeColumn(type, index){
                var params = new Array();

                this.checkActualData();

                if(type === 'columns_by_table'){
                    Object.entries(this.actualData[type]).map((item) => {
                        if (item[0] === index) {
                            var deleteKey = '';
                            this.structure.column.forEach((col, key) => {
                                if(col.field === index){
                                    deleteKey = key;
                                }
                            });

                            delete this.structure.column[deleteKey];
                            delete this.actualData[type][index];
                            this.structure.selected = this.$refs.RefTableStructure.checkdata;
                            this.structureRefresh++
                        }
                    });

                    this.structure.column.forEach((item) => {
                        params.push(item);
                    });

                    this.structure.column = [];
                    if (params[0] !== undefined) {
                        params.forEach((item) => {
                            this.structure.column.push(item);
                        });
                    }

                    this.length = Object.keys(this.actualData.columns_by_table).length;
                }else {
                    this.actualData[type].forEach((item, key) => {
                        if (key !== index) {
                            var array = {
                                'column': this.$refs['RefLineOfTable' + key][0].$children[0].datainput.value,
                                'type': this.$refs['RefLineOfTable' + key][0].$children[1].checkdata,
                                'length': this.$refs['RefLineOfTable' + key][0].$children[2].datainput.value,
                                'disabled': item.disabled,
                                'max': item.max,
                                'min': item.min,
                                'type_input': item.type_input
                            };
                            params.push(array);
                        }

                        if(key === index){
                            var deleteKey = '';
                            this.structure.column.forEach((col, key) => {
                                if(col.field === this.transformatorTextToKey(this.actualData[type][index].column)){
                                    deleteKey = key;
                                }
                            });

                            delete this.structure.column[deleteKey];
                        }
                    });

                    this.actualData[type] = [];
                    if (params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData[type].push(item);
                        });
                    }

                    var params2 = new Array();
                    this.structure.column.forEach((item) => {
                        params2.push(item);
                    });

                    this.structure.column = [];
                    if (params2[0] !== undefined) {
                        params2.forEach((item) => {
                            this.structure.column.push(item);
                        });
                    }
                }

                this.lengthCol = this.structure.column.length;
            },
            addColumn(){
                this.checkActualData();

                this.actualData.new_columns.push(this.newColumn);

                this.length = Object.keys(this.actualData.columns_by_table).length;

                var paramsColumn = {
                    text: '',
                    field: '',
                    style: ''
                }

                this.structure.column.push(paramsColumn);
            },
            renameColumn(index, refParent, refChildren){
                var nameColumn = this.$refs[refParent][0].$refs[refChildren].datainput.value;
                this.structure.column[index].text = nameColumn;
                this.structure.column[index].field = this.transformatorTextToKey(nameColumn);
            },
            transformatorTextToKey(text){
                var replaces = {'-':'_','ñ':'n','Ñ':'N','á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U','à':'a','è':'e','ì':'i','ò':'o','ù':'u','À':'A','È':'E','Ì':'I','Ò':'O','Ù':'U','ä':'a','ë':'e','ï':'i','ö':'o','ü':'u','Ä':'A','Ë':'E','Ï':'I','Ö':'O','Ü':'U','â':'a','ê':'e','î':'i','ô':'o','û':'u','Â':'A','Ê':'E','Î':'I','Ô':'O','Û':'U','ç':'c','Ç':'C'};
                text = text.split('').map( character => replaces[character] || character).join('').toString();
                text = text.replace(/[^a-zA-Z0-9_ ]/g, "");
                return text.toLowerCase().replace(/ /g,"_");
            }
        }
    }
</script>

<style>
    #label_standard_item_table_selected_selector_label, #label_standard_item_structure_selected_selector_label{
        width: 150px;
        text-align: end;
    }

    #list_table_structure_selected > thead > tr > td{
        resize: horizontal;
    }
    #list_table_structure_selected > thead > tr > td:last-child{
        resize: none;
    }
    #list_table_structure_selected {
        min-width: 950px !important;
    }
</style>