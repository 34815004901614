<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'patients_',
        }"
        :header="header"
        :data="Patients.records_data.length == 0 ? [] : Patients.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_patient_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('edit_patient', {id: itemProp.id}),'patient')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_patient_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removePatient', itemProp.id,{'id': itemProp.code, 'name': itemProp.name}, $t('delete_patient'), $t('preparing_delete_patient'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_new_patient',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('new_patient', {}),'patient')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_patient",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['Patients'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 60%;'},
                    {text: 'gender', field: 'gender', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'date_of_birth', field: 'date_of_birth', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPatients', '', {root: true});
            this.haveData = true;
        }
    }
</script>