import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadTableConfigurationClinicData(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getConfigurationClinicData(state){
            await axios.get(
                API_URL + "configuration/clinic-data"
            ).then(
                response => {
                    state.commit('loadTableConfigurationClinicData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setConfigurationClinicData(state, item){
            let requestLogin = API_URL + "configuration/clinic-data";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.data !== undefined) {
                        state.dispatch('getConfigurationClinicData', '', {root: true});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};