import SectionForms from "@/view/configuration/form_process/forms/section/section_forms.vue";
import CategoryForm from "./category_form/route";
import CreationForm from "./creation_form/route";

const route = [
    {
        path: '/configuration/forms',
        name: 'configuration_forms',
        component: SectionForms,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...CategoryForm,
    ...CreationForm
]

export default routes;