<template>
    <div class="gh-row-content" :key="dataBlock.index">
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('answer') + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: true,
                    id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_answer',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][answer]',
                    style: 'min-width: 300px; width: 300px',
                    value: dataBlock.item.answer,
                    type: 'text',
                }"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('answer') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.answer}}</label>
        </template>
        <template v-if="!$root._route.params.view">
            <GhTreeSelectorBlock
                :dataBlock="{
                    type: 'radio',
                    id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_item',
                    label: $t('item'),
                    title: $t('item'),
                    text: $t('select_item') + ':',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][item]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: false,
                    style: 'margin-left: 30px;margin-top:2px;',
                    popupStyle: 'min-width: 900px;width: 55%;',
                }"
                :selected_input="dataBlock.item.item"
                :items="getFeeds.items"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{dataBlock.item.item[0].text}}</label>
        </template>
        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
        <GhChecks
            :datalabel="{}"
            :datainput="{
                required: false,
                disabled: $root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_associate_to',
                value: 1,
                name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][associate_to]',
                selected: dataBlock.item.associate_to,
            }"
        />
        <template v-if="$root._route.params.view !== 'true'">
            <GhAction
                style="margin-left: 10px; width: max-content; white-space: nowrap"
                :dataction="{
                    id: 'action_delete_' + dataBlock.index,
                    text: $t('delete'),
                    icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
                }"
                @click="$parent.removeAnswer(dataBlock.index)"
            />
        </template>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'include_line_of_multiples',
        props: ['dataBlock'],
        components: {
            GhAction,
            GhChecks,
            GhTreeSelectorBlock,
            GhInputTextField
        },
        data(){
            return {
                refresh: 0
            }
        },
        computed: {
            ...mapState(['getFeeds'])
        },
    }
</script>