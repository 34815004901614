import SupportCategory from "@/view/configuration/support/views/view_category.vue";

const routes = [
    {
        path: '/configuration/support/category',
        name: 'configuration_support_new_category',
        component: SupportCategory,
        props: {id: null, showMenu: false, background: true, otherTitle: 'category'},
        children: [
            {
                path: '/configuration/support/category/:id',
                name: 'configuration_support_edit_category',
                component: SupportCategory,
                props: {id: null, showMenu: false, background: true, otherTitle: 'category'},
            },
            {
                path: '/configuration/support/category/:id/:view',
                name: 'configuration_support_view_category',
                component: SupportCategory,
                props: {id: null, showMenu: false, background: true, otherTitle: 'category'},
            },
        ]
    }
];
export default routes;
