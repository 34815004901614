import Departments from "@/view/configuration/variable/views/view_department.vue";

const routes = [
    {
        path: '/configuration/variable/department',
        name: 'configuration_variable_new_department',
        component: Departments,
        props: {id: null, showMenu: false, background: true, otherTitle: 'department'},
        children: [
            {
                path: '/configuration/variable/department/:id',
                name: 'configuration_variable_edit_department',
                component: Departments,
                props: {id: null, showMenu: false, background: true, otherTitle: 'department'},
            },
            {
                path: '/configuration/variable/department/:id/:view',
                name: 'configuration_variable_view_department',
                component: Departments,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'department'},
            }
        ],
    },

];

export default routes;