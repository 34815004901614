<template>
    <div style="padding-bottom: 12px;">
        <GhNoDropSelector :datablock="{id: 'speciality',title: $t('calendar')}">
            <template v-slot:content>
                <component v-bind:is="list_speciality" :dataBlock="{id: 2}"/>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import list_speciality from "@/view/calendar/speciality/lists/list_speciality.vue";

    export default {
        name: 'section_speciality',
        components: {
            GhNoDropSelector,
        },
        data(){
            return{
                list_speciality:list_speciality
            }
        }
    }
</script>