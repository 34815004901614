<template>
    <div style="padding-bottom: 12px;">
        <GhNoDropSelector :datablock="{id: 'list_department',title: $t('calendar')}">
            <template v-slot:content>
                <component v-bind:is="list_department" :dataBlock="{id: 1}"/>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import list_department from "@/view/calendar/department/lists/list_department.vue";

    export default {
        name: 'section_department',
        components: {
            GhNoDropSelector,
        },
        data(){
            return{
                list_department:list_department
            }
        }
    }
</script>