var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'clinic',
    },"header":_vm.header,"data":_vm.SubmitsClinics.records_data.length == 0 ? [] : _vm.SubmitsClinics.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'clinic'}),'view_submit')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_validate_clinic_' + itemProp.id,
                text: _vm.$t('validate'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','validateClinic', itemProp.id,{'name_of_clinic': itemProp.name_of_clinic, 'business_number': itemProp.business_number}, _vm.$t('validate_clinic'), _vm.$t('you_are_preparing_to_validate_the_following_clinic'), 'validate')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_view_clinic_' + itemProp.id,
                text: _vm.$t('view'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'clinic'}),'view_submit')}}})]}}],null,false,622842333)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }