<template>
    <GhTableCommon style="margin-top: 10px;" v-if="haveData"
        :extratable="{
            id: 'table_category_form',
        }"
        :header="header"
        :data="ConfigurationFormsCategory.list_category_form.length == 0 ? [] : ConfigurationFormsCategory.list_category_form"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_category_form_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_forms_edit_category_form', {id: itemProp.id}),'configuration_forms_category_form')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_category_form_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationCategoryForm', itemProp.id,{ 'name': itemProp.name, 'description': itemProp.description}, $t('delete_category_form'), $t('preparing_delete_category_form'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_category_form',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_forms_new_category_form', {}),'configuration_forms_category_form')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'list_category_form',
        components: {
            GhButton,
            GhAction,
            GhTableCommon
        },
        computed:{
            ...mapState(['ConfigurationFormsCategory']),
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationCategoryForm','',{root:true});
            this.haveData = true;
        }
    }
</script>
