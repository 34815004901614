var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{key:'A' + _vm.refreshAll,attrs:{"id":"option_to_select_selector_feeds"}},[_c('div',{key:'B' + _vm.refreshAll2,staticClass:"gh-row-content"},[(!_vm.$parent.$parent.feed.is_tree)?[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefSelecteds",attrs:{"datablock":{
                        id: 'label_' + _vm.$parent.$parent.type_item + '_selector_selecteds',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_value') + ':',
                        text: _vm.$t('selecteds') + '*',
                        label: _vm.$t('selecteds'),
                        type: _vm.$parent.$parent.feed.type,
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    },"datainput":{
                        id: _vm.$parent.$parent.type_item + '_selector_selecteds',
                        name: _vm.$parent.$parent.type_item + '[selector][selecteds]' + (_vm.$parent.$parent.feed.type === 'checkbox' ? '[]' : '')
                    },"feed":_vm.getFeeds.selected,"selected_input":_vm.selecteds},on:{"accept":_vm.getSelectedValues}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('selecteds') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.selecteds !== [] ? Object.values(_vm.selecteds).join() : ''))])]]:[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{ref:"RefSelecteds",attrs:{"dataBlock":{
                        type: _vm.$parent.$parent.feed.type,
                        id: _vm.$parent.$parent.type_item + '_selector_selecteds',
                        label: _vm.$t('selecteds') + '*',
                        title: _vm.$t('selecteds'),
                        text: _vm.$t('select_value') + ':',
                        name: _vm.$parent.$parent.type_item + '[selector][selecteds]'  + (_vm.$parent.$parent.feed.type === 'checkbox' ? '[]' : ''),
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: true,
                        checkparentdata: false,
                        style: 'margin-left: 0px;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    },"selected_input":_vm.selecteds,"items":_vm.getFeeds.selected},on:{"accept":_vm.getSelectedValues}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('selecteds') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.selecteds !== [] ? _vm.selecteds : ''))])]]],2),(_vm.actualData.length !== 0)?[_c(_vm.component,{key:'C' + _vm.refreshAll2,tag:"component",attrs:{"dataBlock":{items: _vm.actualData}}})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }