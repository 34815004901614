var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
            id: 'configuration_support_flow',
        },"header":_vm.header,"data":_vm.ConfigurationSupportFlow.records_data.length == 0 ? [] : _vm.ConfigurationSupportFlow.records_data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'action_edit_flow_' + itemProp.id,
                    text: _vm.$t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    item_prop: itemProp
                }},on:{"click":function($event){return _vm.changeFlow(itemProp)}}})]}}],null,false,339581665)}):_vm._e(),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'update_config_flow',
            title: _vm.titlePopup,
            type: 'add',
            style: 'width: 50%; min-width:920px;',
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('span',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('select_group') + ':'))])]),_c('form',{attrs:{"id":"form_update_flow"}},[_c('div',{key:_vm.refresh,staticClass:"gh-content-global",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"display":"block","width":"50%"}},_vm._l((_vm.preGroupsArray),function(item,index){return _c('div',{key:index},[_c('GhChecks',{attrs:{"datainput":{
                                    id: 'groups_selection_' + item.id,
                                    value: item.id,
                                    type: _vm.ConfigurationSupportFlow.data.selection_type == 'single' ? 'radio' : 'checkbox',
                                    name: 'groups[]',
                                    style: 'margin-left:48px; cursor:pointer;',
                                    selected: _vm.selectedsGroups.includes(item.id.toString()),
                                },"datalabel":{
                                    style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                                    class: 'T15 input_label',
                                    id: 'label_groups_selection_' + item.id,
                                    text: item.name
                                }}})],1)}),0),_c('div',{staticStyle:{"display":"block","width":"50%"}},_vm._l((_vm.postGroupsArray),function(item,index){return _c('div',{key:index},[_c('GhChecks',{attrs:{"datainput":{
                                    id: 'groups_selection_' + item.id,
                                    value: item.id,
                                    type: _vm.ConfigurationSupportFlow.data.selection_type == 'single' ? 'radio' : 'checkbox',
                                    name: 'groups[]',
                                    style: 'margin-left:48px; cursor:pointer;',
                                    selected: _vm.selectedsGroups.includes(item.id.toString()),
                                },"datalabel":{
                                    style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                                    class: 'T15 input_label',
                                    id: 'label_groups_selection_' + item.id,
                                    text: item.name
                                }}})],1)}),0)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save_flow',
                    text: _vm.$t('accept'),
                }},on:{"click":_vm.saveFlow}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_flow',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.closePopUp( 'update_config_flow')}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }