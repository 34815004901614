<template>
    <div>
        <form id="form_open" v-if="haveData">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">&nbsp;</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('monday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('tuesday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('wednesday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('thursday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('friday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('saturday')}}</label></div>
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('sunday')}}</label></div>
                </div>
            </div>
            <div class="gh-content-global" style="margin-top: -25px;">
                <div class="gh-row-content">
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('hour_start')}}</label></div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_monday',
                                    name: 'open[start][monday]',
                                    hour: ConfigurationCalendarOpen.records_data.start.monday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.monday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.monday.hour + ':' + ConfigurationCalendarOpen.records_data.start.monday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_tuesday',
                                    name: 'open[start][tuesday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.tuesday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.tuesday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.tuesday.hour + ':' + ConfigurationCalendarOpen.records_data.start.tuesday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_wednesday',
                                    name: 'open[start][wednesday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.wednesday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.wednesday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.wednesday.hour + ':' + ConfigurationCalendarOpen.records_data.start.wednesday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_thursday',
                                    name: 'open[start][thursday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.thursday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.thursday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.thursday.hour + ':' + ConfigurationCalendarOpen.records_data.start.thursday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_friday',
                                    name: 'open[start][friday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.friday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.friday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.friday.hour + ':' + ConfigurationCalendarOpen.records_data.start.friday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_saturday',
                                    name: 'open[start][saturday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.saturday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.saturday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.saturday.hour + ':' + ConfigurationCalendarOpen.records_data.start.saturday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_start_sunday',
                                    name: 'open[start][sunday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.start.sunday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.start.sunday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.start.sunday.hour + ':' + ConfigurationCalendarOpen.records_data.start.sunday.minute}}</label>
                        </template>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('hour_end') }}</label></div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_monday',
                                    name: 'open[finish][monday]',
                                    hour: ConfigurationCalendarOpen.records_data.finish.monday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.monday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.monday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.monday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_tuesday',
                                    name: 'open[finish][tuesday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.tuesday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.tuesday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.tuesday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.tuesday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_wednesday',
                                    name: 'open[finish][wednesday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.wednesday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.wednesday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.wednesday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.wednesday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_thursday',
                                    name: 'open[finish][thursday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.thursday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.thursday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.thursday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.thursday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_friday',
                                    name: 'open[finish][friday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.friday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.friday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.friday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.friday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_saturday',
                                    name: 'open[finish][saturday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.saturday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.saturday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.saturday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.saturday.minute}}</label>
                        </template>
                    </div>
                    <div>
                        <template v-if="!dataBlock.type">
                            <GhInputHour
                                :datalabel="{text: ''}"
                                :datainput="{
                                    id: 'open_finish_sunday',
                                    name: 'open[finish][sunday]',
                                    style: 'margin-left: 65px;',
                                    hour: ConfigurationCalendarOpen.records_data.finish.sunday.hour,
                                    minute: ConfigurationCalendarOpen.records_data.finish.sunday.minute
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarOpen.records_data.finish.sunday.hour + ':' + ConfigurationCalendarOpen.records_data.finish.sunday.minute}}</label>
                        </template>
                    </div>
                </div>
            </div>
        </form>
        <div class="centered_footer" style="margin-top: 8px; padding-bottom: 10px;" v-if="!dataBlock.type">
            <GhButton
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept')
                }"
                type="submit"
                @click="setSchedule()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('close')
                }"
                @click="$parent.$parent.changeState()"
            />
        </div>
    </div>
</template>

<script>
    import GhInputHour from "fe-gh-input-hour-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "view_open",
        props: ['dataBlock'],
        components: {
            GhInputHour,
            GhButton
        },
        data(){
            return{
                haveData:false,
            }
        },
        computed:{
            ...mapState(['ConfigurationCalendarOpen'])
        },
        methods:{
            async setSchedule(){
                if(this.Global.checkRequired('form_open') === 'OK') {
                    const formData = new FormData(document.getElementById('form_open'));
                    await this.$store.dispatch('setScheduleData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.$parent.$parent.changeState();
                }
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getConfigurationCalendarOpen', '', {root: true});
            this.haveData = true;
        },
    }
</script>