import Gender from "@/view/configuration/general_data/views/view_gender.vue";

const routes = [
    {
        path: '/configuration/general-data/gender',
        name: 'configuration_general_data_new_gender',
        component: Gender,
        props: {id: null, showMenu: false, background: true, otherTitle: 'gender'},
        children: [
            {
                path: '/configuration/general-data/gender/:id',
                name: 'configuration_general_data_edit_gender',
                component: Gender,
                props: {id: null, showMenu: false, background: true, otherTitle: 'gender'},
            },
            {
                path: '/configuration/general-data/gender/:id/:view',
                name: 'configuration_general_data_view_gender',
                component: Gender,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'gender'},
            }
        ],
    },

];

export default routes;