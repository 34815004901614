var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'view_creation_' + _vm.typeCreation,
            title: _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":'form_creation_' + _vm.typeCreation}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                    text: _vm.$t('id') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: _vm.typeCreation + '_code',
                                    name: _vm.typeCreation + '[code]',
                                    style: 'width: 100px',
                                    value: _vm.code,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('id') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationFormProcess.data_form_process !== undefined ? _vm.ConfigurationFormProcess.data_form_process.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: _vm.typeCreation + '_name',
                                    name: _vm.typeCreation + '[name]',
                                    style: 'width: 300px',
                                    value: _vm.name,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationFormProcess.data_form_process !== undefined ? _vm.ConfigurationFormProcess.data_form_process.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'label_' + _vm.typeCreation + '_category',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_a_category_' + _vm.typeCreation) + ':',
                                    text: _vm.$t('category_' + (_vm.typeCreation == 'process' ? 'process' : 'form')),
                                    label: _vm.$t('category_' + (_vm.typeCreation == 'process' ? 'process' : 'form')),
                                    type: 'radio',
                                    columns: 3,
                                    required: false,
                                    style: '',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                },"datainput":{
                                    id: _vm.typeCreation + '_category',
                                    name: _vm.typeCreation + '[category]'
                                },"feed":_vm.getFeeds.categories,"selected_input":_vm.ConfigurationFormProcess.data_form_process !== undefined && _vm.ConfigurationFormProcess.data_form_process.category !== undefined ? _vm.ConfigurationFormProcess.data_form_process.category : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('category_' + (_vm.typeCreation == 'process' ? 'process' : 'form')) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationFormProcess.data_form_process !== undefined && _vm.ConfigurationFormProcess.data_form_process.category !== undefined ? Object.values(_vm.ConfigurationFormProcess.data_form_process.category) : ''))])]],2),_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.typeCreation === 'process' ? _vm.$t('subprocess_and_forms') : _vm.$t('sections_and_items')))])]),(_vm.actualData.length)?_c(_vm.components.partial_section_subprocess,{tag:"component",attrs:{"actualData":{data: _vm.actualData, type: _vm.typeCreation, refresh: _vm.refreshComponent}}}):_vm._e(),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_' + (_vm.typeCreation === 'process' ? 'add_subprocess' : 'add_section'),
                                text: _vm.typeCreation === 'process' ? _vm.$t('add_subprocess') : _vm.$t('add_section'),
                            }},on:{"click":function($event){return _vm.getSectionSubprocess(true)}}})],1)],1)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view && !this.$root._route.params.validate)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_finish_' + _vm.typeCreation,
                    text: _vm.$t('finish'),
                }},on:{"click":function($event){return _vm.setFormProcess('process')}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save_' + _vm.typeCreation,
                    text: _vm.$t('save'),
                }},on:{"click":function($event){return _vm.setFormProcess('save')}}})]:_vm._e(),(this.$root._route.params.validate)?[_c('GhButton',{attrs:{"datainput":{
                      id: 'btn_validate_' + _vm.typeCreation,
                      text: _vm.$t('validate'),
                  }},on:{"click":function($event){return _vm.setFormProcess('validate')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_back_' + _vm.typeCreation,
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],2),_c(_vm.components.popup_create_section_subprocess,{ref:"RefPopupCreateSectionSubprocess",tag:"component",attrs:{"dataBlock":{type: _vm.typeCreation, data: _vm.dataPopupSectionSubprocess}},on:{"emiter":_vm.setSectionSubprocess}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }