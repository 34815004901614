var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_variable_equipment_maintenance_'
    },"header":_vm.header,"data":_vm.ConfigurationVariableEquipmentMaintenance.records_data.length === 0 ? [] : _vm.ConfigurationVariableEquipmentMaintenance.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){return _vm.viewEquipmentMaintenance(itemProp.id)}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_equipment_maintenance_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg')
            }},on:{"click":function($event){return _vm.editEquipmentMaintenance(itemProp.id)}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_equipment_maintenance_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg')
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationVariableEquipmentMaintenance', itemProp.id,{
                'code': itemProp.code,
                'name': itemProp.name,
                'documents': itemProp.documents,
                'description': itemProp.description
            }, _vm.$t('delete_equipment_maintenance'), _vm.$t('preparing_delete_equipment_maintenance'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_new_equipment_maintenance',
                    text: _vm.$t('add')
                }},on:{"click":function($event){return _vm.addEquipmentMaintenance()}}})],1)]},proxy:true}],null,false,2106253214)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }