<template>
    <div class="gh-content-global" v-if="haveData">
        <div v-for="(item,index) in ConfigurationProcessEnableDisable.list_enable_disable_process" :key="index" style="margin-bottom: 5px;">
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_field_enable_disable_process_' + item.id,
                    title: item.name,
                }"
            >
                <template v-slot:content>
                    <GhTableCommon style="margin-top: 10px;"
                        :extratable="{
                            id: 'table_enable_disable_process',
                        }"
                        :header="header"
                        :data="item.data === undefined ? [] : item.data"
                    >
                        <template v-slot:actions="{itemProp}">
                            <GhAction
                                :dataction="{
                                    id: 'action_copy_enable_disable_process_' + itemProp.id,
                                    text: $t('copy'),
                                    icon: require('../../../../../assets/svg/doc.svg'),
                                }"
                                @click="Global.deleteConfirm('delete_confirm','copyConfigurationProcess', itemProp.id,{ 'process': itemProp.process}, $t('copy_process'), $t('preparing_copy_process'), 'copy')"
                            />
                            <GhAction v-if="!itemProp.enable"
                                :dataction="{
                                    id: 'action_enable_enable_disable_process_' + itemProp.id,
                                    text: $t('enable'),
                                    icon: require('../../../../../assets/svg/tick.svg'),
                                }"
                                @click="Global.deleteConfirm('delete_confirm','enableDisableConfigurationProcess', itemProp.id,{ 'process': itemProp.process}, $t('enable_process'), $t('preparing_enable_process'), 'enable')"
                            />
                            <GhAction v-if="itemProp.enable"
                                :dataction="{
                                    id: 'action_disable_enable_disable_process_' + itemProp.id,
                                    text: $t('disable'),
                                    icon: require('../../../../../assets/gh_new_dessign/denied.svg'),
                                }"
                                @click="Global.deleteConfirm('delete_confirm','enableDisableConfigurationProcess', itemProp.id,{ 'process': itemProp.process}, $t('disable_process'), $t('preparing_disable_process'), 'disable')"
                            />
                        </template>
                    </GhTableCommon>
                </template>
            </GhDropDownField>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_enable_disable_process',
        components: {
            GhAction,
            GhTableCommon,
            GhDropDownField
        },
        computed:{
            ...mapState(['ConfigurationProcessEnableDisable']),
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'process', field: 'process', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'related_items', field: 'related_items', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 70%;'},
                    {text: 'available_since', field: 'available_since', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationEnableDisableProcess','',{root:true});
            this.haveData = true;
        }
    }
</script>