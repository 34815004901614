var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'view_group',
            title: _vm.$t('group')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"group_permissions"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'name',
                                    name: 'group[name]',
                                    style: 'width: 40%',
                                    value: _vm.PermissionsGroups.data !== undefined ? _vm.PermissionsGroups.data.name : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.name ? _vm.PermissionsGroups.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-bottom":"15px"}},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    class: 'T15_b',
                                    styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                },"datainput":{
                                    id: 'description',
                                    name: 'group[description]',
                                    required: false,
                                    value: _vm.PermissionsGroups.data !== undefined ? _vm.PermissionsGroups.data.description : '',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.description ? _vm.PermissionsGroups.data.description : ''))])]],2),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticClass:"T15_b",staticStyle:{"width":"151px","text-align":"right","margin-right":"11px"}},[_vm._v(" "+_vm._s(_vm.$t('permissions'))+" ")]),_c('div',{staticStyle:{"display":"block","width":"82%"}},_vm._l((_vm.getFeeds.feed_perms),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content",staticStyle:{"padding-bottom":"3px !important","width":"100%","flex-wrap":"wrap","padding-left":"5px","padding-top":"2px"},style:(index%2 ? '' : 'background-color: #d9edfc;')},[_c('div',_vm._l((item),function(itemChildren,indexChildren){return _c('div',{key:indexChildren,staticStyle:{"width":"calc((100% - 240px) - (((100% - 280px) * 12) / 15))"},attrs:{"title":itemChildren.description}},[_c('GhChecks',{attrs:{"datalabel":{text:itemChildren.name,style:'',class:'',id:''},"datainput":{
                                                disabled: _vm.$root._route.params.view ? true : false,
                                                required: false,
                                                selected: _vm.PermissionsGroups.data === undefined || _vm.PermissionsGroups.data.length === 0 ? false : _vm.PermissionsGroups.data.perms.includes(itemChildren.id),
                                                type: 'checkbox',
                                                id: itemChildren.id,
                                                value: itemChildren.id,
                                                name: 'group[perms][]'
                                            }}})],1)}),0)])}),0)]),(_vm.PermissionsGroups.data === undefined || !_vm.PermissionsGroups.data.predefined)?[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"15px","height":"18px"}},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'label_profiles',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_profile') + ':',
                                        text: _vm.$t('profiles'),
                                        label: _vm.$t('profiles'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    },"datainput":{
                                        id: 'profiles',
                                        name: 'group[profiles][]'
                                    },"feed":_vm.getFeeds.feed_profiles,"selected_input":_vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.profiles ? _vm.PermissionsGroups.data.profiles : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('profiles') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.profiles ? Object.values(_vm.PermissionsGroups.data.profiles).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                        label: {
                                            style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                        },
                                        ResultSearch: 'result_search_data_groups',
                                        nameStore: 'getFeeds',
                                        imagelocation: require('../../../assets/gh_new_dessign/floating.svg'),
                                        id: 'users',
                                        text: _vm.$t('users'),
                                        textlocation: _vm.$t('add_users'),
                                        actionresult: 'loadResultSearchGroups',
                                        nameResult: 'group[users]',
                                        supportPermision: true
                                    },"mainSelector":{
                                        name: 'Formsearchcompany',
                                        formId: 'search',
                                        actionsearch: 'getSearchGroups'
                                    },"tableResolt":{
                                        header: _vm.header,
                                        data: _vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.users && _vm.PermissionsGroups.data.users.summary ? _vm.PermissionsGroups.data.users.summary : [],
                                    },"tableSelected":{
                                        headersummary: _vm.headersummary,
                                        selected: _vm.PermissionsGroups && _vm.PermissionsGroups.data && _vm.PermissionsGroups.data.users && _vm.PermissionsGroups.data.users.selecteds ? _vm.PermissionsGroups.data.users.selecteds : []
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('users') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.summary))])]],2)]:_vm._e()],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('accept'),
            }},on:{"click":_vm.submitPermissionGroup}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }