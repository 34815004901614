var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('medical')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'radio',
                id: 'surgery_type_of_admission_medical',
                value: 1,
                name: 'surgery[type_of_admission][type]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.type === 1 ? true : false,
            }},on:{"click":function($event){return _vm.checkType()}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"250px","min-width":"250px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surgical')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'radio',
                id: 'surgery_type_of_admission_surgical',
                value: 2,
                name: 'surgery[type_of_admission][type]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.type === 2 ? true : false,
            }},on:{"click":function($event){return _vm.checkType()}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"250px","min-width":"250px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('minor_procedure_room')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'radio',
                id: 'surgery_type_of_admission_minor_procedure_room',
                value: 3,
                name: 'surgery[type_of_admission][type]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.type === 3 ? true : false,
            }},on:{"click":function($event){return _vm.checkType()}}})],1),(_vm.isSurgical)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('day_surgery')))]),_c('GhChecks',{ref:"RefTypeOfAdmissionDaySurgery",attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_day_surgery',
                value: 4,
                name: 'surgery[type_of_admission][surgical][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.surgical.includes(4),
            }},on:{"click":function($event){return _vm.checkSurgical('RefTypeOfAdmissionDaySurgery')}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"251px","min-width":"251px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('pre_op_admission')))]),_c('GhChecks',{ref:"RefTypeOfAdmissionPreOpAdmission",attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_pre_op_admission',
                value: 5,
                name: 'surgery[type_of_admission][surgical][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.surgical.includes(5),
            }},on:{"click":function($event){return _vm.checkSurgical('RefTypeOfAdmissionPreOpAdmission')}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"251px","min-width":"251px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('post_op_admission')))]),_c('GhChecks',{ref:"RefTypeOfAdmissionPostOpAdmission",attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_post_op_admission',
                value: 6,
                name: 'surgery[type_of_admission][surgical][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.surgical.includes(6),
            }},on:{"click":function($event){return _vm.checkSurgical('RefTypeOfAdmissionPostOpAdmission')}}})],1):_vm._e(),(_vm.isPOST)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('pacu_steodown')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_pacu_steodown',
                value: 7,
                name: 'surgery[type_of_admission][post][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.post.includes(7),
            }},on:{"click":_vm.checkPost}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"251px","min-width":"251px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('icu')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_icu',
                value: 8,
                name: 'surgery[type_of_admission][post][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.post.includes(8),
            }},on:{"click":_vm.checkPost}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"251px","min-width":"251px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('ward')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: true,
                disabled: false,
                type: 'checkbox',
                id: 'surgery_type_of_admission_ward',
                value: 9,
                name: 'surgery[type_of_admission][post][]',
                selected: _vm.$parent.$parent.dataForm.type_of_admission.post.includes(9),
            }},on:{"click":_vm.checkPost}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }