<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_professional_active_',
        }"
        :header="header"
        :data="ConfigurationPriorityPriorities.records_data.length == 0 ? [] : ConfigurationPriorityPriorities.records_data"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_condition_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_priority_priority_edit', {id: itemProp.id}),'configuration_priority_priority')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_condition_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationPriorities', itemProp.id,{ 'id': itemProp.code, 'time': itemProp.time}, $t('delete_priority'), $t('preparing_delete_priority'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_new_condition',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_priority_priority_new', {}),'configuration_priority_priority')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import {mapState} from "vuex";
import GhButton from "fe-gh-button-lib";
import GhAction from "fe-gh-action-lib";

export default {
    name: "list_priorities",
    components: {
        GhAction,
        GhButton,
        GhTableCommon
    },
    computed: {
        ...mapState(['ConfigurationPriorityPriorities'])
    },
    data(){
        return{
            haveData: false,
            header: [
                {text: 'id', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; width: 100px;'},
                {text: 'time', field: 'time', sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 100%;'},
                {text: '', field: 'actions', sorting: false}
            ],
        }
    },
    watch: {
        async 'Global.state.temp_response.response'(){
            await this.$store.dispatch('getConfigurationPriorities', '', {root: true});
        }
    },
    async beforeMount(){
        await this.$store.dispatch('getConfigurationPriorities', '', {root: true});
        this.haveData = true;
    }
}
</script>