var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTableCommon',{staticStyle:{"margin-top":"12px"},attrs:{"extratable":{
          id: 'table_certifications'
      },"header":_vm.header,"data":_vm.MyFile.data.certifications},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp}){return [_c('GhEasyUpload',{staticStyle:{"width":"100%"},attrs:{"extrablock":{
                id: 'easy_upload_certification_' + itemProp.id,
                class: '',
                name_hidden: 'upload[certification][' + itemProp.id + ']',
                id_hidden: 'upload_certification_' + itemProp.id,
                icon_attach: require('../../../assets/gh_new_dessign/attach.svg'),
                icon_trash: require('../../../assets/gh_new_dessign/trash.svg')
            },"datalabel":{
                id: 'label_import_certification_' + itemProp.id,
                style: 'width: 100%'
            },"datainput":{
                text: _vm.$t('attach'),
                show_image: false,
                delete: true,
                href: 'easyUpload',
                file_name: itemProp.file.file_name,
                file_path: itemProp.file.file_path,
                file_download: itemProp.file.file_download,
                image_path: '',
                required: true
            }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }