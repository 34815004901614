<template>
    <div id="option_to_select_graphic" v-if="haveData">
        <component :is="popUpGraphicType" :dataBlock="{type: $parent.$parent.type_item, item: $parent.$parent.stateData.data.graphic}" ref="RefGraphicType" style="height: 26px;"/>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('min_y_value') + '*',
                        style: 'width: 150px; min-width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: $parent.$parent.type_item + '_graphic_min',
                        name: $parent.$parent.type_item + '[graphic][min]',
                        style: 'min-width: 100px; width: 100px',
                        value: min,
                        type: 'text',
                    }"
                    ref="RefMinYValue"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('min_y_value') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.graphic !== undefined && $parent.$parent.stateData.data.graphic.min !== undefined ? $parent.$parent.stateData.data.graphic.min : ''}}</label>
            </template>
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('max_y_value') + '*',
                        style: 'width: 150px; min-width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: $parent.$parent.type_item + '_graphic_max',
                        name: $parent.$parent.type_item + '[graphic][max]',
                        style: 'min-width: 100px; width: 100px',
                        value: max,
                        type: 'text',
                    }"
                    ref="RefMaxYValue"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('max_y_value') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.graphic !== undefined && $parent.$parent.stateData.data.graphic.max !== undefined ? $parent.$parent.stateData.data.graphic.max : ''}}</label>
            </template>
        </div>
        <div v-for="(item, index) in actualData" :key="index + refreshData">
            <component v-bind:is="includeLineOfGraphic" :dataBlock="{item: item, index: index, type: 'graphic'}" :key="index + refreshData" :ref="'RefLineOfGraphic' + index"/>
        </div>
        <template v-if="$root._route.params.view !== 'true'">
            <div style="padding-bottom: 10px;">
                <GhAction
                    style="margin-left: 160px;"
                    :dataction="{
                        id: 'action_add_variable',
                        text: $t('add_variable'),
                        icon: require('../../../../../assets/svg/add_plus.svg')
                    }"
                    @click="addVariable"
                />
                <br>
            </div>
        </template>
    </div>
</template>

<script>
    import popUpGraphicType from "@/view/configuration/items/views/partials/popups/popup_graphic_type.vue";
    import includeLineOfGraphic from "@/view/configuration/items/views/partials/includes/include_line_of_graphic.vue";

    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'view_graphic',
        components: {
            GhAction,
            GhInputTextField
        },
        data(){
            return {
                popUpGraphicType: popUpGraphicType,
                includeLineOfGraphic: includeLineOfGraphic,
                updated: this.$parent.$parent.updated,
                min: this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.graphic !== undefined && this.$parent.$parent.stateData.data.graphic.min !== undefined ? this.$parent.$parent.stateData.data.graphic.min : '',
                max: this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.graphic !== undefined && this.$parent.$parent.stateData.data.graphic.max !== undefined ? this.$parent.$parent.stateData.data.graphic.max : '',
                newData: {
                    'variable': ''
                },
                actualData: [],
                refreshData: 0,
                haveData: false,
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.graphic !== undefined && this.$parent.$parent.stateData.data.graphic.variables !== undefined){
                this.addVariable(this.$parent.$parent.stateData.data.graphic);
            }else{
                this.addVariable();
            }

            this.haveData = true;
        },
        updated() {
            if(!this.updated) {
                this.openGraphicType();
            }

            this.updated = true;
        },
        methods: {
            openGraphicType() {
                this.Global.openPopUp('label_' + this.$parent.$parent.type_item + '_graphic_type_data_selector_popup');
                this.$refs.RefGraphicType.setStatePopup();
            },
            addVariable(data){
                if(this.$refs['RefMinYValue'] !== undefined){
                    this.min = this.$refs['RefMinYValue'].datainput.value;
                    this.max = this.$refs['RefMaxYValue'].datainput.value;
                }

                var params = new Array();
                if(data !== undefined && data.variables !== undefined){
                    params = data.variables;
                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }
                }else{
                    this.actualData.forEach((item, key) => {
                        var array = {
                            'variable': this.$refs['RefLineOfGraphic' + key][0].$children[0].datainput.value,
                        };
                        params.push(array);
                    });

                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }

                    this.actualData.push(this.newData);
                }
            },
            removeVariable(index){
                this.min = this.$refs['RefMinYValue'].datainput.value;
                this.max = this.$refs['RefMaxYValue'].datainput.value;

                var params = new Array();
                this.actualData.forEach((item, key) => {
                    if(key !== index) {
                        var array = {
                            'variable': this.$refs['RefLineOfGraphic' + key][0].$children[0].datainput.value,
                        };
                        params.push(array);
                    }
                });

                this.actualData = [];
                if(params[0] !== undefined) {
                    params.forEach((item) => {
                        this.actualData.push(item);
                    });
                }

                this.refreshData++;
            }
        }
    }
</script>