<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t('creation_' + typeCreation) + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_creation_' + typeCreation,
                    title: $t($root._route.matched[0].props.default.otherTitle)
                }"
            >
                <template v-slot:content>
                    <form :id="'form_register_' + typeCreation" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content" style="padding-bottom: 2px;">
                                <GhTreeSelectorBlock
                                    :dataBlock="{
                                        type: 'radio',
                                        id: typeCreation + '_' + $root._route.matched[0].props.default.otherTitle,
                                        label: $t(typeCreation == 'process' ? 'form' : 'item') + '*',
                                        title: $t(typeCreation == 'process' ? 'form' : 'item'),
                                        text: $t('select_' + (typeCreation == 'process' ? 'form' : 'item')) + ':',
                                        name: typeCreation + '[' + $root._route.matched[0].props.default.otherTitle + ']',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: 'margin-left: 0px;',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :disabled_input="disabledItemForm"
                                    :selected_input="actualData.item !== undefined ? actualData.item : []"
                                    :items="typeCreation == 'process' ? getFeeds.forms : getFeeds.all_items"
                                    :ref="'RefSelected' + (typeCreation == 'process' ? 'Form' : 'Item')"
                                    @accept="getSelectedValues"
                                />
                            </div>
                            <template v-if="haveSelected && typeCreation !== 'process'">
                                <template v-for="(item, index) in ConfigurationFormProcess.info">
                                    <div v-if="index !== 'children'" class="gh-row-content" :key="index + refreshSelected">
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(index) + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item}}</label>
                                    </div>
                                </template>
                            </template>
                            <div class="gh-row-content">
                                <template v-if="parentData.data.data.data.length <= 1">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('mandatory')}}</label>
                                    <input type="hidden" :name="typeCreation + '[type]'" value="1">
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + '*'}}</label>
                                    <GhChecks
                                        :datalabel="{}"
                                        :datainput="{
                                            required: true,
                                            label_required: $t('type') + '*',
                                            type: 'radio',
                                            id: typeCreation + '_type_mandatory',
                                            value: 1,
                                            name: typeCreation + '[type]',
                                            selected: actualData.type !== undefined && actualData.type == 2 ? false : true,
                                        }"
                                        ref="RefTypeMandatory"
                                        @click="checkType('RefTypeMandatory')"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 25px;">{{$t('mandatory')}}</label>
                                    <GhChecks
                                        :datalabel="{}"
                                        :datainput="{
                                            required: true,
                                            label_required: $t('leave') + '*',
                                            type: 'radio',
                                            id: typeCreation + '_type_dependent',
                                            value: 2,
                                            name: typeCreation + '[type]',
                                            selected: actualData.type !== undefined && actualData.type == 2 ? true : false,
                                        }"
                                        ref="RefTypeDependent"
                                        @click="checkType('RefTypeDependent')"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('dependent')}}</label>
                                </template>
                            </div>
                            <template v-if="showDependent">
                                <div class="gh-row-content" style="padding-bottom: 2px;">
                                    <GhTreeSelectorBlock
                                        :dataBlock="{
                                            type: 'checkbox',
                                            id: typeCreation + '_dependent_element',
                                            label: $t('dependent_element'),
                                            title: $t('dependent_element'),
                                            text: $t('select_dependent_element') + ':',
                                            name: typeCreation + '[dependent_element]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: false,
                                            checkparentdata: false,
                                            style: 'margin-left: 0px; width: 100%;',
                                            popupStyle: 'min-width: 900px;width: 55%;',
                                            showParent: true
                                        }"
                                        :selected_input="actualData.dependent !== undefined ? actualData.dependent : []"
                                        :items="feedDependencies"
                                        :disabled_input="blockedByDependences"
                                        ref="RefDependents"
                                        @accept="checkDependent()"
                                    />
                                </div>
                                <div class="gh-row-content" v-if="showNumberOfDependencies" :key="refreshDependencies">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_of') + ' ' + nDependencies + ' ' + $t('dependencies').toLowerCase()}}</label>
                                    <GhAction style="margin-right: 23px;"
                                        :dataction="{
                                            id: 'action_edit_dependencies_' + typeCreation,
                                            text: $t('edit'),
                                            icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                        }"
                                        @click="openEditDependencies"
                                    />
                                </div>
                            </template>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton
                    :datainput="{
                        id: 'btn_accept_' + typeCreation,
                        text: $t('accept'),
                    }"
                    @click="setNewRegister"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_' + typeCreation,
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>

        <component v-bind:is="components2.popup_dependencies_include" :dataBlock="{data: feedAnswersByItems}" :key="refreshDependencies" ref="RefDependencyDefinition"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    import popup_dependencies from "@/view/configuration/form_process/shared/popups/popup_dependencies.vue";

    export default {
        name: 'partial_register_item_form',
        components: {
            GhAction,
            GhButton,
            GhLocation,
            GhNoDropSelector,
            GhTreeSelectorBlock,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationFormProcess'])
        },
        data(){
            return {
                type: '',
                typeCreation: '',
                actualData: [],
                parentData: [],
                feedDependencies: [],
                feedAnswersByItems: [],
                blockedByDependences: [],
                disabledItemForm: [],
                haveSelected: false,
                refreshSelected: 0,
                showDependent: false,
                showNumberOfDependencies: false,
                refreshDependencies: false,
                nDependencies: 0,
                haveData: false,
                components2: {
                    popup_dependencies_include: popup_dependencies
                },
                counterItems: 0
            }
        },
        async beforeMount(){
            this.type = this.$root._route.name.includes('configuration_process_form_') ? this.$root._route.name.split('configuration_process_form_')[1].split('_')[0] : this.$root._route.name.split('configuration_forms_item_')[1].split('_')[0];
            this.typeCreation = this.$root._route.name.split('configuration_')[1].split('_')[0];
            this.parentData = JSON.parse(localStorage.getItem('tempFloatWindow'));

            this.parentData.data.data.data.forEach((item) => {
                var params = {
                    'text': this.typeCreation == 'process' ? item.form : item.item,
                    'value': this.typeCreation == 'process' ? item.form_value : item.item_value
                };

                if(this.parentData.indexEdit !== undefined){
                    if(this.parentData.indexEdit.id !== item.id) {
                        this.disabledItemForm.push(params);
                    }
                }else{
                    this.disabledItemForm.push(params);
                }
            });

            if(this.$root._route.params.id !== undefined) {
                var data = this.parentData.data.data.data.find((item) => item.id == this.$root._route.params.id);

                if (data.data === undefined) {
                    await this.$store.dispatch('getItemFormData', {id: this.$root._route.params.id, item_section_id: this.$root._route.params.item_section_id, form_process_id: this.$root._route.params.form_process_id, type: this.typeCreation}, {root: true});
                    this.actualData = this.ConfigurationFormProcess.data_item_form;
                } else {
                    this.actualData = data.data;
                }
            }

            this.parentData.data.allData.forEach((i) => {
                var children = [];

                if(i.data.length !== 0){
                    i.data.forEach((x) => {
                        if(this.parentData.indexEdit === undefined || (this.parentData.indexEdit !== undefined && this.parentData.indexEdit.id !== x.id)) {
                            var childrenKey = this.typeCreation == 'process' ? 'form_children' : 'item_children';
                            var actualChildren = [];

                            this.typeCreation == 'process' ? actualChildren.push(x[childrenKey] !== undefined ? x[childrenKey] : []) : actualChildren = x[childrenKey] !== undefined ? x[childrenKey] : [];

                            var param = {
                                'value': x.id,
                                'text': this.typeCreation == 'process' ? x.form : x.item,
                                'children': actualChildren.length !== 0 && actualChildren[0].length !== undefined && actualChildren[0].length !== 1 ? actualChildren[0] : actualChildren
                            };

                            if(actualChildren.length !== 0){
                                children.push(param);
                            }

                        }
                    });
                }

                var param = {
                    'value': i.id,
                    'text': i.name,
                    'children': children
                }

                if(children.length !== 0){
                    this.feedDependencies.push(param);
                }
            });

            if(this.actualData.type == 2){
                this.showDependent = true;

                if(this.actualData.dependent !== undefined) {
                    this.feedAnswersByItems = [];

                    for (var i = 0; i < this.actualData.dependent.length; i++) {
                        this.findChildren(this.feedDependencies, this.actualData.dependent[i]);
                    }

                    this.showNumberOfDependencies = this.actualData.dependent.length !== 0 ? true : false;

                    if (this.actualData.dependency !== undefined) {
                        this.nDependencies = this.actualData.dependency.length;
                    }
                }
                this.refreshDependencies++;
            }

            if(this.typeCreation == 'process'){
                await this.$store.dispatch('getFeedForms', '', {root: true});
            }else{
                await this.$store.dispatch('getFeedAllItems', '', {root: true});
            }

            if(this.actualData.item !== undefined) {
                await this.$store.dispatch('getInfoByTypeAndID', {
                    id: this.actualData.item[0].value,
                    type: this.typeCreation == 'process' ? 'form' : 'item',
                });

                this.haveSelected = true;

                this.showNumberOfDependencies = this.actualData.dependent !== undefined ? true : false;
                this.refreshSelected++;
            }

            this.haveData = true;
        },
        methods: {
            async getSelectedValues(){
                if(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved.length !== 0){
                    var selected = Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[0][1];

                    await this.$store.dispatch('getInfoByTypeAndID', {
                        id: selected,
                        type: this.typeCreation == 'process' ? 'form' : 'item',
                    });

                    this.haveSelected = true;
                }else{
                    this.haveSelected = false;
                }

                this.refreshSelected++;
            },
            checkType(ref){
                if (this.$refs[ref].$el.children[0].children[1].checked != true){
                    this.$refs[ref].checkUncheck();
                }

                this.showDependent = ref === 'RefTypeDependent' ? true : false;
            },
            checkDependent(){
                this.feedAnswersByItems = [];

                for(var i = 0; i < this.$refs['RefDependents'].datasaved.length; i++){
                    this.findChildren(this.feedDependencies, this.$refs['RefDependents'].datasaved[i]);
                }

                this.showNumberOfDependencies = this.$refs['RefDependents'].datasaved.length !== 0 ? true : false;

                var that = this;
                setTimeout(function(){
                    that.$refs['RefDependencyDefinition'].dependency.forEach((item, key) => {
                        if(item.selecteds !== 0){
                            that.feedAnswersByItems.forEach((feed) => {
                                item.selecteds.forEach((i, k) => {
                                    var result = feed.children.find((f) => f.value == i.value);
                                    if(result !== undefined){
                                        that.$refs['RefDependencyDefinition'].dependency[key].selecteds[k].counter = result.counter;
                                    }
                                });
                            });
                        }
                    });
                },100);

                this.refreshDependencies++;
            },
            findChildren(array, value, route = []) {
                for(let i = 0; i < array.length; i++){
                    const element = array[i];
                    if(element.children && element.children.length > 0){
                        this.findChildren(element.children, value, [...route, i]);
                    }else if(element.value === value.value){
                        var result = [...route, i];
                        this.drawFeedDependent(result, this.feedDependencies);
                        return;
                    }
                }
            },
            drawFeedDependent(route, array){
                var text = '';
                var identificator = '';
                for (let i = 0; i < route.length; i++) {
                    if(array.children){
                        text += array.text + ' > ';
                        identificator = array.value;
                        array = array.children[route[i]];
                    }else{
                        array = array[route[i]];
                    }

                    if(array.children === undefined){
                        var params = {
                            'counter': this.counterDependent++,
                            'value': identificator,
                            'text': text.slice(0, -2),
                            'children': [array],
                        }

                        this.feedAnswersByItems.push(params);
                    }
                }
            },
            openEditDependencies(){
                this.Global.openPopUp('popup_dependencies');
            },
            setNewRegister(){
                if(this.Global.checkRequired('form_register_' + this.typeCreation) == 'OK') {
                    var response = {};
                    var dependent = [];

                    var type = document.getElementById(this.typeCreation + '_type_dependent') !== null ? document.querySelector('input[name="' + this.typeCreation + '[type]"]:checked').value : 1;
                    var item_value = Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[0][0] == 'value' ? Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[0][1] : Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[1][1];
                    var item_text = Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[1][0] == 'text' ? Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[1][1] : Object.entries(this.$refs['RefSelected' + (this.typeCreation == 'process' ? 'Form' : 'Item')].datasaved)[0][1];

                    if(this.$refs['RefDependents'] !== undefined) {
                        Object.entries(this.$refs['RefDependents'].datasaved).forEach((item) => {
                            var pushDependent = {
                                'text': item[1].text,
                                'value': item[1].value
                            };
                            dependent.push(pushDependent);
                        });
                    }

                    var data = {
                        'item': [
                            {
                                'value': item_value,
                                'text': item_text
                            }
                        ],
                        'type': type,
                        'dependent': this.$refs['RefDependents'] !== undefined ? dependent : undefined,
                        'dependency': this.$refs['RefDependencyDefinition'].dependency
                    };

                    if (this.typeCreation == 'process') {
                        response = {
                            'id': this.$root._route.params.id !== undefined ? this.$root._route.params.id : Math.floor(Math.random() * 100000000000),
                            'form': item_text,
                            'form_value': item_value,
                            'form_children': this.ConfigurationFormProcess.info !== undefined && this.ConfigurationFormProcess.info.children !== undefined ? this.ConfigurationFormProcess.info.children : [],
                            'mandatory_dependent': type == 1 ? this.$t('mandatory') : this.nDependencies + ' ' + this.$t('dependents'),
                            'data': data
                        }
                    } else {
                        response = {
                            'id': this.$root._route.params.id !== undefined ? this.$root._route.params.id : Math.floor(Math.random() * 100000000000),
                            'item': item_text,
                            'item_value': item_value,
                            'item_children': this.ConfigurationFormProcess.info !== undefined && this.ConfigurationFormProcess.info.children !== undefined ? this.ConfigurationFormProcess.info.children : [],
                            'mandatory_dependent': type == 1 ? this.$t('mandatory') : this.nDependencies + ' ' + this.$t('dependents'),
                            'data': data
                        }
                    }

                    window.opener.UpdatedSelect(response);

                    this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    #process_process_selector_label, #forms_item_selector_label, #forms_dependent_element_selector_label, #process_dependent_element_selector_label{
        width: 150px;
        text-align: end;
    }
</style>