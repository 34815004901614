<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationProcess.section_process_info[label['type']].show"
                :datablock="{
                    id: 'configuration_process_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationProcess.section_process_info[label['type']]['second_text']),
                    num_results: ConfigurationProcess.section_process_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>

        <component v-bind:is="popup_propagate" :dataBlock="{type: popupType, refresh: popupRefresh}"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhDropSelector from "fe-gh-dropselector-lib";

    import list_parameterization_process from "@/view/configuration/form_process/process/list/list_parameterization_process.vue";
    import list_category_process from "@/view/configuration/form_process/process/list/list_category_process.vue";
    import list_creation_process from "@/view/configuration/form_process/process/list/list_creation_process.vue";
    import list_enable_disable_process from "@/view/configuration/form_process/process/list/list_enable_disable_process.vue";
    import list_validate_propagate_process from "@/view/configuration/form_process/process/list/list_validate_propagate_process.vue";

    import popup_propagate from "@/view/configuration/form_process/shared/popups/popup_propagate.vue";

    export default {
        name: "sections_process",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationProcess']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: this.$t('parameterization_process'), component: list_parameterization_process, type: 'parameterization_process'},
                    {id: 2, name: this.$t('category_process'), component: list_category_process, type: 'category_process'},
                    {id: 3, name: this.$t('creation_process'), component: list_creation_process, type: 'creation_process'},
                    {id: 4, name: this.$t('enable_disable_process'), component: list_enable_disable_process, type: 'enable_process'},
                    {id: 5, name: this.$t('validate_propagate_process'), component: list_validate_propagate_process, type: 'validate_propagate_process'},
                ],
                popupRefresh: 0,
                popupType: '',
                popup_propagate: popup_propagate
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationProcessSections','',{root:true});
            this.haveData = true;
        }
    }
</script>