<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_nationality',
                    title: $t('nationality')
                }"
            >
                <template v-slot:content>
                    <form id="form_nationality" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'nationality_code',
                                            name: 'nationality[code]',
                                            style: 'width: 200px',
                                            value: ConfigurationGeneralDataNationality.data !== undefined ? ConfigurationGeneralDataNationality.data.code : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationGeneralDataNationality.data !== undefined ? ConfigurationGeneralDataNationality.data.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'nationality_name',
                                            name: 'nationality[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationGeneralDataNationality.data !== undefined ? ConfigurationGeneralDataNationality.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationGeneralDataNationality.data !== undefined ? ConfigurationGeneralDataNationality.data.name : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="!this.$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitNationality();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_nationality",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationGeneralDataNationality'])
        },
        data(){
            return {
                type: '',
                haveData: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationGeneralDataNationality', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationGeneralDataNationality.data = [];
            }

            this.type = this.$root._route.name.split('configuration_general_data_')[1].split('_nationality')[0];
            this.haveData = true;
        },
        methods: {
            async submitNationality(){
                if(this.Global.checkRequired('form_nationality') == 'OK'){
                    const formData = new FormData(document.getElementById('form_nationality'));
                    await this.$store.dispatch('setDataConfigurationGeneralDataNationality', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>
