import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationGeneralDataRelationships(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationGeneralDataRelationships(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationGeneralDataRelationships(state){
            await axios.get(
                API_URL + "relationships"
            ).then(
                response => {
                    state.commit('loadTableConfigurationGeneralDataRelationships', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationGeneralDataRelationship(state, item){
            await axios.get(
                API_URL + "relationships/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationGeneralDataRelationships', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationGeneralDataRelationship(state, item){
            let request = item.id !== undefined ? API_URL + "relationships/" + item.id : API_URL + "relationships";
            let formData = new FormData();

            formData.append('relationship[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        window.opener.UpdatedSelect(Math.random(), item.id !== undefined ? 'edit' : 'add');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationGeneralDataRelationship(state, item){
            let requestLogin = API_URL + "relationships/" + item.id ;

            await axios({
                method: "delete",
                url: requestLogin,
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationGeneralDataRelationships');
                        state.rootState.ConfigurationGeneralData.section_info.relationships.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};