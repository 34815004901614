var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_professional',
                title: _vm.$t('professional')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_professional"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})])]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_code',
                                            name: 'professional[code]',
                                            style: 'width: 200px',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.code : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_name',
                                            name: 'professional[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.name : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('surname') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_surname',
                                            name: 'professional[surname]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.surname : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.surname : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'professional_type_professional',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_professional_type') + ':',
                                            text: _vm.$t('professional_type'),
                                            label: _vm.$t('professional_type'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'professional_professional_type',
                                            name: 'professional[professional_type][]'
                                        },"feed":_vm.getFeeds.professional_type,"selected_input":_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.professional_type : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('professional_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? Object.values(_vm.ConfigurationProfessionals.data.professional_type).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('private')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                            required: false,
                                            type: 'checkbox',
                                            id: 'professional_private',
                                            value: 1,
                                            name: 'professional[private]',
                                            selected: _vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.private !== undefined && _vm.ConfigurationProfessionals.data.private == 1 ? true : false
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('status') + ':'))]),(_vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.private !== undefined )?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[(_vm.ConfigurationProfessionals.data.private == 1)?[_vm._v(" "+_vm._s(_vm.$t('yes').toUpperCase())+" ")]:[_vm._v(" "+_vm._s(_vm.$t('no').toUpperCase())+" ")]],2)]:_vm._e()]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('picture') + '*'))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                                        id: 'professional_picture',
                                        class: '',
                                        name_hidden: 'professional[picture]',
                                        id_hidden: 'professional_picture',
                                        icon_attach: ''
                                    },"datalabel":{
                                        id: 'label_professional_picture',
                                        style: 'margin-right: 5px'
                                    },"datainput":{
                                        view: _vm.$root._route.params.view,
                                        accept: 'image/png, image/gif, image/jpeg',
                                        text: _vm.$t('upload'),
                                        show_image: false,
                                        delete: true,
                                        href: 'easyUpload',
                                        file_name: _vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.logo !== undefined ? _vm.ConfigurationProfessionals.data.logo.file_name : '',
                                        file_path: _vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.logo !== undefined ? _vm.ConfigurationProfessionals.data.logo.file_path : '',
                                        file_download: _vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.logo !== undefined ? _vm.ConfigurationProfessionals.data.logo.file_download : '',
                                        required: true
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('md_number') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_md_number',
                                            name: 'professional[md_number]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.md_number : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('md_number') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.md_number : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('referral_number') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_referral_number',
                                            name: 'professional[referral_number]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.referral_number : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('referral_number') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.referral_number : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'professional_specialties',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_specialties') + ':',
                                            text: _vm.$t('specialties'),
                                            label: _vm.$t('specialties'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'professional_specialties',
                                            name: 'professional[specialties][]'
                                        },"feed":_vm.getFeeds.specialties,"selected_input":_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? Object.values(_vm.ConfigurationProfessionals.data.specialties).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('telephone') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_telephone',
                                            name: 'professional[telephone]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.telephone : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('telephone') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.telephone : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('fax') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_fax',
                                            name: 'professional[fax]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.fax : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('fax') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.fax : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.$t('email') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'professional_email',
                                            name: 'professional[email]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.email : '',
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationProfessionals.data !== undefined ? _vm.ConfigurationProfessionals.data.email : ''))])]],2),(_vm.$root._route.params.id !== undefined)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultiUpload',{attrs:{"datalabel":{
                                            text: _vm.$t('required_documents'),
                                            text_popup: _vm.$t('required_documents'),
                                            input_text: _vm.$t('name'),
                                            textarea_text: _vm.$t('description'),
                                            attach_text: _vm.$t('attach'),
                                            content_warning: '',
                                            label_required: _vm.$t('required_documents'),
                                            required: false
                                        },"datablock":{
                                            id: 'professional_documents',
                                            name: 'professional[documents]',
                                            style: '',
                                            class: 'T15_b',
                                            icon_attach: '',
                                            icon_trash: '',
                                            icon_download: '',
                                            href: _vm.dispatchEvidence
                                        },"defaultfiles":_vm.ConfigurationProfessionals.data !== undefined && _vm.ConfigurationProfessionals.data.required_documents !== undefined ? _vm.ConfigurationProfessionals.data.required_documents : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('required_documents') + ':'))]),_vm._l((_vm.ConfigurationProfessionals.data.required_documents),function(file,index){return _c('div',{key:index},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_c('a',{staticClass:"C1 F5",attrs:{"href":file.file_download}},[_vm._v(_vm._s(file.name))]),_vm._v(_vm._s(index != Object.keys(_vm.ConfigurationProfessionals.data.required_documents).pop() ? ', ' : ''))])])})]],2):_vm._e()])])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.haveData && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitProfessional();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }