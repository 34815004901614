import ConfigSchedule from "@/view/configuration/support/views/view_schedule.vue";

const routes = [
    {
        path: '/configuration/support/schedule',
        name: 'configuration_support_new_schedule',
        component: ConfigSchedule,
        props: {id: null, showMenu: false, background: true, otherTitle: 'schedule'},
        children: [
            {
                path: '/configuration/support/schedule/:id',
                name: 'configuration_support_edit_schedule',
                component: ConfigSchedule,
                props: {id: null, showMenu: false, background: true, otherTitle: 'schedule'},
            },
            {
                path: '/configuration/support/schedule/:id/:view',
                name: 'configuration_support_view_schedule',
                component: ConfigSchedule,
                props: {id: null, showMenu: false, background: true, otherTitle: 'schedule'},
            },
        ]
    }
];
export default routes;