<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <template v-if="dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.id_recurrence !== undefined">
                    <label :id="'label_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_item_recurrence_title'" class="gh_text_field-label T15">
                        {{$t('recurrence') + ':'}}
                    </label>
                    <label :id="'label_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_item_recurrence_result'" class="gh_text_field-label T15_b" style="margin-right: 10px;">
                        {{dataItem.item.config.input.id_recurrence === 1 ? $t('continous') : $t('intermittent')}}
                    </label>
                    <div style="padding-top: 8px; display: flex;">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('quantity') + (dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required ? '*' : ''),
                                style: '',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required,
                                id: 'input_' + dataItem.item.config.input.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item_quantity',
                                name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item][quantity]',
                                style: 'width: 100px',
                                value: dataItem.item.data !== undefined ? dataItem.item.data.quantity : '',
                                type: 'float'
                            }"
                            :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                            @change="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);$parent.checkExtraItem($el, haveExtraItem, $refs, 7, dataItem.item.config.input.id, processId, subprocessId, formId, sectionId, indexItem, dataItem.id)"
                        />
                        <label class="gh_text_field-label T15_b" style="width: 130px; margin-left: 10px;">
                            {{dataItem.item.config.input.quantity}}
                        </label>
                        <template v-if="dataItem.item.config.input.id_recurrence === 2">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('period_every') + (dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required ? '*' : ''),
                                    style: '',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required,
                                    id: 'input_' + dataItem.item.config.input.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item_period_every',
                                    name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item][period_every]',
                                    style: 'width: 100px',
                                    value: dataItem.item.data !== undefined ? dataItem.item.data.period_every : '',
                                    type: 'int'
                                }"
                                @change="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);"
                            />
                            <label class="gh_text_field-label T15_b" style="width: 130px; margin-left: 10px;">
                                {{$t(dataItem.item.config.input.period_every)}}
                            </label>
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('period_lasting') + (dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required ? '*' : ''),
                                    style: '',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required,
                                    id: 'input_' + dataItem.item.config.input.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item_period_lasting',
                                    name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item][period_lasting]',
                                    style: 'width: 100px',
                                    value: dataItem.item.data !== undefined ? dataItem.item.data.period_lasting : '',
                                    type: 'int'
                                }"
                                @change="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);"
                            />
                            <label class="gh_text_field-label T15_b" style="margin-left: 10px;">
                                {{$t(dataItem.item.config.input.period_lasting)}}
                            </label>
                        </template>
                    </div>
                </template>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'view_dosage',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhInputTextField
        },
        data(){
            return{
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                haveExtraItem: this.dataItem.item.config !== undefined && this.dataItem.item.config.extra_item !== undefined ? this.dataItem.item.config.extra_item : false
            }
        }
    }
</script>