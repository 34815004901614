var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'table_category_form',
    },"header":_vm.header,"data":_vm.ConfigurationFormsCategory.list_category_form.length == 0 ? [] : _vm.ConfigurationFormsCategory.list_category_form},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_category_form_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_forms_edit_category_form', {id: itemProp.id}),'configuration_forms_category_form')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_category_form_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationCategoryForm', itemProp.id,{ 'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_category_form'), _vm.$t('preparing_delete_category_form'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_category_form',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_forms_new_category_form', {}),'configuration_forms_category_form')}}})],1)]},proxy:true}],null,false,3236839583)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }