<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <template v-if="dataItem.item.config.checks !== undefined && dataItem.item.config.checks.values !== undefined && dataItem.item.config.checks.values.length !== 0">
                    <div style="display: flex;">
                        <template v-for="(item, key) in dataItem.item.config.checks.values">
                            <GhChecks :key="key"
                                :datalabel="{text: item.text, style: 'margin-right: 100px;', class: '', id: ''}"
                                :datainput="{
                                    disabled: false,
                                    required: dataItem.item.config.checks.required !== undefined ? dataItem.item.config.checks.required : false,
                                    selected: dataItem.item.data !== undefined && dataItem.item.data.item !== undefined && item.value === dataItem.item.data.item,
                                    type: 'radio',
                                    id: 'check_' + dataItem.item.config.checks.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                                    value: item.value,
                                    name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]'
                                }"
                                :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                                @click="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);$parent.checkExtraItem($el, haveExtraItem, $refs, 2, dataItem.item.config.checks.id, processId, subprocessId, formId, sectionId, indexItem, dataItem.id)"
                            />
                        </template>
                    </div>
                </template>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhMultiUpload from "fe-gh-multi-upload-lib";

    export default {
        name: 'view_yes_no',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhChecks,
            GhMultiUpload
        },
        data(){
            return {
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                haveExtraItem: this.dataItem.item.config !== undefined && this.dataItem.item.config.extra_item !== undefined ? this.dataItem.item.config.extra_item : false
            }
        }
    }
</script>