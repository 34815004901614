var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'permissions_groups',
    },"header":_vm.header,"data":_vm.PermissionsGroups.records_data.length === 0 ? [] : _vm.PermissionsGroups.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'permissions_view_group', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'permissions_edit_group', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_group_' + itemProp['id'],
                    text: _vm.$t('edit'),
                    key_show_condition: 'toShow',
                    value_show_condition: false,
                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    item_prop: itemProp
                }}})],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'permissions_new_group'}}},[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_add_group',
                        text: _vm.$t('add')
                    }}})],1)],1)]},proxy:true}],null,false,78308768)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }