var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        }}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_schedule_pre_ops',
                title: _vm.$t('schedule_pre_ops')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_schedule_pre_ops"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"200px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('will_be_external_preops') + '?'))]),_c('GhChecks',{ref:"checkExternal1",attrs:{"datalabel":{
                                    text: _vm.$t('yes'),
                                    style: 'margin-right: 50px;'
                                },"datainput":{
                                    id: 'schedule_pre_available_short_notice',
                                    value: 1,
                                    type: 'radio',
                                    name: 'schedule_pre_ops[external_pre_ops]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }},on:{"click":function($event){return _vm.externalPreOps(true)}}}),_c('GhChecks',{ref:"checkExternal2",attrs:{"datalabel":{
                                    text: _vm.$t('no'),
                                },"datainput":{
                                    id: 'schedule_pre_available_short_notice',
                                    value: 0,
                                    type: 'radio',
                                    name: 'schedule_pre_ops[external_pre_ops]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }},on:{"click":function($event){return _vm.externalPreOps(false)}}})],1),(_vm.block_yes_external_preops)?_c('div',{staticStyle:{"margin-top":"15px"}},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{staticStyle:{"width":"50%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                                                id: 'label_external_clinics',
                                                class: 'T15_b',
                                                subtitle: _vm.$t('select_clinic') + ':',
                                                text: _vm.$t('clinic'),
                                                label: _vm.$t('clinic'),
                                                type: 'radio',
                                                columns: 3,
                                                required: false,
                                                style: 'margin-left: 50px;',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            },"datainput":{
                                                id: 'external_clinic',
                                                name: 'schedule_pre_ops[external_clinic]'
                                            },"feed":_vm.getFeeds.clinics,"selected_input":[]}})],1)]),_c('div',[_c('div',{staticStyle:{"width":"50%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                                                id: 'label_external_doctors',
                                                class: 'T15_b',
                                                subtitle: _vm.$t('select_doctor') + ':',
                                                text: _vm.$t('doctor'),
                                                label: _vm.$t('doctor'),
                                                type: 'radio',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            },"datainput":{
                                                id: 'external_doctor',
                                                name: 'schedule_pre_ops[external_doctor]'
                                            },"feed":_vm.getFeeds.doctors,"selected_input":[]}})],1)])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"50px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('no_later_than') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.WarningsPreOps.data_schedule_pre_ops.external.no_later_than))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"50px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('comments') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.WarningsPreOps.data_schedule_pre_ops.external.comments))])])]):_vm._e(),(_vm.block_no_external_preops)?_c('div',{key:_vm.refresh_no_external,staticStyle:{"margin-top":"15px"}},[_vm._l((_vm.WarningsPreOps.data_schedule_pre_ops.internal),function(item,index){return _c('div',{key:'no_external_pre_ops-'+index},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"50px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t(item.type) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.date))]),(!_vm.show_no_external_select[item.id])?[_c('GhAction',{ref:"RefScheduleSelect1",refInFor:true,staticStyle:{"margin-top":"-2px"},attrs:{"dataction":{
                                                id: 'action_select_' + item.id,
                                                text: _vm.$t('select')
                                            }},on:{"click":function($event){return _vm.checkSchedule(item.id)}}})]:[_c('GhAction',{staticStyle:{"margin-top":"-2px","margin-left":"10px"},attrs:{"dataction":{
                                                id: 'action_select_' + item.id,
                                                text: '',
                                                icon: require('../../../../assets/svg/tick.svg'),
                                            }},on:{"click":function($event){return _vm.unCheckSchedule(item.id)}}})]],2)])}),_c('input',{attrs:{"type":"hidden","name":"schedule_pre_ops[no_external_selected]"},domProps:{"value":_vm.no_external_preops_selected}})],2):_vm._e()])])]},proxy:true}])})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submit()}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }