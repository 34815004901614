<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Submits.section_info[label['type']].show"
                :datablock="{
                    id: 'submits_'+label.id,
                    title: $t(label['name']),
                    second_title: $t(Submits.section_info[label['type']]['second_text']),
                    num_results: Submits.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";

    import list_clinics from "@/view/submits/lists/list_clinics.vue";
    import list_professionals from "@/view/submits/lists/list_professionals.vue";

    export default {
        name: 'list_submits',
        data(){
            return {
                data: [
                    {id: 1, name: 'clinics', component: list_clinics, type: 'clinics'},
                    {id: 2, name: 'professionals', component: list_professionals, type: 'professionals'},
                ],
                haveData: false
            }
        },
        components:{
            GhDropSelector
        },
        computed : {
            ...mapState(['Submits'])
        },
        async beforeMount(){
            await this.$store.dispatch('getSubmitsSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>