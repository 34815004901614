var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{attrs:{"id":"option_to_select_graphic"}},[_c(_vm.popUpGraphicType,{ref:"RefGraphicType",tag:"component",staticStyle:{"height":"26px"},attrs:{"dataBlock":{type: _vm.$parent.$parent.type_item, item: _vm.$parent.$parent.stateData.data.graphic}}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefMinYValue",attrs:{"datalabel":{
                    text: _vm.$t('min_y_value') + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: _vm.$parent.$parent.type_item + '_graphic_min',
                    name: _vm.$parent.$parent.type_item + '[graphic][min]',
                    style: 'min-width: 100px; width: 100px',
                    value: _vm.min,
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('min_y_value') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.graphic !== undefined && _vm.$parent.$parent.stateData.data.graphic.min !== undefined ? _vm.$parent.$parent.stateData.data.graphic.min : ''))])],(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefMaxYValue",attrs:{"datalabel":{
                    text: _vm.$t('max_y_value') + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: _vm.$parent.$parent.type_item + '_graphic_max',
                    name: _vm.$parent.$parent.type_item + '[graphic][max]',
                    style: 'min-width: 100px; width: 100px',
                    value: _vm.max,
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('max_y_value') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.graphic !== undefined && _vm.$parent.$parent.stateData.data.graphic.max !== undefined ? _vm.$parent.$parent.stateData.data.graphic.max : ''))])]],2),_vm._l((_vm.actualData),function(item,index){return _c('div',{key:index + _vm.refreshData},[_c(_vm.includeLineOfGraphic,{key:index + _vm.refreshData,ref:'RefLineOfGraphic' + index,refInFor:true,tag:"component",attrs:{"dataBlock":{item: item, index: index, type: 'graphic'}}})],1)}),(_vm.$root._route.params.view !== 'true')?[_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('GhAction',{staticStyle:{"margin-left":"160px"},attrs:{"dataction":{
                    id: 'action_add_variable',
                    text: _vm.$t('add_variable'),
                    icon: require('../../../../../assets/svg/add_plus.svg')
                }},on:{"click":_vm.addVariable}}),_c('br')],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }