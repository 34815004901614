<template>
    <div class="float_window" v-if="haveData" style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Support.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_variable_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(Support.section_info[label['type']]['second_text']),
                    num_results: Support.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
                <template v-slot:actions v-if="label.actions">
                    <GhAction
                        v-for="action in label.actions"
                        :key="action.id"
                        :dataction="{
                            id: action.id,
                            class: 'T15_action',
                            text: $t(action.name)
                        }"
                        @click="action.id == 'export_monthly_support_indicator_data' ? exportTypeExcel(1) : '';  action.id == 'export_annual_support_indicator_data' ? exportTypeExcel(0) : ''"
                    />
                </template>
            </GhDropSelector>
        </div>
        <GhNoDropSelector
            v-if="SupportHistoric && SupportHistoric.haveResult==true"
            :datablock="{
                id: 'result_search_historical',
                title: typeof SupportHistoric.resultTitle == 'number' ? SupportHistoric.resultTitle + ' ' + $t('results') : $t(SupportHistoric.resultTitle),
                num_results: '',
            }"
        >
            <template v-slot:content>
                <component v-bind:is="list_result_historic" />
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import list_pending_requests_groups from "@/view/support/lists/list_pending_requests_groups.vue";
    import list_pending_requests from "@/view/support/lists/list_pending_requests.vue";
    import list_indicators from "@/view/support/lists/list_indicators.vue";
    import list_historic from "@/view/support/lists/list_historic.vue";
    import list_result_historic from "@/view/support/lists/list_result_historic.vue";

    export default {
        name: "section_support",
        components: {
            GhDropSelector,
            GhNoDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['Support', 'SupportHistoric'])
        },
        data(){
            return {
                haveData:false,
                data: [
                    {id: 1, name: 'pending_requests_from_my_group', component: list_pending_requests_groups, type: 'pending_requests_from_my_group'},
                    {id: 2, name: 'pending_requests', component: list_pending_requests, type: 'pending_requests'},
                    {id: 3, name: 'indicator', component: list_indicators, type: 'indicator', actions: [
                        {id: 'export_monthly_support_indicator_data', name: 'export_monthly'},
                        {id: 'export_annual_support_indicator_data', name: 'export_annual'}
                    ]},
                    {id: 4, name: 'historic', component: list_historic, type: 'historic'}
                ],
                list_result_historic: list_result_historic
            }
        },
        async beforeMount() {
            this.$store.dispatch('destroyedSupportHistoricalResultData',{},{root:true});
            await this.$store.dispatch('getSupportSections', '', {root: true});
            this.haveData = true;
        },
        methods:{
            exportTypeExcel(type){
                this.$store.dispatch('exportTypeExcel', {where: 'support', type: type}, {root: true});
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedSupportHistoricalResultData',{},{root:true})
        }
    }
</script>