<template>
    <component v-bind:is="partial"/>
</template>

<script>
    import {mapState} from "vuex";

    import today from "@/view/visits/views/view_today_upcoming_patient_by_professional.vue";
    import week from "@/view/visits/views/view_week_month_upcoming_patient_by_professional.vue";

    export default {
        name: "list_upcoming_patients_by_professional",
        computed: {
            ...mapState(['VisitsUpcomingPatientsByProfessional'])
        },
        data(){
            return {
                haveData: false,
                partial: ''
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getVisitsUpcomingPatientsByProfessionalList', {type: this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1]}, {root: true});

            if(this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1] == 'today'){
                this.partial = today;
            }else{
                this.partial = week;
            }

            this.haveData = true;
        }
    }
</script>