<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_support_holiday'
        }"
        :header="header"
        :data="ConfigurationSupportHoliday.records_data.length == 0 ? [] : ConfigurationSupportHoliday.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_support_view_holiday', {id: itemProp.id, view: true}),'configuration_support_holiday')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_holiday_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_support_edit_holiday', {id: itemProp.id}),'configuration_support_holiday')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "list_holiday",
        components:{
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['ConfigurationSupportHoliday'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'assignation_group', field: 'assignment_group', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'holidays', field: 'working_days', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false,style: 'width:10%;'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportHoliday', '', {root: true});
            this.haveData = true;
        }
    }
</script>