var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_rename_' + _vm.dataBlock.type,
        title: _vm.dataBlock.type === 'process' ? _vm.$t('subprocess') : _vm.$t('section'),
        type: 'warning',
        style: 'width: 50%; min-width: 700px'
    },"bodyConfiguration":{
        id: 'popup_rename_' + _vm.dataBlock.type + '_body',
        class: '',
        style: 'padding:2px;',
        text: _vm.dataBlock.type === 'process' ? _vm.$t('specifies_a_subprocess_name') : _vm.$t('specifies_a_section_name'),
    },"footerConfiguration":{}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('GhInputTextField',{ref:"RefNameSectionSubprocess",attrs:{"datalabel":{
                    text: _vm.$t('name') + '*',
                    style: 'margin-left: 5px;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: _vm.dataBlock.type + '_name',
                    name: _vm.dataBlock.type + '[name]',
                    style: 'width: 100% !important',
                    value: _vm.dataBlock.data !== undefined && _vm.dataBlock.data.item !== undefined ? _vm.dataBlock.data.item.name : '',
                    type: 'text',
                }}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_popup_rename_' + _vm.dataBlock.type,
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.$emit('emiter')}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_rename_' + _vm.dataBlock.type,
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_rename_' + _vm.dataBlock.type)}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }