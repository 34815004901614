import ClinicData from "@/view/configuration/clinic_data/sections/section_clinic_data.vue"

const routes = [
    {
        path: '/configuration/clinic-data',
        name: 'configuration_clinic_data',
        component: ClinicData,
        props: {showMenu: true}
    },
];
export default routes;