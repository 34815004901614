import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadTableConfigurationCalendarOpen(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getConfigurationCalendarOpen(state){
            await axios.get(
                API_URL + "configuration/calendar/open"
            ).then(
                response => {
                    state.commit('loadTableConfigurationCalendarOpen', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setScheduleData(state, item){
            let request = API_URL + "configuration/calendar/open";
            let formData = new FormData();

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.dispatch('getConfigurationCalendarOpen');
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};