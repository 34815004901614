<template>
    <div>
        <div class="gh-row-content" style="height: 18px;">
            <div>
                <div style="width: 50%" class="no-min-width">
                    <GhDataSelector
                        :datablock="{
                            id: 'label_clinic_type',
                            class: 'T15_b',
                            subtitle: $t('Select a specialty') + ':',
                            text: $t('specialty'),
                            label: $t('specialty'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        }"
                        :datainput="{
                            id: 'clinic_type',
                            name: 'clinic[type]'
                        }"
                        :feed="getFeeds.type_clinic"
                        :selected_input="[]"
                    />
                </div>
                <div style="width: 50%" class="no-min-width">
                    <GhDataSelector
                        :datablock="{
                            id: 'label_clinic_province',
                            class: 'T15_b',
                            subtitle: $t('Select a province') + ':',
                            text: $t('province'),
                            label: $t('province'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        }"
                        :datainput="{
                            id: 'clinic_province',
                            name: 'clinic[province]'
                        }"
                        :feed="getFeeds.province_clinic"
                        :selected_input="[]"
                    />
                </div>
            </div>
        </div>
        <div class="gh-row-content">
            <GhDataSelector
                :datablock="{
                    id: 'label_clinic_city',
                    class: 'T15_b',
                    subtitle: $t('Select a city') + ':',
                    text: $t('city'),
                    label: $t('city'),
                    type: 'checkbox',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;',
                }"
                :datainput="{
                    id: 'clinic_city',
                    name: 'clinic[city]'
                }"
                :feed="getFeeds.city_clinic"
                :selected_input="[]"
            />
        </div>
    </div>
</template>

<script >
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";
    export default {
        name: "form_search_companies",
        components:{
            GhDataSelector,
        },
        computed : {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getFeedTypeClinic', '', {root: true});
            await this.$store.dispatch('getFeedProvinceClinic', '', {root: true});
            await this.$store.dispatch('getFeedCityClinic', '', {root: true});
            this.haveData = true;
        }
    }
</script>

<style>
    #label_clinic_type_selector_label, #label_clinic_city_selector_label{
        width: 150px;
        text-align: end;
    }

    .no-min-width > div{
        min-width: 300px !important;
    }
</style>