<template>
    <div class="float_window" v-if="haveData">
        <GhNoDropSelector
            :datablock="{
                id: 'my_file_data',
                title: $t('personal_data')
            }"
        >
            <template v-slot:content>
                <component v-bind:is="initial_registration"/>
            </template>
        </GhNoDropSelector>

        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="MyFile.section_info[label['type']].show"
                :datablock="{
                    id: 'submits_'+label.id,
                    title: $t(label['name']),
                    second_title: $t(MyFile.section_info[label['type']]['second_text']),
                    num_results: MyFile.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
                <template v-slot:actions v-if="label['type'] == 'clinics'">
                    <GhAction
                        :dataction="{
                            id: 'leave_all',
                            text: $t('leave'),
                            icon: require('../../../assets/gh_new_dessign/denied.svg'),
                        }"
                        @click="leaveAllCenter(itemProp)"
                    />
                </template>
            </GhDropSelector>
        </div>

        <component v-bind:is="popup_leave" :data="{center: center, leave_id: leave_id}"/>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import initial_registration from "@/view/register/template/initial_registration.vue";
    import list_certifications from "@/view/my_file/lists/list_certifications.vue";
    import list_clinics from "@/view/my_file/lists/list_clinics.vue";

    import popup_leave from "@/view/my_file/templates/popup_leave.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_my_file",
        components: {
            GhDropSelector,
            GhNoDropSelector,
            GhAction
        },
        computed : {
            ...mapState(['MyFile'])
        },
        data(){
            return {
                initial_registration: initial_registration,
                data: [
                    {id: 'certifications', name: 'certifications', component: list_certifications, type: 'certifications'},
                    {id: 'clinics', name: 'clinics', component: list_clinics, type: 'clinics'},
                ],
                haveData: false,
                popup_leave: popup_leave,
                center: '',
                leave_id: ''
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getMyFileSections', '', {root: true});
            await this.$store.dispatch('getDataClinicOrProfessionalByToken', '', {root: true});
            await this.$store.dispatch('getMyFileData', {type: 'certifications'}, {root: true});
            await this.$store.dispatch('getMyFileData', {type: 'clinics'}, {root: true});

            this.haveData = true;
        },
        methods: {
            leaveAllCenter(){
              this.center = 'All';
              this.leave_id = '';
              this.Global.openPopUp('leave_confirm');
            }
        }
    }
</script>