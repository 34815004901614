<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab
            :items="$router.options.GhTabsCalendar"
            :dataBlock="{
                id: 'calendar_tab_',
                calldispatch: 'getCalendarTabActive'
            }"
        />
        <component v-bind:is="Calendars.subTabCActive" />
    </div>
</template>

<script>
import GhSubTab from "fe-gh-subtab-lib";
import {mapState} from "vuex";

export default {
    name: 'template_calendar',
    components: {
        GhSubTab
    },
    computed:{
        ...mapState(['Calendars']),
    },
    watch:{
        '$i18n.locale'(){
            this.haveData = false;
            this.Global.changeLanguage();

            var that = this;

            setTimeout(function (){
                that.haveData = true;
            },200);
        }
    },
    data(){
        return{
            haveData: false
        }
    },
    mounted(){
        var that = this;
        setTimeout(function(){
            that.haveData = true;
        },200);
    }
}
</script>