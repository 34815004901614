import CalendarTab from "@/view/calendar/calendar.vue";

import Department from './department/route';
import Speciality from './speciality/route';
import Professional from  './professional/route';
import Group from './group/route';

const route = [
    {
        path: '/calendar',
        name: 'calendar',
        component: CalendarTab,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Department,
    ...Speciality,
    ...Professional,
    ...Group,
]

export default routes;