<template>
    <div class="float_window" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Warnings.section_info[label['type']].show"
                :datablock="{
                    id: 'warnings_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(Warnings.section_info[label['type']]['second_text']),
                    num_results: Warnings.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_start_visit from "@/view/warnings/lists/list_start_visit.vue";
    import list_pre_ops from "@/view/warnings/lists/list_pre_ops.vue";
    import list_surgery from "@/view/warnings/lists/list_surgery.vue";
    import list_anesthesia from "@/view/warnings/lists/list_anesthesia.vue";
    import list_consent_forms from "@/view/warnings/lists/list_consent_forms.vue";
    import list_missing from "@/view/warnings/lists/list_missing.vue";

    export default{
        name: "section_warnings",
        components: {
            GhDropSelector,
        },
        computed : {
            ...mapState(['Warnings'])
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 'start_visit', name: 'start_visit', component: list_start_visit, type: 'start_visit'},
                    {id: 'pre_ops', name: 'pre_ops', component: list_pre_ops, type: 'pre_ops'},
                    {id: 'surgery', name: 'surgery', component: list_surgery, type: 'surgery'},
                    {id: 'anesthesia', name: 'anesthesia', component: list_anesthesia, type: 'anesthesia'},
                    {id: 'consent_forms', name: 'consent_forms', component: list_consent_forms, type: 'consent_forms'},
                    {id: 'missing', name: 'missing', component: list_missing, type: 'missing'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getWarningsSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>