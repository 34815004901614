var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"float_window"},[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.Visits.section_info[label['type']].show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'visits_' + label.id,
                title: _vm.$t(label['id']) + (label['type'] == 'upcoming_patients_by_professional' && _vm.upcomingDataSelected !== '' ? ' - ' + _vm.upcomingDataSelected : ''),
                second_title: _vm.$t(_vm.Visits.section_info[label['type']]['second_text']),
                num_results: _vm.Visits.section_info[label['type']]['result'],
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true},(label['type'] == 'upcoming_patients_by_professional' && _vm.upcomingDataSelected !== '')?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'leave_all',
                        text: _vm.$t('back'),
                        icon: require('../../../assets/svg/back.svg'),
                    }},on:{"click":_vm.backCalendar}})]},proxy:true}:null],null,true)}):_vm._e()],1)}),(_vm.Visits.section_info.show_button_schedule_visit)?_c('div',{staticClass:"centered",staticStyle:{"padding-top":"12px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_schedule_visit',
                text: _vm.$t('schedule_visit')
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_visit_schedule', ''),'schedule')}}})],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }