import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        data: []
    },
    mutations: {
        loadDefaultRegister(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getDefaultRegister(state, item){
            let  requestLogin = API_URL + "register/" + item.type;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadDefaultRegister", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setRegister(state, item){
            let requestLogin = API_URL + "register/" + item.type;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200'){
                        router.push({name: 'login'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};