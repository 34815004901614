<template>
    <div style="margin-top: 8px; white-space: nowrap;" v-if="dataItem.item.config.picture">
        <GhMultiUpload
            :class="'process_multi_upload'"
            :datalabel="{
                text: $t('add_picture'),
                text_popup: $t('picture'),
                input_text: $t('name'),
                textarea_text: $t('description'),
                attach_text: $t('attach_picture'),
                content_warning: '',
                label_required: $t('documents'),
                required: false,
            }"
            :datablock="{
                id: 'upload_' + dataItem.item.config[dataItem.type === 1 ? 'textarea' : dataItem.type === 6 || dataItem.type === 7 ? 'input' : dataItem.type === 8 ? 'selector' : dataItem.type === 9 ? 'table' : dataItem.type === 10 ? 'imported' : dataItem.type == 11 ? 'graphic' : dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_picture',
                name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][picture]',
                accept: 'image/png, image/gif, image/jpeg',
                class: 'T15_b',
                icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg'),
                icon_trash: require('../../../../../assets/gh_new_dessign/trash.svg'),
                icon_download: require('../../../../../assets/gh_new_dessign/descargar.svg'),
                href: dispatchEvidence,
            }"
            :defaultfiles="dataItem.item.data !== undefined ? dataItem.item.data.picture : []"
            :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Picture'"
            @emiter="$parent.$parent.setChangesSection(subprocessId, $parent.$parent.dataSubprocess.id);"
        />
    </div>
</template>

<script>
    import GhMultiUpload from "fe-gh-multi-upload-lib";

    export default {
        name: 'view_attach_picture',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhMultiUpload
        },
        data(){
            return {
                dispatchEvidence: 'easyUpload',
            }
        }
    }
</script>