<template>
    <div style="padding-bottom: 12px;">
        <GhNoDropSelector :datablock="{id: 'group',title: $t('calendar')}">
            <template v-slot:content>
                <component v-bind:is="list_group" :dataBlock="{id: 4}"/>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import list_group from "@/view/calendar/group/lists/list_group.vue";

    export default {
        name: 'section_group',
        components: {
            GhNoDropSelector,
        },
        data(){
            return{
                list_group:list_group
            }
        }
    }
</script>