var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(_vm.$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false')?[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('name') + '*',
                            style:'width: 150px; min-width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'register_' + _vm.$root._route.params.type + '_main_contact_person_name',
                            name: 'register[' + _vm.$root._route.params.type + '][main_contact_person][name]',
                            style: 'min-width: 130px; width: 100%',
                            value: '',
                            type: 'text',
                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.main_contact_person !== undefined ? _vm.DataClinicsProfessionals.data.main_contact_person.name : ''))])]],2),_c('div',{style:(_vm.$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false')?[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('surname') + '*',
                            style:'width: 150px; min-width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'register_' + _vm.$root._route.params.type + '_main_contact_person_surname',
                            name: 'register[' + _vm.$root._route.params.type + '][main_contact_person][surname]',
                            style: 'min-width: 130px; width: 100%',
                            value: '',
                            type: 'text',
                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('surname')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.main_contact_person !== undefined ? _vm.DataClinicsProfessionals.data.main_contact_person.surname : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(_vm.$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false')?[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('email') + '*',
                            style:'width: 150px; min-width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'register_' + _vm.$root._route.params.type + '_main_contact_person_email',
                            name: 'register[' + _vm.$root._route.params.type + '][main_contact_person][email]',
                            style: 'min-width: 130px; width: 100%',
                            value: '',
                            type: 'text',
                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.main_contact_person !== undefined ? _vm.DataClinicsProfessionals.data.main_contact_person.email : ''))])]],2),_c('div',{style:(_vm.$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false')?[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('telephone') + '*',
                            style:'width: 150px; min-width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'register_' + _vm.$root._route.params.type + '_main_contact_person_telephone',
                            name: 'register[' + _vm.$root._route.params.type + '][main_contact_person][telephone]',
                            style: 'min-width: 130px; width: 100%',
                            value: '',
                            type: 'text',
                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.main_contact_person !== undefined ? _vm.DataClinicsProfessionals.data.main_contact_person.telephone : ''))])]],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }