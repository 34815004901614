<template>
    <div class="gh-content-global-info" style="padding-bottom: 15px;">
        <div style="min-width: 970px;" v-if="Patients.data !== undefined && Patients.data.pending_visit !== undefined ? Patients.data.pending_visit.show : false">
            <component :is="info_patient_reason_for_visit"/>
        </div>
        <div style="min-width: 970px; display: flex;">
            <div style="width: 33.33%">
                <component :is="info_patient_general"/>
                <component :is="info_patient_upcoming_and_past_appoinments"/>
                <component :is="info_patient_forms"/>
            </div>
            <div style="width: 66.66%">
                <component :is="info_patient_critical_information"/>
                <component :is="info_patient_active_patient_prescriptions"/>
                <div style="display: flex">
                    <div style="width: 60%">
                        <component :is="info_patient_medical_history"/>
                    </div>
                    <div style="width: 40%">
                        <component :is="info_patient_test_imagining"/>
                        <component :is="info_patient_family_history"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import info_patient_reason_for_visit from "@/view/patients/views/partials/info_patient_reason_for_visit.vue";
    import info_patient_general from "@/view/patients/views/partials/info_patient_general.vue";
    import info_patient_upcoming_and_past_appoinments from "@/view/patients/views/partials/info_patient_upcoming_and_past_appoinments.vue";
    import info_patient_forms from "@/view/patients/views/partials/info_patient_forms.vue";
    import info_patient_critical_information from "@/view/patients/views/partials/info_patient_critical_information.vue";
    import info_patient_active_patient_prescriptions from "@/view/patients/views/partials/info_patient_active_patient_prescriptions.vue";
    import info_patient_medical_history from "@/view/patients/views/partials/info_patient_medical_history.vue";
    import info_patient_test_imagining from "@/view/patients/views/partials/info_patient_test_imagining.vue";
    import info_patient_family_history from "@/view/patients/views/partials/info_patient_family_history.vue";

    export default {
        name: "info_patient",
        computed: {
            ...mapState(['Patients'])
        },
        data(){
            return {
                info_patient_reason_for_visit: info_patient_reason_for_visit,
                info_patient_general: info_patient_general,
                info_patient_upcoming_and_past_appoinments: info_patient_upcoming_and_past_appoinments,
                info_patient_forms: info_patient_forms,
                info_patient_critical_information: info_patient_critical_information,
                info_patient_active_patient_prescriptions: info_patient_active_patient_prescriptions,
                info_patient_medical_history: info_patient_medical_history,
                info_patient_test_imagining: info_patient_test_imagining,
                info_patient_family_history: info_patient_family_history,
                haveData: false,
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.is_surgery !== undefined && this.$parent.$parent.is_surgery){
                await this.$store.dispatch('getDataPatient', {id: this.$root._route.params.id, type: 'surgery'}, {root: true});
            }
            this.haveData = true;
        }
    }
</script>

<style>
    .style_test_imagining{
        white-space: normal;
        width: 50%;
        margin-top: -2px;
        line-height: 0.8;
    }

    .container-element-info {
        display: flex;
        flex: 1 1 0px;
        justify-content: space-between;
    }

    #selectors > div{
        min-width: 70px;
        text-align: center;
    }

    .forms_icon {
        margin-top: 2px;
    }

    #info_patient_upcoming_and_past_appoinments_slide_selector > div > #selectors{
        margin-left: auto;
        margin-right: auto;
    }
</style>