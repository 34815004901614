<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'professional',
        }"
        :header="header"
        :data="SubmitsProfessionals.records_data.length == 0 ? [] : SubmitsProfessionals.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'professional'}),'view_submit')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_validate_professional_' + itemProp.id,
                    text: $t('validate'),
                }"
                @click="Global.deleteConfirm('delete_confirm','validateSubmitProfessional', itemProp.id,{'name': itemProp.name, 'surname': itemProp.surname, 'md_number': itemProp.md_number}, $t('validate_professional'), $t('you_are_preparing_to_validate_the_following_professional'), 'validate')"
            />
            <GhAction
                :dataction="{
                    id: 'action_view_professional_' + itemProp.id,
                    text: $t('view'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'professional'}),'view_submit')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';

    import {mapState} from "vuex";

    export default {
        name: "list_professionals",
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 50%;'},
                    {text: 'surname', field: 'surname', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 50%;'},
                    {text: 'md_number', field: 'md_number', sorting: true, typeSort: 'string', style: 'min-width: 250px; width: 250px;'},
                    {text: 'signup_date', field: 'signup_date', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        computed:{
            ...mapState(['SubmitsProfessionals'])
        },
        components: {
            GhTableCommon,
            GhAction
        },
        async beforeMount(){
            await this.$store.dispatch('getSubmitsProfessionals', '', {root: true});
            this.haveData = true;
        }
    }
</script>
