<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <template v-if="dataItem.item.config.matrix !== undefined && dataItem.item.config.matrix.input !== undefined && dataItem.item.config.matrix.input.length !== 0">
                    <GhTableCommon
                        style="margin-top: 10px;"
                        :extratable="{
                            id: 'table_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem
                        }"
                        :header="dataItem.item.config !== undefined && dataItem.item.config.matrix !== undefined && dataItem.item.config.matrix.header !== undefined ? dataItem.item.config.matrix.header : []"
                        :data="dataTable"
                    />
                    <template v-if="dataItem.item.config.matrix.required">
                        <input type="hidden" required="required" class="required" :label_required="dataItem.item.config.label !== undefined && dataItem.item.config.label.text !== undefined ? dataItem.item.config.label.text : ''" :value="dataTable.length !== 0 ? 1 : 0">
                    </template>
                    <div :class="'grid-container' + (dataItem.item.config.matrix.input.length < 5 ? '-' + dataItem.item.config.matrix.input.length : '')" style="padding-top: 15px;">
                        <div v-for="(item, key) in dataItem.item.config.matrix.input" class="grid-item centered" :key="key">
                            <GhInputTextField
                                :datalabel="{
                                    text: item.label,
                                    style: '',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'input_' + dataItem.item.config.matrix.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item_quantity',
                                    name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item][' + item.id + ']',
                                    style: 'width: 100px',
                                    value: '',
                                    type: 'float',
                                    limitNumMin: item.min !== '' ? item.min : undefined,
                                    limitNumMax: item.max !== '' ? item.max : undefined
                                }"
                                :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item' + key"
                            />
                        </div>
                    </div>
                    <div class="centered" style="padding-top: 15px;">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_data_matrix_information_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem,
                                text: $t('add_data')
                            }"
                            @click="setMatrixData"
                        />
                    </div>
                </template>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTableCommon from "fe-gh-table-common-lib";

    export default {
        name: 'view_matrix_information',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhTableCommon,
            GhInputTextField,
            GhButton
        },
        data(){
            return {
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                dataTable: [],
                newMatrix: []
            }
        },
        mounted(){
            if(this.dataItem.item !== undefined && this.dataItem.item.data !== undefined && this.dataItem.item.data.data !== undefined){
                this.dataTable = this.dataItem.item.data.data;
            }
        },
        methods: {
            setMatrixData(){
                var tempMatrix = [];
                tempMatrix['id'] = Math.floor(Math.random() * 100000000000);
                Object.entries(this.$refs).forEach((ref) => {
                    var key = ref[1][0].datainput.name.split('][item][')[1].split(']')[0];
                    var value = ref[1][0].datainput.value;

                    tempMatrix[key] = value;
                });

                var mergedTable = [];

                mergedTable.push(tempMatrix);

                this.newMatrix.push(tempMatrix);

                this.dataTable = [...this.dataTable, ...mergedTable];

                this.$parent.setChangesSection(this.subprocessId, this.$parent.dataSubprocess.id);
            }
        }
    }
</script>