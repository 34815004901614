<template>
    <div :style=" this.$store.state.SupportHistoric.searchData && this.$store.state.SupportHistoric.searchData != [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            :extratable="{id:'result_client_data'}"
            :data="this.$store.state.SupportHistoric.searchData"
            :header="header"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'support_view_incidence', params: {id:itemProp.id,view:true}}">
                    <GhAction
                        :dataction="{
                            id: 'view_support_pending_requests_groups',
                            text: $t('view'),
                            icon: require('../../../assets/gh_new_dessign/view.svg'),
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'view_support_pending_requests_groups',
                        text: $t('reopen'),
                        icon: '',
                    }"
                    @click="Global.deleteConfirm('delete_confirm','reopenSupportHistorical', itemProp.id,{'assigned_to': itemProp.group_name, 'description': itemProp.description}, $t('reopen_incidence'), $t('reopen_incidence_description'), 'reopen')"
                />
            </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from 'fe-gh-action-lib'
    import {mapState} from "vuex";
    export default {
        name: "list_result_historic",
        computed:{
            ...mapState(['SupportHistoric'])
        },
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                header: [
                    {text: 'identifier', field: 'ticket_reference', contentAlt: 'user', title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 90px; width: 150px;'},
                    {text: 'user', field: 'ticket_created_by', sorting: true,typeSort: 'string', style: 'min-width: 75px; width:10%;'},
                    {text: 'business', field: 'company', sorting: true, typeSort: 'string', style: 'min-width: 75px; width:10%;'},
                    {text: 'description', field: 'description', sorting: true,typeSort: 'string', style: 'max-width: 100px;width:29%;'},
                    {text: 'assigned_to', field: 'group_name', sorting: true,typeSort: 'string', style: 'min-width: 75px; width:10%;'},
                    {text: 'priority', field: 'priority_name', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                    {text: 'fe', field: 'entry_date', sorting: true,typeSort: 'string', style: ' min-width: 40px; max-width: 90px; width:7%;'},
                    {text: 'ua', field: 'updated_date', sorting: true,typeSort: 'string', style: 'min-width: 40px; max-width: 90px; width:7%;'},
                    {text: '', field: 'actions', style: ''}
                ],
            }
        },
    }
</script>
