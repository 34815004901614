<template>
    <GhTableCommon style="margin-top: 10px; margin-bottom: 10px;"
        :extratable="{
            id: 'table_creation_' + actualData.type + '_by_drop_down_' + actualData.parentIndex + '_' + actualData.id,
        }"
        :header="header"
        :data="actualData.data.data"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_creation_' + actualData.type + '_by_drop_down_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow(actualData.type === 'process' ? 'configuration_process_form_edit_form' : 'configuration_forms_item_edit_item', {id: itemProp.id, item_section_id: actualData.id, form_process_id: $root._route.params.id !== undefined ? $root._route.params.id : 0}),'configuration_register_item_form', false,{data: actualData, indexEdit: itemProp})"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_creation_' + actualData.type + '_by_drop_down_' +  itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeItemForm', itemProp.id, {'name': actualData.type === 'process' ? itemProp.form : itemProp.item, 'data': {'data': actualData, 'item': itemProp}}, $t(actualData.type === 'process' ? 'delete_form' : 'delete_item'), $t(actualData.type === 'process' ? 'preparing_delete_form' : 'preparing_delete_item'), 'remove');"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_creation_' + actualData.type + '_by_drop_down_' + actualData.parentIndex + '_' + actualData.id,
                        text: $t('add_' + (actualData.type === 'process' ? 'form' : 'item'))
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow(actualData.type === 'process' ? 'configuration_process_form_new_form' : 'configuration_forms_item_new_item', {}),'configuration_register_item_form', false,{data: actualData})"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'partial_item_form',
        props: ['actualData'],
        components: {
            GhButton,
            GhAction,
            GhTableCommon
        },
        data(){
            return {
                header: [
                    {text: this.actualData.type === 'process' ? 'form' : 'item', field: this.actualData.type === 'process' ? 'form' : 'item', sorting: true, typeSort: 'string', style: 'max-width: 500px; width: 70%'},
                    {text: 'mandatory_dependent', field: 'mandatory_dependent', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 30%'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ]
            }
        }
    }
</script>
