import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadTableValidationsProfessionals(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getValidationsProfessionals(state){
            await axios.get(
                API_URL + "validations/professionals"
            ).then(
                response => {
                    state.commit('loadTableValidationsProfessionals', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async validateValidationsProfessional(state, item){
            let request = API_URL + "validations/professionals/" + item.id + "/validate"
            let formData = new FormData();

            formData.append('professional[id]', item.id);
            formData.append('professional[type]', item.type);

            axios({
                method: 'POST',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getValidationsProfessionals');
                        state.rootState.Validations.section_info.professionals.result = state.state.records_data.length;
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
    }
};