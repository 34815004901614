<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_support_assignment'
        }"
        :header="header"
        :data="ConfigurationSupportAssignment.records_data.length == 0 ? [] : ConfigurationSupportAssignment.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_support_view_assignment', {id: itemProp.id, view: true}),'configuration_support_assignment')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_assignment_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_support_edit_assignment', {id: itemProp.id}),'configuration_support_assignment')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_assignment_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationSupportAssignments', itemProp.id,{'assignment': itemProp.name}, $t('delete_assignment'), $t('preparing_delete_assignment'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_conf_supp_assignment',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_support_new_assignment', {}),'configuration_support_assignment')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "list_assignment",
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigurationSupportAssignment'])
        },
        data(){
            return{
                haveData: false,
                header:[
                    {text: 'assignation_group', field: 'name', urlRedirect: true,sorting: true,typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true,typeSort: 'string', style: 'max-width: 150px; width: 70%; '},
                    {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportAssignments', '', {root: true});
            this.haveData = true;
        }
    }
</script>