import Environment from "@/view/environment/environment.vue";
import EnvironmentAction from "@/view/environment/environment_action.vue";

const routes = [
    {
        path: '/environment',
        name: 'environment',
        component: Environment,
        props: {showMenu: false}
    },
    {
        path: '/environment/:id/actions',
        name: 'environment_id',
        component: EnvironmentAction,
        props: {id: null, showMenu: false},
    }
];
export default routes;