var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTableCommon',{staticStyle:{"margin-top":"12px"},attrs:{"extratable":{
        id: 'table_clinics'
    },"header":_vm.header,"data":_vm.MyFile.data.clinics},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_leave_' + itemProp.id,
                text: _vm.$t('leave'),
                icon: require('../../../assets/gh_new_dessign/denied.svg')
            }},on:{"click":function($event){return _vm.leaveCenter(itemProp)}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_view_' + itemProp.id,
                text: _vm.$t('view'),
                icon: require('../../../assets/gh_new_dessign/view.svg')
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('my_file_clinics', {id: itemProp.id}),'my_file_clinics')}}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }