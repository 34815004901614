<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('patients') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <template v-if="!$root._route.params.view">
                <GhNoDropSelector
                    :datablock="{
                        id: 'view_patient',
                        title: $t('patient')
                    }"
                >
                    <template v-slot:content>
                        <form id="form_patient" v-if="haveData">
                            <div class="gh-content-global">
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('patient_data')}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('mrn') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.mrn : ''}}</label>
                                    <input type="hidden" name="patient[patient][mrn]" :value="Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.mrn : ''"/>
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('ohip') + ' ' + $t('number') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_patient_ohip',
                                            name: 'patient[patient][ohip]',
                                            style: 'width: 200px',
                                            value: Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.ohip : '',
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('driver_license'),
                                            style: 'width: 250px; min-width: 250px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'patient_patient_driver_license',
                                            name: 'patient[patient][driver_license]',
                                            style: 'width: 200px',
                                            value: Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.driver_license : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_patient_name',
                                            name: 'patient[patient][name]',
                                            style: 'width: 300px;',
                                            value: Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.name : '',
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('surname') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_patient_name',
                                            name: 'patient[patient][surname]',
                                            style: 'width: 300px; ',
                                            value: Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.surname : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_patient_patient_gender',
                                            class: 'T15_b',
                                            subtitle: $t('select_gender') + ':',
                                            text: $t('gender') + '*',
                                            label: $t('gender'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'patient_patient_gender',
                                            name: 'patient[patient][gender]'
                                        }"
                                        :feed="getFeeds.genders"
                                        :selected_input="Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.gender : []"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhCalendar
                                        :datalabel="{
                                            id: 'label_patient_patient_date_of_birth',
                                            text: $t('date_of_birth'),
                                            style: 'width: 150px; min-width: 150px; text-align: right;'
                                        }"
                                        :datainput="{
                                            id: 'patient_patient_date_of_birth',
                                            name: 'patient[patient][date_of_birth]',
                                            value: Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.date_of_birth : '',
                                            disabled_date: 'after',
                                            range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                            required: true
                                        }"
                                        ref="RefDateOfBirthday"
                                        @change="calcBirthday('patient')"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 325px; min-width: 325px; text-align: right;">{{$t('age') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{age}}</label>
                                </div>
                                <div class="gh-row-content" style="margin-left: 160px;">
                                    <GhChecks
                                        :datalabel="{
                                            text: $t('dependent')
                                        }"
                                        :datainput="{
                                            required: false,
                                            type: 'checkbox',
                                            id: 'patient_patient_dependent',
                                            value: 1,
                                            name: 'patient[patient][dependent]',
                                            selected: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.dependent == 1 ? true : false,
                                        }"
                                        ref="RefDependent"
                                        @click="selectDependent('RefDependent')"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_patient_patient_nationality',
                                            class: 'T15_b',
                                            subtitle: $t('select_nationality') + ':',
                                            text: $t('nationality'),
                                            label: $t('nationality'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'patient_patient_nationality',
                                            name: 'patient[patient][nationality][]'
                                        }"
                                        :feed="getFeeds.nationalities"
                                        :selected_input="Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.nationality : []"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture')}}</label>
                                    <GhEasyUpload
                                        :extrablock="{
                                            id: 'patient_patient_picture',
                                            class: '',
                                            name_hidden: 'patient[patient][picture]',
                                            id_hidden: 'clinic_data_initial_registration_upload',
                                            icon_attach: ''
                                        }"
                                        :datalabel="{
                                            id: 'label_patient_patient_picture',
                                            style: 'margin-right: -15px'
                                        }"
                                        :datainput="{
                                            view: false,
                                            accept: 'image/png, image/gif, image/jpeg',
                                            text: $t('upload'),
                                            label_required: $t('picture'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            file_name: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.picture !== undefined ? Patients.data.patient.picture.file_name : '',
                                            file_path: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.picture !== undefined ? Patients.data.patient.picture.file_path : '',
                                            file_download: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.picture !== undefined ? Patients.data.patient.picture.file_download : '',
                                            required: true
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_patient_patient_language_spoken',
                                            class: 'T15_b',
                                            subtitle: $t('select_language_spoken') + ':',
                                            text: $t('language_spoken'),
                                            label: $t('language_spoken'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'patient_patient_language_spoken',
                                            name: 'patient[patient][language_spoken][]'
                                        }"
                                        :feed="getFeeds.languages"
                                        :selected_input="Patients.data !== undefined && Patients.data.patient !== undefined ? Patients.data.patient.language_spoken : []"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; text-align: end; margin-right: 10px;">{{$t('interpret_required')}}</label>
                                    <GhChecks
                                        :datalabel="{
                                            text: $t('yes')
                                        }"
                                        :datainput="{
                                            required: false,
                                            type: 'radio',
                                            id: 'patient_patient_interpret_required_yes',
                                            value: 1,
                                            name: 'patient[patient][interpret_required]',
                                            selected: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.interpret_required == 1 ? true : false,
                                        }"
                                    />
                                    <GhChecks
                                        :datalabel="{
                                            text: $t('no'),
                                            style: ''
                                        }"
                                        :datainput="{
                                            required: false,
                                            type: 'radio',
                                            style: 'margin-left: 20px;',
                                            id: 'patient_patient_interpret_required_no',
                                            value: 2,
                                            name: 'patient[patient][interpret_required]',
                                            selected: Patients.data !== undefined && Patients.data.patient !== undefined && Patients.data.patient.interpret_required == 2 ? true : false,
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('emergency_contact')}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_emergency_contact_name',
                                            name: 'patient[emergency_contact][name]',
                                            style: 'width: 300px;',
                                            value: Patients.data !== undefined && Patients.data.emergency_contact !== undefined ? Patients.data.emergency_contact.name : '',
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('surname') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_emergency_contact_name',
                                            name: 'patient[emergency_contact][surname]',
                                            style: 'width: 300px; ',
                                            value: Patients.data !== undefined && Patients.data.emergency_contact !== undefined ? Patients.data.emergency_contact.surname : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('telephone') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_emergency_contact_telephone',
                                            name: 'patient[emergency_contact][home_phone]',
                                            style: 'width: 200px; ',
                                            value: Patients.data !== undefined && Patients.data.emergency_contact !== undefined ? Patients.data.emergency_contact.telephone : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_patient_emergency_contact_relationship',
                                            class: 'T15_b',
                                            subtitle: $t('select_relationship') + ':',
                                            text: $t('relationship'),
                                            label: $t('relationship'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'patient_emergency_contact_relationship',
                                            name: 'patient[emergency_contact][relationship]'
                                        }"
                                        :feed="getFeeds.relationships"
                                        :selected_input="Patients.data !== undefined && Patients.data.emergency_contact !== undefined ? Patients.data.emergency_contact.relationship : []"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('contact_information')}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('mobile_phone') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_contact_informationt_mobile_phone',
                                            name: 'patient[contact_information][mobile_phone]',
                                            style: 'width: 200px; ',
                                            value: Patients.data !== undefined && Patients.data.contact_information !== undefined ? Patients.data.contact_information.mobile_phone : '',
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('home_phone'),
                                            style: 'width: 250px; min-width: 250px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'patient_contact_informationt_home_phone',
                                            name: 'patient[contact_information][home_phone]',
                                            style: 'width: 200px; ',
                                            value: Patients.data !== undefined && Patients.data.contact_information !== undefined ? Patients.data.contact_information.home_phone : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('email') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_contact_information_name',
                                            name: 'patient[contact_information][email]',
                                            style: 'width: 300px;',
                                            value: Patients.data !== undefined && Patients.data.contact_information !== undefined ? Patients.data.contact_information.email : '',
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('social_address') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'patient_contact_information_address',
                                            name: 'patient[contact_information][address]',
                                            style: 'width: 300px; ',
                                            value: Patients.data !== undefined && Patients.data.contact_information !== undefined ? Patients.data.contact_information.address : '',
                                            type: 'text',
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('consent_forms')}}</label>
                                </div>
                                <div class="gh-row-content" style="margin-left: 160px;" v-for="(item, index) in Patients.data.consent_forms" :key="index">
                                    <GhChecks
                                        :datainput="{
                                            id: 'patient_consent_forms_' + item.id,
                                            value: item.id,
                                            type: 'checkbox',
                                            name: 'patient[consent_forms][]',
                                            style: 'cursor:pointer; margin-right: 10px;',
                                            selected: item.selected
                                        }"
                                        :datalabel="{
                                            style: '',
                                            class: 'T15 input_label',
                                            id: 'label_patient_consent_forms_' + item.id,
                                            text: ''
                                        }"
                                    />
                                    <label class="gh_text_field-label T15" style=""><div v-html="item.html"></div></label>
                                </div>
                                <template v-if="is_children">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('main_caregiver')}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('type') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_type',
                                                name: 'patient[main_caregiver][type]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.type : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_name',
                                                name: 'patient[main_caregiver][name]',
                                                style: 'width: 300px;',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.name : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('surname') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_name',
                                                name: 'patient[main_caregiver][surname]',
                                                style: 'width: 300px; ',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.surname : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'label_patient_main_caregiver_gender',
                                                class: 'T15_b',
                                                subtitle: $t('select_gender') + ':',
                                                text: $t('gender') + '*',
                                                label: $t('gender'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: true,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'patient_main_caregiver_gender',
                                                name: 'patient[main_caregiver][gender]'
                                            }"
                                            :feed="getFeeds.genders"
                                            :selected_input="Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.gender : []"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhCalendar
                                            :datalabel="{
                                                id: 'label_patient_main_caregiver_date_of_birth',
                                                text: $t('date_of_birth'),
                                                style: 'width: 150px; min-width: 150px; text-align: right;'
                                            }"
                                            :datainput="{
                                                id: 'patient_main_caregiver_date_of_birth',
                                                name: 'patient[main_caregiver][date_of_birth]',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.date_of_birth : '',
                                                disabled_date: 'after',
                                                range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                required: true
                                            }"
                                            ref="RefDateOfBirthdayMainCaregiver"
                                            @change="calcBirthday('main_caregiver')"
                                        />
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 325px; min-width: 325px; text-align: right;">{{$t('age') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ageCareGiver}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture')}}</label>
                                        <GhEasyUpload
                                            :extrablock="{
                                                id: 'patient_main_caregiver_picture',
                                                class: '',
                                                name_hidden: 'patient[main_caregiver][picture]',
                                                id_hidden: 'patient_main_caregiver_picture_upload',
                                                icon_attach: ''
                                            }"
                                            :datalabel="{
                                                id: 'label_patient_main_caregiver_picture',
                                                style: 'margin-right: -15px'
                                            }"
                                            :datainput="{
                                                view: false,
                                                accept: 'image/png, image/gif, image/jpeg',
                                                text: $t('upload'),
                                                label_required: $t('picture'),
                                                show_image: false,
                                                delete: false,
                                                href: 'easyUpload',
                                                file_name: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.picture.file_name : '',
                                                file_path: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.picture.file_path : '',
                                                file_download: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.picture.file_download : '',
                                                required: true
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('mobile_phone') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_mobile_phone',
                                                name: 'patient[main_caregiver][mobile_phone]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.mobile_phone : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('home_phone'),
                                                style: 'width: 250px; min-width: 250px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'patient_main_caregiver_home_phone',
                                                name: 'patient[main_caregiver][home_phone]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.home_phone : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('email') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_name',
                                                name: 'patient[main_caregiver][email]',
                                                style: 'width: 300px;',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.email : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('social_address') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_main_caregiver_address',
                                                name: 'patient[main_caregiver][address]',
                                                style: 'width: 300px; ',
                                                value: Patients.data !== undefined && Patients.data.main_caregiver !== undefined ? Patients.data.main_caregiver.address : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('secondary_caregiver')}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('type') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_type',
                                                name: 'patient[secondary_caregiver][type]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.type : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_name',
                                                name: 'patient[secondary_caregiver][name]',
                                                style: 'width: 300px;',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.name : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('surname') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_name',
                                                name: 'patient[secondary_caregiver][surname]',
                                                style: 'width: 300px; ',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.surname : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'label_patient_secondary_caregiver_gender',
                                                class: 'T15_b',
                                                subtitle: $t('select_gender') + ':',
                                                text: $t('gender') + '*',
                                                label: $t('gender'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: true,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'patient_secondary_caregiver_gender',
                                                name: 'patient[secondary_caregiver][gender]'
                                            }"
                                            :feed="getFeeds.genders"
                                            :selected_input="Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.gender : []"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhCalendar
                                            :datalabel="{
                                                id: 'label_patient_secondary_caregiver_date_of_birth',
                                                text: $t('date_of_birth'),
                                                style: 'width: 150px; min-width: 150px; text-align: right;'
                                            }"
                                            :datainput="{
                                                id: 'patient_secondary_caregiver_date_of_birth',
                                                name: 'patient[secondary_caregiver][date_of_birth]',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.date_of_birth : '',
                                                disabled_date: 'after',
                                                range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                required: true
                                            }"
                                            ref="RefDateOfBirthdaySecondaryCaregiver"
                                            @change="calcBirthday('secondary_caregiver')"
                                        />
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 325px; min-width: 325px; text-align: right;">{{$t('age') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ageSecondCareGiver}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture')}}</label>
                                        <GhEasyUpload
                                            :extrablock="{
                                                id: 'patient_secondary_caregiver_picture',
                                                class: '',
                                                name_hidden: 'patient[secondary_caregiver][picture]',
                                                id_hidden: 'patient_secondary_caregiver_picture_upload',
                                                icon_attach: ''
                                            }"
                                            :datalabel="{
                                                id: 'label_patient_secondary_caregiver_picture',
                                                style: 'margin-right: -15px'
                                            }"
                                            :datainput="{
                                                view: false,
                                                accept: 'image/png, image/gif, image/jpeg',
                                                text: $t('upload'),
                                                label_required: $t('picture'),
                                                show_image: false,
                                                delete: false,
                                                href: 'easyUpload',
                                                file_name: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.picture.file_name : '',
                                                file_path: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.picture.file_path : '',
                                                file_download: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.picture.file_download : '',
                                                required: true
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('mobile_phone') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_mobile_phone',
                                                name: 'patient[secondary_caregiver][mobile_phone]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.mobile_phone : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('home_phone'),
                                                style: 'width: 250px; min-width: 250px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'patient_secondary_caregiver_home_phone',
                                                name: 'patient[secondary_caregiver][home_phone]',
                                                style: 'width: 200px; ',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.home_phone : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('email') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_name',
                                                name: 'patient[secondary_caregiver][email]',
                                                style: 'width: 300px;',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.email : '',
                                                type: 'text',
                                            }"
                                        />
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('social_address') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'patient_secondary_caregiver_address',
                                                name: 'patient[secondary_caregiver][address]',
                                                style: 'width: 300px; ',
                                                value: Patients.data !== undefined && Patients.data.secondary_caregiver !== undefined ? Patients.data.secondary_caregiver.address : '',
                                                type: 'text',
                                            }"
                                        />
                                    </div>
                                </template>
                            </div>
                        </form>
                    </template>
                </GhNoDropSelector>
                <div class="centered" style="margin-top: 10px; padding-bottom: 10px;">
                    <GhButton
                        :datainput="{
                            id: 'btn_accept',
                            text: $t('accept'),
                        }"
                        @click="submitPatient();"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_close',
                            text: $t('close'),
                        }"
                        @click="Global.windowClose()"
                    />
                </div>
            </template>
            <template v-else>
                <component :is="info_patient" v-if="haveData"/>
            </template>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    import info_patient from "@/view/patients/views/partials/info_patient.vue";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_patient",
        components: {
            GhNoDropSelector,
            GhLocation,
            GhInputTextField,
            GhCalendar,
            GhDataSelector,
            GhEasyUpload,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'Patients'])
        },
        data(){
            return {
                type: '',
                haveData: false,
                age: '',
                ageCareGiver: '',
                ageSecondCareGiver: '',
                is_children: false,
                info_patient: info_patient,
                actualHeight: 150
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedGenders', '', {root:true});
                await this.$store.dispatch('getFeedNationalities', '', {root:true});
                await this.$store.dispatch('getFeedLanguages', '', {root:true});
                await this.$store.dispatch('getFeedRelationships', '', {root:true});
                await this.$store.dispatch('getDataPatient', {id: this.$root._route.params.id}, {root: true});
            }else{
                await this.$store.dispatch('getDataPatient', {id: this.$root._route.params.id, view: true}, {root: true});
            }

            this.type = this.$root._route.name.split('_patient')[0];

            if(this.Patients.data !== undefined && this.Patients.data.patient !== undefined && this.Patients.data.patient.date_of_birth){
                this.calcBirthday('patient');
            }

            if(this.Patients.data !== undefined && this.Patients.data.main_caregiver !== undefined && this.Patients.data.main_caregiver.date_of_birth){
                this.calcBirthday('main_caregiver');
            }

            if(this.Patients.data !== undefined && this.Patients.data.secondary_caregiver !== undefined && this.Patients.data.secondary_caregiver.date_of_birth){
                this.calcBirthday('secondary_caregiver');
            }

            this.haveData = true;
        },
        updated() {
            this.is_children = this.Patients.data !== undefined && this.Patients.data.patient !== undefined && this.Patients.data.patient.dependent == 1 ? true : false;
        },
        methods: {
            calcBirthday(type){
                let completeDateOfBirthday = new Date();

                var date = '';
                var spliter = '';

                switch(type){
                    case 'patient':
                        if(this.$refs.RefDateOfBirthday === undefined){
                            spliter = this.Patients.data.patient.date_of_birth.split('/');
                            date = spliter[1] + '/' + spliter[0] + '/' + spliter[2];
                        }

                        completeDateOfBirthday = new Date(this.$refs.RefDateOfBirthday !== undefined ? this.$refs.RefDateOfBirthday.value1 : date);
                        break;
                    case 'main_caregiver':
                        if(this.$refs.RefDateOfBirthdayMainCaregiver === undefined){
                            spliter = this.Patients.data.main_caregiver.date_of_birth.split('/');
                            date = spliter[1] + '/' + spliter[0] + '/' + spliter[2];
                        }

                        completeDateOfBirthday = new Date(this.$refs.RefDateOfBirthdayMainCaregiver !== undefined ? this.$refs.RefDateOfBirthdayMainCaregiver.value1 : date);
                        break;
                    case 'secondary_caregiver':
                        if(this.$refs.RefDateOfBirthdaySecondaryCaregiver === undefined){
                            spliter = this.Patients.data.secondary_caregiver.date_of_birth.split('/');
                            date = spliter[1] + '/' + spliter[0] + '/' + spliter[2];
                        }

                        completeDateOfBirthday = new Date(this.$refs.RefDateOfBirthdaySecondaryCaregiver !== undefined ? this.$refs.RefDateOfBirthdaySecondaryCaregiver.value1 : date);
                        break;
                }

                let completeDateToday = new Date();

                var ynew = completeDateToday.getFullYear();
                var mnew = completeDateToday.getMonth();
                var dnew = completeDateToday.getDate();
                var yold = completeDateOfBirthday.getFullYear();
                var mold = completeDateOfBirthday.getMonth();
                var dold = completeDateOfBirthday.getDate();
                var diff = ynew - yold;

                if(mold > mnew){
                    diff--;
                }else{
                    if(mold == mnew){
                        if(dold > dnew){
                            diff--;
                        }
                    }
                }

                var monthDiff = Math.abs(mnew - mold + (12 * (ynew - yold)));

                switch(type){
                    case 'patient':
                        if(monthDiff <= 11){
                            this.age = monthDiff + ' ' + this.$i18n.t('months');
                        }else{
                            this.age = diff + ' ' + this.$i18n.t('age');
                        }

                        this.is_children = diff >= 18 ? false : true;
                        break;
                    case 'main_caregiver':
                        if(monthDiff <= 11){
                            this.ageCareGiver = monthDiff + ' ' + this.$i18n.t('months');
                        }else{
                            this.ageCareGiver = diff + ' ' + this.$i18n.t('age');
                        }
                        break;
                    case 'secondary_caregiver':
                        if(monthDiff <= 11){
                            this.ageSecondCareGiver = monthDiff + ' ' + this.$i18n.t('months');
                        }else{
                            this.ageSecondCareGiver = diff + ' ' + this.$i18n.t('age');
                        }
                        break;
                }
            },
            selectDependent(value) {
                var age = this.age.split(' ')[0];
                if(age >= 18) {
                    this.is_children = this.$refs[value].$el.children[0].children[1].checked ? true : false;
                }else{
                    if(this.age.length == 0){
                        this.is_children = this.$refs[value].$el.children[0].children[1].checked ? true : false;
                    }else{
                        if(age < 18){
                            this.is_children = true;
                        }
                    }
                }
            },
            async submitPatient(){
                if(this.Global.checkRequired('form_patient') == 'OK'){
                    const formData = new FormData(document.getElementById('form_patient'));
                    await this.$store.dispatch('setPatient', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true});

                  this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    #label_patient_patient_nationality_selector_label, #label_patient_patient_language_spoken_selector_label, #label_patient_emergency_contact_relationship_selector_label, #label_patient_patient_gender_selector_label, #label_patient_main_caregiver_gender_selector_label, #label_patient_secondary_caregiver_gender_selector_label{
        width: 150px;
        text-align: end;
    }
</style>