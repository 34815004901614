<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{showButton: true, secondWindow: true, showLoader: 'OK', text: $t('professional')}"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <div v-for="(label, labelIndex) in data" :key="labelIndex">
                <GhNoDropSelector
                    :datablock="{
                        id: 'professional_' + label.id,
                        title: $t(label['name']),
                        second_title: '',
                        num_results: '',
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component" />
                    </template>
                </GhNoDropSelector>
            </div>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import initial_registration from "@/view/register/template/initial_registration.vue";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: 'sections_professional_view',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhButton
        },
        data(){
            return{
                data: [
                    {id: 1, name: 'initial_registration', component: initial_registration},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getDataClinicOrProfessionalById', {id: this.$root._route.params.id, type: this.$root._route.params.type, extra_type: 'validations'}, {root: true});
            this.haveData = true;
        }
    }
</script>