var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"float_window",staticStyle:{"padding-bottom":"12px"}},[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.Support.section_info[label['type']].show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'configuration_variable_' + label.id,
                title: _vm.$t(label['name']),
                second_title: _vm.$t(_vm.Support.section_info[label['type']]['second_text']),
                num_results: _vm.Support.section_info[label['type']]['result'],
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true},(label.actions)?{key:"actions",fn:function(){return _vm._l((label.actions),function(action){return _c('GhAction',{key:action.id,attrs:{"dataction":{
                        id: action.id,
                        class: 'T15_action',
                        text: _vm.$t(action.name)
                    }},on:{"click":function($event){action.id == 'export_monthly_support_indicator_data' ? _vm.exportTypeExcel(1) : '';  action.id == 'export_annual_support_indicator_data' ? _vm.exportTypeExcel(0) : ''}}})})},proxy:true}:null],null,true)}):_vm._e()],1)}),(_vm.SupportHistoric && _vm.SupportHistoric.haveResult==true)?_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'result_search_historical',
            title: typeof _vm.SupportHistoric.resultTitle == 'number' ? _vm.SupportHistoric.resultTitle + ' ' + _vm.$t('results') : _vm.$t(_vm.SupportHistoric.resultTitle),
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.list_result_historic,{tag:"component"})]},proxy:true}],null,false,2224102198)}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }