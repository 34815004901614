<template>
    <div class="content_page" style="padding-top: 12px;">
        <GhNoDropSelector
            :datablock="{
                id: 'drop_section_register_info',
                title: $t('registration_accreditation'),
            }"
        >
            <template v-slot:content>
                <form id="form_acreditation" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('type_of_client') + '*'}}</label>
                            <GhChecks
                                :datalabel="{}"
                                :datainput="{
                                    required: true,
                                    label_required: $t('type_of_client') + '*',
                                    type: 'radio',
                                    id: 'acreditation_type_of_client',
                                    value: 1,
                                    name: 'acreditation[type_of_client]',
                                    selected: '',
                                }"
                            />
                            <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 25px;">{{$t('clinic')}}</label>
                            <GhChecks
                                :datalabel="{}"
                                :datainput="{
                                    required: true,
                                    label_required: $t('type_of_client') + '*',
                                    type: 'radio',
                                    id: 'acreditation_type_of_client',
                                    value: 2,
                                    name: 'acreditation[type_of_client]',
                                    selected: '',
                                }"
                            />
                            <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('professional')}}</label>
                        </div>
                        <div>
                            <p class="T15">{{$t('label_acreditation_0')}}</p>
                        </div>
                        <br>
                        <div>
                            <p class="T15">{{$t('label_acreditation_1')}}</p>
                        </div>
                        <br>
                        <div>
                            <p class="T15">{{'Montreal Children\'s Hospital (MCH)'}}</p>
                        </div>
                        <div>
                            <p class="T15_b">{{'info@globalholdings2000.es'}}</p>
                        </div>
                        <div>
                            <p class="T15_b">{{$t('tel') + ': 935 950 764'}}</p>
                        </div>
                        <br>
                        <div>
                            <p class="T15">{{$t('legal_advice_2').toUpperCase() + ': ' + $t('label_acreditation_2')}}</p>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{id: 'btn_back',text: $t('request_registration'),class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="register();"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "pre_register",
        components:{
            GhNoDropSelector,
            GhChecks,
            GhButton
        },
        computed:{
            ...mapState(['Login'])
        },
        data(){
            return {
                haveData: false,
                acreditation: []
            }
        },
        async beforeMount(){
            this.haveData = true;
        },
        methods:{
            register(){
                if(this.Global.checkRequired('form_acreditation') == 'OK'){
                    const formData = new FormData(document.getElementById('form_acreditation'));
                    const type = [...formData][0][1] == 1 ? 'clinic' : 'professional';
                    this.$store.dispatch('redirectTypeRegister', {type: type},{root:true});
                }
            },
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
        }
    }
</script>

<style>
.t13  {
    font-family : 'IBMPlexSans-Regular';
    font-size : 14px;
    line-height : 16px;
    text-transform : uppercase;
    color : #ffffff;
}

.t13:hover  {
    font-family : 'IBMPlexSans-Regular';
    font-size : 14px;
    line-height : 16px;
    text-transform : uppercase;
    color : black;
}
</style>