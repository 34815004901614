<template>
    <div id="option_to_select_free_text">
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('description') + '*',
                        style: 'width: 150px; min-width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: $parent.$parent.type_item + '_free_text_name',
                        name: $parent.$parent.type_item + '[free_text][description]',
                        style: 'min-width: 300px; width: 300px',
                        value: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.free_text !== undefined ? $parent.$parent.stateData.data.free_text.description : '',
                        type: 'text',
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.free_text !== undefined ? $parent.$parent.stateData.data.free_text.description : ''}}</label>
            </template>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_free_text_associate_to',
                    value: 1,
                    name: $parent.$parent.type_item + '[free_text][associate_to]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.free_text !== undefined ? $parent.$parent.stateData.data.free_text.associate_to : false,
                }"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('enhansed')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_free_text_enhansed',
                    value: 1,
                    name: $parent.$parent.type_item + '[free_text][enhansed]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.free_text !== undefined ? $parent.$parent.stateData.data.free_text.enhansed : false,
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: "view_free_text",
        components: {
            GhInputTextField,
            GhChecks
        }
    }
</script>