var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},[(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.required_documents !== undefined)?_vm._l((_vm.DataClinicsProfessionals.data.required_documents.documents),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[_c('label',{class:_vm.$root._route.params.type == 'professional-second-phase' ? 'gh_text_field-label T15_b' : 'gh_text_field-label T15',staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(item.name_of_specialty + (_vm.$root._route.params.type == 'professional-second-phase' ? '*' : ':')))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'register_' + _vm.$root._route.params.type + '_required_documents_documents_' + index,
                    class: '',
                    name_hidden: 'register[' + _vm.$root._route.params.type + '][required_documents][documents][' + index + ']',
                    id_hidden: 'register_' + _vm.$root._route.params.type + '_required_documents_documents_' + index,
                    icon_attach: ''
                },"datalabel":{
                    id: 'label_register_' + _vm.$root._route.params.type + '_required_documents_documents_' + index,
                    style: 'margin-right: -15px'
                },"datainput":{
                    view: _vm.$root._route.params.view,
                    accept: '',
                    text: _vm.$t('upload'),
                    label_required: item.name_of_specialty + '*',
                    show_image: false,
                    delete: true,
                    href: 'easyUpload',
                    file_name: item.file_name !== undefined ? item.file_name : '',
                    file_path: item.file_path !== undefined ? item.file_path : '',
                    file_download: item.file_download !== undefined ? item.file_download : '',
                    required: true
                }}})],1)}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }