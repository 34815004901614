import CategoryProcess from "@/view/configuration/form_process/shared/view_creation.vue";

const routes = [
    {
        path: '/configuration/process/creation-process',
        name: 'configuration_process_new_creation_process',
        component: CategoryProcess,
        props: {id: null, showMenu: true, background: true, otherTitle: 'creation_process'},
        children: [
            {
                path: '/configuration/process/creation-process/:id',
                name: 'configuration_process_edit_creation_process',
                component: CategoryProcess,
                props: {id: null, showMenu: true, background: true, otherTitle: 'creation_process'},
            },
            {
                path: '/configuration/process/creation-process/:id/:validate',
                name: 'configuration_process_validate_creation_process',
                component: CategoryProcess,
                props: {id: null, validate: true, showMenu: true, background: true, otherTitle: 'validation_process'},
            },
            {
                path: '/configuration/process/creation-process/:id/:view',
                name: 'configuration_process_view_creation_process',
                component: CategoryProcess,
                props: {id: null, view: true, showMenu: true, background: true, otherTitle: 'creation_process'},
            }
        ],
    }
];

export default routes;