var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"12px"}},[(_vm.haveProcess)?_c('GhSubTab',{key:'S' + _vm.refreshSubTab,attrs:{"items":_vm.$router.options.GhTabsProcess,"dataBlock":{
            id: 'process_tab_',
            calldispatch: 'setProcessActiveTab'
        }}}):_vm._e(),_c(_vm.Process.sections !== undefined && _vm.Process.sections.component !== undefined && _vm.Process.sections.component.component !== undefined ? _vm.Process.sections.component.component : '',{key:'C'+_vm.refreshSection,tag:"component",attrs:{"dataProcess":_vm.Process.sections,"processId":_vm.$root._route.params.process_id}}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'pending_save',
            title: _vm.$t('warning'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: _vm.$t('Tienes formularios pendientes de guardar, si aceptas este mensaje se eliminaran los datos que has introducido'),
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button_accept_pending_save',
                    text: _vm.$t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.$store.dispatch('acceptProcessActiveTab', '', {root: true})}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'button_close_pending_save',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.$store.dispatch('cancelProcessActiveTab', '', {root: true})}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }