<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'haveData' ? 'OK' : '',
                text: $route.params.name + ' ' + $t('date').toLowerCase() + ' - ' + $route.params.date
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'speciality_data',
                    title: $route.params.name
                }"
            >
                <template v-slot:content v-if="haveData">
                    <GhTableCommon
                        :extratable="{
                            id: 'table_speciality'
                        }"
                        :header="header"
                        :data="CalendarDataSpeciality.speciality_data"
                        style="margin-top: 12px;"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <div class="table-cell-colored" :style="'background-color:' + colors[itemProp[labelProp]]"></div>
                        </template>
                    </GhTableCommon>
                </template>
            </GhNoDropSelector>
        </div>
        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: 'view_speciality',
        components: {
            GhButton,
            GhTableCommon,
            GhNoDropSelector,
            GhLocation
        },
        computed:{
            ...mapState(['CalendarDataSpeciality']),
        },
        data(){
            return{
                haveData: false,
                colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'},
                header: [
                    {text: 'professional', field: 'full_name', style: 'max-width: 100px;width: 100px;'},
                    {text: '00', field: '0', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '01', field: '1', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '02', field: '2', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '03', field: '3', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '04', field: '4', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '05', field: '5', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '06', field: '6', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '07', field: '7', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '08', field: '8', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '09', field: '9', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '10', field: '10', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '11', field: '11', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '12', field: '12', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '13', field: '13', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '14', field: '14', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '15', field: '15', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '16', field: '16', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '17', field: '17', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '18', field: '18', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '19', field: '19', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '20', field: '20', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '21', field: '21', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '22', field: '22', urlRedirect: true, style: 'max-width: 20px;'},
                    {text: '23', field: '23', urlRedirect: true, style: 'max-width: 20px;'},
                ]
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getDataSpeciality', {id:this.$route.params.id}, {root: true});
            this.haveData = true;
        }
    }

</script>