import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        section_info: '',
    },
    mutations: {
        loadConfigurationProfessionalsInfo(state, payload) {
            state.section_info = payload;
        },
        loadDataConfigurationVariableProfessionalTypes(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationProfessionalsSections(state){
            await axios.get(
                API_URL + "configuration/professionals"
            ).then(
                response => {
                    state.commit('loadConfigurationProfessionalsInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationProfessional(state, item){
            await axios.get(
                API_URL + "configuration/professionals/professional/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationVariableProfessionalTypes', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationProfessional(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/professionals/professional/" + item.id : API_URL + "configuration/professionals/professional";
            let formData = new FormData();

            formData.append('professional[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            return await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        return 'OK';
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationProfessionals(state, item){
            let requestLogin = API_URL + "configuration/professionals/professional/" + item.id + "/remove";
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationProfessionalsActive');
                        await this.dispatch('getConfigurationProfessionalsNotAvailable');
                        await this.dispatch('getConfigurationProfessionalsPast');
                        await this.dispatch('getConfigurationProfessionalsPending');
                        await state.dispatch('getConfigurationProfessionalsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async changeStateConfigurationProfessionals(state, item){
            let requestLogin = API_URL + "configuration/professionals/professional/" + item.id + "/" + item.type;
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationProfessionalsActive');
                        await this.dispatch('getConfigurationProfessionalsNotAvailable');
                        await this.dispatch('getConfigurationProfessionalsPast');
                        await this.dispatch('getConfigurationProfessionalsPending');
                        await state.dispatch('getConfigurationProfessionalsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanConfigurationProfessionals(state){
            state.commit('loadDataConfigurationVariableProfessionalTypes', []);
        }
    }
};