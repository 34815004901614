<template>
    <GhInfoBox
        style="margin-top: 6px; margin-left: 3px;"
        :dataBlock="{
            id: 'info_patient_critical_information',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 10, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'height',
        }"
        :dataTitle="{
            show: true,
            text: 'critical_information',
            image: require('../../../../assets/svg/alert.svg'),
            styleBlock: '',
            textStyle:{
                class: 'T20B',
            },
            imageStyle:{
                width: '30px',
                height: '30px',
            },
        }"
        :dataExpansible="{
            show: false,
        }"
    >
        <template v-slot:content>
            <div style="padding-top: 15px; padding-bottom: 15px;">
                <div style="display: flex;">
                    <div style="width: 40%">
                        <label class="gh_text_field-label T16_b" style="margin-right: 10px;">{{$t('allergies')}}</label>
                        <template v-if="Patients.data.critical_information.allergies !== undefined && Patients.data.critical_information.allergies.length != 0">
                            <div class="gh-row-content-info" v-for="(item, index) in Patients.data.critical_information.allergies" :key="index" style="display: grid">
                                <label class="gh_text_field-label T15_b" style="margin-left: 5px; color: red">{{'• ' + item.name}}</label>
                                <template v-if="index.description !== ''">
                                    <br>
                                    <label class="gh_text_field-label T15" style="margin-left: 25px; margin-top: 2px; margin-bottom: 5px;" >{{item.description}}</label>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="gh-row-content-info">
                                <label class="gh_text_field-label T15_b" style="margin-left: 5px; color: green">{{$t('no_allergies')}}</label>
                            </div>
                        </template>
                    </div>
                    <div style="width: 60%">
                        <label class="gh_text_field-label T16_b" style="margin-right: 10px;">{{$t('ongoing_critical')}}</label>
                        <template v-if="Patients.data.critical_information.ongoing_critical !== undefined && Patients.data.critical_information.ongoing_critical.length != 0">
                            <div v-for="(item, index) in Patients.data.critical_information.ongoing_critical" :key="index" style="display: grid">
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px; color: red">{{'• ' + item.name}}</label>
                                <template v-if="index.description !== ''">
                                    <label class="gh_text_field-label T15" style="margin-left: 30px; margin-top: 2px; margin-bottom: 5px;">{{item.description}}</label>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="gh-row-content-info">
                                <label class="gh_text_field-label T15_b" style="margin-left: 5px; color: green">{{$t('no_ongoing_critical')}}</label>
                            </div>
                        </template>
                    </div>
                </div>
                <div style="padding-top: 15px;">
                    <label class="gh_text_field-label T16_b" style="margin-right: 10px;">{{$t('past_critical')}}</label>
                    <template v-if="Patients.data.critical_information.past_critical !== undefined && Patients.data.critical_information.past_critical.length != 0">
                        <div class="gh-row-content-info" v-for="(item, index) in Patients.data.critical_information.past_critical" :key="index" style="display: grid">
                            <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{'• ' + item.name}}</label>
                            <template v-if="index.description !== ''">
                                <br>
                                <label class="gh_text_field-label T15" style="margin-left: 25px; margin-top: 2px; margin-bottom: 5px;" >{{item.description}}</label>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="gh-row-content-info">
                            <label class="gh_text_field-label T15_b" style="margin-left: 5px; color: green">{{$t('no_past_critical')}}</label>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_critical_information",
        components: {
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>