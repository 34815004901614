import GhContentSearchView from "fe-gh-content-window-search-view-lib";
import Formsearchcompany from "@/view/employees/view/search_employees.vue"
import Formsearchclinic from "@/view/float_search/views/view_search_clinics.vue";

const routes = [
    {
        path: '/employees/search/:idblock',
        name: 'GhContentWindowSearchView',
        props: {showMenu: false, background: true},
        component: GhContentSearchView
    },
    {
        path: '/Formsearchcompany',
        name: 'Formsearchcompany',
        component: Formsearchcompany,
        props: {showMenu: true}
    },
    {
        path: '/Formsearchclinic',
        name: 'Formsearchclinic',
        component: Formsearchclinic,
        props: {showMenu: true}
    },
];

export default routes;
