<template>
    <div v-show="haveData" style="padding-bottom: 12px;">
        <div class="gh-content-global">
            <div v-for="(item,index) in CalendarDepartments.department_array" :key="index" style="margin-bottom: 10px;" @click="refreshView(item.id)">
                <GhDropDownField
                    :dataBlock="{
                        id: 'drop_field_department_' + item.id,
                        title: item.name,
                    }"
                >
                    <template v-slot:content>
                            <component :ref="'ref_schedule_'+item.id" v-bind:is="calendar_department" :dataBlock="{idTab: dataBlock.id,id:item.id,name:item.name}"/>
                    </template>
                </GhDropDownField>
            </div>
        </div>
    </div>
</template>

<script>
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import calendar_department from "@/view/calendar/department/views/department_calendar.vue";
    import {mapState} from "vuex";
    export default {
        name: 'list_department',
        props:['dataBlock'],
        components: {
            GhDropDownField,
        },
        computed:{
            ...mapState(['CalendarDepartments']),
        },
        data(){
            return{
                haveData: false,
                calendar_department: calendar_department,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getDepartments', '', {root: true});
            this.haveData = true;
        },
        methods:{
            refreshView(id){
                this.$refs['ref_schedule_'+id][0].refresh++;
                var that = this;
                setTimeout(function(){
                    that.$refs['ref_schedule_'+id][0].resizeSchedule();
                },100);
            }
        }
    }
</script>