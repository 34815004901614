<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_category_process',
                    title: $t('category_process')
                }"
            >
                <template v-slot:content>
                    <form id="form_category_process" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'category_process_name',
                                            name: 'category_process[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationProcessCategory.data !== undefined ? ConfigurationProcessCategory.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProcessCategory.data !== undefined ? ConfigurationProcessCategory.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description') + '*',
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'category_process_description',
                                            name: 'category_process[description]',
                                            required: true,
                                            value: ConfigurationProcessCategory.data !== undefined ? ConfigurationProcessCategory.data.description : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProcessCategory.data !== undefined ? ConfigurationProcessCategory.data.description : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="!this.$root._route.params.view"
                          :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                          @click="submitCategoryProcess();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhButton from "fe-gh-button-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: 'view_category_process',
        components: {
            GhButton,
            GhTextarea,
            GhInputTextField,
            GhNoDropSelector,
            GhLocation
        },
        computed:{
            ...mapState(['ConfigurationProcessCategory']),
        },
        data(){
            return {
                haveData: false,
                type: ''
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_process_')[1].split('_')[0];

            if(this.type === 'view' || this.type === 'edit') {
                await this.$store.dispatch('getDataConfigurationCategoryProcess', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationProcessCategory.data = [];
            }

            this.haveData = true;
        },
        methods: {
            async submitCategoryProcess(){
                if(this.Global.checkRequired('form_category_process') == 'OK'){
                    const formData = new FormData(document.getElementById('form_category_process'));
                    await this.$store.dispatch('setDataConfigurationCategoryProcess', {
                        id: this.$root._route.params.id,
                        formData: [...formData]
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>