import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        specialties: [],
        clinic_specialties: [],
        departments: [],
        room: [],
        required_maintenance: [],
        equipment: [],
        variables: [],
        professional_type: [],
        procedure: [],
        result_search_data_groups:[],
        result_md_by_center:[],
        nationalities:[],
        relationships: [],
        languages:[],
        genders:[],
        type_of_visits:[],
        options_to_select:[],
        item_groups: [],
        items: [],
        all_items: [],
        forms: [],
        periods: [],
        quantities: [],
        picture: [],
        selected: [],
        tables: [],
        structure: [],
        type_ddbb: [],
        categories: [],
        feed_supp_groups: [],
        feed_support_categories: [],
        feed_support_priority: [],
        feed_support_asigned: [],
        feed_perms: [],
        feed_profiles: [],
        feed_groups: [],
        doctors: [],
        clinics: [],
        age_conditions: [],
        genre: [],
        conditions: [],
        type_clinic: [],
        province_clinic: [],
        city_clinic: [],
        type_equipments: [],
    },
    mutations: {
        loadResultSearchClinics(state, payload){
            state.result_search_data_groups = payload;
        },
        loadFeedSpecialties(state, payload){
            state.specialties = payload;
        },
        loadFeedClinicSpecialties(state, payload){
            state.clinic_specialties = payload;
        },
        loadFeedDepartments(state, payload){
            state.departments = payload;
        },
        loadFeedRoom(state, payload){
            state.room = payload;
        },
        loadFeedRequiredMaintenance(state, payload){
            state.required_maintenance = payload;
        },
        loadFeedEquipment(state, payload){
            state.equipment = payload;
        },
        loadFeedVariables(state, payload){
            state.variables = payload;
        },
        loadFeedProcedure(state, payload){
            state.procedure = payload;
        },
        loadFeedProfessionalType(state, payload){
            state.professional_type = payload;
        },
        loadFeedNationalities(state, payload){
            state.nationalities = payload;
        },
        loadFeedRelationships(state, payload){
            state.relationships = payload;
        },
        loadFeedLanguages(state, payload){
            state.languages = payload;
        },
        loadFeedGenders(state, payload){
            state.genders = payload;
        },
        loadFeedTypeOfVisits(state, payload){
            state.type_of_visits = payload;
        },
        loadFeedOptionsToSelect(state, payload){
            state.options_to_select = payload;
        },
        loadFeedItemGroups(state, payload){
            state.item_groups = payload;
        },
        loadFeedItems(state, payload){
            state.items = payload;
        },
        loadFeedAllItems(state, payload){
            state.all_items = payload;
        },
        loadFeedForms(state, payload){
            state.forms = payload;
        },
        loadFeedPeriods(state, payload){
            state.periods = payload;
        },
        loadFeedQuantities(state, payload){
            state.quantities = payload;
        },
        loadFeedPictures(state, payload){
            state.pictures = payload;
        },
        loadFeedTables(state, payload){
            state.tables = payload;
        },
        loadResultSearchGroups(state, payload){
            state.result_search_data_groups = payload;
        },
        loadResultMDByCenter(state, payload){
            state.result_md_by_center = payload;
        },
        loadFeedSelected(state, payload){
            state.selected = payload;
        },
        loadStructureSelected(state, payload){
            state.structure = payload;
        },
        loadTypeDDBB(state, payload){
            state.type_ddbb = payload;
        },
        loadTypeCategories(state, payload){
            state.categories = payload;
        },
        loadSupportGroups(state, payload){
            state.feed_supp_groups = payload;
        },
        loadFeedSupportCategory(state,payload){
            state.feed_support_categories = payload
        },
        loadFeedSupportPriority(state,payload){
            state.feed_support_priority = payload
        },
        loadFeedSupportAsigned(state,payload){
            state.feed_support_asigned = payload
        },
        loadPermissions(state,payload){
            state.feed_perms = payload
        },
        loadProfiles(state, payload){
            state.feed_profiles = payload;
        },
        loadGroups(state, payload){
            state.feed_groups = payload;
        },
        loadFeedDoctors(state, payload){
            state.doctors = payload;
        },
        loadFeedClinics(state, payload){
            state.clinics = payload;
        },
        loadFeedAgeConditions(state, payload){
            state.age_conditions = payload;
        },
        loadFeedGenre(state, payload){
            state.genre = payload;
        },
        loadFeedConditions(state, payload){
            state.conditions = payload;
        },
        loadFeedTypeClinic(state, payload){
            state.type_clinic = payload;
        },
        loadFeedProvinceClinic(state, payload){
            state.province_clinic = payload;
        },
        loadFeedCityClinic(state, payload){
            state.city_clinic = payload;
        },
        loadFeedTypeEquipments(state, payload){
            state.type_equipments = payload;
        },
    },
    actions: {
        async getFeedSpecialties(state){
            let  requestLogin = API_URL + "configurations/specialties/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedSpecialties", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedClinicSpecialties(state){
            let  requestLogin = API_URL + "configurations/clinics-specialties/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    console.log('response.data.data', response.data.data);
                    state.commit("loadFeedClinicSpecialties", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedDepartments(state){
            let  requestLogin = API_URL + "configurations/departments/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedDepartments", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedRoom(state){
            let  requestLogin = API_URL + "configurations/room/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedRoom", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedDoctors(state){
            let  requestLogin = API_URL + 'doctors/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedDoctors", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedClinics(state){
            let  requestLogin = API_URL + 'clinics/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedClinics", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedRequiredMaintenance(state){
            let  requestLogin = API_URL + "configurations/required-maintenance/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedRequiredMaintenance", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedEquipment(state){
            let  requestLogin = API_URL + "configurations/equipment/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedEquipment", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedVariables(state){
            let  requestLogin = API_URL + "configurations/variables/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedVariables", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProcedure(state){
            let  requestLogin = API_URL + "configurations/procedure/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedProcedure", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProfessionalType(state){
            let  requestLogin = API_URL + "configurations/professional-type/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedProfessionalType", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedNationalities(state){
            let  requestLogin = API_URL + "nationalities/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedNationalities", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedLanguages(state){
            let  requestLogin = API_URL + "languages/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedLanguages", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGenders(state){
            let  requestLogin = API_URL + "genders/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedGenders", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedRelationships(state){
            let  requestLogin = API_URL + "relationships/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedRelationships", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeOfVisits(state){
            let  requestLogin = API_URL + "configurations/type-of-visits/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedTypeOfVisits", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedOptionsToSelect(state){
            let  requestLogin = API_URL + "options-to-select/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedOptionsToSelect", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedItemGroups(state){
            let  requestLogin = API_URL + "item-groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedItemGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedItems(state, item){
            let  requestLogin = API_URL + "items/" + item.type + "/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedItems", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedAllItems(state){
            let  requestLogin = API_URL + "all-items/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedAllItems", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedForms(state){
            let  requestLogin = API_URL + "forms/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedForms", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedPeriods(state){
            let  requestLogin = API_URL + "periods/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedPeriods", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedQuantities(state){
            let  requestLogin = API_URL + "quantities/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedQuantities", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedPictures(state){
            let  requestLogin = API_URL + "pictures/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedPictures", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTables(state){
            let  requestLogin = API_URL + "tables/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedTables", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getOhipSearch(state, item){
            let requestLogin = API_URL + 'patients/ohip/search';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getClinicProfessionalsSearch(state, item){
            let requestLogin = API_URL + 'clinics/professionals/search';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAllUsersSearch(state, item){
            let requestLogin = API_URL + 'users/all/search';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchGroups(state,item){
            let requestSearchCompanies = API_URL + "search/users";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });
            axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchGroups", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchMDByCenter(state,item){
            let requestSearchCompanies = API_URL + "search/users/md";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });
            axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit("loadResultMDByCenter", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSelected(state, item){
            let  requestLogin = API_URL + item.call;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedSelected", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStructureSelected(state, item){
            let  requestLogin = API_URL + item.call;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadStructureSelected", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeDDBB(state){
            let  requestLogin = API_URL + "type-ddbb/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTypeDDBB", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCategoryProcessOrItem(state, item){
            let  requestLogin = API_URL + "category-" + (item.type == 'process' ? 'process' : 'form') + "/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTypeCategories", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportGroups(state){
            let  requestLogin = API_URL + "support/groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSupportGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportCategory(state){
            await axios.get(
                API_URL + "support/categories/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportCategory', response.data.data);
                }
            )
        },
        async getFeedSupportPriority(state){
            await axios.get(
                API_URL + "support/priorities/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportPriority', response.data.data);
                }
            )
        },
        async getFeedSupportAsigned(state){
            await axios.get(
                API_URL + "support/groups/tickets-feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportAsigned', response.data.data);
                }
            )
        },
        async getFeedPermissions(state){
            let  requestLogin = API_URL + "permissions/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPermissions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProfiles(state){
            let  requestLogin = API_URL + "permissions/profiles/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfiles", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGroups(state){
            let  requestLogin = API_URL + "permissions/groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedAgeConditions(state){
            let  requestLogin = API_URL + "age-conditions/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedAgeConditions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGenre(state){
            let  requestLogin = API_URL + "genre/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedGenre", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedConditions(state){
            let  requestLogin = API_URL + "conditions/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedConditions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchClinics(state,item){
            let requestSearchClinics = API_URL + "search/clinics";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });
            axios({
                method: "get",
                url: requestSearchClinics,
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchClinics", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeClinic(state){
            let  requestLogin = API_URL + 'type-clinic/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedTypeClinic", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProvinceClinic(state){
            let  requestLogin = API_URL + 'province-clinic/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedProvinceClinic", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCityClinic(state){
            let  requestLogin = API_URL + 'city-clinic/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedCityClinic", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeEquipments(state){
            let  requestLogin = API_URL + 'configurations/type-equipments/feeds';
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedTypeEquipments", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};