<template>
    <div style="display: flex;">
        <label :id="'description_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_item'" class="gh_text_field-label T15_b" style="margin-right: 10px; margin-bottom: 10px;">{{dataItem.item.config.description}}</label>
    </div>
</template>

<script>
    export default {
        name: 'view_description',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
    }
</script>