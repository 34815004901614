<template>
    <div class="gh-content-global">
        <div class="gh-row-content">
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('medical')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'radio',
                    id: 'surgery_type_of_admission_medical',
                    value: 1,
                    name: 'surgery[type_of_admission][type]',
                    selected: $parent.$parent.dataForm.type_of_admission.type === 1 ? true : false,
                }"
                @click="checkType()"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 250px; min-width: 250px; text-align: right;">{{$t('surgical')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'radio',
                    id: 'surgery_type_of_admission_surgical',
                    value: 2,
                    name: 'surgery[type_of_admission][type]',
                    selected: $parent.$parent.dataForm.type_of_admission.type === 2 ? true : false,
                }"
                @click="checkType()"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 250px; min-width: 250px; text-align: right;">{{$t('minor_procedure_room')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'radio',
                    id: 'surgery_type_of_admission_minor_procedure_room',
                    value: 3,
                    name: 'surgery[type_of_admission][type]',
                    selected: $parent.$parent.dataForm.type_of_admission.type === 3 ? true : false,
                }"
                @click="checkType()"
            />
        </div>
        <div class="gh-row-content" v-if="isSurgical">
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('day_surgery')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_day_surgery',
                    value: 4,
                    name: 'surgery[type_of_admission][surgical][]',
                    selected: $parent.$parent.dataForm.type_of_admission.surgical.includes(4),
                }"
                ref="RefTypeOfAdmissionDaySurgery"
                @click="checkSurgical('RefTypeOfAdmissionDaySurgery')"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 251px; min-width: 251px; text-align: right;">{{$t('pre_op_admission')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_pre_op_admission',
                    value: 5,
                    name: 'surgery[type_of_admission][surgical][]',
                    selected: $parent.$parent.dataForm.type_of_admission.surgical.includes(5),
                }"
                ref="RefTypeOfAdmissionPreOpAdmission"
                @click="checkSurgical('RefTypeOfAdmissionPreOpAdmission')"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 251px; min-width: 251px; text-align: right;">{{$t('post_op_admission')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_post_op_admission',
                    value: 6,
                    name: 'surgery[type_of_admission][surgical][]',
                    selected: $parent.$parent.dataForm.type_of_admission.surgical.includes(6),
                }"
                ref="RefTypeOfAdmissionPostOpAdmission"
                @click="checkSurgical('RefTypeOfAdmissionPostOpAdmission')"
            />
        </div>
        <div class="gh-row-content" v-if="isPOST">
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('pacu_steodown')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_pacu_steodown',
                    value: 7,
                    name: 'surgery[type_of_admission][post][]',
                    selected: $parent.$parent.dataForm.type_of_admission.post.includes(7),
                }"
                @click="checkPost"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 251px; min-width: 251px; text-align: right;">{{$t('icu')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_icu',
                    value: 8,
                    name: 'surgery[type_of_admission][post][]',
                    selected: $parent.$parent.dataForm.type_of_admission.post.includes(8),
                }"
                @click="checkPost"
            />
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 251px; min-width: 251px; text-align: right;">{{$t('ward')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: true,
                    disabled: false,
                    type: 'checkbox',
                    id: 'surgery_type_of_admission_ward',
                    value: 9,
                    name: 'surgery[type_of_admission][post][]',
                    selected: $parent.$parent.dataForm.type_of_admission.post.includes(9),
                }"
                @click="checkPost"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'view_type_of_admission',
        components: {
            GhChecks
        },
        data(){
            return{
                haveData: false,
                isSurgical: false,
                isPOST: false,
                data: {
                    type: this.$parent.$parent.dataForm.type_of_admission.type,
                    surgical: this.$parent.$parent.dataForm.type_of_admission.surgical,
                    post: this.$parent.$parent.dataForm.type_of_admission.post
                }
            }
        },
        async beforeMount(){
            this.isSurgical = this.$parent.$parent.dataForm.type_of_admission.type === 2 ? true : false;
            this.isPOST = this.$parent.$parent.dataForm.type_of_admission.surgical.includes(6);
            this.haveData = true;
        },
        beforeDestroy() {
            this.saveData();
        },
        methods:{
            checkType(){
                this.data.type = document.querySelectorAll('input[name="surgery[type_of_admission][type]"]:checked')[0]._value

                this.isSurgical = this.data.type === 2 ? true : false;
                this.isPOST = false;
            },
            checkSurgical(ref){
                var params = [];

                if(ref == 'RefTypeOfAdmissionDaySurgery'){
                    if (this.$refs['RefTypeOfAdmissionPreOpAdmission'].$el.children[0].children[1].checked == true){
                        this.$refs['RefTypeOfAdmissionPreOpAdmission'].checkUncheck();
                    }

                    if (this.$refs['RefTypeOfAdmissionPostOpAdmission'].$el.children[0].children[1].checked == true){
                        this.$refs['RefTypeOfAdmissionPostOpAdmission'].checkUncheck();
                    }
                }else{
                    if (this.$refs['RefTypeOfAdmissionDaySurgery'].$el.children[0].children[1].checked == true){
                        this.$refs['RefTypeOfAdmissionDaySurgery'].checkUncheck();
                    }
                }

                if(this.$refs['RefTypeOfAdmissionPostOpAdmission'].$el.children[0].children[1].checked){
                    this.isPOST = true;
                }else{
                    this.isPOST = false;
                }

                document.querySelectorAll('input[name="surgery[type_of_admission][surgical][]"]:checked').forEach((item) => {
                    params.push(item._value);
                });

                this.data.surgical = params;
            },
            checkPost(){
                var params = [];

                document.querySelectorAll('input[name="surgery[type_of_admission][post][]"]:checked').forEach((item) => {
                    params.push(item._value);
                });

                this.data.post = params;
            },
            saveData(){
                this.$parent.$parent.dataForm.type_of_admission = this.data;
            }
        }
    }
</script>