<template>
    <div id="option_to_select_yes_no_selector" v-if="haveData">
        <div class="gh-row-content">
            <label class="gh_text_field-label" :class="$root._route.params.view == 'true' ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('answer') + ':'}}</label>
            <label class="gh_text_field-label" :class="$root._route.params.view == 'true' ? 'T15_b' : 'T15'" style="width:40px; margin-right: 5px;">{{$t('yes')}}</label>
            <template v-if="!$root._route.params.view">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: $parent.$parent.type_item + '_yes_no_selector_yes_item',
                        label: $t('item'),
                        title: $t('item'),
                        text: $t('select_item') + ':',
                        name: $parent.$parent.type_item + '[yes_no_selector][yes][item]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: false,
                        style: 'margin-left: 0px;margin-top:2px;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.yes !== undefined ? $parent.$parent.stateData.data.yes_no_selector.yes.item : ''"
                    :items="getFeeds.items"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.yes !== undefined ? $parent.$parent.stateData.data.yes_no_selector.yes.item[0].text : ''}}</label>
            </template>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks style="width: 150px"
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_yes_no_selector_yes_associate_to',
                    value: 1,
                    name: $parent.$parent.type_item + '[yes_no_selector][yes][associate_to]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.yes !== undefined ? $parent.$parent.stateData.data.yes_no_selector.yes.associate_to : '',
                }"
            />
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label" :class="$root._route.params.view == 'true' ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('answer') + ':'}}</label>
            <label class="gh_text_field-label" :class="$root._route.params.view == 'true' ? 'T15_b' : 'T15'" style="width:40px; margin-right: 5px;">{{$t('no')}}</label>
            <template v-if="!$root._route.params.view">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: $parent.$parent.type_item + '_yes_no_selector_no_item',
                        label: $t('item'),
                        title: $t('item'),
                        text: $t('select_item') + ':',
                        name: $parent.$parent.type_item + '[yes_no_selector][no][item]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: false,
                        style: 'margin-left: 0px;margin-top:2px;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.no !== undefined ? $parent.$parent.stateData.data.yes_no_selector.no.item : ''"
                    :items="getFeeds.items"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.no !== undefined ? $parent.$parent.stateData.data.yes_no_selector.no.item[0].text : ''}}</label>
            </template>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_yes_no_selector_no_associate_to',
                    value: 1,
                    name: $parent.$parent.type_item + '[yes_no_selector][no][associate_to]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.yes_no_selector !== undefined && $parent.$parent.stateData.data.yes_no_selector.no !== undefined ? $parent.$parent.stateData.data.yes_no_selector.no.associate_to : '',
                }"
            />
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "view_yes_no_selector",
        components: {
            GhTreeSelectorBlock,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedItems', {type: this.$parent.$parent.type_item}, {root: true});
            this.haveData = true;
        }
    }
</script>