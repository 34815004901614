var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real_content"},[_c('div',[_c('div',{staticClass:"selector_page"},[_c('div',{staticClass:"logo_selector"},[_c('img',{attrs:{"src":require('../../assets/login/MCH_logo_login.png')},on:{"click":function($event){return _vm.methodExit()}}})]),_c('div',{staticClass:"title F1 C1",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t('select_action')))]),_c('div',{staticStyle:{"text-align":"right","width":"50%"}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_back_login',
                            text: _vm.$t('back'),
                            icon: require('../../assets/svg/back.svg'),
                        }},on:{"click":_vm.toBackEnvironments}})],1)]),_c('div',{staticClass:"environment_block"},_vm._l((_vm.Environment.records_data),function(item,index){return _c('a',{key:index,on:{"click":function($event){return _vm.toRedirectSpecificClinic(item.id)}}},[_c('GhEnvironemnt',{attrs:{"dataBlock":{
                            id: 'environment_action_' + item.id,
                            title: item.title !== undefined ? item.title.toUpperCase() : '',
                            subtitle: item.subtitle !== undefined ? item.subtitle : '',
                        },"environment":{
                            img_hover: item.image.visible,
                            img_visible: item.image.hover,
                        }}})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }