import Permissions from "@/view/permissions/sections/section_permission.vue";
import Groups from './groups/route';
import Profiles from './profiles/route';
import Companies from "./companies/route";

const route = [
    {
        path: '/permissions',
        name: 'permissions',
        component: Permissions,
        props: {showMenu: true}
    }
];

const routes = [
    ...route,
    ...Groups,
    ...Profiles,
    ...Companies
];

export default routes;