<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_dependencies',
            title: $t('dependency_definition'),
            type: 'warning',
            style: 'width: 80%; min-width: 1000px'
        }"
        :bodyConfiguration="{
            id: 'popup_rename_body',
            class: '',
            style: 'padding:2px;',
            text: $t('click_on_the_elements_to_define_the_dependency') + ':',
        }"
        :footerConfiguration="{}"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <div class="dependencies-elements">
                    <template v-for="(item, index) in dataBlock.data">
                        <div class="gh-row-content" v-for="(answer, keyAnswer) in item.children" :key="'Q' + index + keyAnswer + refreshDependence">
                            <label class="gh_text_field-label T15_b" style="margin-left: 5px;" :id="'number_dependency_' + index + '_' + keyAnswer">{{answer.counter + ':'}}</label>
                            <GhAction style="margin-left: 10px; margin-top: -1px;" :style="'color: ' + returnColor(answer) + ' !important;'"
                                :dataction="{
                                    id: 'action_select_dependency_',
                                    text: item.text + ': ' + answer.text,
                                }"
                                @click="checkDependency(index, keyAnswer)"
                            />
                        </div>
                    </template>
                </div>
                <div class="centered" style="margin-top: 10px;">
                    <GhButton
                        :datainput="{
                            id: 'button_and_dependencies',
                            text: $t('and'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="addCondition('and')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'button_or_dependencies',
                            text: $t('or'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="addCondition('or')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'button_not_dependencies',
                            text: $t('not'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="addCondition('not')"
                    />
                </div>
                <div class="dependencies-table" style="margin-top: 15px;" :key="refreshTableDependency">
                    <table id="table_dependencies">
                        <tr v-for="(item, index) in dependency" :key="index" :class="index % 2 === 0 ? 'striped_white' : 'striped'">
                            <td style="width: auto; padding-right: 10px; text-align: end;" class="T15_b border_w table-cell">{{$t('dependency') + ' ' + (index + 1) + ':'}}</td>
                            <td style="width: 85%" class="T15_b border_w table-cell">
                                <template v-for="(i, k) in item.selecteds">
                                    <span :style="'color: ' + i.color + ';'" :key="'W' + k">{{i.counter}}</span>
                                    <span :key="'V' + k">{{' ' + i.condition + ' '}}</span>
                                </template>
                            </td>
                            <td style="width: 15%">
                                <GhAction v-if="item.active" style="margin-left: 10px; margin-top: -1px;"
                                    :dataction="{
                                        id: 'action_end_dependency_' + index,
                                        text: $t('end'),
                                        icon: require('../../../../../assets/svg/tick.svg'),
                                    }"
                                    @click="endDependency(index)"
                                />
                                <GhAction v-if="!item.active" style="margin-left: 10px; margin-top: -1px;"
                                    :dataction="{
                                        id: 'action_edit_dependency_' + index,
                                        text: $t('edit'),
                                        icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                    }"
                                    @click="editDependency(index)"
                                />
                                <GhAction style="margin-left: 10px; margin-top: -1px;"
                                    :dataction="{
                                        id: 'action_delete_dependency_' + index,
                                        text: $t('delete'),
                                        icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                    }"
                                    @click="Global.deleteConfirm('delete_confirm','removeDependencies', item.id, {'dependency': index + 1, 'data': {'index':index, 'dependency': dependency}}, $t('delete_dependency'), $t('preparing_delete_dependency'), 'remove');"
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="gh-row-content">
                    <GhAction style="margin-top: 10px;"
                        :dataction="{
                            id: 'action_new_dependencies',
                            text: $t('add_dependency'),
                        }"
                        @click="addDependency"
                    />
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_save_popup_dependencies',
                    text: $t('save'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="saveDependency();blockedByDependences();"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_popup_dependencies',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="closeDependency()"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";

    export default {
        name: 'popup_dependencies',
        props: ['dataBlock'],
        components: {
            GhAction,
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationFormProcess'])
        },
        data(){
            return {
                counter: 1,
                checked: 0,
                refreshDependence: 0,
                selecteds: [],
                default: this.$parent.actualData.dependency,
                colors: [
                    '#E81212',
                    '#E84612',
                    '#E88612',
                    '#E8C112',
                    '#C4E812',
                    '#73E812',
                    '#12E87A',
                    '#12E8C4',
                    '#12A7E8',
                    '#125DE8',
                    '#5312E8',
                    '#9D12E8',
                    '#E8128A'
                ],
                activeDependency: {
                    key: '',
                    value: ''
                },
                dependency: [
                    {
                        'id': Math.floor(Math.random() * 100000000000),
                        'active': true,
                        'selecteds': []
                    }
                ],
                refreshTableDependency: 0,
            }
        },
        watch: {
            '$store.state.ConfigurationFormProcess.dependency'(data){
                this.deleteDependency(data);
            }
        },
        beforeMount() {
            var counter = 0;
            var that = this;
            this.dataBlock.data.forEach((item, key) => {
                item.children.forEach((i, k) => {
                    counter++;
                    that.dataBlock.data[key].children[k].counter = counter;
                });
            });

            if(this.$parent.actualData.dependency !== undefined){
                this.dependency = this.$parent.actualData.dependency;
            }

            this.dependency.find((item, key) => {
                if(item.active){
                    this.activeDependency.key = key;
                    this.activeDependency.value = item.id;
                }
            });

            this.blockedByDependences();
        },
        methods: {
            blockedByDependences(){
                var to_push_block = [];
                this.dependency.find((item) => {
                    item.selecteds.find((item) => {
                        var params = {
                            value: item.value
                        }
                        to_push_block.push(params);
                    });
                });
                this.$parent.blockedByDependences = to_push_block;
            },
            returnColor(answer){
                var block = this.selecteds.find((item) => item.value === answer.value);
                return block !== undefined ? block.color : '';
            },
            addDependency(){
                var rand = Math.floor(Math.random() * 100000000000);
                var params = {
                    'id': rand,
                    'active': false,
                    'selecteds': []
                }
                this.dependency.push(params);
                this.refreshTableDependency++;
            },
            endDependency(index){
                this.selecteds = [];
                this.checked = 0;
                this.activeDependency.key = '';
                this.activeDependency.value = '';
                this.dependency[index].active = false;
                this.refreshTableDependency++;
            },
            editDependency(index){
                this.dependency.forEach((item, key) => {
                    if(index === key){
                        this.selecteds = this.dependency[key].selecteds;
                        this.checked = this.selecteds.length;
                        this.dependency[key].active = true;
                        this.activeDependency.key = key;
                        this.activeDependency.value = this.dependency[key].id;
                    }else{
                        this.dependency[key].active = false;
                    }
                    this.refreshTableDependency++;
                });
            },
            deleteDependency(data){
                this.dependency = data;
                var enter = false;
                this.dependency.find((item, key) => {
                    if(item.active){
                        enter = true;
                        this.selecteds = this.dependency[key].selecteds;
                    }
                });

                if(!enter){
                    this.selecteds = [];
                }
            },
            checkDependency(parent, children){
                if(this.activeDependency.key !== '' && this.activeDependency.value !== ''){
                    if(this.checked > 12){
                        this.checked = 0;
                    }

                    var last = this.selecteds[this.selecteds.length - 1];

                    if(last !== undefined && last.condition === ''){
                        this.Global.warningInfo({0: this.$t('please_enter_a_condition')});
                    }else if (this.selecteds.find((item) => item.value === this.dataBlock.data[parent].children[children].value) === undefined || last === undefined) {
                        var params = {
                            'parent': parent,
                            'children': children,
                            'value': this.dataBlock.data[parent].children[children].value,
                            'color': this.colors[this.checked++],
                            'counter': this.dataBlock.data[parent].children[children].counter,
                            'condition': ''
                        };

                        this.selecteds.push(params);
                    }else {
                        this.checked--;
                        var params2 = [];
                        this.selecteds.forEach((item, key) => {
                            if (item.value !== this.dataBlock.data[parent].children[children].value) {
                                params2.push(this.selecteds[key]);
                            }
                        });

                        this.selecteds = params2;
                    }

                    this.dependency.find((item, key) => {
                        if(item.active){
                            this.dependency[key].selecteds = this.selecteds;
                        }
                    });

                    this.refreshDependence++;
                }else{
                    this.Global.warningInfo({0: this.$t('first_select_a_dependency_to_edit_or_add_a_new_one')});
                }
            },
            addCondition(type){
                if(this.selecteds[this.selecteds.length - 1] === undefined){
                    this.Global.warningInfo({0: this.$t('you_have_not_selected_any_item')});
                }else{
                    this.selecteds[this.selecteds.length - 1].condition = this.$t(type);
                }
            },
            saveDependency(){
                this.$parent.actualData.dependency = this.dependency;
                this.$parent.nDependencies = this.dependency.length;
                this.Global.closePopUp('popup_dependencies');
            },
            closeDependency(){
                if(this.$parent.actualData.dependency !== undefined){
                    this.dependency = this.$parent.actualData.dependency;
                }else{
                    this.dependency = [];
                }

                this.refreshTableDependency++;
                this.Global.closePopUp('popup_dependencies');
            }
        }
    }
</script>

<style>
    .dependencies-elements, .dependencies-table{
        overflow-y: auto;
        height: auto;
        max-height: 200px;
    }
</style>