var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_professional_active_',
    },"header":_vm.header,"data":_vm.ConfigurationProfessionalsActive.records_data.length === 0 ? [] : _vm.ConfigurationProfessionalsActive.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){return _vm.viewProfessionalsActive(itemProp.id)}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_deactivate_active_' + itemProp.id,
                text: _vm.$t('deactivate'),
                icon: require('../../../../assets/gh_new_dessign/denied.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','changeStateConfigurationProfessionals', itemProp.id,{
                'code': itemProp.code,
                'name': itemProp.name,
                'surname': itemProp.surname,
                'specialties': itemProp.specialties,
                'status': itemProp.status
            }, _vm.$t('deactivate_professional'), _vm.$t('preparing_deactivate_professional'), 'deactivate')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_active_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){return _vm.editProfessionalsActive(itemProp.id)}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_active_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationProfessionals', itemProp.id,{
                'code': itemProp.code,
                'name': itemProp.name,
                'surname': itemProp.surname,
                'specialties': itemProp.specialties,
                'status': itemProp.status
            }, _vm.$t('delete_professional'), _vm.$t('preparing_delete_professional'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_new_professional',
                    text: _vm.$t('add')
                }},on:{"click":function($event){return _vm.addProfessionalsActive()}}})],1)]},proxy:true}],null,false,2609365431)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }