import Configuration from "@/view/configuration/configuration.vue";

import Equipments from './equipments/route';
import Variable from './variable/route';
import Professionals from './professionals/route';
import Calendar from  './calendar/route';
import ConsentForms from './consent_forms/route';
import ClinicData from './clinic_data/route';
import GeneralData from './general_data/route';
import Items from './items/route';
import Forms from './forms_process/forms/route';
import Process from './forms_process/process/route';
import FormsProcess from './forms_process/route';
import Support from './support/route';
import Priority from "./priority/route";

const route = [
    {
        path: '/configuration',
        name: 'configuration',
        component: Configuration,
        props: {showMenu: true}
    }
];

const routes = [
    ...route,
    ...Equipments,
    ...Variable,
    ...Professionals,
    ...Calendar,
    ...ConsentForms,
    ...ClinicData,
    ...GeneralData,
    ...Items,
    ...FormsProcess,
    ...Forms,
    ...Process,
    ...Support,
    ...Priority
]

export default routes;