import SectionSpeciality from "@/view/calendar/speciality/sections/section_speciality.vue"
import ViewSpeciality from "@/view/calendar/speciality/views/speciality.vue";

const route = [
    {
        path: '/calendar/speciality',
        name: 'calendar_speciality',
        component: SectionSpeciality,
        props: {showMenu: true}
    },
    {
        path: '/calendar/speciality/:id/:name/:date',
        name: 'view_speciality',
        component: ViewSpeciality,
        props: {showMenu: false, background: true, id: null, name: undefined, date: undefined},
    },
];

const routes = [
    ...route,
]

export default routes;