var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{attrs:{"id":"option_to_select_time"}},[_c('div',{staticClass:"gh-row-content",staticStyle:{"height":"18px"}},[_c('div',[_c('div',{staticStyle:{"width":"40%"},style:(!_vm.$root._route.params.view ? '' : 'display: flex;')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'label_' + _vm.$parent.$parent.type_item + '_time_period',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_period') + ':',
                            text: _vm.$t('period'),
                            label: _vm.$t('period'),
                            type: 'radio',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: _vm.$parent.$parent.type_item + '_time_period',
                            name: _vm.$parent.$parent.type_item + '[time][period]'
                        },"feed":_vm.getFeeds.periods,"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.time !== undefined && _vm.$parent.$parent.stateData.data.time.period !== undefined ? _vm.$parent.$parent.stateData.data.time.period : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('period') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.time !== undefined && _vm.$parent.$parent.stateData.data.time.period !== undefined ? Object.values(_vm.$parent.$parent.stateData.data.time.period).join() : ''))])]],2),_c('div',{staticStyle:{"width":"40%"}},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                            type: 'radio',
                            id: _vm.$parent.$parent.type_item + '_time_item',
                            label: _vm.$t('item'),
                            title: _vm.$t('item'),
                            text: _vm.$t('select_item') + ':',
                            name: _vm.$parent.$parent.type_item + '[time][item]',
                            id_value: 'value',
                            name_label: 'text',
                            child_key: 'children',
                            required: false,
                            checkparentdata: false,
                            style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        },"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.time !== undefined && _vm.$parent.$parent.stateData.data.time.item !== undefined ? _vm.$parent.$parent.stateData.data.time.item : [],"items":_vm.getFeeds.items}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('item') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"33.5%"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.time !== undefined && _vm.$parent.$parent.stateData.data.time.item !== undefined ? _vm.$parent.$parent.stateData.data.time.item[0].text : ''))])]],2)]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('associate_to')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: _vm.$parent.$parent.type_item + '_time_associate_to',
                value: 1,
                name: _vm.$parent.$parent.type_item + '[time][associate_to]',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.time !== undefined && _vm.$parent.$parent.stateData.data.time.associate_to !== undefined ? _vm.$parent.$parent.stateData.data.time.associate_to : '',
            }}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }