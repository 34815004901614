var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'section_clinic_data',title: _vm.$t('clinic_data')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.view_clinic_data,{tag:"component",attrs:{"dataBlock":{type: _vm.view}}})]},proxy:true},(_vm.view)?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_clinic_data',
                    text: _vm.$t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }},on:{"click":_vm.changeState}})]},proxy:true}:null],null,true)}),(!_vm.view)?_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save_clinic_data',
                text: _vm.$t('accept'),
            },"type":"submit"},on:{"click":_vm.modifyClinic}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return_clinic_data',
                text: _vm.$t('close'),
            }},on:{"click":_vm.changeState}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }