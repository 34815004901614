<template>
    <div>
        <template v-if="Patients.data.upcoming_and_past_appoinments.past.length !== 0">
            <div v-for="(item, index) in Patients.data.upcoming_and_past_appoinments.past" :key="index">
                <br>
                <div style="display: grid">
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{'• ' + item.name}}</label>
                    <div style="display: flex; width: 100%">
                        <label class="gh_text_field-label T15" style="margin-left: 25px; white-space: nowrap;">{{item.description}}</label>
                        <span style="width: 100%; margin-left: 10px; border-bottom: 1px dotted;"></span>
                        <label class="gh_text_field-label T15" style="margin-left: 10px; white-space: nowrap;">{{item.date + ' - ' + item.time}}</label>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <br>
            <div class="gh-row-content-info">
                <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_past_appoinments')}}</label>
            </div>
        </template>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "past_appoinments",
        computed: {
            ...mapState(['Patients'])
        },
        mounted(){
            if(this.Patients.data.upcoming_and_past_appoinments.past.length > 1) {
                this.$parent.$parent.$parent.showExpansible = true;
                this.$parent.$parent.$parent.$parent.$parent.actualHeight = 230;
            }else{
                if(this.Patients.data.upcoming_and_past_appoinments.past.length === 0){
                    this.$parent.$parent.$parent.$parent.$parent.actualHeight = 150;
                }else{
                    this.$parent.$parent.$parent.$parent.$parent.actualHeight = 170;
                }

                this.$parent.$parent.$parent.showExpansible = false;
            }

            if(this.$parent.$parent.expanded){
                this.$parent.$parent.collapse();
            }

            if(this.$parent.$parent.$parent.state != 1){
                this.$parent.$parent.$parent.state = 1;
                this.$parent.$parent.$parent.refresh++;
            }
        },
    }
</script>