var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real_content"},[_c('div',[_c('div',{staticClass:"selector_page"},[_c('div',{staticClass:"logo_selector"},[_c('img',{attrs:{"src":require('../../assets/login/MCH_logo_login.png')},on:{"click":function($event){return _vm.methodExit()}}})]),_c('div',{staticClass:"title F1 C1",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t('select_clinic_or_hospital')))]),_c('div',{staticStyle:{"text-align":"right","width":"50%"}},[_c('GhAction',{staticStyle:{"display":"inline-flex"},attrs:{"dataction":{
                            id: 'action_back_login',
                            text: _vm.$t('logout'),
                            icon: require('../../assets/svg/logout.svg'),
                        }},on:{"click":_vm.methodExit}})],1)]),_c('div',{staticClass:"environment_block"},_vm._l((_vm.Environment.records_data),function(item,index){return _c('a',{key:index,on:{"click":function($event){return _vm.toRedirectSpecificClinic(item.id)}}},[_c('GhEnvironemnt',{attrs:{"dataBlock":{
                            id: 'environment_' + item.id,
                            title: item.title !== undefined ? item.title.toUpperCase() : '',
                            subtitle: item.subtitle !== undefined ? item.subtitle : '',
                        },"environment":{
                            img_hover: 'https://pre-tapagora.risktor.com/pub/web/images/tap/ICONO_INCIDENTS.svg',
                            img_visible: 'https://pre-tapagora.risktor.com/pub/web/images/tap/ICONO_INCIDENTS_NORMAL.svg',
                        }}})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }