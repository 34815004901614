<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <template v-if="dataItem.item.config.imported !== undefined && dataItem.item.config.imported.values !== undefined && dataItem.item.config.imported.values.length !== 0">
                    <div :class="'grid-container' + (dataItem.item.config.imported.how_many_columns !== undefined ? '-' + dataItem.item.config.imported.how_many_columns : '')">
                        <div v-for="(item, key) in dataItem.item.config.imported.values" :key="key" class="grid-item">
                            <label class="gh_text_field-label T15" style="margin-right: 10px;">{{item.label !== undefined && item.label !== '' ? $t(item.label) + ':' : ''}}</label>
                            <label class="gh_text_field-label T15_b">{{item.text !== undefined && item.text !== '' ? item.text : ''}}</label>
                        </div>
                    </div>
                </template>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'view_imported_data',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        data(){
            return{
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false
            }
        }
    }
</script>