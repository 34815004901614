import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        department_data: [],
    },
    mutations: {
        loadDataDepartment(state, payload) {
            state.department_data = payload;
        },
    },
    actions: {
        async getDataDepartment(state){
            await axios.get(
                API_URL + "calendar/department/data/:id" //cambiar 'variable' por dato
            ).then(
                response => {
                    state.commit('loadDataDepartment', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};