<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t($root._route.matched[0].props.default.otherTitle)
            }"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_schedule_pre_ops',
                    title: $t('schedule_pre_ops')
                }"
            >
                <template v-slot:content>
                    <form id="form_schedule_pre_ops">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 200px; text-align: right;">{{$t('will_be_external_preops') + '?'}}</label>
                                <GhChecks
                                    :datalabel="{
                                        text: $t('yes'),
                                        style: 'margin-right: 50px;'
                                    }"
                                    :datainput="{
                                        id: 'schedule_pre_available_short_notice',
                                        value: 1,
                                        type: 'radio',
                                        name: 'schedule_pre_ops[external_pre_ops]',
                                        style: 'cursor:pointer; ',
                                        selected: ''
                                    }"
                                    ref="checkExternal1"
                                    @click="externalPreOps(true)"
                                />
                                <GhChecks
                                    :datalabel="{
                                        text: $t('no'),
                                    }"
                                    :datainput="{
                                        id: 'schedule_pre_available_short_notice',
                                        value: 0,
                                        type: 'radio',
                                        name: 'schedule_pre_ops[external_pre_ops]',
                                        style: 'cursor:pointer; ',
                                        selected: ''
                                    }"
                                    ref="checkExternal2"
                                    @click="externalPreOps(false)"
                                />
                            </div>
                            <div v-if="block_yes_external_preops" style="margin-top: 15px;">
                                <div class="gh-row-content">
                                    <div>
                                        <div style="width: 50%;">
                                            <GhDataSelector
                                                :datablock="{
                                                    id: 'label_external_clinics',
                                                    class: 'T15_b',
                                                    subtitle: $t('select_clinic') + ':',
                                                    text: $t('clinic'),
                                                    label: $t('clinic'),
                                                    type: 'radio',
                                                    columns: 3,
                                                    required: false,
                                                    style: 'margin-left: 50px;',
                                                    popupStyle: 'min-width: 900px; width: 55%;',
                                                }"
                                                :datainput="{
                                                    id: 'external_clinic',
                                                    name: 'schedule_pre_ops[external_clinic]'
                                                }"
                                                :feed="getFeeds.clinics"
                                                :selected_input="[]"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div style="width: 50%;">
                                            <GhDataSelector
                                                :datablock="{
                                                    id: 'label_external_doctors',
                                                    class: 'T15_b',
                                                    subtitle: $t('select_doctor') + ':',
                                                    text: $t('doctor'),
                                                    label: $t('doctor'),
                                                    type: 'radio',
                                                    columns: 3,
                                                    required: false,
                                                    style: '',
                                                    popupStyle: 'min-width: 900px; width: 55%;',
                                                }"
                                                :datainput="{
                                                    id: 'external_doctor',
                                                    name: 'schedule_pre_ops[external_doctor]'
                                                }"
                                                :feed="getFeeds.doctors"
                                                :selected_input="[]"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-left: 50px; margin-right: 10px;">{{$t('no_later_than') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{WarningsPreOps.data_schedule_pre_ops.external.no_later_than}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-left: 50px; margin-right: 10px;">{{$t('comments') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{WarningsPreOps.data_schedule_pre_ops.external.comments}}</label>
                                </div>
                            </div>
                            <div v-if="block_no_external_preops" style="margin-top: 15px;" :key="refresh_no_external">
                                <div v-for="(item,index) in WarningsPreOps.data_schedule_pre_ops.internal" :key="'no_external_pre_ops-'+index">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="margin-left: 50px; margin-right: 10px;">{{$t(item.type) + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.date}}</label>
                                        <template v-if="!show_no_external_select[item.id]">
                                            <GhAction style="margin-top: -2px;"
                                                :dataction="{
                                                    id: 'action_select_' + item.id,
                                                    text: $t('select')
                                                }"
                                                ref="RefScheduleSelect1"
                                                @click="checkSchedule(item.id)"
                                            />
                                        </template>
                                        <template v-else>
                                            <GhAction style="margin-top: -2px; margin-left: 10px;"
                                                :dataction="{
                                                    id: 'action_select_' + item.id,
                                                    text: '',
                                                    icon: require('../../../../assets/svg/tick.svg'),
                                                }"
                                                @click="unCheckSchedule(item.id)"
                                            />
                                        </template>
                                    </div>
                                </div>
                                <input type="hidden" name="schedule_pre_ops[no_external_selected]" :value="no_external_preops_selected">
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
        </div>

        <div class="centered" style="margin-top: 10px; margin-bottom: 10px;">
            <GhButton
                :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                @click="submit()"
            />
            <GhButton
                :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>

import GhLocation from "fe-gh-location-lib";
import GhNoDropSelector from "fe-gh-no-dropselector-lib";
import GhChecks from "fe-gh-checks-lib";
import {mapState} from "vuex";
import GhAction from "fe-gh-action-lib";
import GhDataSelector from "fe-gh-data-selector-lib";
import GhButton from "fe-gh-button-lib";
import {loadLanguageAsync} from "@/main";

export default {
    name: "view_schedule_pre_ops",
    components: {
        GhButton,
        GhDataSelector,
        GhAction,
        GhChecks,
        GhNoDropSelector,
        GhLocation
    },
    computed: {
        ...mapState(['WarningsPreOps','getFeeds'])
    },
    data(){
        return{
            haveData: false,
            refresh_no_external: 0,
            block_no_external_preops: false,
            block_yes_external_preops: false,
            show_no_external_select: [],
            no_external_preops_selected: '',
        }
    },
    async beforeMount(){
        var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
        loadLanguageAsync(lang);
        await this.$store.dispatch('getFeedDoctors', '', {root:true});
        await this.$store.dispatch('getFeedClinics', '', {root:true});
        await this.$store.dispatch('getSchedulePreOps', {id: this.$root._route.params.id}, {root:true});

        this.show_no_external_select = [];
        for(var i=0;i<this.WarningsPreOps.data_schedule_pre_ops.internal.length;i++){
            this.show_no_external_select[this.WarningsPreOps.data_schedule_pre_ops.internal[i].id] = false;
        }
        this.haveData = true;
    },
    methods:{
        externalPreOps(answer){
            var isCheckedYes = this.$refs.checkExternal1.$el.children[0].lastChild.checked;
            var isCheckedNo = this.$refs.checkExternal2.$el.children[0].lastChild.checked;

            this.block_yes_external_preops = answer ? (isCheckedYes ? answer : !answer) : answer;
            this.block_no_external_preops = !answer ? (isCheckedNo ? !answer : answer) : !answer;

        },
        checkSchedule(id){
            for(var i=0;i<this.show_no_external_select.length;i++){
                this.show_no_external_select[i] = i == id ? true : false;
            }
            this.no_external_preops_selected = id;
            this.refresh_no_external++;
        },
        unCheckSchedule(id){
            this.show_no_external_select[id] = false;
            this.no_external_preops_selected = '';
            this.refresh_no_external++;
        },
        async submit(){
            if(this.Global.checkRequired('form_schedule_pre_ops') === 'OK') {
                const formData = new FormData(document.getElementById('form_schedule_pre_ops'));
                await this.$store.dispatch('setSchedulePreOps', {
                    formData: [...formData],
                    id: this.$root._route.params.id,
                }, {root: true});
                this.Global.windowClose();
            }
        }
    }
}
</script>