<template>
    <div class="login">
        <GhLogin>
            <template v-slot:header>
                <div style="display: flex; padding-bottom: -50px;">
                    <div>
                        <img class="" :src="require('../assets/login/MCH_logo_login.png')" style="margin-left: -15px;"/>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="container-user" style="padding-top: 1px;">
                    <GhInputLogin :datalabel="{id: 'user_label', text: $t('user')}" :datainput="{id: 'user_labelf', type: 'text', name: 'username', class: 'username', error: '', error: Login.error.msg ? Login.error.msg : '', show_message: false}" @keyup="enterSubmit"  ref="GhInputUser"/>
                </div>
                <div class="container-password" style="padding-top: 57px">
                    <GhInputLogin :datalabel="{id: 'pass_label', text: $t('password')}" :datainput="{id: 'pass_labelf', type: 'password', name: 'password', class: 'password', error: Login.error.msg ? Login.error.msg : '', show_message: true}" @keyup="enterSubmit"  ref="GhInputPassword"/>
                </div>
                <div class="recordar_t5_2_ t5 text-inline alink" style="margin-top:47px; color: #1080B5;" v-on:click="Global.openPopUp('remember_password')">
                    <p>{{ $t('remember_password')}}</p>
                </div>
                <div class="botonera" style="margin-top: 66px;">
                    <GhButton :datainput="{id: 'button', text: $t('enter'), class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}" @click="submit()"/>
                    <GhButton :datainput="{id: 'button', text: $t('request_registration'), class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}" @click="register()"/>
                </div>
                <div class="telefono_t8_1_ t8 text-inline cursor-normal" style="padding-top: 49px; font-size: 19px;">
                    <p>{{$t('help_phone') + ': ' + '(+34) 935 950 764'}}</p>
                    <br>
                    <p>{{$t('help_email') + ': ' + 'info@globalholdings2000.es'}}</p>
                </div>
            </template>
            <template v-slot:popups>
                <div v-show="false" id="remember_password" class="modal-backdrop">
                    <div class="modal fondo_dropdown_2_" role="dialog" style="width: 355px; height: auto; overflow: hidden; position: fixed">
                        <div class="recordarnombre_t11 t11 text-inline">{{$t('text_remember_password')}}</div>
                        <div class="introduzcalosdatos_t12 t12" style="padding-top: 10px; padding-bottom: 20px;">{{$t('extra_text_remember_password')}}</div>
                        <div style="width: 313px; margin-left: 24px; margin-top: 20px; padding-top: 20px;">
                            <GhInputLogin :datalabel="{id: 'label_nif', text: $t('cif')}" :datainput="{id: 'input_nif', type: 'text', name: 'nif', class: 'username', error: Login.error_mail && Login.error_mail.code.cif ? Login.error_mail.msg.cif : '', show_message: true}" ref="GhInputCif"/>
                        </div>
                        <div style="width: 313px; margin-left: 24px; margin-top: 20px;">
                            <GhInputLogin :datalabel="{id: 'label_email', text: $t('email')}" :datainput="{id: 'input_email', type: 'text', name: 'email', class: 'email', error: Login.error_mail && Login.error_mail.code.email ? Login.error_mail.msg.email : '', show_message: true}" ref="GhInputEmail"/>
                        </div>
                        <div style="margin-top: 30px; margin-bottom: 15px; flex-flow: row-reverse; display: flex;">
                            <GhAction :dataction="{id: 'close_remember_password', text: $t('close'), class: 't13 alink'}" @click="Global.closePopUp('remember_password')"/>
                            <GhAction :dataction="{id: 'push_remember_password', text: $t('remember_password'), class: 't13 alink'}" @click="doClickRememberPwd()"/>
                        </div>
                    </div>
                </div>

                <div v-show="false" id="rememeber_password_success" class="modal-backdrop">
                    <div class="ayuda_fondo_2_" style="position: static;">
                        <div class="recordarnombre_t11 t11 text-inline" style="color: green; position: relative; white-space: inherit; margin-right: 50px;">
                            {{Login.email_recover}}
                        </div>
                        <div style="margin-top: 55px; z-index: 999; display: flex; flex-flow: row-reverse;">
                            <GhAction :dataction="{id: 'close_remember_password_success', text: $t('close'), class: 't13'}" @click="Global.closePopUp('remember_password'); Global.closePopUp('rememeber_password_success')"/>
                        </div>
                    </div>
                </div>
                <div v-show="false" id="remember_password_error" class="modal-backdrop">
                    <div class="ayuda_fondo_2_" style="position: static;">
                        <div class="recordarnombre_t11 t11 text-inline" style="color: red; position: relative; white-space: inherit; margin-right: 50px;">
                            {{Login.error_mail.msg && Login.error_mail.msg.Normpwd ? Login.error_mail.msg.Normpwd : ''}}
                        </div>
                        <div style="margin-top: 55px; z-index: 999; display: flex; flex-flow: row-reverse;">
                            <GhAction :dataction="{id: 'close_remember_password_error', text: $t('close'), class: 't13'}" @click="Global.closePopUp('remember_password'); Global.closePopUp('remember_password_error')"/>
                        </div>
                    </div>
                </div>
            </template>
        </GhLogin>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import GhLogin from 'fe-gh-login-lib';
    import GhInputLogin from 'fe-gh-input-login-lib';
    import GhButton from 'fe-gh-button-lib';
    import GhAction from 'fe-gh-action-lib';
    export default {
        name: "App",
        components: {
            GhLogin,
            GhInputLogin,
            GhButton,
            GhAction,
        },
        computed: {
            ...mapState(["error","user","errorrmbr","errormailcif","error_cif","error_mail","email_recover","Login"]),
        },
        methods:{
            async submit() {
                this.$refs.GhInputPassword._props.MessageError = '';
                await this.$store.dispatch('GET_USER', {
                    username: this.$refs.GhInputUser.$el.children[1].children[0].value,
                    password: this.$refs.GhInputPassword.$el.children[1].children[0].value
                },{root:true})
            },
            register(){
                this.$store.dispatch('PREREGISTER','',{root:true})
            },
            enterSubmit: function(e){
                if (e.keyCode === 13) {
                    this.submit();
                }
            },
            doClickRememberPwd() {
                this.$store.dispatch('GET_REMEMBER_PASSWORD',{
                    cif: this.$refs.GhInputCif.$el.children[1].children[0].value,
                    email: this.$refs.GhInputEmail.$el.children[1].children[0].value
                },{root:true})
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: IBMPlexSans-Medium;
        src: url('../assets/fonts/IBMPlexSans-Medium.ttf');
    }

    @font-face {
        font-family: IBMPlexSans-Regular;
        src: url('../assets/fonts/IBMPlexSans-Regular.ttf');
    }
    @import "~fe-gh-login-lib/dist/fe-gh-login-lib.css";
    @import '~fe-gh-input-login-lib/dist/fe-gh-input-login-lib.css';
    @import "~fe-gh-action-lib/dist/fe-gh-action-lib.css";
    @import "../assets/css/login/style.css";
    .container-password {
        padding-top: 55px;
        top: 265px;
    }
</style>
