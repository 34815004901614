<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'leave_confirm',
            title: $t('leave_confirm'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: $t('leave_following_center') + ':',
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div style="overflow: auto; max-height: 800px; width: 100%; margin-right: 40px;">
                <div class="gh_delete_confirm-content" style="padding-bottom: 15px;">
                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('center') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{data.center}}</label>
                </div>
                <form id="form_popup_leave">
                    <input type="hidden" name="leave[id]" :value="data.leave_id">
                    <div class="gh-row-content" style="margin-left: 20px; padding-top: 15px;">
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('leave') + '*'}}</label>
                        <GhChecks
                            :datalabel="{}"
                            :datainput="{
                                required: true,
                                label_required: $t('leave') + '*',
                                type: 'radio',
                                id: 'leave_temporal',
                                value: 1,
                                name: 'leave[leave]',
                                selected: true,
                            }"
                            ref="GhRadioTemporal"
                            @click="selectLeave('GhRadioTemporal')"
                        />
                        <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 25px;">{{$t('temporal')}}</label>
                        <GhChecks
                            :datalabel="{}"
                            :datainput="{
                                required: true,
                                label_required: $t('leave') + '*',
                                type: 'radio',
                                id: 'leave_permanent',
                                value: 2,
                                name: 'leave[leave]',
                                selected: '',
                            }"
                            ref="GhRadioPermanent"
                            @click="selectLeave('GhRadioPermanent')"
                        />
                        <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('permanent')}}</label>
                    </div>
                    <div class="gh-row-content" style="margin-left: 20px;" v-if="showDates">
                        <GhCalendar
                            :datalabel="{
                                id: 'leave_date_start',
                                text: $t('start_date'),
                                style: 'text-align: right;'
                            }"
                            :datainput="{
                                id: 'leave_start_date',
                                name: 'leave[start_date]',
                                value: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                required: true
                            }"
                        />
                        <GhCalendar
                            :datalabel="{
                                id: 'leave_date_end',
                                text: $t('end_date'),
                                style: 'width:85px;text-align: right;'
                            }"
                            :datainput="{
                                id: 'leave_end_date',
                                name: 'leave[end_date]',
                                value: '',
                                required: true
                            }"
                        />
                    </div>
                </form>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_leave',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="submitLeave()"
            />
            <GhButton
                :datainput="{
                    id: 'button_clse_leave',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('leave_confirm');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";

    export default{
        name: "popup_leave",
        props: ['data'],
        components: {
            GhPopUp,
            GhChecks,
            GhCalendar,
            GhButton
        },
        data(){
            return {
                showDates: true
            }
        },
        methods: {
            selectLeave(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }

                if(value == 'GhRadioTemporal'){
                    this.showDates = true;
                }else{
                    this.showDates = false;
                }
            },
            async submitLeave(){
                if(this.Global.checkRequired('form_popup_leave') == 'OK'){
                    const formData = new FormData(document.getElementById('form_popup_leave'));
                    await this.$store.dispatch('setLeaveCenter', {
                        formData: [...formData]
                    }, {root: true});

                    this.Global.closePopUp('leave_confirm');
                }
            }
        }
    }
</script>