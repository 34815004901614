var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhInfoBox',{staticStyle:{"margin-top":"6px","margin-left":"3px"},attrs:{"dataBlock":{
        id: 'info_patient_test_imagining',
        border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
        padding:{ top: 20, bottom: 0, left: 20, right: 20 },
        boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
        backgroundColor: 'white',
        type: 'row'
    },"dataTitle":{
        show: true,
        text: 'test_imagining',
        image: require('../../../../assets/svg/test.svg'),
        styleBlock: '',
        textStyle:{
            class: 'T20B style_test_imagining',
        },
        imageStyle:{
            width: '30px',
            height: '30px',
        },
    },"dataExpansible":{
        show: _vm.Patients.data.test_imagining !== undefined && _vm.Patients.data.test_imagining.length >= 2 ? true : false,
        animation: true,
        animationSpeed: 1000,
        textExpand: _vm.$t('show_more_files'),
        textCollapse: _vm.$t('show_less_files'),
        iconExpand: require('../../../../assets/svg/add_plus.svg'),
        iconCollapse: require('../../../../assets/svg/add_less.svg'),
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.Patients.data.test_imagining !== undefined && _vm.Patients.data.test_imagining.length == 0)?[_c('div',{attrs:{"id":"info_patient_test_imagining_show_now"}},[_c('br'),_c('div',{staticClass:"gh-row-content-info"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"5px","color":"green"}},[_vm._v(_vm._s(_vm.$t('no_test_and_imagining')))])])])]:[_c('div',{attrs:{"id":"info_patient_test_imagining_show_now"}},[(_vm.Patients.data.test_imagining !== undefined && _vm.Patients.data.test_imagining.length != 0)?[_vm._l((_vm.Patients.data.test_imagining),function(item,index){return [(index <= 2)?_c('div',{key:index},[_c('br'),_c('div',{staticStyle:{"display":"grid"}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s('• ' + item.name))]),_c('GhAction',{staticStyle:{"margin-top":"-2px"},attrs:{"dataction":{
                                            id: 'action_chain_' + index,
                                            text: '',
                                            icon: require('../../../../assets/svg/chain.svg'),
                                        }}})],1),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"25px","white-space":"nowrap"}},[_vm._v(_vm._s(item.description))]),_c('span',{staticStyle:{"width":"100%","margin-left":"10px","border-bottom":"1px dotted"}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","white-space":"nowrap"}},[_vm._v(_vm._s(item.date))])])])]):_vm._e()]})]:_vm._e()],2),_c('div',{attrs:{"id":"info_patient_test_imagining_show_after"}},[_vm._l((_vm.Patients.data.test_imagining),function(item,index){return [(index >= 3)?_c('div',{key:index},[_c('br'),_c('div',{staticStyle:{"display":"grid"}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s('• ' + item.name))]),_c('GhAction',{staticStyle:{"margin-top":"-2px"},attrs:{"dataction":{
                                        id: 'action_chain_' + index,
                                        text: '',
                                        icon: require('../../../../assets/svg/chain.svg'),
                                    }}})],1),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"25px","white-space":"nowrap"}},[_vm._v(_vm._s(item.description))]),_c('span',{staticStyle:{"width":"100%","margin-left":"10px","border-bottom":"1px dotted"}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","white-space":"nowrap"}},[_vm._v(_vm._s(item.date))])])])]):_vm._e()]})],2)]]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }