import MyFile from '@/view/my_file/sections/sections_my_file.vue';
import MyFileClinics from "@/view/my_file/sections/sections_my_file_clinics.vue";

const routes = [
    {
        path: '/my-file',
        name: 'my_file',
        props: {showMenu: true},
        component: MyFile
    },
    {
        path: '/my-file/clinics/:id',
        name: 'my_file_clinics',
        props: {id: null, showMenu: false, background: true, otherTitle: 'department'},
        component: MyFileClinics
    }
];

export default routes;