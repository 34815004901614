import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTablePermissionsGroups(state, payload) {
            state.records_data = payload;
        },
        loadDataPermissionsGroups(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getPermissionsGroups(state){
            await axios.get(
                API_URL + "permissions/groups"
            ).then(
                response => {
                    state.commit('loadTablePermissionsGroups', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataPermissionsGroups(state, item){
            let  requestLogin = API_URL + "permissions/groups/" + item.id;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadDataPermissionsGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPermissionsGroups(state, item){
            let requestCompaniesGroup =  item.id ? API_URL + "permissions/groups/" + item.id : API_URL + "permissions/groups";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadDataPermissionsGroups", response.data.data);
                    // this.dispatch('getTreeCompanies');
                    router.push({name: 'permissions'});
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};