var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'professional',
    },"header":_vm.header,"data":_vm.SubmitsProfessionals.records_data.length == 0 ? [] : _vm.SubmitsProfessionals.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'professional'}),'view_submit')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_validate_professional_' + itemProp.id,
                text: _vm.$t('validate'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','validateSubmitProfessional', itemProp.id,{'name': itemProp.name, 'surname': itemProp.surname, 'md_number': itemProp.md_number}, _vm.$t('validate_professional'), _vm.$t('you_are_preparing_to_validate_the_following_professional'), 'validate')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_view_professional_' + itemProp.id,
                text: _vm.$t('view'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'professional'}),'view_submit')}}})]}}],null,false,4172721168)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }