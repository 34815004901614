<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'haveData' ? 'OK' : '',
                text: $route.params.name + ' ' + $t('date').toLowerCase() + ' - ' + $route.params.date
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'professional_data',
                    title: $route.params.name
                }"
            >
                <template v-slot:content v-if="haveData">
                    <GhTableCommon
                        :extratable="{
                            id: 'table_professional'
                        }"
                        :header="CalendarDataProfessional.professional_data.header.columns"
                        :data="CalendarDataProfessional.professional_data.data"
                        style="margin-top: 12px;"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <template v-if="itemProp[labelProp] !== ''">
                                {{itemProp[labelProp]}}
                            </template>
                            <template v-else>
                                <div @click="setDate($route.params.date, labelProp, itemProp)" class="table-cell-colored" style="cursor: pointer"></div>
                            </template>
                        </template>
                    </GhTableCommon>
                </template>
            </GhNoDropSelector>
        </div>
        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: 'view_group',
        components: {
            GhButton,
            GhTableCommon,
            GhNoDropSelector,
            GhLocation
        },
        computed:{
            ...mapState(['CalendarDataProfessional', '']),
        },
        data(){
            return{
                haveData: false,
                colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'},
                counter: 0,
                counter2: 0,
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getDataProfessional', {id:this.$route.params.id}, {root: true});
            this.haveData = true;
        },
        updated(){
            var to_for = Object.values(this.CalendarDataProfessional.professional_data.header.values);

            for(var i = 0;i < to_for.length;i++){
                document.getElementById('list_header_table_professional_' + i).style.backgroundColor = this.colors[to_for[i]];
            }
        },
        methods: {
            setDate(date, labelProp, itemProp){
                var hour = this.CalendarDataProfessional.professional_data.header.columns[labelProp].text.split(':')[0];
                var minute = itemProp.minute;

                var response = {
                  'date': date,
                  'hour': hour,
                  'minute': minute
                };

                window.opener.UpdatedSelect(response);

                this.Global.windowClose();
            }
        }
    }

</script>