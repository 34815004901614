import Login from "@/view/login";
import Register from "@/view/register/register.vue";
import Acreditation from "@/view/register/acreditation.vue";

const routes = [
    {
        path: '',
        name: 'login',
        props: {showMenu: false},
        component: Login
    },
    {
        path: '/register/acreditation',
        name: 'pre_register',
        props: {showMenu: true, hiddentabs: true, user: false, showRanking: false},
        component: Acreditation
    },
    {
        path: '/register/:type',
        name: 'register',
        props: {showMenu: true, hiddentabs: true, type: null, user: false, showRanking: false},
        component: Register
    },
];

export default routes;