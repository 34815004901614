<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <template v-if="dataItem.item.config.graphic !== undefined && dataItem.item.config.graphic.input !== undefined && dataItem.item.config.graphic.input.length !== 0">
                    <template v-if="dataItem.item.config.graphic.type === 1">
                        <div class="centered" :style="dataGraphic[0] === undefined || dataGraphic[0].values.length === 0 || dataGraphic[0].values.length === 1 ? 'margin-top: 0px;' : 'margin-top: -25px;'" v-if="haveData" :id="'graphic_line_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem">
                            <template v-if="dataGraphic[0] === undefined || dataGraphic[0].values.length === 0 || dataGraphic[0].values.length === 1">
                                <label class="gh_text_field-label T15_b" style="color: red;">{{$t('display_the_graph_necessary_add_least_two_records')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{'(' + $t('remaining') + ': ' + (2 - dataGraphic[0].values.length) + ')'}}</label>
                            </template>
                            <template v-else>
                                <GhChartLine
                                    :dataBlock="{
                                        id: 'graphic_' + dataItem.item.config.graphic.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id
                                    }"
                                    :chartConfig="{
                                        width: 800,
                                        height: 300,
                                        xTicks: 1,
                                        parseDate: '%d/%m/%Y %H:%M',
                                        timeFormat: '%d/%m/%Y %H:%M',
                                        color: dataItem.item.config.graphic.color,
                                    }"
                                    :chartData="dataGraphic"
                                ></GhChartLine>
                            </template>
                        </div>
                    </template>
                    <template v-else-if="dataItem.item.config.graphic.type === 2">
                        <div class="centered" style="margin-left: 99px;" v-if="haveData" :id="'graphic_bar_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem">
                            <template v-if="dataGraphic[0] === undefined || dataGraphic[0].values.length === 0">
                                <label class="gh_text_field-label T15_b" style="color: red;">{{$t('display_the_graph_necessary_add_least_one_record')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{'(' + $t('remaining') + ': ' + (1 - dataGraphic[0].values.length) + ')'}}</label>
                            </template>
                            <template v-else>
                                <GhChartBarVertical
                                    :chartConfig="{
                                        percentage: false,
                                        width: 950,
                                        height: 350,
                                        ticksY: 6,
                                        chardata:{
                                            xdata:{
                                                style:'',
                                                x: 0,
                                                y: 15
                                            }
                                        }
                                    }"
                                    :dataBlock="{
                                         id: 'graphic_' + dataItem.item.config.graphic.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id,
                                         class: '',
                                         style: '',
                                         datemode: true,
                                         multibar: true,
                                         showMax: true,
                                         parsedate: '%d/%m/%Y %H:%M',
                                         parseformat: '%d/%m/%Y %H:%M',
                                    }"
                                    :color="dataItem.item.config.graphic.color"
                                    :chartData="dataGraphic"
                                />
                            </template>
                        </div>
                    </template>
                    <template v-else-if="dataItem.item.config.graphic.type === 3">
                        <div class="centered" :style="dataGraphic[1] === undefined || dataGraphic[1].data.length === 0 || dataGraphic[1].data.length === 1 ? 'padding-bottom: 20px;' : 'margin-top: -25px; margin-left: -139px;'" v-if="haveData" :id="'graphic_area_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem">
                            <template v-if="dataGraphic[1] === undefined || dataGraphic[1].data.length === 0 || dataGraphic[1].data.length === 1">
                                <label class="gh_text_field-label T15_b" style="color: red;">{{$t('display_the_graph_necessary_add_least_two_records')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{'(' + $t('remaining') + ': ' + (2 - dataGraphic[1].data.length) + ')'}}</label>
                            </template>
                            <template v-else>
                                <GhChartArea
                                    :dataBlock="{
                                        id: dataItem.item.config.graphic.id
                                    }"
                                    :chartConfig="{
                                        width: 852,
                                        height: 290,
                                        margin: {
                                            top: 0,
                                            bottom: 0,
                                            left: 40,
                                            right: 80
                                        },
                                        parseDate: '%d/%m/%Y %H:%M',
                                        timeFormat: '%H:%M',
                                        color: dataItem.item.config.graphic.color,
                                        type: 'relative',
                                        xAxis: {
                                            type: 'timeHour'
                                        },
                                        xTicks: 2,
                                        legendOrientation: 'bottom'
                                    }"
                                    :chartData="dataGraphic"
                                ></GhChartArea>
                            </template>
                        </div>
                    </template>
                    <div :class="'grid-container' + (dataItem.item.config.graphic.input.length < 5 ? '-' + dataItem.item.config.graphic.input.length : '')" :style="dataItem.item.config.graphic.type !== 3 ? 'padding-top: 15px;' : 'margin-top: -5px;'">
                        <div v-for="(item, key) in dataItem.item.config.graphic.input" class="grid-item centered" :key="key">
                            <GhInputTextField
                                :datalabel="{
                                    text: item.label,
                                    style: '',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'input_' + dataItem.item.config.graphic.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item_quantity',
                                    name: 'form_values[' + processId + '][' + subprocessId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item][' + item.id + ']',
                                    style: 'width: 100px',
                                    value: '',
                                    type: 'float',
                                    limitNumMin: dataItem.item.config.graphic.min !== '' ? dataItem.item.config.graphic.min : undefined,
                                    limitNumMax: dataItem.item.config.graphic.max !== '' ? dataItem.item.config.graphic.max : undefined
                                }"
                                :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item' + key"
                            />
                        </div>
                    </div>
                    <div class="centered" style="padding-top: 15px;">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_data_graphic_information_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem,
                                text: $t('add_data')
                            }"
                            @click="setGraphicData"
                        />
                    </div>
                </template>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChartLine from "fe-gh-chart-line-lib";
    import GhChartArea from "fe-gh-chart-area-lib";
    import GhChartBarVertical from "fe-gh-chart-vertical-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: 'view_graphic',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        computed: {
            ...mapState(['Process'])
        },
        components: {
            GhInputTextField,
            GhChartLine,
            GhChartArea,
            GhChartBarVertical,
            GhButton
        },
        data(){
            return {
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                haveData: false,
                dataGraphic: [],
                newGraphic: []
            }
        },
        async mounted(){
            if(this.dataItem.item !== undefined && this.dataItem.item.data !== undefined && this.dataItem.item.data.data !== undefined){
                this.dataGraphic = this.dataItem.item.data.data;

                localStorage.setItem('GraphicRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id, JSON.stringify(this.dataItem.item.data.data));
                localStorage.setItem('GraphicNewRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id, JSON.stringify(this.newGraphic));

                var that = this;
                setTimeout(function(){
                    that.haveData = true;
                },10);
            }
        },
        methods: {
            setGraphicData(){
                this.$parent.setChangesSection(this.subprocessId, this.$parent.dataSubprocess.id);

                switch (this.dataItem.item.config.graphic.type){
                    case 1:
                        this.getChartLine();
                        break;
                    case 2:
                        this.getChartBar();
                        break;
                    case 3:
                        this.getChartArea();
                        break;
                }
            },
            getBarOrLine(){
                this.newGraphic = JSON.parse(localStorage.getItem('GraphicNewRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id));
                var date = new Date();
                var day = date.getDate();
                var month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
                var year = date.getFullYear();
                var hour = date.getHours();
                var minute = date.getMinutes().toString();

                Object.entries(this.$refs).forEach((ref, key) => {
                    if(this.newGraphic[key] === undefined) {
                        this.newGraphic[key] = new Array();
                    }

                    var value = ref[1][0].datainput.value;
                    value = value === '' || value === undefined ? this.dataItem.item.config.graphic.min : value;

                    var params = {
                        'label': day.toString() + '/' + month.toString() + '/' + year.toString() + ' ' + hour.toString() + ':' + minute.toString(),
                        'value': value.toString()
                    }

                    this.newGraphic[key].push(params);
                });

                localStorage.setItem('GraphicNewRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id, JSON.stringify(this.newGraphic));

                this.newGraphic.forEach((item, key) => {
                    this.dataGraphic[key].values = [...JSON.parse(localStorage.getItem('GraphicRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id))[key].values, ...item];
                });
            },
            getChartLine(){
                this.getBarOrLine();

                document.getElementById('graphic_line_' + this.processId + '_' + this.subprocessId + '_' + this.formId + '_' + this.sectionId + '_item_' + this.dataItem.id + '_' + this.indexItem).children[0].__vue__.refreshData++;
            },
            getChartBar(){
                this.getBarOrLine();

                document.getElementById('graphic_bar_' + this.processId + '_' + this.subprocessId + '_' + this.formId + '_' + this.sectionId + '_item_' + this.dataItem.id + '_' + this.indexItem).children[0].__vue__.refreshData++;

            },
            getChartArea(){
                this.newGraphic = JSON.parse(localStorage.getItem('GraphicNewRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id));

                var tempLineChart = {};
                var date = new Date();
                var day = date.getDate();
                var month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
                var year = date.getFullYear();
                var hour = date.getHours();
                var minute = date.getMinutes().toString();

                tempLineChart['year'] = day.toString() + '/' + month.toString() + '/' + year.toString() + ' ' + hour.toString() + ':' + minute.toString();

                Object.entries(this.$refs).forEach((ref) => {
                    var value = ref[1][0].datainput.value;
                    var keyValue = ref[1][0].datainput.name.split('[item][')[1].split(']')[0];
                    value = value === '' || value === undefined ? this.dataItem.item.config.graphic.min : value;

                    tempLineChart[keyValue] = parseInt(value);
                });

                this.newGraphic.push(tempLineChart);

                localStorage.setItem('GraphicNewRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id, JSON.stringify(this.newGraphic));

                this.dataGraphic[1].data = [...JSON.parse(localStorage.getItem('GraphicRef' + this.processId + this.subprocessId + this.formId + this.sectionId + this.indexItem + this.dataItem.id))[1].data, ...this.newGraphic];

                document.getElementById('graphic_area_' + this.processId + '_' + this.subprocessId + '_' + this.formId + '_' + this.sectionId + '_item_' + this.dataItem.id + '_' + this.indexItem).children[0].__vue__.refreshData++;
            }
        }
    }
</script>

<style>
    @import "~fe-gh-chart-line-lib/dist/fe-gh-chart-line-lib.css";
</style>