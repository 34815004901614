import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        section_info: '',
        data: {
            patient_not_presented: [],
            non_active_professionals: []
        }
    },
    mutations: {
        loadIncidenceSectionInfo(state, payload) {
            state.section_info = payload;
        },
        loadDataPatientNotPresented(state, payload){
            state.data.patient_not_presented = payload;
        },
        loadDataNonActiveProfessionals(state, payload){
            state.data.non_active_professionals = payload;
        }
    },
    actions: {
        async getIncidenceSections(state){
            await axios.get(
                API_URL + "incidence/sections"
            ).then(
                response => {
                    state.commit('loadIncidenceSectionInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getIncidenceData(state, item){
            await axios.get(
                API_URL + "incidence/" + item.type
            ).then(
                response => {
                    state.commit(item.type == 'patient-not-presented' ? 'loadDataPatientNotPresented' : 'loadDataNonActiveProfessionals', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setReasignIncidence(state, item){
            var type = item.type == 1 ? 'non-active-professionals' : 'patient-not-presented';
            let requestLogin = API_URL + "incidence/" + type + "/reasign";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200'){
                        state.dispatch('getIncidenceData', {type: type});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};