var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhInfoBox',{attrs:{"dataBlock":{
        id: 'info_patient_reason_for_visit',
        border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
        padding:{ top: 10, bottom: 10, left: 10, right: 10 },
        boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
        backgroundColor: 'white',
        type: 'height',
        width: '',
        height: '',
    },"dataTitle":{
        show: false
    },"dataExpansible":{
        show: false
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-row-content-info"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('reason_for_visit') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15"},[_vm._v(_vm._s(_vm.Patients.data.pending_visit.reason_for_visit))])]),_c('div',{staticClass:"gh-row-content-info"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('md') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.Patients.data.pending_visit.md))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.Patients.data.pending_visit.date))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('time') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"50px"}},[_vm._v(_vm._s(_vm.Patients.data.pending_visit.time))])]),_c('div',{staticClass:"gh-row-content-info"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('patient_location') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15"},[_vm._v(_vm._s(_vm.Patients.data.pending_visit.patient_location))])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }