import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default {
    state: {
        records_data: '',
        data: [],
        searchData: [],
        records: {
            added:[],
            deleted:[],
            default:[],
        },
    },
    mutations: {
        loadTableConfigurationSupportAssignments(state,payload){
            state.records_data = payload
        },
        loadDataConfigurationSupportAssignments(state, payload){
            state.data = payload;
        },
        loadAssignmentDataDefault(state,payload){
            state.records.default = payload
        },
        loadTemporalAssignmentUsers(state,payload){
            state.data.users = payload;
        },
        loadSearchAssignmentUsers(state,payload){
            state.searchData = payload;
        },
    },
    actions: {
        async getConfigurationSupportAssignments(state){
            await axios.get(
                API_URL + "support/groups"
            ).then(
                response => {
                    state.commit('loadTableConfigurationSupportAssignments', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationSupportAssignments(state, item){
            await axios.get(
                API_URL + "support/groups/" + item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationSupportAssignments', response.data.data);
                    response.data.data.users ? state.commit("loadAssignmentDataDefault", response.data.data.users) : '';
                },
                error => {
                    console.log(error);
                }
            )
        },
        cleanTemporalAssignmentData(state){
            state.state.records.added = [];
            state.state.records.deleted = [];
            state.state.records.default = [];
        },
        async setDataConfigurationSupportAssignments(state, item){
            let requestLogin = item.id ? API_URL + "support/groups/" + item.id : API_URL + "support/groups";
            let formData = new FormData();

            formData.append('id', item.id);

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            for (let i = 0; i < state.state.records.added.length; i++) {
                formData.append('group[users][add][' + i + '][id]', state.state.records.added[i].id);
            }
            if(state.state.records.deleted.length!==0) {
                for (let i = 0; i < state.state.records.deleted.length; i++) {
                    formData.append('group[users][delete][' + i + ']', state.state.records.deleted[i].id);
                }
            }
            axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200) {
                        this.dispatch('getConfigurationSupportAssignments');
                        this.dispatch('cleanTemporalAssignmentData');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        setTemporalAssignmentUsers(state,items){
            if( state.state.data['users']) {
                state.commit('loadTemporalAssignmentUsers', items.data)
                state.state.records.deleted = [];
                state.state.records.added = [];
                for (let i = 0; i <= state.state.records.default.length; i++) {
                    if (state.state.records.default[i] !== undefined) {
                        var index = items.data.findIndex(assignment => assignment.id == state.state.records.default[i].id);
                        if (index == -1) {
                            state.state.records.deleted.push(state.state.records.default[i])
                        }
                    }
                }
                for (let i = 0; i <= items.data.length; i++) {
                    if (items.data[i] !== undefined) {
                        index = state.state.records.default.findIndex(assignment => assignment.id == items.data[i].id);
                        if (index == -1) {
                            state.state.records.added.push(items.data[i])
                        }
                    }
                }
                for (let i = 0; i <= state.state.records.added.length; i++) {
                    if (state.state.records.added[i] !== undefined) {
                        index = items.data.findIndex(assignment => assignment.id == state.state.records.added[i].id);
                        if (index == -1) {
                            state.state.records.added.splice(i, 1)
                        }
                    }
                }
            }
        },
        async getSearchAssignmentUsers(state,item){
            let requestSearchCompanies = API_URL + "search/users";
            let payload = {};
            item.formData.forEach((item) => {
                payload[item[0]] = item[1] ;
            });
            axios({
                method: "get",
                url: requestSearchCompanies,
                params: payload,
            }).then(
                response => {
                    state.commit("loadSearchAssignmentUsers", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected) == true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationSupportAssignments(state, item){
            let requestLogin = API_URL + "support/groups/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getConfigurationSupportAssignments');
                        state.rootState.ConfigurationSupport.section_info.assignment.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}