var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'reasign_confirm',
        title: _vm.$t('reasign'),
        type: 'warning',
        style: 'width: 35%; min-width: 430px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: _vm.$t(_vm.data.type == 1 ? 'reasign_following_non_active_professional' : 'reasign_following_patient_not_presented') + ':',
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px"}},[_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"padding-bottom":"15px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.data.name))]),_c('br'),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.data.surname))])]),_c('form',{attrs:{"id":"form_popup_reasign"}},[_c('input',{attrs:{"type":"hidden","name":"reasign[id]"},domProps:{"value":_vm.data.id}}),_c('input',{attrs:{"type":"hidden","name":"reasign[type]"},domProps:{"value":_vm.data.type}}),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"20px","padding-top":"15px"}},[(_vm.data.type == 1)?[_c('GhMultipleSearch',{attrs:{"datalabel":{
                                text: _vm.$t('reasign') + '*',
                                style: '',
                                class: 'T15_b',
                                id: 'label_reasign'
                            },"datainput":{
                                required: true,
                                id: 'reasign_user',
                                name: 'reasign[user][name]',
                                nameS:'reasign[user][surname]',
                                nameId:'reasign[user][id]',
                                style: 'width: 40%',
                                value: '',
                                valueId: '',
                                valueS: '',
                                placeholder: _vm.$t('name'),
                                placeholderS: _vm.$t('surname'),
                                result_1: 'first_name',
                                result_2: 'last_name',
                                result_id: 'id',
                                href: _vm.dispatchUserExecution
                            }}})]:[_c('GhCalendar',{attrs:{"datalabel":{
                                text: _vm.$t('visit_date'),
                            },"datainput":{
                                id: 'reasign_visit_date',
                                name: 'reasign[visit_date]',
                                value: '',
                                required: true
                            }}}),_c('GhInputHour',{attrs:{"datalabel":{text: _vm.$t('visit_hour')},"datainput":{
                                id: 'reasign_input_hour',
                                name: 'reasign[input_hour]',
                                hour: '',
                                minute: '',
                                required: true
                            }}})]],2)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_reasign',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.submitReasign()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_clse_reasign',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('reasign_confirm');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }