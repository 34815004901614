import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: ''
    },
    mutations: {
        loadSupportPendingRequests(state,payload){
            state.records_data = payload
        }
    },
    actions: {
        async getSupportPendingRequests(state){
            await axios.get(
                API_URL + "support/tickets/groups-tickets"
            ).then(
                response => {
                    state.commit('loadSupportPendingRequests', response.data.data);
                }
            )
        },
    }
}