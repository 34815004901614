var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[_c('GhInputTextField',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"datalabel":{
                    text: _vm.$t('during') + (_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.period !== undefined ? ' ' + _vm.$t(_vm.dataItem.item.config.input.period).toLowerCase() : '') + (_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required ? '*' : ''),
                    style: '',
                    class: 'T15_b'
                },"datainput":{
                    required: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required,
                    id: 'input_' + _vm.dataItem.item.config.input.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item',
                    name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item]',
                    style: 'width: 100px',
                    value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.item : '',
                    type: 'int'
                }},on:{"change":function($event){_vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);_vm.$parent.checkExtraItem(_vm.$el, _vm.haveExtraItem, _vm.$refs, 6, _vm.dataItem.item.config.input.id, _vm.processId, _vm.subprocessId, _vm.formId, _vm.sectionId, _vm.indexItem, _vm.dataItem.id)}}}),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }