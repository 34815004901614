import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        section_info: '',
        data: {
            calendar: [],
            historic_patient: []
        }
    },
    mutations: {
        loadMyFileCenterSectionInfo(state, payload) {
            state.section_info = payload;
        },
        loadDataCalendar(state, payload){
            state.data.calendar = payload;
        },
        loadDataHistoricPatient(state, payload){
            state.data.historic_patient = payload;
        }
    },
    actions: {
        async getMyFileCenterSectionsById(state, item){
            await axios.get(
                API_URL + "my-file/clinics/" + item.id + "/sections"
            ).then(
                response => {
                    state.commit('loadMyFileCenterSectionInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMyFileClinicsData(state, item){
            await axios.get(
                API_URL + "my-file/clinics/" + item.type
            ).then(
                response => {
                    state.commit(item.type == 'calendar' ? 'loadDataCalendar' : 'loadDataHistoricPatient', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setLeaveCenter(state, item){
            let requestLogin = API_URL + "my-file/clinics/leave";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200'){
                        state.dispatch('getMyFileData', {type: 'clinics'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};