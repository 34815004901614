import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationVariableSpecialty(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationVariableSpecialty(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationVariableSpecialty(state){
            await axios.get(
                API_URL + "configuration/variable/specialties"
            ).then(
                response => {
                    state.commit('loadTableConfigurationVariableSpecialty', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationVariableSpecialty(state, item){
            await axios.get(
                API_URL + "configuration/variable/specialties/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationVariableSpecialty', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationVariableSpecialty(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/variable/specialties/" + item.id : API_URL + "configuration/variable/specialties";
            let employees = {0:'admin', 1:'md', 2:'nurses', 3:'anesthesiologists', 4:'other'};
            let formData = new FormData();

            formData.append('specialty[id]', item.id);
            if(item.defaultData.length == 0){
                item.formData.forEach((items) => {
                    var type_input = items[0].split('[')[1].split(']')[0];
                    if(Object.values(employees).includes(type_input)){
                        formData.append(items[0].split('[]')[0] + '[add][]', items[1]);
                    }else{
                        formData.append(items[0], items[1]);
                    }
                });
            }else{
                var includePost = new Array();
                item.formData.forEach((items) => {
                    var type_input = items[0].split('[')[1].split(']')[0];
                    if(Object.values(employees).includes(type_input)){
                        var count = includePost[type_input] === undefined ? 0 : includePost[type_input].length;
                        if(count == 0){
                            includePost[type_input] = new Array();
                        }
                        includePost[type_input].push(items[1].toString());
                    }
                });

                for(var i = 0;i < Object.values(employees).length;i++){
                    if(!Object.keys(includePost).includes(Object.values(employees)[i])){
                        var interlude = '';
                        includePost[Object.values(employees)[i]] = new Array();
                        includePost[Object.values(employees)[i]].push(interlude);

                        var toPush = {
                            0: 'specialty[' + Object.values(employees)[i] + '][]',
                            1: ''
                        };
                        item.formData.push(toPush)
                    }
                }


                var checked = new Array();
                item.formData.forEach((i) => {
                    var type_input = i[0].split('[')[1].split(']')[0];
                    if(includePost[type_input] !== undefined){
                        if(!checked.includes(type_input)) {
                            includePost[type_input].forEach((items) => {
                                if (!Object.values(item.defaultData[type_input].selecteds).includes(items) && items !== '') {
                                    formData.append('specialty[' + type_input + '][add][]', items);
                                }
                            });

                            item.defaultData[type_input].selecteds.forEach((items) => {
                                if (!Object.values(includePost[type_input]).includes(items)) {
                                    formData.append('specialty[' + type_input + '][delete][]', items);
                                }
                            });
                        }
                    }else{
                        formData.append(i[0], i[1]);
                    }
                    checked.push(type_input);
                });
            }

            return await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        return 'OK'
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationVariableSpecialty(state, item){
            let requestLogin = API_URL + "configuration/variable/specialties/" + item.id + "/remove";
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationVariableSpecialty');
                        await this.dispatch('getConfigurationVariableSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanConfigurationVariableSpecialty(state){
            state.commit('loadDataConfigurationVariableSpecialty', []);
        }
    }
};