<template>
    <div style="padding-left: 15px; padding-top: 12px; padding-bottom: 15px; padding-right: 15px;">
        <GhTreeActionBlock v-if="haveData"
            :dataBlock="{
                id: 'tree_companies',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
            }"
            :data="PermissionsClinics.records_data"
        >
            <template v-slot:Action="Action">
                <router-link :to="{name: 'permissions_edit_company', params: {id: Action.Action.Action.Action.id}}">
                    <GhAction
                        :dataction="{
                            id: 'btn_edit_company_' + Action.Action.Action.Action.id,
                            text: $t('edit'),
                            key_show_condition: '',
                            value_show_condition: '',
                            icon: require('../../../assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
            </template>
        </GhTreeActionBlock>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_companies',
        components: {
            GhTreeActionBlock,
            GhAction
        },
        computed: {
            ...mapState(['PermissionsClinics']),
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionsClinics', '', {root: true});
            this.haveData = true;
        }
    }
</script>