<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_support_category'
        }"
        :header="header"
        :data="ConfigurationSupportCategory.records_data == '' ? [] : ConfigurationSupportCategory.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_support_view_category', {id: itemProp.id, view: true}),'configuration_support_category')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_category_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_support_edit_category', {id: itemProp.id}),'configuration_support_category')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_category_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationSupportCategories', itemProp.id,{'category': itemProp.name}, $t('delete_category'), $t('preparing_delete_category'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_conf_supp_categories',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_support_new_category', {}),'configuration_support_category')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "list_category",
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigurationSupportCategory'])
        },
        data(){
            return{
                haveData:false,
                header:[
                    {text: 'category', field: 'name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'width:90%;'},
                    {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportCategories', '', {root: true});
            this.haveData = true;
        }
    }
</script>