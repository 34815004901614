<template>
    <GhInfoBox :key="refresh"
        style="margin-top: 6px; margin-right: 3px;"
        :dataBlock="{
            id: 'info_patient_upcoming_and_past_appoinments',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 0, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'height',
            height: $parent.$parent.actualHeight
        }"
        :dataTitle="{
            show: true,
            text: 'upcoming_and_past_appoinments',
            image: require('../../../../assets/svg/calendar.svg'),
            styleBlock: '',
            textStyle:{
                class: 'T20B',
            },
            imageStyle:{
                width: '30px',
                height: '30px',
            },
        }"
        :dataExpansible="{
            show: showExpansible,
            animation: true,
            animationSpeed: 1000,
            textExpand: $t('show_more_appointments'),
            textCollapse: $t('show_less_appointments'),
            iconExpand: require('../../../../assets/svg/add_plus.svg'),
            iconCollapse: require('../../../../assets/svg/add_less.svg'),
        }"
    >
        <template v-slot:content>
            <div id="info_patient_upcoming_and_past_appoinments_slide_selector" class="T15_b" style="margin-top: 20px;">
                <GhSlideSelector
                    :dataBlock="{
                        colors:{
                            active: '#b3dbff',
                            nonActive: '#d9edfc',
                            textActive: '#ffffff !important',
                            textNonActive: '#000000',
                        },
                        startActive: state
                    }"
                    :data="SlideSelectorData"
                />
            </div>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";
    import GhSlideSelector from "fe-gh-slide-selector-lib";

    import upcoming_appoinments from "@/view/patients/views/partials/upcoming_appoinments.vue";
    import past_appoinments from "@/view/patients/views/partials/past_appoinments.vue";

    export default {
        name: "info_patient_upcoming_and_past_appoinments",
        components: {
            GhSlideSelector,
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
        data(){
            return {
                SlideSelectorData:[
                    {name: this.$t('upcoming'), template: upcoming_appoinments},
                    {name: this.$t('past'), template: past_appoinments},
                ],
                showExpansible: true,
                refresh: 0,
                state: 0,
                newHeight: 0
            }
        },
        beforeMount() {
            this.newHeight = this.$parent.$parent.actualHeight;
        }
    }
</script>