var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        }}}),_c('form',{attrs:{"id":"form_schedule_surgery"}},[_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_schedule_surgery',
                title: _vm.$t('schedule_surgery')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"10px","margin-top":"10px","background-color":"#ff00009e !important"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px","color":"white !important"}},[_vm._v(_vm._s(_vm.WarningsSurgery.warning_surgery_data.label))])]),_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('aviable_short_notice_cancelation_list') + '?'))]),_c('GhChecks',{ref:"RefShortNotice1",attrs:{"datalabel":{
                                text: _vm.$t('yes'),
                                style: 'margin-right: 50px;'
                            },"datainput":{
                                id: 'schedule_surgical_available_short_notice',
                                value: 1,
                                type: 'radio',
                                name: 'schedule_surgical[available_short_notice]',
                                style: 'cursor:pointer; ',
                                selected: ''
                            }},on:{"click":function($event){return _vm.selectShortNotice('RefShortNotice1')}}}),_c('GhChecks',{ref:"RefShortNotice2",attrs:{"datalabel":{
                                text: _vm.$t('no'),
                            },"datainput":{
                                id: 'schedule_surgical_available_short_notice',
                                value: 0,
                                type: 'radio',
                                name: 'schedule_surgical[available_short_notice]',
                                style: 'cursor:pointer; ',
                                selected: ''
                            }},on:{"click":function($event){return _vm.selectShortNotice('RefShortNotice2')}}})],1),(_vm.showNoticePeriod)?_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('notice_period') + '*',
                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'schedule_surgical_notice_period',
                                name: 'schedule_surgical[notice_period]',
                                style: 'width: 50px',
                                value: '',
                                type: 'text',
                                placeholder: 'Days'
                            }}})],1):_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{ref:"differentSurgeon",attrs:{"datablock":{
                                id: 'label_schedule_surgical_other_staff',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_different_surgeon') + ':',
                                text: _vm.$t('willing_accept_different_surgeon') + '?',
                                label: _vm.$t('willing_accept_different_surgeon'),
                                type: 'checkbox',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            },"datainput":{
                                id: 'schedule_surgical_other_staff',
                                name: 'schedule_surgical[other_staff]'
                            },"feed":_vm.getFeeds.doctors,"selected_input":[]},on:{"accept":_vm.loadDifferentSurgeon}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('able_go_other_hospital') + '?'))]),_c('GhChecks',{ref:"RefOtherHospital1",attrs:{"datalabel":{
                                text: _vm.$t('yes'),
                                style: 'margin-right: 50px;'
                            },"datainput":{
                                id: 'schedule_surgical_other_hospital',
                                value: 1,
                                type: 'radio',
                                name: 'schedule_surgical[other_hospital]',
                                style: 'cursor:pointer; ',
                                selected: ''
                            }},on:{"click":function($event){return _vm.selectOtherHospital('RefOtherHospital1')}}}),_c('GhChecks',{ref:"RefOtherHospital2",attrs:{"datalabel":{
                                text: _vm.$t('no'),
                            },"datainput":{
                                id: 'schedule_surgical_other_hospital',
                                value: 0,
                                type: 'radio',
                                name: 'schedule_surgical[other_hospital]',
                                style: 'cursor:pointer; ',
                                selected: ''
                            }},on:{"click":function($event){return _vm.selectOtherHospital('RefOtherHospital2')}}})],1),(_vm.showOtherHospital)?_c('div',{staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'result_search_data_groups',
                                nameStore: 'getFeeds',
                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                id: 'schedule_surgical_other_hospital',
                                text: _vm.$t('other_hostpital') + '*',
                                textlocation: _vm.$t('clinic_search'),
                                actionresult: 'loadResultSearchClinics',
                                nameResult: 'schedule_surgical[other_hospital_id]',
                                supportPermision: false,
                                required: true
                            },"mainSelector":{
                                name: 'Formsearchclinic',
                                formId: 'search',
                                actionsearch: 'getSearchClinics'
                            },"tableResolt":{
                                header: _vm.header,
                                data: []
                            },"tableSelected":{
                                headersummary: _vm.headersummary,
                                selected: []
                            }}})],1):_vm._e(),(_vm.showOtherHospital)?_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'label_schedule_surgical_other_doctor',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_other_doctor') + ':',
                                text: _vm.$t('other_doctor') + '*',
                                label: _vm.$t('other_doctor'),
                                type: 'checkbox',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            },"datainput":{
                                id: 'schedule_surgical_other_doctor',
                                name: 'schedule_surgical[other_doctor]'
                            },"feed":_vm.getFeeds.doctors,"selected_input":[]}})],1):_vm._e(),(_vm.showOptimalSchedule2)?_c('div',{key:_vm.refreshToCalendar,staticClass:"gh-content-global"},[_vm._l((_vm.WarningsSurgery.other_surgeon),function(item,index){return _c('div',{key:'diff_surgeon-'+index},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"43%","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.type) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"100px"}},[_vm._v(_vm._s(item.doctor))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(item.date))]),_c('GhAction',{staticStyle:{"margin-top":"-2px","margin-left":"10px"},attrs:{"dataction":{
                                        id: 'action_details_' + item.id,
                                        text: _vm.$t('details'),
                                        icon: require('../../../../assets/svg/add_plus.svg'),
                                    }},on:{"click":function($event){return _vm.openDetails(item.id)}}}),(!_vm.showScheduleSelect1[item.id])?[_c('GhAction',{ref:"RefScheduleSelect1",refInFor:true,staticStyle:{"margin-top":"-2px"},attrs:{"dataction":{
                                            id: 'action_select_' + item.id,
                                            text: _vm.$t('select')
                                        }},on:{"click":function($event){return _vm.checkSchedule(item.id)}}})]:[_c('GhAction',{staticStyle:{"margin-top":"-2px","margin-left":"10px"},attrs:{"dataction":{
                                            id: 'action_select_' + item.id,
                                            text: '',
                                            icon: require('../../../../assets/svg/tick.svg'),
                                        }},on:{"click":function($event){return _vm.unCheckSchedule(item.id)}}})]],2),(_vm.showScheduleSelect1[item.id])?[_c('GhScheduleCalendar',{ref:"RefSchedule",refInFor:true,staticStyle:{"margin-top":"15px","margin-bottom":"25px"},attrs:{"dataBlock":{
                                        id: 'schedule_select_' + item.id,
                                        backgroundColorNumber: '#ffffff',
                                        widthHour: _vm.widthHour,
                                        colors: {
                                            0:'rgb(156, 178, 198, 0.7)',
                                            1:'rgb(99, 204, 110, 0.7)',
                                            2:'rgb(253, 245, 121, 0.7)',
                                            3:'rgb(255, 103, 103, 0.7)',
                                            4:'#0000009e'
                                        }
                                    },"data":_vm.Surgery.calendar[item.id]},on:{"prevM":function($event){_vm.toPrev(_vm.Surgery.calendar[item.id]['prevMonth']);_vm.actualMDID = item.id},"days":_vm.toDays,"nextM":function($event){_vm.toNext(_vm.Surgery.calendar[item.id]['nextMonth']);_vm.actualMDID = item.id}}})]:_vm._e()],2)}),_c('input',{attrs:{"type":"hidden","name":"schedule_surgical[different_surgeon_selected]"},domProps:{"value":_vm.different_surgeon_selected}})],2):_vm._e()]),(_vm.showOptimalSchedule2 && _vm.showBtnOptimalSchedule)?_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_optimal_schedule',
                            text: _vm.$t('optimal_schedule'),
                        }},on:{"click":function($event){_vm.checkOptimalSchedule, _vm.openDetails('details')}}})],1):_vm._e(),(_vm.showOptimalSchedule2 && _vm.showBtnOptimalSchedule)?_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content centered"},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s('(' + _vm.$t('only_information_purposes') + ')'))])]),_c('br'),_c('div',{staticClass:"gh-row-content centered"},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.$t('note_in_schedule_surgery')))])])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submit()}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                id: 'detail_popup',
                title: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'Optimal schedule' : _vm.$t('Details'),
                type: 'warning',
                style: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'width: 98%; min-width: 430px' : 'width: 35%; min-width: 430px'
            },"bodyConfiguration":{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'Surgeries to re-scheduled for an optimal schedule' : '',
            },"footerConfiguration":{
                id: '',
                class: '',
                style: '',
                content: ''
            }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px"}},[(_vm.type && _vm.type !== 'type1' && _vm.type !== 'type2')?[_c('div',{staticClass:"gh-content-global"},[_c('GhTableCommon',{staticStyle:{"margin-top":"-10px"},attrs:{"extratable":{
                                id: 'surgery_instruments_',
                            },"header":_vm.header_details,"data":_vm.WarningsSurgery.optimal_schedule}})],1)]:[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('Surgery') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.type == 'type1' ? _vm.$t('Tonsillectomy and Adenoidectomy') : _vm.$t('Tonsillectomy and Adenoidectomy')))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('MD') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.type == 'type1' ? _vm.$t('Dr.Nguyen') : _vm.$t('Dr.Manoukian')))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('OR #') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.type == 'type1' ? 7 : 11))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('Date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.type == 'type1' ? '15/11/2023' : '15/12/2023'))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"127px","min-width":"127px","text-align":"right"}},[_vm._v(_vm._s('Time' + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.type == 'type1' ? '11:00' : '11:00'))])])])]],2)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                        id: 'button_accept',
                        text: _vm.$t('accept'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }},on:{"click":function($event){return _vm.Global.closePopUp('detail_popup');}}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_close',
                        text: _vm.$t('close'),
                    }},on:{"click":function($event){return _vm.Global.closePopUp('detail_popup');}}})]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }