<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_relationship',
                    title: $t('relationship')
                }"
            >
                <template v-slot:content>
                    <form id="form_relationship" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'relationship_code',
                                            name: 'relationship[code]',
                                            style: 'width: 200px',
                                            value: ConfigurationGeneralDataRelationship.data !== undefined ? ConfigurationGeneralDataRelationship.data.code : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationGeneralDataRelationship.data !== undefined ? ConfigurationGeneralDataRelationship.data.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'relationship_name',
                                            name: 'relationship[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationGeneralDataRelationship.data !== undefined ? ConfigurationGeneralDataRelationship.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationGeneralDataRelationship.data !== undefined ? ConfigurationGeneralDataRelationship.data.name : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="!this.$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitRelationship();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GhLocation from "fe-gh-location-lib";
import GhNoDropSelector from "fe-gh-no-dropselector-lib";
import GhInputTextField from "fe-gh-inputtextfield-lib";
import GhButton from "fe-gh-button-lib";

import {mapState} from "vuex";
import {loadLanguageAsync} from "@/main";

export default {
    name: "view_relationship",
    components: {
        GhLocation,
        GhNoDropSelector,
        GhInputTextField,
        GhButton
    },
    computed: {
        ...mapState(['ConfigurationGeneralDataRelationship'])
    },
    data(){
        return {
            type: '',
            haveData: false
        }
    },
    async beforeMount(){
        var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
        loadLanguageAsync(lang);
        if(this.$root._route.params.id !== undefined){
            await this.$store.dispatch('getDataConfigurationGeneralDataRelationship', {id: this.$root._route.params.id}, {root: true});
        }else{
            this.ConfigurationGeneralDataRelationship.data = [];
        }

        this.type = this.$root._route.name.split('configuration_general_data_')[1].split('_relationship')[0];
        this.haveData = true;
    },
    methods: {
        async submitRelationship(){
            if(this.Global.checkRequired('form_relationship') == 'OK'){
                const formData = new FormData(document.getElementById('form_relationship'));
                await this.$store.dispatch('setDataConfigurationGeneralDataRelationship', {
                    id: this.$root._route.params.id,
                    formData: [...formData],
                }, {root: true});

                this.Global.windowClose();
            }
        }
    }
}
</script>
