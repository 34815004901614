<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <GhInputTextField
                    :datalabel="{
                        text: $t('during') + (dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.period !== undefined ? ' ' + $t(dataItem.item.config.input.period).toLowerCase() : '') + (dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required ? '*' : ''),
                        style: '',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: dataItem.item.config !== undefined && dataItem.item.config.input !== undefined && dataItem.item.config.input.required,
                        id: 'input_' + dataItem.item.config.input.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                        name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]',
                        style: 'width: 100px',
                        value: dataItem.item.data !== undefined ? dataItem.item.data.item : '',
                        type: 'int'
                    }"
                    :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                    @change="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);$parent.checkExtraItem($el, haveExtraItem, $refs, 6, dataItem.item.config.input.id, processId, subprocessId, formId, sectionId, indexItem, dataItem.id)"
                />
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'view_time',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhChecks,
            GhInputTextField
        },
        data(){
            return{
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                haveExtraItem: this.dataItem.item.config !== undefined && this.dataItem.item.config.extra_item !== undefined ? this.dataItem.item.config.extra_item : false
            }
        }
    }
</script>
