<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'permissions_groups',
        }"
        :header="header"
        :data="PermissionsGroups.records_data.length === 0 ? [] : PermissionsGroups.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'permissions_view_group', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'permissions_edit_group', params: {id: itemProp['id']}}">
                <GhAction
                    :dataction="{
                        id: 'edit_group_' + itemProp['id'],
                        text: $t('edit'),
                        key_show_condition: 'toShow',
                        value_show_condition: false,
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }"
                />
            </router-link>
        </template>
        <template v-slot:footer>
            <div class="centered">
                <router-link :to="{name: 'permissions_new_group'}">
                    <GhButton
                        :datainput="{
                            id: 'btn_add_group',
                            text: $t('add')
                        }"
                    />
                </router-link>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_groups',
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['PermissionsGroups']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'name', field: 'name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 70%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionsGroups', '', {root: true});
            this.haveData = true;
        }
    }
</script>