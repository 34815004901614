<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_equipments',
            title: $t('equipments'),
            type: 'warning',
            style: 'width: 50%; min-width: 700px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:15px; margin-left:25px;',
            text: $t('select_equipments') + ':',
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_popup_equipments">
                <div class="gh-content-global" :key="refresh" style="display: flex">
                    <div style="display: block;width: 50%;">
                        <div v-for="(item,index) in preEquipmentsArray" :key="index" style="padding-bottom: 7px">
                            <GhChecks
                                :datainput="{
                                    id: 'equipments_selection_' + item.id,
                                    value: item.id,
                                    type: 'checkbox',
                                    name: 'equipments[]',
                                    style: 'cursor:pointer;',
                                    selected: selecteds.includes(item.id),
                                }"
                                :datalabel="{
                                    style: '',
                                    class: 'T15 input_label',
                                    id: 'label_equipments_selection_' + item.id,
                                    text: item.name
                                }"
                            />
                        </div>
                    </div>
                    <div style="display: block;width: 50%;">
                        <div v-for="(item,index) in postEquipmentsArray" :key="index" style="padding-bottom: 7px">
                            <GhChecks
                                :datainput="{
                                    id: 'equipments_selection_' + item.id,
                                    value: item.id,
                                    type: 'checkbox',
                                    name: 'equipments[]',
                                    style: 'margin-left:48px; cursor:pointer;',
                                    selected: selecteds.includes(item.id),
                                }"
                                v-bind:datalabel="{
                                    style: '',
                                    class: 'T15 input_label',
                                    id: 'label_equipments_selection_' + item.id,
                                    text: item.name
                                }"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_popup_equipments',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="setEquipment"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_popup_equipments',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_equipments');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState} from "vuex";

    export default{
        name: "popup_equipments",
        components: {
            GhPopUp,
            GhButton,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationEquipmentsEquipments'])
        },
        data(){
            return {
                preEquipmentsArray: [],
                postEquipmentsArray: [],
                refresh: 0,
                selecteds: []
            }
        },
        watch: {
            'ConfigurationEquipmentsEquipments.records_data'(){
                this.getValuesForPopup();
            }
        },
        methods: {
            async setEquipment(){
                if(this.Global.checkRequired('form_popup_equipments') === 'OK'){
                    const formData = new FormData(document.getElementById('form_popup_equipments'));
                    await this.$store.dispatch('setEquipmentByProfessional', {
                        formData: [...formData],
                    }, {root: true});

                    this.Global.closePopUp('popup_equipments');
                }
            },
            getValuesForPopup(){
                this.preEquipmentsArray = [];
                this.postEquipmentsArray = [];

                for (var x = 0; x < this.ConfigurationEquipmentsEquipments.records_data.length; x++){
                    this.selecteds.push(this.ConfigurationEquipmentsEquipments.records_data[x].id);
                }


                var divide = Math.round(Object.keys(this.getFeeds.equipment).length / 2);

                for(var i = 0; i < Object.keys(this.getFeeds.equipment).length; i++){
                    var params = {
                        'id': Object.keys(this.getFeeds.equipment)[i],
                        'name': this.getFeeds.equipment[Object.keys(this.getFeeds.equipment)[i]]
                    }

                    if(i < divide){
                        this.preEquipmentsArray.push(params);
                    }else{
                        this.postEquipmentsArray.push(params);
                    }
                }

                this.refresh++;
            }
        }
    }
</script>