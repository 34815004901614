import SectionDepartment from "@/view/calendar/department/sections/section_department.vue"
import ViewDepartment from "@/view/calendar/department/views/department.vue"

const route = [
    {
        path: '/calendar/department',
        name: 'calendar_department',
        component: SectionDepartment,
        props: {showMenu: true}
    },
    {
        path: '/calendar/department/:id/:name/:date',
        name: 'view_department',
        component: ViewDepartment,
        props: {showMenu: false, background: true, id: null, name: undefined, date: undefined},
    },
];

const routes = [
    ...route,
]

export default routes;