var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[(_vm.dataItem.item.config.matrix !== undefined && _vm.dataItem.item.config.matrix.input !== undefined && _vm.dataItem.item.config.matrix.input.length !== 0)?[_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                        id: 'table_' + _vm.dataItem.item.config.label.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem
                    },"header":_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.matrix !== undefined && _vm.dataItem.item.config.matrix.header !== undefined ? _vm.dataItem.item.config.matrix.header : [],"data":_vm.dataTable}}),(_vm.dataItem.item.config.matrix.required)?[_c('input',{staticClass:"required",attrs:{"type":"hidden","required":"required","label_required":_vm.dataItem.item.config.label !== undefined && _vm.dataItem.item.config.label.text !== undefined ? _vm.dataItem.item.config.label.text : ''},domProps:{"value":_vm.dataTable.length !== 0 ? 1 : 0}})]:_vm._e(),_c('div',{class:'grid-container' + (_vm.dataItem.item.config.matrix.input.length < 5 ? '-' + _vm.dataItem.item.config.matrix.input.length : ''),staticStyle:{"padding-top":"15px"}},_vm._l((_vm.dataItem.item.config.matrix.input),function(item,key){return _c('div',{key:key,staticClass:"grid-item centered"},[_c('GhInputTextField',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item' + key,refInFor:true,attrs:{"datalabel":{
                                text: item.label,
                                style: '',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'input_' + _vm.dataItem.item.config.matrix.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item_quantity',
                                name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item][' + item.id + ']',
                                style: 'width: 100px',
                                value: '',
                                type: 'float',
                                limitNumMin: item.min !== '' ? item.min : undefined,
                                limitNumMax: item.max !== '' ? item.max : undefined
                            }}})],1)}),0),_c('div',{staticClass:"centered",staticStyle:{"padding-top":"15px"}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_data_matrix_information_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem,
                            text: _vm.$t('add_data')
                        }},on:{"click":_vm.setMatrixData}})],1)]:_vm._e(),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }