<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_general_data_genders_',
        }"
        :header="header"
        :data="ConfigurationGeneralDataGender.records_data.length == 0 ? [] : ConfigurationGeneralDataGender.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_view_gender', {id: itemProp.id, view: true}),'configuration_gender')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_genders_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_edit_gender', {id: itemProp.id}),'configuration_gender')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_genders_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationGeneralDataGender', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, $t('delete_gender'), $t('preparing_delete_gender'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_new_gender',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_new_gender', {}),'configuration_gender')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_genders",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationGeneralDataGender'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        watch: {
            async 'Global.state.temp_response.response'(data){
                await this.$store.dispatch('getConfigurationGeneralDataGenders', '', {root: true});
                if(data.split('-')[1] == 'add') {
                    this.$store.state.ConfigurationGeneralData.section_info.genders.result++;
                }
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationGeneralDataGenders', '', {root: true});
            this.haveData = true;
        }
    }
</script>