<template>
    <div class="gh-content-global" v-if="haveData">
        <div style="display: flex">
            <div class="gh-row-content" style="width: 150px;" v-if="$root._route.path.includes('/surgery') || $root._route.path === '/my-file' || ($root._route.params.type === 'professional' && $root._route.params.view !== undefined && $root._route.params.view === 'true')">
                <img :src="DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.image : ''" height="120">
            </div>
            <div style="width: 100%">
                <div class="gh-row-content">
                    <div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t($root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_' + ($root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name'),
                                        name: 'register[' + $root._route.params.type + '][initial_registration][' + ($root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name') + ']',
                                        style: 'min-width: 130px; width: 100%',
                                        value: '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t($root._route.params.type == 'clinic' || $root._route.name === 'my_file_clinics'  ? 'name_of_clinic' : 'name')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? $root._route.params.type == 'clinic' ||  $root._route.name === 'my_file_clinics' ? DataClinicsProfessionals.data.initial_registration.name_of_clinic : DataClinicsProfessionals.data.initial_registration.name : ''}}</label>
                            </template>
                        </div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t($root._route.params.type == 'clinic' ? 'business_number' : 'surname') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_' + ($root._route.params.type == 'clinic' ? 'business_number' : 'surname'),
                                        name: 'register[' + $root._route.params.type + '][initial_registration][' + ($root._route.params.type == 'clinic' ? 'business_number' : 'surname') + ']',
                                        style: 'min-width: 130px; width: 100%',
                                        value: '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t($root._route.params.type == 'clinic' || $root._route.name === 'my_file_clinics' ? 'business_number' : 'surname')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? $root._route.params.type == 'clinic' || $root._route.name === 'my_file_clinics' ? DataClinicsProfessionals.data.initial_registration.business_number : DataClinicsProfessionals.data.initial_registration.surname : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content" v-if="$root._route.path.includes('/surgery') || $root._route.path == '/my-file' || $root._route.params.type == 'professional' || $root._route.params.type == 'professional-second-phase'">
                    <div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.path !== '/my-file' && $root._route.params.type !== 'professional-second-phase' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('md_number') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_md_number',
                                        name: 'register[' + $root._route.params.type + '][initial_registration][md_number]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('md_number')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.md_number : ''}}</label>
                            </template>
                        </div>
                        <div style="width: 33.33%; display: flex" v-if="!$root._route.path.includes('/surgery') && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                            <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture') + '*'}}</label>
                            <GhEasyUpload
                                :extrablock="{
                                    id: 'register_' + $root._route.params.type + '_initial_registration_upload',
                                    class: '',
                                    name_hidden: 'register[' + $root._route.params.type + '][initial_registration][upload]',
                                    id_hidden: 'register_' + $root._route.params.type + '_initial_registration_upload',
                                    icon_attach: ''
                                }"
                                :datalabel="{
                                    id: 'label_register_' + $root._route.params.type + '_initial_registration_upload',
                                    style: 'margin-right: -15px'
                                }"
                                :datainput="{
                                    view: false,
                                    accept: 'image/png, image/gif, image/jpeg',
                                    text: $t('upload'),
                                    label_required: $t('picture') + '*',
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    file_name: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined && DataClinicsProfessionals.data.initial_registration.image !== undefined ? DataClinicsProfessionals.data.initial_registration.image.file_name : '',
                                    file_path: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined && DataClinicsProfessionals.data.initial_registration.image !== undefined ? DataClinicsProfessionals.data.initial_registration.image.file_path : '',
                                    file_download: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined && DataClinicsProfessionals.data.initial_registration.image !== undefined ? DataClinicsProfessionals.data.initial_registration.image.file_download : '',
                                    required: true
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' &&  $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t($root._route.params.type == 'clinic' ? 'social_address' : 'referral_number') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_' + ($root._route.params.type == 'clinic' ? 'address' : 'referral_number'),
                                        name: 'register[' + $root._route.params.type + '][initial_registration][' + ($root._route.params.type == 'clinic' ? 'address' : 'referral_number') + ']',
                                        style: 'min-width: 130px; width: 100%',
                                        value: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.referral_number : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t($root._route.params.type == 'clinic' || $root._route.name === 'my_file_clinics' ? 'social_address' : 'referral_number')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? $root._route.params.type == 'clinic' || $root._route.name === 'my_file_clinics' ? DataClinicsProfessionals.data.initial_registration.address : DataClinicsProfessionals.data.initial_registration.referral_number : ''}}</label>
                            </template>
                        </div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 66.67%;' : 'width: 66.67%; display: flex;'" id="selector_specialties">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'country_contact_specialties',
                                        class: 'T15_b',
                                        subtitle: $t('select_specialties') + ':',
                                        text: $t('specialties'),
                                        label: $t('specialties'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'register_' + $root._route.params.type + '_initial_registration_specialties',
                                        name: 'register[' + $root._route.params.type + '][initial_registration][specialties][]'
                                    }"
                                    :feed="getFeeds.specialties"
                                    :selected_input="$root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.specialties : []"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('specialties')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.specialties : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_email',
                                        name: 'register[' + $root._route.params.type + '][initial_registration][email]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.email : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('email')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.email : ''}}</label>
                            </template>
                        </div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('telephone') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_telephone',
                                        name: 'register[' + $root._route.params.type + '][initial_registration][telephone]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.telephone : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('telephone')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.telephone : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!$root._route.path.includes('/surgery') && $root._route.name !== 'my_file_clinics' && $root._route.path !== '/my-file' && ($root._route.params.view === undefined || $root._route.params.view === 'false')">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('fax') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'register_' + $root._route.params.type + '_initial_registration_fax',
                                        name: 'register[' + $root._route.params.type + '][initial_registration][fax]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.fax : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('fax')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.initial_registration !== undefined ? DataClinicsProfessionals.data.initial_registration.fax : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import {mapState} from "vuex";

    export default {
        name: "initial_registration",
        components: {
            GhInputTextField,
            GhDataSelector,
            GhEasyUpload
        },
        data(){
            return {
                haveData: false,
            }
        },
        computed: {
            ...mapState(['getFeeds', 'DataClinicsProfessionals']),
        },
        async beforeMount() {
            if(this.$parent.$parent.is_surgery === undefined) {
                if (this.$root._route.params.view === undefined || this.$root._route.params.view === 'false') {
                    await this.$store.dispatch('getFeedSpecialties', '', {root: true});
                }
            }

            if(this.$parent.$parent.is_surgery !== undefined && this.$parent.$parent.is_surgery){
                await this.$store.dispatch('getDataProfessionalBySurgery', {id: this.$root._route.params.id}, {root: true});
            }

            this.haveData = true;
        }
    }
</script>

<style>
    #country_contact_specialties_selector_label{
        width: 150px;
        text-align: end;
    }

    #selector_specialties > div{
        min-width: 500px !important;
    }

    #selector_specialties > div > div{
        margin-bottom: 0px !important;
    }
</style>