var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[(_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.id_recurrence !== undefined)?[_c('label',{staticClass:"gh_text_field-label T15",attrs:{"id":'label_' + _vm.dataItem.item.config.label.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem + '_item_recurrence_title'}},[_vm._v(" "+_vm._s(_vm.$t('recurrence') + ':')+" ")]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},attrs:{"id":'label_' + _vm.dataItem.item.config.label.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem + '_item_recurrence_result'}},[_vm._v(" "+_vm._s(_vm.dataItem.item.config.input.id_recurrence === 1 ? _vm.$t('continous') : _vm.$t('intermittent'))+" ")]),_c('div',{staticStyle:{"padding-top":"8px","display":"flex"}},[_c('GhInputTextField',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"datalabel":{
                            text: _vm.$t('quantity') + (_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required ? '*' : ''),
                            style: '',
                            class: 'T15_b'
                        },"datainput":{
                            required: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required,
                            id: 'input_' + _vm.dataItem.item.config.input.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item_quantity',
                            name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item][quantity]',
                            style: 'width: 100px',
                            value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.quantity : '',
                            type: 'float'
                        }},on:{"change":function($event){_vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);_vm.$parent.checkExtraItem(_vm.$el, _vm.haveExtraItem, _vm.$refs, 7, _vm.dataItem.item.config.input.id, _vm.processId, _vm.subprocessId, _vm.formId, _vm.sectionId, _vm.indexItem, _vm.dataItem.id)}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"130px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.dataItem.item.config.input.quantity)+" ")]),(_vm.dataItem.item.config.input.id_recurrence === 2)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('period_every') + (_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required ? '*' : ''),
                                style: '',
                                class: 'T15_b'
                            },"datainput":{
                                required: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required,
                                id: 'input_' + _vm.dataItem.item.config.input.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item_period_every',
                                name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item][period_every]',
                                style: 'width: 100px',
                                value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.period_every : '',
                                type: 'int'
                            }},on:{"change":function($event){return _vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"130px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.dataItem.item.config.input.period_every))+" ")]),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('period_lasting') + (_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required ? '*' : ''),
                                style: '',
                                class: 'T15_b'
                            },"datainput":{
                                required: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.input !== undefined && _vm.dataItem.item.config.input.required,
                                id: 'input_' + _vm.dataItem.item.config.input.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item_period_lasting',
                                name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item][period_lasting]',
                                style: 'width: 100px',
                                value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.period_lasting : '',
                                type: 'int'
                            }},on:{"change":function($event){return _vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.dataItem.item.config.input.period_lasting))+" ")])]:_vm._e()],2)]:_vm._e(),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }