var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-left":"15px","padding-top":"12px","padding-bottom":"15px","padding-right":"15px"}},[(_vm.haveData)?_c('GhTreeActionBlock',{attrs:{"dataBlock":{
            id: 'tree_companies',
            actionWithoutChildren: true,
            actionWithChildren: true,
            extraLabelNumber: false,
            extraLabelText: '',
        },"data":_vm.PermissionsClinics.records_data},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [_c('router-link',{attrs:{"to":{name: 'permissions_edit_company', params: {id: Action.Action.Action.Action.id}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'btn_edit_company_' + Action.Action.Action.Action.id,
                        text: _vm.$t('edit'),
                        key_show_condition: '',
                        value_show_condition: '',
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    }}})],1)]}}],null,false,3183899169)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }