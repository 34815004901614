var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'patients_',
    },"header":_vm.header,"data":_vm.Patients.records_data.length == 0 ? [] : _vm.Patients.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_patient_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('edit_patient', {id: itemProp.id}),'patient')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_patient_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removePatient', itemProp.id,{'id': itemProp.code, 'name': itemProp.name}, _vm.$t('delete_patient'), _vm.$t('preparing_delete_patient'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_new_patient',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_patient', {}),'patient')}}})],1)]},proxy:true}],null,false,2169222679)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }