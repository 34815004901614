<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_professional',
                    title: $t('professional')
                }"
            >
                <template v-slot:content>
                    <form id="form_professional" v-if="haveData">
                        <div style="display: flex;">
                            <div class="gh-content-global" style="width: 170px">
                                <div style="width: 168px; height: 168px;">
                                    <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
                                </div>
                            </div>
                            <div class="gh-content-global" style="width: 100%">
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('code') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_code',
                                                name: 'professional[code]',
                                                style: 'width: 200px',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.code : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.code : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_name',
                                                name: 'professional[name]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.name : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.name : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('surname') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_surname',
                                                name: 'professional[surname]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.surname : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.surname : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'professional_type_professional',
                                                class: 'T15_b',
                                                subtitle: $t('select_professional_type') + ':',
                                                text: $t('professional_type'),
                                                label: $t('professional_type'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'professional_professional_type',
                                                name: 'professional[professional_type][]'
                                            }"
                                            :feed="getFeeds.professional_type"
                                            :selected_input="ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.professional_type : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('professional_type') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? Object.values(ConfigurationProfessionals.data.professional_type).join() : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <label class="gh_text_field-label T15_b" style="width: 150px; text-align: end; margin-right: 10px;">{{$t('private')}}</label>
                                        <GhChecks
                                            :datalabel="{}"
                                            :datainput="{
                                                required: false,
                                                type: 'checkbox',
                                                id: 'professional_private',
                                                value: 1,
                                                name: 'professional[private]',
                                                selected: ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.private !== undefined && ConfigurationProfessionals.data.private == 1 ? true : false
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('status') + ':'}}</label>
                                        <template v-if="ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.private !== undefined ">
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">
                                                <template v-if="ConfigurationProfessionals.data.private == 1">
                                                    {{$t('yes').toUpperCase()}}
                                                </template>
                                                <template v-else>
                                                    {{$t('no').toUpperCase()}}
                                                </template>
                                            </label>
                                        </template>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture') + '*'}}</label>
                                    <GhEasyUpload
                                        :extrablock="{
                                            id: 'professional_picture',
                                            class: '',
                                            name_hidden: 'professional[picture]',
                                            id_hidden: 'professional_picture',
                                            icon_attach: ''
                                        }"
                                        :datalabel="{
                                            id: 'label_professional_picture',
                                            style: 'margin-right: 5px'
                                        }"
                                        :datainput="{
                                            view: $root._route.params.view,
                                            accept: 'image/png, image/gif, image/jpeg',
                                            text: $t('upload'),
                                            show_image: false,
                                            delete: true,
                                            href: 'easyUpload',
                                            file_name: ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.logo !== undefined ? ConfigurationProfessionals.data.logo.file_name : '',
                                            file_path: ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.logo !== undefined ? ConfigurationProfessionals.data.logo.file_path : '',
                                            file_download: ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.logo !== undefined ? ConfigurationProfessionals.data.logo.file_download : '',
                                            required: true
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('md_number') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_md_number',
                                                name: 'professional[md_number]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.md_number : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('md_number') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.md_number : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('referral_number') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_referral_number',
                                                name: 'professional[referral_number]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.referral_number : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('referral_number') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.referral_number : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'professional_specialties',
                                                class: 'T15_b',
                                                subtitle: $t('select_specialties') + ':',
                                                text: $t('specialties'),
                                                label: $t('specialties'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'professional_specialties',
                                                name: 'professional[specialties][]'
                                            }"
                                            :feed="getFeeds.specialties"
                                            :selected_input="ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.specialties : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? Object.values(ConfigurationProfessionals.data.specialties).join() : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('telephone') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_telephone',
                                                name: 'professional[telephone]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.telephone : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.telephone : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('fax') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_fax',
                                                name: 'professional[fax]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.fax : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('fax') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.fax : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('email') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'professional_email',
                                                name: 'professional[email]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.email : '',
                                                type: 'text'
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationProfessionals.data !== undefined ? ConfigurationProfessionals.data.email : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" v-if="$root._route.params.id !== undefined">
                                    <template v-if="!$root._route.params.view">
                                        <GhMultiUpload
                                            :datalabel="{
                                                text: $t('required_documents'),
                                                text_popup: $t('required_documents'),
                                                input_text: $t('name'),
                                                textarea_text: $t('description'),
                                                attach_text: $t('attach'),
                                                content_warning: '',
                                                label_required: $t('required_documents'),
                                                required: false
                                            }"
                                            :datablock="{
                                                id: 'professional_documents',
                                                name: 'professional[documents]',
                                                style: '',
                                                class: 'T15_b',
                                                icon_attach: '',
                                                icon_trash: '',
                                                icon_download: '',
                                                href: dispatchEvidence
                                            }"
                                            :defaultfiles="ConfigurationProfessionals.data !== undefined && ConfigurationProfessionals.data.required_documents !== undefined ? ConfigurationProfessionals.data.required_documents : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('required_documents') + ':'}}</label>
                                        <div v-for="(file,index) in ConfigurationProfessionals.data.required_documents" :key="index">
                                            <label class="gh_text_field-label T15_b"><a class="C1 F5" :href="file.file_download">{{file.name}}</a>{{index != Object.keys(ConfigurationProfessionals.data.required_documents).pop() ? ', ' : ''}}</label>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitProfessional();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhMultiUpload from "fe-gh-multi-upload-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";


    export default {
        name: "view_professional",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhChecks,
            GhEasyUpload,
            GhMultiUpload,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationProfessionals', 'UploadFiles'])
        },
        data(){
            return{
                type: '',
                haveData: false,
                image: '',
                dispatchEvidence: 'easyUpload'
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.url;
                this.setPersist();
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationProfessionals', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationProfessional', {id: this.$root._route.params.id}, {root: true});
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedSpecialties', '', {root: true});
                await this.$store.dispatch('getFeedProfessionalType', '', {root: true});
            }

            if(this.ConfigurationProfessionals.data !== undefined && this.ConfigurationProfessionals.data.logo !== undefined && this.ConfigurationProfessionals.data.logo.file_download !== undefined && this.ConfigurationProfessionals.data.logo.file_download !== ''){
                this.image = this.ConfigurationProfessionals.data.logo.file_download;
            }else{
                this.image = require('../../../../assets/gh_new_dessign/no-image.svg');
            }

            this.type = this.$root._route.name.split('configuration_professionals_')[1].split('_')[0];
            this.haveData = true;
        },
        methods: {
            setPersist(){
                console.log('entro');
            },
            async submitProfessional(){
                if(this.Global.checkRequired('form_professional') === 'OK'){
                    const formData = new FormData(document.getElementById('form_professional'));
                    await this.$store.dispatch('setDataConfigurationProfessional', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #professional_type_professional_selector_label, #professional_specialties_selector_label{
        width: 150px;
        text-align: end;
    }

    #hidden_list_multiple_files_professional_documents{
        min-width: 200px !important;
    }

    #professional_documents_SUMMARY {
        margin-right: 0px;
    }
</style>