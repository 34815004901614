<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'permissions_profiles',
        }"
        :header="header"
        :data="PermissionProfiles.records_data.length === 0 ? [] : PermissionProfiles.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'permissions_view_profile', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'permissions_edit_profile', params: {id: itemProp['id']}}">
                <GhAction
                    :dataction="{
                        id: 'edit_profile_' + itemProp['id'],
                        text: $t('edit'),
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }"
                />
            </router-link>
            <GhAction style="margin-right: 23px;"
                :dataction="{
                    id: 'delete_profile_' + itemProp['id'],
                    text: $t('delete'),
                    icon: require('../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removePermissionsProfiles', itemProp.id, {'name': itemProp.name, 'description': itemProp.description}, $t('delete_profile'), $t('preparing_delete_profile'), 'remove');"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <router-link :to="{name: 'permissions_new_profile'}">
                    <GhButton
                        :datainput="{
                            id: 'btn_add_profile',
                            text: $t('add')
                        }"
                    />
                </router-link>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_profiles',
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['PermissionProfiles']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'name', field: 'name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 70%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionsProfiles', '', {root: true});
            this.haveData = true;
        }
    }
</script>