<template>
    <GhInfoBox
        style="margin-top: 6px; margin-right: 3px;"
        :dataBlock="{
            id: 'info_patient_forms',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 0, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'row'
        }"
        :dataTitle="{
            show: true,
            text: 'forms',
            image: require('../../../../assets/svg/form.svg'),
            styleBlock: '',
            textStyle:{
                class: 'T20B',
            },
            imageStyle:{
                width: '30px',
                height: '30px',
                class: 'forms_icon'
            },
        }"
        :dataExpansible="{
            show: Patients.data.forms !== undefined && Patients.data.forms.length >= 1 ? true : false,
            animation: true,
            animationSpeed: 1000,
            textExpand: $t('show_more_forms'),
            textCollapse: $t('show_less_forms'),
            iconExpand: require('../../../../assets/svg/add_plus.svg'),
            iconCollapse: require('../../../../assets/svg/add_less.svg'),
        }"
    >
        <template v-slot:content>
            <template v-if="Patients.data.forms !== undefined && Patients.data.forms.length == 0">
                <div id="info_patient_forms_show_now">
                    <br>
                    <div class="gh-row-content-info">
                        <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_forms')}}</label>
                    </div>
                </div>
            </template>
            <template v-else>
                <div id="info_patient_forms_show_now">
                    <template v-if="Patients.data.forms !== undefined && Patients.data.forms.length != 0">
                        <template v-for="(item, index) in Patients.data.forms">
                            <div v-if="index <= 1" :key="index">
                                <br>
                                <div style="display: grid">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{'• ' + item.name}}</label>
                                    <div style="display: flex; width: 100%">
                                        <label class="gh_text_field-label T15" style="margin-left: 25px; width: 100%" :style="item.pending ? 'color: red' : ''">{{item.description}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-left: 10px; white-space: nowrap;" :style="item.pending ? 'color: red' : ''" v-if="item.pending">{{$t('pending_to_review_sign')}}</label>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div id="info_patient_forms_show_after">
                    <template v-for="(item, index) in Patients.data.forms">
                        <div v-if="index > 1" :key="index">
                            <br>
                            <div style="display: grid">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{'• ' + item.name}}</label>
                                <div style="display: flex; width: 100%">
                                    <label class="gh_text_field-label T15" style="margin-left: 25px; width: 100%" :style="item.pending ? 'color: red' : ''">{{item.description}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 10px; white-space: nowrap;" :style="item.pending ? 'color: red' : ''" v-if="item.pending">{{$t('pending_to_review_sign')}}</label>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_forms",
        components: {
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>