<template>
    <div id="option_to_select_table" v-if="haveData">
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_' + $parent.$parent.type_item + '_table_selected',
                        class: 'T15_b',
                        subtitle: $t('select_table') + ':',
                        text: $t('load_table') + '*',
                        label: $t('load_table'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    }"
                    :datainput="{
                        id: $parent.$parent.type_item + '_import_selected',
                        name: $parent.$parent.type_item + '[import][selected]'
                    }"
                    :feed="getFeeds.tables.feeds"
                    :selected_input="table"
                    ref="RefImportTableSelected"
                    @accept="getSelectedImportTable"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('load_table') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{table !== [] ? Object.values(table).join() : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="showStructure" :key="'A' + structureRefresh">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_' + $parent.$parent.type_item + '_structure_selected',
                        class: 'T15_b',
                        subtitle: $t('select_columns') + ':',
                        text: $t('load_columns') + '*',
                        label: $t('load_columns'),
                        type: 'checkbox',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    }"
                    :datainput="{
                        id: $parent.$parent.type_item + '_structure_selected',
                        name: $parent.$parent.type_item + '[import][structure][]'
                    }"
                    :feed="getFeeds.structure"
                    :selected_input="structure.selected"
                    ref="RefImportTableStructure"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('load_columns') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{structure !== [] ? Object.values(structure.selected).join() : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="showStructure">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('how_many_columns') + '?',
                        style: 'width: 150px; min-width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: $parent.$parent.type_item + '_how_many_columns',
                        name: $parent.$parent.type_item + '[import][how_many_columns]',
                        style: 'min-width: 50px; width: 50px',
                        value: columns,
                        type: 'int',
                        limitNumMin: 1,
                        limitNumMax: 3,
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('how_many_columns') + '?'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{columns}}</label>
            </template>
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'view_import',
        components: {
            GhInputTextField,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false,
                table: [],
                updated: this.$parent.$parent.updated,
                showStructure: false,
                structureRefresh: 0,
                structure: {
                    selected: {},
                },
                columns: ''
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.import !== undefined){
                if(this.$parent.$parent.stateData.data.import.selected !== undefined) {
                    this.table = this.$parent.$parent.stateData.data.import.selected;
                    this.showStructure = true;
                }

                if(this.$parent.$parent.stateData.data.import.structure !== undefined){
                    this.structure.selected = this.$parent.$parent.stateData.data.import.structure;
                }

                if(this.$parent.$parent.stateData.data.import.columns !== undefined){
                    this.columns = this.$parent.$parent.stateData.data.import.columns;
                }
            }

            if(!this.$root._route.params.view) {
                await this.$store.dispatch('getFeedTables', '', {root: true});
            }
            this.haveData = true;
        },
        updated() {
            if(!this.updated) {
                this.openTables();
            }

            this.updated = true;
        },
        methods: {
            openTables(){
                this.Global.openPopUp('label_' + this.$parent.$parent.type_item + '_import_selected_data_selector_popup');
                this.$refs.RefImportTableSelected.StatusPopup();
            },
            async getSelectedImportTable(){
                var selected = Object.keys(this.$refs.RefImportTableSelected.checkdata)[0];
                await this.$store.dispatch('getStructureSelected', {call: this.getFeeds.tables.call[selected]}, {root:true});

                this.showStructure = false;
                this.showStructure = true;
                this.structureRefresh++
                var that = this;
                setTimeout(function(){
                    that.Global.openPopUp('label_' + that.$parent.$parent.type_item + '_structure_selected_data_selector_popup');
                    that.$refs.RefImportTableStructure.StatusPopup();
                },30);
            }
        }
    }
</script>