import Groups from "@/view/permissions/views/view_group.vue";

const routes = [
    {
        path: '/permissions/group',
        name: 'permissions_new_group',
        component: Groups,
        props: {id: null, showMenu: true, otherTitle: 'group'},
        children: [
            {
                path: '/permissions/group/:id',
                name: 'permissions_edit_group',
                component: Groups,
                props: {id: null, showMenu: true, otherTitle: 'group'},
            },
            {
                path: '/permissions/group/:id/:view',
                name: 'permissions_view_group',
                component: Groups,
                props: {id: null, view: true, showMenu: true, otherTitle: 'group'},
            },
        ]
    },
];

export default routes;