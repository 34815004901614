<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'upcoming_patients_',
        }"
        :header="header"
        :data="VisitsUpcomingPatients.records_data.length == 0 ? [] : VisitsUpcomingPatients.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_reschedule_upcoming_patients_' + itemProp.id,
                    text: $t('reschedule'),
                    icon: require('../../../assets/svg/calendar2.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('edit_visit_schedule', {id: itemProp.id, type: 'reschedule'}),'schedule')"
            />
            <GhAction
                :dataction="{
                    id: 'action_arrived_upcoming_patients_' + itemProp.id,
                    text: $t('arrived'),
                    icon: require('../../../assets/svg/tick.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','setArrivedUpcomingPatient', itemProp.id,{'ohip': itemProp.ohip, 'name': itemProp.name, 'surname': itemProp.surname, 'md': itemProp.md, 'date': itemProp.date, 'time': itemProp.time}, $t('arrived_patients'), $t('preparing_arrived_patients'), 'arrived')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_upcoming_patients",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['VisitsUpcomingPatients'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, urlRedirect: true, typeSort: 'string', style: 'max-width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'md', field: 'md', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'date', field: 'date', sorting: true, typeSort: 'string', style: 'max-width: 100px;'},
                    {text: 'time', field: 'time', sorting: true, typeSort: 'string', style: 'max-width: 100px;'},
                    {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'max-width: 100px'},
                    {text: 'type_of_visit', field: 'type_of_visit', sorting: true, typeSort: 'string', style: 'max-width: 100px;'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ]
            }
        },
        async beforeMount(){
            this.$store.dispatch('getVisitsUpcomingPatientsList', {type: this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1]}, {root: true});
            this.haveData = true;
        }
    }
</script>