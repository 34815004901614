import Incidences from '@/view/incidences/sections/section_incidences.vue';

const routes = [
    {
        path: '/incidences',
        name: 'incidences',
        props: {showMenu: true},
        component: Incidences
    },
];

export default routes;