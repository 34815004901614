import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        section_info: '',
        records_data: '',
        data: []
    },
    mutations: {
        loadPatientsInfo(state, payload) {
            state.section_info = payload;
        },
        loadPatientList(state, payload) {
            state.records_data = payload;
        },
        loadPatientData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getPatientsSections(state){
            await axios.get(
                API_URL + "patients/sections"
            ).then(
                response => {
                    state.commit('loadPatientsInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPatients(state){
            await axios.get(
                API_URL + "patients"
            ).then(
                response => {
                    state.commit('loadPatientList', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataPatient(state, item){
            let concat = item.id !== undefined ? "patients/" + item.id : "patients/consents-forms";

            if(item.view !== undefined && item.view == true){
                concat += "/info";
            }

            if(item.type === 'surgery'){
                concat = "surgery/" + item.id + "/patient/info";
            }

            await axios.get(
                API_URL + concat
            ).then(
                response => {
                    state.commit('loadPatientData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPatient(state, item){
            let request = item.id !== undefined ? "patients/" + item.id : "patients"
            let formData = new FormData();

            formData.append('patient[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });
            request = API_URL + request;
            axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.dispatch('getPatientsSections');
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removePatient(state, item){
            let requestLogin = API_URL + "patients/" + item.id ;
            axios({
                method: "delete",
                url: requestLogin,
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getPatientsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};