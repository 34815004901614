import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default {
    state: {
        records_data: '',
        data: [],
    },
    mutations: {
        loadTableConfigurationSupportCategories(state,payload){
            state.records_data = payload
        },
        loadDataConfigurationSupportCategories(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationSupportCategories(state){
            await axios.get(
                API_URL + "support/categories"
            ).then(
                response => {
                    state.commit('loadTableConfigurationSupportCategories', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationSupportCategories(state, item){
            await axios.get(
                API_URL + "support/categories/" + item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationSupportCategories', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationSupportCategories(state, item){
            let requestLogin = item.id ? API_URL + "support/categories/" + item.id : API_URL + "support/categories";
            let formData = new FormData();

            formData.append('category[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('getConfigurationSupportCategories');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationSupportCategories(state, item){
            let requestLogin = API_URL + "support/categories/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getConfigurationSupportCategories');
                        state.rootState.ConfigurationSupport.section_info.category.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}