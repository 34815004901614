import SectionVariable from "@/view/configuration/variable/sections/section_variable.vue";

import ConfigurationDepartments from "./departments/route";
import ConfigurationSpecialty from './specialty/route';
import ConfigurationEquipment from './equipments/route';
import ConfigurationEquipmentMaintenance from './equipment_maintenance/route';
import ConfigurationTypeEquipment from './type_equipment/route';
import ConfigurationProfessionalType from './professional_type/route';

const route = [
    {
        path: '/configuration/variable',
        name: 'configuration_variable',
        component: SectionVariable,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...ConfigurationDepartments,
    ...ConfigurationSpecialty,
    ...ConfigurationEquipment,
    ...ConfigurationEquipmentMaintenance,
    ...ConfigurationTypeEquipment,
    ...ConfigurationProfessionalType
]

export default routes;