var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},_vm._l((_vm.ConfigurationFormsEnableDisable.list_enable_disable_form),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_field_enable_disable_form_' + item.id,
                title: item.name,
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                        id: 'table_enable_disable_form',
                    },"header":_vm.header,"data":item.data === undefined ? [] : item.data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                                id: 'action_copy_enable_disable_form_' + itemProp.id,
                                text: _vm.$t('copy'),
                                icon: require('../../../../../assets/svg/doc.svg'),
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','copyConfigurationForm', itemProp.id,{ 'form': itemProp.form}, _vm.$t('copy_form'), _vm.$t('preparing_copy_form'), 'copy')}}}),(!itemProp.enable)?_c('GhAction',{attrs:{"dataction":{
                                id: 'action_enable_enable_disable_form_' + itemProp.id,
                                text: _vm.$t('enable'),
                                icon: require('../../../../../assets/svg/tick.svg'),
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','enableDisableConfigurationForm', itemProp.id,{ 'form': itemProp.form}, _vm.$t('enable_form'), _vm.$t('preparing_enable_form'), 'enable')}}}):_vm._e(),(itemProp.enable)?_c('GhAction',{attrs:{"dataction":{
                                id: 'action_disable_enable_disable_form_' + itemProp.id,
                                text: _vm.$t('disable'),
                                icon: require('../../../../../assets/gh_new_dessign/denied.svg'),
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','enableDisableConfigurationForm', itemProp.id,{ 'form': itemProp.form}, _vm.$t('disable_form'), _vm.$t('preparing_disable_form'), 'disable')}}}):_vm._e()]}}],null,true)})]},proxy:true}],null,true)})],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }