<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_schedule',
                    title: haveData ? $t('schedule') + ': ' + ConfigurationSupportSchedule.data.assignment_group : $t('schedule'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_schedule" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">&nbsp;</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('monday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('tuesday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('wednesday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('thursday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('friday')}}</label></div>
                            </div>
                        </div>
                        <div class="gh-content-global" style="margin-top: -25px;">
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('start_hour') + (!$root._route.params.view ? '*' : ':')}}</label></div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'start_monday',
                                                name: 'schedule[start][monday]',
                                                required: true,
                                                label_required: $t('start_hour_monday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.monday && ConfigurationSupportSchedule.data.start.monday.hour ? ConfigurationSupportSchedule.data.start.monday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.monday && ConfigurationSupportSchedule.data.start.monday.minute ? ConfigurationSupportSchedule.data.start.monday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.start.monday.hour + ':' + ConfigurationSupportSchedule.data.start.monday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'start_tuesday',
                                                name: 'schedule[start][tuesday]',
                                                style: 'margin-left: 115px;',
                                                required: true,
                                                label_required: $t('start_hour_tuesday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.tuesday && ConfigurationSupportSchedule.data.start.tuesday.hour ? ConfigurationSupportSchedule.data.start.tuesday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.tuesday && ConfigurationSupportSchedule.data.start.tuesday.minute ? ConfigurationSupportSchedule.data.start.tuesday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.start.tuesday.hour+ ':' + ConfigurationSupportSchedule.data.start.tuesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'start_wednesday',
                                                name: 'schedule[start][wednesday]',
                                                style: 'margin-left: 123px;',
                                                required: true,
                                                label_required: $t('start_hour_wednesday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.wednesday && ConfigurationSupportSchedule.data.start.wednesday.hour ? ConfigurationSupportSchedule.data.start.wednesday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.wednesday && ConfigurationSupportSchedule.data.start.wednesday.minute ? ConfigurationSupportSchedule.data.start.wednesday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.start.wednesday.hour + ':' + ConfigurationSupportSchedule.data.start.wednesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'start_thursday',
                                                name: 'schedule[start][thursday]',
                                                style: 'margin-left: 109px;',
                                                required: true,
                                                label_required: $t('start_hour_thursday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.thursday && ConfigurationSupportSchedule.data.start.thursday.hour?ConfigurationSupportSchedule.data.start.thursday.hour:'',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.thursday && ConfigurationSupportSchedule.data.start.thursday.minute?ConfigurationSupportSchedule.data.start.thursday.minute:''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.start.thursday.hour+ ':' + ConfigurationSupportSchedule.data.start.thursday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'start_friday',
                                                name: 'schedule[start][friday]',
                                                style: 'margin-left: 115px;',
                                                required: true,
                                                label_required: $t('start_hour_friday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.friday && ConfigurationSupportSchedule.data.start.friday.hour ?ConfigurationSupportSchedule.data.start.friday.hour : '',
                                                minute:ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.start && ConfigurationSupportSchedule.data.start.friday && ConfigurationSupportSchedule.data.start.friday.minute ? ConfigurationSupportSchedule.data.start.friday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.start.friday.hour + ':' + ConfigurationSupportSchedule.data.start.friday.minute}}</label>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="gh-content-global" style="padding:0px 15px;">
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('end_hour') + (!$root._route.params.view ? '*' : ':')}}</label></div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'finish_monday',
                                                name: 'schedule[finish][monday]',
                                                required: true,
                                                label_required: $t('end_hour_monday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.monday && ConfigurationSupportSchedule.data.finish.monday.hour ? ConfigurationSupportSchedule.data.finish.monday.hour : '',
                                                minute:ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.monday && ConfigurationSupportSchedule.data.finish.monday.minute ? ConfigurationSupportSchedule.data.finish.monday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.finish.monday.hour + ':' + ConfigurationSupportSchedule.data.finish.monday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'finish_tuesday',
                                                name: 'schedule[finish][tuesday]',
                                                style: 'margin-left: 115px;',
                                                required: true,
                                                label_required: $t('end_hour_tuesday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.tuesday && ConfigurationSupportSchedule.data.finish.tuesday.hour ? ConfigurationSupportSchedule.data.finish.tuesday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.tuesday && ConfigurationSupportSchedule.data.finish.tuesday.minute ? ConfigurationSupportSchedule.data.start.tuesday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.finish.tuesday.hour + ':' + ConfigurationSupportSchedule.data.finish.tuesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'finish_wednesday',
                                                name: 'schedule[finish][wednesday]',
                                                style: 'margin-left: 123px;',
                                                required: true,
                                                label_required: $t('end_hour_wednesday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.wednesday && ConfigurationSupportSchedule.data.finish.wednesday.hour?ConfigurationSupportSchedule.data.finish.wednesday.hour:'',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.wednesday && ConfigurationSupportSchedule.data.finish.wednesday.minute?ConfigurationSupportSchedule.data.start.wednesday.minute:'',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.finish.wednesday.hour+ ':' + ConfigurationSupportSchedule.data.finish.wednesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'finish_thursday',
                                                name: 'schedule[finish][thursday]',
                                                style: 'margin-left: 109px;',
                                                required: true,
                                                label_required: $t('end_hour_thursday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.thursday && ConfigurationSupportSchedule.data.finish.thursday.hour ? ConfigurationSupportSchedule.data.finish.thursday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.thursday && ConfigurationSupportSchedule.data.finish.thursday.minute ? ConfigurationSupportSchedule.data.finish.thursday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.finish.thursday.hour + ':' + ConfigurationSupportSchedule.data.finish.thursday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'finish_friday',
                                                name: 'schedule[finish][friday]',
                                                style: 'margin-left: 115px;',
                                                required: true,
                                                label_required: $t('end_hour_friday') + '*',
                                                hour: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.friday && ConfigurationSupportSchedule.data.finish.friday.hour ? ConfigurationSupportSchedule.data.finish.friday.hour : '',
                                                minute: ConfigurationSupportSchedule.data && ConfigurationSupportSchedule.data.finish && ConfigurationSupportSchedule.data.finish.friday && ConfigurationSupportSchedule.data.finish.friday.minute ? ConfigurationSupportSchedule.data.finish.friday.minute : '',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigurationSupportSchedule.data.finish.friday.hour + ':' + ConfigurationSupportSchedule.data.finish.friday.minute}}</label>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_accept',
                        class: 'T19 container-md_button_content',
                        text: $t('accept')
                    }"
                    @click="setCategorie()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhButton from 'fe-gh-button-lib'
    import GhInputHour from 'fe-gh-input-hour-lib';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhLocation from "fe-gh-location-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_schedule",
        components:{
            GhLocation,
            GhNoDropSelector,
            GhInputHour,
            GhButton
        },
        data(){
            return{
                type: '',
                haveData: false,
            }
        },
        computed:{
            ...mapState(['ConfigurationSupportSchedule'])
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_support_')[1].split('_schedule')[0];

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationSupportSchedule', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationSupportSchedule.data = [];
            }

            this.haveData = true;
        },
        methods:{
            async setCategorie(){
                if(this.Global.checkRequired('form_schedule') == 'OK') {
                    const formData = new FormData(document.getElementById('form_schedule'));
                    await this.$store.dispatch('setDataConfigurationSupportSchedule', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.windowClose();
                }
            },
        }
    }
</script>