var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{attrs:{"id":"form_process"}},_vm._l((_vm.subprocess),function(item,index){return _c('div',{key:index,staticStyle:{"display":"block"}},[_c('GhDropSelector',{attrs:{"datablock":{
                    id: 'subprocess_section_' + item.id,
                    title: item.name,
                    second_title: '',
                    num_results: ''
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.subprocess_content,{tag:"component",attrs:{"dataSubprocess":item,"indexSubprocess":index,"processId":_vm.processId,"subprocessId":_vm.dataTab.id}})]},proxy:true}],null,true)})],1)}),0),_c('div',{staticClass:"centered",staticStyle:{"padding-top":"10px"}},[(_vm.getButtons.finish)?_c('GhButton',{attrs:{"datainput":{
                id: 'button_save_process_' + _vm.processId + '_' + _vm.dataTab.id,
                text: _vm.$t('finish'),
                class: '',
                style: ''
            }},on:{"click":function($event){return _vm.setSaveProcess(_vm.processId, _vm.dataTab.id)}}}):_vm._e(),(_vm.getButtons.finish)?_c('GhButton',{attrs:{"datainput":{
                id: 'button_back_process_' + _vm.processId + '_' + _vm.dataTab.id,
                text: _vm.$t('back'),
                class: '',
                style: ''
            }},on:{"click":function($event){return _vm.router.go(-1)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }