<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_equipment_maintenance',
                    title: $t('equipment_maintenance')
                }"
            >
                <template v-slot:content>
                    <form id="form_equipment_maintenance" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'equipment_maintenance_code',
                                            name: 'equipment_maintenance[code]',
                                            style: 'width: 200px',
                                            value: values.code,
                                            type: 'text'
                                        }"
                                        ref="RefCode"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentMaintenance.data !== undefined ? ConfigurationVariableEquipmentMaintenance.data.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'equipment_maintenance_name',
                                            name: 'equipment_maintenance[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: values.name,
                                            type: 'text'
                                        }"
                                        ref="RefName"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentMaintenance.data !== undefined ? ConfigurationVariableEquipmentMaintenance.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'equipment_maintenance_description',
                                            name: 'equipment_maintenance[description]',
                                            value: values.description
                                        }"
                                        ref="RefDescription"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentMaintenance.data !== undefined ? ConfigurationVariableEquipmentMaintenance.data.description : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; text-align: end; margin-right: 10px;">{{$t('recurrent_every')}}</label>
                                    <GhChecks
                                        :datalabel="{}"
                                        :datainput="{
                                            required: false,
                                            type: 'radio',
                                            id: 'equipment_maintenance_months',
                                            value: 1,
                                            name: 'equipment_maintenance[recurrent_every]',
                                            selected: recurrent_every == 1,
                                        }"
                                        ref="RefRecurrentEveryMonths"
                                        @click="checkRecurrent('RefRecurrentEveryMonths', 1)"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 25px;">{{$t('months')}}</label>
                                    <GhChecks
                                        :datalabel="{}"
                                        :datainput="{
                                            required: false,
                                            type: 'radio',
                                            id: 'equipment_maintenance_hours',
                                            value: 2,
                                            name: 'equipment_maintenance[recurrent_every]',
                                            selected: recurrent_every == 2,
                                        }"
                                        ref="RefRecurrentEveryHours"
                                        @click="checkRecurrent('RefRecurrentEveryHours', 2)"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('years')}}</label>
                                    <div v-if="recurrent_every !== '' && recurrent_every !== undefined">
                                        <div style="margin-left: 25px;">
                                            <GhInputTextField
                                                :datalabel="{
                                                    text: '',
                                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    required: true,
                                                    id: 'equipment_maintenance_recurrent_every_value',
                                                    name: 'equipment_maintenance[recurrent_every][value]',
                                                    style: 'min-width: 50px; max-width: 50px;',
                                                    value: values.recurrent_every,
                                                    type: 'int',
                                                }"
                                                ref="RefRecurrentEvery"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('recurrent_every') + ':'}}</label>
                                    <template v-if="ConfigurationVariableEquipmentMaintenance.data !== undefined && ConfigurationVariableEquipmentMaintenance.data.recurrent_every !== undefined ">
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">
                                            <template v-if="ConfigurationVariableEquipmentMaintenance.data.recurrent_every === 1">
                                                {{values.recurrent_every + ' ' + $t('months')}}
                                            </template>
                                            <template v-if="ConfigurationVariableEquipmentMaintenance.data.recurrent_every === 2">
                                                {{values.recurrent_every + ' ' + $t('years')}}
                                            </template>
                                        </label>
                                    </template>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'equipment_maintenance_equipment_affected',
                                            class: 'T15_b',
                                            subtitle: $t('select_equipment_affected') + ':',
                                            text: $t('equipment_affected'),
                                            label: $t('equipment_affected'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'equipment_maintenance_affected_equipment',
                                            name: 'equipment_maintenance[equipment_affected][]'
                                        }"
                                        :feed="getFeeds.equipment"
                                        :selected_input="ConfigurationVariableEquipmentMaintenance.data !== undefined ? ConfigurationVariableEquipmentMaintenance.data.equipment_affected : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment_affected') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentMaintenance.data !== undefined ? Object.values(ConfigurationVariableEquipmentMaintenance.data.equipment_affected).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhMultiUpload
                                        :datalabel="{
                                            text: $t('template_documents'),
                                            text_popup: $t('template_documents'),
                                            input_text: $t('name'),
                                            textarea_text: $t('description'),
                                            attach_text: $t('attach'),
                                            content_warning: '',
                                            label_required: $t('template_documents'),
                                            required: false,
                                        }"
                                        :datablock="{
                                            id: 'equipment_maintenance_documents',
                                            name: 'equipment_maintenance[documents]',
                                            style: '',
                                            class: 'T15_b',
                                            icon_attach: '',
                                            icon_trash: '',
                                            icon_download: '',
                                            href: dispatchEvidence
                                        }"
                                        :defaultfiles="ConfigurationVariableEquipmentMaintenance.data !== undefined && ConfigurationVariableEquipmentMaintenance.data.template_documents !== undefined ? ConfigurationVariableEquipmentMaintenance.data.template_documents : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('template_documents') + ':'}}</label>
                                    <div v-for="(file,index) in ConfigurationVariableEquipmentMaintenance.data.template_documents" :key="index">
                                        <label class="gh_text_field-label T15_b"><a class="C1 F5" :href="file.file_download">{{file.name}}</a>{{index != Object.keys(ConfigurationVariableEquipmentMaintenance.data.template_documents).pop() ? ', ' : ''}}</label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept')
                    }"
                    @click="submitEquipmentMaintenance();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhMultiUpload from "fe-gh-multi-upload-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_equipment_maintenance",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhChecks,
            GhDataSelector,
            GhMultiUpload,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationVariableEquipmentMaintenance'])
        },
        data(){
            return{
                type: '',
                haveData: false,
                recurrent_every: '',
                values: {
                    code: '',
                    name: '',
                    description: '',
                    recurrent_every: '',
                },
                dispatchEvidence: 'easyUpload'
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableEquipmentMaintenance', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableEquipmentMaintenance', {id: this.$root._route.params.id}, {root: true});

                this.recurrent_every = this.ConfigurationVariableEquipmentMaintenance.data !== undefined && this.ConfigurationVariableEquipmentMaintenance.data.recurrent_every !== undefined && this.ConfigurationVariableEquipmentMaintenance.data.recurrent_every;
                this.values.code = this.ConfigurationVariableEquipmentMaintenance.data !== undefined ? this.ConfigurationVariableEquipmentMaintenance.data.code : '';
                this.values.name = this.ConfigurationVariableEquipmentMaintenance.data !== undefined ? this.ConfigurationVariableEquipmentMaintenance.data.name : '';
                this.values.description = this.ConfigurationVariableEquipmentMaintenance.data !== undefined ? this.ConfigurationVariableEquipmentMaintenance.data.description : '';
                this.values.recurrent_every = this.ConfigurationVariableEquipmentMaintenance.data !== undefined ? this.ConfigurationVariableEquipmentMaintenance.data.recurrent_every_value : '';
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedEquipment', '', {root: true});
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_equipment_maintenance')[0];
            this.haveData = true;
        },
        methods: {
            checkRecurrent(ref, value){
                this.recurrent_every = this.$refs[ref].selected ? value : '';
                this.setPersist();
            },
            setPersist(){
                this.values.code = this.$refs['RefCode'].datainput.value;
                this.values.name = this.$refs['RefName'].datainput.value;
                this.values.description = this.$refs['RefDescription'].datainput.value;
                if(this.$refs['RefRecurrentEvery'] !== undefined) {
                    this.values.recurrent_every = this.$refs['RefRecurrentEvery'].datainput.value;
                }
            },
            async submitEquipmentMaintenance(){
                if(this.Global.checkRequired('form_equipment_maintenance') === 'OK'){
                    const formData = new FormData(document.getElementById('form_equipment_maintenance'));
                    await this.$store.dispatch('setDataConfigurationVariableEquipmentMaintenance', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #equipment_maintenance_equipment_affected_selector_label, #equipment_maintenance_documents_multi_doc_selector_label, #professional_documents_multi_doc_selector_label{
        width: 150px;
        text-align: end;
    }
</style>