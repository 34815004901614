<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector
                :datablock="{
                    id: 'surgery_section_' + label.id,
                    title: $t(label['name']),
                    second_title: '',
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <template v-if="label.id === 'patient_data'">
                        <div style="padding-right: 12px; padding-left: 12px;">
                            <component v-bind:is="label.component"/>
                        </div>
                    </template>
                    <template v-else>
                        <component v-bind:is="label.component"/>
                    </template>
                </template>
            </GhDropSelector>
        </div>
        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_back',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from 'fe-gh-button-lib';

    import {mapState} from "vuex";

    import view_patient from "@/view/patients/views/partials/info_patient.vue";
    import view_professional from "@/view/register/template/initial_registration.vue";
    import view_type_of_admission from "@/view/surgery/views/partials/view_type_of_admission.vue";

    export default {
        name: 'view_surgery',
        components: {
            GhDropSelector,
            GhButton
        },
        computed : {
            ...mapState(['SurgeryData'])
        },
        data(){
            return{
                haveData: false,
                data: [
                    {id: 'patient_data', name: 'patient_data', component: view_patient, type: 'patient_data'},
                    {id: 'physician_data', name: 'physician_data', component: view_professional, type: 'physician_data'},
                    {id: 'diagnostic', name: 'diagnostic', component: '', type: 'diagnostic'},
                    {id: 'type_of_admission', name: 'type_of_admission', component: view_type_of_admission, type: 'type_of_admission'},
                    {id: 'surgical_plan', name: 'surgical_plan', component: '', type: 'surgical_plan'},
                ],
                is_surgery: true,
                dataForm: {
                    type_of_admission: {
                        type: '',
                        surgical: [],
                        post: [],
                    }
                }
            }
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>