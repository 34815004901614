var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.dataBlock.index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:'RefColumnName' + (_vm.dataBlock.length + _vm.dataBlock.index + 1),attrs:{"datalabel":{
                text: _vm.$t('column') + ' ' + (_vm.dataBlock.length + _vm.dataBlock.index + 1) + '*',
                style: 'width: 150px; min-width: 150px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                required: true,
                id: _vm.$parent.$parent.$parent.type_item + '_new_column_' + _vm.dataBlock.type + '_' + _vm.dataBlock.index + '_column',
                name: _vm.$parent.$parent.$parent.type_item + '[' + _vm.dataBlock.type + '][new_column][' + _vm.dataBlock.index + '][column]',
                style: 'min-width: 300px; width: 300px',
                value: _vm.dataBlock.item.column,
                type: 'text',
            }},on:{"keyup":function($event){_vm.$parent.renameColumn(_vm.dataBlock.length + _vm.dataBlock.index, 'RefLineOfTable' + _vm.dataBlock.index, 'RefColumnName' + (_vm.dataBlock.length + _vm.dataBlock.index + 1))}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('column') + ' ' + (_vm.dataBlock.length + _vm.dataBlock.index + 1) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.dataBlock.item.column))])],(!_vm.$root._route.params.view)?[_c('div',{staticClass:"change-min-with-type"},[_c('GhDataSelector',{ref:'RefSelectorType' + _vm.dataBlock.index,attrs:{"datablock":{
                    id: 'label_' + _vm.$parent.$parent.$parent.type_item + '_new_column_' + _vm.dataBlock.type + '_' + _vm.dataBlock.index + '_type',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_type') + ' ' + (_vm.dataBlock.length + _vm.dataBlock.index + 1) + ':',
                    text: _vm.$t('type') + '*',
                    label: _vm.$t('type'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: 'margin-left: 25px;',
                    popupStyle: 'min-width: 900px; width: 55%;',
                },"datainput":{
                    id: _vm.$parent.$parent.$parent.type_item + '_new_new_column_' + _vm.dataBlock.type + '_' + _vm.dataBlock.index + '_type',
                    name:  _vm.$parent.$parent.$parent.type_item + '[' + _vm.dataBlock.type + '][new_column][' + _vm.dataBlock.index + '][type]'
                },"feed":_vm.getFeeds.type_ddbb.feeds,"selected_input":_vm.dataBlock.item.type},on:{"accept":function($event){return _vm.checkType(_vm.dataBlock.index)}}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.dataBlock.item.type).join()))])],(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('length') + '*',
                class: 'T15_b'
            },"datainput":{
                required: _vm.type.disabled === true || _vm.type.disabled === 'true' ? false : true,
                id: _vm.$parent.$parent.$parent.type_item + '_new_column_' + _vm.dataBlock.type + '_' + _vm.dataBlock.index + '_length',
                name: _vm.$parent.$parent.$parent.type_item + '[' + _vm.dataBlock.type + '][new_column][' + _vm.dataBlock.index + '][length]',
                style: 'min-width: 75px; width: 75px',
                value: _vm.dataBlock.item.length,
                type: _vm.type.type_input,
                limitNumMin: _vm.type.min,
                limitNumMax: _vm.type.max,
                class: _vm.type.disabled === true || _vm.type.disabled === 'true' ? 'disabled' : ''
            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('length') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.dataBlock.item.length))])],(_vm.$root._route.params.view !== 'true')?[_c('GhAction',{staticStyle:{"margin-left":"10px","width":"max-content","white-space":"nowrap"},attrs:{"dataction":{
                id: 'action_delete_structure_' + _vm.dataBlock.index,
                text: _vm.$t('delete'),
                icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
            }},on:{"click":function($event){return _vm.$parent.removeColumn('new_columns', _vm.dataBlock.index)}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }