<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_department',
                    title: $t('department')
                }"
            >
                <template v-slot:content>
                    <form id="form_department" v-if="haveData">
                        <div style="display: flex;">
                            <div class="gh-content-global" style="width: 170px">
                                <div style="width: 168px; height: 168px;">
                                    <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
                                </div>
                            </div>
                            <div class="gh-content-global" style="width: 100%">
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('code') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'department_code',
                                                name: 'department[code]',
                                                style: 'width: 200px',
                                                value: values.code,
                                                type: 'text',
                                            }"
                                            ref="RefCode"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{values.code}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'department_name',
                                                name: 'department[name]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: values.name,
                                                type: 'text',
                                            }"
                                            ref="RefName"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{values.name}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhTextarea
                                            :datalabel="{
                                                text: $t('description'),
                                                styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'department_description',
                                                name: 'department[description]',
                                                value: values.description
                                            }"
                                            ref="RefDescription"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{values.description}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('logo')}}</label>
                                    <GhEasyUpload
                                        :extrablock="{
                                            id: 'department_logo',
                                            class: '',
                                            name_hidden: 'department[logo]',
                                            id_hidden: 'department_logo',
                                            icon_attach: ''
                                        }"
                                        :datalabel="{
                                            id: 'label_department_logo',
                                            style: 'margin-right: 5px'
                                        }"
                                        :datainput="{
                                            view: $root._route.params.view,
                                            accept: 'image/png, image/gif, image/jpeg',
                                            text: $t('upload'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            file_name: ConfigurationVariableDepartments.data !== undefined && ConfigurationVariableDepartments.data.logo !== undefined ? ConfigurationVariableDepartments.data.logo.file_name : '',
                                            file_path: ConfigurationVariableDepartments.data !== undefined && ConfigurationVariableDepartments.data.logo !== undefined ? ConfigurationVariableDepartments.data.logo.file_path : '',
                                            file_download: ConfigurationVariableDepartments.data !== undefined && ConfigurationVariableDepartments.data.logo !== undefined ? ConfigurationVariableDepartments.data.logo.file_download : ''
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'department_specialties',
                                                class: 'T15_b',
                                                subtitle: $t('select_specialties') + ':',
                                                text: $t('specialties'),
                                                label: $t('specialties'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'department_specialties',
                                                name: 'department[specialties]'
                                            }"
                                            :feed="getFeeds.clinic_specialties"
                                            :selected_input="ConfigurationVariableDepartments.data !== undefined ? ConfigurationVariableDepartments.data.specialties : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableDepartments.data !== undefined ? Object.values(ConfigurationVariableDepartments.data.specialties).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" v-for="(item, index) in employees" :key="index">
                                    <template v-if="!$root._route.params.view">
                                        <GhSearchWindow
                                            :dataBlock="{
                                                label: {
                                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                                },
                                                ResultSearch: 'result_search_data_groups',
                                                nameStore: 'getFeeds',
                                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                                id: item.id,
                                                text: $t(item.id),
                                                textlocation: $t('add') + ' ' + $t(item.id),
                                                actionresult: 'loadResultSearchGroups',
                                                nameResult: 'department[' + item.id + ']',
                                                supportPermision: false,
                                            }"
                                            :mainSelector="{
                                                name: 'Formsearchcompany',
                                                formId: 'search',
                                                actionsearch: 'getSearchGroups'
                                            }"
                                            :tableResolt="{
                                                header: header,
                                                data: ConfigurationVariableDepartments.data !== undefined && ConfigurationVariableDepartments.data[item.id] !== undefined && ConfigurationVariableDepartments.data[item.id].summary !== undefined ? ConfigurationVariableDepartments.data[item.id].summary : []
                                            }"
                                            :tableSelected="{
                                                headersummary: headersummary,
                                                selected: ConfigurationVariableDepartments.data !== undefined && ConfigurationVariableDepartments.data[item.id] !== undefined && ConfigurationVariableDepartments.data[item.id].summary !== undefined ? ConfigurationVariableDepartments.data[item.id].summary : []
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(item.id) + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{summary[item.id]}}</label>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitDepartment();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_department",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhEasyUpload,
            GhDataSelector,
            GhSearchWindow,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationVariableDepartments', 'UploadFiles'])
        },
        data(){
            return {
                type: '',
                haveData: false,
                employees: [
                  {id: 'admin'},
                  {id: 'md'},
                  {id: 'nurses'},
                  {id: 'anesthesiologists'},
                  {id: 'other'},
                ],
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'professional_type', field: 'professional_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'}
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
                summary: {
                    admin: '',
                    md: '',
                    nurses: '',
                    anesthesiologists: '',
                    other: '',
                },
                image: '',
                values: {
                    code: '',
                    name: '',
                    description: ''
                }
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableDepartment', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableDepartment', {id: this.$root._route.params.id}, {root: true});

                this.values.code = this.ConfigurationVariableDepartments.data !== undefined ? this.ConfigurationVariableDepartments.data.code : '';
                this.values.name = this.ConfigurationVariableDepartments.data !== undefined ? this.ConfigurationVariableDepartments.data.name : '';
                this.values.description = this.ConfigurationVariableDepartments.data !== undefined ? this.ConfigurationVariableDepartments.data.description : '';
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedClinicSpecialties', '', {root: true});
            }else{
                this.employees.forEach((item) => {
                    if(this.ConfigurationVariableDepartments.data !== undefined && this.ConfigurationVariableDepartments.data.admin !== undefined && this.ConfigurationVariableDepartments.data[item.id].summary !== undefined){
                        this.ConfigurationVariableDepartments.data[item.id].summary.forEach((values) => {
                            this.summary[item.id] += values.name + ' ' + values.surname + ', ';
                        });
                        this.summary[item.id] = this.summary[item.id].substring(0, this.summary[item.id].length - 2);
                    }
                })
            }

            if(this.ConfigurationVariableDepartments.data !== undefined && this.ConfigurationVariableDepartments.data.logo !== undefined && this.ConfigurationVariableDepartments.data.logo.file_download !== undefined && this.ConfigurationVariableDepartments.data.logo.file_download !== ''){
                this.image = this.ConfigurationVariableDepartments.data.logo.file_download;
            }else{
                this.image = require('../../../../assets/gh_new_dessign/no-image.svg');
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_department')[0];
            this.haveData = true;
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.url;
                this.setPersist();
            }
        },
        methods: {
            setPersist(){
                this.values.code = this.$refs['RefCode'].datainput.value;
                this.values.name = this.$refs['RefName'].datainput.value;
                this.values.description = this.$refs['RefDescription'].datainput.value;
            },
            async submitDepartment(){
                if(this.Global.checkRequired('form_department') === 'OK'){
                    const formData = new FormData(document.getElementById('form_department'));
                    await this.$store.dispatch('setDataConfigurationVariableDepartment', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                        defaultData: this.ConfigurationVariableDepartments.data,
                        employees: this.employees
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #department_specialties_selector_label{
        width: 150px;
        text-align: end;
    }

    #list_selected_data_search, #list_Process_table_search{
        margin-top: 10px;
    }
</style>
