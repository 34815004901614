<template>
    <GhInfoBox
        :dataBlock="{
            id: 'info_patient_reason_for_visit',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 10, bottom: 10, left: 10, right: 10 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'height',
            width: '',
            height: '',
        }"
        :dataTitle="{
            show: false
        }"
        :dataExpansible="{
            show: false
        }"
    >
        <template v-slot:content>
            <div class="gh-row-content-info">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('reason_for_visit') + ':'}}</label>
                <label class="gh_text_field-label T15">{{Patients.data.pending_visit.reason_for_visit}}</label>
            </div>
            <div class="gh-row-content-info">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('md') + ':'}}</label>
                <label class="gh_text_field-label T15" style="width: 100%">{{Patients.data.pending_visit.md}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('date') + ':'}}</label>
                <label class="gh_text_field-label T15" style="width: 200px;">{{Patients.data.pending_visit.date}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('time') + ':'}}</label>
                <label class="gh_text_field-label T15" style="width: 50px;">{{Patients.data.pending_visit.time}}</label>
            </div>
            <div class="gh-row-content-info"> <!-- TODO Este bloque falta ponerle una condición -->
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('patient_location') + ':'}}</label>
                <label class="gh_text_field-label T15">{{Patients.data.pending_visit.patient_location}}</label>
            </div>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_reason_for_visit",
        components: {
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>