var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[(_vm.dataItem.item.config.checks !== undefined && _vm.dataItem.item.config.checks.values !== undefined && _vm.dataItem.item.config.checks.values.length !== 0)?[_c('div',{staticClass:"grid-container"},_vm._l((_vm.dataItem.item.config.checks.values),function(item,key){return _c('div',{key:key,staticClass:"grid-item"},[_c('GhChecks',{key:key,ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',refInFor:true,attrs:{"datalabel":{text: item.text, style: '', class: '', id: ''},"datainput":{
                                disabled: false,
                                required: _vm.dataItem.item.config.checks.required !== undefined ? _vm.dataItem.item.config.checks.required : false,
                                selected: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.item !== undefined && _vm.dataItem.item.data.item === item.value,
                                type: 'radio',
                                id: 'check_' + _vm.dataItem.item.config.checks.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_value_' + item.value + '_item',
                                value: item.value,
                                name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item]'
                            }},on:{"click":function($event){_vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id); _vm.$parent.checkExtraItem(_vm.$el, _vm.haveExtraItem, _vm.$refs, 5, _vm.dataItem.item.config.checks.id, _vm.processId, _vm.subprocessId, _vm.formId, _vm.sectionId, _vm.indexItem, _vm.dataItem.id)}}})],1)}),0)]:_vm._e(),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }