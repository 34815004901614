<template>
    <GhInfoBox
        style="margin-top: 6px; margin-left: 3px;"
        :dataBlock="{
            id: 'info_patient_test_imagining',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 0, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'row'
        }"
        :dataTitle="{
            show: true,
            text: 'test_imagining',
            image: require('../../../../assets/svg/test.svg'),
            styleBlock: '',
            textStyle:{
                class: 'T20B style_test_imagining',
            },
            imageStyle:{
                width: '30px',
                height: '30px',
            },
        }"
        :dataExpansible="{
            show: Patients.data.test_imagining !== undefined && Patients.data.test_imagining.length >= 2 ? true : false,
            animation: true,
            animationSpeed: 1000,
            textExpand: $t('show_more_files'),
            textCollapse: $t('show_less_files'),
            iconExpand: require('../../../../assets/svg/add_plus.svg'),
            iconCollapse: require('../../../../assets/svg/add_less.svg'),
        }"
    >
        <template v-slot:content>
            <template v-if="Patients.data.test_imagining !== undefined && Patients.data.test_imagining.length == 0">
                <div id="info_patient_test_imagining_show_now">
                    <br>
                    <div class="gh-row-content-info">
                        <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_test_and_imagining')}}</label>
                    </div>
                </div>
            </template>
            <template v-else>
                <div id="info_patient_test_imagining_show_now">
                    <template v-if="Patients.data.test_imagining !== undefined && Patients.data.test_imagining.length != 0">
                        <template v-for="(item, index) in Patients.data.test_imagining">
                            <div :key="index" v-if="index <= 2">
                                <br>
                                <div style="display: grid">
                                    <div style="display: flex; width: 100%">
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{'• ' + item.name}}</label>
                                        <GhAction
                                            style="margin-top: -2px;"
                                            :dataction="{
                                                id: 'action_chain_' + index,
                                                text: '',
                                                icon: require('../../../../assets/svg/chain.svg'),
                                            }"
                                        />
                                    </div>
                                    <div style="display: flex; width: 100%">
                                        <label class="gh_text_field-label T15" style="margin-left: 25px; white-space: nowrap;">{{item.description}}</label>
                                        <span style="width: 100%; margin-left: 10px; border-bottom: 1px dotted;"></span>
                                        <label class="gh_text_field-label T15" style="margin-left: 10px; white-space: nowrap;">{{item.date}}</label>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div id="info_patient_test_imagining_show_after">
                    <template v-for="(item, index) in Patients.data.test_imagining">
                        <div :key="index" v-if="index >= 3">
                            <br>
                            <div style="display: grid">
                                <div style="display: flex; width: 100%">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{'• ' + item.name}}</label>
                                    <GhAction
                                        style="margin-top: -2px;"
                                        :dataction="{
                                            id: 'action_chain_' + index,
                                            text: '',
                                            icon: require('../../../../assets/svg/chain.svg'),
                                        }"
                                    />
                                </div>
                                <div style="display: flex; width: 100%">
                                    <label class="gh_text_field-label T15" style="margin-left: 25px; white-space: nowrap;">{{item.description}}</label>
                                    <span style="width: 100%; margin-left: 10px; border-bottom: 1px dotted;"></span>
                                    <label class="gh_text_field-label T15" style="margin-left: 10px; white-space: nowrap;">{{item.date}}</label>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_test_imagining",
        components: {
            GhInfoBox,
            GhAction
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>