<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_professional_type',
                    title: $t('professional_type')
                }"
            >
                <template v-slot:content>
                    <form id="form_professional_type" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'professional_type_code',
                                            name: 'professional_type[code]',
                                            style: 'width: 200px',
                                            value: ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.code : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'professional_type_name',
                                            name: 'professional_type[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'professional_type_description',
                                            name: 'professional_type[description]',
                                            value: ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.description : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.description : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'professional_type_variable',
                                            class: 'T15_b',
                                            subtitle: $t('select_variable') + ':',
                                            text: $t('variable'),
                                            label: $t('variable'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'professional_type_variable',
                                            name: 'professional_type[variable][]'
                                        }"
                                        :feed="getFeeds.variables"
                                        :selected_input="ConfigurationVariableProfessionalTypes.data !== undefined ? ConfigurationVariableProfessionalTypes.data.variables : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('variable') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableProfessionalTypes.data !== undefined ? Object.values(ConfigurationVariableProfessionalTypes.data.variables).join(', ') : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitProfessionalType();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_professional_type",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationVariableProfessionalTypes'])
        },
        data(){
            return {
                type: '',
                haveData: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableProfessionalTypes', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableProfessionalType', {id: this.$root._route.params.id}, {root: true});
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedVariables', '', {root: true});
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_professional_type')[0];
            this.haveData = true;
        },
        methods:{
            async submitProfessionalType(){
                if(this.Global.checkRequired('form_professional_type') === 'OK'){
                    const formData = new FormData(document.getElementById('form_professional_type'));
                    await this.$store.dispatch('setDataConfigurationVariableProfessionalType', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #professional_type_variable_selector_label{
        width: 150px;
        text-align: end;
    }
</style>