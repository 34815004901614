var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'surgery_',
    },"header":_vm.header,"data":_vm.SurgeryData.records_data.length == 0 ? [] : _vm.SurgeryData.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_vm._v(" "+_vm._s(itemProp[labelProp])+" ")]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'surgery_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'action_execute_surgery_' + itemProp.id,
                    text: _vm.$t('execute'),
                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                }}})],1)]}}],null,false,3305379152)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }