import SectionCalendar from "@/view/configuration/calendar/sections/section_calendar.vue"
import Availability from "./availability/route";

const route = [
    {
        path: '/configuration/calendar',
        name: 'configuration_calendar',
        component: SectionCalendar,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Availability
]

export default routes;