<template>
    <GhTableCommon style="margin-top: 10px;" v-if="haveData"
        :extratable="{
            id: 'table_category_process',
        }"
        :header="header"
        :data="ConfigurationProcessCategory.list_category_process.length == 0 ? [] : ConfigurationProcessCategory.list_category_process"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_category_process_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_process_edit_category_process', {id: itemProp.id}),'configuration_process_category_process')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_category_process_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationCategoryProcess', itemProp.id,{ 'name': itemProp.name, 'description': itemProp.description}, $t('delete_category_process'), $t('preparing_delete_category_process'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_category_process',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_process_new_category_process', {}),'configuration_process_category_process')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
import {mapState} from "vuex";

import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from "fe-gh-action-lib";
import GhButton from "fe-gh-button-lib";

export default {
    name: 'list_category_process',
    components: {
        GhButton,
        GhAction,
        GhTableCommon
    },
    computed:{
        ...mapState(['ConfigurationProcessCategory']),
    },
    data(){
        return {
            haveData: false,
            header: [
                {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 80%;'},
                {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
            ]
        }
    },
    async beforeMount(){
        await this.$store.dispatch('getConfigurationCategoryProcess','',{root:true});
        this.haveData = true;
    }
}
</script>
