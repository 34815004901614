import ConfigPriorities from "@/view/configuration/support/views/view_priority.vue";

const routes = [
    {
        path: '/configuration/support/priority',
        name: 'configuration_support_new_priority',
        component: ConfigPriorities,
        props: {id: null, showMenu: false, background: true, otherTitle: 'priority'},
        children: [
            {
                path: '/configuration/support/priority/:id',
                name: 'configuration_support_edit_priority',
                component: ConfigPriorities,
                props: {id: null, showMenu: false, background: true, otherTitle: 'priority'},
            },
            {
                path: '/configuration/support/priority/:id/:view',
                name: 'configuration_support_view_priority',
                component: ConfigPriorities,
                props: {id: null, showMenu: false, background: true, otherTitle: 'priority'},
            },
        ]
    }
];
export default routes;