var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},[_vm._l((_vm.ConfigurationProcessValidatePropagate.list_validate_propagate_process.data),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_field_validate_propagate_process_' + item.id,
                title: item.name,
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                        id: 'table_creation_process',
                    },"header":_vm.header,"data":item.data === undefined ? [] : item.data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'configuration_process_validate_creation_process', params: {id: itemProp.id, validate: true}}}},[(itemProp.show_validate)?_c('GhAction',{attrs:{"dataction":{
                                    id: 'action_validate_process_' + itemProp.id,
                                    text: _vm.$t('validate'),
                                    icon: require('../../../../../assets/svg/tick.svg'),
                                }}}):_vm._e()],1)]}}],null,true)})]},proxy:true}],null,true)})],1)}),(_vm.ConfigurationProcessValidatePropagate.list_validate_propagate_process.show_propagate)?_c('div',{staticClass:"centered",staticStyle:{"padding-top":"5px","margin-bottom":"-5px !important"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_propagate_process',
                text: _vm.$t('propagate')
            }},on:{"click":function($event){return _vm.toPropagate('process')}}})],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }