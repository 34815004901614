import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        subTabActive: '',
        nameSubTabActive:'',
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
    },
    actions: {
        async getConfigTabActive(state, item) {
            var valRouter = await router.options.routes.find((i) => i.name == item.call);

            state.commit("loadSubActiveItem", valRouter.component != '' ? valRouter.component : '');
            state.commit("loadNameSubActiveItem", valRouter.name != '' ? valRouter.name : '');
        },
    }
};
