<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_variable_equipment_maintenance_'
        }"
        :header="header"
        :data="ConfigurationVariableEquipmentMaintenance.records_data.length === 0 ? [] : ConfigurationVariableEquipmentMaintenance.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="viewEquipmentMaintenance(itemProp.id)"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_equipment_maintenance_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg')
                }"
                @click="editEquipmentMaintenance(itemProp.id)"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_equipment_maintenance_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg')
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationVariableEquipmentMaintenance', itemProp.id,{
                    'code': itemProp.code,
                    'name': itemProp.name,
                    'documents': itemProp.documents,
                    'description': itemProp.description
                }, $t('delete_equipment_maintenance'), $t('preparing_delete_equipment_maintenance'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_new_equipment_maintenance',
                        text: $t('add')
                    }"
                    @click="addEquipmentMaintenance()"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib"
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_equipment_maintenance",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationVariableEquipmentMaintenance'])
        },
        data(){
            return{
                haveData: false,
                timer: [],
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'documents', field: 'documents', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationVariableEquipmentMaintenance', '', {root: true});
            this.haveData = true;
        },
        methods: {
            addEquipmentMaintenance(){
                this.Global.windowOpen(
                    this.Global.openSecondWindow('configuration_variable_new_equipment_maintenance', {}),'configuration_variable_equipment_maintenance', true
                );

                this.updatedParentWindow();
            },
            editEquipmentMaintenance(id){
                this.Global.windowOpen(
                    this.Global.openSecondWindow(
                        'configuration_variable_edit_equipment_maintenance', {
                            id: id
                        }),'configuration_variable_equipment_maintenance', true
                );

                this.updatedParentWindow();
            },
            viewEquipmentMaintenance(id){
                this.Global.windowOpen(
                    this.Global.openSecondWindow('configuration_variable_view_equipment_maintenance', {
                        id: id,
                        view: true
                    }),'configuration_variable_equipment_maintenance', true
                );

                this.updatedParentWindow();
            },
            updatedParentWindow(){
                var then = this;
                window.UpdatedSelect = async function(e){
                    if(e === 'OK'){
                        await then.$store.dispatch('getConfigurationVariableEquipmentMaintenance', '', {root: true});
                        await then.$store.dispatch('getConfigurationVariableSections', '', {root: true});
                    }
                }

                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            checkChildWindow() {
                if (this.Global.state.window.closed) {
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>