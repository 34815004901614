<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Validations.section_info[label['type']].show"
                :datablock="{
                    id: 'validations_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(Validations.section_info[label['type']]['second_text']),
                    num_results: Validations.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_professionals from "@/view/validations/lists/list_professionals.vue";

    export default {
        name: "sections_validations",
        components: {
            GhDropSelector
        },
        data() {
            return {
                data: [
                    {id: 1, name: 'professionals', component: list_professionals, type: 'professionals'},
                ],
                haveData: false
            }
        },
        computed : {
            ...mapState(['Validations'])
        },
        async beforeMount(){
            await this.$store.dispatch('getValidationsSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>
