var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_specialty',
                title: _vm.$t('specialty')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_specialty"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'specialty_code',
                                        name: 'specialty[code]',
                                        style: 'width: 200px',
                                        value: _vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.code : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'specialty_name',
                                        name: 'specialty[name]',
                                        style: 'min-width: 200px; width: 100%',
                                        value: _vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.name : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'specialty_description',
                                        name: 'specialty[description]',
                                        value: _vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.description : ''
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'specialty_departments',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_departments') + ':',
                                        text: _vm.$t('departments'),
                                        label: _vm.$t('departments'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'specialty_departments',
                                        name: 'specialty[departments][]'
                                    },"feed":_vm.getFeeds.departments,"selected_input":_vm.ConfigurationVariableSpecialties.data !== undefined ? _vm.ConfigurationVariableSpecialties.data.departments : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('departments') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableSpecialties.data !== undefined ? Object.values(_vm.ConfigurationVariableSpecialties.data.departments).join(', ') : ''))])]],2),_vm._l((_vm.employees),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                        label: {
                                            style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                        },
                                        ResultSearch: 'result_search_data_groups',
                                        nameStore: 'getFeeds',
                                        imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                        id: item.id,
                                        text: _vm.$t(item.id),
                                        textlocation: _vm.$t('add') + ' ' + _vm.$t(item.id),
                                        actionresult: 'loadResultSearchGroups',
                                        nameResult: 'specialty[' + item.id + ']',
                                        supportPermision: false,
                                    },"mainSelector":{
                                        name: 'Formsearchcompany',
                                        formId: 'search',
                                        actionsearch: 'getSearchGroups'
                                    },"tableResolt":{
                                        header: _vm.header,
                                        data: _vm.ConfigurationVariableSpecialties.data !== undefined && _vm.ConfigurationVariableSpecialties.data[item.id] !== undefined && _vm.ConfigurationVariableSpecialties.data[item.id].summary !== undefined ? _vm.ConfigurationVariableSpecialties.data[item.id].summary : []
                                    },"tableSelected":{
                                        headersummary: _vm.headersummary,
                                        selected: _vm.ConfigurationVariableSpecialties.data !== undefined && _vm.ConfigurationVariableSpecialties.data[item.id] !== undefined && _vm.ConfigurationVariableSpecialties.data[item.id].summary !== undefined ? _vm.ConfigurationVariableSpecialties.data[item.id].summary : []
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.id) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.summary[item.id]))])]],2)})],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.haveData && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitSpecialty();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }