<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_category',
                    title: $t('category'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <div style="margin-top: 15px;" v-if="haveData">
                        <GhTableCommon
                            :extratable="{
                                id: 'holidays',
                            }"
                            :header="!$root._route.params.view ? header : header_view"
                            :data="ConfigurationSupportHoliday.data.length == 0 ? [] : ConfigurationSupportHoliday.data"
                        >
                            <template v-slot:actions="{itemProp}" v-if="!$root._route.params.view">
                                <GhAction
                                    :dataction="{
                                        id: 'delete_holiday_' + itemProp.id,
                                        text: $t('delete'),
                                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                                        item_prop: itemProp
                                    }"
                                    @click="Global.deleteConfirm('delete_confirm','removeTemporaryHoliday', itemProp.id,{'description': itemProp.description}, $t('delete_holiday'), $t('preparing_delete_holiday'), 'remove')"
                                />
                            </template>
                            <template v-slot:footer v-if="!$root._route.params.view">
                                <div class="centered">
                                    <GhButton
                                        :datainput="{
                                            id: 'btn_add_user',
                                            text: $t('add')
                                        }"
                                        @click="Global.openPopUp('add_new_holiday_popup');"
                                    />
                                </div>
                            </template>
                        </GhTableCommon>
                    </div>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_accept',
                        class: 'T19 container-md_button_content',
                        text: $t('accept')
                    }"
                    @click="submitHoliday()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
            <template v-if="!$root._route.params.view">
                <GhPopUp v-show="false"
                    :generalConfiguration="{
                        id: 'add_new_holiday_popup',
                        title: $t('new_holiday'),
                        type: 'add',
                        style: 'width: 60%; min-width:300px;',
                    }"
                    :bodyConfiguration="{
                        id: '',
                        class: '',
                        style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;'
                    }"
                    :footerConfiguration="{
                        id: '',
                        class: '',
                        style: '',
                        content: ''
                    }"
                >
                    <template v-slot:body>
                        <div class="gh-content-global" :key="refresh">
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description') + '*',
                                        class: 'T15_b',
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                     }"
                                    :datainput="{
                                        id: 'holiday_description',
                                        name: 'holiday[description]',
                                        required: true,
                                        value: ''
                                    }"
                                    ref="ref_holiday_description"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhCalendar
                                    :datalabel="{
                                        id: 'label_date_holiday',
                                        text: $t('date'),
                                        style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id:'holiday_date',
                                        name:'holiday[date]',
                                        value: '',
                                        required: true,
                                    }"
                                    ref="ref_holiday_date"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:footer>
                        <GhButton
                            :datainput="{
                                id: 'button',
                                text: $t('save'),
                                class: '',
                                style: ''
                            }"
                            extra-attr="test"
                            @click="setHoliday(); Global.closePopUp('add_new_holiday_popup');"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_close_holiday_popup',
                                text: $t('close'),
                            }"
                            @click="Global.closePopUp('add_new_holiday_popup');"
                        />
                    </template>
                </GhPopUp>
            </template>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from 'fe-gh-action-lib';
    import GhPopUp from 'fe-gh-popup-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhCalendar from 'fe-gh-input-calendar-lib';
    import GhButton from "fe-gh-button-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_holiday",
        components:{
            GhLocation,
            GhNoDropSelector,
            GhTableCommon,
            GhAction,
            GhPopUp,
            GhTextarea,
            GhCalendar,
            GhButton,
        },
        data(){
            return{
                type: '',
                haveData: false,
                header: [
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 85%;'},
                    {text: 'day', field: 'day', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                    {text: 'month', field: 'month', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                    {text: 'year', field: 'year', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                header_view: [
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 85%;'},
                    {text: 'day', field: 'day', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                    {text: 'month', field: 'month', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                    {text: 'year', field: 'year', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                ],
                refresh: 0
            }
        },
        computed:{
            ...mapState(['ConfigurationSupportHoliday'])
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_support_')[1].split('_holiday')[0];
            this.$store.dispatch('cleanTemporalHolidayData', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationSupportHoliday', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationSupportHoliday.data = [];
            }

            this.haveData = true;
        },
        methods:{
            setHoliday(){
                var spliter_date = this.$refs.ref_holiday_date.$el.children[1].children[0].children[0].value.split('/');
                var params = {
                    id: Date.now(),
                    description: this.$refs.ref_holiday_description.$el.children[1].value,
                    date: this.$refs.ref_holiday_date.$el.children[1].children[0].children[0].value,
                    day: spliter_date[0],
                    month: spliter_date[1],
                    year: spliter_date[2]
                }
                this.$store.dispatch('setTemporalHolidayData',{tmp_holidays: params},{root:true});
                this.refresh++;
            },
            async submitHoliday(){
                await this.$store.dispatch('setDataConfigurationSupportHoliday', {
                    id: this.$root._route.params.id
                }, {root: true});

                this.Global.windowClose();
            },
        }
    }
</script>