<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_standard_item',
                    title: $t('standard_item')
                }"
            >
                <template v-slot:content>
                    <form id="form_standard_item" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content" v-if="$root._route.params.id !== null && $root._route.params.id !== undefined">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.code : ''}}</label>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'standard_item_name',
                                            name: 'standard_item[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_standard_item_group',
                                            class: 'T15_b',
                                            subtitle: $t('select_group') + ':',
                                            text: $t('group'),
                                            label: $t('group'),
                                            type: 'radio',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'standard_item_group',
                                            name: 'standard_item[group]'
                                        }"
                                        :feed="getFeeds.item_groups"
                                        :selected_input="ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.group : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('group') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationItemsStandardItems.data !== undefined ? Object.values(ConfigurationItemsStandardItems.data.group).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('critical')}}</label>
                                <GhChecks
                                    :datalabel="{}"
                                    :datainput="{
                                        required: false,
                                        disabled: $root._route.params.view == 'true' ? true : false,
                                        type: 'checkbox',
                                        id: 'standard_item_critical',
                                        value: 1,
                                        name: 'standard_item[critical]',
                                        selected: ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.critical : false,
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTreeSelectorBlock
                                        :dataBlock="{
                                            type: 'radio',
                                            id: 'standard_item_options_to_select',
                                            label: $t('options_to_select') + '*',
                                            title: $t('options_to_select'),
                                            text: $t('select_option_to_select') + ':',
                                            name: 'standard_item[options_to_select]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: false,
                                            style: 'margin-left: 0px;',
                                            popupStyle: 'min-width: 900px;width: 55%;',
                                        }"
                                        :selected_input="ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.options_to_select : []"
                                        :items="getFeeds.options_to_select"
                                        @accept="getTypeSelected"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('options_to_select') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.options_to_select[0].text : ''}}</label>
                                </template>
                            </div>
                            <template v-if="haveOptionToSelect">
                                <component v-bind:is="component"/>
                            </template>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('allow_pictures')}}</label>
                                <GhChecks
                                    :datalabel="{}"
                                    :datainput="{
                                        required: false,
                                        disabled: $root._route.params.view == 'true' ? true : false,
                                        type: 'checkbox',
                                        id: 'standard_item_allow_pictures',
                                        value: 1,
                                        name: 'standard_item[allow_pictures]',
                                        selected: ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.allow_pictures : false,
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('allow_na')}}</label>
                                <GhChecks
                                    :datalabel="{}"
                                    :datainput="{
                                        required: false,
                                        type: 'checkbox',
                                        disabled: $root._route.params.view == 'true' ? true : false,
                                        id: 'standard_item_allow_na',
                                        value: 1,
                                        name: 'standard_item[allow_na]',
                                        selected: ConfigurationItemsStandardItems.data !== undefined ? ConfigurationItemsStandardItems.data.allow_na : false,
                                    }"
                                />
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="!this.$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                          @click="submitStandardItem();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    import partial_free_text from "@/view/configuration/items/views/partials/view_free_text.vue";
    import partial_yes_no_selector from "@/view/configuration/items/views/partials/view_yes_no_selector.vue";
    import partial_yes_no_na_selector from "@/view/configuration/items/views/partials/view_yes_no_na_selector.vue";
    import partial_multiple_n from "@/view/configuration/items/views/partials/view_multiple_n.vue";
    import partial_multiple_1 from "@/view/configuration/items/views/partials/view_multiple_1.vue";
    import partial_selector_feeds from "@/view/configuration/items/views/partials/view_selector_feeds.vue";
    import partial_time from "@/view/configuration/items/views/partials/view_time.vue";
    import partial_dosage from "@/view/configuration/items/views/partials/view_dosage.vue";
    import partial_table from "@/view/configuration/items/views/partials/view_table.vue";
    import partial_import from "@/view/configuration/items/views/partials/view_import.vue";
    import patial_graphic from "@/view/configuration/items/views/partials/view_graphic.vue";
    import partial_information_matrix from "@/view/configuration/items/views/partials/view_information_matrix.vue";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_standard_item",
        components: {
            GhButton,
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhChecks,
            GhTreeSelectorBlock
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationItemsStandardItems'])
        },
        data(){
            return {
                haveData: false,
                haveOptionToSelect: false,
                type: '',
                component: '',
                feed: [],
                type_item: 'standard_item',
                stateData: [],
                updated: false,
                opened: ''
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_items_')[1].split('_')[0];

            if(this.type === 'view' || this.type === 'edit') {
                await this.$store.dispatch('getDataConfigurationItemsStandardItem', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationItemsStandardItems.data = [];
            }

            this.stateData = this.ConfigurationItemsStandardItems;

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedItemGroups', '', {root: true});
                await this.$store.dispatch('getFeedOptionsToSelect', '', {root: true});
            }

            this.haveData = true;
        },
        async mounted() {
            this.haveData = false;
                var that = this;
                await setTimeout(function(){
                    if(that.ConfigurationItemsStandardItems.data !== undefined && that.ConfigurationItemsStandardItems.data.options_to_select !== undefined) {
                        that.getTypeSelected(that.ConfigurationItemsStandardItems.data.options_to_select[0].value);
                    }
                },150);
            this.haveData = true;
        },
        methods:{
            getTypeSelected(id){
                var value = id !== undefined ? id : document.querySelectorAll('input[name="standard_item[options_to_select]"]:checked')[0]._value;
                var finded = this.getFeeds.options_to_select.find(el => el.value === value);

                if(finded === undefined){
                    this.getFeeds.options_to_select.forEach((item) => {
                        if(item.children !== undefined){
                            finded = item.children.find(el => el.value === value) === undefined ? finded : item.children.find(el => el.value === value);
                        }
                    });
                }

                this.opened = finded;

                if(finded !== undefined){
                    switch(finded.type){
                        case 1:
                            this.component = partial_free_text;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 2:
                            this.component = partial_yes_no_selector;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 3:
                            this.component = partial_yes_no_na_selector;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 4:
                            this.component = partial_multiple_n;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 5:
                            this.component = partial_multiple_1;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 6:
                            this.component = partial_time;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 7:
                            this.component = partial_dosage;
                            this.feed = [];
                            this.updated = false;
                            break;
                        case 8:
                            this.component = partial_selector_feeds;
                            this.feed = finded.feed;
                            this.updated = this.ConfigurationItemsStandardItems.data !== undefined && this.ConfigurationItemsStandardItems.data.selecteds !== undefined ? true : false;
                            break;
                        case 9:
                            this.component = partial_table;
                            this.feed = [];
                            this.updated = this.ConfigurationItemsStandardItems.data !== undefined && this.ConfigurationItemsStandardItems.data.table !== undefined ? true : false;
                            break;
                        case 10:
                            this.component = partial_import;
                            this.feed = [];
                            this.updated = this.ConfigurationItemsStandardItems.data !== undefined && this.ConfigurationItemsStandardItems.data.import_data !== undefined ? true : false;
                            break;
                        case 11:
                            this.component = patial_graphic;
                            this.feed = [];
                            this.updated = this.ConfigurationItemsStandardItems.data !== undefined && this.ConfigurationItemsStandardItems.data.graphic !== undefined ? true : false;
                            break;
                        case 12:
                            this.component = partial_information_matrix;
                            this.feed = [];
                            this.updated = false;
                            break;
                        default:
                            this.component = '';
                    }

                    this.haveOptionToSelect = true;
                }
            },
            async submitStandardItem(){
                var params = new Array();
                if(this.opened.type === 9){
                    var tempParam = {};
                    var tableWidth = document.getElementById('list_table_structure_selected').clientWidth;
                    for (var i = 0; i < document.getElementById('list_table_structure_selected').children[0].children[0].children.length; i++) {
                        var realWidth = '';
                        var min_width = '';
                        var width = '';

                        if(document.getElementById('list_table_structure_selected').children[0].children[0].children[i].style.minWidth !== ''){
                            realWidth = parseInt(document.getElementById('list_table_structure_selected').children[0].children[0].children[i].clientWidth) - 20;
                            min_width = parseInt(document.getElementById('list_table_structure_selected').children[0].children[0].children[i].style.minWidth.split('px')[0]);
                            width = ((realWidth + 20) / tableWidth) * 100;

                            tempParam = {
                                text: document.getElementById('list_table_structure_selected').children[0].children[0].children[i].innerHTML.trim(),
                                field: document.getElementById('list_table_structure_selected').children[0].children[0].children[i].id.split('structure_selected_')[1],
                                style: 'min-width: ' + min_width + 'px; max-width: ' + realWidth + 'px; width: ' + width + '%;'
                            }
                        }else{
                            realWidth = parseInt(document.getElementById('list_table_structure_selected').children[0].children[0].children[i].clientWidth) - 20;
                            width = ((realWidth + 20) / tableWidth) * 100;
                            tempParam = {
                                text: document.getElementById('list_table_structure_selected').children[0].children[0].children[i].innerHTML.trim(),
                                field: document.getElementById('list_table_structure_selected').children[0].children[0].children[i].id.split('structure_selected_')[1],
                                style: 'min-width: ' + realWidth + 'px; max-width: ' + realWidth + 'px; width: ' + width + '%;'
                            }
                        }

                        params.push(tempParam);
                    }
                }

                if(this.Global.checkRequired('form_standard_item') == 'OK'){
                    const formData = new FormData(document.getElementById('form_standard_item'));
                    await this.$store.dispatch('setDataConfigurationItemsStandardItem', {
                        id: this.$root._route.params.id,
                        table: this.opened.type === 9 ? params : '',
                        formData: [...formData]
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    #standard_item_options_to_select_selector_label, #label_standard_item_group_selector_label {
        width: 150px;
        text-align: end;
    }
</style>