<template>
    <div class="content_page" style="padding-top: 12px;padding-bottom: 12px;" v-if="haveData">
        <form id="form_register">
            <div v-for="(label, labelIndex) in data" :key="labelIndex">
                <GhNoDropSelector v-if="Register.data[label['name']]"
                    :datablock="{
                        id: 'general'+label.id,
                        title: $t(label['name']),
                        second_title: '',
                        num_results: '',
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component" />
                    </template>
                </GhNoDropSelector>
            </div>
        </form>
        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{id: 'btn_send', text: $t('send_request'), class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="submitRegister()"
            />
            <GhButton
                :datainput="{id: 'btn_back', text: $t('exit'), class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import initial_registration from "@/view/register/template/initial_registration.vue";
    import main_contact_person from "@/view/register/template/main_contact_person.vue";
    import required_documents from "@/view/register/template/required_documents.vue";
    import privacy_data_forms from "@/view/register/template/privacy_data_forms.vue";

    import GhButton from 'fe-gh-button-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import {mapState} from "vuex";
    export default {
        name: "register_view",
        components:{
            GhNoDropSelector,
            GhButton
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'initial_registration', component: initial_registration},
                    {id: 2, name: 'main_contact_person', component: main_contact_person},
                    {id: 3, name: 'required_documents', component: required_documents},
                    {id: 4, name: 'privacy_data_forms', component: privacy_data_forms},
                ],
                haveData: false,
                keyGeneralData: 0,
                keyContactPerson: 0,
                keyContactPersonHSE: 0,
                keyTypeOfCompany: 0
            }
        },
        computed: {
            ...mapState(['Register']),
        },
        async beforeMount(){
            await this.$store.dispatch('getDefaultRegister', {type: this.$route.params.type}, {root:true});

            if(this.$root._route.params.type == 'professional-second-phase'){
                await this.$store.dispatch('getDataClinicOrProfessionalById', {
                  type: this.$root._route.params.type,
                  extra_type: 'professional'
                }, {root: true});
            }

            this.haveData = true;
        },
        methods:{
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
            async submitRegister(){
                if(this.Global.checkRequired('form_register') == 'OK') {
                    const formData = new FormData(document.getElementById('form_register'));
                    this.$store.dispatch('setRegister', {
                      formData: [...formData],
                      type: this.$root._route.params.type,
                      id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>