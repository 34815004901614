import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data_schedule_pre_ops: [],
    },
    mutations: {
        loadTableWarningsPreOps(state, payload) {
            state.records_data = payload;
        },
        loadSchedulePreOps(state, payload) {
            state.data_schedule_pre_ops = payload;
        }
    },
    actions: {
        async getWarningsPreOpsList(state){
            await axios.get(
                API_URL + "warnings/pre-ops"
            ).then(
                response => {
                    state.commit('loadTableWarningsPreOps', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSchedulePreOps(state,item){
            await axios.get(
                API_URL + "warnings/pre-ops/schedule/" + item.id
            ).then(
                response => {
                    state.commit('loadSchedulePreOps', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setSchedulePreOps(state, item){
            let requestLogin = API_URL + "warnings/pre-ops/schedule/" + item.id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};