import SectionConsentForms from "@/view/configuration/consent_forms/sections/section_consent_forms.vue";
import ConsentForms from "@/view/configuration/consent_forms/views/view_consent_form.vue";

const route = [
    {
        path: '/configuration/consent-forms',
        name: 'configuration_consent_forms',
        component: SectionConsentForms,
        props: {showMenu: true}
    },
    {
        path: '/configuration/consent-forms/form',
        name: 'configuration_consent_forms_new_form',
        component: ConsentForms,
        props: {id: null, showMenu: false, background: true, otherTitle: 'consent_forms'},
        children: [
            {
                path: '/configuration/consent-forms/form/:id',
                name: 'configuration_consent_forms_edit_form',
                component: ConsentForms,
                props: {id: null, showMenu: false, background: true, otherTitle: 'consent_forms'},
            },
            {
                path: '/configuration/consent-forms/form/:id/:view',
                name: 'configuration_consent_forms_view_form',
                component: ConsentForms,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'consent_forms'},
            }
        ],
    },
];

const routes = [
    ...route,
]

export default routes;