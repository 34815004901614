import ExaminationItem from "@/view/configuration/items/views/view_examination_item.vue";

const routes = [
    {
        path: '/configuration/items/examination-item',
        name: 'configuration_items_new_examination_items',
        component: ExaminationItem,
        props: {id: null, showMenu: false, background: true, otherTitle: 'examination_item'},
        children: [
            {
                path: '/configuration/items/examination-item/:id',
                name: 'configuration_items_edit_examination_items',
                component: ExaminationItem,
                props: {id: null, showMenu: false, background: true, otherTitle: 'examination_item'},
            },
            {
                path: '/configuration/items/examination-item/:id/:view',
                name: 'configuration_items_view_examination_items',
                component: ExaminationItem,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'examination_item'},
            }
        ],
    },

];

export default routes;