var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: 'haveData' ? 'OK' : '',
            text: _vm.$route.params.name + ' ' + _vm.$t('date').toLowerCase() + ' - ' + _vm.$route.params.date
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'department_data',
                title: _vm.$route.params.name
            }},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"12px"},attrs:{"extratable":{
                        id: 'table_departments'
                    },"header":_vm.header,"data":_vm.CalendarDataDepartment.department_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',{staticClass:"table-cell-colored",style:('background-color:' + _vm.colors[itemProp[labelProp]])})]}}],null,false,138925393)})]},proxy:true}:null],null,true)})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close',
                text: _vm.$t('close'),
            }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }