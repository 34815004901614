var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t('creation_' + _vm.typeCreation) + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_creation_' + _vm.typeCreation,
                title: _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":'form_register_' + _vm.typeCreation}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-bottom":"2px"}},[_c('GhTreeSelectorBlock',{ref:'RefSelected' + (_vm.typeCreation == 'process' ? 'Form' : 'Item'),attrs:{"dataBlock":{
                                    type: 'radio',
                                    id: _vm.typeCreation + '_' + _vm.$root._route.matched[0].props.default.otherTitle,
                                    label: _vm.$t(_vm.typeCreation == 'process' ? 'form' : 'item') + '*',
                                    title: _vm.$t(_vm.typeCreation == 'process' ? 'form' : 'item'),
                                    text: _vm.$t('select_' + (_vm.typeCreation == 'process' ? 'form' : 'item')) + ':',
                                    name: _vm.typeCreation + '[' + _vm.$root._route.matched[0].props.default.otherTitle + ']',
                                    id_value: 'value',
                                    name_label: 'text',
                                    child_key: 'children',
                                    required: true,
                                    checkparentdata: false,
                                    style: 'margin-left: 0px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                },"disabled_input":_vm.disabledItemForm,"selected_input":_vm.actualData.item !== undefined ? _vm.actualData.item : [],"items":_vm.typeCreation == 'process' ? _vm.getFeeds.forms : _vm.getFeeds.all_items},on:{"accept":_vm.getSelectedValues}})],1),(_vm.haveSelected && _vm.typeCreation !== 'process')?[_vm._l((_vm.ConfigurationFormProcess.info),function(item,index){return [(index !== 'children')?_c('div',{key:index + _vm.refreshSelected,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(index) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item))])]):_vm._e()]})]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[(_vm.parentData.data.data.data.length <= 1)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('mandatory')))]),_c('input',{attrs:{"type":"hidden","name":_vm.typeCreation + '[type]',"value":"1"}})]:[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + '*'))]),_c('GhChecks',{ref:"RefTypeMandatory",attrs:{"datalabel":{},"datainput":{
                                        required: true,
                                        label_required: _vm.$t('type') + '*',
                                        type: 'radio',
                                        id: _vm.typeCreation + '_type_mandatory',
                                        value: 1,
                                        name: _vm.typeCreation + '[type]',
                                        selected: _vm.actualData.type !== undefined && _vm.actualData.type == 2 ? false : true,
                                    }},on:{"click":function($event){return _vm.checkType('RefTypeMandatory')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"25px"}},[_vm._v(_vm._s(_vm.$t('mandatory')))]),_c('GhChecks',{ref:"RefTypeDependent",attrs:{"datalabel":{},"datainput":{
                                        required: true,
                                        label_required: _vm.$t('leave') + '*',
                                        type: 'radio',
                                        id: _vm.typeCreation + '_type_dependent',
                                        value: 2,
                                        name: _vm.typeCreation + '[type]',
                                        selected: _vm.actualData.type !== undefined && _vm.actualData.type == 2 ? true : false,
                                    }},on:{"click":function($event){return _vm.checkType('RefTypeDependent')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('dependent')))])]],2),(_vm.showDependent)?[_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-bottom":"2px"}},[_c('GhTreeSelectorBlock',{ref:"RefDependents",attrs:{"dataBlock":{
                                        type: 'checkbox',
                                        id: _vm.typeCreation + '_dependent_element',
                                        label: _vm.$t('dependent_element'),
                                        title: _vm.$t('dependent_element'),
                                        text: _vm.$t('select_dependent_element') + ':',
                                        name: _vm.typeCreation + '[dependent_element]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: false,
                                        checkparentdata: false,
                                        style: 'margin-left: 0px; width: 100%;',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                        showParent: true
                                    },"selected_input":_vm.actualData.dependent !== undefined ? _vm.actualData.dependent : [],"items":_vm.feedDependencies,"disabled_input":_vm.blockedByDependences},on:{"accept":function($event){return _vm.checkDependent()}}})],1),(_vm.showNumberOfDependencies)?_c('div',{key:_vm.refreshDependencies,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('number_of') + ' ' + _vm.nDependencies + ' ' + _vm.$t('dependencies').toLowerCase()))]),_c('GhAction',{staticStyle:{"margin-right":"23px"},attrs:{"dataction":{
                                        id: 'action_edit_dependencies_' + _vm.typeCreation,
                                        text: _vm.$t('edit'),
                                        icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                    }},on:{"click":_vm.openEditDependencies}})],1):_vm._e()]:_vm._e()],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept_' + _vm.typeCreation,
                    text: _vm.$t('accept'),
                }},on:{"click":_vm.setNewRegister}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_' + _vm.typeCreation,
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1),_c(_vm.components2.popup_dependencies_include,{key:_vm.refreshDependencies,ref:"RefDependencyDefinition",tag:"component",attrs:{"dataBlock":{data: _vm.feedAnswersByItems}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }