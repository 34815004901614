<template>
    <div style="display: flex;">
        <label :id="'description_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_medical_note'" class="gh_text_field-label T15_b" style="margin-right: 10px; ">{{dataItem.item.config.medical_note}}</label>
    </div>
</template>

<script>
export default {
    name: 'view_medical_note',
    props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
}
</script>