var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'table_category_process',
    },"header":_vm.header,"data":_vm.ConfigurationProcessCategory.list_category_process.length == 0 ? [] : _vm.ConfigurationProcessCategory.list_category_process},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_category_process_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_process_edit_category_process', {id: itemProp.id}),'configuration_process_category_process')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_category_process_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationCategoryProcess', itemProp.id,{ 'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_category_process'), _vm.$t('preparing_delete_category_process'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_category_process',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_process_new_category_process', {}),'configuration_process_category_process')}}})],1)]},proxy:true}],null,false,1241644159)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }