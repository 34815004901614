var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"},style:(_vm.$parent.checkedNA ? '' : 'margin-bottom: 10px;')},[_c('div',{staticStyle:{"width":"60% !important","display":"flex","z-index":"1","position":"relative"}},[_c('label',{staticClass:"gh_text_field-label T15_b",class:_vm.dataItem.item.config.label.class,staticStyle:{"margin-right":"10px"},style:(_vm.dataItem.item.config.label.style),attrs:{"id":'label_' + _vm.dataItem.item.config.label.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem + '_item'}},[_vm._v(_vm._s(_vm.dataItem.item.config.label.text))]),(_vm.dataItem.item.config.na)?_c('GhChecks',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'NA',attrs:{"datalabel":{text: _vm.$t('na'), style: '', class: '', id: ''},"datainput":{
                disabled: false,
                required: false,
                selected: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.na : false,
                type: 'checkbox',
                id: 'check_' + _vm.dataItem.item.config[_vm.dataItem.type === 1 ? 'textarea' : _vm.dataItem.type === 6 || _vm.dataItem.type === 7 ? 'input' : _vm.dataItem.type === 8 ? 'selector' : _vm.dataItem.type === 9 ? 'table' : _vm.dataItem.type === 10 ? 'imported' : _vm.dataItem.type == 11 ? 'graphic' : _vm.dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_na',
                value: 1,
                name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][na]'
            }},on:{"click":function($event){_vm.$parent.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.$parent.dataSubprocess.id);_vm.$parent.$parent.setNA(_vm.$children)}}}):_vm._e()],1),_c('div',{staticStyle:{"width":"40% !important","text-align":"right","display":"flex","margin-left":"-5px"}},[(_vm.dataItem.item.config.critical)?_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"color":"red","width":"100%"},attrs:{"id":'label_' + _vm.dataItem.item.config.label.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_item_' + _vm.dataItem.id + '_' + _vm.indexItem + '_critical'}},[_vm._v(_vm._s(_vm.$t('item_was_identified_as_critical') + '!'))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }