import CreationForm from "@/view/configuration/form_process/shared/view_creation.vue";

const routes = [
    {
        path: '/configuration/forms/creation-form',
        name: 'configuration_forms_new_creation_form',
        component: CreationForm,
        props: {id: null, showMenu: true, background: true, otherTitle: 'creation_form'},
        children: [
            {
                path: '/configuration/forms/creation-form/:id',
                name: 'configuration_forms_edit_creation_form',
                component: CreationForm,
                props: {id: null, showMenu: true, background: true, otherTitle: 'creation_form'},
            },
            {
                path: '/configuration/forms/creation-form/:id/:validate',
                name: 'configuration_forms_validate_creation_form',
                component: CreationForm,
                props: {id: null, validate: true, showMenu: true, background: true, otherTitle: 'validation_form'},
            },
            {
                path: '/configuration/forms/creation-form/:id/:view',
                name: 'configuration_forms_view_creation_form',
                component: CreationForm,
                props: {id: null, view: true, showMenu: true, background: true, otherTitle: 'creation_form'},
            }
        ],
    },
];

export default routes;