<template>
    <GhTextarea
        :datalabel="{text: ''}"
        :datainput="{
            id: 'textarea_' + dataItem.item.config[dataItem.type === 1 ? 'textarea' : dataItem.type === 6 || dataItem.type === 7 ? 'input' : dataItem.type === 8 ? 'selector' : dataItem.type === 9 ? 'table' : dataItem.type === 10 ? 'imported' : dataItem.type == 11 ? 'graphic' : dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_comment',
            name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][comment]',
            value: dataItem.item.data !== undefined ? dataItem.item.data.comment : '',
            required: false,
            edited: dataItem.item.config !== undefined && dataItem.item.config.comment !== undefined ? dataItem.item.config.comment.enhansed : false,
            image: {
                subscript: require('../../../../../assets/img/text/subscript.svg'),
                superscript: require('../../../../../assets/img/text/superscript.svg'),
                bold: require('../../../../../assets/img/text/text-bold.svg'),
                italic: require('../../../../../assets/img/text/text-italic.svg'),
                underline: require('../../../../../assets/img/text/text-underline.svg')
            }
        }"
        :placeholder="$t('comment')"
        :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
        @emiter="$parent.$parent.setChangesSection(subprocessId, $parent.$parent.dataSubprocess.id);"
    />
</template>

<script>
    import GhTextarea from "fe-gh-textarea-lib";

    export default {
        name: 'view_comment',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhTextarea
        },
        mounted() {
            console.log(this.$parent.$parent);
        }
    }
</script>