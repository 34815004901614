<template>
    <div class="float_window" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Visits.section_info[label['type']].show"
                :datablock="{
                    id: 'visits_' + label.id,
                    title: $t(label['id']) + (label['type'] == 'upcoming_patients_by_professional' && upcomingDataSelected !== '' ? ' - ' + upcomingDataSelected : ''),
                    second_title: $t(Visits.section_info[label['type']]['second_text']),
                    num_results: Visits.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
                <template v-slot:actions v-if="label['type'] == 'upcoming_patients_by_professional' && upcomingDataSelected !== ''">
                    <GhAction
                        :dataction="{
                            id: 'leave_all',
                            text: $t('back'),
                            icon: require('../../../assets/svg/back.svg'),
                        }"
                        @click="backCalendar"
                    />
                </template>
            </GhDropSelector>
        </div>
        <div class="centered" style="padding-top: 12px;" v-if="Visits.section_info.show_button_schedule_visit">
            <GhButton
                :datainput="{
                    id: 'btn_schedule_visit',
                    text: $t('schedule_visit')
                }"
                @click="Global.windowOpen(Global.openSecondWindow('new_visit_schedule', ''),'schedule')"
            />
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    import list_upcoming_patients from "@/view/visits/lists/list_upcoming_patients.vue";
    import list_follow_up_visits from "@/view/visits/lists/list_follow_up_visits.vue";
    import list_pending from "@/view/visits/lists/list_pending.vue";
    import list_potential_visits from "@/view/visits/lists/list_potential_visits.vue";
    import list_upcoming_patients_by_specialty_admin from "@/view/visits/lists/list_upcoming_patients_by_specialty_admin.vue";
    import list_call_to_remind_and_confirm from "@/view/visits/lists/list_call_to_remind_and_confirm.vue";
    import list_upcoming_patients_by_professional from "@/view/visits/lists/list_upcoming_patients_by_professional.vue";
    import GhAction from "fe-gh-action-lib";

    export default{
        name: "section_visits",
        components: {
            GhAction,
            GhButton,
            GhDropSelector,
        },
        computed : {
            ...mapState(['Visits'])
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 'upcoming_patients', name: 'upcoming_patients', component: list_upcoming_patients, type: 'upcoming_patients'},
                    {id: 'follow_up_visits', name: 'follow_up_visits', component: list_follow_up_visits, type: 'follow_up_visits'},
                    {id: 'pending', name: 'pending', component: list_pending, type: 'pending'},
                    {id: 'potential_visits', name: 'potential_visits', component: list_potential_visits, type: 'potential_visits'},
                    {id: 'upcoming_patients_by_specialty_admin', name: 'upcoming_patients_by_specialty_admin', component: list_upcoming_patients_by_specialty_admin, type: 'upcoming_patients_by_specialty_admin'},
                    {id: 'call_to_remind_and_confirm', name: 'call_to_remind_and_confirm', component: list_call_to_remind_and_confirm, type: 'call_to_remind_and_confirm'},
                    {id: 'upcoming_patients_by_professional', name: 'upcoming_patients_by_professional', component: list_upcoming_patients_by_professional, type: 'upcoming_patients_by_professional'}
                ],
                upcomingDataSelected: '',
                showCalendar: true
            }
        },
        async beforeMount(){
            var type = this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1];
            await this.$store.dispatch('getVisitsSections', {type: type}, {root: true});
            this.haveData = true;
        },
        // updated() {
        //     this.upcomingDataSelected = '';
        // },
        watch:{
            async '$store.state.Visits.nameVisitsSubTabActive'(data){
                var type = data.split('_')[1];

                this.haveData = false;
                await this.$store.dispatch('getVisitsSections', {type: type}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async backCalendar(){
                this.upcomingDataSelected = '';

                await this.$store.dispatch('getVisitsUpcomingPatientsByProfessionalList', {type: this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1]}, {root: true});

                this.showCalendar = true;
            }
        }
    }
</script>