<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_rename_' + dataBlock.type,
            title: dataBlock.type === 'process' ? $t('subprocess') : $t('section'),
            type: 'warning',
            style: 'width: 50%; min-width: 700px'
        }"
        :bodyConfiguration="{
            id: 'popup_rename_' + dataBlock.type + '_body',
            class: '',
            style: 'padding:2px;',
            text: dataBlock.type === 'process' ? $t('specifies_a_subprocess_name') : $t('specifies_a_section_name'),
        }"
        :footerConfiguration="{}"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <GhInputTextField
                    :datalabel="{
                        text: $t('name') + '*',
                        style: 'margin-left: 5px;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: dataBlock.type + '_name',
                        name: dataBlock.type + '[name]',
                        style: 'width: 100% !important',
                        value: dataBlock.data !== undefined && dataBlock.data.item !== undefined ? dataBlock.data.item.name : '',
                        type: 'text',
                    }"
                    ref="RefNameSectionSubprocess"
                />
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_popup_rename_' + dataBlock.type,
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="$emit('emiter')"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_popup_rename_' + dataBlock.type,
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_rename_' + dataBlock.type)"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'popup_create_section_subprocess',
        props: ['dataBlock'],
        components: {
            GhInputTextField,
            GhPopUp,
            GhButton
        }
    }
</script>