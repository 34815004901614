<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_priority',
                    title: $t('priority'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_priority" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_name_priority',
                                            text: $t('name_priority') + '*',
                                            style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_name_priority',
                                            name: 'priority[name]',
                                            type: 'text',
                                            value: ConfigurationSupportPriority.data && ConfigurationSupportPriority.data.name ? ConfigurationSupportPriority.data.name : '',
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('name_priority') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{ConfigurationSupportPriority.data && ConfigurationSupportPriority.data.name ? ConfigurationSupportPriority.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_description_priority',
                                            text: $t('description_priority') + '*',
                                            style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_description_priority',
                                            name: 'priority[description]',
                                            type: 'text',
                                            value: ConfigurationSupportPriority.data && ConfigurationSupportPriority.data.description ? ConfigurationSupportPriority.data.description : '',
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('description_priority') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{ConfigurationSupportPriority.data && ConfigurationSupportPriority.data.description ? ConfigurationSupportPriority.data.description : ''}}</label>
                                </template>
                            </div>
                            <div v-if="ConfigurationSupportPriority.data && ConfigurationSupportPriority.data.groups && ConfigurationSupportPriority.data.groups.length !== 0">
                                <div v-for="(item,index) in ConfigurationSupportPriority.data.groups" :key="index">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t(item.name)}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="!$root._route.params.view">
                                            <GhInputTextField
                                                :datalabel="{
                                                    id: 'time_priority_response_' + item.id,
                                                    text: $t('time_priority_response') + ' (h)' + '*',
                                                    style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    name: 'priority[groups]['+item.id+'][response_time]',
                                                    style: 'width:20%; ',
                                                    type: 'int',
                                                    value: item.response_time !== undefined ? item.response_time : '',
                                                    required:true
                                                }"
                                            />
                                            <GhInputTextField
                                                :datalabel="{
                                                    id: 'time_priority_resolution_'+item.id,
                                                    text: $t('time_priority_resolution') + ' (h)' + '*',
                                                    style: 'width: 88px; min-width: 208px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    name: 'priority[groups]['+item.id+'][solution_time]',
                                                    style: 'width:20%;',
                                                    type: 'int',
                                                    value: item.solution_time !== undefined ? item.solution_time : '',
                                                    required: true
                                                }"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('time_priority_response') + ' (h)' + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 44%; min-width: 100px;">{{item.response_time !== undefined ? item.response_time : ''}}</label>
                                            <label class="gh_text_field-label T15" style="width: 88px; min-width: 208px; text-align: right; margin-right: 10px;">{{$t('time_priority_resolution') + ' (h)' + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 44%; min-width: 100px;">{{item.solution_time !== undefined ? item.solution_time : ''}}</label>
                                        </template>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="!$root._route.params.view">
                                            <GhInputTextField
                                                :datalabel="{
                                                    id:'time_priority_percentage_' + item.id,
                                                    text: $t('percentage'),
                                                    style: 'width: 192px; min-width: 192px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    id: 'input_priority_percentage_' + item.id,
                                                    name: 'priority[groups][' + item.id + '][percentage]',
                                                    style: 'width:20%; max-height: 17px;',
                                                    type: 'int',
                                                    limitNumMin: 0,
                                                    limitNumMax: 100,
                                                    value: item.percentage !== undefined ? item.percentage : ''
                                                }"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('percentage') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{item.percentage !== undefined ? item.percentage : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_accept',
                        class: 'T19 container-md_button_content',
                        text: $t('accept')
                    }"
                    @click="setPriority()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhButton from 'fe-gh-button-lib'
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhLocation from "fe-gh-location-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_priority",
        components:{
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        data(){
            return{
                type: '',
                haveData: false,
            }
        },
        computed:{
            ...mapState(['ConfigurationSupportPriority'])
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_support_')[1].split('_priority')[0];
            await this.$store.dispatch('getDataConfigurationSupportPriority', {id: this.$root._route.params.id}, {root: true});
            this.haveData = true;
        },
        methods:{
            async setPriority(){
                if(this.Global.checkRequired('form_priority') == 'OK') {
                    const formData = new FormData(document.getElementById('form_priority'));
                    await this.$store.dispatch('setDataConfigurationSupportPriority', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.windowClose();
                }
            },
        }
    }
</script>