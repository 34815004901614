var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content",staticStyle:{"height":"18px"}},[_c('div',[_c('div',{staticClass:"no-min-width",staticStyle:{"width":"50%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                        id: 'label_clinic_type',
                        class: 'T15_b',
                        subtitle: _vm.$t('Select a specialty') + ':',
                        text: _vm.$t('specialty'),
                        label: _vm.$t('specialty'),
                        type: 'checkbox',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    },"datainput":{
                        id: 'clinic_type',
                        name: 'clinic[type]'
                    },"feed":_vm.getFeeds.type_clinic,"selected_input":[]}})],1),_c('div',{staticClass:"no-min-width",staticStyle:{"width":"50%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                        id: 'label_clinic_province',
                        class: 'T15_b',
                        subtitle: _vm.$t('Select a province') + ':',
                        text: _vm.$t('province'),
                        label: _vm.$t('province'),
                        type: 'checkbox',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    },"datainput":{
                        id: 'clinic_province',
                        name: 'clinic[province]'
                    },"feed":_vm.getFeeds.province_clinic,"selected_input":[]}})],1)])]),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                id: 'label_clinic_city',
                class: 'T15_b',
                subtitle: _vm.$t('Select a city') + ':',
                text: _vm.$t('city'),
                label: _vm.$t('city'),
                type: 'checkbox',
                columns: 3,
                required: false,
                style: '',
                popupStyle: 'min-width: 900px; width: 55%;',
            },"datainput":{
                id: 'clinic_city',
                name: 'clinic[city]'
            },"feed":_vm.getFeeds.city_clinic,"selected_input":[]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }