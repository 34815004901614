var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.dataBlock.index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('variable') + ' ' + (_vm.dataBlock.index + 1) + '*',
                style: 'width: 150px; min-width: 150px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                required: true,
                id: _vm.$parent.$parent.$parent.type_item + '_' + _vm.dataBlock.type + '_variables_' + _vm.dataBlock.index + '_variable',
                name: _vm.$parent.$parent.$parent.type_item + '[' + _vm.dataBlock.type + '][variables][' + _vm.dataBlock.index + '][variable]',
                style: 'min-width: 365px; width: 365px',
                value: _vm.dataBlock.item.variable,
                type: 'text',
            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('variable') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.dataBlock.item.variable))])],(_vm.$root._route.params.view !== 'true')?[_c('GhAction',{staticStyle:{"margin-left":"10px","width":"max-content","white-space":"nowrap"},attrs:{"dataction":{
                id: 'action_delete_' + _vm.dataBlock.index,
                text: _vm.$t('delete'),
                icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
            }},on:{"click":function($event){return _vm.$parent.removeVariable(_vm.dataBlock.index)}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }