<template>
    <div>
        <form id="form_process">
            <div v-for="(item,index) in subprocess" :key="index" style="display: block">
                <GhDropSelector
                    :datablock="{
                        id: 'subprocess_section_' + item.id,
                        title: item.name,
                        second_title: '',
                        num_results: ''
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="subprocess_content" :dataSubprocess="item" :indexSubprocess="index" :processId="processId" :subprocessId="dataTab.id"/>
                    </template>
                </GhDropSelector>
            </div>
        </form>

        <div class="centered" style="padding-top: 10px;">
            <GhButton v-if="getButtons.finish"
                :datainput="{
                    id: 'button_save_process_' + processId + '_' + dataTab.id,
                    text: $t('finish'),
                    class: '',
                    style: ''
                }"
                @click="setSaveProcess(processId, dataTab.id)"
            />
            <GhButton v-if="getButtons.finish"
                :datainput="{
                    id: 'button_back_process_' + processId + '_' + dataTab.id,
                    text: $t('back'),
                    class: '',
                    style: ''
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import subprocess_content from '@/view/process/views/view_process.vue';

    import {mapGetters, mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'section_process',
        components: {
            GhButton,
            GhDropSelector
        },
        props: ['dataProcess','processId'],
        computed: {
            ...mapState(['Process']),
            ...mapGetters(['getSubprocess', 'getButtons']),
            subprocess(){
                return this.getSubprocess;
            }
        },
        data(){
            return{
                dataTab: '',
                refresh: 0,
                subprocess_content: subprocess_content
            }
        },
        async beforeMount(){
            this.dataTab = this.dataProcess.dataTab;
            await this.$store.dispatch('getSubProcess', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
            await this.$store.dispatch('getSubProcessButtons', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
            await this.$store.dispatch('getSupProcessExtraItems', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
        },
        async updated(){
            if(this.dataTab != this.dataProcess.dataTab){
                this.dataTab = this.dataProcess.dataTab;
                await this.$store.dispatch('getSubProcess', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
                await this.$store.dispatch('getSubProcessButtons', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
                await this.$store.dispatch('getSupProcessExtraItems', {task_id: this.$root._route.params.id, process_id: this.processId, subprocess_id: this.dataTab.id}, {root: true});
            }
        },
        methods: {
            async setSaveProcess(supraId, processId){
                var params = {
                    supraId: supraId,
                    processId: processId
                }

                if(this.Global.checkRequired('form_process') == 'OK') {
                    const formData = new FormData(document.getElementById('form_process'));
                    await this.$store.dispatch('setSectionSave', {
                        formData: [...formData],
                        item: params
                    }, {root: true});
                }
            }
        }
    }
</script>