<template>
    <div style="padding-top: 10px;">
        <div class="T14 link table-header">{{$t('permissions')}}</div>
        <form id="form_associated_users">
            <div style="margin-right: 10px" :key="refreshAssociatedUsers">
                <div class="gh-row-content" style="padding-bottom: 3px !important; width: 100%; flex-wrap: wrap; padding-left: 10px; padding-top: 2px;" v-for="(item,index) in getFeeds.feed_perms" :key="index" :style="index % 2 ? '' : 'background-color: #d9edfc;'">
                    <div>
                        <div style="width: calc((100% - 240px) - (((100% - 280px) * 12) / 15))" v-for="(itemChildren,indexChildren) in item" :key="indexChildren" :title="itemChildren.description">
                            <GhChecks
                                :datalabel="{
                                    text: itemChildren.name,
                                    style: '',
                                    class: '',
                                    id: ''
                                }"
                                :datainput="{
                                    disabled: $root._route.params.view ? true : false,
                                    required: false,
                                    selected: '',
                                    type: 'checkbox',
                                    id: itemChildren.id,
                                    value: itemChildren.id,
                                    name: 'associated[perms][]'
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div style="background-color: #c5d1df; height: 46px;">
            <div class="centered" style="padding-top: 9px;">
                <GhButton
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept')
                    }"
                    @click="acceptAssociatedUsers"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_clean',
                        text: $t('clean')
                    }"
                    @click="cleanAssociatedUsers"
                />
            </div>
        </div>

        <template v-if="PermissionsAssociatedUsers.records_data !== undefined && PermissionsAssociatedUsers.records_data.users !== undefined">
            <GhTableCommon style="margin-top: 30px;"
                :extratable="{
                    id: 'search_permissions',
                }"
                :header="header"
                :data="PermissionsAssociatedUsers.records_data.users"
            />

            <div class="gh-row-content" style="margin-top: 10px;">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('groups') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{PermissionsAssociatedUsers.records_data.groups}}</label>
            </div>
            <div class="gh-row-content">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('profiles') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{PermissionsAssociatedUsers.records_data.profiles}}</label>
            </div>
        </template>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from "fe-gh-table-common-lib";

    export default {
        name: 'list_associated_user',
        components: {
            GhChecks,
            GhButton,
            GhTableCommon
        },
        computed: {
            ...mapState(['getFeeds', 'PermissionsAssociatedUsers']),
        },
        data(){
            return{
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'email', field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
                ],
                refreshAssociatedUsers: 0,
                haveData: false
            }
        },
        async beforeMount(){
            this.PermissionsAssociatedUsers.records_data = [];

            await this.$store.dispatch('getFeedPermissions', '', {root: true});

            this.haveData = true;
        },
        methods:{
            cleanAssociatedUsers(){
                this.PermissionsAssociatedUsers.records_data = [];
                this.refreshAssociatedUsers++;
            },
            acceptAssociatedUsers(){
                const formData = new FormData(document.getElementById('form_associated_users'));
                this.$store.dispatch('checkAssociatedUsers', {
                    formData: [...formData],
                }, {root: true});
            }
        }
    }
</script>