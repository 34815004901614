<template>
    <div id="option_to_select_multiple_1" v-if="haveData" style="margin-bottom: 10px;">
        <div v-for="(item, index) in actualData" :key="index + refreshData">
            <component v-bind:is="component" :dataBlock="{item: item, index: index, type: 'multiple_1'}" :key="index + refreshData" :ref="'RefLineOfMultiple' + index"/>
        </div>
        <template v-if="$root._route.params.view !== 'true'">
            <GhAction
                style="margin-left: 160px;"
                :dataction="{
                    id: 'action_add_answer',
                    text: $t('add_answer'),
                    icon: require('../../../../../assets/svg/add_plus.svg')
                }"
                @click="addAnswer"
            />
            <br>
        </template>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";

    import include_line_of_multiples from "@/view/configuration/items/views/partials/includes/include_line_of_multiples.vue";

    export default {
        name: 'view_multiple_1',
        components: {
            GhAction
        },
        data(){
            return {
                haveData: false,
                component: include_line_of_multiples,
                newData: {
                    'answer': '',
                    'item': [],
                    'associate_to': false
                },
                actualData: [],
                refreshData: 0,
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined){
                this.addAnswer(this.$parent.$parent.stateData.data);
            }else{
                this.addAnswer();
            }

            await this.$store.dispatch('getFeedItems', {type: this.$parent.$parent.type_item}, {root: true});
            this.haveData = true;
        },
        methods: {
            addAnswer(data){
                var params = new Array();
                if(data.multiple_1 !== undefined){
                    params = data.multiple_1;
                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }
                }else {
                    this.actualData.forEach((item, key) => {
                        var interlude = [];
                        interlude.push(this.$refs['RefLineOfMultiple' + key][0].$children[1].datasaved);
                        var array = {
                            'answer': this.$refs['RefLineOfMultiple' + key][0].$children[0].datainput.value,
                            'item': interlude,
                            'associate_to': document.querySelector('[name="' + this.$refs['RefLineOfMultiple' + key][0].$children[2].datainput.name + '"]:checked') !== null ? true : false
                        };
                        params.push(array);
                    });

                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }

                    this.actualData.push(this.newData);
                }

                this.refreshData++;
            },
            removeAnswer(index){
                var params = new Array();
                this.actualData.forEach((item, key) => {
                    if(key !== index) {
                        var interlude = [];
                        interlude.push(this.$refs['RefLineOfMultiple' + key][0].$children[1].datasaved);
                        var array = {
                            'answer': this.$refs['RefLineOfMultiple' + key][0].$children[0].datainput.value,
                            'item': interlude,
                            'associate_to': document.querySelector('[name="' + this.$refs['RefLineOfMultiple' + key][0].$children[2].datainput.name + '"]:checked') !== null ? true : false
                        };
                        params.push(array);
                    }
                });

                this.actualData = [];
                if(params[0] !== undefined) {
                    params.forEach((item) => {
                        this.actualData.push(item);
                    });
                }

                this.refreshData++;
            }
        }
    }
</script>