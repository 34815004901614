<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'reasign_confirm',
            title: $t('reasign'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: $t(data.type == 1 ? 'reasign_following_non_active_professional' : 'reasign_following_patient_not_presented') + ':',
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div style="overflow: auto; max-height: 800px; width: 100%; margin-right: 40px;">
                <div class="gh_delete_confirm-content" style="padding-bottom: 15px;">

                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('name') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{data.name}}</label>
                    <br>
                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('surname') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{data.surname}}</label>
                </div>
                <form id="form_popup_reasign">
                    <input type="hidden" name="reasign[id]" :value="data.id">
                    <input type="hidden" name="reasign[type]" :value="data.type">
                    <div class="gh-row-content" style="margin-left: 20px; padding-top: 15px;">
                        <template v-if="data.type == 1">
                            <GhMultipleSearch
                                :datalabel="{
                                    text: $t('reasign') + '*',
                                    style: '',
                                    class: 'T15_b',
                                    id: 'label_reasign'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'reasign_user',
                                    name: 'reasign[user][name]',
                                    nameS:'reasign[user][surname]',
                                    nameId:'reasign[user][id]',
                                    style: 'width: 40%',
                                    value: '',
                                    valueId: '',
                                    valueS: '',
                                    placeholder: $t('name'),
                                    placeholderS: $t('surname'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: dispatchUserExecution
                                }"
                            />
                        </template>
                        <template v-else>
                            <GhCalendar
                                :datalabel="{
                                    text: $t('visit_date'),
                                }"
                                :datainput="{
                                    id: 'reasign_visit_date',
                                    name: 'reasign[visit_date]',
                                    value: '',
                                    required: true
                                }"
                            />
                            <GhInputHour
                                :datalabel="{text: $t('visit_hour')}"
                                :datainput="{
                                    id: 'reasign_input_hour',
                                    name: 'reasign[input_hour]',
                                    hour: '',
                                    minute: '',
                                    required: true
                                }"
                            />
                        </template>
                    </div>
                </form>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_reasign',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="submitReasign()"
            />
            <GhButton
                :datainput="{
                    id: 'button_clse_reasign',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('reasign_confirm');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    import GhButton from "fe-gh-button-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhInputHour from "fe-gh-input-hour-lib";

    export default{
        name: "popup_reasign",
        props: ['data'],
        components: {
            GhInputHour,
            GhCalendar,
            GhPopUp,
            GhMultipleSearch,
            GhButton
        },
        data(){
            return {
                showDates: true,
                dispatchUserExecution: 'getClinicProfessionalsSearch',
            }
        },
        methods: {
            async submitReasign(){
                if(this.Global.checkRequired('form_popup_reasign') == 'OK'){
                    const formData = new FormData(document.getElementById('form_popup_reasign'));
                    await this.$store.dispatch('setReasignIncidence', {
                        formData: [...formData]
                    }, {root: true});

                    this.Global.closePopUp('reasign_confirm');
                }
            }
        }
    }
</script>