<template>
    <div style="display: block; min-width: 1024px;">
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <div>
                    <div style="display: flex; margin-top: 1px;" :id="'label_' + dataBlock.type + '_graphic_type_data_block_selector'" class="T15_b">
                        <label class="T14" :id="'label_' + dataBlock.type + '_graphic_type_selector_label'" style="width: 150px; text-align: end;">{{$t('graphic_type') + '*'}}</label>
                        <div :id="'label_' + dataBlock.type + '_graphic_type_data_block_selector_operators'" style="cursor: pointer; margin-left: 10px">
                            <div :class="toggleGraphicType" style="white-space: nowrap;" @click="Global.openPopUp('label_' + dataBlock.type + '_graphic_type_data_selector_popup');setStatePopup();">{{statePopupGraphicType}}</div>
                        </div>
                    </div>
                    <div :id="'label_' + dataBlock.type + '_graphic_type_data_select_SUMMARY'" class="gh_data_selector-summary T15" style="display: flex; white-space: normal; height: auto; margin-top: 1px;">{{summaryGraphicType}}</div>
                    <input :id="'label_' + dataBlock.type + '_graphic_type_hidden_data_selector_required'" :label_required="$t('graphic_type') + '*'" class="required" type="hidden" :value="summaryGraphicType != '' ? 1 : ''">
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('graphic_type') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{summaryGraphicType}}</label>
            </template>
        </div>
        <template v-if="!$root._route.params.view">
            <GhPopUp v-show="false"
                  :generalConfiguration="{
                      id: 'label_' + dataBlock.type + '_graphic_type_data_selector_popup',
                      title: $t('graphic_type'),
                      type: 'add',
                      class: '',
                      style: 'width: 65%; min-width:900px !important; min-height: 310px; max-height: 80%; overflow-y: auto;',
                  }"
                  :bodyConfiguration="{id: '', class: '', style: 'padding:2px;', text:''}"
                  :footerConfiguration="{id:'', class: 'gh_data_selector-buttons', style: 'padding-top: 20px;', content: ''}"
            >
                <template v-slot:body>
                    <div class="gh_data_selector-subtitle T21 " style="margin-left: calc((100% - 240px) - (((100% - 240px) * 12) / 13) + 40px);">
                        {{$t('select_graphic_type') + ':'}}
                    </div>
                    <div :id="'label_' + dataBlock.type + '_graphic_type'" style="display: flex; margin-left: calc((100% - 240px) - (((100% - 240px) * 12) / 13) + 40px); margin-top: 20px; margin-right: calc((100% - 240px) - (((100% - 240px) * 12) / 13) + 40px);">
                        <div style="width: 33.33%" v-for="(item, index) in dataRadioGraphicType" :key="refreshChecks + index">
                            <div class="centered">
                                <div class="md-input" style="padding-top: 8px;">
                                    <GhChecks
                                        :datainput="{
                                            id: dataBlock.type + '_graphic_type_' + index,
                                            class: 'T19 ',
                                            style: 'position: relative;margin-bottom: -15px;',
                                            value: index,
                                            type: 'radio',
                                            required: false,
                                            name: dataBlock.type + '[graphic][type]',
                                            selected: checked == index ? true : false,
                                            disabled: false,
                                        }"
                                        :datalabel="{
                                            style:'',
                                            id:'',
                                            text: item.value,
                                            class: ''
                                        }"
                                        ref="ghChecksDataSelector"
                                        @click="setCheckGraphic(index);"
                                    />
                                </div>
                            </div>
                            <div class="centered" style="padding-top: 15px;">
                                <img width="220" :src="require('../../../../../../assets/charts/' + item.image)">
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <GhButton
                        :datainput="{
                            id: 'button_accept_' + dataBlock.type + '_graphic_type',
                            text: $t('accept'),
                            class: 'T19 container-md_button_content',
                            style: 'display: flex;'
                        }"
                        @click="setSummaryGraphicType();Global.closePopUp('label_' + dataBlock.type + '_graphic_type_data_selector_popup');setStatePopup();"
                    />
                    <GhButton
                        :datainput="{
                            id: 'button_close_' + dataBlock.type + '_graphic_type',
                            text: $t('close'),
                            class: 'T19 container-md_button_content',
                            style: 'display: flex;'
                        }"
                        @click="Global.closePopUp('label_' + dataBlock.type + '_graphic_type_data_selector_popup');setBeforeAccept();setStatePopup();"
                    />
                </template>
            </GhPopUp>
        </template>
    </div>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'popup_graphic_type',
        props: ['dataBlock'],
        components: {
            GhPopUp,
            GhChecks,
            GhButton
        },
        data(){
            return {
                statePopup: false,
                checked: this.dataBlock.item !== undefined && this.dataBlock.item.type !== undefined ? this.dataBlock.item.type : '',
                refreshChecks: 0,
                dataRadioGraphicType: {1: {value: 'Lineal chart', image: 'line.png'}, 2: {value: 'Bar chart', image: 'bar.png'}, 3: {value: 'Area chart', image: 'area.png'}},
                checkGraphicType: [],
                summaryGraphicType: '',
            }
        },
        mounted(){
            if(this.checked !== '') {
                this.summaryGraphicType = this.dataRadioGraphicType[this.checked].value;
            }
        },
        methods:{
            setStatePopup(){
                this.statePopup = !this.statePopup;
            },
            setSummaryGraphicType(){
                let jsonArray = '';

                this.$refs.ghChecksDataSelector.forEach((item) => {
                    if (item.$el.children[0].children[1].checked == true) {
                        jsonArray += '"' + item.datainput.value + '": "' + this.dataRadioGraphicType[item.datainput.value].value + '", ';
                    }
                });

                jsonArray = jsonArray.substring(0, jsonArray.length - 2);
                jsonArray = '{' + jsonArray + '}'

                this.checkGraphicType = JSON.parse(jsonArray);

                var summary = '';
                for(let element in this.checkGraphicType) {
                    summary += this.checkGraphicType[element] + ', ';
                }
                summary = summary.substring(0, summary.length - 2);
                this.summaryGraphicType = summary;
            },
            setBeforeAccept(){
                this.$refs.ghChecksDataSelector.forEach((item) => {
                    item.$el.children[0].children[1].checked = true;
                    item.checkUncheck();
                });
            },
            setCheckGraphic(id) {
                this.checked = id;
                this.$refs.ghChecksDataSelector.forEach((item) => {
                    if (item.datainput.value != id && item.$el.children[0].children[1].checked == true){
                        item.checkUncheck();
                    }
                });

                this.refreshChecks++;
            }
        },
        computed: {
            statePopupGraphicType(){
                return this.statePopup ? '[ - ]' : '[ + ]';
            },
            toggleGraphicType(){
                return this.statePopup ? 'gh_data_selector-less T19' : 'gh_data_selector-more T19'
            }
        }
    }
</script>