import Support from "@/view/configuration/support/sections/section_support.vue";
import Category from './categories/route';
import Priority from './priorities/route';
import Assignment from './assignment/route';
import Schedule from './schedule/route';
import Holiday from './holidays/route';

const route = [
    {
        path: '/configuration_support',
        name: 'configuration_support',
        component: Support,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Category,
    ...Priority,
    ...Assignment,
    ...Schedule,
    ...Holiday
]

export default routes;