var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[(_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined && !_vm.dataItem.item.config.selector.is_tree)?_c('GhDataSelector',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"datablock":{
                    id: 'label_selector_' + _vm.dataItem.item.config.selector.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item',
                    class: 'T15_b',
                    subtitle: _vm.$t(_vm.dataItem.item.config.selector.subtitle) + ':',
                    text: _vm.$t(_vm.dataItem.item.config.selector.text),
                    label: _vm.$t(_vm.dataItem.item.config.selector.text),
                    type: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined ? _vm.dataItem.item.config.selector.type : 'radio',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;'
                },"datainput":{
                    id: 'selector_' + _vm.dataItem.item.config.selector.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item',
                    name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item]' + _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined && _vm.dataItem.item.config.selector.type !== undefined && _vm.dataItem.item.config.selector.type === 'checkbox' ? '[]' : ''
                },"feed":_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined ? _vm.dataItem.item.config.selector.feed : [],"selected_input":_vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.item !== undefined ? _vm.dataItem.item.data.item : []},on:{"accept":function($event){_vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);_vm.$parent.checkExtraItem(_vm.$el, _vm.haveExtraItem, _vm.$refs, 8, _vm.dataItem.item.config.selector.id, _vm.processId, _vm.subprocessId, _vm.formId, _vm.sectionId, _vm.indexItem, _vm.dataItem.id)}}}):_c('GhTreeSelectorBlock',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"dataBlock":{
                    type: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined ? _vm.dataItem.item.config.selector.type : 'radio',
                    id: 'label_selector_' + _vm.dataItem.item.config.selector.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item',
                    label: _vm.$t(_vm.dataItem.item.config.selector.text) + '*',
                    title: _vm.$t(_vm.dataItem.item.config.selector.text),
                    text: _vm.$t(_vm.dataItem.item.config.selector.subtitle) + ':',
                    name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item]' + _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined && _vm.dataItem.item.config.selector.type !== undefined && _vm.dataItem.item.config.selector.type === 'checkbox' ? '[]' : '',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: true,
                    checkparentdata: false,
                    style: 'margin-left: 0px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"selected_input":_vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.item !== undefined ? _vm.dataItem.item.data.item : [],"items":_vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.selector !== undefined ? _vm.dataItem.item.config.selector.feed : []},on:{"accept":function($event){_vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);_vm.$parent.checkExtraItem(_vm.$el, _vm.haveExtraItem, _vm.$refs, 8, _vm.dataItem.item.config.selector.id, _vm.processId, _vm.subprocessId, _vm.formId, _vm.sectionId, _vm.indexItem, _vm.dataItem.id)}}}),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }