<template>
    <div class="gh-row-content" :key="dataBlock.index">
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('column') + ' ' + (dataBlock.length + dataBlock.index + 1) + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: true,
                    id: $parent.$parent.$parent.type_item + '_new_column_' + dataBlock.type + '_' + dataBlock.index + '_column',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][new_column][' + dataBlock.index + '][column]',
                    style: 'min-width: 300px; width: 300px',
                    value: dataBlock.item.column,
                    type: 'text',
                }"
                :ref="'RefColumnName' + (dataBlock.length + dataBlock.index + 1)"
                @keyup="$parent.renameColumn(dataBlock.length + dataBlock.index, 'RefLineOfTable' + dataBlock.index, 'RefColumnName' + (dataBlock.length + dataBlock.index + 1))"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('column') + ' ' + (dataBlock.length + dataBlock.index + 1) + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.column}}</label>
        </template>
        <template v-if="!$root._route.params.view">
            <div class="change-min-with-type">
                <GhDataSelector
                    :datablock="{
                        id: 'label_' + $parent.$parent.$parent.type_item + '_new_column_' + dataBlock.type + '_' + dataBlock.index + '_type',
                        class: 'T15_b',
                        subtitle: $t('select_type') + ' ' + (dataBlock.length + dataBlock.index + 1) + ':',
                        text: $t('type') + '*',
                        label: $t('type'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: 'margin-left: 25px;',
                        popupStyle: 'min-width: 900px; width: 55%;',
                    }"
                    :datainput="{
                        id: $parent.$parent.$parent.type_item + '_new_new_column_' + dataBlock.type + '_' + dataBlock.index + '_type',
                        name:  $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][new_column][' + dataBlock.index + '][type]'
                    }"
                    :feed="getFeeds.type_ddbb.feeds"
                    :selected_input="dataBlock.item.type"
                    :ref="'RefSelectorType' + dataBlock.index"
                    @accept="checkType(dataBlock.index)"
                />
            </div>
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(dataBlock.item.type).join()}}</label>
        </template>
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('length') + '*',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: type.disabled === true || type.disabled === 'true' ? false : true,
                    id: $parent.$parent.$parent.type_item + '_new_column_' + dataBlock.type + '_' + dataBlock.index + '_length',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][new_column][' + dataBlock.index + '][length]',
                    style: 'min-width: 75px; width: 75px',
                    value: dataBlock.item.length,
                    type: type.type_input,
                    limitNumMin: type.min,
                    limitNumMax: type.max,
                    class: type.disabled === true || type.disabled === 'true' ? 'disabled' : ''
                }"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('length') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.length}}</label>
        </template>
        <template v-if="$root._route.params.view !== 'true'">
            <GhAction
                style="margin-left: 10px; width: max-content; white-space: nowrap"
                :dataction="{
                    id: 'action_delete_structure_' + dataBlock.index,
                    text: $t('delete'),
                    icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
                }"
                @click="$parent.removeColumn('new_columns', dataBlock.index)"
            />
        </template>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'include_line_of_table',
        props: ['dataBlock'],
        components: {
            GhAction,
            GhDataSelector,
            GhInputTextField
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                type: {
                    length: this.dataBlock.item.length,
                    disabled: this.dataBlock.item.disabled,
                    max: this.dataBlock.item.max,
                    min: this.dataBlock.item.min,
                    type_input: this.dataBlock.item.type_input
                },
            }
        },
        methods: {
            checkType(id){
                if(this.$refs['RefSelectorType' + id].checkdata !== undefined) {
                    var selected = Object.keys(this.$refs['RefSelectorType' + id].checkdata)[0];
                    var values = this.getFeeds.type_ddbb.values[selected];
                    this.type = {
                        length: values.default,
                        disabled: values.disabled,
                        max: values.max,
                        min: values.min,
                        type_input: values.type,
                    }
                }

                this.$parent.checkActualData(this.type);
            }
        }
    }
</script>

<style>
    .change-min-with-type > div{
        min-width: 318px !important;
        max-width: 318px !important;
    }
</style>