import SectionProfessional from "@/view/calendar/professional/sections/section_professional.vue"
import ViewProfessional from "@/view/calendar/professional/views/professional.vue";

const route = [
    {
        path: '/calendar/professional',
        name: 'calendar_professional',
        component: SectionProfessional,
        props: {showMenu: true}
    },
    {
        path: '/calendar/:from/:id/:name/:date',
        name: 'view_professional',
        component: ViewProfessional,
        props: {showMenu: false, background: true, id: null, name: undefined, date: undefined, from: null},
    },
];

const routes = [
    ...route,
]

export default routes;