<template>
    <div style="margin-top: -4px;">
        <GhNoDropSelector
            :datablock="{
                id: 'view_creation_' + typeCreation,
                title: $t($root._route.matched[0].props.default.otherTitle)
            }"
        >
            <template v-slot:content>
                <form :id="'form_creation_' + typeCreation" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('id') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: typeCreation + '_code',
                                        name: typeCreation + '[code]',
                                        style: 'width: 100px',
                                        value: code,
                                        type: 'text',
                                    }"
                                    ref="RefCode"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('id') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationFormProcess.data_form_process !== undefined ? ConfigurationFormProcess.data_form_process.code : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: typeCreation + '_name',
                                        name: typeCreation + '[name]',
                                        style: 'width: 300px',
                                        value: name,
                                        type: 'text',
                                    }"
                                    ref="RefName"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationFormProcess.data_form_process !== undefined ? ConfigurationFormProcess.data_form_process.name : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_' + typeCreation + '_category',
                                        class: 'T15_b',
                                        subtitle: $t('select_a_category_' + typeCreation) + ':',
                                        text: $t('category_' + (typeCreation == 'process' ? 'process' : 'form')),
                                        label: $t('category_' + (typeCreation == 'process' ? 'process' : 'form')),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    }"
                                    :datainput="{
                                        id: typeCreation + '_category',
                                        name: typeCreation + '[category]'
                                    }"
                                    :feed="getFeeds.categories"
                                    :selected_input="ConfigurationFormProcess.data_form_process !== undefined && ConfigurationFormProcess.data_form_process.category !== undefined ? ConfigurationFormProcess.data_form_process.category : []"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('category_' + (typeCreation == 'process' ? 'process' : 'form')) + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationFormProcess.data_form_process !== undefined && ConfigurationFormProcess.data_form_process.category !== undefined ? Object.values(ConfigurationFormProcess.data_form_process.category) : ''}}</label>
                            </template>
                        </div>
                        <div class="section_line" style="margin-bottom: 10px;">
                            <div class="T15_b" style="padding-left:13px;">{{typeCreation === 'process' ? $t('subprocess_and_forms') : $t('sections_and_items')}}</div>
                        </div>

                        <component v-if="actualData.length" v-bind:is="components.partial_section_subprocess" :actualData="{data: actualData, type: typeCreation, refresh: refreshComponent}"/>

                        <div class="centered" style="margin-top: 10px;">
                            <GhButton
                                :datainput="{
                                    id: 'btn_' + (typeCreation === 'process' ? 'add_subprocess' : 'add_section'),
                                    text: typeCreation === 'process' ? $t('add_subprocess') : $t('add_section'),
                                }"
                                @click="getSectionSubprocess(true)"
                            />
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 10px;">
            <template v-if="!this.$root._route.params.view && !this.$root._route.params.validate">
                <GhButton
                    :datainput="{
                        id: 'btn_finish_' + typeCreation,
                        text: $t('finish'),
                    }"
                    @click="setFormProcess('process')"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_save_' + typeCreation,
                        text: $t('save'),
                    }"
                    @click="setFormProcess('save')"
                />
            </template>
            <template v-if="this.$root._route.params.validate">
                <GhButton
                    :datainput="{
                          id: 'btn_validate_' + typeCreation,
                          text: $t('validate'),
                      }"
                    @click="setFormProcess('validate')"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_back_' + typeCreation,
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>

        <component v-bind:is="components.popup_create_section_subprocess" :dataBlock="{type: typeCreation, data: dataPopupSectionSubprocess}" ref="RefPopupCreateSectionSubprocess" @emiter="setSectionSubprocess"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import partial_section_subprocess from "@/view/configuration/form_process/shared/partials/partial_section_subprocess.vue";
    import popup_create_section_subprocess from "@/view/configuration/form_process/shared/popups/popup_create_section_subprocess.vue";

    export default {
        name: 'view_creation',
        components: {
            GhDataSelector,
            GhButton,
            GhInputTextField,
            GhLocation,
            GhNoDropSelector
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationFormProcess'])
        },
        data(){
            return{
                type: '',
                typeCreation: '',
                code: '',
                name: '',
                actualData: [],
                dataPopupSectionSubprocess: [],
                components: {
                    partial_section_subprocess: partial_section_subprocess,
                    popup_create_section_subprocess: popup_create_section_subprocess,
                },
                refreshComponent: 0,
                haveData: false
            }
        },
        async beforeMount(){
          this.type = this.$root._route.name.includes('configuration_process_') ? this.$root._route.name.split('configuration_process_')[1].split('_')[0] : this.$root._route.name.split('configuration_forms_')[1].split('_')[0];
            this.typeCreation = this.$root._route.name.split('configuration_')[1].split('_')[0];

            await this.$store.dispatch('getFeedCategoryProcessOrItem', {type: this.typeCreation}, {root: true});

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getFormProcessData', {id: this.$root._route.params.id, type: this.typeCreation}, {root: true});
            }else{
                this.ConfigurationFormProcess.data_form_process = [];
                this.ConfigurationFormProcess.data = [];
            }

            this.code = this.ConfigurationFormProcess.data_form_process !== undefined ? this.ConfigurationFormProcess.data_form_process.code : '';
            this.name = this.ConfigurationFormProcess.data_form_process !== undefined ? this.ConfigurationFormProcess.data_form_process.name : '';

            this.actualData = this.ConfigurationFormProcess.data;

            this.haveData = true;
        },
        watch: {
            '$store.state.ConfigurationFormProcess.data'(data){
                this.removeSectionSubprocess(data);
            },
            'Global.state.temp_response.response'(data){
                this.code = this.$refs['RefCode'].datainput.value;
                this.name = this.$refs['RefName'].datainput.value;

                var processedData = JSON.parse(localStorage.getItem('tempFloatWindow'));
                localStorage.removeItem('tempFloatWindow');

                var newRow = undefined;

                this.actualData[processedData.data.parentIndex].data.find((item, key) => {
                    if(item.id == data.id){
                        newRow = key;
                    }
                });

                if(newRow === undefined){
                    this.actualData[processedData.data.parentIndex].data.push(data);
                }else {
                    this.actualData[processedData.data.parentIndex].data[newRow] = data;
                }

                this.refreshComponent++
            }
        },
        methods: {
            getSectionSubprocess(create, item, index){
                this.code = this.$refs['RefCode'].datainput.value;
                this.name = this.$refs['RefName'].datainput.value;

                if(create){
                    this.dataPopupSectionSubprocess = [];
                }else{
                    var params = {
                        item: item,
                        index: index
                    }
                    this.dataPopupSectionSubprocess = params;
                }

                this.Global.openPopUp('popup_rename_' + this.typeCreation);
            },
            setSectionSubprocess(){
                if(this.Global.checkRequired('popup_rename_' + this.typeCreation) == 'OK') {
                    var name = this.$refs['RefPopupCreateSectionSubprocess'].$refs['RefNameSectionSubprocess'].datainput.value;

                    if(this.dataPopupSectionSubprocess.index !== undefined) {
                        this.actualData[this.dataPopupSectionSubprocess.index].name = name;
                    }else{
                        var param = {
                            'id': Math.floor(Math.random() * 100000000000),
                            'name': name,
                            'data': []
                        };

                        this.actualData.push(param);
                    }

                    this.Global.closePopUp('popup_rename_' + this.typeCreation);
                }
            },
            async removeSectionSubprocess(data) {
                if(this.$refs['RefCode'] !== undefined) {
                    this.code = this.$refs['RefCode'].datainput.value;
                    this.name = this.$refs['RefName'].datainput.value;
                }
                this.actualData = data;
            },
            async setFormProcess(type){
                if(this.Global.checkRequired('form_creation_' + this.typeCreation) == 'OK'){
                    const formData = new FormData(document.getElementById('form_creation_' + this.typeCreation));
                    await this.$store.dispatch('setDataConfigurationFormProcess', {
                        id: this.$root._route.params.id,
                        type: type,
                        formData: [...formData],
                        typeCreation: this.typeCreation,
                        actualData: this.actualData
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #label_forms_category_selector_label, #label_process_category_selector_label{
        width: 150px;
        text-align: end;
    }
</style>