import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        list_enable_disable_process: [],
    },
    mutations: {
        loadConfigurationEnableDisableProcess(state, payload) {
            state.list_enable_disable_process = payload;
        },
        loadDataConfigurationCreationProcess(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getConfigurationEnableDisableProcess(state){
            await axios.get(
                API_URL + "configuration/process/enable-disable-process"
            ).then(
                response => {
                    state.commit('loadConfigurationEnableDisableProcess', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async copyConfigurationProcess(state, item){
            let requestLogin = API_URL + "configuration/process/enable-disable-process/" + item.id + "/copy";
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationEnableDisableProcess');
                        await state.dispatch('getConfigurationCreationProcess');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async enableDisableConfigurationProcess(state, item){
            let requestLogin = API_URL + "configuration/process/enable-disable-process/" + item.id + "/" + item.type;
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationEnableDisableProcess');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};