import Support from "@/view/support/sections/section_support.vue"
import SupportIncidence from "@/view/support/views/view_incidence.vue"

const routes = [
    {
        path: '/support',
        name: 'support',
        component: Support,
        props: {showMenu: true}
    },
    {
        path: '/support/incidence',
        name: 'support_new_incidence',
        component: SupportIncidence,
        props: {id: null, showMenu: true,  otherTitle: 'incidence'},
        children: [
            {
                path: '/support/incidence/:id',
                name: 'support_edit_incidence',
                component: SupportIncidence,
                props: {id: null, showMenu: true,  otherTitle: 'incidence'},
            },
            {
                path: '/support/incidence/:id/:view',
                name: 'support_view_incidence',
                component: SupportIncidence,
                props: {id: null, showMenu: true, view: true, otherTitle: 'incidence'},
            }
        ],
    },
    {
        path: '/support/incidence_new/:add',
        name: 'new_incidence',
        component: SupportIncidence,
        props: {showMenu: false, background:true, floatWindow:true},
    },
    {
        path: '/support/incidence_new/:add/:origin_id',
        name: 'new_incidence_by_origin',
        component: SupportIncidence,
        props: {showMenu: false, background: true, floatWindow:true},
    },
]

export default routes;