<template>
    <GhInfoBox
        style="margin-top: 6px; margin-right: 3px;"
        :dataBlock="{
            id: 'info_patient_general',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 0, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'row',
        }"
        :dataTitle="{
            show: false,
        }"
        :dataExpansible="{
            show: true,
            animation: true,
            animationSpeed: 1000,
            textExpand: $t('show_more_patient_information'),
            textCollapse: $t('show_less_patient_information'),
            iconExpand: require('../../../../assets/svg/add_plus.svg'),
            iconCollapse: require('../../../../assets/svg/add_less.svg'),
        }"
    >
        <template v-slot:content>
            <div id="info_patient_general_show_now">
                <div style="display: flex;">
                    <div style="width: 40%; position: relative">
                        <div class="gh-row-content-info" style="position: absolute;bottom: 0;">
                            <label class="gh_text_field-label T32B">{{Patients.data.general.name.toUpperCase()}}</label>
                        </div>
                    </div>
                    <div style="width: 60%; text-align: right;">
                        <img class="img-rounded" :src="Patients.data !== undefined && Patients.data.general !== undefined && Patients.data.general.image !== undefined ? Patients.data.general.image : ''" height="180">
                    </div>
                </div>
                <div class="gh-row-content-info" style="margin-top: -20px">
                    <label class="gh_text_field-label T32R">{{Patients.data.general.surname.toUpperCase()}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.gender + ' ' + Patients.data.general.years + ' ' + (Patients.data.general.type_age == 'years' ? $t('y_or_o') : $t('m_or_o'))}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('ohip') + ' #'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.ohip}}</label>
                </div>
            </div>
            <div id="info_patient_general_show_after">
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('date_of_birth') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.date_of_birth}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('driver_license') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.driver_license}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('dependent') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.dependent == 1 ? $t('yes') : $t('no')}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('nationality') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.nationality}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('language_spoken') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.language_spoken}}</label>
                </div>
                <div class="gh-row-content-info">
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('interpret_required') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Patients.data.general.interpret_required == 1 ? $t('no') : $t('yes')}}</label>
                </div>
            </div>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_general",
        components: {
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>