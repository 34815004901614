import CategoryProcess from "@/view/configuration/form_process/process/views/view_category_process.vue";

const routes = [
    {
        path: '/configuration/process/category-process',
        name: 'configuration_process_new_category_process',
        component: CategoryProcess,
        props: {id: null, showMenu: false, background: true, otherTitle: 'category_process'},
        children: [
            {
                path: '/configuration/process/category-process/:id',
                name: 'configuration_process_edit_category_process',
                component: CategoryProcess,
                props: {id: null, showMenu: false, background: true, otherTitle: 'category_process'},
            },
            {
                path: '/configuration/process/category-process/:id/:view',
                name: 'configuration_process_view_category_process',
                component: CategoryProcess,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'category_process'},
            }
        ],
    },

];

export default routes;