<template>
    <div>
        <div v-for="(item, index) in actualData.data" :key="index" style="margin-bottom: 5px;">
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_field_creation_' + actualData.type + '_' + item.id,
                    title: item.name,
                    extraAction: true,
                    style: '',
                    extraSubStyle: 'margin-left: auto !important; margin-right: 0 !important;',
                    contentStyle: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="components.partial_item_forms" :key="actualData.refresh" :actualData="{allData: actualData.data, parentIndex: index, id: item.id, data: item, type: actualData.type}"/>
                </template>
                <template v-slot:actions>
                    <GhAction
                        :dataction="{
                            id: 'action_edit_creation_' + actualData.type + '_' + item.id,
                            text: $t('edit_' + (actualData.type === 'process' ? 'subprocess' : 'section')),
                            icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                        @click="$parent.$parent.getSectionSubprocess(false, item, index)"
                    />
                    <GhAction style="margin-right: 23px;"
                        :dataction="{
                            id: 'action_delete_creation_' + actualData.type + '_' + item.id,
                            text: $t('delete'),
                            icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm','removeSectionSubprocess', item.id, {'name': item.name, 'data': {index: index, data: actualData}}, $t(actualData.type === 'process' ? 'delete_subprocess' : 'delete_section'), $t(actualData.type === 'process' ? 'preparing_delete_subprocess' : 'preparing_delete_section'), 'remove');"
                    />
                </template>
            </GhDropDownField>
        </div>
    </div>
</template>

<script>
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhAction from "fe-gh-action-lib";

    import partial_item_forms from "@/view/configuration/form_process/shared/partials/partial_item_form.vue";

    export default {
        name: 'partial_section_subprocess',
        props: ['actualData'],
        components: {
            GhAction,
            GhDropDownField
        },
        data(){
            return{
                components: {
                    partial_item_forms: partial_item_forms
                }
            }
        }
    }
</script>

<style>
    .definition-field-header{
        width: 100% !important;
    }
    .definition-field_header-text{
        padding-left: 23px;
    }
</style>