import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        list_category_form: [],
    },
    mutations: {
        loadConfigurationCategoryForm(state, payload) {
            state.list_category_form = payload;
        },
        loadDataConfigurationCategoryForm(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getConfigurationCategoryForm(state){
            await axios.get(
                API_URL + "configuration/forms/category-form"
            ).then(
                response => {
                    state.commit('loadConfigurationCategoryForm', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationCategoryForm(state, item){
            await axios.get(
                API_URL + "configuration/forms/category-form/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationCategoryForm', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationCategoryForm(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/forms/category-form/" + item.id : API_URL + "configuration/forms/category-form";
            let formData = new FormData();

            if(item.id !== undefined) {
                formData.append('category_form[id]', item.id);
            }

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        window.opener.UpdatedSelect(Math.random(), item.id !== undefined ? 'edit' : 'add');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationCategoryForm(state, item){
            let requestLogin = API_URL + "configuration/forms/category-form/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationCategoryForm');
                        state.rootState.ConfigurationForms.section_forms_info.category_form.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};