<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_availability',
                    title: $t('availability')
                }"
            >
                <template v-slot:content>
                    <form id="form_availability" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'availability_special_equipment_operation',
                                            class: 'T15_b',
                                            subtitle: $t('select_special_equipment_operation') + ':',
                                            text: $t('special_equipment_operation'),
                                            label: $t('special_equipment_operation'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'availability_special_equipment_operation',
                                            name: 'availability[special_equipment_operation][]'
                                        }"
                                        :feed="getFeeds.equipment"
                                        :selected_input="ConfigurationCalendarAvailability.data !== undefined ? ConfigurationCalendarAvailability.data.special_equipment_operation : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('special_equipment_operation') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationCalendarAvailability.data !== undefined ? Object.values(ConfigurationCalendarAvailability.data.special_equipment_operation).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">&nbsp;</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('monday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('tuesday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('wednesday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('thursday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('friday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('saturday')}}</label></div>
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{$t('sunday')}}</label></div>
                            </div>
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('hour_start')}}</label></div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_monday',
                                                name: 'availability[start][monday]',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.monday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.monday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.monday.hour + ':' + ConfigurationCalendarAvailability.data.start.monday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_tuesday',
                                                name: 'availability[start][tuesday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.tuesday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.tuesday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.tuesday.hour + ':' + ConfigurationCalendarAvailability.data.start.tuesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_wednesday',
                                                name: 'availability[start][wednesday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.wednesday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.wednesday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.wednesday.hour + ':' + ConfigurationCalendarAvailability.data.start.wednesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_thursday',
                                                name: 'availability[start][thursday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.thursday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.thursday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.thursday.hour + ':' + ConfigurationCalendarAvailability.data.start.thursday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_friday',
                                                name: 'availability[start][friday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.friday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.friday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.friday.hour + ':' + ConfigurationCalendarAvailability.data.start.friday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_saturday',
                                                name: 'availability[start][saturday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.saturday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.saturday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.saturday.hour + ':' + ConfigurationCalendarAvailability.data.start.saturday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_start_sunday',
                                                name: 'availability[start][sunday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.sunday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.start !== undefined ? ConfigurationCalendarAvailability.data.start.sunday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.start.sunday.hour + ':' + ConfigurationCalendarAvailability.data.start.sunday.minute}}</label>
                                    </template>
                                </div>
                            </div>
                            <div class="gh-row-content">
                                <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('hour_end') }}</label></div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_monday',
                                                name: 'availability[finish][monday]',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.monday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.monday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.monday.hour + ':' + ConfigurationCalendarAvailability.data.finish.monday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_tuesday',
                                                name: 'availability[finish][tuesday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.tuesday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.tuesday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.tuesday.hour + ':' + ConfigurationCalendarAvailability.data.finish.tuesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_wednesday',
                                                name: 'availability[finish][wednesday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.wednesday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.wednesday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.wednesday.hour + ':' + ConfigurationCalendarAvailability.data.finish.wednesday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_thursday',
                                                name: 'availability[finish][thursday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.thursday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.thursday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.thursday.hour + ':' + ConfigurationCalendarAvailability.data.finish.thursday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_friday',
                                                name: 'availability[finish][friday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.friday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.friday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.friday.hour + ':' + ConfigurationCalendarAvailability.data.finish.friday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_saturdayay',
                                                name: 'availability[finish][saturday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.saturday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.saturday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.saturday.hour + ':' + ConfigurationCalendarAvailability.data.finish.saturday.minute}}</label>
                                    </template>
                                </div>
                                <div>
                                    <template v-if="!$root._route.params.view">
                                        <GhInputHour
                                            :datalabel="{text: ''}"
                                            :datainput="{
                                                id: 'availability_finish_sunday',
                                                name: 'availability[finish][sunday]',
                                                style: 'margin-left: 65px;',
                                                hour: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.sunday.hour : '',
                                                minute: ConfigurationCalendarAvailability.data.finish !== undefined ? ConfigurationCalendarAvailability.data.finish.sunday.minute : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100px; min-width: 100px;">{{ConfigurationCalendarAvailability.data.finish.sunday.hour + ':' + ConfigurationCalendarAvailability.data.finish.sunday.minute}}</label>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('accept')
                    }"
                    type="submit"
                    @click="submitAvailability()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhInputHour from "fe-gh-input-hour-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_availability",
        components: {
            GhNoDropSelector,
            GhLocation,
            GhDataSelector,
            GhInputHour,
            GhButton
        },
        data(){
            return{
                type: '',
                haveData:false,
            }
        },
        computed:{
            ...mapState(['getFeeds', 'ConfigurationCalendarAvailability'])
        },
        methods:{
            async submitAvailability(){
                if(this.Global.checkRequired('form_availability') === 'OK') {
                    const formData = new FormData(document.getElementById('form_availability'));
                    await this.$store.dispatch('setDataConfigurationCalendarAvailability', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);

            await this.$store.dispatch('cleanConfigurationCalendarAvailability', '', {root: true});

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getDataConfigurationCalendarAvailability', {id: this.$root._route.params.id}, {root: true});
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedEquipment', '', {root: true});
            }

            this.type = this.$root._route.name.split('configuration_calendar_')[1].split('_availability')[0];
            this.haveData = true;
        }
    }
</script>

<style>
    #availability_special_equipment_operation_selector_label{
        width: 150px;
        text-align: end;
    }
</style>