<template>
  <div style="margin-top: -4px;">
      <GhLocation
          :dataLocation="{
              showButton: true,
              secondWindow: true,
              showLoader: haveData ? 'OK' : '',
              text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
              img: require('@/assets/gh_new_dessign/floating.svg')
          }"
          :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
          @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
      />

      <div class="content_page">
          <GhNoDropSelector
              :datablock="{
                  id: 'view_specialty',
                  title: $t('specialty')
              }"
          >
              <template v-slot:content>
                  <form id="form_specialty" v-if="haveData">
                      <div class="gh-content-global">
                          <div class="gh-row-content">
                              <template v-if="!$root._route.params.view">
                                  <GhInputTextField
                                      :datalabel="{
                                          text: $t('code') + '*',
                                          style: 'width: 150px; min-width: 150px; text-align: right;',
                                          class: 'T15_b'
                                      }"
                                      :datainput="{
                                          required: true,
                                          id: 'specialty_code',
                                          name: 'specialty[code]',
                                          style: 'width: 200px',
                                          value: ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.code : '',
                                          type: 'text',
                                      }"
                                  />
                              </template>
                              <template v-else>
                                  <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                  <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.code : ''}}</label>
                              </template>
                          </div>
                          <div class="gh-row-content">
                              <template v-if="!$root._route.params.view">
                                  <GhInputTextField
                                      :datalabel="{
                                          text: $t('name') + '*',
                                          style: 'width: 150px; min-width: 150px; text-align: right;',
                                          class: 'T15_b'
                                      }"
                                      :datainput="{
                                          required: true,
                                          id: 'specialty_name',
                                          name: 'specialty[name]',
                                          style: 'min-width: 200px; width: 100%',
                                          value: ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.name : '',
                                          type: 'text',
                                      }"
                                  />
                              </template>
                              <template v-else>
                                  <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                  <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.name : ''}}</label>
                              </template>
                          </div>
                          <div class="gh-row-content">
                              <template v-if="!$root._route.params.view">
                                  <GhTextarea
                                      :datalabel="{
                                          text: $t('description'),
                                          styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                      }"
                                      :datainput="{
                                          id: 'specialty_description',
                                          name: 'specialty[description]',
                                          value: ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.description : ''
                                      }"
                                  />
                              </template>
                              <template v-else>
                                  <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                  <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.description : ''}}</label>
                              </template>
                          </div>
                          <div class="gh-row-content">
                              <template v-if="!$root._route.params.view">
                                  <GhDataSelector
                                      :datablock="{
                                          id: 'specialty_departments',
                                          class: 'T15_b',
                                          subtitle: $t('select_departments') + ':',
                                          text: $t('departments'),
                                          label: $t('departments'),
                                          type: 'checkbox',
                                          columns: 3,
                                          required: false,
                                          style: '',
                                          popupStyle: 'min-width: 900px; width: 55%;',
                                      }"
                                      :datainput="{
                                          id: 'specialty_departments',
                                          name: 'specialty[departments][]'
                                      }"
                                      :feed="getFeeds.departments"
                                      :selected_input="ConfigurationVariableSpecialties.data !== undefined ? ConfigurationVariableSpecialties.data.departments : []"
                                  />
                              </template>
                              <template v-else>
                                  <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('departments') + ':'}}</label>
                                  <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableSpecialties.data !== undefined ? Object.values(ConfigurationVariableSpecialties.data.departments).join(', ') : ''}}</label>
                              </template>
                          </div>
                          <div class="gh-row-content" v-for="(item, index) in employees" :key="index">
                              <template v-if="!$root._route.params.view">
                                  <GhSearchWindow
                                      :dataBlock="{
                                          label: {
                                              style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                          },
                                          ResultSearch: 'result_search_data_groups',
                                          nameStore: 'getFeeds',
                                          imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                          id: item.id,
                                          text: $t(item.id),
                                          textlocation: $t('add') + ' ' + $t(item.id),
                                          actionresult: 'loadResultSearchGroups',
                                          nameResult: 'specialty[' + item.id + ']',
                                          supportPermision: false,
                                      }"
                                      :mainSelector="{
                                          name: 'Formsearchcompany',
                                          formId: 'search',
                                          actionsearch: 'getSearchGroups'
                                      }"
                                      :tableResolt="{
                                          header: header,
                                          data: ConfigurationVariableSpecialties.data !== undefined && ConfigurationVariableSpecialties.data[item.id] !== undefined && ConfigurationVariableSpecialties.data[item.id].summary !== undefined ? ConfigurationVariableSpecialties.data[item.id].summary : []
                                      }"
                                      :tableSelected="{
                                          headersummary: headersummary,
                                          selected: ConfigurationVariableSpecialties.data !== undefined && ConfigurationVariableSpecialties.data[item.id] !== undefined && ConfigurationVariableSpecialties.data[item.id].summary !== undefined ? ConfigurationVariableSpecialties.data[item.id].summary : []
                                      }"
                                  />
                              </template>
                              <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(item.id) + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{summary[item.id]}}</label>
                              </template>
                          </div>
                      </div>
                  </form>
              </template>
          </GhNoDropSelector>
          <div class="centered" style="margin-top: 10px;">
              <GhButton v-if="haveData && !$root._route.params.view"
                  :datainput="{
                      id: 'btn_accept',
                      text: $t('accept'),
                  }"
                  @click="submitSpecialty();"
              />
              <GhButton
                  :datainput="{
                      id: 'btn_close',
                      text: $t('close'),
                  }"
                  @click="Global.windowClose()"
              />
          </div>
      </div>
  </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default{
        name: "view_specialty",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhDataSelector,
            GhSearchWindow,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationVariableSpecialties'])
        },
        data(){
            return {
                type: '',
                haveData: false,
                employees: [
                    {id: 'admin'},
                    {id: 'md'},
                    {id: 'nurses'},
                    {id: 'anesthesiologists'},
                    {id: 'other'},
                ],
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'professional_type', field: 'professional_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'}
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
                summary: {
                    admin: '',
                    md: '',
                    nurses: '',
                    anesthesiologists: '',
                    other: '',
                }
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableSpecialty', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableSpecialty', {id: this.$root._route.params.id}, {root: true});
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedDepartments', '', {root: true});
            }else{
                this.employees.forEach((item) => {
                    if(this.ConfigurationVariableSpecialties.data !== undefined && this.ConfigurationVariableSpecialties.data[item.id] !== undefined && this.ConfigurationVariableSpecialties.data[item.id].summary !== undefined){
                        this.ConfigurationVariableSpecialties.data[item.id].summary.forEach((values) => {
                            this.summary[item.id] += values.name + ' ' + values.surname + ', ';
                        });
                        this.summary[item.id] = this.summary[item.id].substring(0, this.summary[item.id].length - 2);
                    }
                })
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_specialty')[0];
            this.haveData = true;
        },
        methods: {
            async submitSpecialty(){
                if(this.Global.checkRequired('form_specialty') === 'OK'){
                    const formData = new FormData(document.getElementById('form_specialty'));
                    await this.$store.dispatch('setDataConfigurationVariableSpecialty', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                        defaultData: this.ConfigurationVariableSpecialties.data,
                        employees: this.employees
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #specialty_departments_selector_label{
        width: 150px;
        text-align: end;
    }
</style>