<template>
    <GhTableCommon v-if="haveData"
        :extratable="{
            id: 'table_clinics'
        }"
        :header="header"
        :data="Incidence.data.patient_not_presented"
        style="margin-top: 12px;"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_reasign_' + itemProp.id,
                    text: $t('reasign'),
                }"
                @click="reasignPatient(itemProp)"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_patient_not_presented",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['Incidence']),
        },
        data(){
            return {
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, typeSort: 'code', style: 'max-width: 50px; min-width:100px'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 20%;'},
                    {text: 'specialty_required', field: 'specialty_required', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 35%;'},
                    {text: 'visit_hour', field: 'visit_hour', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 50px;'},
                    {text: 'visit_date', field: 'visit_date', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 50px;'},
                    {text: '', field: 'actions', sorting: false, style: 'min-width: 60px;'}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getIncidenceData', {type: 'patient-not-presented'}, {root: true});
            this.haveData = true;
        },
        methods: {
            reasignPatient(item){
                this.$parent.$parent.reasign_id = item.id;
                this.$parent.$parent.reasign_name = item.name;
                this.$parent.$parent.reasign_surname = item.surname;
                this.$parent.$parent.reasign_type = 2;
                this.Global.openPopUp('reasign_confirm');
            }
        }
    }
</script>