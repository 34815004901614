import Vue from "vue";
import vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

//LOGIN
import Login from './login/Login';
import Environment from "@/store/environment/environment";
import Tabs from './tabs/tabs';
import UploadFiles from './attach/upload';
import Register from "./register/register";
import Submits from "@/store/submits/submits";
import SubmitsClinics from "@/store/submits/clinics/clinics";
import SubmitsProfessionals from "@/store/submits/professionals/professionals";
import Validations from "@/store/validations/validations";
import ValidationsProfessionals from "@/store/validations/professionals/professionals";
import Configuration from "@/store/configuration/configuration";
import ConfigurationEquipments from "@/store/configuration/equipments/equipments";
import ConfigurationEquipmentsEquipments from "@/store/configuration/equipments/equipments/equipments";
import ConfigurationVariable from "@/store/configuration/variable/variable";
import ConfigurationVariableDepartments from "@/store/configuration/variable/departments/departments";
import ConfigurationVariableSpecialties from "@/store/configuration/variable/specialty/specialty";
import ConfigurationVariableEquipments from "@/store/configuration/variable/equipments/equipments";
import ConfigurationVariableEquipmentMaintenance from "@/store/configuration/variable/equipment_maintenance/equipment_maintenance";
import ConfigurationVariableEquipmentType from "@/store/configuration/variable/type_equipment/type_equipment";
import ConfigurationVariableProfessionalTypes from "@/store/configuration/variable/professional_types/professional_types";
import ConfigurationProfessionals from "@/store/configuration/professionals/professionals";
import ConfigurationProfessionalsActive from "@/store/configuration/professionals/active/active";
import ConfigurationProfessionalsPending from "@/store/configuration/professionals/pending/pending";
import ConfigurationProfessionalsNotAvailable from "@/store/configuration/professionals/not_available/not_available";
import ConfigurationProfessionalsPast from "@/store/configuration/professionals/past/past";
import ConfigurationCalendar from "@/store/configuration/calendar/calendar";
import ConfigurationCalendarOpen from "@/store/configuration/calendar/open/open";
import ConfigurationCalendarAvailability from "@/store/configuration/calendar/availability/availability";
import ConfigurationConsentForms from "@/store/configuration/consent_forms/consent_forms";
import ConfigurationConsentFormsActive from "@/store/configuration/consent_forms/active/active";
import ConfigurationConsentFormsPending from "@/store/configuration/consent_forms/pending/pending";
import ConfigurationConsentFormsDeleted from "@/store/configuration/consent_forms/deleted/deleted";
import ConfigurationClinicData from "@/store/configuration/clinic_data/clinic_data";
import ConfigurationGeneralData from "@/store/configuration/general_data/general_data";
import ConfigurationGeneralDataRelationship from "@/store/configuration/general_data/relationship/relationship";
import ConfigurationGeneralDataNationality from "@/store/configuration/general_data/nationality/nationality";
import ConfigurationGeneralDataLanguage from "@/store/configuration/general_data/languages/languages";
import ConfigurationGeneralDataGender from "@/store/configuration/general_data/gender/gender";
import ConfigurationPriority from "@/store/configuration/priorities/priorities";
import ConfigurationPriorityConditions from "@/store/configuration/priorities/conditions/conditions";
import ConfigurationPriorityPriorities from "@/store/configuration/priorities/priorities/priorities";
import ConfigurationItems from "@/store/configuration/items/items";
import ConfigurationItemsStandardItems from "@/store/configuration/items/standard_items/standard_items";
import ConfigurationItemsExaminationItems from "@/store/configuration/items/examination_items/examination_items";
import ConfigurationForms from "@/store/configuration/form_process/forms/forms";
import ConfigurationFormsCategory from "@/store/configuration/form_process/forms/category_form/category_form";
import ConfigurationFormsCreation from "@/store/configuration/form_process/forms/creation_form/creation_form";
import ConfigurationFormsEnableDisable from "@/store/configuration/form_process/forms/enable_disable_form/enable_disable_form";
import ConfigurationFormsValidatePropagate from "@/store/configuration/form_process/forms/validate_propagate_form/validate_propagate_form";
import ConfigurationProcess from "@/store/configuration/form_process/process/process";
import ConfigurationProcessCategory from "@/store/configuration/form_process/process/category_process/category_process";
import ConfigurationProcessCreation from "@/store/configuration/form_process/process/creation_process/creation_process";
import ConfigurationProcessEnableDisable from "@/store/configuration/form_process/process/enable_disable_process/enable_disable_process";
import ConfigurationProcessValidatePropagate from "@/store/configuration/form_process/process/validate_propagate_process/validate_propagate_process";
import ConfigurationFormProcess from "@/store/configuration/form_process/shared/form_process";
import ConfigurationSupport from "@/store/configuration/support/support";
import ConfigurationSupportCategory from "@/store/configuration/support/category/category";
import ConfigurationSupportPriority from "@/store/configuration/support/priority/priority";
import ConfigurationSupportAssignment from "@/store/configuration/support/assignment/assignment";
import ConfigurationSupportSchedule from "@/store/configuration/support/schedule/schedule";
import ConfigurationSupportHoliday from "@/store/configuration/support/holiday/holiday";
import ConfigurationSupportFlow from "@/store/configuration/support/flow/flow";
import Incidence from "@/store/incidences/incidence";
import Calendars from '@/store/calendar/calendars';
import CalendarDepartments from '@/store/calendar/department/department';
import CalendarDataDepartment from '@/store/calendar/department/views/department_data';
import CalendarSpecialities from '@/store/calendar/speciality/speciality';
import CalendarDataSpeciality from '@/store/calendar/speciality/views/speciality_data';
import CalendarProfessionals from '@/store/calendar/professional/professional';
import CalendarDataProfessional from '@/store/calendar/professional/views/professional_data';
import CalendarGroups from '@/store/calendar/group/group';
import CalendarDataGroup from '@/store/calendar/group/views/group_data';
import Patients from "@/store/patients/patients";
import Visits from "@/store/visits/visits";
import VisitsUpcomingPatients from "@/store/visits/upcoming_patients/upcoming_patients";
import VisitsFollowUpVisits from "@/store/visits/follow_up_visits/follow_up_visits";
import VisitsPendings from "@/store/visits/pending/pending";
import VisitsPotentialVisit from "@/store/visits/potential_visits/potential_visits";
import VisitsUpcomingPatientsBySpecialtyAdmin from "@/store/visits/upcoming_patients_by_specialty_admin/upcoming_patients_by_specialty_admin";
import VisitsUpcomingPatientsByProfessional from "@/store/visits/upcoming_patients_by_professional/upcoming_patients_by_professional";
import VisitsCallToRemindAndConfirm from "@/store/visits/call_to_remind_and_confirm/call_to_remind_and_confirm";
import Warnings from "@/store/warnings/warnings";
import WarningsPreOps from "@/store/warnings/pre_ops/pre_ops";
import WarningsSurgery from "@/store/warnings/surgery/surgery";
import WarningsAnesthesia from "@/store/warnings/anesthesia/anesthesia";
import WarningsMissing from "@/store/warnings/missing/missing";
import WarningsConsentForms from "@/store/warnings/consent_forms/consent_forms";
import Surgery from "@/store/surgery/surgery";
import SurgeryData from "@/store/surgery/surgery/surgery";
import Support from "@/store/support/support";
import SupportPendingRequestsGroups from "@/store/support/pending_request_group/pending_request_group";
import SupportPendingRequests from "@/store/support/pending_request/pending_request";
import SupportIndicators from "@/store/support/indicators/indicators";
import SupportHistoric from "@/store/support/historic/historic";
import Permissions from "@/store/permissions/permissions";
import PermissionsGroups from "@/store/permissions//groups/groups";
import PermissionsProfiles from "@/store/permissions/profiles/profiles";
import PermissionsClinics from "@/store/permissions/clinics/clinics";
import PermissionsAssociatedUsers from "@/store/permissions/associated_users/associated_users";
import Process from "@/store/process/process";

import MyFile from '@/store/my_file/my_file';
import MyFileClinics from '@/store/my_file/clinics/clinics';

import GlobalStore from "@/store/Global";
import DataClinicsProfessionals from "@/store/clinics_professionals/clinics_professionals";
import getFeeds from './feeds/feeds';


Vue.use(vuex);

export default new vuex.Store({
    modules: {
        Login: Login,
        Environment: Environment,
        Tabs: Tabs,
        UploadFiles: UploadFiles,
        Register: Register,
        Submits: Submits,
        SubmitsClinics: SubmitsClinics,
        SubmitsProfessionals: SubmitsProfessionals,
        Validations: Validations,
        ValidationsProfessionals: ValidationsProfessionals,
        Configuration: Configuration,
        ConfigurationEquipments: ConfigurationEquipments,
        ConfigurationEquipmentsEquipments: ConfigurationEquipmentsEquipments,
        ConfigurationVariable: ConfigurationVariable,
        ConfigurationVariableDepartments: ConfigurationVariableDepartments,
        ConfigurationVariableSpecialties: ConfigurationVariableSpecialties,
        ConfigurationVariableEquipments: ConfigurationVariableEquipments,
        ConfigurationVariableEquipmentMaintenance: ConfigurationVariableEquipmentMaintenance,
        ConfigurationVariableEquipmentType: ConfigurationVariableEquipmentType,
        ConfigurationVariableProfessionalTypes: ConfigurationVariableProfessionalTypes,
        ConfigurationProfessionals: ConfigurationProfessionals,
        ConfigurationProfessionalsActive: ConfigurationProfessionalsActive,
        ConfigurationProfessionalsPending: ConfigurationProfessionalsPending,
        ConfigurationProfessionalsNotAvailable: ConfigurationProfessionalsNotAvailable,
        ConfigurationProfessionalsPast: ConfigurationProfessionalsPast,
        ConfigurationCalendar: ConfigurationCalendar,
        ConfigurationCalendarOpen: ConfigurationCalendarOpen,
        ConfigurationCalendarAvailability: ConfigurationCalendarAvailability,
        ConfigurationConsentForms: ConfigurationConsentForms,
        ConfigurationConsentFormsActive: ConfigurationConsentFormsActive,
        ConfigurationConsentFormsPending: ConfigurationConsentFormsPending,
        ConfigurationConsentFormsDeleted: ConfigurationConsentFormsDeleted,
        ConfigurationClinicData: ConfigurationClinicData,
        ConfigurationGeneralData: ConfigurationGeneralData,
        ConfigurationGeneralDataRelationship: ConfigurationGeneralDataRelationship,
        ConfigurationGeneralDataNationality: ConfigurationGeneralDataNationality,
        ConfigurationGeneralDataLanguage: ConfigurationGeneralDataLanguage,
        ConfigurationGeneralDataGender: ConfigurationGeneralDataGender,
        ConfigurationPriority: ConfigurationPriority,
        ConfigurationPriorityConditions: ConfigurationPriorityConditions,
        ConfigurationPriorityPriorities: ConfigurationPriorityPriorities,
        ConfigurationItems: ConfigurationItems,
        ConfigurationItemsStandardItems: ConfigurationItemsStandardItems,
        ConfigurationItemsExaminationItems: ConfigurationItemsExaminationItems,
        ConfigurationForms: ConfigurationForms,
        ConfigurationFormsCategory: ConfigurationFormsCategory,
        ConfigurationFormsCreation: ConfigurationFormsCreation,
        ConfigurationFormsEnableDisable: ConfigurationFormsEnableDisable,
        ConfigurationFormsValidatePropagate: ConfigurationFormsValidatePropagate,
        ConfigurationProcess: ConfigurationProcess,
        ConfigurationProcessCategory: ConfigurationProcessCategory,
        ConfigurationProcessCreation: ConfigurationProcessCreation,
        ConfigurationProcessEnableDisable: ConfigurationProcessEnableDisable,
        ConfigurationProcessValidatePropagate: ConfigurationProcessValidatePropagate,
        ConfigurationFormProcess: ConfigurationFormProcess,
        ConfigurationSupport: ConfigurationSupport,
        ConfigurationSupportCategory: ConfigurationSupportCategory,
        ConfigurationSupportPriority: ConfigurationSupportPriority,
        ConfigurationSupportAssignment: ConfigurationSupportAssignment,
        ConfigurationSupportSchedule: ConfigurationSupportSchedule,
        ConfigurationSupportHoliday: ConfigurationSupportHoliday,
        ConfigurationSupportFlow: ConfigurationSupportFlow,
        //INCIDENCE
        Incidence: Incidence,
        //CALENDAR
        Calendars: Calendars,
        CalendarDepartments: CalendarDepartments,
        CalendarDataDepartment: CalendarDataDepartment,
        CalendarSpecialities: CalendarSpecialities,
        CalendarDataSpeciality: CalendarDataSpeciality,
        CalendarProfessionals: CalendarProfessionals,
        CalendarDataProfessional: CalendarDataProfessional,
        CalendarGroups: CalendarGroups,
        CalendarDataGroup: CalendarDataGroup,
        //PATIENTS
        Patients: Patients,
        //VISITS
        Visits: Visits,
        VisitsUpcomingPatients: VisitsUpcomingPatients,
        VisitsFollowUpVisits: VisitsFollowUpVisits,
        VisitsPendings: VisitsPendings,
        VisitsPotentialVisit: VisitsPotentialVisit,
        VisitsUpcomingPatientsBySpecialtyAdmin: VisitsUpcomingPatientsBySpecialtyAdmin,
        VisitsCallToRemindAndConfirm: VisitsCallToRemindAndConfirm,
        VisitsUpcomingPatientsByProfessional: VisitsUpcomingPatientsByProfessional,
        //WARNINGS
        Warnings: Warnings,
        WarningsPreOps: WarningsPreOps,
        WarningsSurgery: WarningsSurgery,
        WarningsAnesthesia: WarningsAnesthesia,
        WarningsMissing: WarningsMissing,
        WarningsConsentForms: WarningsConsentForms,
        //SURGERY
        Surgery: Surgery,
        SurgeryData: SurgeryData,
        //SUPPORT
        Support: Support,
        SupportPendingRequestsGroups: SupportPendingRequestsGroups,
        SupportPendingRequests: SupportPendingRequests,
        SupportIndicators: SupportIndicators,
        SupportHistoric: SupportHistoric,
        //PERMISSIONS
        Permissions: Permissions,
        PermissionsGroups: PermissionsGroups,
        PermissionsProfiles: PermissionsProfiles,
        PermissionsClinics: PermissionsClinics,
        PermissionsAssociatedUsers: PermissionsAssociatedUsers,
        //PROCESS
        Process: Process,
        //MY FILE
        MyFile: MyFile,
        MyFileClinics: MyFileClinics,
        //GLOBAL STORE'S
        GlobalStore: GlobalStore,
        //SPECIFIC DATA'S
        DataClinicsProfessionals: DataClinicsProfessionals,
        //FEEDS
        getFeeds: getFeeds,
    },
    plugins: [createPersistedState()]
});

