<template>
    <div id="option_to_select_dosage" v-if="haveData">
        <div class="gh-row-content">
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('recurrence')}}</label>
            <GhChecks
                :datalabel="{
                    text: $t('continous')
                }"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'radio',
                    id: $parent.$parent.type_item + '_dosage_recurrence_1',
                    value: 1,
                    name: $parent.$parent.type_item + '[dosage][recurrence]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.recurrence !== undefined && $parent.$parent.stateData.data.dosage.recurrence === 1 ? true : '',
                }"
                ref="RefRecurrence1"
                @click="checkContinous('RefRecurrence1')"
            />
            <GhChecks
                :datalabel="{
                    text: $t('intermittent')
                }"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'radio',
                    id: $parent.$parent.type_item + '_dosage_recurrence_2',
                    value: 2,
                    name: $parent.$parent.type_item + '[dosage][recurrence]',
                    style: 'margin-left: 20px;',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.recurrence !== undefined && $parent.$parent.stateData.data.dosage.recurrence === 2 ? true : '',
                }"
                ref="RefRecurrence2"
                @click="checkContinous('RefRecurrence2')"
            />
        </div>
        <div class="gh-row-content" style="height: 18px;" v-if="showPeriods">
            <div>
                <div style="width: 40%" :style="!$root._route.params.view ? '' : 'display: flex;'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_' + $parent.$parent.type_item + '_dosage_period_every',
                                class: 'T15_b',
                                subtitle: $t('select_period') + ':',
                                text: $t('period_every'),
                                label: $t('period_every'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: $parent.$parent.type_item + '_dosage_period_every',
                                name: $parent.$parent.type_item + '[dosage][period_every]'
                            }"
                            :feed="getFeeds.periods"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.period_every !== undefined ? $parent.$parent.stateData.data.dosage.period_every : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('period_every') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.period_every !== undefined ? Object.values($parent.$parent.stateData.data.dosage.period_every).join() : ''}}</label>
                    </template>
                </div>
                <div class="no-min-width-children">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_' + $parent.$parent.type_item + '_dosage_period_lasting',
                                class: 'T15_b',
                                subtitle: $t('select_period') + ':',
                                text: $t('period_lasting'),
                                label: $t('period_lasting'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: $parent.$parent.type_item + '_dosage_period_lasting',
                                name: $parent.$parent.type_item + '[dosage][period_lasting]'
                            }"
                            :feed="getFeeds.periods"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.period_lasting !== undefined ? $parent.$parent.stateData.data.dosage.period_lasting : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('period_lasting') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.period_lasting !== undefined ? Object.values($parent.$parent.stateData.data.dosage.period_lasting).join() : ''}}</label>
                    </template>
                </div>
            </div>
        </div>
        <div class="gh-row-content" style="height: 18px;">
            <div>
                <div style="width: 40%" :style="!$root._route.params.view ? '' : 'display: flex;'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_standard_item_dosage_quantities',
                                class: 'T15_b',
                                subtitle: $t('select_quantity') + ':',
                                text: $t('quantity'),
                                label: $t('quantity'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: $parent.$parent.type_item + '_dosage_quantities',
                                name: $parent.$parent.type_item + '[dosage][quantities]'
                            }"
                            :feed="getFeeds.quantities"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.quantity !== undefined ? $parent.$parent.stateData.data.dosage.quantity : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('quantity') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.quantity !== undefined ? Object.values($parent.$parent.stateData.data.dosage.quantity).join() : ''}}</label>
                    </template>
                </div>
                <div style="width: 40%">
                    <template v-if="!$root._route.params.view">
                        <GhTreeSelectorBlock
                            :dataBlock="{
                                type: 'radio',
                                id: $parent.$parent.type_item + '_dosage_item',
                                label: $t('item'),
                                title: $t('item'),
                                text: $t('select_item') + ':',
                                name: $parent.$parent.type_item + '[dosage][item]',
                                id_value: 'value',
                                name_label: 'text',
                                child_key: 'children',
                                required: false,
                                checkparentdata: false,
                                style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.item !== undefined ? $parent.$parent.stateData.data.dosage.item : []"
                            :items="getFeeds.items"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.item !== undefined ? $parent.$parent.stateData.data.dosage.item[0].text : ''}}</label>
                    </template>
                </div>
            </div>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_dosage_associate_to',
                    value: 1,
                    name: $parent.$parent.type_item + '[dosage][associate_to]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.dosage !== undefined && $parent.$parent.stateData.data.dosage.associate_to !== undefined ? $parent.$parent.stateData.data.dosage.associate_to : '',
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: 'view_dosage',
        components: {
            GhTreeSelectorBlock,
            GhDataSelector,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false,
                showPeriods: false
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.dosage !== undefined && this.$parent.$parent.stateData.data.dosage.recurrence !== undefined && this.$parent.$parent.stateData.data.dosage.recurrence === 2){
                this.showPeriods = true;
            }
            await this.$store.dispatch('getFeedPeriods', '', {root: true});
            await this.$store.dispatch('getFeedQuantities', '', {root: true});
            this.haveData = true;
        },
        methods:{
            checkContinous(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }

                this.showPeriods = this.$refs[value].datainput.value === 2 ? true : false;
            }
        }
    }
</script>

<style>
    .no-min-width-children > div{
        min-width: auto !important;
    }

    #label_standard_item_dosage_period_every_selector_label, #label_standard_item_dosage_quantity_selector_label, #label_standard_item_dosage_quantities_selector_label,#label_examination_item_dosage_period_every_selector_label, #label_examination_item_dosage_quantity_selector_label, #label_examination_item_dosage_quantities_selector_label {
        width: 150px;
        text-align: end;
    }
</style>