import SectionProcess from "@/view/configuration/form_process/process/section/section_process.vue";
import CategortProcess from "./category_process/route";
import CreationProcess from './creation_process/route';

const route = [
    {
        path: '/configuration/process',
        name: 'configuration_process',
        component: SectionProcess,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...CategortProcess,
    ...CreationProcess
]

export default routes;