<template>
    <div>
        <template v-if="$parent.$parent.$parent.showCalendar">
            <div class="gh-content-global">
                <GhScheduleCalendar
                    :dataBlock="{
                        id: 'schedule_professional_' + this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1],
                        backgroundColorNumber: '#ffffff',
                        widthHour: 30,
                        colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'}
                    }"
                    :data="VisitsUpcomingPatientsByProfessional.records_data"
                    @days="toDays"
                    ref="RefSchedule"
                />
            </div>
        </template>
        <template v-else>
            <component :is="componentDay"/>
        </template>
    </div>
</template>

<script>
    import GhScheduleCalendar from "fe-gh-schedule-calendar-lib";

    import {mapState} from "vuex";

    import day from "@/view/visits/views/view_today_upcoming_patient_by_professional.vue";

    export default {
        name: "view_week_month_upcoming_patient_by_professional",
        components: {
            GhScheduleCalendar
        },
        computed: {
            ...mapState(['VisitsUpcomingPatientsByProfessional'])
        },
        data(){
            return {
                componentDay: day,
                refreshKey: 0
            }
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        mounted() {
            if(this.VisitsUpcomingPatientsByProfessional.records_data.am !== undefined){
                this.$parent.$parent.$parent.showCalendar = true;
            }

            this.$parent.$parent.$parent.upcomingDataSelected = '';
            var that = this;
            setTimeout(function () {
                that.resizeSchedule();
            }, 1);
            this.refreshKey++;
        },
        updated() {
            this.resizeSchedule();
        },
        methods: {
            myEventHandler(e) {
                if(e.type == 'resize' && this.$refs.RefSchedule !== undefined){
                    this.resizeSchedule();
                }
            },
            async resizeSchedule(){
                if(this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1] !== 'today' && this.$parent.$parent.$parent.upcomingDataSelected == '') {
                    var fullWidth = this.$refs.RefSchedule.$el.clientWidth - 250;

                    var newWidth = fullWidth / this.$refs.RefSchedule.$el.children[0].children[1].children.length;

                    this.widthHour = newWidth - 8;

                    this.$refs.RefSchedule.$el.children[0].children[0].style.minWidth = '150px';
                    this.$refs.RefSchedule.$el.children[0].children[0].style.paddingRight = '7px';
                    this.$refs.RefSchedule.$el.children[0].children[0].style.maxWidth = '150px';
                    this.$refs.RefSchedule.$el.children[0].children[2].style.minWidth = '100px';
                    this.$refs.RefSchedule.$el.children[0].children[2].style.maxWidth = '100px';
                    this.$refs.RefSchedule.$el.children[1].children[0].style.minWidth = '156px';
                    this.$refs.RefSchedule.$el.children[1].children[0].style.maxWidth = '156px';
                    this.$refs.RefSchedule.$el.children[1].children[2].style.minWidth = '106px';
                    this.$refs.RefSchedule.$el.children[1].children[2].style.maxWidth = '106px';
                    this.$refs.RefSchedule.$el.children[2].children[0].style.minWidth = '156px';
                    this.$refs.RefSchedule.$el.children[2].children[0].style.maxWidth = '156px';
                    this.$refs.RefSchedule.$el.children[2].children[1].style.minWidth = fullWidth + 'px';
                    this.$refs.RefSchedule.$el.children[2].children[1].style.maxWidth = fullWidth + 'px';
                    this.$refs.RefSchedule.$el.children[3].children[0].style.minWidth = '150px';
                    this.$refs.RefSchedule.$el.children[3].children[0].style.paddingRight = '7px';
                    this.$refs.RefSchedule.$el.children[3].children[0].style.maxWidth = '150px';
                    this.$refs.RefSchedule.$el.children[3].children[2].style.minWidth = '100px';
                    this.$refs.RefSchedule.$el.children[3].children[2].style.maxWidth = '100px';

                    for (var i = 0; i < this.$refs.RefSchedule.$el.children[0].children[1].children.length; i++) {
                        for (var x = 0; x < this.$refs.RefSchedule.$el.children[0].children[1].children[i].children.length; x++) {
                            this.$refs.RefSchedule.$el.children[0].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                            this.$refs.RefSchedule.$el.children[0].children[1].children[i].style.paddingRight = 3 + 'px';
                            this.$refs.RefSchedule.$el.children[3].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                            this.$refs.RefSchedule.$el.children[3].children[1].children[i].style.paddingRight = 3 + 'px';
                            this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.width = (newWidth - 8) + 'px';
                            this.$refs.RefSchedule.$el.children[1].children[1].children[i].style.paddingRight = 3 + 'px';
                            this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].setAttribute('style', this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.cssText + 'font-size: 11px !important');
                        }
                    }
                }
            },
            async toDays(item){
                console.log(item);
                this.$parent.$parent.$parent.upcomingDataSelected = item.day + '/' + item.month + '/' + item.year;

                await this.$store.dispatch('getVisitsUpcomingPatientsByProfessionalList', {type: this.$parent.$parent.$parent.upcomingDataSelected.replaceAll('/','-')}, {root: true});

                this.$parent.$parent.$parent.showCalendar = false;
            }
        }
    }
</script>