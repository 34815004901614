<template>
    <div style="display: flex;" :style="$parent.checkedNA ? '' : 'margin-bottom: 10px;'">
        <div style="width: 60% !important; display: flex; z-index: 1; position: relative;">
            <label :id="'label_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_item'" class="gh_text_field-label T15_b" :class="dataItem.item.config.label.class" style="margin-right: 10px;" :style="dataItem.item.config.label.style">{{dataItem.item.config.label.text}}</label>
            <GhChecks v-if="dataItem.item.config.na"
                :datalabel="{text: $t('na'), style: '', class: '', id: ''}"
                :datainput="{
                    disabled: false,
                    required: false,
                    selected: dataItem.item.data !== undefined ? dataItem.item.data.na : false,
                    type: 'checkbox',
                    id: 'check_' + dataItem.item.config[dataItem.type === 1 ? 'textarea' : dataItem.type === 6 || dataItem.type === 7 ? 'input' : dataItem.type === 8 ? 'selector' : dataItem.type === 9 ? 'table' : dataItem.type === 10 ? 'imported' : dataItem.type == 11 ? 'graphic' : dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_na',
                    value: 1,
                    name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][na]'
                }"
                @click="$parent.$parent.setChangesSection(subprocessId, $parent.$parent.dataSubprocess.id);$parent.$parent.setNA($children)"
                :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'NA'"
            />
        </div>
        <div style="width: 40% !important; text-align: right; display: flex; margin-left: -5px;">
            <label v-if="dataItem.item.config.critical" :id="'label_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem + '_critical'" class="gh_text_field-label T15_b" style="color: red; width: 100%">{{$t('item_was_identified_as_critical') + '!'}}</label>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'view_label',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhChecks
        }
    }
</script>