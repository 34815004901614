<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhNoDropSelector :datablock="{id: 'list_open',title: $t('open')}">
            <template v-slot:content>
                <component v-bind:is="view_open" :dataBlock="{type: view}" :key="refreshKey"/>
            </template>
            <template v-slot:actions v-if="view">
                <GhAction
                    :dataction="{
                        id: 'modify_business',
                        text: $t('edit'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="changeState"
                />
            </template>
        </GhNoDropSelector>

        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationCalendar.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_calendar_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationCalendar.section_info[label['type']]['second_text']),
                    num_results: ConfigurationCalendar.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import view_open from "@/view/configuration/calendar/views/view_open.vue";
    import list_availability from "@/view/configuration/calendar/lists/list_availability.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_calendar",
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['ConfigurationCalendar']),
        },
        data(){
            return {
                view: true,
                haveData: false,
                data: [
                    {id: 1, name: 'availability', component: list_availability, type: 'availability'},
                ],
                view_open: view_open,
                refreshKey: 0
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationCalendarSections','',{root:true});
            this.haveData = true;
        },
        methods: {
            changeState(){
                this.view = !this.view;
            },
        }
    }
</script>
