import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationGeneralDataLanguages(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationGeneralDataLanguages(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationGeneralDataLanguages(state){
            await axios.get(
                API_URL + "languages"
            ).then(
                response => {
                    state.commit('loadTableConfigurationGeneralDataLanguages', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationGeneralDataLanguage(state, item){
            await axios.get(
                API_URL + "languages/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationGeneralDataLanguages', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationGeneralDataLanguage(state, item){
            let request = item.id !== undefined ? API_URL + "languages/" + item.id : API_URL + "languages";
            let formData = new FormData();

            formData.append('language[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.data.status == '200') {
                        window.opener.UpdatedSelect(Math.random(), item.id !== undefined ? 'edit' : 'add');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationGeneralDataLanguage(state, item){
            let requestLogin = API_URL + "languages/" + item.id ;

            await axios({
                method: "delete",
                url: requestLogin,
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationGeneralDataLanguages');
                        state.rootState.ConfigurationGeneralData.section_info.languages.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};