import Relationship from "@/view/configuration/general_data/views/view_relationship.vue";

const routes = [
    {
        path: '/configuration/general-data/relationship',
        name: 'configuration_general_data_new_relationship',
        component: Relationship,
        props: {id: null, showMenu: false, background: true, otherTitle: 'relationship'},
        children: [
            {
                path: '/configuration/general-data/relationship/:id',
                name: 'configuration_general_data_edit_relationship',
                component: Relationship,
                props: {id: null, showMenu: false, background: true, otherTitle: 'relationship'},
            },
            {
                path: '/configuration/general-data/relationship/:id/:view',
                name: 'configuration_general_data_view_relationship',
                component: Relationship,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'relationship'},
            }
        ],
    },

];

export default routes;