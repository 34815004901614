var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},[_vm._l((_vm.ConfigurationProcessCreation.list_creation_process),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_field_creation_process_' + item.id,
                title: item.name,
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                        id: 'table_creation_process',
                    },"header":_vm.header,"data":item.data === undefined ? [] : item.data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'configuration_process_edit_creation_process', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                    id: 'action_edit_creation_process_' + itemProp.id,
                                    text: _vm.$t('edit'),
                                    icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                }}})],1),_c('GhAction',{attrs:{"dataction":{
                                id: 'action_delete_creation_process_' + itemProp.id,
                                text: _vm.$t('delete'),
                                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationCreationProcess', itemProp.id,{ 'process': itemProp.process}, _vm.$t('delete_process'), _vm.$t('preparing_delete_process'), 'remove')}}})]}}],null,true)})]},proxy:true}],null,true)})],1)}),_c('div',{staticClass:"centered",staticStyle:{"padding-top":"5px","margin-bottom":"-5px !important"}},[_c('router-link',{attrs:{"to":{name: 'configuration_process_new_creation_process'}}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_category_process',
                    text: _vm.$t('add')
                }}})],1)],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }