<template>
    <div class="float_window" v-if="haveData" style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Permissions.section_info[label['type']].show"
                :datablock="{
                    id: 'permissions_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(Permissions.section_info[label['type']]['second_text']),
                    num_results: Permissions.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhDropSelector from "fe-gh-dropselector-lib";

    import list_groups from "@/view/permissions/lists/list_groups.vue";
    import list_profiles from "@/view/permissions/lists/list_profiles.vue";
    import list_clinics from "@/view/permissions/lists/list_clinics.vue";
    import list_associated_user from "@/view/permissions/lists/list_associated_user.vue";

    export default {
        name: 'section_permission',
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['Permissions']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'groups', component: list_groups, type: 'groups'},
                    {id: 2, name: 'profiles', component: list_profiles, type: 'profiles'},
                    {id: 3, name: 'clinics', component: list_clinics, type: 'clinics'},
                    {id: 4, name: 'associated_users', component: list_associated_user, type: 'associated_users'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionsSections','',{root:true});
            this.haveData = true;
        }
    }
</script>