var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'upcoming_patients_',
    },"header":_vm.header,"data":_vm.VisitsUpcomingPatients.records_data.length == 0 ? [] : _vm.VisitsUpcomingPatients.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_reschedule_upcoming_patients_' + itemProp.id,
                text: _vm.$t('reschedule'),
                icon: require('../../../assets/svg/calendar2.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('edit_visit_schedule', {id: itemProp.id, type: 'reschedule'}),'schedule')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_arrived_upcoming_patients_' + itemProp.id,
                text: _vm.$t('arrived'),
                icon: require('../../../assets/svg/tick.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','setArrivedUpcomingPatient', itemProp.id,{'ohip': itemProp.ohip, 'name': itemProp.name, 'surname': itemProp.surname, 'md': itemProp.md, 'date': itemProp.date, 'time': itemProp.time}, _vm.$t('arrived_patients'), _vm.$t('preparing_arrived_patients'), 'arrived')}}})]}}],null,false,3114068072)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }