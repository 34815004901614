var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{attrs:{"id":"option_to_select_table"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefImportTableSelected",attrs:{"datablock":{
                    id: 'label_' + _vm.$parent.$parent.type_item + '_table_selected',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_table') + ':',
                    text: _vm.$t('load_table') + '*',
                    label: _vm.$t('load_table'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;',
                },"datainput":{
                    id: _vm.$parent.$parent.type_item + '_import_selected',
                    name: _vm.$parent.$parent.type_item + '[import][selected]'
                },"feed":_vm.getFeeds.tables.feeds,"selected_input":_vm.table},on:{"accept":_vm.getSelectedImportTable}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('load_table') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.table !== [] ? Object.values(_vm.table).join() : ''))])]],2),(_vm.showStructure)?_c('div',{key:'A' + _vm.structureRefresh,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefImportTableStructure",attrs:{"datablock":{
                    id: 'label_' + _vm.$parent.$parent.type_item + '_structure_selected',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_columns') + ':',
                    text: _vm.$t('load_columns') + '*',
                    label: _vm.$t('load_columns'),
                    type: 'checkbox',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;',
                },"datainput":{
                    id: _vm.$parent.$parent.type_item + '_structure_selected',
                    name: _vm.$parent.$parent.type_item + '[import][structure][]'
                },"feed":_vm.getFeeds.structure,"selected_input":_vm.structure.selected}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('load_columns') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.structure !== [] ? Object.values(_vm.structure.selected).join() : ''))])]],2):_vm._e(),(_vm.showStructure)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('how_many_columns') + '?',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: _vm.$parent.$parent.type_item + '_how_many_columns',
                    name: _vm.$parent.$parent.type_item + '[import][how_many_columns]',
                    style: 'min-width: 50px; width: 50px',
                    value: _vm.columns,
                    type: 'int',
                    limitNumMin: 1,
                    limitNumMax: 3,
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('how_many_columns') + '?'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.columns))])]],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }