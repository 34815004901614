var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'clinic_permissions',
            title: _vm.$t('clinic')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.PermissionsClinics.data.name))])])]),_c('div',{staticClass:"section_line"},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('groups')))])]),_c('div',{staticClass:"gh-content-global"},[_c('form',{attrs:{"id":"form_clinics_groups"}},_vm._l((_vm.PermissionsClinics.data.groups),function(i){return _c('div',{key:i.id,staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'getFeeds',
                                    imagelocation: require('../../../assets/gh_new_dessign/floating.svg'),
                                    id: i.type,
                                    text: _vm.$t(i.name),
                                    textlocation: _vm.$t('add') + ' ' + i.name,
                                    actionresult: 'loadResultSearchGroups',
                                    nameResult: 'clinic[groups][' + i.type + '][]',
                                    supportPermision: true
                                },"mainSelector":{
                                    name: 'Formsearchcompany',
                                    formId: 'search',
                                    actionsearch: 'getSearchGroups'
                                },"tableResolt":{
                                    header: _vm.header,
                                    data: i.selected && i.selected.summary ? i.selected.summary : []
                                },"tableSelected":{
                                    headersummary: _vm.headersummary,
                                    selected: i.selected && i.selected.selecteds ? i.selected.selecteds : []
                                }}})],1)}),0)])]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.submitPermissionsClinics();}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_back',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }