import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    tate: {
        data_form_process: [],
        data_item_form: [],
        data: [],
        info: [],
        dependency: [],
        propagate: [],
    },
    mutations: {
        loadSectionSubprocess(state, payload) {
            var newArray = []
            for (let key in payload) {
                if (payload[key] !== undefined) {
                    var param = {
                        'id': payload[key].id,
                        'name': payload[key].name,
                        'data': payload[key].data
                    };

                    newArray.push(param);
                }
            }

            state.data = newArray;
        },
        loadItemForm(state, payload){
            var newArray = []
            for (let key in payload.data) {
                var dataArray = [];
                for (let k in payload.data[key].data) {
                    if (payload.data[key].data[k] !== undefined) {
                        dataArray.push(payload.data[key].data[k]);
                    }
                }

                var param = {
                    'id': payload.data[key].id,
                    'name': payload.data[key].name,
                    'data': dataArray
                };

                newArray.push(param);
            }

            state.data = newArray;
        },
        loadDependency(state, payload){
            var newArray = []
            for (let key in payload) {
                if (payload[key] !== undefined) {
                    newArray.push(payload[key]);
                }
            }

            state.dependency = newArray;
        },
        loadFormProcessData(state, payload){
            var params = {
                'id': payload.id,
                'code': payload.code,
                'name': payload.name,
                'category': payload.category
            }
            state.data_form_process = params;
            state.data = payload.data;
        },
        loadItemFormData(state, payload){
            state.data_item_form = payload
        },
        loadInfoByTypeAndID(state, payload){
            state.info = payload;
        },
        loadPropagateFormProcess(state, payload){
            state.propagate = payload;
        }
    },
    actions: {
        async removeSectionSubprocess(state, item){
            delete item.item.data.data.data[item.item.data.index];
            state.commit('loadSectionSubprocess', [...item.item.data.data.data]);
        },
        async removeDependencies(state, item){
            delete item.item.data.dependency[item.item.data.index];
            state.commit('loadDependency', [...item.item.data.dependency]);
        },
        async removeItemForm(state, item){
            item.item.data.data.allData[item.item.data.data.parentIndex].data.find((i, k) => {
                if(i.id === item.item.data.item.id){
                    delete item.item.data.data.allData[item.item.data.data.parentIndex].data[k];
                }
            });

            state.commit('loadItemForm', {'data': [...item.item.data.data.allData], 'index': item.item.data.data.parentIndex});
        },
        async getFormProcessData(state, item){
            let request = API_URL + 'configuration/' + item.type + '/creation-' + (item.type == 'process' ? 'process' : 'form') + '/' + item.id;
            await axios({
                url: request,
            }).then(
                response => {
                    state.commit("loadFormProcessData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getItemFormData(state, item){
            let request = API_URL + 'configuration/' + item.type + '/creation-' + (item.type == 'process' ? 'process' : 'form') + '/' + (item.type == 'process' ? 'form' : 'item') + '/' + item.form_process_id + '/' + item.item_section_id + '/' + item.id;
            await axios({
                url: request,
            }).then(
                response => {
                    state.commit("loadItemFormData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getInfoByTypeAndID(state, item){
            let request = API_URL  + item.type + '/' + item.id + '/details';
            await axios({
                url: request,
            }).then(
                response => {
                    state.commit("loadInfoByTypeAndID", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationFormProcess(state, item){
            let request = API_URL + 'configuration/'+ item.typeCreation + '/' + (item.typeCreation == 'process' ? 'creation-process' : 'creation-form') + (item.id !== undefined ? '/' + item.id : '');
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            formData.append(item.typeCreation + '[type]', item.type);

            item.actualData.forEach((i, iKey) => {
                formData.append(item.typeCreation + '[data][' + iKey + '][id]', i.id);
                formData.append(item.typeCreation + '[data][' + iKey + '][name]', i.name);

                if(i.data !== undefined){
                    i.data.forEach((x, xKey) => {
                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey +'][id]', x.id);
                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey +'][' + (item.typeCreation == 'process' ? 'form' : 'item') +']', x[item.typeCreation == 'process' ? 'form' : 'item']);
                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey +'][' + (item.typeCreation == 'process' ? 'form_value' : 'item_value') +']', x[item.typeCreation == 'process' ? 'form_value' : 'item_value']);
                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey +'][mandatory_dependent]', x.mandatory_dependent);

                         if(x.data !== undefined) {
                             formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][type]', x.data.type);
                             formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][item]', x.data.item[0].value);


                             if(x.data.dependent !== undefined) {
                                 x.data.dependent.forEach((dependent, dependentKey) => {
                                     formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependent][' + dependentKey + ']', dependent.value);
                                 });
                             }

                             x.data.dependency.forEach((dependency, dependencyKey) => {
                                 if (dependency.selecteds.length !== 0) {
                                     formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][id]', dependency.id);
                                     formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][active]', false);
                                     dependency.selecteds.forEach((d, dKey) => {
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][children]', d.children);
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][color]', d.color);
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][condition]', d.condition);
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][counter]', d.counter);
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][parent]', d.parent);
                                         formData.append(item.typeCreation + '[data][' + iKey + '][data][' + xKey + '][data][dependency][' + dependencyKey + '][selecteds][' + dKey + '][value]', d.value);
                                     });
                                 }
                             });
                         }
                    });
                }
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200'){
                        router.push({name: 'Configuration'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPropagateFormProcess(state, item){
            let request = API_URL + 'configuration/' + (item.type == 'process' ? 'process' : 'forms') + '/propagate';
            await axios({
                url: request,
            }).then(
                response => {
                    state.commit("loadPropagateFormProcess", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPropagate(state, item){
            let request = API_URL + 'configuration/' + (item.type == 'process' ? 'process' : 'forms') + '/propagate';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append('propagate[]', item);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200'){
                        state.dispatch(item.type == 'form' ? 'getConfigurationValidatePropagateForm' : 'getConfigurationValidatePropagateProcess');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};