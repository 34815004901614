import Warnings from "@/view/warnings/sections/section_warnings.vue";
import SchedulePreOps from "@/view/warnings/lists/partials/view_schedule_pre_ops.vue";

const routes = [
    {
        path: '/warnings',
        name: 'warnings',
        props: {showMenu: true},
        component: Warnings
    },
    {
        path: '/pre-ops/:id/schedule',
        name: 'schedule_id_pre_ops',
        props: {id: null, showMenu: false, background: true, otherTitle: 'Schedule pre-ops'},
        component: SchedulePreOps,
    },
];

export default routes;