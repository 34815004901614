<template>
    <div id="option_to_select_selector_feeds" v-if="haveData" :key="'A' + refreshAll">
        <div class="gh-row-content" :key="'B' + refreshAll2">
            <template v-if="!$parent.$parent.feed.is_tree">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'label_' + $parent.$parent.type_item + '_selector_selecteds',
                            class: 'T15_b',
                            subtitle: $t('select_value') + ':',
                            text: $t('selecteds') + '*',
                            label: $t('selecteds'),
                            type: $parent.$parent.feed.type,
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        }"
                        :datainput="{
                            id: $parent.$parent.type_item + '_selector_selecteds',
                            name: $parent.$parent.type_item + '[selector][selecteds]' + ($parent.$parent.feed.type === 'checkbox' ? '[]' : '')
                        }"
                        :feed="getFeeds.selected"
                        :selected_input="selecteds"
                        ref="RefSelecteds"
                        @accept="getSelectedValues"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('selecteds') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{selecteds !== [] ? Object.values(selecteds).join() : ''}}</label>
                </template>
            </template>
            <template v-else>
                <template v-if="!$root._route.params.view">
                    <GhTreeSelectorBlock
                        :dataBlock="{
                            type: $parent.$parent.feed.type,
                            id: $parent.$parent.type_item + '_selector_selecteds',
                            label: $t('selecteds') + '*',
                            title: $t('selecteds'),
                            text: $t('select_value') + ':',
                            name: $parent.$parent.type_item + '[selector][selecteds]'  + ($parent.$parent.feed.type === 'checkbox' ? '[]' : ''),
                            id_value: 'value',
                            name_label: 'text',
                            child_key: 'children',
                            required: true,
                            checkparentdata: false,
                            style: 'margin-left: 0px;',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        }"
                        :selected_input="selecteds"
                        :items="getFeeds.selected"
                        ref="RefSelecteds"
                        @accept="getSelectedValues"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('selecteds') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{selecteds !== [] ? selecteds : ''}}</label>
                </template>
            </template>
        </div>
        <template v-if="actualData.length !== 0">
            <component v-bind:is="component" :dataBlock="{items: actualData}" :key="'C' + refreshAll2"/>
        </template>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";

    import include_line_of_selector from "@/view/configuration/items/views/partials/includes/include_line_of_selector.vue";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: 'view_selector_feeds',
        components: {
            GhTreeSelectorBlock,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false,
                component: include_line_of_selector,
                refreshComponent: 0,
                refreshAll: 0,
                refreshAll2: 0,
                actualData: [],
                selecteds: [],
                updated: this.$parent.$parent.updated,
            }
        },
        watch: {
            async '$parent.$parent.feed'(newData, oldData){
                if(newData.call !== oldData.call){
                    await this.$store.dispatch('getFeedSelected', {call: this.$parent.$parent.feed.call}, {root: true});
                    this.selecteds = [];
                    this.actualData = [];
                    this.refreshAll2++;

                    var that = this;
                    setTimeout(function(){
                        that.openSelecteds();
                    },1);
                }
            },
            'refreshAll'(){
                this.getSelectedValues();
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.feed.call !== undefined){
                await this.$store.dispatch('getFeedSelected', {call: this.$parent.$parent.feed.call}, {root: true});
            }

            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.selecteds !== undefined){
                var dataSelecteds = [];
                this.$parent.$parent.stateData.data.selecteds.forEach((item) => {
                    if(this.$parent.$parent.feed.is_tree){
                        var param = {
                            'value': item.id,
                            'text': item.answer
                        }
                        dataSelecteds.push(param);
                    }else {
                        dataSelecteds[item.id] = item.answer;
                    }
                });

                this.selecteds = dataSelecteds;
                this.actualData = this.$parent.$parent.stateData.data.selecteds;
            }

            this.haveData = true;
        },
        updated() {
            if(!this.updated) {
                this.openSelecteds();
            }

            this.updated = true;
        },
        methods: {
            openSelecteds(){
                if(this.$parent.$parent.feed.is_tree){
                    this.Global.openPopUp(this.$parent.$parent.type_item + '_selector_selecteds-Popup');
                    this.$refs.RefSelecteds.StatusPopup;
                }else {
                    this.Global.openPopUp('label_' + this.$parent.$parent.type_item + '_selector_selecteds_data_selector_popup');
                    this.$refs.RefSelecteds.StatusPopup();
                }
            },
            getSelectedValues(){
                var params = new Array();

                if(this.$parent.$parent.feed.is_tree){
                    Object.entries(this.$refs.RefSelecteds.datasaved).forEach((item) => {
                        var values = this.actualData.find(e => e.id.toString() === item[1].value.toString());
                        var push = {
                            'id': item[1].value,
                            'answer': item[1].text,
                            'item': values !== undefined && values.item !== undefined ? values.item : [],
                            'associate_to': values !== undefined && values.associate_to !== undefined ? values.associate_to : false
                        }
                        params.push(push);
                    });

                    this.selecteds = this.$refs.RefSelecteds.datasaved;
                }else {
                    Object.entries(this.$refs.RefSelecteds.checkdata).forEach((item) => {
                        var values = this.actualData.find(e => e.id.toString() === item[0].toString());
                        var push = {
                            'id': item[0],
                            'answer': item[1],
                            'item': values !== undefined && values.item !== undefined ? values.item : [],
                            'associate_to': values !== undefined && values.associate_to !== undefined ? values.associate_to : false
                        }
                        params.push(push);
                    });

                    this.selecteds = this.$refs.RefSelecteds.checkdata;
                }

                this.actualData = params;
                this.refreshAll2++;
            },
            removeAnswer(item){
                if(this.$parent.$parent.feed.is_tree){
                    Object.entries(this.$refs.RefSelecteds.datasaved).forEach((items, key) => {
                        if(items[1].value == item){
                            delete this.$refs.RefSelecteds.datasaved[key];
                        }
                    });

                    this.selecteds = this.$refs.RefSelecteds.datasaved;
                }else{
                    var params = new Array();
                    this.actualData.forEach((items) => {
                        var interlude = [];
                        interlude.push(this.$children[1].$refs['RefLineSelector' + items.id][0].children[2].__vue__.datasaved);
                        if(items.id !== item) {
                            var array = {
                                'id': items.id,
                                'answer': items.answer,
                                'item': interlude,
                                'associate_to': document.querySelector('[id="'+this.$children[1].$refs['RefLineSelector' + items.id][0].children[4].__vue__.datainput.id+'"]:checked')
                            };
                            params.push(array);
                        }
                    });

                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }

                    delete this.$refs.RefSelecteds.checkdata[item];
                    this.selecteds = this.$refs.RefSelecteds.checkdata;
                }

                this.refreshAll++;
            }
        }
    }
</script>

<style>
    #label_standard_item_selector_selecteds_selector_label, #standard_item_selector_selecteds_selector_label, #label_examination_item_selector_selecteds_selector_label, #examination_item_selector_selecteds_selector_label{
        width: 150px;
        text-align: end;
    }
</style>