import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: [],
    },
    mutations: {
        loadTablePermissionsProfiles(state, payload) {
            state.records_data = payload;
        },
        loadDataPermissionsProfiles(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getPermissionsProfiles(state){
            await axios.get(
                API_URL + "permissions/profiles"
            ).then(
                response => {
                    state.commit('loadTablePermissionsProfiles', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataPermissionsProfiles(state, item){
            let  requestLogin = API_URL + "permissions/profiles/" + item.id;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadDataPermissionsProfiles", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPermissionsProfiles(state,item){
            let request =  item.id ? API_URL + "permissions/profiles/"+item.id : API_URL + "permissions/profiles";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200') {
                        router.push({name: 'permissions'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removePermissionsProfiles(state, item){
            let requestLogin = API_URL + "permissions/profiles/" + item.id;
            let formData = new FormData();

            formData.append('profile[id]', item.id);
            formData.append('profile[type]', item.type);

            axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getPermissionsProfiles');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};