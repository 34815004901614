import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadTableConfigurationConsentFormsPending(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getConfigurationConsentFormsPending(state){
            await axios.get(
                API_URL + "configuration/consent-forms/pending"
            ).then(
                response => {
                    state.commit('loadTableConfigurationConsentFormsPending', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};