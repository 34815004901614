import CreationFormItem from "@/view/configuration/form_process/shared/partials/partial_register_item_form.vue";

const routes = [
    {
        path: '/configuration/forms/creation-form/item',
        name: 'configuration_forms_item_new_item',
        component: CreationFormItem,
        props: {id: null, showMenu: false, background: true, otherTitle: 'item'},
        children: [
            {
                path: '/configuration/forms/creation-form/item/:form_process_id/:item_section_id/:id',
                name: 'configuration_forms_item_edit_item',
                component: CreationFormItem,
                props: {id: null, item_section_id: null, form_process_id: null, showMenu: false, background: true, otherTitle: 'item'},
            }
        ]
    },
    {
        path: '/configuration/process/creation-process/form',
        name: 'configuration_process_form_new_form',
        component: CreationFormItem,
        props: {id: null, showMenu: false, background: true, otherTitle: 'process'},
        children: [
            {
                path: '/configuration/process/creation-process/form/:form_process_id/:item_section_id/:id',
                name: 'configuration_process_form_edit_form',
                component: CreationFormItem,
                props: {id: null, item_section_id: null, form_process_id: null, showMenu: false, background: true, otherTitle: 'process'},
            }
        ]
    },
];

export default routes;