var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'pre_ops_patients_',
    },"header":_vm.header,"data":_vm.WarningsPreOps.records_data.length == 0 ? [] : _vm.WarningsPreOps.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_view_pre_ops_patient_' + itemProp.id,
                text: _vm.$t('view'),
                icon: require('../../../assets/gh_new_dessign/view.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')}}})]}}],null,false,1384201052)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }