<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_propagate',
            title: $t('propagate_' + dataBlock.type),
            type: 'warning',
            style: 'width: 50%; min-width: 920px'
        }"
        :bodyConfiguration="{
            id: 'popup_propagate_body',
            class: '',
            style: '',
            text: $t('select_' + dataBlock.type + '_to_propagate'),
        }"
        :footerConfiguration="{}"
    >
        <template v-slot:body>
            <div style="margin-top: 15px; margin-right: 15px; margin-left: 15px;">
                <form id="form_propagate" :key="dataBlock.refresh">
                    <div v-for="(item,index) in ConfigurationFormProcess.propagate" :key="index" style="margin-bottom: 5px;">
                        <GhDropDownField
                            :dataBlock="{
                                id: 'drop_field_propagate_' + dataBlock.type + '_' + item.id,
                                title: item.name,
                            }"
                        >
                            <template v-slot:content>
                                <GhTableCommon style="margin-top: 10px;"
                                    :extratable="{
                                        id: 'table_propagate_' + dataBlock.type + '_' + item.id,
                                    }"
                                    :header="dataBlock.type === 'process' ? header_process : header_form"
                                    :data="item.data === undefined ? [] : item.data"
                                >
                                </GhTableCommon>
                            </template>
                        </GhDropDownField>
                    </div>
                </form>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_popup_propagate',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="acceptPropagate"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_popup_propagate',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_propagate')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import {mapState} from "vuex";

    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhTableCommon from "fe-gh-table-common-lib";

    export default {
        name: 'popup_propagate',
        props: ['dataBlock'],
        components: {
            GhTableCommon,
            GhDropDownField,
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationFormProcess'])
        },
        data(){
            return {
                header_form: [
                    {text: '', field: 'check', typeCheck: 'checkbox', style: 'min-width: 25px; max-width: 25px; width: 25px;'},
                    {text: 'form', field: 'form', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 30%;'},
                    {text: 'related_items', field: 'related_items', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 70%;'},
                ],
                header_process: [
                    {text: '', field: 'check', typeCheck: 'checkbox', style: 'min-width: 25px; max-width: 25px; width: 25px;'},
                    {text: 'process', field: 'process', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 30%;'},
                    {text: 'related_forms', field: 'related_forms', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 70%;'},
                ]
            }
        },
        methods: {
            async acceptPropagate(){
                const formData = new FormData(document.getElementById('form_propagate'));
                if([...formData].length === 0){
                    this.Global.warningInfo({0: this.$t('not_selected_any_' + this.dataBlock.type + '_to_propagate')});
                }else{
                    await this.$store.dispatch('setPropagate', {
                        formData: formData,
                        type: this.dataBlock.type
                    }, {root: true});

                    this.Global.closePopUp('popup_propagate');
                }
            }
        }
    }
</script>