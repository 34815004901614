<template>
    <div class="gh-row-content" :key="dataBlock.index">
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('variable') + ' ' + (dataBlock.index + 1) + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: true,
                    id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_variable',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][variable]',
                    style: 'min-width: 365px; width: 365px',
                    value: dataBlock.item.variable,
                    type: 'text'
                }"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('variable') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.variable}}</label>
        </template>
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('min_value')  + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: true,
                    id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_min',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][min]',
                    style: 'min-width: 75px; width: 75px',
                    value: dataBlock.item.min,
                    type: 'float'
                }"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('min_value') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.variable}}</label>
        </template>
        <template v-if="!$root._route.params.view">
            <GhInputTextField
                :datalabel="{
                    text: $t('max_value')  + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: true,
                    id: $parent.$parent.$parent.type_item + '_' + dataBlock.type + '_' + dataBlock.index + '_max',
                    name: $parent.$parent.$parent.type_item + '[' + dataBlock.type + '][' + dataBlock.index + '][max]',
                    style: 'min-width: 75px; width: 75px',
                    value: dataBlock.item.max,
                    type: 'float'
                }"
            />
        </template>
        <template v-else>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('max_value') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{dataBlock.item.variable}}</label>
        </template>
        <template v-if="$root._route.params.view !== 'true'">
            <GhAction
                style="margin-left: 10px; width: max-content; white-space: nowrap"
                :dataction="{
                    id: 'action_delete_' + dataBlock.index,
                    text: $t('delete'),
                    icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
                }"
                @click="$parent.removeVariable(dataBlock.index)"
            />
        </template>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'include_line_of_information_matrix',
        components: {GhAction, GhInputTextField},
        props: ['dataBlock'],
    }
</script>