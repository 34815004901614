import SectionPriorities from "@/view/configuration/priorities/sections/section_priority.vue";
import Condition from "./condition/route";
import Priority from "./priority/route";

const route = [
    {
        path: '/configuration/priority',
        name: 'configuration_priority',
        component: SectionPriorities,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Condition,
    ...Priority,
]

export default routes;