import Visits from "@/view/visits/visits.vue";
import Schedule from "./schedule/route";
import VisitSection from "@/view/visits/sections/section_visits.vue";
// import VisitSectionCalendar from "@/view/visits/sections/section_visits_calendar.vue";

const route = [
    {
        path: '/visits',
        name: 'visits',
        props: {showMenu: true},
        component: Visits,
    },
    {
        path: '/visits/today',
        name: 'visits_today',
        component: VisitSection,
        props: {type: 'today', showMenu: true},
    },
    {
        path: '/visits/weekly',
        name: 'visits_weekly',
        component: VisitSection,
        props: {type: 'weekly', showMenu: true},
    },
    {
        path: '/visits/month',
        name: 'visits_month',
        component: VisitSection,
        props: {type: 'month', showMenu: true},
    }
];

const routes = [
    ...route,
    ...Schedule
];
export default routes;