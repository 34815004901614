<template>
    <div class="float_window" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Surgery.section_info[label['type']].show"
                :datablock="{
                    id: 'surgery_' + label.id,
                    title: $t(label['id']),
                    second_title: $t(Surgery.section_info[label['type']]['second_text']),
                    num_results: Surgery.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_surgery from "@/view/surgery/lists/list_surgery.vue";

    export default{
        name: "section_surgery",
        components: {
            GhDropSelector,
        },
        computed : {
            ...mapState(['Surgery'])
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 'surgery', name: 'surgery', component: list_surgery, type: 'surgery'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSurgerySections', '', {root: true});
            this.haveData = true;
        }
    }
</script>