import Patients from '@/view/patients/sections/section_patients.vue';
import Patient from "@/view/patients/views/view_patient.vue";

const routes = [
    {
        path: '/patients',
        name: 'patient',
        props: {showMenu: true},
        component: Patients
    },
    {
        path: '/patients/patient',
        name: 'new_patient',
        component: Patient,
        props: {id: null, showMenu: false, background: true, otherTitle: 'patient'},
        children: [
            {
                path: '/patients/patient/:id',
                name: 'edit_patient',
                component: Patient,
                props: {id: null, showMenu: false, background: true, otherTitle: 'patient'},
            },
            {
                path: '/patients/patient/:id/:view',
                name: 'view_patient',
                component: Patient,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'patient'},
            }
        ],
    },
];

export default routes;