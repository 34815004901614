var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[_c('GhDropSelector',{attrs:{"datablock":{
                id: 'surgery_section_' + label.id,
                title: _vm.$t(label['name']),
                second_title: '',
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(label.id === 'patient_data')?[_c('div',{staticStyle:{"padding-right":"12px","padding-left":"12px"}},[_c(label.component,{tag:"component"})],1)]:[_c(label.component,{tag:"component"})]]},proxy:true}],null,true)})],1)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_back',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }