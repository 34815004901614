import ProfessionalTypes from "@/view/configuration/variable/views/view_professional_type.vue";

const routes = [
    {
        path: '/configuration/variable/professional-type',
        name: 'configuration_variable_new_professional_type',
        component: ProfessionalTypes,
        props: {id: null, showMenu: false, background: true, otherTitle: 'professional_type'},
        children: [
            {
                path: '/configuration/variable/professional-type/:id',
                name: 'configuration_variable_edit_professional_type',
                component: ProfessionalTypes,
                props: {id: null, showMenu: false, background: true, otherTitle: 'professional_type'},
            },
            {
                path: '/configuration/variable/professional-type/:id/:view',
                name: 'configuration_variable_view_professional_type',
                component: ProfessionalTypes,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'professional_type'},
            }
        ],
    },

];

export default routes;