<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_professional_past_',
        }"
        :header="header"
        :data="ConfigurationProfessionalsPast.records_data.length == 0 ? [] : ConfigurationProfessionalsPast.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="viewProfessionalsPast(itemProp.id)"> {{itemProp[labelProp]}}</a>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_past",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['ConfigurationProfessionalsPast'])
        },
        data(){
            return{
                haveData: false,
                timer: [],
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'specialties', field: 'specialties', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 50%;'},
                    {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationProfessionalsPast', '', {root: true});
            this.haveData = true;
        },
        methods: {
            viewProfessionalsPast(id){
                this.Global.windowOpen(
                    this.Global.openSecondWindow('configuration_professionals_view_professional', {
                        id: id,
                        view: true
                    }),'configuration_professionals_professional', true
                );

                this.updatedParentWindow();
            },
            updatedParentWindow(){
                var then = this;
                window.UpdatedSelect = async function(e){
                    if(e === 'OK'){
                        await then.$store.dispatch('getConfigurationProfessionalsPast', '', {root: true});
                        await then.$store.dispatch('getConfigurationProfessionalsSections', '', {root: true});
                    }
                }

                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            checkChildWindow() {
                if (this.Global.state.window.closed) {
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>