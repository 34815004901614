<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'surgery_',
        }"
        :header="header"
        :data="SurgeryData.records_data.length == 0 ? [] : SurgeryData.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            {{itemProp[labelProp]}}
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'surgery_id', params: {id: itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'action_execute_surgery_' + itemProp.id,
                        text: $t('execute'),
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_surgery",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['SurgeryData'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 60%;'},
                    {text: 'gender', field: 'gender', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'date_of_birth', field: 'date_of_birth', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSurgeryList', '', {root: true});
            this.haveData = true;
        }
    }
</script>