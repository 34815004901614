<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab
            :items="$router.options.GhTabsVisits"
            :dataBlock="{
                id: 'visits_tab_',
                calldispatch: 'getVisitsTabActive'
            }"
        />
        <component v-bind:is="Visits.subVisitsTabActive"/>
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";
    import {mapState} from "vuex";

    export default {
        name: 'template_visits',
        components: {
            GhSubTab
        },
        computed : {
            ...mapState(['Visits'])
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            }
        },
        data(){
            return{
                haveData: false
            }
        },
        mounted(){
            var that = this;
            setTimeout(function(){
                that.haveData = true;
            },200);
        }
    }
</script>