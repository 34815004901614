var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_professional_active_',
    },"header":_vm.header,"data":_vm.ConfigurationPriorityConditions.records_data.length == 0 ? [] : _vm.ConfigurationPriorityConditions.records_data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_condition_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_priority_condition_edit', {id: itemProp.id}),'configuration_priority_condition')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_condition_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationConditions', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, _vm.$t('delete_condition'), _vm.$t('preparing_delete_condition'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_new_condition',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_priority_condition_new', {}),'configuration_priority_condition')}}})],1)]},proxy:true}],null,false,3182051519)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }