import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: [],
    },
    mutations: {
        loadFlows(state,payload){
            state.records_data = payload
        },
        loadFlowsData(state,payload){
            state.data = payload
        },
    },
    actions: {
        async getFlows(state){
            await axios.get(
                API_URL + "support/workflow-tasks"
            ).then(
                response => {
                    state.commit('loadFlows', response.data.data);
                }
            )
        },
        async setFlowData(state, item){
            let requestLogin = API_URL + "support/workflow-tasks/"+item.id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('getFlows')
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFlowsData(state, items){
            if(items.id === undefined){
                state.commit("loadFlowsData", '');
            }else {
                let requestLogin = API_URL + "support/workflow-tasks/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadFlowsData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
    }
}
