var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_general_data_genders_',
    },"header":_vm.header,"data":_vm.ConfigurationGeneralDataGender.records_data.length == 0 ? [] : _vm.ConfigurationGeneralDataGender.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_general_data_view_gender', {id: itemProp.id, view: true}),'configuration_gender')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_genders_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_general_data_edit_gender', {id: itemProp.id}),'configuration_gender')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_genders_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationGeneralDataGender', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, _vm.$t('delete_gender'), _vm.$t('preparing_delete_gender'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_new_gender',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_general_data_new_gender', {}),'configuration_gender')}}})],1)]},proxy:true}],null,false,4113116488)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }