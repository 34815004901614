var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataItem.item.config.picture)?_c('div',{staticStyle:{"margin-top":"8px","white-space":"nowrap"}},[_c('GhMultiUpload',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Picture',class:'process_multi_upload',attrs:{"datalabel":{
            text: _vm.$t('add_picture'),
            text_popup: _vm.$t('picture'),
            input_text: _vm.$t('name'),
            textarea_text: _vm.$t('description'),
            attach_text: _vm.$t('attach_picture'),
            content_warning: '',
            label_required: _vm.$t('documents'),
            required: false,
        },"datablock":{
            id: 'upload_' + _vm.dataItem.item.config[_vm.dataItem.type === 1 ? 'textarea' : _vm.dataItem.type === 6 || _vm.dataItem.type === 7 ? 'input' : _vm.dataItem.type === 8 ? 'selector' : _vm.dataItem.type === 9 ? 'table' : _vm.dataItem.type === 10 ? 'imported' : _vm.dataItem.type == 11 ? 'graphic' : _vm.dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_picture',
            name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][picture]',
            accept: 'image/png, image/gif, image/jpeg',
            class: 'T15_b',
            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg'),
            icon_trash: require('../../../../../assets/gh_new_dessign/trash.svg'),
            icon_download: require('../../../../../assets/gh_new_dessign/descargar.svg'),
            href: _vm.dispatchEvidence,
        },"defaultfiles":_vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.picture : []},on:{"emiter":function($event){return _vm.$parent.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.$parent.dataSubprocess.id);}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }