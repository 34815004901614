var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{key:_vm.refreshEquipments,staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_equipments_equipments_',
    },"header":_vm.header,"data":_vm.ConfigurationEquipmentsEquipments.records_data.length == 0 ? [] : _vm.ConfigurationEquipmentsEquipments.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_variable_view_equipment', {id: itemProp.id, view: true}),'configuration_variable_equipment')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_equipments_delete_equipments_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeEquipmentByProfessional', itemProp.id,{
                'sn': itemProp.code,
                'name': itemProp.name,
                'type': itemProp.type,
                'status': itemProp.status,
                'description': itemProp.description,
            }, _vm.$t('delete_equipment'), _vm.$t('preparing_delete_equipment'), 'unselect')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_equipments_new_equipments',
                    text: _vm.$t('add')
                }},on:{"click":function($event){return _vm.Global.openPopUp('popup_equipments')}}})],1)]},proxy:true}],null,false,1685846566)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }