<template>
    <div class="float_window" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Patients.section_info[label['type']].show"
                :datablock="{
                    id: 'patients_' + label.id,
                    title: $t(label['id']),
                    second_title: $t(Patients.section_info[label['type']]['second_text']),
                    num_results: Patients.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_patients from "@/view/patients/lists/list_patients.vue";
    export default{
        name: "section_patients",
        components: {
            GhDropSelector,
        },
        computed : {
            ...mapState(['Patients'])
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 'patients', name: 'patients', component: list_patients, type: 'patients'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPatientsSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>