import StandardItem from "@/view/configuration/items/views/view_standard_item.vue";

const routes = [
    {
        path: '/configuration/items/standard-item',
        name: 'configuration_items_new_standard_items',
        component: StandardItem,
        props: {id: null, showMenu: false, background: true, otherTitle: 'standard_item'},
        children: [
            {
                path: '/configuration/items/standard-item/:id',
                name: 'configuration_items_edit_standard_items',
                component: StandardItem,
                props: {id: null, showMenu: false, background: true, otherTitle: 'standard_item'},
            },
            {
                path: '/configuration/items/standard-item/:id/:view',
                name: 'configuration_items_view_standard_items',
                component: StandardItem,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'standard_item'},
            }
        ],
    },

];

export default routes;