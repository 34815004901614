import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        speciality_array: '',
        calendar_speciality_array: [],
    },
    mutations: {
        loadSpecialities(state, payload) {
            state.speciality_array = payload;
        },
        loadCalendarSpecialities(state, payload) {
            state.calendar_speciality_array[payload.id] = payload.data;
        },
    },
    actions: {
        async getSpecialities(state){
            await axios.get(
                API_URL + "calendar/speciality"
            ).then(
                response => {
                    state.commit('loadSpecialities', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCalendarSpecialities(state,item){
            await axios.get(
                API_URL + "calendar/speciality/:id"
            ).then(
                response => {
                    state.commit('loadCalendarSpecialities', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async updateCalendarSpecialities(state,item){
            await axios.get(
                API_URL + "calendar/speciality/:id/:idMonth"
            ).then(
                response => {
                    state.commit('loadCalendarSpecialities', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};