<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_support_priority'
        }"
        :header="ConfigurationSupportPriority.header"
        :data="ConfigurationSupportPriority.records_data.length == 0 ? [] : ConfigurationSupportPriority.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_support_view_priority', {id: itemProp.id, view: true}),'configuration_support_priority')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_priority_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_support_edit_priority', {id: itemProp.id}),'configuration_support_priority')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_priority_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationSupportPriority', itemProp.id,{'priority': itemProp.name}, $t('delete_priority'), $t('preparing_delete_priority'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_priority',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_support_new_priority', {}),'configuration_support_priority')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "list_priority",
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigurationSupportPriority'])
        },
        data(){
            return{
                haveData:false,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportPriority', '', {root: true});
            this.haveData = true;
        }
    }
</script>