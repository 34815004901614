import EquipmentMaintenance from "@/view/configuration/variable/views/view_equipment_maintenance.vue";

const routes = [
    {
        path: '/configuration/variable/equipment-maintenance',
        name: 'configuration_variable_new_equipment_maintenance',
        component: EquipmentMaintenance,
        props: {id: null, showMenu: false, background: true, otherTitle: 'equipment_maintenance'},
        children: [
            {
                path: '/configuration/variable/equipment-maintenance/:id',
                name: 'configuration_variable_edit_equipment_maintenance',
                component: EquipmentMaintenance,
                props: {id: null, showMenu: false, background: true, otherTitle: 'equipment_maintenance'},
            },
            {
                path: '/configuration/variable/equipment-maintenance/:id/:view',
                name: 'configuration_variable_view_equipment_maintenance',
                component: EquipmentMaintenance,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'equipment_maintenance'},
            }
        ],
    },

];

export default routes;