import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        group_array: '',
        calendar_group_array: [],
    },
    mutations: {
        loadGroup(state, payload) {
            state.group_array = payload;
        },
        loadCalendarGroup(state, payload) {
            state.calendar_group_array[payload.id] = payload.data;
        },
    },
    actions: {
        async getGroup(state){
            await axios.get(
                API_URL + "calendar/group"
            ).then(
                response => {
                    state.commit('loadGroup', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCalendarGroup(state,item){
            await axios.get(
                API_URL + "calendar/group/:id"
            ).then(
                response => {
                    state.commit('loadCalendarGroup', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async updateCalendarGroup(state,item){
            await axios.get(
                API_URL + "calendar/group/:id/:idMonth"
            ).then(
                response => {
                    state.commit('loadCalendarGroup', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};