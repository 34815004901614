<template>
    <div id="option_to_select_time" v-if="haveData">
        <div class="gh-row-content" style="height: 18px;">
            <div>
                <div style="width: 40%" :style="!$root._route.params.view ? '' : 'display: flex;'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_' + $parent.$parent.type_item + '_time_period',
                                class: 'T15_b',
                                subtitle: $t('select_period') + ':',
                                text: $t('period'),
                                label: $t('period'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: $parent.$parent.type_item + '_time_period',
                                name: $parent.$parent.type_item + '[time][period]'
                            }"
                            :feed="getFeeds.periods"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.time !== undefined && $parent.$parent.stateData.data.time.period !== undefined ? $parent.$parent.stateData.data.time.period : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('period') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.time !== undefined && $parent.$parent.stateData.data.time.period !== undefined ? Object.values($parent.$parent.stateData.data.time.period).join() : ''}}</label>
                    </template>
                </div>
                <div style="width: 40%">
                    <template v-if="!$root._route.params.view">
                        <GhTreeSelectorBlock
                            :dataBlock="{
                                type: 'radio',
                                id: $parent.$parent.type_item + '_time_item',
                                label: $t('item'),
                                title: $t('item'),
                                text: $t('select_item') + ':',
                                name: $parent.$parent.type_item + '[time][item]',
                                id_value: 'value',
                                name_label: 'text',
                                child_key: 'children',
                                required: false,
                                checkparentdata: false,
                                style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :selected_input="$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.time !== undefined && $parent.$parent.stateData.data.time.item !== undefined ? $parent.$parent.stateData.data.time.item : []"
                            :items="getFeeds.items"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{$parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.time !== undefined && $parent.$parent.stateData.data.time.item !== undefined ? $parent.$parent.stateData.data.time.item[0].text : ''}}</label>
                    </template>
                </div>
            </div>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.type_item + '_time_associate_to',
                    value: 1,
                    name: $parent.$parent.type_item + '[time][associate_to]',
                    selected: $parent.$parent.stateData.data !== undefined && $parent.$parent.stateData.data.time !== undefined && $parent.$parent.stateData.data.time.associate_to !== undefined ? $parent.$parent.stateData.data.time.associate_to : '',
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: 'view_time',
        components: {
            GhTreeSelectorBlock, GhChecks,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedPeriods', '', {root: true});
            await this.$store.dispatch('getFeedItems', {type: this.$parent.$parent.type_item}, {root: true});
            this.haveData = true;
        }
    }
</script>

<style>
    #label_standard_item_time_period_selector_label, #label_examination_item_time_period_selector_label{
        width: 150px;
        text-align: end;
    }
</style>