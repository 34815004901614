import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        conditions_data: [],
        data: [],
    },
    mutations: {
        loadTableConfigurationPriorities(state, payload) {
            state.records_data = payload;
        },
        loadConfigurationPrioritiesConditions(state, payload) {
            state.conditions_data = payload;
        },
        loadConfigurationPriorityData(state, payload) {
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationPriorities(state){
            await axios.get(
                API_URL + "configuration/priorities/priorities"
            ).then(
                response => {
                    state.commit('loadTableConfigurationPriorities', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationPriorities(state, item){
            let requestLogin = API_URL + "configuration/priorities/priorities/" + item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationPriorities');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConfigurationPrioritiesConditions(state,item){
            var requestUrl = API_URL + "configuration/priorities/priorities";
            for(var i=0;i<item.conditions.length;i++){
                requestUrl += i == 0 ? '?condition[]=' + item.conditions[i] : '&condition[]=' + item.conditions[i];
            }

            await axios.get(
                requestUrl
            ).then(
                response => {
                    state.commit('loadConfigurationPrioritiesConditions', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConfigurationPriorityData(state,item){
            await axios.get(
                API_URL + "configuration/priorities/priorities/" + item.id
            ).then(
                async response => {
                    state.commit('loadConfigurationPriorityData', response.data.data);
                    if(response.data.data.condition !== undefined && response.data.data.condition.length !== 0){
                        await state.dispatch('getConfigurationPrioritiesConditions',{conditions:Object.keys(response.data.data.condition)});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationPriority(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/priorities/priorities/" + item.id : API_URL + "configuration/priorities/priorities";
            let formData = new FormData();

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        window.opener.UpdatedSelect(Math.random(), item.id !== undefined ? 'edit' : 'add');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};