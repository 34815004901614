<template>
    <GhInfoBox
        style="margin-top: 6px; margin-left: 3px;"
        :dataBlock="{
            id: 'info_patient_active_patient_prescriptions',
            border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
            padding:{ top: 20, bottom: 0, left: 20, right: 20 },
            boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
            backgroundColor: 'white',
            type: 'row'
        }"
        :dataTitle="{
            show: true,
            text: 'active_patient_prescriptions',
            image: require('../../../../assets/svg/pills.svg'),
            styleBlock: '',
            textStyle:{
                class: 'T20B',
            },
            imageStyle:{
                width: '30px',
                height: '30px',
            },
        }"
        :dataExpansible="{
            show: Patients.data.active_patient_prescriptions.active_medications !== undefined && Patients.data.active_patient_prescriptions.past_medications !== undefined && (Patients.data.active_patient_prescriptions.active_medications.length != 0 || Patients.data.active_patient_prescriptions.past_medications.length != 0) ? true : false,
            animation: true,
            animationSpeed: 1000,
            textExpand: $t('show_past_medications'),
            textCollapse: $t('Hide past medications'),
            iconExpand: require('../../../../assets/svg/add_plus.svg'),
            iconCollapse: require('../../../../assets/svg/add_less.svg'),
        }"
    >
        <template v-slot:content>
            <template v-if="Patients.data.active_patient_prescriptions.active_medications !== undefined && Patients.data.active_patient_prescriptions.past_medications !== undefined && Patients.data.active_patient_prescriptions.active_medications.length == 0 && Patients.data.active_patient_prescriptions.past_medications.length == 0">
                <div id="info_patient_active_patient_prescriptions_show_now">
                    <br>
                    <div class="gh-row-content-info">
                        <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_active_past_medications')}}</label>
                    </div>
                </div>
            </template>
            <template v-else>
                <div id="info_patient_active_patient_prescriptions_show_now">
                    <br>
                    <label class="gh_text_field-label T16_b" style="margin-right: 10px;">{{$t('active_medications')}}</label>
                    <label class="gh_text_field-label T15">{{'(' + Patients.data.active_patient_prescriptions.last_review + ')'}}</label>
                    <template v-if="Patients.data.active_patient_prescriptions.active_medications !== undefined && Patients.data.active_patient_prescriptions.active_medications.length != 0">
                        <div class="gh-row-content-info" v-for="(item, index) in Patients.data.active_patient_prescriptions.active_medications" :key="index" style="display: grid">
                            <span>
                                <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{'• ' + item.name}}</label>
                                <label class="gh_text_field-label T15" v-if="item.description !== '' && item.description !== undefined">{{' - ' + item.description}}</label>
                            </span>
                            <template v-if="item.extra_description !== ''">
                                <label class="gh_text_field-label T15" style="margin-left: 30px; margin-top: 6px;">{{$t(item.extra_description)}}</label>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="gh-row-content-info">
                            <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_active_medications')}}</label>
                        </div>
                    </template>
                </div>
                <div id="info_patient_active_patient_prescriptions_show_after">
                    <br>
                    <div>
                        <label class="gh_text_field-label T16_b" style="margin-right: 10px;">{{$t('past_medications')}}</label>
                        <template v-if="Patients.data.active_patient_prescriptions.past_medications !== undefined && Patients.data.active_patient_prescriptions.past_medications.length != 0">
                            <div class="gh-row-content-info" v-for="(item, index) in Patients.data.active_patient_prescriptions.past_medications" :key="index">
                                <span>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{'• ' + item.name}}</label>
                                    <label class="gh_text_field-label T15" v-if="item.description !== '' && item.description !== undefined">{{' - ' + item.description}}</label>
                                </span>
                                <template v-if="item.extra_description !== ''">
                                    <label class="gh_text_field-label T15" style="margin-left: 30px; margin-top: 6px;">{{$t(item.extra_description)}}</label>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="gh-row-content-info">
                                <label class="gh_text_field-label T15" style="margin-left: 5px; color: green">{{$t('no_past_medications')}}</label>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </template>
    </GhInfoBox>
</template>

<script>
    import GhInfoBox from "fe-gh-info-box-lib";

    import {mapState} from "vuex";

    export default {
        name: "info_patient_active_patient_prescriptions",
        components: {
            GhInfoBox
        },
        computed: {
            ...mapState(['Patients'])
        },
    }
</script>