var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'leave_confirm',
        title: _vm.$t('leave_confirm'),
        type: 'warning',
        style: 'width: 35%; min-width: 430px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: _vm.$t('leave_following_center') + ':',
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px"}},[_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"padding-bottom":"15px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('center') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.data.center))])]),_c('form',{attrs:{"id":"form_popup_leave"}},[_c('input',{attrs:{"type":"hidden","name":"leave[id]"},domProps:{"value":_vm.data.leave_id}}),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"20px","padding-top":"15px"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('leave') + '*'))]),_c('GhChecks',{ref:"GhRadioTemporal",attrs:{"datalabel":{},"datainput":{
                            required: true,
                            label_required: _vm.$t('leave') + '*',
                            type: 'radio',
                            id: 'leave_temporal',
                            value: 1,
                            name: 'leave[leave]',
                            selected: true,
                        }},on:{"click":function($event){return _vm.selectLeave('GhRadioTemporal')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"25px"}},[_vm._v(_vm._s(_vm.$t('temporal')))]),_c('GhChecks',{ref:"GhRadioPermanent",attrs:{"datalabel":{},"datainput":{
                            required: true,
                            label_required: _vm.$t('leave') + '*',
                            type: 'radio',
                            id: 'leave_permanent',
                            value: 2,
                            name: 'leave[leave]',
                            selected: '',
                        }},on:{"click":function($event){return _vm.selectLeave('GhRadioPermanent')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('permanent')))])],1),(_vm.showDates)?_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"20px"}},[_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'leave_date_start',
                            text: _vm.$t('start_date'),
                            style: 'text-align: right;'
                        },"datainput":{
                            id: 'leave_start_date',
                            name: 'leave[start_date]',
                            value: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                            required: true
                        }}}),_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'leave_date_end',
                            text: _vm.$t('end_date'),
                            style: 'width:85px;text-align: right;'
                        },"datainput":{
                            id: 'leave_end_date',
                            name: 'leave[end_date]',
                            value: '',
                            required: true
                        }}})],1):_vm._e()])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_leave',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.submitLeave()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_clse_leave',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('leave_confirm');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }