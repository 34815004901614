import SectionEquipments from "@/view/configuration/equipments/sections/section_equipments.vue";

const routes = [
    {
        path: '/configuration/equipments',
        name: 'configuration_equipments',
        component: SectionEquipments,
        props: {showMenu: true}
    },
];

export default routes;