<template>
    <div class="float_window">
        <form id="form_search_historical" :key="refresh">
            <div class="gh-content-global" v-if="haveData">
                <component v-bind:is="searchHistorical.component" />
                <div class="gh-row-content" style="">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'historical[criterio]',
                            style: 'margin-left:48px; cursor:pointer;',
                            selected:true,
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('meet_all_search_criteria_entered')
                        }"
                        @click="selectRadio('GhRadioAND')"
                        ref="GhRadioAND"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            name: 'historical[criterio]',
                            style: 'cursor:pointer;',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('meet_any_of_the_search_criteria_entered')
                        }"
                        ref="GhRadioOR"
                        @click="selectRadio('GhRadioOR')"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_search',
                    class: 'T19 container-md_button_content',
                    text: $t('search')
                }"
                @click="search()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_clean',
                    class: 'T19 container-md_button_content',
                    text: $t('clean')
                }"
                @click="clean()"
            />
        </div>
    </div>
</template>
<script>
    import GhChecks from 'fe-gh-checks-lib'
    import GhButton from 'fe-gh-button-lib'

    import partial_historic from "@/view/support/lists/partials/partial_historic.vue";

    export default {
        name: "list_historic",
        components: {
            GhChecks,
            GhButton
        },
        data() {
            return {
                refresh: 0,
                haveData: false,
                searchHistorical: {
                    component: partial_historic
                },
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedSupportCategory', '', {root: true});
            await this.$store.dispatch('getFeedSupportPriority', '', {root: true});
            await this.$store.dispatch('getFeedSupportAsigned', '', {root: true});
            this.haveData = true;
        },
        methods:{
            selectRadio(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }
            },
            clean(){
                this.$store.dispatch('destroyedSupportHistoricalResultData','',{root:true});
                document.getElementById("form_search_historical").reset();
                this.refresh++;
            },
            async search(){
                const formData = new FormData(document.getElementById('form_search_historical'));
                await this.$store.dispatch('searchSupportHistoricalData',{formData: [...formData]},{root:true});
            }
        },
    }
</script>

<style scoped>
    .mx-datepicker{
        width: 129px;
    }
</style>
