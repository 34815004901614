import SectionProfessionals from "@/view/configuration/professionals/sections/section_professionals.vue";
import Professionals from "@/view/configuration/professionals/views/view_professional.vue";

const route = [
    {
        path: '/configuration/professionals',
        name: 'configuration_professionals',
        component: SectionProfessionals,
        props: {showMenu: true}
    },
    {
        path: '/configuration/professionals/professional',
        name: 'configuration_professionals_new_professional',
        component: Professionals,
        props: {id: null, showMenu: false, background: true, otherTitle: 'professional'},
        children: [
            {
                path: '/configuration/professionals/professional/:id',
                name: 'configuration_professionals_edit_professional',
                component: Professionals,
                props: {id: null, showMenu: false, background: true, otherTitle: 'professional'},
            },
            {
                path: '/config/professionals/professional/:id/:view',
                name: 'configuration_professionals_view_professional',
                component: Professionals,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'professional'},
            }
        ],
    },
];

const routes = [
    ...route,
]

export default routes;