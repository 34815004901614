<template>
    <div class="real_content">
        <div>
            <div class="selector_page">
                <div class="logo_selector">
                    <img :src="require('../../assets/login/MCH_logo_login.png')" @click="methodExit()"/>
                </div>
                <div class="title F1 C1" style="display: flex;">
                    <div style="width: 50%;">{{$t('select_action')}}</div>
                    <div style="text-align: right; width: 50%;">
                        <GhAction
                            :dataction="{
                                id: 'action_back_login',
                                text: $t('back'),
                                icon: require('../../assets/svg/back.svg'),
                            }"
                            @click="toBackEnvironments"
                        />
                    </div>
                </div>
                <div class="environment_block">
                    <a v-for="(item, index) in Environment.records_data" :key="index" @click="toRedirectSpecificClinic(item.id)">
                        <GhEnvironemnt
                            :dataBlock="{
                                id: 'environment_action_' + item.id,
                                title: item.title !== undefined ? item.title.toUpperCase() : '',
                                subtitle: item.subtitle !== undefined ? item.subtitle : '',
                            }"
                            :environment="{
                                img_hover: item.image.visible,
                                img_visible: item.image.hover,
                            }"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhEnvironemnt from 'fe-gh-environment-lib';
    import GhAction from 'fe-gh-action-lib';

    import {mapState} from "vuex";

    export default {
        name: "view_environment_action",
        components:{
            GhEnvironemnt,
            GhAction
        },
        computed:{
            ...mapState(['Environment']),
        },
        async beforeMount(){
            await this.$store.dispatch('getEnvironmentsActionByUser', {id: this.$root._route.params.id}, {root: true});
        },
        methods: {
            async methodExit(){
                await this.$store.dispatch('removeTabActive','',{root: true});
                await this.$store.dispatch('SET_LOGOUT','',{root: true});
                await this.$store.dispatch('TO_LOGIN','',{root: true});
            },
            async toRedirectSpecificClinic(id){
                await this.$store.dispatch('setEnvironmentAction', {id: this.$root._route.params.id, id_action: id}, {root: true});
            },
            async toBackEnvironments(){
                await this.$store.dispatch('backEnvironment', '', {root: true});
            }
        }
    }
</script>

<style>
    .selector_page{
        min-width: 1024px;
        width: 1024px;
        margin: 0 auto;
        margin-top: 69px;
        padding-left: 34px;
    }

    .logo_selector{
        margin-left: 10px;
    }

    .title {
        margin-top: 56px;
    }

    .F1{
        font-family: 'IBMPlexSans-Regular';
        font-size: 20px;
        line-height: 24px;
    }

    .C1{
        color: #636362;
    }

    .environment_block{
        margin: 45px 0px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .environment_box, .environment_box-info{
        width: 120px !important;
    }
</style>