<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_consent_forms_active_',
        }"
        :header="header"
        :data="ConfigurationConsentFormsActive.records_data.length == 0 ? [] : ConfigurationConsentFormsActive.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_consent_forms_view_form', {id: itemProp.id, view: true}),'configuration_consent_forms')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_deactivate_active_' + itemProp.id,
                    text: $t('deactivate'),
                    icon: require('../../../../assets/gh_new_dessign/denied.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','changeStateConfigurationConsentForms', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, $t('deactivate_form'), $t('preparing_deactivate_form'), 'deactivate')"
            />
            <GhAction
                :dataction="{
                    id: 'action_edit_active_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_consent_forms_edit_form', {id: itemProp.id}),'configuration_consent_forms')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_active_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationConsentForms', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, $t('delete_form'), $t('preparing_delete_form'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_new_professional',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_consent_forms_new_form', {}),'configuration_consent_forms')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_active",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationConsentFormsActive'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 35%;'},
                    {text: 'applies_to', field: 'applies_to', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 35%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationConsentFormsActive', '', {root: true});
            this.haveData = true;
        }
    }
</script>