import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationEquipmentsEquipments(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationEquipmentsEquipments(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationEquipmentsEquipments(state){
            await axios.get(
                API_URL + "configuration/equipments/professional"
            ).then(
                response => {
                    state.commit('loadTableConfigurationEquipmentsEquipments', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setEquipmentByProfessional(state, item){
            let request = API_URL + "configuration/equipments/professional";
            let formData = new FormData();

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationEquipmentsEquipments');
                        await this.dispatch('getConfigurationEquipmentsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeEquipmentByProfessional(state, item){
            let request = API_URL + "configuration/equipments/professional/" + item.id;

            await axios({
                method: "delete",
                url: request,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationEquipmentsEquipments');
                        await this.dispatch('getConfigurationEquipmentsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};