import Profiles from "@/view/permissions/views/view_profile.vue";

const routes = [
    {
        path: '/permissions/profile',
        name: 'permissions_new_profile',
        component: Profiles,
        props: {id: null, showMenu: true, otherTitle: 'profile'},
        children: [
            {
                path: '/permissions/profile/:id',
                name: 'permissions_edit_profile',
                component: Profiles,
                props: {id: null, showMenu: true, otherTitle: 'profile'},
            },
            {
                path: '/permissions/profile/:id/:view',
                name: 'permissions_view_profile',
                component: Profiles,
                props: {id: null, view: true, showMenu: true, otherTitle: 'profile'},
            },
        ]
    },
];

export default routes;