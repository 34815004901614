var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.refresh_translate,staticStyle:{"max-width":"100%","margin-right":"auto","margin-left":"auto"}},[(this.$route.matched[0].props.default.showMenu)?[_c('GhMenu',{key:_vm.refresh_menu,ref:"refMenu",scopedSlots:_vm._u([{key:"cabezera",fn:function(){return [_c('div',{staticClass:"logo_cabecera_square",staticStyle:{"margin-left":"8px"}},[_c('img',{staticStyle:{"max-width":"50px"},attrs:{"src":require('./assets/login/MCH_logo_menu.png')}})])]},proxy:true},(this.$route.matched[0].props.default.hiddentabs)?{key:"tabss",fn:function(){return [_c('div')]},proxy:true}:null,{key:"header-platform_info",fn:function(){return [(_vm.showUser)?_c('GhUserIcon',{attrs:{"generalConfiguration":{
                        class: 'flex-row-textt',
                        icon: require('./assets/gh_new_dessign/user.svg'),
                        title: '',
                        style: 'top: 53px; right: 20px; position:fixed;',
                        id: 'Icon_User'
                    },"bodyConfiguration":{
                        class: 'flex-row-text',
                        title: '',
                        style: '',
                        id: 'Icon_User_Body'
                    }},scopedSlots:_vm._u([(_vm.Login.responseUser.is_professional && !_vm.Login.responseUser.no_finish_register)?{key:"extra_action",fn:function(){return [_c('div',{staticClass:"link dropdown_rowhover cerrar_t14_1_",on:{"click":function($event){return _vm.goBack()}}},[_c('a',{staticClass:"t14"},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])])]},proxy:true}:null],null,true)}):_vm._e(),_c('GhHelpIcon',{attrs:{"dataBlock":{icon: require('./assets/gh_new_dessign/help.svg')}}}),_c('GhLanguage',{attrs:{"dataBlock":{
                        title: _vm.$t('select_language'),
                        id:'select_lang',
                        icon: require('./assets/gh_new_dessign/arrowdown.svg')
                    },"language":_vm.languages,"selected":_vm.idioma}}),_c('div',{staticClass:"header-phone T4"},[_vm._v(" "+_vm._s('935 950 764')+" ")]),_c('div',{staticClass:"header-email T4"},[_vm._v(" "+_vm._s('info@globalholdings2000.es')+" ")])]},proxy:true}],null,true)}),_c('div',{staticStyle:{"margin-top":"93px"}}),(!this.$route.matched[0].props.default.hiddentabs)?_c('GhLocation',{key:_vm.Tabs.TabLoading,attrs:{"dataLocation":{showButton: true, secondWindow: false, showLoader: 'OK', text: this.$route.matched[0].props.default.otherTitle !== undefined ? this.$route.matched[0].props.default.otherTitle : _vm.$t(_vm.Tabs.TabActive)},"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}):_vm._e(),_c('div',{class:this.$route.path == '/' ? '' : 'content_page',attrs:{"id":"content_page"}},[_c('router-view'),_c(_vm.popups.popup_401,{tag:"component"})],1)]:[_c('router-view')],_c('GhDeleteConfirm',{attrs:{"visible_fields":_vm.Global.state.state_confirm.item_confirm,"id_popup":'delete_confirm',"content_warning":_vm.Global.state.state_confirm.subheader_confirm},on:{"function":_vm.setDelete}}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'warning_confirm',
            title: _vm.$t('required_fields'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: _vm.$t('the_following_fields_are_missing') + ':',
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","padding-bottom":"15px"}},_vm._l((_vm.Global.state.alert_required.requireds),function(item,index){return _c('div',{key:index,staticClass:"T15_b",staticStyle:{"padding-left":"20px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.Global.closePopUp('warning_confirm');}}})]},proxy:true}])}),(_vm.Global.state.window_open)?_c('div',{staticClass:"modal-backdrop"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }