var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global"},[_c('div',{staticStyle:{"display":"flex"}},[(_vm.$root._route.path.includes('/surgery') || _vm.$root._route.path === '/my-file' || (_vm.$root._route.params.type === 'professional' && _vm.$root._route.params.view !== undefined && _vm.$root._route.params.view === 'true'))?_c('div',{staticClass:"gh-row-content",staticStyle:{"width":"150px"}},[_c('img',{attrs:{"src":_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.image : '',"height":"120"}})]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t(_vm.$root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_' + (_vm.$root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name'),
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][' + (_vm.$root._route.params.type == 'clinic' ? 'name_of_clinic' : 'name') + ']',
                                    style: 'min-width: 130px; width: 100%',
                                    value: '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t(_vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? 'name_of_clinic' : 'name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? _vm.DataClinicsProfessionals.data.initial_registration.name_of_clinic : _vm.DataClinicsProfessionals.data.initial_registration.name : ''))])]],2),_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t(_vm.$root._route.params.type == 'clinic' ? 'business_number' : 'surname') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_' + (_vm.$root._route.params.type == 'clinic' ? 'business_number' : 'surname'),
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][' + (_vm.$root._route.params.type == 'clinic' ? 'business_number' : 'surname') + ']',
                                    style: 'min-width: 130px; width: 100%',
                                    value: '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t(_vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? 'business_number' : 'surname')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? _vm.DataClinicsProfessionals.data.initial_registration.business_number : _vm.DataClinicsProfessionals.data.initial_registration.surname : ''))])]],2)])]),(_vm.$root._route.path.includes('/surgery') || _vm.$root._route.path == '/my-file' || _vm.$root._route.params.type == 'professional' || _vm.$root._route.params.type == 'professional-second-phase')?_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.path !== '/my-file' && _vm.$root._route.params.type !== 'professional-second-phase' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('md_number') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_md_number',
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][md_number]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('md_number')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.md_number : ''))])]],2),(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?_c('div',{staticStyle:{"width":"33.33%","display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('picture') + '*'))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                                id: 'register_' + _vm.$root._route.params.type + '_initial_registration_upload',
                                class: '',
                                name_hidden: 'register[' + _vm.$root._route.params.type + '][initial_registration][upload]',
                                id_hidden: 'register_' + _vm.$root._route.params.type + '_initial_registration_upload',
                                icon_attach: ''
                            },"datalabel":{
                                id: 'label_register_' + _vm.$root._route.params.type + '_initial_registration_upload',
                                style: 'margin-right: -15px'
                            },"datainput":{
                                view: false,
                                accept: 'image/png, image/gif, image/jpeg',
                                text: _vm.$t('upload'),
                                label_required: _vm.$t('picture') + '*',
                                show_image: false,
                                delete: false,
                                href: 'easyUpload',
                                file_name: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined && _vm.DataClinicsProfessionals.data.initial_registration.image !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.image.file_name : '',
                                file_path: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined && _vm.DataClinicsProfessionals.data.initial_registration.image !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.image.file_path : '',
                                file_download: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined && _vm.DataClinicsProfessionals.data.initial_registration.image !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.image.file_download : '',
                                required: true
                            }}})],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' &&  _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t(_vm.$root._route.params.type == 'clinic' ? 'social_address' : 'referral_number') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_' + (_vm.$root._route.params.type == 'clinic' ? 'address' : 'referral_number'),
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][' + (_vm.$root._route.params.type == 'clinic' ? 'address' : 'referral_number') + ']',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.referral_number : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t(_vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? 'social_address' : 'referral_number')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.$root._route.params.type == 'clinic' || _vm.$root._route.name === 'my_file_clinics' ? _vm.DataClinicsProfessionals.data.initial_registration.address : _vm.DataClinicsProfessionals.data.initial_registration.referral_number : ''))])]],2),_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 66.67%;' : 'width: 66.67%; display: flex;'),attrs:{"id":"selector_specialties"}},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'country_contact_specialties',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_specialties') + ':',
                                    text: _vm.$t('specialties'),
                                    label: _vm.$t('specialties'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: false,
                                    style: '',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                },"datainput":{
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_specialties',
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][specialties][]'
                                },"feed":_vm.getFeeds.specialties,"selected_input":_vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('specialties')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.specialties : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_email',
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][email]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.email : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.email : ''))])]],2),_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('telephone') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_telephone',
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][telephone]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.telephone : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.telephone : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false') ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.$root._route.path.includes('/surgery') && _vm.$root._route.name !== 'my_file_clinics' && _vm.$root._route.path !== '/my-file' && (_vm.$root._route.params.view === undefined || _vm.$root._route.params.view === 'false'))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('fax') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'register_' + _vm.$root._route.params.type + '_initial_registration_fax',
                                    name: 'register[' + _vm.$root._route.params.type + '][initial_registration][fax]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.fax : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('fax')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.initial_registration !== undefined ? _vm.DataClinicsProfessionals.data.initial_registration.fax : ''))])]],2)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }