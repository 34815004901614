var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?_c('form',{attrs:{"id":"form_clinic_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name_of_clinic') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_initial_registration_name_of_clinic',
                                    name: 'clinic_data[initial_registration][name_of_clinic]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.name_of_clinic : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('name_of_clinic')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.name_of_clinic : ''))])]],2),_c('div',{staticStyle:{"width":"33.33%","display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('business_number')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.business_number : ''))])])])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{class:_vm.dataBlock.type ? 'gh_text_field-label T15' : 'gh_text_field-label T15_b',staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('picture') + (_vm.dataBlock.type ? '' : '*')))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                        id: 'clinic_data_initial_registration_upload',
                        class: '',
                        name_hidden: 'clinic_data[initial_registration][upload]',
                        id_hidden: 'clinic_data_initial_registration_upload',
                        icon_attach: ''
                    },"datalabel":{
                        id: 'label_clinic_data_initial_registration_upload',
                        style: 'margin-right: -15px'
                    },"datainput":{
                        view: _vm.dataBlock.type,
                        accept: 'image/png, image/gif, image/jpeg',
                        text: _vm.$t('upload'),
                        label_required: _vm.$t('picture') + '*',
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        file_name: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.image.file_name : '',
                        file_path: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.image.file_path : '',
                        file_download: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.image.file_download : '',
                        required: true
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('social_address') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_initial_registration_address',
                                    name: 'clinic_data[initial_registration][address]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.address : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('social_address')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.address : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('telephone') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_initial_registration_telephone',
                                    name: 'clinic_data[initial_registration][telephone]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.telephone : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.telephone : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_initial_registration_email',
                                    name: 'clinic_data[initial_registration][email]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.email : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.email : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('fax') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_initial_registration_fax',
                                    name: 'clinic_data[initial_registration][fax]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.fax : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('fax')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.fax : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[(!_vm.dataBlock.type)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'clinic_data_initial_registration_departments',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_departments') + ':',
                            text: _vm.$t('departments'),
                            label: _vm.$t('departments'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: 'clinic_data_initial_registration_departments',
                            name: 'clinic_data[initial_registration][departments][]'
                        },"feed":_vm.getFeeds.departments,"selected_input":_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.departments : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('departments')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(_vm.ConfigurationClinicData.records_data.initial_registration.departments).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.dataBlock.type)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'clinic_data_initial_registration_specialties',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_specialties') + ':',
                            text: _vm.$t('specialties'),
                            label: _vm.$t('specialties'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: 'clinic_data_initial_registration_specialties',
                            name: 'clinic_data[initial_registration][specialties][]'
                        },"feed":_vm.getFeeds.specialties,"selected_input":_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(_vm.ConfigurationClinicData.records_data.initial_registration.specialties).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.dataBlock.type)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'clinic_data_initial_registration_equipments',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_equipments') + ':',
                            text: _vm.$t('equipments'),
                            label: _vm.$t('equipments'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: 'clinic_data_initial_registration_equipments',
                            name: 'clinic_data[initial_registration][equipments][]'
                        },"feed":_vm.getFeeds.equipment,"selected_input":_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? _vm.ConfigurationClinicData.records_data.initial_registration.equipments : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('equipments')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.initial_registration !== undefined && _vm.ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(_vm.ConfigurationClinicData.records_data.initial_registration.equipments).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"160px"}},[_vm._v(_vm._s(_vm.$t('main_contact_person')))])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_main_contact_person_name',
                                    name: 'clinic_data[main_contact_person][name]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.name : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.name : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('surname') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_main_contact_person_surname',
                                    name: 'clinic_data[main_contact_person][surname]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.surname : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('surname')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.surname : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_main_contact_person_email',
                                    name: 'clinic_data[main_contact_person][email]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.email : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.email : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('telephone') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'clinic_data_main_contact_person_telephone',
                                    name: 'clinic_data[main_contact_person][telephone]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.telephone : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.main_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.main_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.main_contact_person.telephone : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"160px"}},[_vm._v(_vm._s(_vm.$t('alternate_contact_person')))])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'clinic_data_alternate_contact_person_name',
                                    name: 'clinic_data[alternate_contact_person][name]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.name : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.name : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('surname') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'clinic_data_alternate_contact_person_surname',
                                    name: 'clinic_data[alternate_contact_person][surname]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.surname : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('surname')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.surname : ''))])]],2)])]),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'clinic_data_alternate_contact_person_email',
                                    name: 'clinic_data[alternate_contact_person][email]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.email : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.email : ''))])]],2),_c('div',{style:(!_vm.dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;')},[(!_vm.dataBlock.type)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('telephone') + '*',
                                    style:'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'clinic_data_alternate_contact_person_telephone',
                                    name: 'clinic_data[alternate_contact_person][telephone]',
                                    style: 'min-width: 130px; width: 100%',
                                    value: _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.telephone : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","min-width":"150px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('telephone')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined && _vm.ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? _vm.ConfigurationClinicData.records_data.alternate_contact_person.telephone : ''))])]],2)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }