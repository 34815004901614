var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_consent_form',
                title: _vm.$t('consent_forms')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_consent_form"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'consent_forms_code',
                                        name: 'consent_forms[code]',
                                        style: 'width: 200px',
                                        value: _vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.code : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'consent_forms_name',
                                        name: 'consent_forms[name]',
                                        style: 'min-width: 200px; width: 100%',
                                        value: _vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.name : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'consent_forms_description',
                                        name: 'consent_forms[description]',
                                        value: _vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.description : ''
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'consent_forms_departments',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_departments') + ':',
                                        text: _vm.$t('departments'),
                                        label: _vm.$t('departments'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'consent_forms_departments',
                                        name: 'consent_forms[departments][]'
                                    },"feed":_vm.getFeeds.departments,"selected_input":_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.departments : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('departments') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? Object.values(_vm.ConfigurationConsentForms.data.departments).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'consent_forms_specialties',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_specialties') + ':',
                                        text: _vm.$t('specialties'),
                                        label: _vm.$t('specialties'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'consent_forms_specialties',
                                        name: 'consent_forms[specialties][]'
                                    },"feed":_vm.getFeeds.specialties,"selected_input":_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? Object.values(_vm.ConfigurationConsentForms.data.specialties).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'consent_forms_procedure',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_procedure') + ':',
                                        text: _vm.$t('procedure'),
                                        label: _vm.$t('procedure'),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'consent_forms_procedure',
                                        name: 'consent_forms[procedure]'
                                    },"feed":_vm.getFeeds.procedure,"selected_input":_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.procedure : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? Object.values(_vm.ConfigurationConsentForms.data.procedure).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{attrs:{"datalabel":{
                                        id: 'consent_forms_go_live_date',
                                        text: _vm.$t('go_live_date'),
                                        style: 'width: 150px; min-width: 150px; text-align: right;'
                                    },"datainput":{
                                        id: 'consent_forms_go_live_date',
                                        name: 'consent_forms[go_live_date]',
                                        value: _vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.go_live_date : '',
                                        required: false
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('go_live_date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationConsentForms.data !== undefined ? _vm.ConfigurationConsentForms.data.go_live_date : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultiUpload',{attrs:{"datalabel":{
                                        text: _vm.$t('documents'),
                                        text_popup: _vm.$t('documents'),
                                        input_text: _vm.$t('name'),
                                        textarea_text: _vm.$t('description'),
                                        attach_text: _vm.$t('attach'),
                                        content_warning: '',
                                        label_required: _vm.$t('documents'),
                                        required: false,
                                    },"datablock":{
                                        id: 'consent_forms_documents',
                                        name: 'consent_forms[documents]',
                                        style: '',
                                        class: 'T15_b',
                                        icon_attach: '',
                                        icon_trash: '',
                                        icon_download: '',
                                        href: _vm.dispatchEvidence,
                                    },"defaultfiles":_vm.ConfigurationConsentForms.data !== undefined && _vm.ConfigurationConsentForms.data.documents !== undefined ? _vm.ConfigurationConsentForms.data.documents : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('documents') + ':'))]),_vm._l((_vm.ConfigurationConsentForms.data.documents),function(file,index){return _c('div',{key:index},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_c('a',{staticClass:"C1 F5",attrs:{"href":file.file_download}},[_vm._v(_vm._s(file.name))]),_vm._v(_vm._s(index != Object.keys(_vm.ConfigurationConsentForms.data.documents).pop() ? ', ' : ''))])])})]],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitConsentForm();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }