<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <GhTableCommon
                    style="margin-top: 10px;"
                    :extratable="{
                        id: 'table_' + dataItem.item.config.label.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_item_' + dataItem.id + '_' + indexItem
                    }"
                    :header="dataItem.item.config !== undefined && dataItem.item.config.table !== undefined && dataItem.item.config.table.header !== undefined ? dataItem.item.config.table.header : []"
                    :data="dataItem.item.data !== undefined && dataItem.item.data.content !== undefined ? dataItem.item.data.content : []"
                >
                    <template v-slot:field_component="{itemProp, labelProp}">
                        <GhInputTextFied
                            style="margin-top: -2px; margin-bottom: -2px;"
                            :datalabel="{text: '', style: '', class: '', id: ''}"
                            :datainput="{
                                required: labelProp.required,
                                label_required: labelProp.text,
                                id: 'input_' + dataItem.item.config.table.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                                name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]',
                                value: itemProp[labelProp.field]
                            }"
                            @change="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);"
                        />
                    </template>
                </GhTableCommon>
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhInputTextFied from "fe-gh-inputtextfield-lib";

    export default {
        name: 'view_table',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhTableCommon,
            GhInputTextFied
        },
        data(){
            return{
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false
            }
        }
    }
</script>