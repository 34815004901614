import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationVariableProfessionalTypes(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationVariableProfessionalTypes(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationVariableProfessionalTypes(state){
            await axios.get(
                API_URL + "configuration/variable/professional-types"
            ).then(
                response => {
                    state.commit('loadTableConfigurationVariableProfessionalTypes', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationVariableProfessionalType(state, item){
            await axios.get(
                API_URL + "configuration/variable/professional-types/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationVariableProfessionalTypes', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationVariableProfessionalType(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/variable/professional-types/" + item.id : API_URL + "configuration/variable/professional-types";
            let formData = new FormData();

            formData.append('professional_type[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            return await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        return 'OK'
                    }
                    state.dispatch('getConfigurationVariableProfessionalTypes');
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationVariableProfessionalTypes(state, item){
            let requestLogin = API_URL + "configuration/variable/professional-types/" + item.id + "/remove";
            let formData = new FormData();

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationVariableProfessionalTypes');
                        await this.dispatch('getConfigurationVariableSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanConfigurationVariableProfessionalTypes(state){
            state.commit('loadDataConfigurationVariableProfessionalTypes', []);
        }
    }
};