<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'missing_',
        }"
        :header="header"
        :data="WarningsMissing.records_data.length == 0 ? [] : WarningsMissing.records_data"
    >
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_missing",
        components: {
            GhTableCommon,
        },
        computed: {
            ...mapState(['WarningsMissing'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'document', field: 'document', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 50%'},
                    {text: 'patient_tutor', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 50%;'},

                ],
            }
        },
        async beforeMount(){
            this.$store.dispatch('getWarningsMissingList', '', {root: true});
            this.haveData = true;
        }
    }
</script>