import TypeEquipment from "@/view/configuration/variable/views/view_type_equipment.vue";

const routes = [
    {
        path: '/configuration/variable/type-equipment',
        name: 'configuration_variable_new_type_equipment',
        component: TypeEquipment,
        props: {id: null, showMenu: false, background: true, otherTitle: 'equipments_type'},
        children: [
            {
                path: '/configuration/variable/type-equipment/:id',
                name: 'configuration_variable_edit_type_equipment',
                component: TypeEquipment,
                props: {id: null, showMenu: false, background: true, otherTitle: 'equipments_type'},
            },
            {
                path: '/configuration/variable/type-equipment/:id/:view',
                name: 'configuration_variable_view_type_equipment',
                component: TypeEquipment,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'equipments_type'},
            }
        ],
    },

];

export default routes;