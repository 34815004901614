var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('my_file') + ' / ' + _vm.$t('clinic')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_clinic',
                title: _vm.$t('clinic')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.initial_registration,{tag:"component"})]},proxy:true}])}),_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.MyFileClinics.section_info[label['type']].show)?_c('GhDropSelector',{attrs:{"datablock":{
                    id: 'sections_my_file_clinic_'+label.id,
                    title: _vm.$t(label['name']),
                    second_title: _vm.$t(_vm.MyFileClinics.section_info[label['type']]['second_text']),
                    num_results: _vm.MyFileClinics.section_info[label['type']]['result'],
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)})],2),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close',
                text: _vm.$t('close'),
            }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }