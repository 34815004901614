<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $root._route.params.id !== undefined ? $t('edit_condition') : $t('add_condition')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_condition',
                    title: $t('condition')
                }"
            >
                <template v-slot:content>
                    <form id="form_condition" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('id') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'condition_code',
                                        name: 'condition[code]',
                                        style: 'width: 300px',
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.code : '',
                                        type: 'text',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'condition_name',
                                        name: 'condition[name]',
                                        style: 'width: 300px',
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.name : '',
                                        type: 'text',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 150px; min-width: 150px; text-align: right; margin-right: 11px;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'condition_description',
                                        name: 'condition[description]',
                                        required: false,
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.description : '',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('base_percentage') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'condition_base_percentage',
                                        name: 'condition[base_percentage]',
                                        style: 'width: 150px',
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.base_percentage : '',
                                        type: 'int',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('modifier')}}</label>
                            </div>
                            <!-- INIT MODIFIER FOR -->
                            <div v-for="(item,index) in modifier" :key="index">
                                <div class="gh-row-content">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_condition_modifier_genre_' + index,
                                            class: 'T15_b',
                                            subtitle: $t('select_genre') + ':',
                                            text: $t('genre'),
                                            label: $t('genre'),
                                            type: 'radio',
                                            columns: 3,
                                            required: false,
                                            style: 'margin-left: 112px;',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'condition_modifier_genre_' + index,
                                            name: 'condition[modifier][' + index + '][genre]'
                                        }"
                                        :feed="getFeeds.genre"
                                        :selected_input="item.genre !== undefined ? item.genre : []"
                                        :key="refreshSelector"
                                    />
                                </div>
                                <div v-for="(subItem,subIndex) in item.age" class="gh-row-content" :key="subIndex">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('age')}}</label>
                                    <GhInputTextField
                                        :datalabel="{
                                            text: getFeeds.age_conditions.symbols[Object.keys(subItem.age_select_first)[0]] + ' ' + subItem.age_input_first + ' ' + (subItem.age_input_first == 1 ? $t('year') : $t('years')) + (subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0 ? ' ' + $t('and').toLowerCase() + ' ' + getFeeds.age_conditions.symbols[Object.keys(subItem.age_select_second)[0]]  + ' ' + subItem.age_input_second + ' ' + (subItem.age_input_second == 1 ? $t('year') : $t('years')) : ''),
                                            style: 'width: auto; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'condition_modifier_' + index + '_age_' + subIndex,
                                            name: 'condition[modifier][' + index + '][age][' + subIndex + '][value]',
                                            style: 'width: 150px',
                                            value: subItem.value !== undefined ? subItem.value : '',
                                            type: 'int',
                                        }"
                                    />
<!--                                    <GhAction style="text-align: right; width: 150px;"-->
<!--                                        :dataction="{-->
<!--                                            id: 'delete_age',-->
<!--                                            text: $t('delete_age'),-->
<!--                                        }"-->
<!--                                        @click="deleteAge(index,subIndex)"-->
<!--                                    />-->
                                    <input type="hidden" :name="'condition[modifier][' + index + '][age][' + subIndex + '][age_select_first]'" :value="Object.keys(subItem.age_select_first)[0]">
                                    <input type="hidden" :name="'condition[modifier][' + index + '][age][' + subIndex + '][age_input_first]'" :value="subItem.age_input_first">
                                    <input v-if="subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0" type="hidden" :name="'condition[modifier][' + index + '][age][' + subIndex + '][age_select_second]'" :value="Object.keys(subItem.age_select_second)[0]">
                                    <input v-if="subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0" type="hidden" :name="'condition[modifier][' + index + '][age][' + subIndex + '][age_input_second]'" :value="subItem.age_input_second">
                                </div>
                                <div class="gh-row-content">
                                    <GhAction style="text-align: right; width: 175px;"
                                        :dataction="{
                                            id: 'add_age_' + index,
                                            text: $t('add_age'),
                                        }"
                                        @click="Global.openPopUp( 'popup_add_age');actualIndex = index;"
                                    />
                                </div>
                            </div>
                            <div class="gh-row-content">
                                <GhAction style="text-align: right; width: 150px;"
                                    :dataction="{
                                        id: 'add_modifier',
                                        text: $t('add_modifier'),
                                    }"
                                    @click="modifier.push({age:[]})"
                                />
                            </div>
                            <!-- END MODIFIER FOR -->
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('booking_time')}}</label>
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('from_hour') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'condition_from_hour',
                                        name: 'condition[from_hour]',
                                        style: 'width: 150px',
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.from_hour : '',
                                        type: 'text',
                                    }"
                                />
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('to_hour') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'condition_to_hour',
                                        name: 'condition[to_hour]',
                                        style: 'width: 150px',
                                        value: ConfigurationPriorityConditions.data !== undefined ? ConfigurationPriorityConditions.data.to_hour : '',
                                        type: 'text',
                                    }"
                                />
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
        </div>

        <div class="centered" style="margin-top: 10px;">
            <GhButton v-if="!this.$root._route.params.view"
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="submitCondition();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="Global.windowClose()"
            />
        </div>
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_add_age',
                title: $t('add_age'),
                type: 'add',
                style: 'width: 90%; min-width:920px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <span class="T15_b">{{$t('specify_correct_ages') + ':'}}</span>
                </div>
                <form id="check_popup_age">
                    <div class="gh-content-global" :key="refreshPopup" style="display: flex">
                        <div class="gh-row-content">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_age_select_first',
                                    class: 'T15_b',
                                    subtitle: $t('select_age') + ':',
                                    text: $t('age_is') + '*',
                                    label: $t('age_is'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left: 10px;',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                }"
                                :datainput="{
                                    id: 'age_select_first',
                                    name: 'popup[age_select_first]'
                                }"
                                :feed="getFeeds.age_conditions.labels"
                                :selected_input="[]"
                                ref="age_select_first"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('age') + '*',
                                    style: 'width: 100px; min-width: 100px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'age_input_first',
                                    name: 'popup[age_input_first]',
                                    style: 'width: 150px',
                                    value: '',
                                    type: 'int',
                                }"
                                ref="age_input_first"
                            />
                            <label class="gh_text_field-label T15_b" style="margin-left: 10px;width: 50px; min-width: 50px; text-align: center;">{{$t('and').toUpperCase()}}</label>
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_age_select_second',
                                    class: 'T15_b',
                                    subtitle: $t('select_age') + ':',
                                    text: $t('age_is'),
                                    label: $t('age_is'),
                                    type: 'radio',
                                    columns: 3,
                                    required: false,
                                    style: 'margin-left: 10px;',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                }"
                                :datainput="{
                                    id: 'age_select_second',
                                    name: 'popup[age_select_second]'
                                }"
                                :feed="getFeeds.age_conditions.labels"
                                :selected_input="[]"
                                ref="age_select_second"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('age'),
                                    style: 'width: 100px; min-width: 100px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'age_input_second',
                                    name: 'popup[age_input_second]',
                                    style: 'width: 150px',
                                    value: '',
                                    type: 'int',
                                }"
                                ref="age_input_second"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_save_flow',
                        text: $t('accept'),
                    }"
                    @click="addAge"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_flow',
                        text: $t('close'),
                    }"
                    @click="Global.closePopUp( 'popup_add_age');refreshPopup++;"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import {loadLanguageAsync} from "@/main";
    import GhAction from "fe-gh-action-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "view_condition",
        components:{
            GhButton,
            GhPopUp,
            GhDataSelector,
            GhAction,
            GhTextarea,
            GhInputTextField,
            GhNoDropSelector,
            GhLocation
        },
        computed: {
            ...mapState(['getFeeds','ConfigurationPriorityConditions'])
        },
        data(){
            return{
                haveData: false,
                refreshPopup: 0,
                actualIndex: 0,
                refreshSelector: 0,
                modifier: [{age:[]}],
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);

            await this.$store.dispatch('getFeedAgeConditions', '', {root: true});
            await this.$store.dispatch('getFeedGenre', '', {root: true});

            this.ConfigurationPriorityConditions.data = [];
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getConfigurationConditionsData', {id:this.$root._route.params.id}, {root: true});
                this.modifier = this.ConfigurationPriorityConditions.data.modifier;
            }
            this.haveData = true;
        },
        methods:{
            addAge(){
                if(this.Global.checkRequired('check_popup_age') == 'OK') {
                    var age_select_first = this.$refs.age_select_first.checkdata;
                    var age_input_first = this.$refs.age_input_first.$el.children[1].value;
                    var age_select_second = this.$refs.age_select_second.checkdata;
                    var age_input_second = this.$refs.age_input_second.$el.children[1].value;
                    this.modifier[this.actualIndex].age.push({
                        age_select_first: age_select_first,
                        age_input_first: age_input_first,
                        age_select_second: age_select_second,
                        age_input_second: age_input_second
                    });

                    this.Global.closePopUp('popup_add_age');
                    this.refreshPopup++;
                }
            },
            deleteModifier(index){
                delete this.modifier[index];
                this.modifier = this.modifier.filter((n) => n);
                this.refreshSelector++;
            },
            deleteAge(index,subIndex){
                delete this.modifier[index].age[subIndex];
                this.modifier[index].age = this.modifier[index].age.filter((n) => n);
            },
            async submitCondition(){
                if(this.Global.checkRequired('form_condition') == 'OK'){
                    const formData = new FormData(document.getElementById('form_condition'));
                    await this.$store.dispatch('setDataConfigurationCondition', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>