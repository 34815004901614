<template>
    <GhTableCommon
        :extratable="{
            id: 'table_clinics'
        }"
        :header="header"
        :data="MyFile.data.clinics"
        style="margin-top: 12px;"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_leave_' + itemProp.id,
                    text: $t('leave'),
                    icon: require('../../../assets/gh_new_dessign/denied.svg')
                }"
                @click="leaveCenter(itemProp)"
            />
            <GhAction
                :dataction="{
                    id: 'action_view_' + itemProp.id,
                    text: $t('view'),
                    icon: require('../../../assets/gh_new_dessign/view.svg')
                }"
                @click="Global.windowOpen(Global.openSecondWindow('my_file_clinics', {id: itemProp.id}),'my_file_clinics')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_clinics",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['MyFile']),
        },
        data(){
            return {
                header: [
                    {text: 'name_of_clinic', field: 'name_of_clinic', sorting: true, typeSort: 'code', style: 'max-width: 100px;width: 50%;'},
                    {text: 'social_address', field: 'address', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 50%;'},
                    {text: 'telephone', field: 'telephone', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;width: 100px;'},
                    {text: '', field: 'actions', sorting: false, style: 'min-width: 60px;'}
                ]
            }
        },
        methods: {
            leaveCenter(item){
                this.$parent.$parent.center = item.name_of_clinic;
                this.$parent.$parent.leave_id = item.id;
                this.Global.openPopUp('leave_confirm');
            }
        }
    }
</script>