import Submits from "@/view/submits/sections/sections_submits.vue";
import SubmitsClinicProfessional from "@/view/submits/sections/sections_clinic_professional_view.vue";

const routes = [
    {
        path: '/submits',
        name: 'submits',
        props: {showMenu: true},
        component: Submits
    },
    {
        path: '/submits/:type/:id/:view',
        name: 'view_submit',
        props: {showMenu: false, background: true, id: null, view: null, type: null},
        component: SubmitsClinicProfessional
    },
];

export default routes;