var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_open',title: _vm.$t('open')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.view_open,{key:_vm.refreshKey,tag:"component",attrs:{"dataBlock":{type: _vm.view}}})]},proxy:true},(_vm.view)?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_business',
                    text: _vm.$t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }},on:{"click":_vm.changeState}})]},proxy:true}:null],null,true)}),_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.ConfigurationCalendar.section_info[label['type']].show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'configuration_calendar_' + label.id,
                title: _vm.$t(label['name']),
                second_title: _vm.$t(_vm.ConfigurationCalendar.section_info[label['type']]['second_text']),
                num_results: _vm.ConfigurationCalendar.section_info[label['type']]['result'],
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }