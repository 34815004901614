import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        list_enable_disable_form: [],
    },
    mutations: {
        loadConfigurationEnableDisableForm(state, payload) {
            state.list_enable_disable_form = payload;
        },
        loadDataConfigurationCreationForm(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getConfigurationEnableDisableForm(state){
            await axios.get(
                API_URL + "configuration/forms/enable-disable-form"
            ).then(
                response => {
                    state.commit('loadConfigurationEnableDisableForm', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async copyConfigurationForm(state, item){
            let requestLogin = API_URL + "configuration/forms/enable-disable-form/" + item.id + "/copy";
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationEnableDisableForm');
                        await state.dispatch('getConfigurationCreationForm');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async enableDisableConfigurationForm(state, item){
            let requestLogin = API_URL + "configuration/forms/enable-disable-form/" + item.id + "/" + item.type;
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationEnableDisableForm');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};