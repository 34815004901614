import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        section_info: '',
    },
    mutations: {
        loadConfigurationConsentFormsInfo(state, payload) {
            state.section_info = payload;
        },
        loadDataConfigurationConsentFormsTypes(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationConsentFormsSections(state){
            await axios.get(
                API_URL + "configuration/consent-forms"
            ).then(
                response => {
                    state.commit('loadConfigurationConsentFormsInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationConsentForms(state, item){
            await axios.get(
                API_URL + "configuration/consent-forms/form/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationConsentFormsTypes', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationConsentForms(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/consent-forms/form/" + item.id : API_URL + "configuration/consent-forms/form";
            let formData = new FormData();

            formData.append('consent_form[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.dispatch('getConfigurationConsentFormsSections');
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationConsentForms(state, item){
            let requestLogin = API_URL + "configuration/consent-forms/form/" + item.id + "/remove";
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationConsentFormsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async changeStateConfigurationConsentForms(state, item){
            let requestLogin = API_URL + "configuration/consent-forms/form/" + item.id + "/" + item.type;
            let formData = new FormData();

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationProfessionalsSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};