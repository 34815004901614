<template>
    <GhTableCommon
        :extratable="{
            id: 'upcoming_patients_by_professional'
        }"
        :header="VisitsUpcomingPatientsByProfessional.records_data !== undefined && VisitsUpcomingPatientsByProfessional.records_data.header !== undefined ? VisitsUpcomingPatientsByProfessional.records_data.header.columns : []"
        :data="VisitsUpcomingPatientsByProfessional.records_data.data"
        style="margin-top: 12px;"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <div :title="itemProp[labelProp]['name']" class="table-cell-full">
                <div style="padding-top: 7px; padding-left: 10px;" class="ellipsis" :style="itemProp[labelProp]['style']">
                    <template v-if="itemProp[labelProp]['active']">
                        <router-link :to="{name: 'process', params: {process_id: itemProp[labelProp].process, id: itemProp[labelProp].id}}">
                            <a class="table-cell_link T13" :style="itemProp[labelProp]['style']">{{itemProp[labelProp]['name']}}</a>
                        </router-link>
                    </template>
                    <template v-else>
                        {{itemProp[labelProp]['name']}}
                    </template>
                </div>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapState} from "vuex";

    export default {
        name: "view_today_upcoming_patient_by_professional",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['VisitsUpcomingPatientsByProfessional'])
        },
        data(){
            return{
                colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'},
            }
        },
        mounted(){
            if(this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1] == 'today') {
                this.$parent.$parent.$parent.upcomingDataSelected = '';
            }

            if(this.VisitsUpcomingPatientsByProfessional.records_data !== undefined && this.VisitsUpcomingPatientsByProfessional.records_data.header !== undefined){
                var to_for = Object.values(this.VisitsUpcomingPatientsByProfessional.records_data.header.values);

                for(var i = 0;i < to_for.length;i++){
                    document.getElementById('list_header_upcoming_patients_by_professional_' + i).style.backgroundColor = this.colors[to_for[i]];
                }
            }
        },
    }
</script>
