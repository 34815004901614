var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_page",staticStyle:{"padding-top":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'drop_section_register_info',
            title: _vm.$t('registration_accreditation'),
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_acreditation"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('type_of_client') + '*'))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                required: true,
                                label_required: _vm.$t('type_of_client') + '*',
                                type: 'radio',
                                id: 'acreditation_type_of_client',
                                value: 1,
                                name: 'acreditation[type_of_client]',
                                selected: '',
                            }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"25px"}},[_vm._v(_vm._s(_vm.$t('clinic')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                required: true,
                                label_required: _vm.$t('type_of_client') + '*',
                                type: 'radio',
                                id: 'acreditation_type_of_client',
                                value: 2,
                                name: 'acreditation[type_of_client]',
                                selected: '',
                            }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('professional')))])],1),_c('div',[_c('p',{staticClass:"T15"},[_vm._v(_vm._s(_vm.$t('label_acreditation_0')))])]),_c('br'),_c('div',[_c('p',{staticClass:"T15"},[_vm._v(_vm._s(_vm.$t('label_acreditation_1')))])]),_c('br'),_c('div',[_c('p',{staticClass:"T15"},[_vm._v(_vm._s('Montreal Children\'s Hospital (MCH)'))])]),_c('div',[_c('p',{staticClass:"T15_b"},[_vm._v(_vm._s('info@globalholdings2000.es'))])]),_c('div',[_c('p',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('tel') + ': 935 950 764'))])]),_c('br'),_c('div',[_c('p',{staticClass:"T15"},[_vm._v(_vm._s(_vm.$t('legal_advice_2').toUpperCase() + ': ' + _vm.$t('label_acreditation_2')))])])])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('request_registration'),class: 'container-md_button text-inline t13', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.register();}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }