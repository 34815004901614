<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationItems.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_items_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationItems.section_info[label['type']]['second_text']),
                    num_results: ConfigurationItems.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_standard_item from "@/view/configuration/items/list/list_standard_item.vue";
    import list_examination_items from "@/view/configuration/items/list/list_examination_items.vue";

    export default {
        name: "sections_items",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationItems']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'standard_item', component: list_standard_item, type: 'standard_item'},
                    {id: 2, name: 'examination_item', component: list_examination_items, type: 'examination_item'}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationItemsSections','',{root:true});
            this.haveData = true;
        }
    }
</script>