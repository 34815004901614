<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationEquipments.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_equipments_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationEquipments.section_info[label['type']]['second_text']),
                    num_results: ConfigurationEquipments.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>

            <template v-if="ConfigurationEquipments.section_info[label['type']].show && label['type'] == 'equipments'">
                <component v-bind:is="popup_equipments"/>
            </template>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_equipments from "@/view/configuration/equipments/lists/list_equipments.vue";
    import popup_equipments from "@/view/configuration/equipments/templates/popup_equipments.vue";

    export default {
        name: "sections_equipments",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationEquipments']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: this.$t('equipments'), component: list_equipments, type: 'equipments'},
                ],
                popup_equipments: popup_equipments
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationEquipmentsSections','',{root:true});

            if(this.ConfigurationEquipments.section_info.equipments.show){
                await this.$store.dispatch('getFeedEquipment','',{root:true});
            }

            this.haveData = true;
        }
    }
</script>