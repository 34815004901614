<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_professional_active_',
        }"
        :header="header"
        :data="ConfigurationProfessionalsActive.records_data.length === 0 ? [] : ConfigurationProfessionalsActive.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="viewProfessionalsActive(itemProp.id)"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_deactivate_active_' + itemProp.id,
                    text: $t('deactivate'),
                    icon: require('../../../../assets/gh_new_dessign/denied.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','changeStateConfigurationProfessionals', itemProp.id,{
                    'code': itemProp.code,
                    'name': itemProp.name,
                    'surname': itemProp.surname,
                    'specialties': itemProp.specialties,
                    'status': itemProp.status
                }, $t('deactivate_professional'), $t('preparing_deactivate_professional'), 'deactivate')"
            />
            <GhAction
                :dataction="{
                    id: 'action_edit_active_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="editProfessionalsActive(itemProp.id)"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_active_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationProfessionals', itemProp.id,{
                    'code': itemProp.code,
                    'name': itemProp.name,
                    'surname': itemProp.surname,
                    'specialties': itemProp.specialties,
                    'status': itemProp.status
                }, $t('delete_professional'), $t('preparing_delete_professional'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_new_professional',
                        text: $t('add')
                    }"
                    @click="addProfessionalsActive()"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_active",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationProfessionalsActive'])
        },
        data(){
            return{
                haveData: false,
                timer: [],
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'specialties', field: 'specialties', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 50%;'},
                    {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationProfessionalsActive', '', {root: true});
            this.haveData = true;
        },
        methods: {
            addProfessionalsActive(){
                this.Global.windowOpen(
                    this.Global.openSecondWindow('configuration_professionals_new_professional', {}),'configuration_professionals_professional', true
                );

                this.updatedParentWindow();
            },
            editProfessionalsActive(id){
                this.Global.windowOpen(
                    this.Global.openSecondWindow(
                        'configuration_professionals_edit_professional', {
                            id: id
                        }),'configuration_professionals_professional', true
                );

                this.updatedParentWindow();
            },
            viewProfessionalsActive(id){
                this.Global.windowOpen(
                    this.Global.openSecondWindow('configuration_professionals_view_professional', {
                        id: id,
                        view: true
                    }),'configuration_professionals_professional', true
                );

                this.updatedParentWindow();
            },
            updatedParentWindow(){
                var then = this;
                window.UpdatedSelect = async function(e){
                    if(e === 'OK'){
                        await then.$store.dispatch('getConfigurationProfessionalsActive', '', {root: true});
                        await then.$store.dispatch('getConfigurationProfessionalsSections', '', {root: true});
                    }
                }

                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            checkChildWindow() {
                if (this.Global.state.window.closed) {
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>