<template>
    <div>
        <form id="form_clinic_data" v-if="haveData">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name_of_clinic') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_initial_registration_name_of_clinic',
                                        name: 'clinic_data[initial_registration][name_of_clinic]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.name_of_clinic : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('name_of_clinic')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.name_of_clinic : ''}}</label>
                            </template>
                        </div>
                        <div style="width: 33.33%; display: flex;">
                            <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('business_number')}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.business_number : ''}}</label>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <label :class="dataBlock.type ? 'gh_text_field-label T15' : 'gh_text_field-label T15_b'" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture') + (dataBlock.type ? '' : '*')}}</label>
                    <GhEasyUpload
                        :extrablock="{
                            id: 'clinic_data_initial_registration_upload',
                            class: '',
                            name_hidden: 'clinic_data[initial_registration][upload]',
                            id_hidden: 'clinic_data_initial_registration_upload',
                            icon_attach: ''
                        }"
                        :datalabel="{
                            id: 'label_clinic_data_initial_registration_upload',
                            style: 'margin-right: -15px'
                        }"
                        :datainput="{
                            view: dataBlock.type,
                            accept: 'image/png, image/gif, image/jpeg',
                            text: $t('upload'),
                            label_required: $t('picture') + '*',
                            show_image: false,
                            delete: false,
                            href: 'easyUpload',
                            file_name: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.image.file_name : '',
                            file_path: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.image.file_path : '',
                            file_download: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.image.file_download : '',
                            required: true
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('social_address') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_initial_registration_address',
                                        name: 'clinic_data[initial_registration][address]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.address : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('social_address')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.address : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('telephone') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_initial_registration_telephone',
                                        name: 'clinic_data[initial_registration][telephone]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.telephone : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('telephone')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.telephone : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_initial_registration_email',
                                        name: 'clinic_data[initial_registration][email]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.email : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('email')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.email : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('fax') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_initial_registration_fax',
                                        name: 'clinic_data[initial_registration][fax]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.fax : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('fax')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.fax : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <template v-if="!dataBlock.type">
                        <GhDataSelector
                            :datablock="{
                                id: 'clinic_data_initial_registration_departments',
                                class: 'T15_b',
                                subtitle: $t('select_departments') + ':',
                                text: $t('departments'),
                                label: $t('departments'),
                                type: 'checkbox',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: 'clinic_data_initial_registration_departments',
                                name: 'clinic_data[initial_registration][departments][]'
                            }"
                            :feed="getFeeds.departments"
                            :selected_input="ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.departments : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('departments')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(ConfigurationClinicData.records_data.initial_registration.departments).join() : ''}}</label>
                    </template>
                </div>
                <div class="gh-row-content">
                    <template v-if="!dataBlock.type">
                        <GhDataSelector
                            :datablock="{
                                id: 'clinic_data_initial_registration_specialties',
                                class: 'T15_b',
                                subtitle: $t('select_specialties') + ':',
                                text: $t('specialties'),
                                label: $t('specialties'),
                                type: 'checkbox',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: 'clinic_data_initial_registration_specialties',
                                name: 'clinic_data[initial_registration][specialties][]'
                            }"
                            :feed="getFeeds.specialties"
                            :selected_input="ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.specialties : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(ConfigurationClinicData.records_data.initial_registration.specialties).join() : ''}}</label>
                    </template>
                </div>
                <div class="gh-row-content">
                    <template v-if="!dataBlock.type">
                        <GhDataSelector
                            :datablock="{
                                id: 'clinic_data_initial_registration_equipments',
                                class: 'T15_b',
                                subtitle: $t('select_equipments') + ':',
                                text: $t('equipments'),
                                label: $t('equipments'),
                                type: 'checkbox',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;',
                            }"
                            :datainput="{
                                id: 'clinic_data_initial_registration_equipments',
                                name: 'clinic_data[initial_registration][equipments][]'
                            }"
                            :feed="getFeeds.equipment"
                            :selected_input="ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? ConfigurationClinicData.records_data.initial_registration.equipments : []"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipments')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationClinicData.records_data.initial_registration !== undefined && ConfigurationClinicData.records_data.initial_registration !== undefined ? Object.values(ConfigurationClinicData.records_data.initial_registration.equipments).join() : ''}}</label>
                    </template>
                </div>
                <div class="gh-row-content">
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('main_contact_person')}}</label>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_main_contact_person_name',
                                        name: 'clinic_data[main_contact_person][name]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.name : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('name')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.name : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('surname') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_main_contact_person_surname',
                                        name: 'clinic_data[main_contact_person][surname]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.surname : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('surname')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.surname : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_main_contact_person_email',
                                        name: 'clinic_data[main_contact_person][email]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.email : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('email')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.email : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('telephone') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'clinic_data_main_contact_person_telephone',
                                        name: 'clinic_data[main_contact_person][telephone]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.telephone : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('telephone')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.main_contact_person !== undefined && ConfigurationClinicData.records_data.main_contact_person !== undefined ? ConfigurationClinicData.records_data.main_contact_person.telephone : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{$t('alternate_contact_person')}}</label>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'clinic_data_alternate_contact_person_name',
                                        name: 'clinic_data[alternate_contact_person][name]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.name : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('name')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.name : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('surname') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'clinic_data_alternate_contact_person_surname',
                                        name: 'clinic_data[alternate_contact_person][surname]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.surname : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('surname')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.surname : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-row-content">
                    <div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'clinic_data_alternate_contact_person_email',
                                        name: 'clinic_data[alternate_contact_person][email]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.email : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('email')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.email : ''}}</label>
                            </template>
                        </div>
                        <div :style="!dataBlock.type ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                            <template v-if="!dataBlock.type">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('telephone') + '*',
                                        style:'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'clinic_data_alternate_contact_person_telephone',
                                        name: 'clinic_data[alternate_contact_person][telephone]',
                                        style: 'min-width: 130px; width: 100%',
                                        value: ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.telephone : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('telephone')}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{ConfigurationClinicData.records_data.alternate_contact_person !== undefined && ConfigurationClinicData.records_data.alternate_contact_person !== undefined ? ConfigurationClinicData.records_data.alternate_contact_person.telephone : ''}}</label>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";

    export default {
        name: "view_clinic_data",
        props: ['dataBlock'],
        components: {
            GhDataSelector,
            GhEasyUpload,
            GhInputTextField
        },
        computed:{
            ...mapState(['getFeeds','ConfigurationClinicData'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>

<style>
    #clinic_data_initial_registration_departments_selector_label, #clinic_data_initial_registration_specialties_selector_label, #clinic_data_initial_registration_equipments_selector_label{
        width: 150px;
        text-align: end;
    }
</style>