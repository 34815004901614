var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_support_assignment'
    },"header":_vm.header,"data":_vm.ConfigurationSupportAssignment.records_data.length == 0 ? [] : _vm.ConfigurationSupportAssignment.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_support_view_assignment', {id: itemProp.id, view: true}),'configuration_support_assignment')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_assignment_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_support_edit_assignment', {id: itemProp.id}),'configuration_support_assignment')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_assignment_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationSupportAssignments', itemProp.id,{'assignment': itemProp.name}, _vm.$t('delete_assignment'), _vm.$t('preparing_delete_assignment'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_conf_supp_assignment',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_support_new_assignment', {}),'configuration_support_assignment')}}})],1)]},proxy:true}],null,false,1463861502)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }