<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'surgery_patients_',
        }"
        :header="header"
        :data="WarningsSurgery.records_data.length == 0 ? [] : WarningsSurgery.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <template v-if="!itemProp.schedule">
                <GhAction
                    :dataction="{
                        id: 'action_view_surgery_patient_' + itemProp.id,
                        text: $t('view'),
                        icon: require('../../../assets/gh_new_dessign/view.svg'),
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')"
                />
            </template>
            <template v-else>
                <GhAction
                    :dataction="{
                        id: 'action_schedule_surgery_patient_' + itemProp.id,
                        text: $t('schedule'),
                        icon: require('../../../assets/svg/calendar2.svg'),
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('schedule_id_surgery', {id: itemProp.id}),'schedule')"
                />
            </template>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_surgery",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['WarningsSurgery'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 20%;'},
                    {text: 'reason', field: 'reason', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 25%;'},
                    {text: 'warning', field: 'warning', keyFormat: 'format', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px;'},
                    {text: 'day_surgery', field: 'surgery_day', sorting: true, typeSort: 'date', style: 'min-width: 200px; max-width: 200px;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            this.$store.dispatch('getWarningsSurgeryList', '', {root: true});
            this.haveData = true;
        }
    }
</script>