import SectionGroup from "@/view/calendar/group/sections/section_group.vue"
import ViewGroup from "@/view/calendar/group/views/group.vue";

const route = [
    {
        path: '/calendar/group',
        name: 'calendar_group',
        component: SectionGroup,
        props: {showMenu: true}
    },
    {
        path: '/calendar/group/:id/:name/:date',
        name: 'view_group',
        component: ViewGroup,
        props: {showMenu: false, background: true, id: null, name: undefined, date: undefined},
    },
];

const routes = [
    ...route,
]

export default routes;