<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <GhDataSelector v-if="dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined && !dataItem.item.config.selector.is_tree"
                    :datablock="{
                        id: 'label_selector_' + dataItem.item.config.selector.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                        class: 'T15_b',
                        subtitle: $t(dataItem.item.config.selector.subtitle) + ':',
                        text: $t(dataItem.item.config.selector.text),
                        label: $t(dataItem.item.config.selector.text),
                        type: dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined ? dataItem.item.config.selector.type : 'radio',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :datainput="{
                        id: 'selector_' + dataItem.item.config.selector.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                        name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]' + dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined && dataItem.item.config.selector.type !== undefined && dataItem.item.config.selector.type === 'checkbox' ? '[]' : ''
                    }"
                    :feed="dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined ? dataItem.item.config.selector.feed : []"
                    :selected_input="dataItem.item.data !== undefined && dataItem.item.data.item !== undefined ? dataItem.item.data.item : []"
                    :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                    @accept="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);$parent.checkExtraItem($el, haveExtraItem, $refs, 8, dataItem.item.config.selector.id, processId, subprocessId, formId, sectionId, indexItem, dataItem.id)"
                />
                <GhTreeSelectorBlock v-else
                    :dataBlock="{
                        type: dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined ? dataItem.item.config.selector.type : 'radio',
                        id: 'label_selector_' + dataItem.item.config.selector.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                        label: $t(dataItem.item.config.selector.text) + '*',
                        title: $t(dataItem.item.config.selector.text),
                        text: $t(dataItem.item.config.selector.subtitle) + ':',
                        name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]' + dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined && dataItem.item.config.selector.type !== undefined && dataItem.item.config.selector.type === 'checkbox' ? '[]' : '',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: true,
                        checkparentdata: false,
                        style: 'margin-left: 0px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :selected_input="dataItem.item.data !== undefined && dataItem.item.data.item !== undefined ? dataItem.item.data.item : []"
                    :items="dataItem.item.config !== undefined && dataItem.item.config.selector !== undefined ? dataItem.item.config.selector.feed : []"
                    :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                    @accept="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);$parent.checkExtraItem($el, haveExtraItem, $refs, 8, dataItem.item.config.selector.id, processId, subprocessId, formId, sectionId, indexItem, dataItem.id)"
                />
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: 'view_selector',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhTreeSelectorBlock,
            GhDataSelector
        },
        data(){
            return{
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
                haveExtraItem: this.dataItem.item.config !== undefined && this.dataItem.item.config.extra_item !== undefined ? this.dataItem.item.config.extra_item : false
            }
        }
    }
</script>