<template>
    <div class="real_content">
        <div>
            <div class="selector_page">
                <div class="logo_selector">
                    <img :src="require('../../assets/login/MCH_logo_login.png')" @click="methodExit()"/>
                </div>
                <div class="title F1 C1" style="display: flex;">
                    <div style="width: 50%;">{{$t('select_clinic_or_hospital')}}</div>
                    <div style="text-align: right; width: 50%;">
                        <GhAction
                            :dataction="{
                                id: 'action_back_login',
                                text: $t('logout'),
                                icon: require('../../assets/svg/logout.svg'),
                            }"
                            @click="methodExit"
                            style="display: inline-flex;"
                        />
                    </div>
                </div>
                <div class="environment_block">
                    <a v-for="(item, index) in Environment.records_data" :key="index" @click="toRedirectSpecificClinic(item.id)">
                        <GhEnvironemnt
                            :dataBlock="{
                                id: 'environment_' + item.id,
                                title: item.title !== undefined ? item.title.toUpperCase() : '',
                                subtitle: item.subtitle !== undefined ? item.subtitle : '',
                            }"
                            :environment="{
                                img_hover: 'https://pre-tapagora.risktor.com/pub/web/images/tap/ICONO_INCIDENTS.svg',
                                img_visible: 'https://pre-tapagora.risktor.com/pub/web/images/tap/ICONO_INCIDENTS_NORMAL.svg',
                            }"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhEnvironemnt from 'fe-gh-environment-lib';
    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";
    export default {
        name: "view_environment",
        components:{
            GhAction,
            GhEnvironemnt
        },
        computed:{
            ...mapState(['Environment']),
        },
        async beforeMount(){
            await this.$store.dispatch('getEnvironmentsByUser', '', {root: true});
        },
        methods: {
            async methodExit(){
                await this.$store.dispatch('removeTabActive','',{root: true});
                await this.$store.dispatch('SET_LOGOUT','',{root: true});
                await this.$store.dispatch('TO_LOGIN','',{root: true});
            },
            async toRedirectSpecificClinic(id){
                await this.$store.dispatch('setEnvironment', {id: id}, {root: true});
            }
        }
    }
</script>

<style>
  .selector_page{
      min-width: 1024px;
      width: 1024px;
      margin: 0 auto;
      margin-top: 69px;
      padding-left: 34px;
  }

  .logo_selector{
      margin-left: 10px;
  }

  .title {
      margin-top: 56px;
  }

  .F1{
      font-family: 'IBMPlexSans-Regular';
      font-size: 20px;
      line-height: 24px;
  }

  .C1{
      color: #636362;
  }

  .environment_block{
      margin: 45px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
  }

  .environment_box, .environment_box-info{
      width: 120px !important;
  }
</style>