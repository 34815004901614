<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationProfessionals.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_professionals_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationProfessionals.section_info[label['type']]['second_text']),
                    num_results: ConfigurationProfessionals.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import list_active from "@/view/configuration/professionals/lists/list_active.vue"
    import list_pending from "@/view/configuration/professionals/lists/list_pending.vue";
    import list_not_available from "@/view/configuration/professionals/lists/list_not_available.vue";
    import list_past from "@/view/configuration/professionals/lists/list_past.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_variable",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationProfessionals']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'active', component: list_active, type: 'active'},
                    {id: 2, name: 'pending', component: list_pending, type: 'pending'},
                    {id: 3, name: 'not_available', component: list_not_available, type: 'not_available'},
                    {id: 4, name: 'past', component: list_past, type: 'past'}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationProfessionalsSections','',{root:true});
            this.haveData = true;
        }
    }
</script>
