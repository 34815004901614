<template>
    <div v-if="haveData" style="padding-bottom: 12px;" :key="refresh">
        <GhScheduleCalendar
            :dataBlock="{
                id: 'schedule_department_' + dataBlock.id,
                backgroundColorNumber: '#ffffff',
                widthHour: 30,
                colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'}
            }"
            :data="CalendarDepartments.calendar_department_array[dataBlock.id]"
            @prevM="toPrev(CalendarDepartments.calendar_department_array[dataBlock.id]['prevMonth'])"
            @days="toDays"
            @nextM="toNext(CalendarDepartments.calendar_department_array[dataBlock.id]['nextMonth'])"
            ref="RefSchedule"
        />
    </div>
</template>

<script>
    import GhScheduleCalendar from "fe-gh-schedule-calendar-lib";
    import {mapState} from "vuex";

    export default {
        name: 'department_calendar',
        props:['dataBlock'],
        components: {
            GhScheduleCalendar,
        },
        computed:{
            ...mapState(['CalendarDepartments']),
        },
        data(){
            return{
                haveData: false,
                refresh: 0,
            }
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        async beforeMount(){
            await this.$store.dispatch('getCalendarDepartment', {id:this.dataBlock.id}, {root: true});
            this.haveData = true;
        },
        updated(){
            this.resizeSchedule();
        },
        methods:{
            async toPrev(item){
                await this.$store.dispatch('updateCalendarDepartment', {id:this.dataBlock.id,idMonth:item.id}, {root: true});
                this.refresh++;
            },
            toDays(item){
                this.Global.windowOpen(this.Global.openSecondWindow('view_department', {id: this.dataBlock.id, name: this.dataBlock.name, date: item.day + '/' + item.month + '/' + item.year}),'department_data');
            },
            async toNext(item){
                await this.$store.dispatch('updateCalendarDepartment', {id:this.dataBlock.id,idMonth:item.id}, {root: true});
                this.refresh++;
            },
            myEventHandler(e) {
                if(e.type == 'resize' && this.$refs.RefSchedule !== undefined){
                    this.resizeSchedule();
                }
            },
            async resizeSchedule(){
                var fullWidth = this.$refs.RefSchedule.$el.clientWidth - 250;

                var newWidth = fullWidth / this.$refs.RefSchedule.$el.children[0].children[1].children.length;

                this.widthHour = newWidth - 8;

                this.$refs.RefSchedule.$el.children[0].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule.$el.children[0].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule.$el.children[0].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule.$el.children[0].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule.$el.children[0].children[2].style.maxWidth = '100px';
                this.$refs.RefSchedule.$el.children[1].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule.$el.children[1].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule.$el.children[1].children[2].style.minWidth = '106px';
                this.$refs.RefSchedule.$el.children[1].children[2].style.maxWidth = '106px';
                this.$refs.RefSchedule.$el.children[2].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule.$el.children[2].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule.$el.children[2].children[1].style.minWidth = fullWidth + 'px';
                this.$refs.RefSchedule.$el.children[2].children[1].style.maxWidth = fullWidth + 'px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule.$el.children[3].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule.$el.children[3].children[2].style.maxWidth = '100px';

                for (var i = 0; i < this.$refs.RefSchedule.$el.children[0].children[1].children.length; i++) {
                    for (var x = 0; x < this.$refs.RefSchedule.$el.children[0].children[1].children[i].children.length; x++) {
                        this.$refs.RefSchedule.$el.children[0].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[0].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[3].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[3].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].setAttribute('style', this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.cssText + 'font-size: 11px !important');
                    }
                }
            },
        }
    }
</script>