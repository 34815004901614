<template>
    <GhTableCommon v-if="haveData"
        :extratable="{
            id: 'table_clinics'
        }"
        :header="header"
        :data="Incidence.data.non_active_professionals"
        style="margin-top: 12px;"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_reasign_' + itemProp.id,
                    text: $t('reasign'),
                }"
                @click="reasignProfessional(itemProp)"
            />
        </template>
    </GhTableCommon>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from "fe-gh-action-lib";

import {mapState} from "vuex";

export default {
    name: "list_non_active_professionals",
    components: {
        GhTableCommon,
        GhAction
    },
    computed: {
        ...mapState(['Incidence']),
    },
    data(){
        return {
            header: [
                {text: 'md_number', field: 'md_number', sorting: true, typeSort: 'code', style: 'max-width: 50px; min-width: 100px'},
                {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 20%;'},
                {text: 'specialty', field: 'specialty', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 42.5%;'},
                {text: 'number_of_patients', field: 'number_of_patients', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 50px;'},
                {text: '', field: 'actions', sorting: false, style: 'min-width: 60px;'}
            ],
            haveData: false
        }
    },
    async beforeMount(){
        await this.$store.dispatch('getIncidenceData', {type: 'non-active-professionals'}, {root: true});
        this.haveData = true;
    },
    methods: {
        reasignProfessional(item){
            this.$parent.$parent.reasign_id = item.id;
            this.$parent.$parent.reasign_name = item.name;
            this.$parent.$parent.reasign_surname = item.surname;
            this.$parent.$parent.reasign_type = 1;
            this.Global.openPopUp('reasign_confirm');
        }
    }
}
</script>