<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_category',
                    title: $t('category'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_category" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_config_support_category_name',
                                            text: $t('name') + '*',
                                            style: 'width: 88px; min-width: 123px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_config_support_category_name',
                                            name: 'category[name]',
                                            style: 'max-height: 17px;',
                                            type: 'text',
                                            value: ConfigurationSupportCategory.data && ConfigurationSupportCategory.data.name ? ConfigurationSupportCategory.data.name : '',
                                            required: true,
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigurationSupportCategory.data && ConfigurationSupportCategory.data.name ? ConfigurationSupportCategory.data.name : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_accept',
                        class: 'T19 container-md_button_content',
                        text: $t('accept')
                    }"
                    @click="setCategorie()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhButton from 'fe-gh-button-lib'
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhLocation from "fe-gh-location-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_category",
        components:{
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        data(){
            return{
                type: '',
                haveData: false,
            }
        },
        computed:{
            ...mapState(['ConfigurationSupportCategory'])
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_support_')[1].split('_category')[0];

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationSupportCategories', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationSupportCategory.data = [];
            }

            this.haveData = true;
        },
        methods:{
            async setCategorie(){
                if(this.Global.checkRequired('form_category') == 'OK') {
                    const formData = new FormData(document.getElementById('form_category'));
                    await this.$store.dispatch('setDataConfigurationSupportCategories', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.windowClose();
                }
            },
        }
    }
</script>