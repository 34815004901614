var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_propagate',
        title: _vm.$t('propagate_' + _vm.dataBlock.type),
        type: 'warning',
        style: 'width: 50%; min-width: 920px'
    },"bodyConfiguration":{
        id: 'popup_propagate_body',
        class: '',
        style: '',
        text: _vm.$t('select_' + _vm.dataBlock.type + '_to_propagate'),
    },"footerConfiguration":{}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"margin-top":"15px","margin-right":"15px","margin-left":"15px"}},[_c('form',{key:_vm.dataBlock.refresh,attrs:{"id":"form_propagate"}},_vm._l((_vm.ConfigurationFormProcess.propagate),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                            id: 'drop_field_propagate_' + _vm.dataBlock.type + '_' + item.id,
                            title: item.name,
                        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                                    id: 'table_propagate_' + _vm.dataBlock.type + '_' + item.id,
                                },"header":_vm.dataBlock.type === 'process' ? _vm.header_process : _vm.header_form,"data":item.data === undefined ? [] : item.data}})]},proxy:true}],null,true)})],1)}),0)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_popup_propagate',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":_vm.acceptPropagate}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_propagate',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_propagate')}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }