var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_equipment_maintenance',
                title: _vm.$t('equipment_maintenance')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_equipment_maintenance"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'equipment_maintenance_code',
                                        name: 'equipment_maintenance[code]',
                                        style: 'width: 200px',
                                        value: _vm.values.code,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined ? _vm.ConfigurationVariableEquipmentMaintenance.data.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'equipment_maintenance_name',
                                        name: 'equipment_maintenance[name]',
                                        style: 'min-width: 200px; width: 100%',
                                        value: _vm.values.name,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined ? _vm.ConfigurationVariableEquipmentMaintenance.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'equipment_maintenance_description',
                                        name: 'equipment_maintenance[description]',
                                        value: _vm.values.description
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined ? _vm.ConfigurationVariableEquipmentMaintenance.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('recurrent_every')))]),_c('GhChecks',{ref:"RefRecurrentEveryMonths",attrs:{"datalabel":{},"datainput":{
                                        required: false,
                                        type: 'radio',
                                        id: 'equipment_maintenance_months',
                                        value: 1,
                                        name: 'equipment_maintenance[recurrent_every]',
                                        selected: _vm.recurrent_every == 1,
                                    }},on:{"click":function($event){return _vm.checkRecurrent('RefRecurrentEveryMonths', 1)}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"25px"}},[_vm._v(_vm._s(_vm.$t('months')))]),_c('GhChecks',{ref:"RefRecurrentEveryHours",attrs:{"datalabel":{},"datainput":{
                                        required: false,
                                        type: 'radio',
                                        id: 'equipment_maintenance_hours',
                                        value: 2,
                                        name: 'equipment_maintenance[recurrent_every]',
                                        selected: _vm.recurrent_every == 2,
                                    }},on:{"click":function($event){return _vm.checkRecurrent('RefRecurrentEveryHours', 2)}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('years')))]),(_vm.recurrent_every !== '' && _vm.recurrent_every !== undefined)?_c('div',[_c('div',{staticStyle:{"margin-left":"25px"}},[_c('GhInputTextField',{ref:"RefRecurrentEvery",attrs:{"datalabel":{
                                                text: '',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            },"datainput":{
                                                required: true,
                                                id: 'equipment_maintenance_recurrent_every_value',
                                                name: 'equipment_maintenance[recurrent_every][value]',
                                                style: 'min-width: 50px; max-width: 50px;',
                                                value: _vm.values.recurrent_every,
                                                type: 'int',
                                            }}})],1)]):_vm._e()]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('recurrent_every') + ':'))]),(_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined && _vm.ConfigurationVariableEquipmentMaintenance.data.recurrent_every !== undefined )?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[(_vm.ConfigurationVariableEquipmentMaintenance.data.recurrent_every === 1)?[_vm._v(" "+_vm._s(_vm.values.recurrent_every + ' ' + _vm.$t('months'))+" ")]:_vm._e(),(_vm.ConfigurationVariableEquipmentMaintenance.data.recurrent_every === 2)?[_vm._v(" "+_vm._s(_vm.values.recurrent_every + ' ' + _vm.$t('years'))+" ")]:_vm._e()],2)]:_vm._e()]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'equipment_maintenance_equipment_affected',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_equipment_affected') + ':',
                                        text: _vm.$t('equipment_affected'),
                                        label: _vm.$t('equipment_affected'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;'
                                    },"datainput":{
                                        id: 'equipment_maintenance_affected_equipment',
                                        name: 'equipment_maintenance[equipment_affected][]'
                                    },"feed":_vm.getFeeds.equipment,"selected_input":_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined ? _vm.ConfigurationVariableEquipmentMaintenance.data.equipment_affected : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('equipment_affected') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipmentMaintenance.data.equipment_affected).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultiUpload',{attrs:{"datalabel":{
                                        text: _vm.$t('template_documents'),
                                        text_popup: _vm.$t('template_documents'),
                                        input_text: _vm.$t('name'),
                                        textarea_text: _vm.$t('description'),
                                        attach_text: _vm.$t('attach'),
                                        content_warning: '',
                                        label_required: _vm.$t('template_documents'),
                                        required: false,
                                    },"datablock":{
                                        id: 'equipment_maintenance_documents',
                                        name: 'equipment_maintenance[documents]',
                                        style: '',
                                        class: 'T15_b',
                                        icon_attach: '',
                                        icon_trash: '',
                                        icon_download: '',
                                        href: _vm.dispatchEvidence
                                    },"defaultfiles":_vm.ConfigurationVariableEquipmentMaintenance.data !== undefined && _vm.ConfigurationVariableEquipmentMaintenance.data.template_documents !== undefined ? _vm.ConfigurationVariableEquipmentMaintenance.data.template_documents : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('template_documents') + ':'))]),_vm._l((_vm.ConfigurationVariableEquipmentMaintenance.data.template_documents),function(file,index){return _c('div',{key:index},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_c('a',{staticClass:"C1 F5",attrs:{"href":file.file_download}},[_vm._v(_vm._s(file.name))]),_vm._v(_vm._s(index != Object.keys(_vm.ConfigurationVariableEquipmentMaintenance.data.template_documents).pop() ? ', ' : ''))])])})]],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.haveData && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept')
                }},on:{"click":function($event){return _vm.submitEquipmentMaintenance();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }