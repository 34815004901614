var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_items_standard_items_',
    },"header":_vm.header,"data":_vm.ConfigurationItemsStandardItems.records_data.length == 0 ? [] : _vm.ConfigurationItemsStandardItems.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_items_view_standard_items', {id: itemProp.id, view: true}),'configuration_standard_items')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_standard_items_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_items_edit_standard_items', {id: itemProp.id}),'configuration_standard_items')}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_standard_items_' + itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeConfigurationItemsStandardItem', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, _vm.$t('delete_standard_items'), _vm.$t('preparing_delete_standard_items'), 'remove')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_configuration_new_standard_items',
                    text: _vm.$t('add')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_items_new_standard_items', {}),'configuration_standard_items')}}})],1)]},proxy:true}],null,false,2727538171)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }