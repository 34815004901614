import Languages from "@/view/configuration/general_data/views/view_language.vue";

const routes = [
    {
        path: '/configuration/general-data/language',
        name: 'configuration_general_data_new_language',
        component: Languages,
        props: {id: null, showMenu: false, background: true, otherTitle: 'language'},
        children: [
            {
                path: '/configuration/general-data/language/:id',
                name: 'configuration_general_data_edit_language',
                component: Languages,
                props: {id: null, showMenu: false, background: true, otherTitle: 'language'},
            },
            {
                path: '/configuration/general-data/language/:id/:view',
                name: 'configuration_general_data_view_language',
                component: Languages,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'language'},
            }
        ],
    },

];

export default routes;