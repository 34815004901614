<template>
    <div>
        <div class="gh-row-content">
            <GhInputTextField
                :datalabel="{
                    text: $t('name'),
                    id:' group_label_name',
                    class:'gh_colspan-6',
                    style:'width: 85px; text-align: right;'
                }"
                :datainput="{
                    id:'group_last_nombre',
                    name:'user[first_name]',
                    style:'width:280px; min-width:280px;',
                    type:'text',
                }"
            />
            <GhInputTextField
                :datalabel="{
                    text:$t('surname'),
                    id:'group_label_last_name',
                    class:'gh_colspan-6',
                    style:'width: 100px; text-align: right;'
                }"
                :datainput="{
                    class: '',
                    id:'group_nombre',
                    name:'user[worker_surname]',
                    style:'width:280px; min-width:280px;',
                    type:'text',
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhDataSelector
                :datablock="{
                    id: 'group_user_professional_type',
                    class: 'T15_b',
                    subtitle: $t('select_professiona_type') + ':',
                    text: $t('professional_types'),
                    label: $t('professional_types'),
                    type: 'checkbox',
                    columns: 3,
                    required: false,
                    style: 'margin-left: 35px;',
                    popupStyle: 'min-width: 900px; width: 55%;',
                }"
                :datainput="{
                    id: 'group_user_professional_type',
                    name: 'user[professional_type][]'
                }"
                :feed="getFeeds.professional_type"
                :selected_input="[]"
            />
        </div>
    </div>
</template>

<script >
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "form_search_companies",
        components:{
            GhInputTextField,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getFeedProfessionalType', '', {rooot: true});
            this.haveData = true;
        }
    }
</script>