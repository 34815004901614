import Companies from "@/view/permissions/views/view_clinic.vue";

const routes = [
    {
        path: '/permissions/clinics',
        name: 'permissions_new_company',
        component: Companies,
        props: {id: null, showMenu: true, otherTitle: 'company'},
        children: [
            {
                path: '/permissions/clinics/:id',
                name: 'permissions_edit_company',
                component: Companies,
                props: {id: null, showMenu: true, otherTitle: 'company'},
            }
        ]
    },
];

export default routes;