var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'permissions_profiles',
    },"header":_vm.header,"data":_vm.PermissionProfiles.records_data.length === 0 ? [] : _vm.PermissionProfiles.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'permissions_view_profile', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'permissions_edit_profile', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_profile_' + itemProp['id'],
                    text: _vm.$t('edit'),
                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    item_prop: itemProp
                }}})],1),_c('GhAction',{staticStyle:{"margin-right":"23px"},attrs:{"dataction":{
                id: 'delete_profile_' + itemProp['id'],
                text: _vm.$t('delete'),
                icon: require('../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removePermissionsProfiles', itemProp.id, {'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_profile'), _vm.$t('preparing_delete_profile'), 'remove');}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'permissions_new_profile'}}},[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_add_profile',
                        text: _vm.$t('add')
                    }}})],1)],1)]},proxy:true}],null,false,2924104216)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }