<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_professional_not_available_',
        }"
        :header="header"
        :data="ConfigurationProfessionalsNotAvailable.records_data.length === 0 ? [] : ConfigurationProfessionalsNotAvailable.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="viewProfessionalsNotAvailable(itemProp.id)"> {{itemProp[labelProp]}}</a>        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_deactivate_not_available_' + itemProp.id,
                    text: $t('activate'),
                    icon: require('../../../../assets/gh_new_dessign/denied.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','changeStateConfigurationProfessionals', itemProp.id,{
                    'code': itemProp.code,
                    'name': itemProp.name,
                    'surname': itemProp.surname,
                    'specialties': itemProp.specialties,
                    'status': itemProp.status
                }, $t('activate_professional'), $t('preparing_activate_professional'), 'activate')"
            />
            <GhAction
                :dataction="{
                    id: 'action_edit_not_available_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="editProfessionalsNotAvailable(itemProp.id)"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_not_available_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationProfessionals', itemProp.id,{
                    'code': itemProp.code,
                    'name': itemProp.name,
                    'surname': itemProp.surname,
                    'specialties': itemProp.specialties,
                    'status': itemProp.status
                }, $t('delete_professional'), $t('preparing_delete_professional'), 'remove')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from "fe-gh-action-lib";

import {mapState} from "vuex";

export default {
    name: "list_not_available",
    components: {
        GhTableCommon,
        GhAction
    },
    computed: {
        ...mapState(['ConfigurationProfessionalsNotAvailable'])
    },
    data(){
        return{
            haveData: false,
            timer: [],
            header: [
                {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                {text: 'specialties', field: 'specialties', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 50%;'},
                {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                {text: '', field: 'actions', sorting: false}
            ]
        }
    },
    async beforeMount(){
        await this.$store.dispatch('getConfigurationProfessionalsNotAvailable', '', {root: true});
        this.haveData = true;
    },
    methods: {
        editProfessionalsNotAvailable(id){
            this.Global.windowOpen(
                this.Global.openSecondWindow(
                    'configuration_professionals_edit_professional', {
                        id: id
                    }),'configuration_professionals_professional', true
            );

            this.updatedParentWindow();
        },
        viewProfessionalsNotAvailable(id){
            this.Global.windowOpen(
                this.Global.openSecondWindow('configuration_professionals_view_professional', {
                    id: id,
                    view: true
                }),'configuration_professionals_professional', true
            );

            this.updatedParentWindow();
        },
        updatedParentWindow(){
            var then = this;
            window.UpdatedSelect = async function(e){
                if(e === 'OK'){
                    await then.$store.dispatch('getConfigurationProfessionalsNotAvailable', '', {root: true});
                    await then.$store.dispatch('getConfigurationProfessionalsSections', '', {root: true});
                }
            }

            this.timer = setInterval(this.checkChildWindow, 1000);
        },
        checkChildWindow() {
            if (this.Global.state.window.closed) {
                this.Global.state.window_open = false;
                clearInterval(this.timer);
            }
        }
    }
}
</script>