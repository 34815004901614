<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_consent_form',
                    title: $t('consent_forms')
                }"
            >
                <template v-slot:content>
                    <form id="form_consent_form" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'consent_forms_code',
                                            name: 'consent_forms[code]',
                                            style: 'width: 200px',
                                            value: ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.code : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'consent_forms_name',
                                            name: 'consent_forms[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.name : '',
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'consent_forms_description',
                                            name: 'consent_forms[description]',
                                            value: ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.description : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.description : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'consent_forms_departments',
                                            class: 'T15_b',
                                            subtitle: $t('select_departments') + ':',
                                            text: $t('departments'),
                                            label: $t('departments'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'consent_forms_departments',
                                            name: 'consent_forms[departments][]'
                                        }"
                                        :feed="getFeeds.departments"
                                        :selected_input="ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.departments : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('departments') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? Object.values(ConfigurationConsentForms.data.departments).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'consent_forms_specialties',
                                            class: 'T15_b',
                                            subtitle: $t('select_specialties') + ':',
                                            text: $t('specialties'),
                                            label: $t('specialties'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'consent_forms_specialties',
                                            name: 'consent_forms[specialties][]'
                                        }"
                                        :feed="getFeeds.specialties"
                                        :selected_input="ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.specialties : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? Object.values(ConfigurationConsentForms.data.specialties).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'consent_forms_procedure',
                                            class: 'T15_b',
                                            subtitle: $t('select_procedure') + ':',
                                            text: $t('procedure'),
                                            label: $t('procedure'),
                                            type: 'radio',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'consent_forms_procedure',
                                            name: 'consent_forms[procedure]'
                                        }"
                                        :feed="getFeeds.procedure"
                                        :selected_input="ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.procedure : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? Object.values(ConfigurationConsentForms.data.procedure).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhCalendar
                                        :datalabel="{
                                            id: 'consent_forms_go_live_date',
                                            text: $t('go_live_date'),
                                            style: 'width: 150px; min-width: 150px; text-align: right;'
                                        }"
                                        :datainput="{
                                            id: 'consent_forms_go_live_date',
                                            name: 'consent_forms[go_live_date]',
                                            value: ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.go_live_date : '',
                                            required: false
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('go_live_date') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationConsentForms.data !== undefined ? ConfigurationConsentForms.data.go_live_date : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhMultiUpload
                                        :datalabel="{
                                            text: $t('documents'),
                                            text_popup: $t('documents'),
                                            input_text: $t('name'),
                                            textarea_text: $t('description'),
                                            attach_text: $t('attach'),
                                            content_warning: '',
                                            label_required: $t('documents'),
                                            required: false,
                                        }"
                                        :datablock="{
                                            id: 'consent_forms_documents',
                                            name: 'consent_forms[documents]',
                                            style: '',
                                            class: 'T15_b',
                                            icon_attach: '',
                                            icon_trash: '',
                                            icon_download: '',
                                            href: dispatchEvidence,
                                        }"
                                        :defaultfiles="ConfigurationConsentForms.data !== undefined && ConfigurationConsentForms.data.documents !== undefined ? ConfigurationConsentForms.data.documents : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('documents') + ':'}}</label>
                                    <div v-for="(file,index) in ConfigurationConsentForms.data.documents" :key="index">
                                        <label class="gh_text_field-label T15_b"><a class="C1 F5" :href="file.file_download">{{file.name}}</a>{{index != Object.keys(ConfigurationConsentForms.data.documents).pop() ? ', ' : ''}}</label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="!this.$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitConsentForm();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import GhMultiUpload from "fe-gh-multi-upload-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_consent_forms",
        components: {
            GhCalendar,
            GhMultiUpload,
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhButton,
            GhTextarea,
            GhDataSelector,
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationConsentForms'])
        },
        data(){
            return{
                type: '',
                haveData: false,
                dispatchEvidence: 'easyUpload'
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationConsentForms', {id: this.$root._route.params.id}, {root: true});
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedDepartments', '', {root: true});
                await this.$store.dispatch('getFeedSpecialties', '', {root: true});
                await this.$store.dispatch('getFeedProcedure', '', {root: true});
            }

            this.type = this.$root._route.name.split('configuration_consent_forms_')[1].split('_')[0];
            this.haveData = true;
        },
        methods: {
            async submitConsentForm(){
                if(this.Global.checkRequired('form_consent_form') == 'OK'){
                    const formData = new FormData(document.getElementById('form_consent_form'));
                    await this.$store.dispatch('setDataConfigurationConsentForms', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    #consent_forms_departments_selector_label, #consent_forms_specialties_selector_label, #consent_forms_procedure_selector_label, #consent_forms_documents_multi_doc_selector_label{
        width: 150px;
        text-align: end;
    }
</style>