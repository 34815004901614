<template>
    <div style="max-width: 100%; margin-right: auto; margin-left: auto;" :key="refresh_translate">
        <template v-if="this.$route.matched[0].props.default.showMenu">
            <GhMenu ref="refMenu" :key="refresh_menu">
                <template v-slot:cabezera>
                    <div class="logo_cabecera_square" style="margin-left: 8px;" >
                        <img style="max-width: 50px;" :src="require('./assets/login/MCH_logo_menu.png')" />
                    </div>
                </template>
                <template v-slot:tabss v-if="this.$route.matched[0].props.default.hiddentabs">
                    <div></div>
                </template>
                <template v-slot:header-platform_info>
                    <GhUserIcon v-if="showUser"
                        :generalConfiguration="{
                            class: 'flex-row-textt',
                            icon: require('./assets/gh_new_dessign/user.svg'),
                            title: '',
                            style: 'top: 53px; right: 20px; position:fixed;',
                            id: 'Icon_User'
                        }"
                        :bodyConfiguration="{
                            class: 'flex-row-text',
                            title: '',
                            style: '',
                            id: 'Icon_User_Body'
                        }"
                    >
                        <template v-slot:extra_action v-if="Login.responseUser.is_professional && !Login.responseUser.no_finish_register">
                            <div class="link dropdown_rowhover cerrar_t14_1_" @click="goBack()">
                                <a class="t14">
                                    {{$t('back')}}
                                </a>
                            </div>
                        </template>
                    </GhUserIcon>
                    <GhHelpIcon :dataBlock="{icon: require('./assets/gh_new_dessign/help.svg')}"></GhHelpIcon>
                    <GhLanguage
                        :dataBlock="{
                            title: $t('select_language'),
                            id:'select_lang',
                            icon: require('./assets/gh_new_dessign/arrowdown.svg')
                        }"
                        :language="languages"
                        :selected="idioma"
                    ></GhLanguage>
                    <div class="header-phone T4">
                        {{ '935 950 764' }}
                    </div>
                    <div class="header-email T4">
                        {{ 'info@globalholdings2000.es' }}
                    </div>
                </template>
            </GhMenu>
            <div style="margin-top: 93px;"></div>
            <GhLocation
                v-if="!this.$route.matched[0].props.default.hiddentabs"
                :key="Tabs.TabLoading"
                :dataLocation="{showButton: true, secondWindow: false, showLoader: 'OK', text: this.$route.matched[0].props.default.otherTitle !== undefined ? this.$route.matched[0].props.default.otherTitle : $t(Tabs.TabActive)}"
                :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
                @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
            />
            <div id="content_page" :class="this.$route.path == '/' ? '' : 'content_page'">
                <router-view/>

                <component :is="popups.popup_401"/>
            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>

        <GhDeleteConfirm
            :visible_fields="Global.state.state_confirm.item_confirm"
            :id_popup="'delete_confirm'"
            :content_warning="Global.state.state_confirm.subheader_confirm"
            v-on:function="setDelete"
        />

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_confirm',
                title: $t('required_fields'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t('the_following_fields_are_missing') + ':',
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; padding-bottom: 15px;" >
                    <div class="T15_b" v-for="(item, index) in Global.state.alert_required.requireds" :key="index" style="padding-left: 20px;">
                        {{item}}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_confirm');"
                />
            </template>
        </GhPopUp>

        <div v-if="Global.state.window_open" class="modal-backdrop"></div>
    </div>
</template>

<script>

import GhMenu from 'fe-gh-menu-lib';
import GhLocation from 'fe-gh-location-lib';
import GhUserIcon from 'fe-gh-user-icon-lib'
import GhHelpIcon from 'fe-gh-help-lib';
import GhLanguage from 'fe-gh-language-lib';
import GhDeleteConfirm from 'fe-gh-delete-confirm-lib';
import GhButton from "fe-gh-button-lib";
import {mapState} from "vuex";
import GhPopUp from "fe-gh-popup-lib";

import popup_401 from "@/view/shared/popup/popup_401.vue";
// import GhWarningTimeOut from "fe-gh-warning-timeout-lib";
export default {
    components: {
        GhMenu,
        GhLocation,
        GhUserIcon,
        GhHelpIcon,
        GhLanguage,
        GhPopUp,
        GhButton,
        // GhWarningTimeOut,
        GhDeleteConfirm,
    },
    data(){
        return{
            idioma: localStorage.getItem('Language-selected-select_lang') ? JSON.parse(localStorage.getItem('Language-selected-select_lang')) : {id: 'en', extra_value_lang: 'English'},
            showUser: false,
            languages:[
                {id: 'fr', extra_value_lang: 'French'},
                {id: 'en', extra_value_lang: 'English'},
                {id: 'es', extra_value_lang: 'Español'}
            ],
            refresh_translate: 0,
            refresh_menu: 0,
            popups: {
                popup_401: popup_401
            }
        }
    },
    watch:{
        async '$i18n.locale'(){
            this.Global.changeLanguage();
            this.resizeMenu();
        }
    },
    mounted() {
        this.$i18n.locale = this.idioma.id;
        this.updateBackColor();
        this.showUser = this.$route.matched[0].props.default.user !== undefined ? this.$route.matched[0].props.default.user : true;
    },
    beforeUpdate() {
        this.resizeMenu();
        this.updateBackColor();
    },
    updated() {
        this.showUser = this.$route.matched[0].props.default.user !== undefined ? this.$route.matched[0].props.default.user : true;
    },
    computed: {
        ...mapState(["TabItem","TabCustom","TabHeader",'Tabs', 'GlobalStore', 'Login', 'Environment']),
    },
    methods:{
        resizeMenu(){
            var that = this;
            setTimeout(function (){
                if(that.$refs.refMenu !== undefined) {
                    var tabs = that.$refs.refMenu.$children[2].$el.children[0].children;
                    for (var i = 0; i < tabs.length; i++) {
                        if (i != 0) {
                            const NumLetters = that.splitResizeTab(tabs[i].lastChild.textContent).split('\n').reduce(function (a, b) {
                                return a.length > b.length ? a : b;
                            }).length;
                            const CalculateWidth = 8.67 * NumLetters;
                            if(that.$refs.refMenu.$children[2].$el.children[0].children[i].lastChild.style !== undefined){
                                that.$refs.refMenu.$children[2].$el.children[0].children[i].lastChild.style.width = CalculateWidth + 'px';
                                if (tabs[i].classList[3] == 'tab-active') {
                                    let maximoaumento = document.getElementById(tabs[i].id).getBoundingClientRect().width;
                                    let destino = document.getElementById(tabs[i].id).getBoundingClientRect().x;
                                    document.getElementById('nav-actived').getElementsByClassName('nav-active-center')[0].animate([{width: maximoaumento - 10 + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                    document.getElementById('nav-actived').animate([{left: destino + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                }
                            }
                        }
                    }
                }
            },150);
        },
        splitResizeTab(value){
            var stringnosplited = value.split(' ');
            var stringsplited = '';
            var stringsplitedaux = '';
            stringnosplited.forEach((string,i)=>{
                if(i == 0){
                    stringsplited = string;
                    stringsplitedaux = string;
                }else{
                    stringsplitedaux += string;
                    if(stringsplitedaux.length < 12) {
                        stringsplited += ' ' + string;
                    }else{
                        stringsplited+= '\n' + string;
                    }
                }
            })
            return stringsplited;
        },
        updateBackColor(){
            var r = document.querySelector(':root');
            var color = '#FFFFFF';

            if(this.$route.matched[0].props.default.showMenu || this.$route.matched[0].props.default.background){
                color = '#DDDDDD';
            }
            r.style.setProperty('--body_color',color);
        },
        setDelete(){
            this.$store.dispatch(this.Global.state.state_confirm.where_confirm,{
                id: this.Global.state.state_confirm.id_delete_confirm,
                type: this.Global.state.state_confirm.type_confirm,
                item: this.Global.state.state_confirm.item,
            },{root:true});
            this.Global.closePopUp('delete_confirm');
        },
        async goBack(){
            await this.$store.dispatch('backEnvironmentAction', {id: this.Environment.idBack}, {root: true});
        }
    },
}
</script>

<style>
    :root{
      --body_color: #FFFFFF;
    }

    @import './assets/css/styles.css';
    @import '~fe-gh-menu-lib/dist/fe-gh-menu-lib.css';
    @import '~fe-gh-data-selector-lib/dist/fe-gh-data-selector-lib.css';
    @import '~fe-gh-tabs-lib/dist/fe-gh-tabs-lib.css';
    @import '~fe-gh-location-lib/dist/fe-gh-location-lib.css';
    @import '~fe-gh-user-icon-lib/dist/fe-gh-user-icon-lib.css';
    @import "~fe-gh-popup-lib/dist/fe-gh-popup-lib.css";
    @import '~fe-gh-button-lib/dist/fe-gh-button-lib.css';
    @import "~fe-gh-inputtextfield-lib/dist/fe-gh-inputtextfield-lib.css";
    @import "~fe-gh-help-lib/dist/fe-gh-help-lib.css";
    @import "~fe-gh-language-lib/dist/fe-gh-language-lib.css";
    @import "~fe-gh-checks-lib/dist/fe-gh-checks-lib.css";
    @import "~fe-gh-table-common-lib/dist/fe-gh-table-common-lib.css";
    @import "~fe-gh-no-dropselector-lib/dist/fe-gh-no-dropselector-lib.css";
    @import "~fe-gh-drop-down-field-lib/dist/fe-gh-drop-down-field-lib.css";
    @import "~fe-gh-easy-upload-lib/dist/fe-gh-easy-upload-lib.css";
    @import "~fe-gh-table-mixed-lib/dist/fe-gh-table-mixed-lib.css";
    @import "~fe-gh-delete-confirm-lib/dist/fe-gh-delete-confirm-lib.css";
    @import "~fe-gh-input-calendar-lib/dist/fe-gh-input-calendar-lib.css";
    @import "~fe-gh-subtab-lib/dist/fe-gh-subtab-lib.css";
    @import "~fe-gh-environment-lib/dist/fe-gh-environment-lib.css";
    @import "~fe-gh-input-hour-lib/dist/fe-gh-input-hour-lib.css";
    @import "~fe-gh-textarea-lib/dist/fe-gh-textarea-lib.css";
    @import "~fe-gh-color-lib/dist/fe-gh-color-lib.css";
    @import "~fe-gh-easy-draw-canvas-lib/dist/fe-gh-easy-draw-canvas-lib.css";
    @import "~fe-gh-color-lib/dist/fe-gh-color-lib.css";

    input[type="color"]{
        display: flex !important;
        margin-top: -3px;
    }

    input {
        display: flex;
    }

    .gh_draw_image-canvas, .gh_draw_image-canvas-eraser, .gh_draw_image-canvas-text{
        width: 880px !important;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    * {
        padding: 0;
        margin: 0;
    }
    body{
        background-color: var( --body_color);
    }
    .content_page{
        padding-left: 12px;
        padding-right: 12px;
    }
</style>
