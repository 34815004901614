var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTextarea',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"datalabel":{text: ''},"datainput":{
        id: 'textarea_' + _vm.dataItem.item.config[_vm.dataItem.type === 1 ? 'textarea' : _vm.dataItem.type === 6 || _vm.dataItem.type === 7 ? 'input' : _vm.dataItem.type === 8 ? 'selector' : _vm.dataItem.type === 9 ? 'table' : _vm.dataItem.type === 10 ? 'imported' : _vm.dataItem.type == 11 ? 'graphic' : _vm.dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_comment',
        name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][comment]',
        value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.comment : '',
        required: false,
        edited: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.comment !== undefined ? _vm.dataItem.item.config.comment.enhansed : false,
        image: {
            subscript: require('../../../../../assets/img/text/subscript.svg'),
            superscript: require('../../../../../assets/img/text/superscript.svg'),
            bold: require('../../../../../assets/img/text/text-bold.svg'),
            italic: require('../../../../../assets/img/text/text-italic.svg'),
            underline: require('../../../../../assets/img/text/text-underline.svg')
        }
    },"placeholder":_vm.$t('comment')},on:{"emiter":function($event){return _vm.$parent.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.$parent.dataSubprocess.id);}}})
}
var staticRenderFns = []

export { render, staticRenderFns }