<template>
    <GhTableCommon
        :extratable="{
              id: 'table_historic_patient'
          }"
        :header="header"
        :data="MyFileClinics.data.historic_patient"
        style="margin-top: 12px;"
    />
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_historic_patient",
        components: {
            GhTableCommon
        },
        computed : {
            ...mapState(['MyFileClinics'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'code', style: 'max-width: 100px;width: 50%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 50%;'},
                    {text: 'date', field: 'date', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;width: 100px;'}
                ]
            }
        },
    }
</script>