<template>
    <div style="padding-bottom: 12px;">
        <GhSubTab v-if="haveProcess"
            :key="'S' + refreshSubTab"
            :items="$router.options.GhTabsProcess"
            :dataBlock="{
                id: 'process_tab_',
                calldispatch: 'setProcessActiveTab'
            }"
        />
        <component v-bind:is="Process.sections !== undefined && Process.sections.component !== undefined && Process.sections.component.component !== undefined ? Process.sections.component.component : ''" :dataProcess="Process.sections" :processId="$root._route.params.process_id" :key="'C'+refreshSection"/>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'pending_save',
                title: $t('warning'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t('Tienes formularios pendientes de guardar, si aceptas este mensaje se eliminaran los datos que has introducido'),
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_accept_pending_save',
                        text: $t('accept'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="$store.dispatch('acceptProcessActiveTab', '', {root: true})"
                />
                <GhButton
                    :datainput="{
                        id: 'button_close_pending_save',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="$store.dispatch('cancelProcessActiveTab', '', {root: true})"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhSubTab from "fe-gh-subtab-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'template_configuration',
        components: {
            GhButton,
            GhPopUp,
            GhSubTab
        },
        computed: {
            ...mapState(['Process']),
        },
        data(){
            return {
                haveProcess: false,
                refreshSection: 0,
                refreshSubTab: 0,
                enterRefreshSubTab: false
            }
        },
        watch: {
            'Process.sections.component.component'(){
                this.refreshSection++;
            },
            'Process.refreshSubTab'(){
                this.enterRefreshSubTab = true;
                this.refreshSubTab++;
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getProcessMain', {process_id: this.$root._route.params.process_id}, {root: true});
            if (this.Process.sections.component === undefined) {
                await this.$store.dispatch('setProcessActiveTab', {process_id: this.Process.subtabs[0]}, {root: true});
            }
            this.$router.options.GhTabsProcess = this.Process.subtabs;
            this.haveProcess = true;
        },
        destroyed() {
            this.$store.dispatch('restartState', '', {root: true});
        }
    }
</script>