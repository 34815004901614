var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_examination_item',
                title: _vm.$t('examination_item')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_examination_item"}},[_c('div',{staticClass:"gh-content-global"},[(_vm.$root._route.params.id !== null && _vm.$root._route.params.id !== undefined)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.code : ''))])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'examination_item_name',
                                        name: 'examination_item[name]',
                                        style: 'min-width: 200px; width: 100%',
                                        value: _vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.name : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description') + '*',
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'examination_item_description',
                                        name: 'examination_item[description]',
                                        required: true,
                                        value: _vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.description : ''
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'label_examination_item_group',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_group') + ':',
                                        text: _vm.$t('group'),
                                        label: _vm.$t('group'),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'examination_item_group',
                                        name: 'examination_item[group]'
                                    },"feed":_vm.getFeeds.item_groups,"selected_input":_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.group : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? Object.values(_vm.ConfigurationItemsExaminationItems.data.group).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'examination_item_picture',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_picture') + ':',
                                        text: _vm.$t('picture') + '*',
                                        label: _vm.$t('picture'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'examination_item_picture',
                                        name: 'examination_item[picture]'
                                    },"feed":_vm.getFeeds.pictures,"selected_input":_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.picture : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('picture') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? Object.values(_vm.ConfigurationItemsExaminationItems.data.picture).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('comments')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                    required: false,
                                    disabled: _vm.$root._route.params.view == 'true' ? true : false,
                                    type: 'checkbox',
                                    id: 'examination_item_comments',
                                    value: 1,
                                    name: 'examination_item[comments]',
                                    selected: _vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.comments : false,
                                }}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('enhansed')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                    required: false,
                                    disabled: _vm.$root._route.params.view == 'true' ? true : false,
                                    type: 'checkbox',
                                    id: 'examination_item_comments_enhansed',
                                    value: 1,
                                    name: 'examination_item[enhansed]',
                                    selected: _vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.enhansed : false,
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                        type: 'radio',
                                        id: 'examination_item_options_to_select',
                                        label: _vm.$t('options_to_select') + '*',
                                        title: _vm.$t('options_to_select'),
                                        text: _vm.$t('select_option_to_select') + ':',
                                        name: 'examination_item[options_to_select]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: 'margin-left: 0px;',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    },"selected_input":_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.options_to_select : [],"items":_vm.getFeeds.options_to_select},on:{"accept":_vm.getTypeSelected}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('options_to_select') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.options_to_select[0].text : ''))])]],2),(_vm.haveOptionToSelect)?[_c(_vm.component,{tag:"component"})]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('medical_note'),
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: false,
                                        id: 'examination_item_medical_note',
                                        name: 'examination_item[medical_note]',
                                        style: 'min-width: 300px; width: 300px',
                                        value: _vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.medical_note : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('medical_note') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsExaminationItems.data !== undefined ? _vm.ConfigurationItemsExaminationItems.data.medical_note : ''))])]],2)],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitExaminationItem();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }