var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhInfoBox',{key:_vm.refresh,staticStyle:{"margin-top":"6px","margin-right":"3px"},attrs:{"dataBlock":{
        id: 'info_patient_upcoming_and_past_appoinments',
        border:{ width: '0px', radius: '2px', color: '#aba4a4', style: 'solid' },
        padding:{ top: 20, bottom: 0, left: 20, right: 20 },
        boxShadow: 'rgb(61 61 61) 0px 0.5px 3px',
        backgroundColor: 'white',
        type: 'height',
        height: _vm.$parent.$parent.actualHeight
    },"dataTitle":{
        show: true,
        text: 'upcoming_and_past_appoinments',
        image: require('../../../../assets/svg/calendar.svg'),
        styleBlock: '',
        textStyle:{
            class: 'T20B',
        },
        imageStyle:{
            width: '30px',
            height: '30px',
        },
    },"dataExpansible":{
        show: _vm.showExpansible,
        animation: true,
        animationSpeed: 1000,
        textExpand: _vm.$t('show_more_appointments'),
        textCollapse: _vm.$t('show_less_appointments'),
        iconExpand: require('../../../../assets/svg/add_plus.svg'),
        iconCollapse: require('../../../../assets/svg/add_less.svg'),
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"T15_b",staticStyle:{"margin-top":"20px"},attrs:{"id":"info_patient_upcoming_and_past_appoinments_slide_selector"}},[_c('GhSlideSelector',{attrs:{"dataBlock":{
                    colors:{
                        active: '#b3dbff',
                        nonActive: '#d9edfc',
                        textActive: '#ffffff !important',
                        textNonActive: '#000000',
                    },
                    startActive: _vm.state
                },"data":_vm.SlideSelectorData}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }