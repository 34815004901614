var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{showButton: true, secondWindow: true, showLoader: 'OK', text: _vm.$t('professional')},"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'professional_' + label.id,
                    title: _vm.$t(label['name']),
                    second_title: '',
                    num_results: '',
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)})],1)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }