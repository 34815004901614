<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_general_data_nationalities_',
        }"
        :header="header"
        :data="ConfigurationGeneralDataNationality.records_data.length == 0 ? [] : ConfigurationGeneralDataNationality.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_view_nationality', {id: itemProp.id, view: true}),'configuration_nationality_department')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_nationality_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_edit_nationality', {id: itemProp.id}),'configuration_nationality_department')"
            />
            <GhAction
                :dataction="{
                    id: 'action_delete_nationality_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeConfigurationGeneralDataNationality', itemProp.id,{ 'id': itemProp.code, 'name': itemProp.name}, $t('delete_nationality'), $t('preparing_delete_nationality'), 'remove')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'configuration_general_data_new_nationality',
                        text: $t('add')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('configuration_general_data_new_nationality', {}),'configuration_nationality_department')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_nationalities",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigurationGeneralDataNationality'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'id', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        watch: {
            async 'Global.state.temp_response.response'(){
                await this.$store.dispatch('getConfigurationGeneralDataNationalities', '', {root: true});
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationGeneralDataNationalities', '', {root: true});
            this.haveData = true;
        }
    }
</script>