import Condition from "@/view/configuration/priorities/views/view_condition.vue";
import Gender from "@/view/configuration/general_data/views/view_gender.vue";

const route = [
    {
        path: '/configuration/priority/condition',
        name: 'configuration_priority_condition_new',
        component: Condition,
        props: {id: null, showMenu: false, background: true, otherTitle: 'condition'},
        children: [
            {
                path: '/configuration/priority/condition/:id',
                name: 'configuration_priority_condition_edit',
                component: Gender,
                props: {id: null, showMenu: false, background: true, otherTitle: 'condition'},
            },
        ],
    },
];

const routes = [
    ...route,
]

export default routes;