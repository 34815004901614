<template>
    <div>
        <div style="display: block; width: 100%;">
            <component v-bind:is="$parent.componentItem.label" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.description !== undefined && dataItem.item.config.description !== ''" v-bind:is="$parent.componentItem.description" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <template v-if="!checkedNA">
                <GhTextarea
                    :datalabel="{text: ''}"
                    :datainput="{
                        id: 'textarea_' + dataItem.item.config.textarea.id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_item',
                        name: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][item]',
                        value: dataItem.item.data !== undefined ? dataItem.item.data.item : '',
                        required: dataItem.item.config !== undefined && dataItem.item.config.textarea !== undefined ? dataItem.item.config.textarea.required : false,
                        edited: dataItem.item.config !== undefined && dataItem.item.config.textarea !== undefined ? dataItem.item.config.textarea.enhansed : false,
                        image: {
                            subscript: require('../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../assets/img/text/text-underline.svg')
                        }
                    }"
                    :ref="'RefItem' + processId + subprocessId + formId + sectionId + indexItem + dataItem.id + 'Item'"
                    @emiter="$parent.setChangesSection(subprocessId, $parent.dataSubprocess.id);"
                />
                <component v-bind:is="$parent.componentItem.picture" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            </template>
            <component v-if="dataItem.item.config.draw !== undefined && dataItem.item.config.draw" v-bind:is="$parent.componentItem.draw" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.comment !== undefined && dataItem.item.config.comment.show" v-bind:is="$parent.componentItem.comment" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
            <component v-if="dataItem.item.config.medical_note !== undefined && dataItem.item.config.medical_note !== ''" v-bind:is="$parent.componentItem.medical_note" :dataItem="dataItem" :indexItem="indexItem" :processId="processId" :subprocessId="subprocessId" :blockId="blockId" :formId="formId" :sectionId="sectionId"/>
        </div>
    </div>
</template>

<script>
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhMultiUpload from "fe-gh-multi-upload-lib";

    export default {
        name: 'view_free_text',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components: {
            GhTextarea,
            GhChecks,
            GhMultiUpload,
        },
        data(){
            return {
                checkedNA: this.dataItem.item.data !== undefined ? this.dataItem.item.data.na : false,
            }
        }
    }
</script>