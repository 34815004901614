var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"block","width":"100%"}},[_c(_vm.$parent.componentItem.label,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}),(_vm.dataItem.item.config.description !== undefined && _vm.dataItem.item.config.description !== '')?_c(_vm.$parent.componentItem.description,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(!_vm.checkedNA)?[_c('GhTextarea',{ref:'RefItem' + _vm.processId + _vm.subprocessId + _vm.formId + _vm.sectionId + _vm.indexItem + _vm.dataItem.id + 'Item',attrs:{"datalabel":{text: ''},"datainput":{
                    id: 'textarea_' + _vm.dataItem.item.config.textarea.id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_item',
                    name: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][item]',
                    value: _vm.dataItem.item.data !== undefined ? _vm.dataItem.item.data.item : '',
                    required: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.textarea !== undefined ? _vm.dataItem.item.config.textarea.required : false,
                    edited: _vm.dataItem.item.config !== undefined && _vm.dataItem.item.config.textarea !== undefined ? _vm.dataItem.item.config.textarea.enhansed : false,
                    image: {
                        subscript: require('../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../assets/img/text/text-underline.svg')
                    }
                }},on:{"emiter":function($event){return _vm.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.dataSubprocess.id);}}}),_c(_vm.$parent.componentItem.picture,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}})]:_vm._e(),(_vm.dataItem.item.config.draw !== undefined && _vm.dataItem.item.config.draw)?_c(_vm.$parent.componentItem.draw,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.comment !== undefined && _vm.dataItem.item.config.comment.show)?_c(_vm.$parent.componentItem.comment,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e(),(_vm.dataItem.item.config.medical_note !== undefined && _vm.dataItem.item.config.medical_note !== '')?_c(_vm.$parent.componentItem.medical_note,{tag:"component",attrs:{"dataItem":_vm.dataItem,"indexItem":_vm.indexItem,"processId":_vm.processId,"subprocessId":_vm.subprocessId,"blockId":_vm.blockId,"formId":_vm.formId,"sectionId":_vm.sectionId}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }