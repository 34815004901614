var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_start_date',
                text: _vm.$t('date_from'),
                style: 'margin-left: 30px;'
            },"datainput":{
                id: 'search_start_date',
                name: 'historical[start_date]',
                value: '',
            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_date_end',
                text: _vm.$t('date_to'),
                style: '',
            },"datainput":{
                id: 'search_end_date',
                name: 'historical[end_date]',
                value: '',
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhMultipleSearch',{attrs:{"datalabel":{
                text: _vm.$t('opened_by'),
                style: 'margin-left: 30px;',
                class: 'T15_b',
                id: 'label_historical_user'
            },"datainput":{
                id: 'historical_user',
                name: 'historical[user][name]',
                nameS:'historical[user][surname]',
                nameId:'historical[user][id]',
                style: 'min-width: 200px;',
                placeholder: _vm.$t('name'),
                placeholderS: _vm.$t('surname'),
                result_1: 'first_name',
                result_2: 'last_name',
                result_id: 'id',
                href: _vm.dispatchUserResponsible
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                id: 'label_search_identificator',
                text: _vm.$t('identifier'),
                style: 'margin-left: 30px;',
                class: 'T15_b'
            },"datainput":{
                id: 'input_search_name',
                name: 'historical[identificator]',
                style: 'width:280px; min-width:280px; max-height: 17px;',
                type: 'text',
            }}}),_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                id: 'selected_support_category',
                class: 'T15_b',
                subtitle: _vm.$t('select_category') + ':',
                text: _vm.$t('category'),
                label: _vm.$t('category'),
                type: 'checkbox',
                columns: 3,
                required: true,
                style: 'margin-left:614px;',

            },"datainput":{
                name: 'historical[category][]'
            },"feed":_vm.getFeeds.feed_support_categories}})],1),_c('div',{staticClass:"gh-row-content"},[_c('div',{staticStyle:{"width":"50%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'selected_support_assignation',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_assignment'),
                    text: _vm.$t('assigned_to'),
                    label: _vm.$t('assigned_to'),
                    type: 'checkbox',
                    columns: 3,
                    required: true,
                    style: 'margin-left: 30px;',
                    popupStyleContent:'height:60px;',
                },"datainput":{
                    name: 'historical[assigned_to][]',
                },"feed":_vm.getFeeds.feed_support_asigned}})],1),_c('GhDataSelector',{attrs:{"datablock":{
                id: 'selected_support_priority',
                class: 'T15_b',
                subtitle: _vm.$t('select_priority') + ':',
                text: _vm.$t('priority'),
                label: _vm.$t('priority'),
                type: 'checkbox',
                columns: 3,
                required: true,
                style: 'display: flex; margin-left:-12px;',
                popupStyleContent: 'height:90px;'
            },"datainput":{
                name: 'historical[priority][]'
            },"feed":_vm.getFeeds.feed_support_priority}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }