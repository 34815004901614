var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.actualData.data),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_field_creation_' + _vm.actualData.type + '_' + item.id,
                title: item.name,
                extraAction: true,
                style: '',
                extraSubStyle: 'margin-left: auto !important; margin-right: 0 !important;',
                contentStyle: ''
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.components.partial_item_forms,{key:_vm.actualData.refresh,tag:"component",attrs:{"actualData":{allData: _vm.actualData.data, parentIndex: index, id: item.id, data: item, type: _vm.actualData.type}}})]},proxy:true},{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'action_edit_creation_' + _vm.actualData.type + '_' + item.id,
                        text: _vm.$t('edit_' + (_vm.actualData.type === 'process' ? 'subprocess' : 'section')),
                        icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                    }},on:{"click":function($event){return _vm.$parent.$parent.getSectionSubprocess(false, item, index)}}}),_c('GhAction',{staticStyle:{"margin-right":"23px"},attrs:{"dataction":{
                        id: 'action_delete_creation_' + _vm.actualData.type + '_' + item.id,
                        text: _vm.$t('delete'),
                        icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeSectionSubprocess', item.id, {'name': item.name, 'data': {index: index, data: _vm.actualData}}, _vm.$t(_vm.actualData.type === 'process' ? 'delete_subprocess' : 'delete_section'), _vm.$t(_vm.actualData.type === 'process' ? 'preparing_delete_subprocess' : 'preparing_delete_section'), 'remove');}}})]},proxy:true}],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }