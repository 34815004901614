var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'configuration_support_schedule'
    },"header":_vm.header,"data":_vm.ConfigurationSupportSchedule.records_data.length == 0 ? [] : _vm.ConfigurationSupportSchedule.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_support_view_schedule', {id: itemProp.id, view: true}),'configuration_support_schedule')}}},[_vm._v(" "+_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_schedule_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('configuration_support_edit_schedule', {id: itemProp.id}),'configuration_support_schedule')}}})]}}],null,false,1581573283)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }