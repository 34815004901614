<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle)
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_assignment',
                    title: $t('assignment'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_assignment">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_group_assignment_name',
                                            text: $t('assignation_group') + '*',
                                            style: 'width: 138px; min-width: 138px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_group_assignment_name',
                                            name: 'group[name]',
                                            style: 'max-height: 17px;',
                                            type: 'text',
                                            value: ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.name ? ConfigurationSupportAssignment.data.name : '',
                                            required: true,
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 138px; min-width: 138px; text-align: right;">{{ $t('assignation_group') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 100%;">{{ ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.name ? ConfigurationSupportAssignment.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description') ,
                                            class: 'T15',
                                            styles: 'width: 136px; min-width: 136px; text-align: right; margin-right:12px;',
                                        }"
                                        :datainput="{
                                            id: 'textarea_group_assignment_description',
                                            name: 'group[description]',
                                            value:  ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.description ? ConfigurationSupportAssignment.data.description : '',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px;  width: 138px; min-width: 138px; text-align: right;">{{ $t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 100%;">{{ ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.description ? ConfigurationSupportAssignment.data.description : '' }}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_group_assignment_email',
                                            text: $t('email'),
                                            style: 'width: 138px; min-width: 138px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            id: 'input_group_assignment_email',
                                            name: 'group[email]',
                                            style: 'max-height: 17px;',
                                            type: 'text',
                                            value: ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.email ? ConfigurationSupportAssignment.data.email : '',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 138px; min-width: 138px; text-align: right;">{{ $t('email') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 100%;">{{ ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.email ? ConfigurationSupportAssignment.data.email : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <div style="margin-right: 40px;">
                                    <GhDropDownField
                                        :dataBlock="{
                                            id: 'drop_users_assignment',
                                            style:'width: 100%; margin-right: 0px;',
                                            title: $t('users'),
                                            subTitle: $root._route.params.id ? ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.users && ConfigurationSupportAssignment.data.users.length !== 0 ? ConfigurationSupportAssignment.data.users.length + ' ' + $t('Resultados') :  0 + ' ' + $t('Resultados') : users.length !== 0 ? users.length + ' ' + $t('Resultados') : 0 + ' ' + $t('Resultados'),
                                            isDrop: true
                                        }"
                                    >
                                        <template v-slot:content>
                                            <GhTableCommon v-if="haveData"
                                                :extratable="{
                                                    id: 'conf_supp_assignment_users'
                                                }"
                                                :header="header"
                                                :data="$root._route.params.id ? ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.users && ConfigurationSupportAssignment.data.users.length !== 0 ? ConfigurationSupportAssignment.data.users : [] : users"
                                            >
                                                <template v-slot:footer v-if="!$root._route.params.view">
                                                    <div class="centered">
                                                        <GhButton
                                                            :datainput="{
                                                                id: 'btn_add_assignment_users',
                                                                text: $t('add_users')
                                                            }"
                                                            @click="searchUsers($root._route.params.id ? ConfigurationSupportAssignment.data && ConfigurationSupportAssignment.data.users && ConfigurationSupportAssignment.data.users.length !== 0 ? ConfigurationSupportAssignment.data.users : [] : users)"
                                                        />
                                                    </div>
                                                </template>
                                            </GhTableCommon>
                                        </template>
                                    </GhDropDownField>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_accept',
                        class: 'T19 container-md_button_content',
                        text: $t('accept')
                    }"
                    @click="setAssignment()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhLocation from 'fe-gh-location-lib'
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhTextarea from 'fe-gh-textarea-lib'
    import GhButton from 'fe-gh-button-lib'
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhDropDownField from 'fe-gh-drop-down-field-lib'
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_location",
        components:{
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhButton,
            GhTextarea,
            GhDropDownField,
            GhTableCommon
        },
        data(){
            return{
                type: '',
                haveData: false,
                users: [],
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style:'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
                headerSearch: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
            }
        },
        computed:{
            ...mapState(['ConfigurationSupportAssignment'])
        },
        async beforeMount() {
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            this.type = this.$root._route.name.split('configuration_support_')[1].split('_assignment')[0];

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationSupportAssignments', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.ConfigurationSupportAssignment.data = [];
            }

            this.haveData = true;
        },
        methods:{
            async setAssignment(){
                if(this.Global.checkRequired('form_assignment') == 'OK') {
                    const formData = new FormData(document.getElementById('form_assignment'));
                    await this.$store.dispatch('setDataConfigurationSupportAssignments', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.windowClose();
                }
            },
            searchUsers(data){
                var then = this;
                var params = {
                    idblock: 'users',
                    actionsSearch: '',
                    actionsSelected: '',
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    allmarkoption: false,
                    supportPermision: true,
                    textuserselected: this.$t('Usuarios seleccionados'),
                    img: require('../../../../assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('No se ha aplicado ningún filtro'),
                    textNoResult: this.$t('No se ha encontrado ningún resultado'),
                    nameSlot: 'view_assignment',
                    nameStore: 'ConfigurationSupportAssignment',
                    ResultSearch: 'searchData',
                    dataSelected: data,
                    actionresult: 'loadSearchAssignmentUsers',
                    textlocation: this.$t('Añadir Usuarios'),
                    headersearch: JSON.stringify(this.headerSearch),
                    headerselected: JSON.stringify(this.headerSearch),
                    formId: 'user',
                    actionsearch: 'getSearchAssignmentUsers',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                }

                localStorage.setItem('Params_' + 'users', JSON.stringify(params));
                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchView',{ idblock: params.idblock }),'usersS');
                window.UpdatedSelect = function (e) {
                    if(then.$root._route.params.id == undefined){
                        then.users = e;
                        then.$store.state.ConfigurationSupportAssignment.records.added = e;
                    }else{
                        then.$store.dispatch('setTemporalAssignmentUsers', {data: e}, {root: true});
                    }
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {}
                window.events = function () {}
            },
        }
    }
</script>

<style>
    #drop_users_assignment > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_users_assignment > div > div > .definition-field_header-text {
        width: 115px;
        display: flex;
        flex-flow: row-reverse;
    }
</style>