import { render, staticRenderFns } from "./acreditation.vue?vue&type=template&id=f6752cbe"
import script from "./acreditation.vue?vue&type=script&lang=js"
export * from "./acreditation.vue?vue&type=script&lang=js"
import style0 from "./acreditation.vue?vue&type=style&index=0&id=f6752cbe&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports