<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('schedule_visit'),
                icon: require('../../../assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'schedule_visit',
                    title: $t('schedule_visit')
                }"
            >
                <template v-slot:content>
                    <form id="form_schedule_visit" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content" id="row_schedule_ohip">
                                <template v-if="!viewOhip">
                                    <GhSimpleSearch
                                        :datalabel="{
                                            text: $t('ohip') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b',
                                            id: 'label_schedule_ohip'
                                        }"
                                        :datainput="{
                                            required: true,
                                            class: '',
                                            id: 'schedule_ohip',
                                            name: 'schedule[ohip][text]',
                                            nameId: 'schedule[ohip][value]',
                                            style: 'width: 204px',
                                            value: ohip,
                                            valueId: ohip_id,
                                            placeholder: '',
                                            href: dispatchOHIP
                                        }"
                                        ref="RefSearchOHIP"
                                        @blur="checkOhip"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('ohip') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 140px; min-width: 140px;">{{ohip}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" :key="'A' + refreshUser">
                                <template v-if="!viewUser">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'schedule_name',
                                            name: 'schedule[name]',
                                            style: 'width: 200px;',
                                            value: name,
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('surname') + '*',
                                            style: 'width: 100px; min-width: 100px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'schedule_surname',
                                            name: 'schedule[surname]',
                                            style: 'width: 200px;',
                                            value: surname,
                                            type: 'text',
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('gender') + '*',
                                            style: 'width: 100px; min-width: 100px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'schedule_gender',
                                            name: 'schedule[gender]',
                                            style: 'width: 100px;',
                                            value: gender,
                                            type: 'text',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 140px; min-width: 140px;">{{name}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 142px; min-width: 142px;">{{surname}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('gender') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{gender}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" :key="'B' + refreshUser">
                                <template v-if="!viewUser">
                                    <GhCalendar
                                        :datalabel="{
                                            id: 'label_schedule_date_of_birth',
                                            text: $t('date_of_birth'),
                                            style: 'width: 150px; min-width: 150px; text-align: right;'
                                        }"
                                        :datainput="{
                                            id: 'schedule_date_of_birth',
                                            name: 'schedule[date_of_birth]',
                                            value: date_of_birth,
                                            disabled_date: 'after',
                                            range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('date_of_birth') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{date_of_birth}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" v-for="(item, index) in employees" :key="'C' + index">
                                <template v-if="!viewMD">
                                    <GhSearchWindow
                                        :dataBlock="{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'result_md_by_center',
                                            nameStore: 'getFeeds',
                                            imagelocation: require('../../../assets/gh_new_dessign/floating.svg'),
                                            id: item.id,
                                            text: $t(item.id) + '*',
                                            textlocation: $t('add') + ' ' + $t(item.id),
                                            actionresult: 'loadResultMDByCenter',
                                            nameResult: 'schedule[' + item.id + ']',
                                            supportPermision: false,
                                            required: true
                                        }"
                                        :mainSelector="{
                                            name: 'Formsearchcompany',
                                            formId: 'search',
                                            actionsearch: 'getSearchMDByCenter'
                                        }"
                                        :tableResolt="{
                                            header: header,
                                            data: summaryMD
                                        }"
                                        :tableSelected="{
                                            headersummary: headersummary,
                                            selected: selectedMD
                                        }"
                                        @acept="checkMD"
                                        ref="RefCheckMD"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(item.id) + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{summary[item.id]}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!viewTypeOfVisit">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'schedule_type_of_visit',
                                            class: 'T15_b',
                                            subtitle: $t('select_type_of_visit') + ':',
                                            text: $t('type_of_visit') + '*',
                                            label: $t('type_of_visit'),
                                            type: 'radio',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'schedule_type_of_visit',
                                            name: 'schedule[type_of_visit]'
                                        }"
                                        :feed="getFeeds.type_of_visits"
                                        :selected_input="selectedTypeOfVisits"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type_of_visit') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{selectedTypeOfVisits}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" v-if="showLaterThan">
                                <template v-if="!viewUser">
                                    <GhCalendar
                                        :datalabel="{
                                            id: 'label_schedule_no_lather_than',
                                            text: $t('no_lather_than'),
                                            style: 'width: 150px; min-width: 150px; text-align: right;'
                                        }"
                                        :datainput="{
                                            id: 'schedule_no_lather_than',
                                            name: 'schedule[no_lather_than]',
                                            value: '',
                                            disabled_date: 'before',
                                            range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('no_lather_than') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{VisitsFollowUpVisits.data !== undefined ? VisitsFollowUpVisits.data.no_lather_than : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" v-if="date.show" :key="'D' + refreshDate">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('date') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{dateVisit}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 140px; min-width: 140px; text-align: right;">{{$t('time') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{timeVisit.hour !== '' && timeVisit.minute !== '' ? timeVisit.hour + ':' + timeVisit.minute : ''}}</label>
                                <input id="input_schedule_date" name="schedule[date]" type="hidden" :value="dateVisit" :class="date.required ? 'required' : ''" :label_required="$t('date') + '*'">
                                <input id="input_time_schedule_time_hour" name="schedule[time][hour]" type="hidden" :value="timeVisit.hour" :class="date.required ? 'required' : ''" :label_required="$t('time') + '*'">
                                <input id="input_time_schedule_time_minute" name="schedule[time][minute]" type="hidden" :value="timeVisit.minute" :class="date.required ? 'required' : ''" :label_required="$t('time') + '*'">
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!viewReason">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('reason'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'schedule_reason',
                                            name: 'schedule[reason]',
                                            value: reason
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('reason') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{reason}}</label>
                                </template>
                            </div>

                            <template v-if="showScheduleMD">
                                <GhScheduleCalendar
                                    :dataBlock="{
                                        id: 'schedule_md_month',
                                        backgroundColorNumber: '#ffffff',
                                        widthHour: widthHour,
                                        colors: {0:'rgb(156, 178, 198, 0.7)',1:'rgb(99, 204, 110, 0.7)',2:'rgb(253, 245, 121, 0.7)',3:'rgb(255, 103, 103, 0.7)'}
                                    }"
                                    :data="CalendarProfessionals.calendar_professional_array[actualMDID]"
                                    @prevM="toPrev(CalendarProfessionals.calendar_professional_array[actualMDID]['prevMonth'])"
                                    @days="toDays"
                                    @nextM="toNext(CalendarProfessionals.calendar_professional_array[actualMDID]['nextMonth'])"
                                    ref="RefSchedule"
                                    :key="refreshSchedule"
                                />
                            </template>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px; padding-bottom: 10px;">
                <GhButton
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitSchedule();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhSimpleSearch from "fe-gh-simple-search-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhScheduleCalendar from "fe-gh-schedule-calendar-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_schedule_visit",
        components: {
            GhDataSelector,
            GhSearchWindow,
            GhCalendar,
            GhButton,
            GhInputTextField,
            GhLocation,
            GhNoDropSelector,
            GhSimpleSearch,
            GhTextarea,
            GhScheduleCalendar,
        },
        computed: {
            ...mapState(['getFeeds', 'VisitsUpcomingPatients', 'VisitsFollowUpVisits', 'CalendarProfessionals'])
        },
        data(){
            return {
                haveData: false,
                dispatchOHIP: 'getOhipSearch',
                ohip_id: '',
                ohip: '',
                name: '',
                surname: '',
                gender: '',
                date_of_birth: '',
                summaryMD: [],
                selectedMD: [],
                selectedTypeOfVisits: [],
                dateVisit: '',
                timeVisit: {
                    hour: '',
                    minute: ''
                },
                reason: '',
                refreshUser: 0,
                refreshDate: 0,
                employees: [
                    {id: 'md'}
                ],
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'radio', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 60px; width: 50%;'},
                    {text: this.$t('surname'), field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
                summary: {
                    md: ''
                },
                viewOhip: false,
                viewUser: false,
                viewMD: false,
                viewTypeOfVisit: false,
                viewLatherThan: false,
                viewReason: false,
                showLaterThan: false,
                showScheduleMD: false,
                date:{
                    show: false,
                    required: false,
                    disabled_date: 1,
                    range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear()
                },
                actualMDID: '',
                widthHour: 16,
                refreshSchedule: 0
            }
        },
        watch:{
            'Global.state.temp_response.response'(date){
                this.dateVisit = date.date;
                this.timeVisit.hour = date.hour;
                this.timeVisit.minute = date.minute;
                this.refreshDate++;
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            switch (this.$root._route.params.type){
                case undefined:
                    this.date.show = true;
                    this.date.required = true;
                    break;
                case 'reschedule':
                    await this.$store.dispatch('getRescheduleUpcomingPatient', {id: this.$root._route.params.id}, {root: true});

                    this.ohip = this.VisitsUpcomingPatients.data.ohip;
                    this.name = this.VisitsUpcomingPatients.data.name;
                    this.surname = this.VisitsUpcomingPatients.data.surname;
                    this.gender = this.VisitsUpcomingPatients.data.gender;
                    this.date_of_birth = this.VisitsUpcomingPatients.data.date_of_birth;
                    this.summaryMD = this.VisitsUpcomingPatients.data !== undefined && this.VisitsUpcomingPatients.data.md !== undefined && this.VisitsUpcomingPatients.data.md.summary !== undefined ? this.VisitsUpcomingPatients.data.md.summary : [];
                    this.selectedMD = this.VisitsUpcomingPatients.data !== undefined && this.VisitsUpcomingPatients.data.md !== undefined && this.VisitsUpcomingPatients.data.md.selecteds !== undefined ?  this.VisitsUpcomingPatients.data.md.selecteds : []
                    this.selectedTypeOfVisits = this.VisitsUpcomingPatients.data !== undefined && this.VisitsUpcomingPatients.data.type_of_visit !== undefined ? this.VisitsUpcomingPatients.data.type_of_visit : [];
                    this.dateVisit = this.VisitsUpcomingPatients.data.date;
                    this.timeVisit.hour = this.VisitsUpcomingPatients.data.time.hour;
                    this.timeVisit.minute = this.VisitsUpcomingPatients.data.time.minute;
                    this.reason = this.VisitsUpcomingPatients.data.reason;

                    this.viewOhip = true;
                    this.viewUser = true;
                    this.date.show = true;
                    this.date.required = true;
                    this.refreshDate++;

                    this.actualMDID = this.summaryMD[0].id;
                    this.checkMD(this.actualMDID);
                    break;
                case 'follow-up':
                    await this.$store.dispatch('getScheduleFollowUpVisit', {id: this.$root._route.params.id}, {root: true});

                    this.ohip = this.VisitsFollowUpVisits.data.ohip;
                    this.name = this.VisitsFollowUpVisits.data.name;
                    this.surname = this.VisitsFollowUpVisits.data.surname;
                    this.gender = this.VisitsFollowUpVisits.data.gender;
                    this.date_of_birth = this.VisitsFollowUpVisits.data.date_of_birth;

                    var summaryID = 0;
                    this.employees.forEach((item) => {
                        if(this.VisitsFollowUpVisits.data !== undefined && this.VisitsFollowUpVisits.data[item.id] !== undefined && this.VisitsFollowUpVisits.data[item.id].summary !== undefined){
                            this.VisitsFollowUpVisits.data[item.id].summary.forEach((values) => {
                                this.summary[item.id] += values.name + ' ' + values.surname + ', ';
                                summaryID = values.id;
                            });
                            this.summary[item.id] = this.summary[item.id].substring(0, this.summary[item.id].length - 2);
                        }
                    });

                    var fechaInicio = new Date();

                    var month = (parseInt(fechaInicio.getMonth()) + 1 < 10 ? '0' : '') + (parseInt(fechaInicio.getMonth()) + 1);

                    this.selectedTypeOfVisits = this.VisitsFollowUpVisits.data !== undefined && this.VisitsFollowUpVisits.data.type_of_visit !== undefined ? Object.values(this.VisitsFollowUpVisits.data.type_of_visit).join() : [];

                    fechaInicio = fechaInicio.setHours(0, 0, 0, 0);

                    var date = this.VisitsFollowUpVisits.data.no_lather_than.split('/');
                    var fechaFin = new Date(date[2]+'-'+date[1]+'-'+date[0]);
                    fechaFin = fechaFin.setHours(0, 0, 0, 0);

                    var diff = fechaFin - fechaInicio;

                    diff = diff / (1000*60*60*24);

                    this.reason = this.VisitsFollowUpVisits.data.reason;

                    this.viewOhip = true;
                    this.viewUser = true;
                    this.viewMD = true;
                    this.viewTypeOfVisit = true;
                    this.date.show = true;
                    this.date.required = true;
                    this.date.disabled_date = 2;
                    this.date.range = diff;
                    this.viewLatherThan = true;
                    this.showLaterThan = true;
                    this.viewReason = true;
                    this.refreshDate++;

                    this.actualMDID = summaryID;
                    this.checkMD(this.actualMDID, month, this.VisitsFollowUpVisits.data !== undefined ? this.VisitsFollowUpVisits.data.no_lather_than : '');
                    break;
            }

            await this.$store.dispatch('getFeedTypeOfVisits', '', {root: true});

            this.haveData = true;
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods:{
            myEventHandler(e) {
                if(e.type == 'resize' && this.$refs.RefSchedule !== undefined){
                    this.resizeSchedule();
                }
            },
            resizeSchedule(){
                var fullWidth = this.$refs.RefSchedule.$el.clientWidth - 250;


                var newWidth = fullWidth / this.$refs.RefSchedule.$el.children[0].children[1].children.length;

                this.widthHour = newWidth - 8;

                this.$refs.RefSchedule.$el.children[0].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule.$el.children[0].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule.$el.children[0].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule.$el.children[0].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule.$el.children[0].children[2].style.maxWidth = '100px';
                this.$refs.RefSchedule.$el.children[1].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule.$el.children[1].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule.$el.children[1].children[2].style.minWidth = '106px';
                this.$refs.RefSchedule.$el.children[1].children[2].style.maxWidth = '106px';
                this.$refs.RefSchedule.$el.children[2].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule.$el.children[2].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule.$el.children[2].children[1].style.minWidth = fullWidth + 'px';
                this.$refs.RefSchedule.$el.children[2].children[1].style.maxWidth = fullWidth + 'px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule.$el.children[3].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule.$el.children[3].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule.$el.children[3].children[2].style.maxWidth = '100px';

                for (var i = 0; i < this.$refs.RefSchedule.$el.children[0].children[1].children.length; i++){
                    for(var x = 0; x < this.$refs.RefSchedule.$el.children[0].children[1].children[i].children.length; x++){
                        this.$refs.RefSchedule.$el.children[0].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[0].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[3].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[3].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].setAttribute('style', this.$refs.RefSchedule.$el.children[1].children[1].children[i].children[0].style.cssText + 'font-size: 11px !important');
                    }
                }
            },
            checkOhip(){
                var ref = this.$refs.RefSearchOHIP;
                var block = this;
                setTimeout(function (){
                    if(ref.data.length === 0){
                        block.ohip = ref.datainput.value;
                        block.ohip_id = 'No id';
                        block.name = '';
                        block.surname = '';
                        block.gender = '';
                        block.date_of_birth = '';
                        block.viewUser = false;
                    }else{
                        var haveData = false;
                        for (var i = 0; i < ref.data.length; i++) {
                            if (ref.data[i].text.toUpperCase() === ref.datainput.value.toUpperCase()) {
                                block.ohip = ref.data[i].text;
                                block.name = ref.data[i].name;
                                block.surname = ref.data[i].surname;
                                block.gender = ref.data[i].gender;
                                block.date_of_birth = ref.data[i].date_of_birth;

                                if (ref.$el.children[1].children[1]._value === undefined || ref.$el.children[1].children[1]._value == '') {
                                    block.ohip_id = ref.data[i].value;
                                }

                                block.refreshUser++;
                                haveData = true;
                                block.viewUser = true;
                            }
                        }

                        if(!haveData){
                            block.ohip = ref.datainput.value;
                            block.ohip_id = 'No id';
                            block.name = '';
                            block.surname = '';
                            block.gender = '';
                            block.date_of_birth = '';
                            block.viewUser = false;
                        }
                    }
                },300);
            },
            async checkMD(id, month, no_lather_than){
                this.showScheduleMD = false;

                if(id !== undefined || (this.$refs.RefCheckMD !== undefined && this.$refs.RefCheckMD[0].selectedData.length !== 0)){
                    this.actualMDID = this.$refs.RefCheckMD === undefined ? id : this.$refs.RefCheckMD[0].selectedData[0].id;

                    if(this.$root._route.params.type !== 'follow-up') {
                        await this.$store.dispatch('getCalendarProfessional', {id: this.actualMDID, start_today: true}, {root: true});
                    }else{
                        await this.$store.dispatch('getCalendarProfessional', {id: this.actualMDID, actual_month: month, no_lather_than: no_lather_than, start_today: true}, {root: true});
                    }

                    var that = this;
                    setTimeout(function(){
                        that.resizeSchedule();
                    },5);
                    this.showScheduleMD = true;
                }else{
                    this.showScheduleMD = false;
                }
                this.refreshSchedule++;
            },
            async submitSchedule(){
                if(this.Global.checkRequired('form_schedule_visit') == 'OK'){
                    const formData = new FormData(document.getElementById('form_schedule_visit'));
                    await this.$store.dispatch('setSchedulePatient', {
                        id: this.$root._route.params.id,
                        type: this.$root._route.params.type,
                        formData: [...formData]
                    }, {root: true});

                    this.Global.windowClose();
                }
            },
            async toPrev(item){
                await this.$store.dispatch('updateCalendarProfessional', {id: this.actualMDID, actual_month: item.id, no_lather_than: this.VisitsFollowUpVisits.data !== undefined ? this.VisitsFollowUpVisits.data.no_lather_than : '', start_today: true}, {root: true});
                this.refreshSchedule++;
                var that = this;
                setTimeout(function() {
                    that.resizeSchedule();
                },0);
            },
            toDays(item){
                this.Global.windowOpen(this.Global.openSecondWindow('view_professional', {id: this.actualMDID, name: this.$t('md'), date: item.day + '/' + item.month + '/' + item.year, from: 'schedule'}),'professional_data');
            },
            async toNext(item){
                await this.$store.dispatch('updateCalendarProfessional', {id: this.actualMDID, actual_month: item.id, no_lather_than: this.VisitsFollowUpVisits.data !== undefined ? this.VisitsFollowUpVisits.data.no_lather_than : '', start_today: true}, {root: true});
                this.refreshSchedule++;
                var that = this;
                setTimeout(function() {
                    that.resizeSchedule();
                },0);
            }
        }
    }
</script>

<style>
    #row_schedule_ohip > div > div{
        z-index: 1;
    }

    #schedule_type_of_visit_selector_label{
        width: 150px;
        text-align: end;
    }
</style>