<template>
    <div>
        <GhNoDropSelector
            :datablock="{
                id: 'view_profiles',
                title: $t('profiles')
            }"
        >
            <template v-slot:content>
                <form id="profiles_permissions" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'name',
                                        name: 'profile[name]',
                                        style: 'width: 40%',
                                        value: PermissionsProfiles.data !== undefined ? PermissionsProfiles.data.name : '',
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('name')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{PermissionsProfiles && PermissionsProfiles.data && PermissionsProfiles.data.name ? PermissionsProfiles.data.name : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        class: 'T15_b',
                                        styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                    }"
                                    :datainput="{
                                        id: 'description',
                                        name: 'profile[description]',
                                        required: false,
                                        value: PermissionsProfiles.data !== undefined ? PermissionsProfiles.data.description : '',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('description')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{PermissionsProfiles && PermissionsProfiles.data && PermissionsProfiles.data.description ? PermissionsProfiles.data.description : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_groups',
                                        class: 'T15_b',
                                        subtitle: $t('select_group') + ':',
                                        text: $t('groups') + '*',
                                        label: $t('groups'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'groups',
                                        name: 'profile[groups][]'
                                    }"
                                    :feed="getFeeds.feed_groups"
                                    :selected_input="PermissionsProfiles && PermissionsProfiles.data && PermissionsProfiles.data.groups ? PermissionsProfiles.data.groups : []"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('groups')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{PermissionsProfiles && PermissionsProfiles.data && PermissionsProfiles.data.groups ? Object.values(PermissionsProfiles.data.groups).join() : ''}}</label>
                            </template>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div  class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="submitPermsProfiles"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="$router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: 'view_profiles',
        computed: {
            ...mapState(['getFeeds','PermissionsProfiles']),
        },
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhDataSelector,
            GhButton
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedGroups', '', {root: true});

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getDataPermissionsProfiles', {id: this.$root._route.params.id}, {root: true});
            }else{
                this.PermissionsProfiles.data = [];
            }

            this.haveData = true;
        },
        methods: {
            async submitPermsProfiles(){
                if(this.Global.checkRequired('profiles_permissions') == 'OK') {
                    const formData = new FormData(document.getElementById('profiles_permissions'));
                    await this.$store.dispatch('setPermissionsProfiles', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #label_groups_selector_label{
        width: 150px;
        text-align: end;
    }
</style>