import Nationalities from "@/view/configuration/general_data/views/view_nationality.vue";

const routes = [
    {
        path: '/configuration/general-data/nationality',
        name: 'configuration_general_data_new_nationality',
        component: Nationalities,
        props: {id: null, showMenu: false, background: true, otherTitle: 'nationality'},
        children: [
            {
                path: '/configuration/general-data/nationality/:id',
                name: 'configuration_general_data_edit_nationality',
                component: Nationalities,
                props: {id: null, showMenu: false, background: true, otherTitle: 'nationality'},
            },
            {
                path: '/configuration/general-data/nationality/:id/:view',
                name: 'configuration_general_data_view_nationality',
                component: Nationalities,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'nationality'},
            }
        ],
    },

];

export default routes;