import ScheduleVisit from "@/view/visits/views/view_schedule_visit.vue";

const routes = [
    {
        path: '/visits/schedule',
        name: 'new_visit_schedule',
        component: ScheduleVisit,
        props: {id: null, showMenu: false, background: true},
        children: [
            {
                path: '/visits/schedule/:type/:id',
                name: 'edit_visit_schedule',
                component: ScheduleVisit,
                props: {id: null, type: null, showMenu: false, background: true},
            }
        ],
    },

];

export default routes;