import SectionGeneralData from "@/view/configuration/general_data/section/section_general_data.vue";
import Languages from "./languages/route";
import Nationalities from "./nationality/route";
import Relationships from "./relationship/route";
import Genders from "./gender/route";

const route = [
    {
        path: '/configuration/general-data',
        name: 'configuration_general_data',
        component: SectionGeneralData,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Languages,
    ...Nationalities,
    ...Relationships,
    ...Genders
]

export default routes;