<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_equipment',
                    title: $t('equipment')
                }"
            >
                <template v-slot:content>
                    <form id="form_equipment" v-if="haveData">
                        <div style="display: flex">
                            <div class="gh-content-global" style="width: 170px">
                                <div style="width: 168px; height: 168px;">
                                    <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
                                </div>
                            </div>
                            <div class="gh-content-global" style="width: 100%">
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('sn') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'equipment_sn',
                                                name: 'equipment[sn]',
                                                style: 'width: 200px',
                                                value: values.sn,
                                                type: 'text'
                                            }"
                                            ref="RefSn"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sn') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.sn : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'equipment_name',
                                                name: 'equipment[name]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: values.name,
                                                type: 'text'
                                            }"
                                            ref="RefName"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.name : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhTextarea
                                            :datalabel="{
                                                text: $t('description'),
                                                styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_description',
                                                name: 'equipment[description]',
                                                value: values.description
                                            }"
                                            ref="RefDescription"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.description : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'equipment_type',
                                                class: 'T15_b',
                                                subtitle: $t('select_equipment_type') + ':',
                                                text: $t('equipment_type') + '*',
                                                label: $t('equipment_type'),
                                                type: 'radio',
                                                columns: 3,
                                                required: true,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_type',
                                                name: 'equipment[type]'
                                            }"
                                            :feed="getFeeds.type"
                                            :selected_input="ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.type : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? Object.values(ConfigurationVariableEquipments.data.type).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{$t('picture') + '*'}}</label>
                                    <GhEasyUpload
                                        :extrablock="{
                                            id: 'equipment_picture',
                                            class: '',
                                            name_hidden: 'equipment[picture]',
                                            id_hidden: 'equipment_picture',
                                            icon_attach: ''
                                        }"
                                        :datalabel="{
                                            id: 'label_equipment_picture',
                                            style: 'margin-right: 5px'
                                        }"
                                        :datainput="{
                                            view: $root._route.params.view,
                                            accept: 'image/png, image/gif, image/jpeg',
                                            text: $t('upload'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            file_name: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.picture !== undefined ? ConfigurationVariableEquipments.data.picture.file_name : '',
                                            file_path: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.picture !== undefined ? ConfigurationVariableEquipments.data.picture.file_path : '',
                                            file_download: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.picture !== undefined ? ConfigurationVariableEquipments.data.picture.file_download : '',
                                            required: true
                                        }"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'equipment_departments',
                                                class: 'T15_b',
                                                subtitle: $t('select_departments') + ':',
                                                text: $t('departments'),
                                                label: $t('departments'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_departments',
                                                name: 'equipment[departments][]'
                                            }"
                                            :feed="getFeeds.departments"
                                            :selected_input="ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.departments : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('departments') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? Object.values(ConfigurationVariableEquipments.data.departments).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'equipment_specialties',
                                                class: 'T15_b',
                                                subtitle: $t('select_specialties') + ':',
                                                text: $t('specialties'),
                                                label: $t('specialties'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_specialties',
                                                name: 'equipment[specialties][]'
                                            }"
                                            :feed="getFeeds.specialties"
                                            :selected_input="ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.specialties : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? Object.values(ConfigurationVariableEquipments.data.specialties).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'equipment_room',
                                                class: 'T15_b',
                                                subtitle: $t('select_room') + ':',
                                                text: $t('room'),
                                                label: $t('room'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_room',
                                                name: 'equipment[room][]'
                                            }"
                                            :feed="getFeeds.room"
                                            :selected_input="ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.room : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('room') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? Object.values(ConfigurationVariableEquipments.data.room).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <label class="gh_text_field-label T15_b" style="width: 150px; text-align: end; margin-right: 10px;">{{$t('status')}}</label>
                                        <GhChecks
                                            :datalabel="{}"
                                            :datainput="{
                                                required: false,
                                                type: 'radio',
                                                id: 'equipment_operative',
                                                value: 1,
                                                name: 'equipment[status]',
                                                selected: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.status_id !== undefined && ConfigurationVariableEquipments.data.status_id == 1 ? true : false,
                                            }"
                                        />
                                        <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 25px;">{{$t('operative')}}</label>
                                        <GhChecks
                                            :datalabel="{}"
                                            :datainput="{
                                                required: false,
                                                type: 'radio',
                                                id: 'equipment_non_operative',
                                                value: 2,
                                                name: 'equipment[status]',
                                                selected: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.status_id !== undefined && ConfigurationVariableEquipments.data.status_id == 2 ? true : false,
                                            }"
                                        />
                                        <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('non_operative')}}</label>
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('status') + ':'}}</label>
                                        <template v-if="ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.status_id !== undefined ">
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">
                                                <template v-if="ConfigurationVariableEquipments.data.status_id == 1">
                                                    {{$t('operative')}}
                                                </template>
                                                <template v-if="ConfigurationVariableEquipments.data.status_id == 2">
                                                    {{$t('non_operative')}}
                                                </template>
                                            </label>
                                        </template>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhDataSelector
                                            :datablock="{
                                                id: 'equipment_required_maintenance',
                                                class: 'T15_b',
                                                subtitle: $t('select_required_maintenance') + ':',
                                                text: $t('required_maintenance'),
                                                label: $t('required_maintenance'),
                                                type: 'checkbox',
                                                columns: 3,
                                                required: false,
                                                style: '',
                                                popupStyle: 'min-width: 900px; width: 55%;',
                                            }"
                                            :datainput="{
                                                id: 'equipment_required_maintenance',
                                                name: 'equipment[required_maintenance][]'
                                            }"
                                            :feed="getFeeds.required_maintenance"
                                            :selected_input="ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.required_maintenance : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('required_maintenance') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? Object.values(ConfigurationVariableEquipments.data.required_maintenance).join(', ') : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhCalendar
                                            :datalabel="{
                                                id: 'equipment_checked_last',
                                                text: $t('last_checked'),
                                                style: 'width: 150px; min-width: 150px; text-align: right;'
                                            }"
                                            :datainput="{
                                                id: 'equipment_last_checked',
                                                name: 'equipment[last_checked]',
                                                value: ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.last_checked : '',
                                                required: false
                                            }"
                                        />
                                        <GhCalendar
                                            :datalabel="{
                                                id: 'equipment_one_next',
                                                text: $t('next_one'),
                                                style: 'width:85px;text-align: right;'
                                            }"
                                            :datainput="{
                                                id: 'equipment_next_one',
                                                name: 'equipment[next_one]',
                                                value: ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.next_one : '',
                                                required: false
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('last_checked') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.last_checked : ''}}</label>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('next_one') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipments.data !== undefined ? ConfigurationVariableEquipments.data.next_one : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" v-for="(item, index) in employees" :key="index">
                                    <template v-if="!$root._route.params.view">
                                        <GhSearchWindow
                                            :dataBlock="{
                                                label: {
                                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                                },
                                                ResultSearch: 'result_search_data_groups',
                                                nameStore: 'getFeeds',
                                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                                id: item.id,
                                                text: $t(item.id),
                                                textlocation: $t('add') + ' ' + $t(item.id),
                                                actionresult: 'loadResultSearchGroups',
                                                nameResult: 'equipment[' + item.id + ']',
                                                supportPermision: false,
                                            }"
                                            :mainSelector="{
                                                name: 'Formsearchcompany',
                                                formId: 'search',
                                                actionsearch: 'getSearchGroups'
                                            }"
                                            :tableResolt="{
                                                header: header,
                                                data: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data[item.id] !== undefined && ConfigurationVariableEquipments.data[item.id].summary !== undefined ? ConfigurationVariableEquipments.data[item.id].summary : []
                                            }"
                                            :tableSelected="{
                                                headersummary: headersummary,
                                                selected: ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data[item.id] !== undefined && ConfigurationVariableEquipments.data[item.id].summary !== undefined ? ConfigurationVariableEquipments.data[item.id].summary : []
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(item.id) + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{summary[item.id]}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhMultiUpload
                                            :datalabel="{
                                                text: $t('required_documents'),
                                                text_popup: $t('required_documents'),
                                                input_text: $t('name'),
                                                textarea_text: $t('description'),
                                                attach_text: $t('attach'),
                                                content_warning: '',
                                                label_required: $t('required_documents'),
                                                required: false,
                                            }"
                                            :datablock="{
                                                id: 'equipment_documents',
                                                name: 'equipment[documents]',
                                                style: '',
                                                class: 'T15_b',
                                                icon_attach: '',
                                                icon_trash: '',
                                                icon_download: '',
                                                href: dispatchEvidence,
                                            }"
                                            :defaultfiles="ConfigurationVariableEquipments.data !== undefined && ConfigurationVariableEquipments.data.required_documents !== undefined ? ConfigurationVariableEquipments.data.required_documents : []"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('required_documents') + ':'}}</label>
                                        <div v-for="(file,index) in ConfigurationVariableEquipments.data.required_documents" :key="index">
                                            <label class="gh_text_field-label T15_b"><a class="C1 F5" :href="file.file_download">{{file.name}}</a>{{index != Object.keys(ConfigurationVariableEquipments.data.required_documents).pop() ? ', ' : ''}}</label>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitEquipment();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhMultiUpload from "fe-gh-multi-upload-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "view_equipment",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhEasyUpload,
            GhDataSelector,
            GhChecks,
            GhCalendar,
            GhSearchWindow,
            GhMultiUpload,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigurationVariableEquipments', 'UploadFiles'])
        },
        data(){
            return{
                type: '',
                haveData: false,
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'professional_type', field: 'professional_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'}
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
                employees: [
                    {id: 'reserved_only_for'},
                ],
                summary: {
                    reserved_only_for: '',
                },
                dispatchEvidence: 'easyUpload',
                image: '',
                values: {
                    sn: '',
                    name: '',
                    description: ''
                }
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableEquipments', '', {root: true});
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableEquipments', {id: this.$root._route.params.id}, {root: true});

                this.values.sn = this.ConfigurationVariableEquipments.data !== undefined ? this.ConfigurationVariableEquipments.data.sn : '';
                this.values.name = this.ConfigurationVariableEquipments.data !== undefined ? this.ConfigurationVariableEquipments.data.name : '';
                this.values.description = this.ConfigurationVariableEquipments.data !== undefined ? this.ConfigurationVariableEquipments.data.description : '';
            }

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedDepartments', '', {root: true});
                await this.$store.dispatch('getFeedSpecialties', '', {root: true});
                await this.$store.dispatch('getFeedRoom', '', {root: true});
                await this.$store.dispatch('getFeedTypeEquipments', '', {root: true});
                await this.$store.dispatch('getFeedRequiredMaintenance', '', {root: true});
            }else{
                this.employees.forEach((item) => {
                    if(this.ConfigurationVariableEquipments.data !== undefined && this.ConfigurationVariableEquipments.data[item.id] !== undefined && this.ConfigurationVariableEquipments.data[item.id].summary !== undefined){
                        this.ConfigurationVariableEquipments.data[item.id].summary.forEach((values) => {
                            this.summary[item.id] += values.name + ' ' + values.surname + ', ';
                        });
                        this.summary[item.id] = this.summary[item.id].substring(0, this.summary[item.id].length - 2);
                    }
                })
            }

            if(this.ConfigurationVariableEquipments.data !== undefined && this.ConfigurationVariableEquipments.data.picture !== undefined && this.ConfigurationVariableEquipments.data.picture.file_download !== undefined && this.ConfigurationVariableEquipments.data.picture.file_download !== ''){
                this.image = this.ConfigurationVariableEquipments.data.picture.file_download;
            }else{
                this.image = require('../../../../assets/gh_new_dessign/no-image.svg');
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_equipment')[0];
            this.haveData = true;
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.url;
                this.setPersist();
            }
        },
        methods: {
            setPersist(){
                this.values.sn = this.$refs['RefSn'].datainput.value;
                this.values.name = this.$refs['RefName'].datainput.value;
                this.values.description = this.$refs['RefDescription'].datainput.value;
            },
            async submitEquipment(){
                if(this.Global.checkRequired('form_equipment') == 'OK'){
                    const formData = new FormData(document.getElementById('form_equipment'));
                    await this.$store.dispatch('setDataConfigurationVariableEquipment', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                        defaultData: this.ConfigurationVariableEquipments.data,
                        employees: this.employees
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    #equipment_type_selector_label, #equipment_departments_selector_label, #equipment_specialties_selector_label, #equipment_room_selector_label, #equipment_required_maintenance_selector_label, #equipment_documents_multi_doc_selector_label{
        width: 150px;
        text-align: end;
    }

    #hidden_list_multiple_files_equipment_documents{
        min-width: 200px !important;
    }

    #equipment_documents_SUMMARY {
        margin-right: 0px;
    }
</style>