import Vue from "vue";
import {LoginUrl,API_URL} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
import router from "../../routes/router"
import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        tabs: [],
        username:'',
        password:'',
        error_cif:'',
        error_mail:'',
        email:'',
        error:'',
        errormailcif:'',
        email_recover:'',
        token: '',
        token_time: 0,
        text:[],
        responseUser: []
    },
    mutations:{
        LOAD_USERNAME(state,payload){
            state.username = payload;
            payload != '' ? localStorage.setItem('username',payload):localStorage.removeItem('username');
        },
        LOAD_ERROR: (state, payload) => (state.error = payload),
        LOAD_EMAIL_REMEMBER(state,payload){
            state.email_recover = payload;
        },
        Close_Messages_Login(state){
            state.error_cif = '';
            state.error_mail = '';
            state.email_recover = '';
            state.errormailcif = '';
        },
        LOAD_ERROR_MAIL(state,payload){
            if(payload.code == 'cif') {
                state.error_cif = payload.msg;
            }
            if(payload.code == 'email') {
                state.error_mail = payload.msg;
            }
            if(payload.code=='cifemail'){
                let ErrorMsgMail = payload.msg.split(",");
                state.error_cif = ErrorMsgMail[0];
                state.error_mail = ErrorMsgMail[1];
            }
            if(payload.code==''){
                state.errormailcif = payload.msg;
            }
            state.error_mail = payload;
        },
        LOAD_TOKEN(state,payload){
            state.token = payload.token;
        },
        LOAD_TOKEN_TIME(state,payload){
            state.token_time = payload;
        },
        loadGetAcreditation(state,payload){
            state.text = payload;
        },
        loadTabsWithPerm(state,payload){
            state.tabs = payload;
        },
        loadResponseUser(state,payload){
            state.responseUser = payload;
        },
    },
    actions:{
        TO_LOGIN(){
            router.push({name: 'login'});
        },
        PREREGISTER(){
            router.push({name: 'pre_register'});
        },
        redirectTypeRegister(state, item){
            router.push({name: 'register', params: {type: item.type}});
        },
        async GetAcreditation(state){
            await axios.get(
                API_URL + "register/acreditation"
            ).then(
                response => {
                    state.commit('loadGetAcreditation',response.data.data)
                },
                error => {
                    console.log(error);
                }
            )
        },
        async set401(){
            Global.openPopUp('popup_401');
        },
        async acceptSet401(state){
            state.commit("LOAD_TOKEN", "");
            state.commit("LOAD_TOKEN_TIME", "");
            localStorage.clear();
            // TODO: Cuando este SSO descomentar las lineas comentadas
            // const authParams = await AuthService.getAuthType();
            // if (authParams?.auth_type === 'SSO') {
            //     let sso_config = await AuthService.getLogoutUrl();
            //     if(sso_config['logout_callback']){
            //         window.location.replace(sso_config['logout_callback']);
            //     }
            // }else{
                router.push({ name: 'login'});
            // }
        },
        async GET_USER(state, credentials) {
            let requestLogin = LoginUrl + "auth/login"
            let formData = new FormData();
            formData.append("username", credentials.username);
            formData.append("password", credentials.password);
            formData.append("submit", "Submit");
            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if (response.data.data) {
                        state.commit("LOAD_TOKEN", response.data.data);
                        state.commit("loadResponseUser", response.data.data);
                        localStorage.setItem('user',response.data.data.username);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);

                        if(response.data.data !== undefined && response.data.data.is_professional){
                            if(response.data.data !== undefined && response.data.data.no_finish_register) {
                                router.push({name: 'register', params: {type: 'professional-second-phase'}});
                            }else{
                                router.push({name: 'environment'});
                            }
                        }else {
                            router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/submits'});
                        }
                        state.commit("LOAD_ERROR", '');

                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);

                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                error => {
                    router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route)=> route.name == localStorage.getItem('TabItem'))].path : '/submits'});
                    let the_error = {error: {code: error.code, msg: error.error}}
                    state.commit("LOAD_ERROR", the_error)
                }
            )
        },
        async getTabsWithPerm(state){
            await axios.get(
                LoginUrl + "app-modules"
            ).then(
                response => {
                    state.commit('loadTabsWithPerm', response.data.data);
                }
            )
        },
        CloseMessagesLogin(state){
            state.commit("Close_Messages_Login")
        },
        GET_REMEMBER_PASSWORD(state, credentials) {
            let requestLogin = LoginUrl + "rmbpwd"
            let formData = new FormData();
            formData.append("cif", credentials.cif);
            formData.append("email", credentials.email);
            formData.append("submit", "Submit");
            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.data.data) {
                        Global.openPopUp('rememeber_password_success');
                        state.commit("LOAD_EMAIL_REMEMBER", response.data.data.returnmsg)
                    } else if (response.data.error) {
                        if(response.data.error.code.Normpwd){
                            Global.openPopUp('remember_password_error');
                        }
                        state.commit("LOAD_ERROR_MAIL", response.data.error)
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: "Fallo de comunicaciones en vuex!!!!!"}};
                    state.commit("LOAD_ERROR", the_error);
                    console.log(error.name);
                }
            )
        },
        SET_LOGOUT(state){
            state.commit("LOAD_TOKEN", '');
            state.commit("LOAD_TOKEN_TIME", '');
        },
        refreshToken(state){
            let requestLogin = LoginUrl + "auth/refresh"
            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if (response.data.data.token !== undefined) {
                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);
                        state.commit("LOAD_TOKEN", response.data.data);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);
                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}}
                    state.commit("LOAD_ERROR", the_error)
                }
            )
        }
    }
}
