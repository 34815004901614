import Availability from "@/view/configuration/calendar/views/view_availability.vue";

const routes = [
    {
        path: '/configuration/calendar/availability',
        name: 'configuration_calendar_new_availability',
        component: Availability,
        props: {id: null, showMenu: false, background: true, otherTitle: 'availability'},
        children: [
            {
                path: '/configuration/calendar/availability/:id',
                name: 'configuration_calendar_edit_availability',
                component: Availability,
                props: {id: null, showMenu: false, background: true, otherTitle: 'availability'},
            },
            {
                path: '/configuration/calendar/availability/:id/:view',
                name: 'configuration_calendar_view_availability',
                component: Availability,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'availability'},
            }
        ],
    },
];

export default routes;