import Vue from 'vue'
import Router from 'vue-router'

import Login from './login/route';
import Environments from './environment/route';
import Submits from './submits/route';
import Validations from './validations/route';
import Configuration from './configuration/route';
import MyFile from './my_file/route';
import Incidences from './incidences/route';
import Patients from './patients/route';
import Calendars from './calendar/route';
import Visits from './visits/route';
import Warnings from './warnings/route';
import Surgery from './surgery/route';
import Support from './support/route';
import Permissions from './permissions/route';
import Process from './process/route';

import FloatSearch from './float_search/route';

Vue.use(Router);

const routes = [
    ...Login,
    ...Environments,
    ...Submits,
    ...Validations,
    ...Configuration,
    ...MyFile,
    ...FloatSearch,
    ...Incidences,
	...Calendars,
    ...Patients,
    ...Visits,
    ...Warnings,
    ...Surgery,
    ...Support,
    ...Permissions,
    ...Process
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    GhTabs: [
        {id: 1, value: 'submits'},
        {id: 2, value: 'validations'},
        {id: 3, value: 'configuration'},
        {id: 4, value: 'my_file'},
        {id: 5, value: 'incidences'},
        {id: 6, value: 'calendar'},
        {id: 7, value: 'patient'},
        {id: 8, value: 'visits'},
        {id: 9, value: 'warnings'},
        {id: 10, value: 'surgery'},
        {id: 11, value: 'support'},
        {id: 12, value: 'permissions'}
    ],
    GhTabsConfiguration: [
        {id: 1, value: 'equipments', call: 'configuration_equipments'},
        {id: 2, value: 'variable', call: 'configuration_variable'},
        {id: 3, value: 'professionals', call: 'configuration_professionals'},
        {id: 4, value: 'calendar', call: 'configuration_calendar'},
        {id: 5, value: 'consent_forms', call: 'configuration_consent_forms'},
        {id: 6, value: 'clinic_data', call: 'configuration_clinic_data'},
        {id: 7, value: 'general_data', call: 'configuration_general_data'},
        {id: 8, value: 'items', call: 'configuration_items'},
        {id: 9, value: 'forms', call: 'configuration_forms'},
        {id: 10, value: 'process', call: 'configuration_process'},
        {id: 11, value: 'support', call: 'configuration_support'},
        {id: 12, value: 'priority', call: 'configuration_priority'}
    ],
    GhTabsCalendar: [
        {id: 1, value: 'by_department', call: 'calendar_department'},
        {id: 2, value: 'by_speciality', call: 'calendar_speciality'},
        {id: 3, value: 'by_professional', call: 'calendar_professional'},
        {id: 4, value: 'by_group', call: 'calendar_group'},
    ],
    GhTabsVisits: [
        {id: 1, value: 'today', call: 'visits_today'},
        {id: 2, value: 'week', call: 'visits_weekly'},
        {id: 3, value: 'month', call: 'visits_month'},
    ],
    GhTabsSurgery: [
        {id: 1, value: 'patient_data', call: 'surgery_patient-data'},
        {id: 2, value: 'physician_data', call: 'surgery_physician-data'},
        {id: 3, value: 'diagnostic', call: 'surgery_diagnostic'},
        {id: 4, value: 'type_of_admission', call: 'surgery_type-of-admission'},
        {id: 5, value: 'surgical_plan', call: 'surgery_surgical-plan'},
    ],
    GhTabsProcess: [],
    routes
});

export default router;