import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTablePermissionsClinics(state, payload) {
            state.records_data = payload;
        },
        loadDataPermissionsClinics(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getPermissionsClinics(state){
            await axios.get(
                API_URL + "permissions/clinics"
            ).then(
                response => {
                    state.commit('loadTablePermissionsClinics', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataPermissionsClinics(state, item){
            let  requestLogin = API_URL + "permissions/clinics/" + item.id;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadDataPermissionsClinics", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPermissionsClinics(state,item){
            let requestCompaniesGroup =  item.id ? API_URL + "permissions/clinics/" + item.id : API_URL + "permissions/clinics";
            let formData = new FormData();

            var defaultArray = [];
            var formArray = [];

            item.defaultData.forEach((i) => {
                defaultArray[i.id] = [];
                defaultArray[i.id].push(i.selected);

                item.formData.forEach((f) => {
                    var spliter = f[0].split('clinic[groups][')[1].split(']')[0];
                    if(formArray[spliter] === undefined){
                        formArray[spliter] = [];
                    }

                    if(formArray[spliter].find((a) => a == f[1]) === undefined) {
                        formArray[spliter].push(f[1]);
                    }
                })

            });

            Object.keys(defaultArray).forEach((key) => {
                defaultArray[key][0].selecteds.forEach((d) => {
                    if(formArray[key].find((f) => f == d) === undefined){
                        formData.append('remove[clinic][groups][' + key + '][]', d);
                    }
                })
            });

            Object.keys(formArray).forEach((key) => {
                formArray[key].forEach((d) => {
                    if(defaultArray[key][0].selecteds.find((f) => f == d) === undefined){
                        formData.append('add[clinic][groups][' + key + '][]', d);
                    }
                })
            });

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200){
                        this.dispatch('getPermissionsClinics');
                    }
                    router.push({name: 'permissions'});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};