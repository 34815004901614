import MainProcess from "@/view/process/main.vue";
import ProcessSection from "@/view/process/sections/section_process.vue";

const route = [
    {
        path: '/process/:process_id/:id',
        name: 'process',
        component: MainProcess,
        props: {process_id: null, id: null, showMenu: true}
    },
    {
        path: '/process/:process_id/:id/:subprocess_id/:slug',
        name: 'process_subtab',
        component: ProcessSection,
        props: {process_id: null, id: null, slug: null, showMenu: true}
    }
];

const routes = [
    ...route
];
export default routes;