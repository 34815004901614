import SectionItem from "@/view/configuration/items/section/section_items.vue";

import StandardItem from './standard_items/route';
import ExaminationItem from './examination_items/route';

const route = [
    {
        path: '/configuration/items',
        name: 'configuration_items',
        component: SectionItem,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...StandardItem,
    ...ExaminationItem
]

export default routes;