import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        warning_surgery_data: [],
        other_surgeon: [],
        optimal_schedule: [],
    },
    mutations: {
        loadTableWarningsSurgery(state, payload) {
            state.records_data = payload;
        },
        loadWarningSurgeryData(state, payload) {
            state.warning_surgery_data = payload;
        },
        loadDifferentSurgeon(state, payload) {
            state.other_surgeon = payload;
        },
        loadOptimalSchedule(state, payload) {
            state.optimal_schedule = payload;
        },
        getLabelSurgery(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getWarningsSurgeryList(state){
            await axios.get(
                API_URL + "warnings/surgery"
            ).then(
                response => {
                    state.commit('loadTableWarningsSurgery', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getWarningSurgeryData(state, item){
            await axios.get(
                API_URL + "warnings/surgery/" + item.id
            ).then(
                response => {
                    state.commit('loadWarningSurgeryData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDifferentSurgeon(state, item){
            var urlRequest = API_URL + "warnings/surgery/different-surgeon"

            for(var i=0;i<item.ids.length;i++){
                urlRequest += (i == 0 ? '?' : '&') + 'id_other_staff[]=' + item.ids[i];
            }

            await axios.get(
                urlRequest
            ).then(
                response => {
                    state.commit('loadDifferentSurgeon', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getOptimalSchedule(state, item){
            var urlRequest = API_URL + "warnings/surgery/different-surgeon/optimal-schedule"

            for(var i=0;i<item.ids.length;i++){
                urlRequest += (i == 0 ? '?' : '&') + 'id_other_staff[]=' + item.ids[i];
            }

            await axios.get(
                urlRequest
            ).then(
                response => {
                    state.commit('loadOptimalSchedule', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setScheduleSurgery(state, item){
            let requestLogin = API_URL + "warnings/surgery/" + item.id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};