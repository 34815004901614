import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableFollowUpVisitsPatients(state, payload) {
            state.records_data = payload;
        },
        loadDataScheduleFollowUpVisit(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getVisitsFollowUpVisitsList(state, item){
            await axios.get(
                API_URL + "visits/follow-up-visits/" + item.type
            ).then(
                response => {
                    state.commit('loadTableFollowUpVisitsPatients', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getScheduleFollowUpVisit(state, item){
            await axios.get(
                API_URL + "visits/follow-up/" + item.id
            ).then(
                response => {
                    state.commit('loadDataScheduleFollowUpVisit', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};