import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default {
    state: {
        records_data: '',
        data: [],
    },
    mutations: {
        loadTableConfigurationSupportSchedule(state,payload){
            state.records_data = payload
        },
        loadDataConfigurationSupportSchedule(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationSupportSchedule(state){
            await axios.get(
                API_URL + "support/schedules"
            ).then(
                response => {
                    state.commit('loadTableConfigurationSupportSchedule', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationSupportSchedule(state, item){
            await axios.get(
                API_URL + "support/schedules/" + item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationSupportSchedule', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationSupportSchedule(state, item){
            let requestLogin = API_URL + "support/schedules/"+item.id;
            let formData = new FormData();

            formData.append('schedule[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('getConfigurationSupportSchedule');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}