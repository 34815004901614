<template>
    <div style="margin-top: 12px;" ref="refPostForms">
        <template v-for="(itemForm, indexForm) in dataSubprocess.forms">
            <form :id="'supraprocess_' + processId + '_process_' + subprocessId + '_section_' + dataSubprocess.id + '_form_' + itemForm.id" :key="'F' + indexForm">
                <div :id="'supraprocess_' + processId + '_process_' + subprocessId + '_section_' + dataSubprocess.id + '_form_' + itemForm.id" v-if="itemForm !== undefined" :key="indexForm">
                    <div class="section_line">
                        <div class="T15_b" style="padding-left:10px;">{{itemForm.name}}</div>
                    </div>
                    <div class="gh-content-global" v-if="itemForm.sections !== undefined && itemForm.sections.length !== 0">
                        <div v-for="(itemSection, indexSection) in itemForm.sections" :id="'supraprocess_' + processId + '_process_' + subprocessId + '_section_' + dataSubprocess.id + '_form_' + itemForm.id + '_section_' + itemSection.id" :key="indexSection">
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; text-align: right;">{{itemSection.name}}</label>
                            </div>
                            <template v-if="itemSection.items !== undefined && itemSection.items.length !== 0">
                                <template v-for="(item, index) in itemSection.items">
                                    <div v-if="item.item !== undefined && item.item.config !== undefined" class="gh-row-content" :key="index">
                                        <component v-bind:is="component[item.type]" :dataItem="item" :indexItem="index" :processId="processId" :subprocessId="subprocessId" :blockId="dataSubprocess.id" :formId="itemForm.id" :sectionId="itemSection.id"/>
                                    </div>
                                    <template v-if="item.item !== undefined && item.item.config !== undefined && item.item.config.extra_item !== undefined ? item.item.config.extra_item : false">
                                        <template v-if="getExtras !== undefined && getExtras[processId] !== undefined && getExtras[processId][subprocessId] !== undefined && getExtras[processId][subprocessId][itemForm.id] !== undefined && getExtras[processId][subprocessId][itemForm.id][itemSection.id] !== undefined && getExtras[processId][subprocessId][itemForm.id][itemSection.id][item.id] !== undefined">
                                            <div class="gh-row-content" v-for="(extra, keyExtra) in getExtras[processId][subprocessId][itemForm.id][itemSection.id][item.id]" :key="'ExtraElement' + index + '_' + keyExtra" :ref="'RefExtraItem' + processId + subprocessId + itemForm.id + itemSection.id + index + item.id">
                                                <component v-bind:is="component[extra.type]" :dataItem="extra" :indexItem="index + '_' + keyExtra + '_' + extra.id" :processId="processId" :subprocessId="subprocessId" :blockId="dataSubprocess.id" :formId="itemForm.id" :sectionId="itemSection.id"/>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <div class="centered" :key="'BTN' + dataSubprocess.id" style="padding: 10px;" v-if="!getSavedState[subprocessId][dataSubprocess.id]">
            <GhButton
                :datainput="{
                    id: 'button_save_' + processId + '_' + subprocessId + '_' + dataSubprocess.id,
                    text: $t('save'),
                    class: '',
                    style: ''
                }"
                @click="setSaveSection($root._route.params.id, processId, subprocessId, dataSubprocess.id)"
            />
        </div>
    </div>
</template>

<script>
    import view_free_text from "@/view/process/views/partials/view_free_text.vue";
    import view_yes_no from "@/view/process/views/partials/view_yes_no.vue";
    import view_yes_no_na from "@/view/process/views/partials/view_yes_no_na.vue";
    import view_multiple_n from "@/view/process/views/partials/view_multiple_n.vue";
    import view_multiple_1 from "@/view/process/views/partials/view_multiple_1.vue";
    import view_time from "@/view/process/views/partials/view_time.vue";
    import view_dosage from "@/view/process/views/partials/view_dosage.vue";
    import view_selector from "@/view/process/views/partials/view_selector.vue";
    import view_table from "@/view/process/views/partials/view_table.vue";
    import view_imported_data from "@/view/process/views/partials/view_imported_data.vue";
    import view_graphic from "@/view/process/views/partials/view_graphic.vue";
    import view_matrix_information from "@/view/process/views/partials/view_matrix_information.vue";

    import view_label from "@/view/process/views/partials/shared/view_label.vue";
    import view_description from "@/view/process/views/partials/shared/view_description.vue";
    import view_attach_picture from "@/view/process/views/partials/shared/view_attach_picture.vue";
    import view_draw_picture from "@/view/process/views/partials/shared/view_draw_picture.vue";
    import view_comment from "@/view/process/views/partials/shared/view_comment.vue";
    import view_medical_note from "@/view/process/views/partials/shared/view_medical_note.vue";

    import {mapGetters, mapState} from "vuex";

    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'view_process',
        components: {
            GhButton
        },
        props: ['dataSubprocess', 'indexSubprocess', 'processId', 'subprocessId'],
        data(){
            return{
                component: {
                    1: view_free_text,
                    2: view_yes_no,
                    3: view_yes_no_na,
                    4: view_multiple_n,
                    5: view_multiple_1,
                    6: view_time,
                    7: view_dosage,
                    8: view_selector,
                    9: view_table,
                    10: view_imported_data,
                    11: view_graphic,
                    12: view_matrix_information
                },
                componentItem: {
                    label: view_label,
                    description: view_description,
                    picture: view_attach_picture,
                    draw: view_draw_picture,
                    comment: view_comment,
                    medical_note: view_medical_note
                },
            }
        },
        computed: {
            ...mapState(['Process']),
            ...mapGetters(['getExtras', 'getSavedState']),
            subprocess(){
                return this.getExtras;
            }
        },
        methods: {
            setNA(ref){
                var result = ref[0].$parent.$parent.checkedNA;
                ref[0].$parent.$parent.checkedNA = !result;

                setTimeout(function(){
                    if(ref[0].$parent.$children[0].$parent.$parent.$el.children[0].children[1] !== undefined){
                        var spliter = ref[0].$parent.$children[0].$parent.$parent.$el.children[0].children[1].id.split('_');
                        switch(spliter[0] + '_' + spliter[1]){
                            case 'graphic_bar':
                                console.log(ref[0].$parent.$children[0].$parent.$parent.$el.children[0].children[1].parentNode.parentElement);
                                break;
                            case 'graphic_area':
                                console.log(ref[0].$parent.$children[0].$parent.$parent.$el.children[0].children[1].parentNode.parentElement);
                                break;
                            case 'graphic_line':
                                console.log(ref[0].$parent.$children[0].$parent.$parent.$el.children[0].children[1].parentNode.parentElement);
                                break;
                        }
                    }
                },10);
            },
            async checkExtraItem(el, extraItem, clickAnswer, type, itemId, processId, subprocessId, formId, sectionId, indexItem, elementId){
                if(extraItem){
                    var params = {};
                    var value = type === 4 || type === 8 ? [] : '';

                    switch (type){
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            for (var i = 0; i < clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].length; i++){
                                if(clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'][i].$el.children[0].children[1].checked){
                                    if(type === 4){
                                        value.push(clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'][i].$el.children[0].children[1].value)
                                    }else{
                                        value = clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'][i].$el.children[0].children[1].value;
                                    }
                                }
                            }
                            break;
                        case 6:
                        case 7:
                            value = clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].datainput.value;
                            break;
                        case 8:
                            if(Object.entries(clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].checkdata).length !== 0){
                                Object.entries(clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].checkdata).forEach((item) => {
                                    value.push(item[0]);
                                });
                            }

                            if(clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].datasaved.length !== 0){
                                clickAnswer['RefItem' + processId + subprocessId + formId + sectionId + indexItem + elementId + 'Item'].datasaved.forEach((item) => {
                                   value.push(item.value) ;
                                });
                            }
                            break;
                    }

                    params = {
                        value: value,
                        processId: processId,
                        subprocessId: subprocessId,
                        formId: formId,
                        sectionId: sectionId,
                        indexItem: indexItem,
                        elementId: elementId
                    }

                    await this.$store.dispatch('getExtraItem', {item: params}, {root: true});
                }
            },
            async setChangesSection(processId, sectionId){
                await this.$store.dispatch('checkPendingSave', {process: processId, section: sectionId}, {root:true});
            },
            async setSaveSection(taskId, supraId, processId, sectionId){
                var params = {
                    taskId: taskId,
                    supraId: supraId,
                    processId: processId,
                    sectionId: sectionId
                }

                var formData = new FormData();
                for(var i=0;i<this.$refs.refPostForms.children.length;i++){
                    if(this.$refs.refPostForms.children[i].localName == 'form'){
                        var form = new FormData(document.getElementById(this.$refs.refPostForms.children[i].attributes.id.value));

                        for (var input of form.entries()) {
                            formData.append(input[0], input[1]);
                        }
                    }
                }

                // const formData = new FormData(document.getElementById('supraprocess_' + supraId + '_process_' + processId + '_section_' + sectionId));
                await this.$store.dispatch('setSectionSave', {
                    formData: [...formData],
                    item: params
                }, {root: true});
            }
        }
    }
</script>