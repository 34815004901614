<template>
    <div>
        <GhNoDropSelector
            :datablock="{
                id: 'clinic_permissions',
                title: $t('clinic')
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{PermissionsClinics.data.name}}</label>
                        </div>
                    </div>
                    <div class="section_line">
                        <div class="T15_b" style="padding-left:13px;">{{$t('groups')}}</div>
                    </div>
                    <div class="gh-content-global">
                        <form id="form_clinics_groups">
                            <div class="gh-row-content" v-for="i in PermissionsClinics.data.groups" :key="i.id">
                                <GhSearchWindow
                                    :dataBlock="{
                                        label: {
                                            style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                        },
                                        ResultSearch: 'result_search_data_groups',
                                        nameStore: 'getFeeds',
                                        imagelocation: require('../../../assets/gh_new_dessign/floating.svg'),
                                        id: i.type,
                                        text: $t(i.name),
                                        textlocation: $t('add') + ' ' + i.name,
                                        actionresult: 'loadResultSearchGroups',
                                        nameResult: 'clinic[groups][' + i.type + '][]',
                                        supportPermision: true
                                    }"
                                    :mainSelector="{
                                        name: 'Formsearchcompany',
                                        formId: 'search',
                                        actionsearch: 'getSearchGroups'
                                    }"
                                    :tableResolt="{
                                        header: header,
                                        data: i.selected && i.selected.summary ? i.selected.summary : []
                                    }"
                                    :tableSelected="{
                                        headersummary: headersummary,
                                        selected: i.selected && i.selected.selecteds ? i.selected.selecteds : []
                                    }"
                                />
                            </div>
                        </form>
                    </div>
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="submitPermissionsClinics();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_back',
                    text: $t('back'),
                }"
                @click="$router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhSearchWindow from 'fe-gh-float-window-lib'
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'view_company',
        components: {
            GhNoDropSelector,
            GhSearchWindow,
            GhButton
        },
        computed: {
            ...mapState(['PermissionsClinics']),
        },
        data(){
            return{
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'name', sorting: true,typeSort: 'string', style: 'max-width: 60px; width: 50%;'},
                    {text: this.$t('surname'), field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getDataPermissionsClinics', {id: this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods: {
            async submitPermissionsClinics(){
                if(this.Global.checkRequired('form_clinics_groups') == 'OK') {
                    const formData = new FormData(document.getElementById('form_clinics_groups'));
                    await this.$store.dispatch('setPermissionsClinics', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        defaultData: this.PermissionsClinics.data.groups
                    }, {root: true});
                }
            }
        }
    }
</script>