<template>
    <div class="gh-content-global" v-if="haveData">
        <div v-for="(item,index) in ConfigurationFormsCreation.list_creation_form" :key="index" style="margin-bottom: 5px;">
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_field_creation_form_' + item.id,
                    title: item.name,
                }"
            >
                <template v-slot:content>
                    <GhTableCommon style="margin-top: 10px;"
                        :extratable="{
                            id: 'table_creation_form',
                        }"
                        :header="header"
                        :data="item.data === undefined ? [] : item.data"
                    >
                        <template v-slot:actions="{itemProp}">
                            <router-link :to="{name: 'configuration_forms_edit_creation_form', params: {id: itemProp.id}}">
                                <GhAction
                                    :dataction="{
                                        id: 'action_edit_creation_form_' + itemProp.id,
                                        text: $t('edit'),
                                        icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                    }"
                                />
                            </router-link>
                            <GhAction
                                :dataction="{
                                    id: 'action_delete_creation_form_' + itemProp.id,
                                    text: $t('delete'),
                                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                }"
                                @click="Global.deleteConfirm('delete_confirm','removeConfigurationCreationForm', itemProp.id,{ 'form': itemProp.form}, $t('delete_form'), $t('preparing_delete_form'), 'remove')"
                            />
                        </template>
                    </GhTableCommon>
                </template>
            </GhDropDownField>
        </div>
        <div class="centered" style="padding-top: 5px; margin-bottom: -5px !important;">
            <router-link :to="{name: 'configuration_forms_new_creation_form'}">
                <GhButton
                    :datainput="{
                        id: 'btn_add_category_form',
                        text: $t('add')
                    }"
                />
            </router-link>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'list_creation_form',
        components: {
            GhDropDownField,
            GhTableCommon,
            GhButton,
            GhAction
        },
        computed:{
            ...mapState(['ConfigurationFormsCreation']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'form', field: 'form', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'related_items', field: 'related_items', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 70%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationCreationForm','',{root:true});
            this.haveData = true;
        }
    }
</script>

<style>
    .definition-field-content-expansion > div > table {
        min-width: 855px !important;
    }
</style>