<template>
    <div>
        <div class="gh-row-content" v-for="(item, index) in dataBlock.items" :key="index" :ref="'RefLineSelector' + item.id">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('answer') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 210px; width: 210px">{{item.answer}}</label>
            <template v-if="!$root._route.params.view">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: $parent.$parent.$parent.type_item + '_selecteds_' + item.id + '_item',
                        label: $t('item'),
                        title: $t('item'),
                        text: $t('select_item') + ':',
                        name: $parent.$parent.$parent.type_item + '[selecteds][' + item.id + '][item]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: false,
                        style: 'margin-left: 30px;margin-top:2px;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="item.item"
                    :items="getFeeds.items"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 33.5%">{{item.item[0].text}}</label>
            </template>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('associate_to')}}</label>
            <GhChecks
                :datalabel="{}"
                :datainput="{
                    required: false,
                    disabled: $root._route.params.view == 'true' ? true : false,
                    type: 'checkbox',
                    id: $parent.$parent.$parent.type_item + '_selecteds_' + item.id + '_associate_to',
                    value: 1,
                    name: $parent.$parent.$parent.type_item + '[selecteds][' + item.id + '][associate_to]',
                    selected: item.associate_to,
                }"
            />
            <template v-if="$root._route.params.view !== 'true'">
                <GhAction
                    style="margin-left: 10px; width: max-content; white-space: nowrap"
                    :dataction="{
                        id: 'action_delete_' + item.id,
                        text: $t('delete'),
                        icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
                    }"
                    @click="$parent.removeAnswer(item.id)"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'include_line_of_selector',
        props: ['dataBlock'],
        components: {
            GhAction,
            GhTreeSelectorBlock,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds'])
        },
    }
</script>