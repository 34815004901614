var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_department',
                title: _vm.$t('department')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_department"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})])]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                            text: _vm.$t('code') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'department_code',
                                            name: 'department[code]',
                                            style: 'width: 200px',
                                            value: _vm.values.code,
                                            type: 'text',
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.values.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                            text: _vm.$t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'department_name',
                                            name: 'department[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.values.name,
                                            type: 'text',
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.values.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                            text: _vm.$t('description'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'department_description',
                                            name: 'department[description]',
                                            value: _vm.values.description
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.values.description))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('logo')))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                                        id: 'department_logo',
                                        class: '',
                                        name_hidden: 'department[logo]',
                                        id_hidden: 'department_logo',
                                        icon_attach: ''
                                    },"datalabel":{
                                        id: 'label_department_logo',
                                        style: 'margin-right: 5px'
                                    },"datainput":{
                                        view: _vm.$root._route.params.view,
                                        accept: 'image/png, image/gif, image/jpeg',
                                        text: _vm.$t('upload'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        file_name: _vm.ConfigurationVariableDepartments.data !== undefined && _vm.ConfigurationVariableDepartments.data.logo !== undefined ? _vm.ConfigurationVariableDepartments.data.logo.file_name : '',
                                        file_path: _vm.ConfigurationVariableDepartments.data !== undefined && _vm.ConfigurationVariableDepartments.data.logo !== undefined ? _vm.ConfigurationVariableDepartments.data.logo.file_path : '',
                                        file_download: _vm.ConfigurationVariableDepartments.data !== undefined && _vm.ConfigurationVariableDepartments.data.logo !== undefined ? _vm.ConfigurationVariableDepartments.data.logo.file_download : ''
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'department_specialties',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_specialties') + ':',
                                            text: _vm.$t('specialties'),
                                            label: _vm.$t('specialties'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'department_specialties',
                                            name: 'department[specialties]'
                                        },"feed":_vm.getFeeds.clinic_specialties,"selected_input":_vm.ConfigurationVariableDepartments.data !== undefined ? _vm.ConfigurationVariableDepartments.data.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableDepartments.data !== undefined ? Object.values(_vm.ConfigurationVariableDepartments.data.specialties).join(', ') : ''))])]],2),_vm._l((_vm.employees),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'result_search_data_groups',
                                            nameStore: 'getFeeds',
                                            imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                            id: item.id,
                                            text: _vm.$t(item.id),
                                            textlocation: _vm.$t('add') + ' ' + _vm.$t(item.id),
                                            actionresult: 'loadResultSearchGroups',
                                            nameResult: 'department[' + item.id + ']',
                                            supportPermision: false,
                                        },"mainSelector":{
                                            name: 'Formsearchcompany',
                                            formId: 'search',
                                            actionsearch: 'getSearchGroups'
                                        },"tableResolt":{
                                            header: _vm.header,
                                            data: _vm.ConfigurationVariableDepartments.data !== undefined && _vm.ConfigurationVariableDepartments.data[item.id] !== undefined && _vm.ConfigurationVariableDepartments.data[item.id].summary !== undefined ? _vm.ConfigurationVariableDepartments.data[item.id].summary : []
                                        },"tableSelected":{
                                            headersummary: _vm.headersummary,
                                            selected: _vm.ConfigurationVariableDepartments.data !== undefined && _vm.ConfigurationVariableDepartments.data[item.id] !== undefined && _vm.ConfigurationVariableDepartments.data[item.id].summary !== undefined ? _vm.ConfigurationVariableDepartments.data[item.id].summary : []
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.id) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.summary[item.id]))])]],2)})],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.haveData && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitDepartment();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }