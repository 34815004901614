import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import router from "../../routes/router";
import Global from "../Global";
import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        subtabs: '',
        sections: '',
        subprocess: '',
        graphic: [],
        extras: [],
        flagForGetter: false,
        savedState: [],
        anyPendingSave: false,
        lastSectionActive: [],
        lastIdSectionActive: '',
        refreshSubTab: 0,
        buttons: []
    },
    mutations: {
        loadRestartState(state){
            state.subtabs = '';
            state.sections = '';
            state.subprocess = '';
            state.graphic = [];
            state.extras = [];
            state.flagForGetter = false;
            state.savedState = [];
            state.anyPendingSave = false;
            state.lastSectionActive = [];
            state.lastIdSectionActive = '';
            state.refreshSubTab = 0;

            const keys = Object.keys(localStorage);
            keys.forEach(key => {
                if (key.startsWith('GraphicRef')) {
                    localStorage.removeItem(key);
                }

                if (key.startsWith('GraphicNewRef')) {
                    localStorage.removeItem(key);
                }
            });
        },
        loadProcessMain(state, payload) {
            state.subtabs = payload;
        },
        loadSectionActive(state, payload){
            state.sections = payload;
        },
        loadSubProcess(state, payload){
            state.subprocess = payload;
        },
        loadGraphic(state, payload){
            state.graphic = payload;
        },
        loadExtras(state, payload){
            state.extras = payload;
        },
        loadSavedState(state, payload){
            if(payload.process !== undefined && payload.section !== undefined){
                state.anyPendingSave = true;
                state.savedState[payload.process][payload.section] = false;
            }else {
                state.savedState = payload;
            }

            state.flagForGetter = !state.flagForGetter;
        },
        loadNewExtras(state,payload){
            var id = payload.item.item.elementId;
            if(typeof payload.item.item.indexItem === 'string' && payload.item.item.indexItem.includes('_')) {
                var subprocessKey = '';
                var formKey = '';
                var sectionKey = '';
                state.subprocess.find((i, k) => {
                    if(i.id === payload.item.item.subprocessId){
                        subprocessKey = k;
                    }
                });

                state.subprocess[subprocessKey].forms.find((i, k) => {
                    if(i.id === payload.item.item.formId){
                        formKey = k;
                    }
                })

                state.subprocess[subprocessKey].forms[formKey].sections.find((i, k) => {
                    if(i.id === payload.item.item.sectionId){
                        sectionKey = k;
                    }
                });

                id = state.subprocess[subprocessKey].forms[formKey].sections[sectionKey].items[payload.item.item.indexItem.split('_')[0]].id;
            }

            state.extras[payload.item.item.processId] = state.extras[payload.item.item.processId] !== undefined ? state.extras[payload.item.item.processId] : {};
            state.extras[payload.item.item.processId][payload.item.item.subprocessId] = state.extras[payload.item.item.processId][payload.item.item.subprocessId] !== undefined ? state.extras[payload.item.item.processId][payload.item.item.subprocessId] : {};
            state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId] = state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId] !== undefined ? state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId] : {};
            state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId] = state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId] !== undefined ? state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId] : {};
            state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] = state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] !== undefined ? state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] : [];

            if(payload.data.data === '' || payload.data.data === undefined || payload.data.data.length === 0){
                if(payload.item.item.elementId === id){
                    state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] = [];
                }else{
                    state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id].forEach((i, k) => {
                       if(i.parent_id === payload.item.item.elementId){
                           delete state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id][k];
                           state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] = [...state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id]];
                           state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] = Object.assign({}, state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id]);
                           for (let key in state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id]) {
                               if (state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id][key] === undefined) {
                                   delete state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id][key];
                               }
                           }
                       }
                    });
                }
                state.change++;
            }else{
                var tempData = [];

                Object.entries(state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id]).forEach((item) => {
                    tempData.push(item[1]);
                });

                payload.data.data.forEach((item) => {
                    tempData.push(JSON.parse(JSON.stringify(item)));
                });

                state.extras[payload.item.item.processId][payload.item.item.subprocessId][payload.item.item.formId][payload.item.item.sectionId][id] = tempData;
            }

            state.flagForGetter = !state.flagForGetter;
        },
        loadSubProcessButtons(state, payload){
            state.flagForGetter = !state.flagForGetter;
            state.buttons = payload;
        }
    },
    getters: {
        getSubprocess: state => state.subprocess,
        getExtras(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return state.extras;
        },
        getSavedState(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return state.savedState;
        },
        getButtons(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return state.buttons;
        }
    },
    actions: {
        async getProcessMain(state, item){
            await axios.get(
                API_URL + "supra-process/" + item.process_id
            ).then(
                response => {
                    state.commit('loadProcessMain', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSubProcess(state, item){
            await axios.get(
                API_URL + "supra-process/" + item.task_id + "/" + item.process_id + "/" + item.subprocess_id
            ).then(
                response => {
                    state.commit('loadSubProcess', response.data.data);
                    state.commit('loadExtras', []);

                    var param = [];
                    param[item.subprocess_id] = [];

                    response.data.data.forEach((i) => {
                        param[item.subprocess_id][i.id] = true;
                    });

                    state.state.anyPendingSave = false;
                    state.commit('loadSavedState', param);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSupProcessExtraItems(state, item){
            await axios.get(
                API_URL + "process/extra_item/" + item.task_id + "/" + item.process_id + "/" + item.subprocess_id
            ).then(
                response => {
                    state.commit('loadExtras', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExtraItem(state, item){
            await axios.get(
                API_URL + "process/extra_item/" + item.item.processId + "/" + item.item.subprocessId + "/" + item.item.formId + "/" + item.item.sectionId + "/" + item.item.elementId + "/" + item.item.value
            ).then(
                response => {
                    var params = {
                        item: item,
                        data: response.data
                    }

                    state.commit("loadNewExtras", params);
                },
                error => {
                    console.log(error);
                }
            )
        },
        setGraphicProcess(state, item){
            state.commit("loadGraphic", item.graphic);
        },
        checkPendingSave(state, item){
            state.commit("loadSavedState", item);
        },
        setProcessActiveTab(state, item){
            if(state.state.anyPendingSave){
                Global.openPopUp('pending_save');
            }else {
                var dataTab = '';
                var component = '';
                for (var i = 0; i < router.options.GhTabsProcess.length; i++) {
                    if (router.options.GhTabsProcess[i].value == item.value) {
                        dataTab = router.options.GhTabsProcess[i];
                    }
                }

                router.options.routes.forEach((items) => {
                    if (items.name == dataTab.call) {
                        component = items;
                    }
                });

                var params = {
                    component: component,
                    dataTab: dataTab,
                    form: []
                }

                if(state.state.anyPendingSave !== null){
                    state.commit("loadSectionActive", params);
                }else{
                    state.state.anyPendingSave = false;
                }
                state.state.lastSectionActive = localStorage.getItem('process_tab__selected');
                state.state.lastIdSectionActive = localStorage.getItem('process_tab_');
            }
        },
        cancelProcessActiveTab(state){
            state.state.anyPendingSave = null;
            state.state.refreshSubTab++;
            localStorage.setItem('process_tab_', state.state.lastIdSectionActive);
            localStorage.setItem('process_tab__selected', state.state.lastSectionActive);
            Global.closePopUp('pending_save');
        },
        acceptProcessActiveTab(state){
            state.state.anyPendingSave = false;
            state.state.refreshSubTab++;
            Global.closePopUp('pending_save');
        },
        async setSectionSave(state, item){
            let request = item.item.sectionId === undefined ? API_URL + 'process/' + item.item.taskId + '/' + item.item.supraId + '/' + item.item.processId : API_URL + 'supra-process/' + item.item.taskId + '/' + item.item.supraId + '/' + item.item.processId + '/' + item.item.sectionId;

            let formData = new FormData();
            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    await state.dispatch('getSubProcess', {task_id: item.item.taskId, process_id: item.item.supraId, subprocess_id: item.item.processId}, {root: true});
                },
                error => {
                    console.log(error);
                }
            )
        },
        restartState(state){
            state.commit("loadRestartState");
        },
        async getSubProcessButtons(state, item){
            await axios.get(
                API_URL + "process/" + item.task_id + "/" + item.process_id + "/" + item.subprocess_id + "/buttons"
            ).then(
                response => {
                    state.commit('loadSubProcessButtons', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};