<template>
    <div>
        {{'Pending to do!!!'}}
    </div>
</template>

<script>
    export default {
        name: 'list_parameterization_forms'
    }
</script>