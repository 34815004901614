var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTableCommon',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"extratable":{
        id: 'table_creation_' + _vm.actualData.type + '_by_drop_down_' + _vm.actualData.parentIndex + '_' + _vm.actualData.id,
    },"header":_vm.header,"data":_vm.actualData.data.data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'action_edit_creation_' + _vm.actualData.type + '_by_drop_down_' + itemProp.id,
                text: _vm.$t('edit'),
                icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow(_vm.actualData.type === 'process' ? 'configuration_process_form_edit_form' : 'configuration_forms_item_edit_item', {id: itemProp.id, item_section_id: _vm.actualData.id, form_process_id: _vm.$root._route.params.id !== undefined ? _vm.$root._route.params.id : 0}),'configuration_register_item_form', false,{data: _vm.actualData, indexEdit: itemProp})}}}),_c('GhAction',{attrs:{"dataction":{
                id: 'action_delete_creation_' + _vm.actualData.type + '_by_drop_down_' +  itemProp.id,
                text: _vm.$t('delete'),
                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeItemForm', itemProp.id, {'name': _vm.actualData.type === 'process' ? itemProp.form : itemProp.item, 'data': {'data': _vm.actualData, 'item': itemProp}}, _vm.$t(_vm.actualData.type === 'process' ? 'delete_form' : 'delete_item'), _vm.$t(_vm.actualData.type === 'process' ? 'preparing_delete_form' : 'preparing_delete_item'), 'remove');}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_creation_' + _vm.actualData.type + '_by_drop_down_' + _vm.actualData.parentIndex + '_' + _vm.actualData.id,
                    text: _vm.$t('add_' + (_vm.actualData.type === 'process' ? 'form' : 'item'))
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow(_vm.actualData.type === 'process' ? 'configuration_process_form_new_form' : 'configuration_forms_item_new_item', {}),'configuration_register_item_form', false,{data: _vm.actualData})}}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }