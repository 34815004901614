import Priority from "@/view/configuration/priorities/views/view_priority.vue";
import Gender from "@/view/configuration/general_data/views/view_gender.vue";

const route = [
    {
        path: '/configuration/priority/priority',
        name: 'configuration_priority_priority_new',
        component: Priority,
        props: {id: null, showMenu: false, background: true, otherTitle: 'priority'},
        children: [
            {
                path: '/configuration/priority/priority/:id',
                name: 'configuration_priority_priority_edit',
                component: Gender,
                props: {id: null, showMenu: false, background: true, otherTitle: 'priority'},
            },
        ],
    },
];

const routes = [
    ...route,
]

export default routes;