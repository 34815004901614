<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationGeneralData.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_general_data_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationGeneralData.section_info[label['type']]['second_text']),
                    num_results: ConfigurationGeneralData.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_relationship from "@/view/configuration/general_data/list/list_relationship.vue";
    import list_nationality from "@/view/configuration/general_data/list/list_nationality.vue";
    import list_language from "@/view/configuration/general_data/list/list_language.vue";
    import list_genders from "@/view/configuration/general_data/list/list_genders.vue";

    export default {
        name: "sections_general_data",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationGeneralData']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'relationship', component: list_relationship, type: 'relationship'},
                    {id: 2, name: 'nationality', component: list_nationality, type: 'nationality'},
                    {id: 3, name: 'languages', component: list_language, type: 'languages'},
                    {id: 4, name: 'genders', component: list_genders, type: 'genders'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationGeneralDataSections','',{root:true});
            this.haveData = true;
        }
    }
</script>