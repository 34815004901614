import Equipment from "@/view/configuration/variable/views/view_equipment.vue";

const routes = [
    {
        path: '/configuration/variable/equipment',
        name: 'configuration_variable_new_equipment',
        component: Equipment,
        props: {id: null, showMenu: false, background: true, otherTitle: 'equipment'},
        children: [
            {
                path: '/configuration/variable/equipment/:id',
                name: 'configuration_variable_edit_equipment',
                component: Equipment,
                props: {id: null, showMenu: false, background: true, otherTitle: 'equipment'},
            },
            {
                path: '/configuration/variable/equipment/:id/:view',
                name: 'configuration_variable_view_equipment',
                component: Equipment,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'equipment'},
            }
        ],
    },

];

export default routes;