import CategoryForm from "@/view/configuration/form_process/forms/views/view_category_form.vue";

const routes = [
    {
        path: '/configuration/forms/category-form',
        name: 'configuration_forms_new_category_form',
        component: CategoryForm,
        props: {id: null, showMenu: false, background: true, otherTitle: 'category_form'},
        children: [
            {
                path: '/configuration/forms/category-form/:id',
                name: 'configuration_forms_edit_category_form',
                component: CategoryForm,
                props: {id: null, showMenu: false, background: true, otherTitle: 'category_form'},
            },
            {
                path: '/configuration/forms/category-form/:id/:view',
                name: 'configuration_forms_view_category_form',
                component: CategoryForm,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'category_form'},
            }
        ],
    },

];

export default routes;