<template>
    <GhTableCommon
        :extratable="{
              id: 'table_certifications'
          }"
        :header="header"
        :data="MyFile.data.certifications"
        style="margin-top: 12px;"
    >
        <template v-slot:field_component="{itemProp}">
            <GhEasyUpload style="width: 100%;"
                :extrablock="{
                    id: 'easy_upload_certification_' + itemProp.id,
                    class: '',
                    name_hidden: 'upload[certification][' + itemProp.id + ']',
                    id_hidden: 'upload_certification_' + itemProp.id,
                    icon_attach: require('../../../assets/gh_new_dessign/attach.svg'),
                    icon_trash: require('../../../assets/gh_new_dessign/trash.svg')
                }"
                :datalabel="{
                    id: 'label_import_certification_' + itemProp.id,
                    style: 'width: 100%'
                }"
                :datainput="{
                    text: $t('attach'),
                    show_image: false,
                    delete: true,
                    href: 'easyUpload',
                    file_name: itemProp.file.file_name,
                    file_path: itemProp.file.file_path,
                    file_download: itemProp.file.file_download,
                    image_path: '',
                    required: true
                }"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_certifications",
        components: {
            GhTableCommon,
            GhEasyUpload
        },
        computed: {
            ...mapState(['MyFile']),
        },
        data(){
            return {
                header: [
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'max-width: 100px;width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 15%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 20%;'},
                    {text: 'centers', field: 'centers', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 20%;'},
                    {text: 'expire_date', field: 'expiration_date', keyFormat: 'format_expire', sorting: true, typeSort: 'date', style: 'max-width: 100px;width: 100px;'},
                    {text: 'file', field: 'file', field_component: true, style: 'min-width: 300px; width: 30%;'},
                ]
            }
        }
    }
</script>
