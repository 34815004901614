import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationVariableTypeEquipments(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationVariableTypeEquipment(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationVariableTypeEquipments(state){
            await axios.get(
                API_URL + "configuration/variable/type-equipments"
            ).then(
                response => {
                    state.commit('loadTableConfigurationVariableTypeEquipments', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationVariableTypeEquipment(state, item){
            await axios.get(
                API_URL + "configuration/variable/type-equipments/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationVariableTypeEquipment', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationVariableTypeEquipment(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/variable/type-equipments/" + item.id : API_URL + "configuration/variable/type-equipments";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        return 'OK'
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationVariableTypeEquipments(state, item){
            let requestLogin = API_URL + "configuration/variable/type-equipments/" + item.id;

            await axios({
                method: "delete",
                url: requestLogin
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationVariableTypeEquipments');
                        await this.dispatch('getConfigurationVariableSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanConfigurationVariableTypeEquipment(state){
            state.commit('loadDataConfigurationVariableTypeEquipment', []);
        }
    }
};