<template>
    <div style="margin-top: 8px; white-space: nowrap;">
        <div>
            <GhEasyDrawCanvas
                :extrablock="{
                    id: 'draw_' + dataItem.item.config[dataItem.type === 1 ? 'textarea' : dataItem.type === 6 || dataItem.type === 7 ? 'input' : dataItem.type === 8 ? 'selector' : dataItem.type === 9 ? 'table' : dataItem.type === 10 ? 'imported' : dataItem.type == 11 ? 'graphic' : dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_picture',
                    class: '',
                    style: 'flex-direction: row !important;',
                    name_hidden: 'form_values[' + processId + '][' + subprocessId + '][' + blockId + '][' + formId + '][' + sectionId + '][' + indexItem + '][' + dataItem.id + '][draw]',
                    id_hidden: 'form_values_' + dataItem.item.config[dataItem.type === 1 ? 'textarea' : dataItem.type === 6 || dataItem.type === 7 ? 'input' : dataItem.type === 8 ? 'selector' : dataItem.type === 9 ? 'table' : dataItem.type === 10 ? 'imported' : dataItem.type == 11 ? 'graphic' : dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + processId + '_' + subprocessId + '_' + formId + '_' + sectionId + '_index_' + indexItem + '_item_' + dataItem.id + '_draw',
                    icon_attach: ''
                }"
                :datalabel="{
                    style: 'margin-left: 0px;'
                }"
                :datainput="{
                    text: $t('upload_picture'),
                    labelText: $t('picture'),
                    historyText: $t('history'),
                    show_image: true,
                    image: {
                        style: 'padding-left: 10px; padding-right: 10px;',
                        draw: true,
                        required: false,
                        standard_size: true,
                        max_height: 200,
                        popup: {
                            text: {
                                editTitle: $t('edit_image'),
                                viewTitle: $t('view_image'),
                                body: ''
                            },
                            style: {
                                footer: 'padding-top: 10px;'
                            },
                            square_image: require('../../../../../assets/gh_new_dessign/square.svg'),
                            circle_image: require('../../../../../assets/gh_new_dessign/circle.svg'),
                            draw_image: require('../../../../../assets/gh_new_dessign/line.svg'),
                            eraser_image: require('../../../../../assets/gh_new_dessign/eraser.svg'),
                            text_image: require('../../../../../assets/gh_new_dessign/text.svg')
                        }
                    },
                    href: dispatchEvidence,
                    file_name: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.file_name : '',
                    file_path: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.file_path : '',
                    file_download: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.file_download : '',
                    image_path: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.image_path : '',
                    actualBase64: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.actual : '',
                    required: true,
                    history: dataItem.item.data !== undefined && dataItem.item.data.draw !== undefined ? dataItem.item.data.draw.history : []
                }"
                @emiter="$parent.$parent.setChangesSection(subprocessId, $parent.$parent.dataSubprocess.id);"
            />
        </div>
    </div>
</template>

<script>
    import GhEasyDrawCanvas from "fe-gh-easy-draw-canvas-lib";

    export default {
        name: 'view_draw_picture',
        props: ['dataItem', 'indexItem', 'processId', 'subprocessId', 'formId', 'sectionId', 'blockId'],
        components:{
            GhEasyDrawCanvas
        },
        data(){
            return {
                dispatchEvidence: 'easyUpload',
                historyImage: []
            }
        }
    }
</script>