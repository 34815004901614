<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;" :key="refreshEquipments"
        :extratable="{
            id: 'configuration_equipments_equipments_',
        }"
        :header="header"
        :data="ConfigurationEquipmentsEquipments.records_data.length == 0 ? [] : ConfigurationEquipmentsEquipments.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_variable_view_equipment', {id: itemProp.id, view: true}),'configuration_variable_equipment')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_equipments_delete_equipments_' + itemProp.id,
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','removeEquipmentByProfessional', itemProp.id,{
                    'sn': itemProp.code,
                    'name': itemProp.name,
                    'type': itemProp.type,
                    'status': itemProp.status,
                    'description': itemProp.description,
                }, $t('delete_equipment'), $t('preparing_delete_equipment'), 'unselect')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <GhButton
                    :datainput="{
                        id: 'btn_add_configuration_equipments_new_equipments',
                        text: $t('add')
                    }"
                    @click="Global.openPopUp('popup_equipments')"
                />
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_equipments",
        components: {
            GhButton,
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['ConfigurationEquipmentsEquipments'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'sn', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 40%;'},
                    {text: 'type', field: 'type', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 20%;'},
                    {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 250px; width: 15%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                refreshEquipments: 0
            }
        },
        watch: {
            'ConfigurationEquipmentsEquipments.records_data'(){
                this.refreshEquipments++;
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationEquipmentsEquipments', '', {root: true});
            this.haveData = true;
        }
    }
</script>