var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_dependencies',
        title: _vm.$t('dependency_definition'),
        type: 'warning',
        style: 'width: 80%; min-width: 1000px'
    },"bodyConfiguration":{
        id: 'popup_rename_body',
        class: '',
        style: 'padding:2px;',
        text: _vm.$t('click_on_the_elements_to_define_the_dependency') + ':',
    },"footerConfiguration":{}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"dependencies-elements"},[_vm._l((_vm.dataBlock.data),function(item,index){return _vm._l((item.children),function(answer,keyAnswer){return _c('div',{key:'Q' + index + keyAnswer + _vm.refreshDependence,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"},attrs:{"id":'number_dependency_' + index + '_' + keyAnswer}},[_vm._v(_vm._s(answer.counter + ':'))]),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},style:('color: ' + _vm.returnColor(answer) + ' !important;'),attrs:{"dataction":{
                                id: 'action_select_dependency_',
                                text: item.text + ': ' + answer.text,
                            }},on:{"click":function($event){return _vm.checkDependency(index, keyAnswer)}}})],1)})})],2),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                        id: 'button_and_dependencies',
                        text: _vm.$t('and'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }},on:{"click":function($event){return _vm.addCondition('and')}}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'button_or_dependencies',
                        text: _vm.$t('or'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }},on:{"click":function($event){return _vm.addCondition('or')}}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'button_not_dependencies',
                        text: _vm.$t('not'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }},on:{"click":function($event){return _vm.addCondition('not')}}})],1),_c('div',{key:_vm.refreshTableDependency,staticClass:"dependencies-table",staticStyle:{"margin-top":"15px"}},[_c('table',{attrs:{"id":"table_dependencies"}},_vm._l((_vm.dependency),function(item,index){return _c('tr',{key:index,class:index % 2 === 0 ? 'striped_white' : 'striped'},[_c('td',{staticClass:"T15_b border_w table-cell",staticStyle:{"width":"auto","padding-right":"10px","text-align":"end"}},[_vm._v(_vm._s(_vm.$t('dependency') + ' ' + (index + 1) + ':'))]),_c('td',{staticClass:"T15_b border_w table-cell",staticStyle:{"width":"85%"}},[_vm._l((item.selecteds),function(i,k){return [_c('span',{key:'W' + k,style:('color: ' + i.color + ';')},[_vm._v(_vm._s(i.counter))]),_c('span',{key:'V' + k},[_vm._v(_vm._s(' ' + i.condition + ' '))])]})],2),_c('td',{staticStyle:{"width":"15%"}},[(item.active)?_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                    id: 'action_end_dependency_' + index,
                                    text: _vm.$t('end'),
                                    icon: require('../../../../../assets/svg/tick.svg'),
                                }},on:{"click":function($event){return _vm.endDependency(index)}}}):_vm._e(),(!item.active)?_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                    id: 'action_edit_dependency_' + index,
                                    text: _vm.$t('edit'),
                                    icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                                }},on:{"click":function($event){return _vm.editDependency(index)}}}):_vm._e(),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                    id: 'action_delete_dependency_' + index,
                                    text: _vm.$t('delete'),
                                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeDependencies', item.id, {'dependency': index + 1, 'data': {'index':index, 'dependency': _vm.dependency}}, _vm.$t('delete_dependency'), _vm.$t('preparing_delete_dependency'), 'remove');}}})],1)])}),0)]),_c('div',{staticClass:"gh-row-content"},[_c('GhAction',{staticStyle:{"margin-top":"10px"},attrs:{"dataction":{
                        id: 'action_new_dependencies',
                        text: _vm.$t('add_dependency'),
                    }},on:{"click":_vm.addDependency}})],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_save_popup_dependencies',
                text: _vm.$t('save'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){_vm.saveDependency();_vm.blockedByDependences();}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_dependencies',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.closeDependency()}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }