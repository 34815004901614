var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_equipment',
                title: _vm.$t('equipment')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_equipment"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})])]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefSn",attrs:{"datalabel":{
                                            text: _vm.$t('sn') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'equipment_sn',
                                            name: 'equipment[sn]',
                                            style: 'width: 200px',
                                            value: _vm.values.sn,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('sn') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.sn : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                            text: _vm.$t('name') + '*',
                                            style: 'width: 150px; min-width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'equipment_name',
                                            name: 'equipment[name]',
                                            style: 'min-width: 200px; width: 100%',
                                            value: _vm.values.name,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                            text: _vm.$t('description'),
                                            styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'equipment_description',
                                            name: 'equipment[description]',
                                            value: _vm.values.description
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'equipment_type',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_equipment_type') + ':',
                                            text: _vm.$t('equipment_type') + '*',
                                            label: _vm.$t('equipment_type'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'equipment_type',
                                            name: 'equipment[type]'
                                        },"feed":_vm.getFeeds.type,"selected_input":_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.type : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipments.data.type).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","min-width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('picture') + '*'))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                                        id: 'equipment_picture',
                                        class: '',
                                        name_hidden: 'equipment[picture]',
                                        id_hidden: 'equipment_picture',
                                        icon_attach: ''
                                    },"datalabel":{
                                        id: 'label_equipment_picture',
                                        style: 'margin-right: 5px'
                                    },"datainput":{
                                        view: _vm.$root._route.params.view,
                                        accept: 'image/png, image/gif, image/jpeg',
                                        text: _vm.$t('upload'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        file_name: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.picture !== undefined ? _vm.ConfigurationVariableEquipments.data.picture.file_name : '',
                                        file_path: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.picture !== undefined ? _vm.ConfigurationVariableEquipments.data.picture.file_path : '',
                                        file_download: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.picture !== undefined ? _vm.ConfigurationVariableEquipments.data.picture.file_download : '',
                                        required: true
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'equipment_departments',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_departments') + ':',
                                            text: _vm.$t('departments'),
                                            label: _vm.$t('departments'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'equipment_departments',
                                            name: 'equipment[departments][]'
                                        },"feed":_vm.getFeeds.departments,"selected_input":_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.departments : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('departments') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipments.data.departments).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'equipment_specialties',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_specialties') + ':',
                                            text: _vm.$t('specialties'),
                                            label: _vm.$t('specialties'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'equipment_specialties',
                                            name: 'equipment[specialties][]'
                                        },"feed":_vm.getFeeds.specialties,"selected_input":_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.specialties : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipments.data.specialties).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'equipment_room',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_room') + ':',
                                            text: _vm.$t('room'),
                                            label: _vm.$t('room'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'equipment_room',
                                            name: 'equipment[room][]'
                                        },"feed":_vm.getFeeds.room,"selected_input":_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.room : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('room') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipments.data.room).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"end","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('status')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                            required: false,
                                            type: 'radio',
                                            id: 'equipment_operative',
                                            value: 1,
                                            name: 'equipment[status]',
                                            selected: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.status_id !== undefined && _vm.ConfigurationVariableEquipments.data.status_id == 1 ? true : false,
                                        }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"25px"}},[_vm._v(_vm._s(_vm.$t('operative')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                            required: false,
                                            type: 'radio',
                                            id: 'equipment_non_operative',
                                            value: 2,
                                            name: 'equipment[status]',
                                            selected: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.status_id !== undefined && _vm.ConfigurationVariableEquipments.data.status_id == 2 ? true : false,
                                        }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('non_operative')))])]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('status') + ':'))]),(_vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.status_id !== undefined )?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[(_vm.ConfigurationVariableEquipments.data.status_id == 1)?[_vm._v(" "+_vm._s(_vm.$t('operative'))+" ")]:_vm._e(),(_vm.ConfigurationVariableEquipments.data.status_id == 2)?[_vm._v(" "+_vm._s(_vm.$t('non_operative'))+" ")]:_vm._e()],2)]:_vm._e()]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                            id: 'equipment_required_maintenance',
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_required_maintenance') + ':',
                                            text: _vm.$t('required_maintenance'),
                                            label: _vm.$t('required_maintenance'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'equipment_required_maintenance',
                                            name: 'equipment[required_maintenance][]'
                                        },"feed":_vm.getFeeds.required_maintenance,"selected_input":_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.required_maintenance : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('required_maintenance') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? Object.values(_vm.ConfigurationVariableEquipments.data.required_maintenance).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{attrs:{"datalabel":{
                                            id: 'equipment_checked_last',
                                            text: _vm.$t('last_checked'),
                                            style: 'width: 150px; min-width: 150px; text-align: right;'
                                        },"datainput":{
                                            id: 'equipment_last_checked',
                                            name: 'equipment[last_checked]',
                                            value: _vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.last_checked : '',
                                            required: false
                                        }}}),_c('GhCalendar',{attrs:{"datalabel":{
                                            id: 'equipment_one_next',
                                            text: _vm.$t('next_one'),
                                            style: 'width:85px;text-align: right;'
                                        },"datainput":{
                                            id: 'equipment_next_one',
                                            name: 'equipment[next_one]',
                                            value: _vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.next_one : '',
                                            required: false
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('last_checked') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.last_checked : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('next_one') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationVariableEquipments.data !== undefined ? _vm.ConfigurationVariableEquipments.data.next_one : ''))])]],2),_vm._l((_vm.employees),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'result_search_data_groups',
                                            nameStore: 'getFeeds',
                                            imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                            id: item.id,
                                            text: _vm.$t(item.id),
                                            textlocation: _vm.$t('add') + ' ' + _vm.$t(item.id),
                                            actionresult: 'loadResultSearchGroups',
                                            nameResult: 'equipment[' + item.id + ']',
                                            supportPermision: false,
                                        },"mainSelector":{
                                            name: 'Formsearchcompany',
                                            formId: 'search',
                                            actionsearch: 'getSearchGroups'
                                        },"tableResolt":{
                                            header: _vm.header,
                                            data: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data[item.id] !== undefined && _vm.ConfigurationVariableEquipments.data[item.id].summary !== undefined ? _vm.ConfigurationVariableEquipments.data[item.id].summary : []
                                        },"tableSelected":{
                                            headersummary: _vm.headersummary,
                                            selected: _vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data[item.id] !== undefined && _vm.ConfigurationVariableEquipments.data[item.id].summary !== undefined ? _vm.ConfigurationVariableEquipments.data[item.id].summary : []
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.id) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.summary[item.id]))])]],2)}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultiUpload',{attrs:{"datalabel":{
                                            text: _vm.$t('required_documents'),
                                            text_popup: _vm.$t('required_documents'),
                                            input_text: _vm.$t('name'),
                                            textarea_text: _vm.$t('description'),
                                            attach_text: _vm.$t('attach'),
                                            content_warning: '',
                                            label_required: _vm.$t('required_documents'),
                                            required: false,
                                        },"datablock":{
                                            id: 'equipment_documents',
                                            name: 'equipment[documents]',
                                            style: '',
                                            class: 'T15_b',
                                            icon_attach: '',
                                            icon_trash: '',
                                            icon_download: '',
                                            href: _vm.dispatchEvidence,
                                        },"defaultfiles":_vm.ConfigurationVariableEquipments.data !== undefined && _vm.ConfigurationVariableEquipments.data.required_documents !== undefined ? _vm.ConfigurationVariableEquipments.data.required_documents : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('required_documents') + ':'))]),_vm._l((_vm.ConfigurationVariableEquipments.data.required_documents),function(file,index){return _c('div',{key:index},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_c('a',{staticClass:"C1 F5",attrs:{"href":file.file_download}},[_vm._v(_vm._s(file.name))]),_vm._v(_vm._s(index != Object.keys(_vm.ConfigurationVariableEquipments.data.required_documents).pop() ? ', ' : ''))])])})]],2)],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.haveData && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitEquipment();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }