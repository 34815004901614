import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableVisitsUpcomingPatients(state, payload) {
            state.records_data = payload;
        },
        loadDataRescheduleUpcomingPatient(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getVisitsUpcomingPatientsList(state, item){
            await axios.get(
                API_URL + "visits/upcoming-patients/" + item.type
            ).then(
                response => {
                    state.commit('loadTableVisitsUpcomingPatients', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getRescheduleUpcomingPatient(state, item){
            await axios.get(
                API_URL + "visits/reschedule/" + item.id
            ).then(
                response => {
                    state.commit('loadDataRescheduleUpcomingPatient', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setArrivedUpcomingPatient(state, item){
            await axios.get(
                API_URL + "visits/upcoming-patients/" + item.id + "/arrived"
            ).then(
                response => {
                    console.log(response);
                    state.dispatch('getVisitsUpcomingPatientsList');
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};