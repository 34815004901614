<template>
    <div class="gh-content-global" v-if="haveData">
        <div class="gh-row-content">
            <div>
                <div :style="$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                    <template v-if="$root._route.params.view === undefined || $root._route.params.view === 'false'">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('name') + '*',
                                style:'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'register_' + $root._route.params.type + '_main_contact_person_name',
                                name: 'register[' + $root._route.params.type + '][main_contact_person][name]',
                                style: 'min-width: 130px; width: 100%',
                                value: '',
                                type: 'text',
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('name')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.main_contact_person !== undefined ? DataClinicsProfessionals.data.main_contact_person.name : ''}}</label>
                    </template>
                </div>
                <div :style="$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                    <template v-if="$root._route.params.view === undefined || $root._route.params.view === 'false'">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('surname') + '*',
                                style:'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'register_' + $root._route.params.type + '_main_contact_person_surname',
                                name: 'register[' + $root._route.params.type + '][main_contact_person][surname]',
                                style: 'min-width: 130px; width: 100%',
                                value: '',
                                type: 'text',
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('surname')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.main_contact_person !== undefined ? DataClinicsProfessionals.data.main_contact_person.surname : ''}}</label>
                    </template>
                </div>
            </div>
        </div>
        <div class="gh-row-content">
            <div>
                <div :style="$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                    <template v-if="$root._route.params.view === undefined || $root._route.params.view === 'false'">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('email') + '*',
                                style:'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'register_' + $root._route.params.type + '_main_contact_person_email',
                                name: 'register[' + $root._route.params.type + '][main_contact_person][email]',
                                style: 'min-width: 130px; width: 100%',
                                value: '',
                                type: 'text',
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('email')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.main_contact_person !== undefined ? DataClinicsProfessionals.data.main_contact_person.email : ''}}</label>
                    </template>
                </div>
                <div :style="$root._route.params.view === 'false' ? 'width: 33.33%;' : 'width: 33.33%; display: flex;'">
                    <template v-if="$root._route.params.view === undefined || $root._route.params.view === 'false'">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('telephone') + '*',
                                style:'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'register_' + $root._route.params.type + '_main_contact_person_telephone',
                                name: 'register[' + $root._route.params.type + '][main_contact_person][telephone]',
                                style: 'min-width: 130px; width: 100%',
                                value: '',
                                type: 'text',
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: end;">{{$t('telephone')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.main_contact_person !== undefined ? DataClinicsProfessionals.data.main_contact_person.telephone : ''}}</label>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import {mapState} from "vuex";

    export default {
        name: "main_contact_person",
        components: {
            GhInputTextField
        },
        computed: {
            ...mapState(['DataClinicsProfessionals']),
        },
        data(){
            return {
                haveData: false,
            }
        },
        async beforeMount() {
            this.haveData = true;
        }
    }
</script>
