<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'configuration_support_schedule'
        }"
        :header="header"
        :data="ConfigurationSupportSchedule.records_data.length == 0 ? [] : ConfigurationSupportSchedule.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('configuration_support_view_schedule', {id: itemProp.id, view: true}),'configuration_support_schedule')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_schedule_' + itemProp.id,
                    text: $t('edit'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('configuration_support_edit_schedule', {id: itemProp.id}),'configuration_support_schedule')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import {mapState} from "vuex";

    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "list_schedule",
        components:{
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['ConfigurationSupportSchedule'])
        },
        data(){
            return{
                haveData: false,
                header:[
                    {text: 'assignation_group', field: 'assignation_group', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'working_hours', field: 'working_hours', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 70%; '},
                    {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportSchedule', '', {root: true});
            this.haveData = true;
        }
    }
</script>