import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadTableVisitsUpcomingPatientsByProfessional(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getVisitsUpcomingPatientsByProfessionalList(state, item){
            await axios.get(
                API_URL + "visits/upcoming-patients-by-professional/" + item.type
            ).then(
                response => {
                    state.commit('loadTableVisitsUpcomingPatientsByProfessional', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};