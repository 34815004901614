<template>
    <div class="gh-content-global" v-if="haveData">
        <div v-for="(item,index) in ConfigurationProcessValidatePropagate.list_validate_propagate_process.data" :key="index" style="margin-bottom: 5px;">
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_field_validate_propagate_process_' + item.id,
                    title: item.name,
                }"
            >
                <template v-slot:content>
                    <GhTableCommon style="margin-top: 10px;"
                        :extratable="{
                            id: 'table_creation_process',
                        }"
                        :header="header"
                        :data="item.data === undefined ? [] : item.data"
                    >
                        <template v-slot:actions="{itemProp}">
                            <router-link :to="{name: 'configuration_process_validate_creation_process', params: {id: itemProp.id, validate: true}}">
                                <GhAction v-if="itemProp.show_validate"
                                    :dataction="{
                                        id: 'action_validate_process_' + itemProp.id,
                                        text: $t('validate'),
                                        icon: require('../../../../../assets/svg/tick.svg'),
                                    }"
                                />
                            </router-link>
                        </template>
                    </GhTableCommon>
                </template>
            </GhDropDownField>
        </div>
        <div class="centered" style="padding-top: 5px; margin-bottom: -5px !important;" v-if="ConfigurationProcessValidatePropagate.list_validate_propagate_process.show_propagate">
            <GhButton
                :datainput="{
                    id: 'btn_propagate_process',
                    text: $t('propagate')
                }"
                @click="toPropagate('process')"
            />
        </div>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import {mapState} from "vuex";

    export default {
        name: 'list_validate_process',
        components: {
            GhAction,
            GhTableCommon,
            GhButton,
            GhDropDownField
        },
        computed:{
            ...mapState(['ConfigurationProcessValidatePropagate']),
        },
        data(){
            return{
                header: [
                    {text: 'process', field: 'process', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 20%;'},
                    {text: 'related_items', field: 'related_items', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 70%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'max-width: 100px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false, style: 'width: 150px; max-width: 200px;'}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationValidatePropagateProcess','',{root:true});
            this.haveData = true;
        },
        methods: {
            async toPropagate(type){
                this.$parent.$parent.popupType = type;
                this.$parent.$parent.popupRefresh++;
                await this.$store.dispatch('getPropagateFormProcess', {type: type}, {root: true});
                this.Global.openPopUp('popup_propagate');
            }
        }
    }
</script>