<template>
    <div class="gh-content-global" v-if="haveData">
        <template v-if="DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.required_documents !== undefined">
            <div class="gh-row-content" v-for="(item, index) in DataClinicsProfessionals.data.required_documents.documents" :key="index">
                <label :class="$root._route.params.type == 'professional-second-phase' ? 'gh_text_field-label T15_b' : 'gh_text_field-label T15'" style="width: 150px; min-width: 150px; text-align: end; margin-right: 10px;">{{item.name_of_specialty + ($root._route.params.type == 'professional-second-phase' ? '*' : ':')}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'register_' + $root._route.params.type + '_required_documents_documents_' + index,
                        class: '',
                        name_hidden: 'register[' + $root._route.params.type + '][required_documents][documents][' + index + ']',
                        id_hidden: 'register_' + $root._route.params.type + '_required_documents_documents_' + index,
                        icon_attach: ''
                    }"
                    :datalabel="{
                        id: 'label_register_' + $root._route.params.type + '_required_documents_documents_' + index,
                        style: 'margin-right: -15px'
                    }"
                    :datainput="{
                        view: $root._route.params.view,
                        accept: '',
                        text: $t('upload'),
                        label_required: item.name_of_specialty + '*',
                        show_image: false,
                        delete: true,
                        href: 'easyUpload',
                        file_name: item.file_name !== undefined ? item.file_name : '',
                        file_path: item.file_path !== undefined ? item.file_path : '',
                        file_download: item.file_download !== undefined ? item.file_download : '',
                        required: true
                    }"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import {mapState} from "vuex";

    export default {
        name: "required_documents",
        components: {
            GhEasyUpload
        },
        data(){
            return {
                haveData: false
            }
        },
        computed: {
            ...mapState(['DataClinicsProfessionals']),
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>