var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"8px","white-space":"nowrap"}},[_c('div',[_c('GhEasyDrawCanvas',{attrs:{"extrablock":{
                id: 'draw_' + _vm.dataItem.item.config[_vm.dataItem.type === 1 ? 'textarea' : _vm.dataItem.type === 6 || _vm.dataItem.type === 7 ? 'input' : _vm.dataItem.type === 8 ? 'selector' : _vm.dataItem.type === 9 ? 'table' : _vm.dataItem.type === 10 ? 'imported' : _vm.dataItem.type == 11 ? 'graphic' : _vm.dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_picture',
                class: '',
                style: 'flex-direction: row !important;',
                name_hidden: 'form_values[' + _vm.processId + '][' + _vm.subprocessId + '][' + _vm.blockId + '][' + _vm.formId + '][' + _vm.sectionId + '][' + _vm.indexItem + '][' + _vm.dataItem.id + '][draw]',
                id_hidden: 'form_values_' + _vm.dataItem.item.config[_vm.dataItem.type === 1 ? 'textarea' : _vm.dataItem.type === 6 || _vm.dataItem.type === 7 ? 'input' : _vm.dataItem.type === 8 ? 'selector' : _vm.dataItem.type === 9 ? 'table' : _vm.dataItem.type === 10 ? 'imported' : _vm.dataItem.type == 11 ? 'graphic' : _vm.dataItem.type === 12 ? 'matrix' : 'checks'].id + '_' + _vm.processId + '_' + _vm.subprocessId + '_' + _vm.formId + '_' + _vm.sectionId + '_index_' + _vm.indexItem + '_item_' + _vm.dataItem.id + '_draw',
                icon_attach: ''
            },"datalabel":{
                style: 'margin-left: 0px;'
            },"datainput":{
                text: _vm.$t('upload_picture'),
                labelText: _vm.$t('picture'),
                historyText: _vm.$t('history'),
                show_image: true,
                image: {
                    style: 'padding-left: 10px; padding-right: 10px;',
                    draw: true,
                    required: false,
                    standard_size: true,
                    max_height: 200,
                    popup: {
                        text: {
                            editTitle: _vm.$t('edit_image'),
                            viewTitle: _vm.$t('view_image'),
                            body: ''
                        },
                        style: {
                            footer: 'padding-top: 10px;'
                        },
                        square_image: require('../../../../../assets/gh_new_dessign/square.svg'),
                        circle_image: require('../../../../../assets/gh_new_dessign/circle.svg'),
                        draw_image: require('../../../../../assets/gh_new_dessign/line.svg'),
                        eraser_image: require('../../../../../assets/gh_new_dessign/eraser.svg'),
                        text_image: require('../../../../../assets/gh_new_dessign/text.svg')
                    }
                },
                href: _vm.dispatchEvidence,
                file_name: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.file_name : '',
                file_path: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.file_path : '',
                file_download: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.file_download : '',
                image_path: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.image_path : '',
                actualBase64: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.actual : '',
                required: true,
                history: _vm.dataItem.item.data !== undefined && _vm.dataItem.item.data.draw !== undefined ? _vm.dataItem.item.data.draw.history : []
            }},on:{"emiter":function($event){return _vm.$parent.$parent.setChangesSection(_vm.subprocessId, _vm.$parent.$parent.dataSubprocess.id);}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }