<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'clinic',
        }"
        :header="header"
        :data="SubmitsClinics.records_data.length == 0 ? [] : SubmitsClinics.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'clinic'}),'view_submit')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_validate_clinic_' + itemProp.id,
                    text: $t('validate'),
                }"
                @click="Global.deleteConfirm('delete_confirm','validateClinic', itemProp.id,{'name_of_clinic': itemProp.name_of_clinic, 'business_number': itemProp.business_number}, $t('validate_clinic'), $t('you_are_preparing_to_validate_the_following_clinic'), 'validate')"
            />
            <GhAction
                :dataction="{
                    id: 'action_view_clinic_' + itemProp.id,
                    text: $t('view'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('view_submit', {id: itemProp.id, view: true, type: 'clinic'}),'view_submit')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';

    import {mapState} from "vuex";

    export default {
        name: "list_clinics",
        data(){
            return {
                header: [
                    {text: 'name_of_clinic', field: 'name_of_clinic', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; width: 100%;'},
                    {text: 'business_number', field: 'business_number', sorting: true, typeSort: 'string', style: 'min-width: 250px; width: 250px;'},
                    {text: 'signup_date', field: 'signup_date', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        computed:{
            ...mapState(['SubmitsClinics'])
        },
        components: {
            GhTableCommon,
            GhAction
        },
        async beforeMount(){
            await this.$store.dispatch('getSubmitsClinics', '', {root: true});
            this.haveData = true;
        }
    }
</script>
