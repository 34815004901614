<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationSupport.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_support_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationSupport.section_info[label['type']]['second_text']),
                    num_results: ConfigurationSupport.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import GhDropSelector from "fe-gh-dropselector-lib";

    import list_category from "@/view/configuration/support/list/list_category.vue";
    import list_priority from "@/view/configuration/support/list/list_priority.vue";
    import list_assignment from "@/view/configuration/support/list/list_assignment.vue";
    import list_schedule from "@/view/configuration/support/list/list_schedule.vue";
    import list_holiday from "@/view/configuration/support/list/list_holiday.vue";
    import list_flows from "@/view/configuration/support/list/list_flows.vue";

    export default {
        name: 'section_support',
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationSupport']),
        },
        data() {
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'category', component: list_category, type: 'category'},
                    {id: 2, name: 'priority', component: list_priority, type: 'priority'},
                    {id: 3, name: 'assignment', component: list_assignment, type: 'assignment'},
                    {id: 4, name: 'schedule', component: list_schedule, type: 'schedule'},
                    {id: 5, name: 'holidays', component: list_holiday, type: 'holidays'},
                    {id: 6, name: 'workflows', component: list_flows, type: 'workflows'}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSupportSections','',{root:true});
            this.haveData = true;
        }
    }
</script>