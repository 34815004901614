var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_equipments',
        title: _vm.$t('equipments'),
        type: 'warning',
        style: 'width: 50%; min-width: 700px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:15px; margin-left:25px;',
        text: _vm.$t('select_equipments') + ':',
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_popup_equipments"}},[_c('div',{key:_vm.refresh,staticClass:"gh-content-global",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"display":"block","width":"50%"}},_vm._l((_vm.preEquipmentsArray),function(item,index){return _c('div',{key:index,staticStyle:{"padding-bottom":"7px"}},[_c('GhChecks',{attrs:{"datainput":{
                                id: 'equipments_selection_' + item.id,
                                value: item.id,
                                type: 'checkbox',
                                name: 'equipments[]',
                                style: 'cursor:pointer;',
                                selected: _vm.selecteds.includes(item.id),
                            },"datalabel":{
                                style: '',
                                class: 'T15 input_label',
                                id: 'label_equipments_selection_' + item.id,
                                text: item.name
                            }}})],1)}),0),_c('div',{staticStyle:{"display":"block","width":"50%"}},_vm._l((_vm.postEquipmentsArray),function(item,index){return _c('div',{key:index,staticStyle:{"padding-bottom":"7px"}},[_c('GhChecks',{attrs:{"datainput":{
                                id: 'equipments_selection_' + item.id,
                                value: item.id,
                                type: 'checkbox',
                                name: 'equipments[]',
                                style: 'margin-left:48px; cursor:pointer;',
                                selected: _vm.selecteds.includes(item.id),
                            },"datalabel":{
                                style: '',
                                class: 'T15 input_label',
                                id: 'label_equipments_selection_' + item.id,
                                text: item.name
                            }}})],1)}),0)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_popup_equipments',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":_vm.setEquipment}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_equipments',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_equipments');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }