var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'view_profiles',
            title: _vm.$t('profiles')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"profiles_permissions"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'name',
                                    name: 'profile[name]',
                                    style: 'width: 40%',
                                    value: _vm.PermissionsProfiles.data !== undefined ? _vm.PermissionsProfiles.data.name : '',
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsProfiles && _vm.PermissionsProfiles.data && _vm.PermissionsProfiles.data.name ? _vm.PermissionsProfiles.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    class: 'T15_b',
                                    styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                },"datainput":{
                                    id: 'description',
                                    name: 'profile[description]',
                                    required: false,
                                    value: _vm.PermissionsProfiles.data !== undefined ? _vm.PermissionsProfiles.data.description : '',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsProfiles && _vm.PermissionsProfiles.data && _vm.PermissionsProfiles.data.description ? _vm.PermissionsProfiles.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'label_groups',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_group') + ':',
                                    text: _vm.$t('groups') + '*',
                                    label: _vm.$t('groups'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                },"datainput":{
                                    id: 'groups',
                                    name: 'profile[groups][]'
                                },"feed":_vm.getFeeds.feed_groups,"selected_input":_vm.PermissionsProfiles && _vm.PermissionsProfiles.data && _vm.PermissionsProfiles.data.groups ? _vm.PermissionsProfiles.data.groups : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groups')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.PermissionsProfiles && _vm.PermissionsProfiles.data && _vm.PermissionsProfiles.data.groups ? Object.values(_vm.PermissionsProfiles.data.groups).join() : ''))])]],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('accept'),
            }},on:{"click":_vm.submitPermsProfiles}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }