var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.dataBlock.items),function(item,index){return _c('div',{key:index,ref:'RefLineSelector' + item.id,refInFor:true,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('answer') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(item.answer))]),(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'radio',
                    id: _vm.$parent.$parent.$parent.type_item + '_selecteds_' + item.id + '_item',
                    label: _vm.$t('item'),
                    title: _vm.$t('item'),
                    text: _vm.$t('select_item') + ':',
                    name: _vm.$parent.$parent.$parent.type_item + '[selecteds][' + item.id + '][item]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: false,
                    style: 'margin-left: 30px;margin-top:2px;',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"selected_input":item.item,"items":_vm.getFeeds.items}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('item') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"33.5%"}},[_vm._v(_vm._s(item.item[0].text))])],_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('associate_to')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: _vm.$parent.$parent.$parent.type_item + '_selecteds_' + item.id + '_associate_to',
                value: 1,
                name: _vm.$parent.$parent.$parent.type_item + '[selecteds][' + item.id + '][associate_to]',
                selected: item.associate_to,
            }}}),(_vm.$root._route.params.view !== 'true')?[_c('GhAction',{staticStyle:{"margin-left":"10px","width":"max-content","white-space":"nowrap"},attrs:{"dataction":{
                    id: 'action_delete_' + item.id,
                    text: _vm.$t('delete'),
                    icon: require('../../../../../../assets/gh_new_dessign/trash.svg')
                }},on:{"click":function($event){return _vm.$parent.removeAnswer(item.id)}}})]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }