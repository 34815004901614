import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        professional_array: '',
        calendar_professional_array: [],
    },
    mutations: {
        loadProfessional(state, payload) {
            state.professional_array = payload;
        },
        loadCalendarProfessional(state, payload) {
            state.calendar_professional_array[payload.id] = payload.data;
        },
    },
    actions: {
        async getProfessional(state){
            await axios.get(
                API_URL + "calendar/professional"
            ).then(
                response => {
                    state.commit('loadProfessional', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCalendarProfessional(state,item){
            var requestURL = "calendar/professional/" + item.id;

            if(item.start_today !== undefined){
                requestURL += "/start-today";
            }

            if(item.no_lather_than !== undefined && item.actual_month !== undefined){
                requestURL += "/" + item.actual_month + "/" + item.no_lather_than.replaceAll('/', '-');
            }

            await axios.get(
                API_URL + requestURL
                // API_URL + "calendar/professional/:id"
            ).then(
                response => {
                    state.commit('loadCalendarProfessional', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async updateCalendarProfessional(state,item){
            // var requestURL = "calendar/professional/:id/:idMonth";
            var requestURL = "calendar/professional/" + item.id;

            if(item.start_today !== undefined){
                requestURL += "/start-today";
            }

            if(item.no_lather_than !== undefined && item.actual_month !== undefined){
                requestURL += "/" + item.actual_month + "/" + item.no_lather_than.replaceAll('/', '-');
            }

            await axios.get(
                API_URL + requestURL
            ).then(
                response => {
                    state.commit('loadCalendarProfessional', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};