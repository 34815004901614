var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$root._route.params.id !== undefined ? _vm.$t('edit_priority') : _vm.$t('add_priority')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_priority',
                title: _vm.$t('priority')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_priority"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('id') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'priority_code',
                                    name: 'priority[code]',
                                    style: 'width: 300px',
                                    value: _vm.ConfigurationPriorityPriorities.data !== undefined ? _vm.ConfigurationPriorityPriorities.data.code : '',
                                    type: 'text',
                                }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('base_line') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'priority_base_line',
                                    name: 'priority[base_line]',
                                    style: 'width: 300px',
                                    value: _vm.ConfigurationPriorityPriorities.data !== undefined ? _vm.ConfigurationPriorityPriorities.data.base_line : '',
                                    type: 'text',
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 150px; min-width: 150px; text-align: right; margin-right: 11px;',
                                    class: 'T15_b'
                                },"datainput":{
                                    id: 'priority_description',
                                    name: 'priority[description]',
                                    required: false,
                                    value: _vm.ConfigurationPriorityPriorities.data !== undefined ? _vm.ConfigurationPriorityPriorities.data.description : '',
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('modifiers')))])]),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{ref:"refCondition",attrs:{"datablock":{
                                    id: 'label_condition_modifier_condition',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_condition') + ':',
                                    text: _vm.$t('condition'),
                                    label: _vm.$t('condition'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: false,
                                    style: 'margin-left: 130px;',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                },"datainput":{
                                    id: 'condition_modifier_condition',
                                    name: 'condition[modifier][condition]'
                                },"feed":_vm.getFeeds.conditions,"selected_input":_vm.ConfigurationPriorityPriorities.data !== undefined && _vm.ConfigurationPriorityPriorities.data.condition !== undefined && _vm.ConfigurationPriorityPriorities.data.condition.length !== 0 ? _vm.ConfigurationPriorityPriorities.data.condition : []},on:{"accept":_vm.getDataCondition}})],1),_vm._l((_vm.conditions_data),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"250px","min-width":"250px","text-align":"right"}},[_vm._v(_vm._s(item.text))])]),_vm._l((item.data),function(subItem,subIndex){return _c('div',{key:subIndex,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"250px","min-width":"250px","text-align":"right"}},[_vm._v(_vm._s(subItem.genre + (subItem.genre !== undefined && subItem.genre != '' ? ',  ' : '')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(subItem.age + ',  '))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(subItem.modifier_percentage + ',  '))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(subItem.booking_time))])])})],2)})],2)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.submitPriority();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close',
                text: _vm.$t('close'),
            }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }