import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        section_info: '',
        calendar: [],
    },
    mutations: {
        loadSurgeryInfo(state, payload) {
            state.section_info = payload;
        },
        loadCalendarScheduleSurgery(state, payload) {
            state.calendar[payload.id] = payload.data;
        },
        loadUpdateCalendarScheduleSurgery(state, payload) {
            state.calendar[payload.id] = payload.data;
        }
    },
    actions: {
        async getSurgerySections(state){
            await axios.get(
                API_URL + "surgery/sections"
            ).then(
                response => {
                    state.commit('loadSurgeryInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCalendarScheduleSurgery(state, item){
            await axios.get(
                API_URL + "surgery/" + item.id + "/schedule"
            ).then(
                response => {
                    state.commit('loadCalendarScheduleSurgery', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async updateCalendarScheduleSurgery(state, item){
            await axios.get(
                API_URL + "surgery/" + item.id + "/schedule/" + item.month
            ).then(
                response => {
                    state.commit('loadUpdateCalendarScheduleSurgery', {data:response.data.data,id:item.id});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};