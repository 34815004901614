import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        list_creation_form: [],
    },
    mutations: {
        loadConfigurationCreationForm(state, payload) {
            state.list_creation_form = payload;
        },
        loadDataConfigurationCreationForm(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getConfigurationCreationForm(state){
            await axios.get(
                API_URL + "configuration/forms/creation-form"
            ).then(
                response => {
                    state.commit('loadConfigurationCreationForm', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationCreationForm(state, item){
            let requestLogin = API_URL + "configuration/process/creation-form/" + item.id;
            let formData = new FormData();

            axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationCreationForm');
                        state.rootState.ConfigurationForms.section_forms_info.creation_form.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};