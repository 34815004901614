<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('my_file') + ' / ' + $t('clinic')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_clinic',
                    title: $t('clinic')
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="initial_registration"/>
                </template>
            </GhNoDropSelector>

            <div v-for="(label, labelIndex) in data" :key="labelIndex">
                <GhDropSelector v-if="MyFileClinics.section_info[label['type']].show"
                    :datablock="{
                        id: 'sections_my_file_clinic_'+label.id,
                        title: $t(label['name']),
                        second_title: $t(MyFileClinics.section_info[label['type']]['second_text']),
                        num_results: MyFileClinics.section_info[label['type']]['result'],
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component"/>
                    </template>
                </GhDropSelector>
            </div>
        </div>

        <div class="centered" style="margin-top: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import initial_registration from "@/view/register/template/initial_registration.vue";
    import list_historic_patient from "@/view/my_file/lists/list_historic_patient.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_my_file_clinics",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhDropSelector,
            GhButton,
        },
        computed : {
            ...mapState(['MyFileClinics'])
        },
        data(){
            return{
                initial_registration: initial_registration,
                data: [
                    {id: 'calendar', name: 'calendar', component: '', type: 'calendar'},
                    {id: 'historic', name: 'historic', component: list_historic_patient, type: 'historic'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getDataClinicOrProfessionalById', {
                type: 'clinics',
                extra_type: 'my-file',
                id: this.$root._route.params.id
            }, {root: true});

            await this.$store.dispatch('getMyFileCenterSectionsById', {
                id: this.$root._route.params.id
            }, {root: true});

            await this.$store.dispatch('getMyFileClinicsData', {type: 'calendar'}, {root: true});
            await this.$store.dispatch('getMyFileClinicsData', {type: 'historic-patient'}, {root: true});

            this.haveData = true;
        }
    }
</script>