<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab
            :items="$router.options.GhTabsConfiguration"
            :dataBlock="{
                id: 'configuration_tab_',
                calldispatch: 'getConfigTabActive'
            }"
        />
        <component v-bind:is="Configuration.subTabActive"/>
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";
    import {mapState} from "vuex";

    export default {
        name: 'template_configuration',
        components: {
            GhSubTab
        },
        computed:{
            ...mapState(['Configuration']),
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            }
        },
        data(){
            return{
                haveData: false
            }
        },
        mounted(){
            var that = this;
            setTimeout(function(){
                that.haveData = true;
            },250);
        }
    }
</script>