import Validations from "@/view/validations/sections/sections_validations.vue";
import ValidationsProfessional from "@/view/validations/sections/sections_professional_view.vue";

const routes = [
    {
        path: '/validations',
        name: 'validations',
        props: {showMenu: true},
        component: Validations
    },
    {
        path: '/validations/:type/:id/:view',
        name: 'view_validations',
        props: {showMenu: false, background: true, id: null, view: null, type: null},
        component: ValidationsProfessional
    },
];

export default routes;