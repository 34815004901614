import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        subSurgeryTabActive: '',
        nameSurgerySubTabActive: '',
    },
    mutations: {
        loadTableSurgery(state, payload) {
            state.records_data = payload;
        },
        loadSurgerySubActiveItem(state, payload) {
            state.subSurgeryTabActive = payload
        },
        loadSurgeryNameSubActiveItem(state,payload){
            state.nameSurgerySubTabActive = payload
        },
    },
    actions: {
        getSurgeryTabActive(state, item) {
            let idTab = ''
            let name = ''
            router.options.routes.forEach((items,index) => {
                if ((item.call == items.name && item.call)){
                    idTab = router.options.routes[index].component;
                    name = items.name
                }
                state.commit("loadSurgerySubActiveItem", idTab != '' ? idTab : '');
                state.commit("loadSurgeryNameSubActiveItem", name != '' ? name : '');
            });
        },
        async getSurgeryList(state){
            await axios.get(
                API_URL + "surgery"
            ).then(
                response => {
                    state.commit('loadTableSurgery', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataSurgery(state, item){
            await axios.get(
                API_URL + "surgery/" + item.id
            ).then(
                response => {
                    state.rootState.Patients.data = response.data.data.patient_data;
                    state.rootState.DataClinicsProfessionals.data = {
                        'initial_registration': response.data.data.initial_registration
                    };
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};