import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationCalendarAvailability(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationCalendarAvailability(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationCalendarAvailability(state){
            await axios.get(
                API_URL + "configuration/calendar/availability"
            ).then(
                response => {
                    state.commit('loadTableConfigurationCalendarAvailability', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationCalendarAvailability(state, item){
            await axios.get(
                API_URL + "configuration/calendar/availability/" + item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationCalendarAvailability', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationCalendarAvailability(state, item){
            let request = item.id !== undefined ? API_URL + "configuration/calendar/availability/" + item.id : API_URL + "configuration/calendar/availability";
            let formData = new FormData();

            formData.append('availability[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            return await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        return 'OK';
                    }
                    // state.dispatch('getConfigurationCalendarAvailability');
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationCalendarAvailability(state, item){
            let requestLogin = API_URL + "configuration/calendar/availability/" + item.id + "/remove";
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getConfigurationCalendarAvailability');
                        await this.dispatch('getConfigurationCalendarSections');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanConfigurationCalendarAvailability(state){
            state.commit('loadDataConfigurationCalendarAvailability', []);
        }
    }
};