<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $root._route.params.id !== undefined ? $t('edit_priority') : $t('add_priority')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_priority',
                    title: $t('priority')
                }"
            >
                <template v-slot:content>
                    <form id="form_priority" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('id') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'priority_code',
                                        name: 'priority[code]',
                                        style: 'width: 300px',
                                        value: ConfigurationPriorityPriorities.data !== undefined ? ConfigurationPriorityPriorities.data.code : '',
                                        type: 'text',
                                    }"
                                />
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('base_line') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'priority_base_line',
                                        name: 'priority[base_line]',
                                        style: 'width: 300px',
                                        value: ConfigurationPriorityPriorities.data !== undefined ? ConfigurationPriorityPriorities.data.base_line : '',
                                        type: 'text',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 150px; min-width: 150px; text-align: right; margin-right: 11px;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'priority_description',
                                        name: 'priority[description]',
                                        required: false,
                                        value: ConfigurationPriorityPriorities.data !== undefined ? ConfigurationPriorityPriorities.data.description : '',
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('modifiers')}}</label>
                            </div>
                            <div class="gh-row-content">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_condition_modifier_condition',
                                        class: 'T15_b',
                                        subtitle: $t('select_condition') + ':',
                                        text: $t('condition'),
                                        label: $t('condition'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: 'margin-left: 130px;',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'condition_modifier_condition',
                                        name: 'condition[modifier][condition]'
                                    }"
                                    :feed="getFeeds.conditions"
                                    :selected_input="ConfigurationPriorityPriorities.data !== undefined && ConfigurationPriorityPriorities.data.condition !== undefined && ConfigurationPriorityPriorities.data.condition.length !== 0 ? ConfigurationPriorityPriorities.data.condition : []"
                                    @accept="getDataCondition"
                                    ref="refCondition"
                                />
                            </div>
                            <div v-for="(item,index) in conditions_data" :key="index">
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 250px; min-width: 250px; text-align: right;">{{item.text}}</label>
                                </div>
                                <div v-for="(subItem,subIndex) in item.data" :key="subIndex" class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 250px; min-width: 250px; text-align: right;">{{subItem.genre + (subItem.genre !== undefined && subItem.genre != '' ? ',&nbsp;&nbsp;' : '')}}</label>
                                    <label class="gh_text_field-label T15_b" style="text-align: right;">{{subItem.age + ',&nbsp;&nbsp;'}}</label>
                                    <label class="gh_text_field-label T15_b" style="text-align: right;">{{subItem.modifier_percentage + ',&nbsp;&nbsp;'}}</label>
                                    <label class="gh_text_field-label T15_b" style="text-align: right;">{{subItem.booking_time}}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
        </div>

        <div class="centered" style="margin-top: 10px;">
            <GhButton v-if="!this.$root._route.params.view"
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="submitPriority();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>

    import GhLocation from "fe-gh-location-lib";
    import {mapState} from "vuex";
    import {loadLanguageAsync} from "@/main";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "view_priority",
        components:{
            GhButton,
            GhDataSelector,
            GhTextarea,
            GhInputTextField,
            GhNoDropSelector,
            GhLocation
        },
        computed: {
            ...mapState(['getFeeds','ConfigurationPriorityPriorities']),
        },
        data(){
            return{
                haveData: false,
                conditions_data: [],
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);

            await this.$store.dispatch('getFeedConditions', '', {root: true});

            this.ConfigurationPriorityPriorities.data = [];
            this.ConfigurationPriorityPriorities.conditions_data = [];
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getConfigurationPriorityData', {id:this.$root._route.params.id}, {root: true});
                this.conditions_data = this.ConfigurationPriorityPriorities.conditions_data !== undefined && this.ConfigurationPriorityPriorities.conditions_data.length !== 0 ? this.ConfigurationPriorityPriorities.conditions_data : [];
            }

            this.haveData = true;
        },
        methods: {
            async getDataCondition() {
                var conditions = Object.keys(this.$refs.refCondition.checkdata);
                if(conditions !== undefined && conditions.length !== 0) {
                    await this.$store.dispatch('getConfigurationPrioritiesConditions', {conditions: conditions}, {root: true});
                    this.conditions_data = this.ConfigurationPriorityPriorities.conditions_data;
                }else{
                    this.conditions_data = [];
                }
            },
            async submitPriority(){
                if(this.Global.checkRequired('form_priority') == 'OK'){
                    const formData = new FormData(document.getElementById('form_priority'));
                    await this.$store.dispatch('setDataConfigurationPriority', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>