<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationPriority.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_priority_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationPriority.section_info[label['type']]['second_text']),
                    num_results: ConfigurationPriority.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
import GhDropSelector from "fe-gh-dropselector-lib";

import {mapState} from "vuex";

import list_conditions from "@/view/configuration/priorities/list/list_conditions.vue";
import list_priorities from "@/view/configuration/priorities/list/list_priorities.vue";

export default {
    name: "sections_priority",
    components: {
        GhDropSelector
    },
    computed:{
        ...mapState(['ConfigurationPriority']),
    },
    data(){
        return {
            haveData: false,
            data: [
                {id: 1, name: 'conditions', component: list_conditions, type: 'conditions'},
                {id: 2, name: 'priorities', component: list_priorities, type: 'priorities'},
            ],
        }
    },
    async beforeMount(){
        await this.$store.dispatch('getConfigurationPrioritiesSections','',{root:true});
        this.haveData = true;
    }
}
</script>