var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'my_file_data',
            title: _vm.$t('personal_data')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.initial_registration,{tag:"component"})]},proxy:true}],null,false,1068412284)}),_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.MyFile.section_info[label['type']].show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'submits_'+label.id,
                title: _vm.$t(label['name']),
                second_title: _vm.$t(_vm.MyFile.section_info[label['type']]['second_text']),
                num_results: _vm.MyFile.section_info[label['type']]['result'],
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true},(label['type'] == 'clinics')?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'leave_all',
                        text: _vm.$t('leave'),
                        icon: require('../../../assets/gh_new_dessign/denied.svg'),
                    }},on:{"click":function($event){return _vm.leaveAllCenter(_vm.itemProp)}}})]},proxy:true}:null],null,true)}):_vm._e()],1)}),_c(_vm.popup_leave,{tag:"component",attrs:{"data":{center: _vm.center, leave_id: _vm.leave_id}}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }