import Surgery from "@/view/surgery/sections/section_surgery.vue";
import SurgeryForm from "@/view/surgery/views/view_surgery.vue";
import ScheduleSurgery from "@/view/surgery/views/partials/view_schedule_surgery.vue";

const routes = [
    {
        path: '/surgery',
        name: 'surgery',
        props: {showMenu: true},
        component: Surgery
    },
    {
        path: '/surgery/:id',
        name: 'surgery_id',
        props: {id: null, showMenu: true, otherTitle: 'surgery_form'},
        component: SurgeryForm,
    },
    {
        path: '/surgery/:id/schedule',
        name: 'schedule_id_surgery',
        props: {id: null, showMenu: false, background: true, otherTitle: 'Schedule surgery'},
        component: ScheduleSurgery,
    },
];

export default routes;