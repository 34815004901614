var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{attrs:{"id":"option_to_select_dosage"}},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('recurrence')))]),_c('GhChecks',{ref:"RefRecurrence1",attrs:{"datalabel":{
                text: _vm.$t('continous')
            },"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'radio',
                id: _vm.$parent.$parent.type_item + '_dosage_recurrence_1',
                value: 1,
                name: _vm.$parent.$parent.type_item + '[dosage][recurrence]',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.recurrence !== undefined && _vm.$parent.$parent.stateData.data.dosage.recurrence === 1 ? true : '',
            }},on:{"click":function($event){return _vm.checkContinous('RefRecurrence1')}}}),_c('GhChecks',{ref:"RefRecurrence2",attrs:{"datalabel":{
                text: _vm.$t('intermittent')
            },"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'radio',
                id: _vm.$parent.$parent.type_item + '_dosage_recurrence_2',
                value: 2,
                name: _vm.$parent.$parent.type_item + '[dosage][recurrence]',
                style: 'margin-left: 20px;',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.recurrence !== undefined && _vm.$parent.$parent.stateData.data.dosage.recurrence === 2 ? true : '',
            }},on:{"click":function($event){return _vm.checkContinous('RefRecurrence2')}}})],1),(_vm.showPeriods)?_c('div',{staticClass:"gh-row-content",staticStyle:{"height":"18px"}},[_c('div',[_c('div',{staticStyle:{"width":"40%"},style:(!_vm.$root._route.params.view ? '' : 'display: flex;')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'label_' + _vm.$parent.$parent.type_item + '_dosage_period_every',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_period') + ':',
                            text: _vm.$t('period_every'),
                            label: _vm.$t('period_every'),
                            type: 'radio',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: _vm.$parent.$parent.type_item + '_dosage_period_every',
                            name: _vm.$parent.$parent.type_item + '[dosage][period_every]'
                        },"feed":_vm.getFeeds.periods,"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.period_every !== undefined ? _vm.$parent.$parent.stateData.data.dosage.period_every : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('period_every') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.period_every !== undefined ? Object.values(_vm.$parent.$parent.stateData.data.dosage.period_every).join() : ''))])]],2),_c('div',{staticClass:"no-min-width-children"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'label_' + _vm.$parent.$parent.type_item + '_dosage_period_lasting',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_period') + ':',
                            text: _vm.$t('period_lasting'),
                            label: _vm.$t('period_lasting'),
                            type: 'radio',
                            columns: 3,
                            required: false,
                            style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: _vm.$parent.$parent.type_item + '_dosage_period_lasting',
                            name: _vm.$parent.$parent.type_item + '[dosage][period_lasting]'
                        },"feed":_vm.getFeeds.periods,"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.period_lasting !== undefined ? _vm.$parent.$parent.stateData.data.dosage.period_lasting : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('period_lasting') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.period_lasting !== undefined ? Object.values(_vm.$parent.$parent.stateData.data.dosage.period_lasting).join() : ''))])]],2)])]):_vm._e(),_c('div',{staticClass:"gh-row-content",staticStyle:{"height":"18px"}},[_c('div',[_c('div',{staticStyle:{"width":"40%"},style:(!_vm.$root._route.params.view ? '' : 'display: flex;')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'label_standard_item_dosage_quantities',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_quantity') + ':',
                            text: _vm.$t('quantity'),
                            label: _vm.$t('quantity'),
                            type: 'radio',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;',
                        },"datainput":{
                            id: _vm.$parent.$parent.type_item + '_dosage_quantities',
                            name: _vm.$parent.$parent.type_item + '[dosage][quantities]'
                        },"feed":_vm.getFeeds.quantities,"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.quantity !== undefined ? _vm.$parent.$parent.stateData.data.dosage.quantity : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('quantity') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.quantity !== undefined ? Object.values(_vm.$parent.$parent.stateData.data.dosage.quantity).join() : ''))])]],2),_c('div',{staticStyle:{"width":"40%"}},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                            type: 'radio',
                            id: _vm.$parent.$parent.type_item + '_dosage_item',
                            label: _vm.$t('item'),
                            title: _vm.$t('item'),
                            text: _vm.$t('select_item') + ':',
                            name: _vm.$parent.$parent.type_item + '[dosage][item]',
                            id_value: 'value',
                            name_label: 'text',
                            child_key: 'children',
                            required: false,
                            checkparentdata: false,
                            style: 'margin-left: 0px;margin-top:2px; width: auto !important;',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        },"selected_input":_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.item !== undefined ? _vm.$parent.$parent.stateData.data.dosage.item : [],"items":_vm.getFeeds.items}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('item') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"33.5%"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.item !== undefined ? _vm.$parent.$parent.stateData.data.dosage.item[0].text : ''))])]],2)]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('associate_to')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: _vm.$parent.$parent.type_item + '_dosage_associate_to',
                value: 1,
                name: _vm.$parent.$parent.type_item + '[dosage][associate_to]',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.dosage !== undefined && _vm.$parent.$parent.stateData.data.dosage.associate_to !== undefined ? _vm.$parent.$parent.stateData.data.dosage.associate_to : '',
            }}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }