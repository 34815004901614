var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{attrs:{"id":"option_to_select_table"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefTableSelected",attrs:{"datablock":{
                    id: 'label_' + _vm.$parent.$parent.type_item + '_table_selected',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_table') + ':',
                    text: _vm.$t('load_table') + '*',
                    label: _vm.$t('load_table'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;',
                },"datainput":{
                    id: _vm.$parent.$parent.type_item + '_table_selected',
                    name: _vm.$parent.$parent.type_item + '[table][selected]'
                },"feed":_vm.getFeeds.tables.feeds,"selected_input":_vm.table},on:{"accept":_vm.getSelectedTable}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('load_table') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.table !== [] ? Object.values(_vm.table).join() : ''))])]],2),(_vm.showStructure)?_c('div',{key:'A' + _vm.structureRefresh,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefTableStructure",attrs:{"datablock":{
                    id: 'label_' + _vm.$parent.$parent.type_item + '_structure_selected',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_columns') + ':',
                    text: _vm.$t('load_columns') + '*',
                    label: _vm.$t('load_columns'),
                    type: 'checkbox',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px; width: 55%;',
                },"datainput":{
                    id: _vm.$parent.$parent.type_item + '_structure_selected',
                    name: _vm.$parent.$parent.type_item + '[table][structure][]'
                },"disabled_input":_vm.structure.disabled,"feed":_vm.getFeeds.structure,"selected_input":_vm.structure.selected},on:{"accept":_vm.getSelectedStructure}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('load_columns') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.structure !== [] ? Object.values(_vm.structure.selected).join() : ''))])]],2):_vm._e(),(_vm.actualData.columns_by_table.id !== undefined)?[_vm._l((_vm.actualData.columns_by_table),function(item,index,number){return _c('div',{key:'B' + number,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('column') + ' ' + (number + 1) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"756px","max-width":"756px"}},[_vm._v(_vm._s(item))]),(_vm.$root._route.params.view !== 'true' && index !== 'id' && index !== 'name')?[_c('GhAction',{staticStyle:{"margin-left":"10px","width":"max-content","white-space":"nowrap"},attrs:{"dataction":{
                        id: 'action_delete_structure_' + index,
                        text: _vm.$t('delete'),
                        icon: require('../../../../../assets/gh_new_dessign/trash.svg')
                    }},on:{"click":function($event){return _vm.removeColumn('columns_by_table', index)}}})]:_vm._e()],2)}),_c('div',{key:'C' + _vm.length + _vm.lengthCol},_vm._l((_vm.actualData.new_columns),function(item,index){return _c('div',{key:'D' + index,staticClass:"gh-row-content",staticStyle:{"height":"20px"}},[_c(_vm.component,{key:'E' + index,ref:'RefLineOfTable' + index,refInFor:true,tag:"component",attrs:{"dataBlock":{item: item, index: index, type: 'table', length: _vm.length}}})],1)}),0),(_vm.$root._route.params.view !== 'true')?[_c('GhAction',{staticStyle:{"margin-left":"160px"},attrs:{"dataction":{
                    id: 'action_add_column',
                    text: _vm.$t('add_column'),
                    icon: require('../../../../../assets/svg/add_plus.svg')
                }},on:{"click":_vm.addColumn}}),_c('br')]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('table_preview') + ':'))])]),_c('div',{staticClass:"gh-row-content"},[_c('GhTableCommon',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"extratable":{
                    id: _vm.$root._route.params.view ? 'view_table_structure_selected' : 'table_structure_selected',
                },"header":_vm.structure.column,"data":_vm.structure.row}})],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }