var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_standard_item',
                title: _vm.$t('standard_item')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_standard_item"}},[_c('div',{staticClass:"gh-content-global"},[(_vm.$root._route.params.id !== null && _vm.$root._route.params.id !== undefined)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.code : ''))])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'standard_item_name',
                                        name: 'standard_item[name]',
                                        style: 'min-width: 200px; width: 100%',
                                        value: _vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.name : '',
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'label_standard_item_group',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_group') + ':',
                                        text: _vm.$t('group'),
                                        label: _vm.$t('group'),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px; width: 55%;',
                                    },"datainput":{
                                        id: 'standard_item_group',
                                        name: 'standard_item[group]'
                                    },"feed":_vm.getFeeds.item_groups,"selected_input":_vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.group : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsStandardItems.data !== undefined ? Object.values(_vm.ConfigurationItemsStandardItems.data.group).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('critical')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                    required: false,
                                    disabled: _vm.$root._route.params.view == 'true' ? true : false,
                                    type: 'checkbox',
                                    id: 'standard_item_critical',
                                    value: 1,
                                    name: 'standard_item[critical]',
                                    selected: _vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.critical : false,
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                        type: 'radio',
                                        id: 'standard_item_options_to_select',
                                        label: _vm.$t('options_to_select') + '*',
                                        title: _vm.$t('options_to_select'),
                                        text: _vm.$t('select_option_to_select') + ':',
                                        name: 'standard_item[options_to_select]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: 'margin-left: 0px;',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    },"selected_input":_vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.options_to_select : [],"items":_vm.getFeeds.options_to_select},on:{"accept":_vm.getTypeSelected}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('options_to_select') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.options_to_select[0].text : ''))])]],2),(_vm.haveOptionToSelect)?[_c(_vm.component,{tag:"component"})]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('allow_pictures')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                    required: false,
                                    disabled: _vm.$root._route.params.view == 'true' ? true : false,
                                    type: 'checkbox',
                                    id: 'standard_item_allow_pictures',
                                    value: 1,
                                    name: 'standard_item[allow_pictures]',
                                    selected: _vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.allow_pictures : false,
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('allow_na')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                                    required: false,
                                    type: 'checkbox',
                                    disabled: _vm.$root._route.params.view == 'true' ? true : false,
                                    id: 'standard_item_allow_na',
                                    value: 1,
                                    name: 'standard_item[allow_na]',
                                    selected: _vm.ConfigurationItemsStandardItems.data !== undefined ? _vm.ConfigurationItemsStandardItems.data.allow_na : false,
                                }}})],1)],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitStandardItem();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }