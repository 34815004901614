import Specialty from "@/view/configuration/variable/views/view_specialty.vue";

const routes = [
    {
        path: '/configuration/variable/speecialty',
        name: 'configuration_variable_new_specialty',
        component: Specialty,
        props: {id: null, showMenu: false, background: true, otherTitle: 'specialty'},
        children: [
            {
                path: '/configuration/variable/speecialty/:id',
                name: 'configuration_variable_edit_specialty',
                component: Specialty,
                props: {id: null, showMenu: false, background: true, otherTitle: 'specialty'},
            },
            {
                path: '/configuration/variable/speecialty/:id/:view',
                name: 'configuration_variable_view_specialty',
                component: Specialty,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'specialty'},
            }
        ],
    },

];

export default routes;