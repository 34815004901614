var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
                showButton: true,
                secondWindow: true,
                showLoader: _vm.haveData ? 'OK' : '',
                text: _vm.$root._route.params.id !== undefined ? _vm.$t('edit_condition') : _vm.$t('add_condition')
            },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'view_condition',
                    title: _vm.$t('condition')
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_condition"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('id') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'condition_code',
                                        name: 'condition[code]',
                                        style: 'width: 300px',
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.code : '',
                                        type: 'text',
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'condition_name',
                                        name: 'condition[name]',
                                        style: 'width: 300px',
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.name : '',
                                        type: 'text',
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 150px; min-width: 150px; text-align: right; margin-right: 11px;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'condition_description',
                                        name: 'condition[description]',
                                        required: false,
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.description : '',
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('base_percentage') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'condition_base_percentage',
                                        name: 'condition[base_percentage]',
                                        style: 'width: 150px',
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.base_percentage : '',
                                        type: 'int',
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('modifier')))])]),_vm._l((_vm.modifier),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{key:_vm.refreshSelector,attrs:{"datablock":{
                                            id: 'label_condition_modifier_genre_' + index,
                                            class: 'T15_b',
                                            subtitle: _vm.$t('select_genre') + ':',
                                            text: _vm.$t('genre'),
                                            label: _vm.$t('genre'),
                                            type: 'radio',
                                            columns: 3,
                                            required: false,
                                            style: 'margin-left: 112px;',
                                            popupStyle: 'min-width: 900px; width: 55%;',
                                        },"datainput":{
                                            id: 'condition_modifier_genre_' + index,
                                            name: 'condition[modifier][' + index + '][genre]'
                                        },"feed":_vm.getFeeds.genre,"selected_input":item.genre !== undefined ? item.genre : []}})],1),_vm._l((item.age),function(subItem,subIndex){return _c('div',{key:subIndex,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('age')))]),_c('GhInputTextField',{attrs:{"datalabel":{
                                            text: _vm.getFeeds.age_conditions.symbols[Object.keys(subItem.age_select_first)[0]] + ' ' + subItem.age_input_first + ' ' + (subItem.age_input_first == 1 ? _vm.$t('year') : _vm.$t('years')) + (subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0 ? ' ' + _vm.$t('and').toLowerCase() + ' ' + _vm.getFeeds.age_conditions.symbols[Object.keys(subItem.age_select_second)[0]]  + ' ' + subItem.age_input_second + ' ' + (subItem.age_input_second == 1 ? _vm.$t('year') : _vm.$t('years')) : ''),
                                            style: 'width: auto; text-align: right;',
                                            class: 'T15'
                                        },"datainput":{
                                            required: true,
                                            id: 'condition_modifier_' + index + '_age_' + subIndex,
                                            name: 'condition[modifier][' + index + '][age][' + subIndex + '][value]',
                                            style: 'width: 150px',
                                            value: subItem.value !== undefined ? subItem.value : '',
                                            type: 'int',
                                        }}}),_c('input',{attrs:{"type":"hidden","name":'condition[modifier][' + index + '][age][' + subIndex + '][age_select_first]'},domProps:{"value":Object.keys(subItem.age_select_first)[0]}}),_c('input',{attrs:{"type":"hidden","name":'condition[modifier][' + index + '][age][' + subIndex + '][age_input_first]'},domProps:{"value":subItem.age_input_first}}),(subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0)?_c('input',{attrs:{"type":"hidden","name":'condition[modifier][' + index + '][age][' + subIndex + '][age_select_second]'},domProps:{"value":Object.keys(subItem.age_select_second)[0]}}):_vm._e(),(subItem.age_select_second !== undefined && subItem.age_select_second.length !== 0)?_c('input',{attrs:{"type":"hidden","name":'condition[modifier][' + index + '][age][' + subIndex + '][age_input_second]'},domProps:{"value":subItem.age_input_second}}):_vm._e()],1)}),_c('div',{staticClass:"gh-row-content"},[_c('GhAction',{staticStyle:{"text-align":"right","width":"175px"},attrs:{"dataction":{
                                            id: 'add_age_' + index,
                                            text: _vm.$t('add_age'),
                                        }},on:{"click":function($event){_vm.Global.openPopUp( 'popup_add_age');_vm.actualIndex = index;}}})],1)],2)}),_c('div',{staticClass:"gh-row-content"},[_c('GhAction',{staticStyle:{"text-align":"right","width":"150px"},attrs:{"dataction":{
                                        id: 'add_modifier',
                                        text: _vm.$t('add_modifier'),
                                    }},on:{"click":function($event){return _vm.modifier.push({age:[]})}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('booking_time')))]),_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('from_hour') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'condition_from_hour',
                                        name: 'condition[from_hour]',
                                        style: 'width: 150px',
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.from_hour : '',
                                        type: 'text',
                                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('to_hour') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'condition_to_hour',
                                        name: 'condition[to_hour]',
                                        style: 'width: 150px',
                                        value: _vm.ConfigurationPriorityConditions.data !== undefined ? _vm.ConfigurationPriorityConditions.data.to_hour : '',
                                        type: 'text',
                                    }}})],1)],2)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept'),
                }},on:{"click":function($event){return _vm.submitCondition();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                id: 'popup_add_age',
                title: _vm.$t('add_age'),
                type: 'add',
                style: 'width: 90%; min-width:920px;',
            },"bodyConfiguration":{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
            },"footerConfiguration":{
                id: '',
                class: '',
                style: '',
                content: ''
            }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('span',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('specify_correct_ages') + ':'))])]),_c('form',{attrs:{"id":"check_popup_age"}},[_c('div',{key:_vm.refreshPopup,staticClass:"gh-content-global",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{ref:"age_select_first",attrs:{"datablock":{
                                    id: 'label_age_select_first',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_age') + ':',
                                    text: _vm.$t('age_is') + '*',
                                    label: _vm.$t('age_is'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left: 10px;',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                },"datainput":{
                                    id: 'age_select_first',
                                    name: 'popup[age_select_first]'
                                },"feed":_vm.getFeeds.age_conditions.labels,"selected_input":[]}}),_c('GhInputTextField',{ref:"age_input_first",attrs:{"datalabel":{
                                    text: _vm.$t('age') + '*',
                                    style: 'width: 100px; min-width: 100px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'age_input_first',
                                    name: 'popup[age_input_first]',
                                    style: 'width: 150px',
                                    value: '',
                                    type: 'int',
                                }}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px","width":"50px","min-width":"50px","text-align":"center"}},[_vm._v(_vm._s(_vm.$t('and').toUpperCase()))]),_c('GhDataSelector',{ref:"age_select_second",attrs:{"datablock":{
                                    id: 'label_age_select_second',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_age') + ':',
                                    text: _vm.$t('age_is'),
                                    label: _vm.$t('age_is'),
                                    type: 'radio',
                                    columns: 3,
                                    required: false,
                                    style: 'margin-left: 10px;',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                },"datainput":{
                                    id: 'age_select_second',
                                    name: 'popup[age_select_second]'
                                },"feed":_vm.getFeeds.age_conditions.labels,"selected_input":[]}}),_c('GhInputTextField',{ref:"age_input_second",attrs:{"datalabel":{
                                    text: _vm.$t('age'),
                                    style: 'width: 100px; min-width: 100px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'age_input_second',
                                    name: 'popup[age_input_second]',
                                    style: 'width: 150px',
                                    value: '',
                                    type: 'int',
                                }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save_flow',
                        text: _vm.$t('accept'),
                    }},on:{"click":_vm.addAge}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_close_flow',
                        text: _vm.$t('close'),
                    }},on:{"click":function($event){_vm.Global.closePopUp( 'popup_add_age');_vm.refreshPopup++;}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }