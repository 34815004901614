import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        section_info: '',
        subVisitsTabActive: '',
        nameVisitsSubTabActive:'',
    },
    mutations: {
        loadVisitsInfo(state, payload) {
            state.section_info = payload;
        },
        loadVisitsSubActiveItem(state, payload) {
            state.subVisitsTabActive = payload
        },
        loadVisitsNameSubActiveItem(state,payload){
            state.nameVisitsSubTabActive = payload
        },
    },
    actions: {
        async getVisitsTabActive(state, item) {
            var valRouter = await router.options.routes.find((i) => i.name == item.call);

            state.commit("loadVisitsSubActiveItem", valRouter.component != '' ? valRouter.component : '');
            state.commit("loadVisitsNameSubActiveItem", valRouter.name != '' ? valRouter.name : '');
        },
        async getVisitsSections(state, item){
            await axios.get(
                API_URL + "visits/sections/" + item.type
            ).then(
                response => {
                    state.commit('loadVisitsInfo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setSchedulePatient(state, item){
            let request = item.id !== undefined && item.type !== undefined ? API_URL + "visits/" + item.type + "/" + item.id : API_URL + "visits/schedule";
            let formData = new FormData();

            formData.append('schedule[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.dispatch('getVisitsSections');
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};