import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default {
    state: {
        records_data: '',
        data: [],
        records:{
            deleted:[],
            added:[],
        }
    },
    mutations: {
        loadTableConfigurationSupportHoliday(state,payload){
            state.records_data = payload
        },
        loadDataConfigurationSupportHoliday(state, payload){
            state.data = payload;
        },
        loadTemporalHolidayData(state, payload){
            state.data.push(payload);
            state.records.added.push(payload);
        },
    },
    actions: {
        async getConfigurationSupportHoliday(state){
            await axios.get(
                API_URL + "support/holidays"
            ).then(
                response => {
                    state.commit('loadTableConfigurationSupportHoliday', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationSupportHoliday(state, item){
            await axios.get(
                API_URL + "support/holidays/" + item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationSupportHoliday', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        setTemporalHolidayData(state,items){
            var params = {
                'id': items.tmp_holidays.id,
                'description': items.tmp_holidays.description,
                'day': items.tmp_holidays.day,
                'month': items.tmp_holidays.month,
                'year': items.tmp_holidays.year,
                'date': items.tmp_holidays.date,
            }
            state.commit("loadTemporalHolidayData", params);
        },
        cleanTemporalHolidayData(state){
            state.state.records.added = [];
            state.state.records.deleted = [];
            state.state.data = [];
        },
        removeTemporaryHoliday(state,items){
            var index = state.state.records.added.findIndex(holiday => holiday.id == items.id);
            if(index != '-1'){
                state.state.records.added.splice(index, 1);
            }else{
                var params = {
                    'id': items.id
                };
                state.state.records.deleted.push(params);
            }

            var indexTable = state.state.data.findIndex(data => data.id == items.id);
            state.state.data.splice(indexTable, 1);
        },
        async setDataConfigurationSupportHoliday(state, item){
            let requestLogin = API_URL + "support/holidays/"+item.id;
            let formData = new FormData();

            formData.append('holiday[id]', item.id);

            if(state.state.records.added.length !== 0) {
                for (let i = 0; i < state.state.records.added.length; i++) {
                    formData.append('holiday[add][' + i + '][description]', state.state.records.added[i].description);
                    formData.append('holiday[add][' + i + '][day]', state.state.records.added[i].day);
                    formData.append('holiday[add][' + i + '][month]', state.state.records.added[i].month);
                    formData.append('holiday[add][' + i + '][year]', state.state.records.added[i].year);
                    formData.append('holiday[add][' + i + '][date]', state.state.records.added[i].date);
                }
            }

            if(state.state.records.deleted.length!==0) {
                for (let i = 0; i < state.state.records.deleted.length; i++) {
                    formData.append('holiday[delete][' + i + ']', state.state.records.deleted[i].id);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('cleanTemporalHolidayData');
                        this.dispatch('getConfigurationSupportHoliday')
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}