<template>
    <div class="gh-content-global" v-if="haveData" style="margin-left: 5px;">
        <div class="gh-row-content">
            <GhChecks
                :datainput="{
                    id: 'register_' + $root._route.params.type + '_privacy_data_forms_accept_conditions',
                    value: 1,
                    type: 'checkbox',
                    name: 'register[' + $root._route.params.type + '][privacy_data_forms][politic_privacity]',
                    style: 'cursor:pointer; margin-right: 10px;',
                    selected: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.privacy_data_forms !== undefined ? DataClinicsProfessionals.data.privacy_data_forms.politic_privacity : false,
                    required: true,
                    label_required: $t('politic_privacity_2')
                }"
                :datalabel="{
                    style: '',
                    class: 'T15 input_label',
                    id: 'label_register_' + $root._route.params.type + '_privacy_data_forms_accept_conditions',
                    text: ''
                }"
            />

            <i18n path="politic_privacy" tag="p" class="T15" style="margin-top: 2px;">
                <template v-slot:terms>
                    <a href="" target="_blank" class="T15_b link">{{$t('terms')}}</a>
                </template>
            </i18n>
        </div>
        <div class="gh-row-content">
            <GhChecks
                :datainput="{
                    id: 'register_' + $root._route.params.type + '_privacy_data_forms_accept_conditions',
                    value: 1,
                    type: 'checkbox',
                    name: 'register[' + $root._route.params.type + '][privacy_data_forms][legal_advise]',
                    style: 'cursor:pointer; margin-right: 10px;',
                    selected: $root._route.params.type == 'professional-second-phase' && DataClinicsProfessionals.data !== undefined && DataClinicsProfessionals.data.privacy_data_forms !== undefined ? DataClinicsProfessionals.data.privacy_data_forms.legal_advise : false,
                    required: true,
                    label_required: $t('legal_advice_2')
                }"
                :datalabel="{
                    style: '',
                    class: 'T15 input_label',
                    id: 'label_register_' + $root._route.params.type + '_privacy_data_forms_accept_conditions',
                    text: ''
                }"
            />

            <i18n path="legal_advice" tag="p" class="T15" style="margin-top: 2px;">
                <template v-slot:terms>
                    <a href="" target="_blank" class="T15_b link">{{$t('terms')}}</a>
                </template>
            </i18n>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import {mapState} from "vuex";

    export default {
        name: "privacy_data_forms",
        components: {
            GhChecks
        },
        computed: {
            ...mapState(['DataClinicsProfessionals']),
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount() {
            this.haveData = true;
        }
    }
</script>