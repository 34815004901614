<template>
    <GhTableCommon v-if="haveData" style="margin-top: 10px;"
        :extratable="{
            id: 'follow_up_visits_patients_',
        }"
        :header="header"
        :data="VisitsFollowUpVisits.records_data.length == 0 ? [] : VisitsFollowUpVisits.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('view_patient', {id: itemProp.id, view: true}),'patient')"> {{itemProp[labelProp]}}</a>
        </template>
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'action_edit_follow_up_visits_patient_' + itemProp.id,
                    text: $t('schedule'),
                    icon: require('../../../assets/svg/calendar2.svg'),
                }"
                @click="Global.windowOpen(Global.openSecondWindow('edit_visit_schedule', {id: itemProp.id, type: 'follow-up'}),'schedule')"
            />
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_follow_up_visits_patients",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['VisitsFollowUpVisits'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'ohip', field: 'ohip', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 15%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 20%;'},
                    {text: 'md', field: 'md', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 39%;'},
                    {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: 'type_of_visit', field: 'type_of_visit', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            this.$store.dispatch('getVisitsFollowUpVisitsList', {type: this.$store.state.Visits.nameVisitsSubTabActive.split('_')[1]}, {root: true});
            this.haveData = true;
        }
    }
</script>