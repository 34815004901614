<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationVariable.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_variable_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationVariable.section_info[label['type']]['second_text']),
                    num_results: ConfigurationVariable.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import list_departments from "@/view/configuration/variable/lists/list_departments.vue";
    import list_specialty from "@/view/configuration/variable/lists/list_specialty.vue";
    import list_equipments from "@/view/configuration/variable/lists/list_equipments.vue";
    import list_equipment_maintenance from "@/view/configuration/variable/lists/list_equipment_maintenance.vue";
    import list_type_equipment from "@/view/configuration/variable/lists/list_type_equipment.vue";
    import list_professional_types from "@/view/configuration/variable/lists/list_professional_types.vue";

    export default {
        name: "sections_variable",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigurationVariable']),
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 1, name: 'departments', component: list_departments, type: 'departments'},
                    {id: 2, name: 'specialties', component: list_specialty, type: 'specialties'},
                    {id: 3, name: 'equipments', component: list_equipments, type: 'equipments'},
                    {id: 4, name: 'equipment_maintenance', component: list_equipment_maintenance, type: 'equipment_maintenance'},
                    {id: 5, name: 'equipments_type', component: list_type_equipment, type: 'equipments_type'},
                    {id: 6, name: 'professional_types', component: list_professional_types, type: 'professional_types'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationVariableSections','',{root:true});
            this.haveData = true;
        }
    }
</script>