import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        records_data: [],
        idBack: ''
    },
    mutations: {
        loadEnvironments(state, payload) {
            state.records_data = payload;
        },
        loadIdBack(state, payload) {
            state.idBack = payload;
        }
    },
    actions: {
        async getEnvironmentsByUser(state){
            await axios.get(
                API_URL + 'environments'
            ).then(
                response => {
                    state.commit('loadEnvironments', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEnvironmentsActionByUser(state, item){
            await axios.get(
                API_URL + 'environments/' + item.id + '/actions'
            ).then(
                response => {
                    state.commit('loadEnvironments', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setEnvironment(state, item){
            let request = API_URL + 'environments'
            let formData = new FormData();

            formData.append('environment[id]', item.id);

            await axios({
                method: 'POST',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        // await this.dispatch('getTabsWithPerm');
                        router.push({name: 'environment_id', params: {id: item.id}});

                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        async setEnvironmentAction(state, item){
            let request = API_URL + 'environments/' + item.id + "/actions"
            let formData = new FormData();

            formData.append('environment[id_action]', item.id_action);

            await axios({
                method: 'POST',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        state.commit('loadIdBack', item.id);
                        await this.dispatch('getTabsWithPerm');
                        router.push({name: router.options.GhTabs[0].value});
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        async backEnvironment(){
            let request = API_URL + 'auth/back/environments';
            let formData = new FormData();
            await axios({
                method: 'POST',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        router.push({name: 'environment'});
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        async backEnvironmentAction(state, item){
            let request = API_URL + 'auth/back/actions';
            let formData = new FormData();
            await axios({
                method: 'POST',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        router.push({name: 'environment_id', params: {id: item.id}});
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
    }
};