import ConfigHoliday from "@/view/configuration/support/views/view_holiday.vue";

const routes = [
    {
        path: '/configuration/support/holiday',
        name: 'configuration_support_new_holiday',
        component: ConfigHoliday,
        props: {id: null, showMenu: false, background: true, otherTitle: 'holiday'},
        children: [
            {
                path: '/configuration/support/holiday/:id',
                name: 'configuration_support_edit_holiday',
                component: ConfigHoliday,
                props: {id: null, showMenu: false, background: true, otherTitle: 'holiday'},
            },
            {
                path: '/configuration/support/holiday/:id/:view',
                name: 'configuration_support_view_holiday',
                component: ConfigHoliday,
                props: {id: null, showMenu: false, background: true, otherTitle: 'holiday'},
            },
        ]
    }
];
export default routes;