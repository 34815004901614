<template>
    <div class="float_window" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Incidence.section_info[label['type']].show"
                :datablock="{
                    id: 'incidences_'+label.id,
                    title: $t(label['name']),
                    second_title: $t(Incidence.section_info[label['type']]['second_text']),
                    num_results: Incidence.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>

        <component v-bind:is="popup_reasign" :data="{id: reasign_id, type: reasign_type, name: reasign_name, surname: reasign_surname}"/>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import list_patient_not_presented from "@/view/incidences/lists/list_patient_not_presented.vue";
    import list_non_active_professionals from "@/view/incidences/lists/list_non_active_professionals.vue";

    import popup_reasign from "@/view/incidences/templates/popup_reasign.vue";

    import {mapState} from "vuex";

    export default{
        name: "section_incidences",
        components: {
            GhDropSelector,
        },
        computed : {
            ...mapState(['Incidence'])
        },
        data(){
            return {
                haveData: false,
                data: [
                    {id: 'patient_not_presented', name: 'patient_not_presented', component: list_patient_not_presented, type: 'patient_not_presented'},
                    {id: 'non_active_professionals', name: 'non_active_professionals', component: list_non_active_professionals, type: 'non_active_professionals'},
                ],
                popup_reasign: popup_reasign,
                reasign_id: '',
                reasign_name: '',
                reasign_surname: '',
                reasign_type: ''
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getIncidenceSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>