import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
        records_data: '',
    },
    mutations: {
        loadTableConfigurationItemsStandardItems(state, payload) {
            state.records_data = payload;
        },
        loadDataConfigurationItemsStandardItems(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getConfigurationItemsStandardItems(state){
            await axios.get(
                API_URL + "standard-items"
            ).then(
                response => {
                    state.commit('loadTableConfigurationItemsStandardItems', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataConfigurationItemsStandardItem(state, item){
            await axios.get(
                API_URL + "standard-items/"+ item.id
            ).then(
                response => {
                    state.commit('loadDataConfigurationItemsStandardItems', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataConfigurationItemsStandardItem(state, item){
            let request = item.id !== undefined ? API_URL + "standard-items/" + item.id : API_URL + "standard-items";
            let formData = new FormData();

            formData.append('standard_item[id]', item.id);

            item.formData.forEach((items) => {
                formData.append(items[0], items[1]);
            });

            if(item.table.length !== 0){
                item.table.forEach((i, key) => {
                    formData.append('standard_item[table][preview][' + key + '][text]', i.text);
                    formData.append('standard_item[table][preview][' + key + '][field]', i.field);
                    formData.append('standard_item[table][preview][' + key + '][style]', i.style);
                });
            }

            await axios({
                method: 'post',
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        window.opener.UpdatedSelect(Math.random(), item.id !== undefined ? 'edit' : 'add');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeConfigurationItemsStandardItem(state, item){
            let requestLogin = API_URL + "standard-items/" + item.id ;

            await axios({
                method: "delete",
                url: requestLogin,
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getConfigurationItemsStandardItems');
                        state.rootState.ConfigurationItems.section_info.standard_items.result--;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};