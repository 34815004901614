import SupportAssignment from "@/view/configuration/support/views/view_assignment.vue";
import SearchByUser from "@/view/search/view/view_search_by_user.vue";

const routes = [
    {
        path: '/configuration/support/assignment',
        name: 'configuration_support_new_assignment',
        component: SupportAssignment,
        props: {id: null, showMenu: false, background: true, otherTitle: 'assignment'},
        children: [
            {
                path: '/configuration/support/assignment/:id',
                name: 'configuration_support_edit_assignment',
                component: SupportAssignment,
                props: {id: null, showMenu: false, background: true, otherTitle: 'assignment'},
            },
            {
                path: '/configuration/support/assignment/:id/:view',
                name: 'configuration_support_view_assignment',
                component: SupportAssignment,
                props: {id: null, showMenu: false, background: true, otherTitle: 'assignment'},
            },
        ]
    },
    {
        path: '/view_assignment',
        name: 'view_assignment',
        component: SearchByUser,
        props: {id: null, showMenu: true, otherTitle: 'assignment'}
    },
];
export default routes;
