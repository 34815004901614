<template>
    <div id="option_to_select_information_matrix" v-if="haveData">
        <div v-for="(item, index) in actualData" :key="index + refreshData">
            <component v-bind:is="includeLineInformationMatrix" :dataBlock="{item: item, index: index, type: 'information_matrix'}" :key="index + refreshData" :ref="'RefLineInformationMatrix' + index"/>
        </div>
        <template v-if="$root._route.params.view !== 'true'">
            <div style="margin-bottom: 10px;">
                <GhAction
                    style="margin-left: 160px;"
                    :dataction="{
                        id: 'action_add_variable',
                        text: $t('add_variable'),
                        icon: require('../../../../../assets/svg/add_plus.svg')
                    }"
                    @click="addVariable"
                />
                <br>
            </div>
        </template>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";

    import includeLineInformationMatrix from "@/view/configuration/items/views/partials/includes/include_line_of_information_matrix.vue";

    export default{
        name: 'view_information_matrix',
        components: {
            GhAction
        },
        data(){
            return {
                actualData: [],
                refreshData: 0,
                newData: {
                    'variable': '',
                    'min': '',
                    'max': ''
                },
                includeLineInformationMatrix: includeLineInformationMatrix,
                haveData: false
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.stateData.data !== undefined && this.$parent.$parent.stateData.data.information_matrix !== undefined){
                this.addVariable(this.$parent.$parent.stateData.data);
            }else{
                this.addVariable();
            }

            this.haveData = true;
        },
        methods: {
            addVariable(data){
                var params = new Array();
                if(data !== undefined && data.information_matrix !== undefined){
                    params = data.information_matrix;
                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }
                }else{
                    this.actualData.forEach((item, key) => {
                        var array = {
                            'variable': this.$refs['RefLineInformationMatrix' + key][0].$children[0].datainput.value,
                            'min': this.$refs['RefLineInformationMatrix' + key][0].$children[1].datainput.value,
                            'max': this.$refs['RefLineInformationMatrix' + key][0].$children[2].datainput.value
                        };
                        params.push(array);
                    });

                    this.actualData = [];
                    if(params[0] !== undefined) {
                        params.forEach((item) => {
                            this.actualData.push(item);
                        });
                    }

                    this.actualData.push(this.newData);
                }
            },
            removeVariable(index){
                var params = new Array();
                this.actualData.forEach((item, key) => {
                    if(key !== index) {
                        var array = {
                            'variable': this.$refs['RefLineInformationMatrix' + key][0].$children[0].datainput.value,
                            'min': this.$refs['RefLineInformationMatrix' + key][0].$children[1].datainput.value,
                            'max': this.$refs['RefLineInformationMatrix' + key][0].$children[2].datainput.value,
                        };
                        params.push(array);
                    }
                });

                this.actualData = [];
                if(params[0] !== undefined) {
                    params.forEach((item) => {
                        this.actualData.push(item);
                    });
                }

                this.refreshData++;
            }
        }
    }
</script>