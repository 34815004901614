<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t($root._route.matched[0].props.default.otherTitle)
            }"
        />
        <form id="form_schedule_surgery">
            <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_schedule_surgery',
                    title: $t('schedule_surgery')
                }"
            >
                <template v-slot:content>
                    <div class="section_line" style="margin-bottom: 10px; margin-top: 10px; background-color: #ff00009e !important;">
                        <div class="T15_b" style="padding-left:13px; color: white !important;">{{WarningsSurgery.warning_surgery_data.label}}</div>
                    </div>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('aviable_short_notice_cancelation_list') + '?'}}</label>
                            <GhChecks
                                :datalabel="{
                                    text: $t('yes'),
                                    style: 'margin-right: 50px;'
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_available_short_notice',
                                    value: 1,
                                    type: 'radio',
                                    name: 'schedule_surgical[available_short_notice]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }"
                                ref="RefShortNotice1"
                                @click="selectShortNotice('RefShortNotice1')"
                            />
                            <GhChecks
                                :datalabel="{
                                    text: $t('no'),
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_available_short_notice',
                                    value: 0,
                                    type: 'radio',
                                    name: 'schedule_surgical[available_short_notice]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }"
                                ref="RefShortNotice2"
                                @click="selectShortNotice('RefShortNotice2')"
                            />
                        </div>
                        <div class="gh-row-content" v-if="showNoticePeriod">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('notice_period') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'schedule_surgical_notice_period',
                                    name: 'schedule_surgical[notice_period]',
                                    style: 'width: 50px',
                                    value: '',
                                    type: 'text',
                                    placeholder: 'Days'
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_schedule_surgical_other_staff',
                                    class: 'T15_b',
                                    subtitle: $t('select_different_surgeon') + ':',
                                    text: $t('willing_accept_different_surgeon') + '?',
                                    label: $t('willing_accept_different_surgeon'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: false,
                                    style: '',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_other_staff',
                                    name: 'schedule_surgical[other_staff]'
                                }"
                                :feed="getFeeds.doctors"
                                :selected_input="[]"
                                @accept="loadDifferentSurgeon"
                                ref="differentSurgeon"
                            />
                        </div>
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('able_go_other_hospital') + '?'}}</label>
                            <GhChecks
                                :datalabel="{
                                    text: $t('yes'),
                                    style: 'margin-right: 50px;'
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_other_hospital',
                                    value: 1,
                                    type: 'radio',
                                    name: 'schedule_surgical[other_hospital]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }"
                                ref="RefOtherHospital1"
                                @click="selectOtherHospital('RefOtherHospital1')"
                            />
                            <GhChecks
                                :datalabel="{
                                    text: $t('no'),
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_other_hospital',
                                    value: 0,
                                    type: 'radio',
                                    name: 'schedule_surgical[other_hospital]',
                                    style: 'cursor:pointer; ',
                                    selected: ''
                                }"
                                ref="RefOtherHospital2"
                                @click="selectOtherHospital('RefOtherHospital2')"
                            />
                        </div>
                        <div class="gh-row-content" v-if="showOtherHospital">
                            <!-- PENDING TO DO SEARCH WINDOW -->
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'getFeeds',
                                    imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                    id: 'schedule_surgical_other_hospital',
                                    text: $t('other_hostpital') + '*',
                                    textlocation: $t('clinic_search'),
                                    actionresult: 'loadResultSearchClinics',
                                    nameResult: 'schedule_surgical[other_hospital_id]',
                                    supportPermision: false,
                                    required: true
                                }"
                                :mainSelector="{
                                    name: 'Formsearchclinic',
                                    formId: 'search',
                                    actionsearch: 'getSearchClinics'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: headersummary,
                                    selected: []
                                }"
                            />
                        </div>
                        <div class="gh-row-content" v-if="showOtherHospital">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_schedule_surgical_other_doctor',
                                    class: 'T15_b',
                                    subtitle: $t('select_other_doctor') + ':',
                                    text: $t('other_doctor') + '*',
                                    label: $t('other_doctor'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px; width: 55%;',
                                }"
                                :datainput="{
                                    id: 'schedule_surgical_other_doctor',
                                    name: 'schedule_surgical[other_doctor]'
                                }"
                                :feed="getFeeds.doctors"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-content-global" v-if="showOptimalSchedule2" :key="refreshToCalendar">
                            <!-- INIT BLOCK OPTIMAL SCHEDULE 2 -->
                            <div v-for="(item,index) in WarningsSurgery.other_surgeon" :key="'diff_surgeon-'+index">
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 43%; text-align: right;">{{$t(item.type) + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 100px;">{{item.doctor}}</label>
                                    <label class="gh_text_field-label T15_b">{{item.date}}</label>
                                    <GhAction style="margin-top: -2px; margin-left: 10px;"
                                        :dataction="{
                                            id: 'action_details_' + item.id,
                                            text: $t('details'),
                                            icon: require('../../../../assets/svg/add_plus.svg'),
                                        }"
                                        @click="openDetails(item.id)"
                                    />
                                    <template v-if="!showScheduleSelect1[item.id]">
                                        <GhAction style="margin-top: -2px;"
                                            :dataction="{
                                                id: 'action_select_' + item.id,
                                                text: $t('select')
                                            }"
                                            ref="RefScheduleSelect1"
                                            @click="checkSchedule(item.id)"
                                        />
                                    </template>
                                    <template v-else>
                                        <GhAction style="margin-top: -2px; margin-left: 10px;"
                                            :dataction="{
                                                id: 'action_select_' + item.id,
                                                text: '',
                                                icon: require('../../../../assets/svg/tick.svg'),
                                            }"
                                            @click="unCheckSchedule(item.id)"
                                        />
                                    </template>
                                </div>
                                <template v-if="showScheduleSelect1[item.id]">
                                    <GhScheduleCalendar style="margin-top: 15px; margin-bottom: 25px;"
                                        :dataBlock="{
                                            id: 'schedule_select_' + item.id,
                                            backgroundColorNumber: '#ffffff',
                                            widthHour: widthHour,
                                            colors: {
                                                0:'rgb(156, 178, 198, 0.7)',
                                                1:'rgb(99, 204, 110, 0.7)',
                                                2:'rgb(253, 245, 121, 0.7)',
                                                3:'rgb(255, 103, 103, 0.7)',
                                                4:'#0000009e'
                                            }
                                        }"
                                        :data="Surgery.calendar[item.id]"
                                        @prevM="toPrev(Surgery.calendar[item.id]['prevMonth']);actualMDID = item.id"
                                        @days="toDays"
                                        @nextM="toNext(Surgery.calendar[item.id]['nextMonth']);actualMDID = item.id"
                                        ref="RefSchedule"
                                    />
                                </template>
                            </div>
                            <input type="hidden" name="schedule_surgical[different_surgeon_selected]" :value="different_surgeon_selected">
                            <!-- END BLOCK OPTIMAL SCHEDULE 2 -->
                        </div>
                    </div>
                    <div class="centered" style="padding-bottom: 10px;" v-if="showOptimalSchedule2 && showBtnOptimalSchedule">
                        <GhButton
                            :datainput="{
                                id: 'btn_optimal_schedule',
                                text: $t('optimal_schedule'),
                            }"
                            @click="checkOptimalSchedule, openDetails('details')"
                        />
                    </div>
                    <div class="gh-content-global" v-if="showOptimalSchedule2 && showBtnOptimalSchedule">
                        <div class="gh-row-content centered">
                            <label class="gh_text_field-label T15_b">{{'(' + $t('only_information_purposes') + ')'}}</label>
                        </div>
                        <br>
                        <div class="gh-row-content centered">
                            <label class="gh_text_field-label T15_b">{{$t('note_in_schedule_surgery')}}</label>
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>

            <div class="centered" style="margin-top: 10px; margin-bottom: 10px;">
                <GhButton
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submit()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>

            <GhPopUp v-show="false"
                :generalConfiguration="{
                    id: 'detail_popup',
                    title: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'Optimal schedule' : $t('Details'),
                    type: 'warning',
                    style: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'width: 98%; min-width: 430px' : 'width: 35%; min-width: 430px'
                }"
                :bodyConfiguration="{
                    id: '',
                    class: '',
                    style: 'padding:2px;',
                    text: this.type && this.type !== 'type1' && this.type !== 'type2' ? 'Surgeries to re-scheduled for an optimal schedule' : '',
                }"
                :footerConfiguration="{
                    id: '',
                    class: '',
                    style: '',
                    content: ''
                }"
            >
                <template v-slot:body>
                    <div style="overflow: auto; max-height: 800px; width: 100%; margin-right: 40px;">
                        <template v-if="type && type !== 'type1' && type !== 'type2'">
                            <div class="gh-content-global">
                            <GhTableCommon style="margin-top: -10px;"
                                :extratable="{
                                    id: 'surgery_instruments_',
                                }"
                                :header="header_details"
                                :data="WarningsSurgery.optimal_schedule"
                            />
                            </div>
                        </template>
                        <template v-else>
                            <div class="gh-content-global">
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('Surgery') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{type == 'type1' ? $t('Tonsillectomy and Adenoidectomy') : $t('Tonsillectomy and Adenoidectomy')}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('MD') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{type == 'type1' ? $t('Dr.Nguyen') : $t('Dr.Manoukian')}}</label>
                                    <label class="gh_text_field-label T15" style="width: 150px; min-width: 150px; text-align: right;">{{$t('OR #') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{type == 'type1' ? 7 : 11}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-left: 20px;">{{$t('Date') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{type == 'type1' ? '15/11/2023' : '15/12/2023'}}</label>
                                    <label class="gh_text_field-label T15" style="width: 127px; min-width: 127px; text-align: right;">{{'Time' + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{type == 'type1' ? '11:00' : '11:00'}}</label>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-slot:footer>
                    <GhButton
                        :datainput="{
                            id: 'button_accept',
                            text: $t('accept'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="Global.closePopUp('detail_popup');"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_close',
                            text: $t('close'),
                        }"
                        @click="Global.closePopUp('detail_popup');"
                    />
                </template>
            </GhPopUp>
        </div>
        </form>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhScheduleCalendar from "fe-gh-schedule-calendar-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: 'view_schedule_surgery',
        components: {
            GhTableCommon,
            GhPopUp,
            GhScheduleCalendar,
            GhAction,
            GhButton,
            GhSearchWindow,
            GhDataSelector,
            GhInputTextField,
            GhLocation,
            GhNoDropSelector,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds', 'WarningsSurgery', 'Surgery'])
        },
        data(){
            return {
                haveData: false,
                showNoticePeriod: false,
                showOtherHospital: false,
                showOptimalSchedule: false,
                showScheduleSelect1: [],
                showOptimalSchedule2: false,
                showBtnOptimalSchedule: false,
                actualMDID: 0,
                different_surgeon_selected: '',
                widthHour: 16,
                refreshToCalendar: 0,
                type: false,
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'radio', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'Clinic name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 60px; width: 30%;'},
                    {text: 'Address', field: 'address', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                    {text: 'Average waiting time', field: 'average', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 20%;'},
                ],
                headersummary: {
                    0: 'name'
                },
                header_details: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px; max-width: 16px;'},
                    {text: 'OHIP', field: 'ohip', sorting: true, typeSort: 'string', style: 'max-width: 60px; width: 15%;'},
                    {text: 'Name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 20%;'},
                    {text: 'Surname', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 20%;'},
                    {text: 'Surgery', field: 'surgery', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 15%;'},
                    {text: 'Initial date', field: 'initial_date', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 15%;'},
                    {text: 'Modified date', field: 'modified_date', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 15%;'},
                ],
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getFeedDoctors', '', {root:true});
            await this.$store.dispatch('getWarningSurgeryData', {id: this.$root._route.params.id}, {root:true});
            this.haveData = true;
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {
            async loadDifferentSurgeon(){
                var ids = Object.keys(this.$refs.differentSurgeon.checkdata);

                this.showScheduleSelect1 = [];
                if(ids.length != 0){
                    await this.$store.dispatch('getDifferentSurgeon', {ids: ids}, {root:true});
                    await this.$store.dispatch('getOptimalSchedule', {ids: ids}, {root:true});
                    this.showOptimalSchedule2 = this.WarningsSurgery.other_surgeon.length != 0 ? true : false;
                    this.showBtnOptimalSchedule = this.WarningsSurgery.optimal_schedule.length != 0 ? true : false;
                    if(this.showOptimalSchedule2){
                        for(var i=0;i<this.WarningsSurgery.other_surgeon.length;i++){
                            this.showScheduleSelect1[this.WarningsSurgery.other_surgeon[i].id] = false;
                        }
                    }else{
                        this.showScheduleSelect1 = [];
                    }
                }else{
                    this.WarningsSurgery.other_surgeon = [];
                    this.WarningsSurgery.optimal_schedule = [];
                    this.showScheduleSelect1 = [];
                    this.showOptimalSchedule2 = false;
                    this.showBtnOptimalSchedule = false;
                }
            },
            selectShortNotice(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }

                if(value == 'RefShortNotice1'){
                    this.showNoticePeriod = true;
                }else{
                    this.showNoticePeriod = false;
                }
            },
            selectOtherHospital(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }

                if(value == 'RefOtherHospital1'){
                    this.showOtherHospital = true;
                }else{
                    this.showOtherHospital = false;
                }
            },
            checkOptimalSchedule(){
                this.showOptimalSchedule = true;
            },
            async checkSchedule(id){
                await this.$store.dispatch('getCalendarScheduleSurgery', {id: id}, {root: true});
                for(var i=0;i<this.showScheduleSelect1.length;i++){
                    this.showScheduleSelect1[i] = i == id ? true : false;
                }
                this.different_surgeon_selected = id;
                this.refreshToCalendar++;

                var that = this;
                setTimeout(function () {
                    that.resizeSchedule();
                }, 150);
            },
            unCheckSchedule(id){
                this.different_surgeon_selected = '';
                this.showScheduleSelect1[id] = false;
                this.refreshToCalendar++;
            },
            myEventHandler(e) {
                if(e.type == 'resize' && this.$refs.RefSchedule !== undefined){
                    this.resizeSchedule();
                }
            },
            resizeSchedule(){
                var fullWidth = this.$refs.RefSchedule[0].$el.clientWidth - 250;

                var newWidth = fullWidth / this.$refs.RefSchedule[0].$el.children[0].children[1].children.length;

                this.widthHour = newWidth - 8;

                this.$refs.RefSchedule[0].$el.children[0].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule[0].$el.children[0].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule[0].$el.children[0].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule[0].$el.children[0].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule[0].$el.children[0].children[2].style.maxWidth = '100px';
                this.$refs.RefSchedule[0].$el.children[1].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule[0].$el.children[1].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule[0].$el.children[1].children[2].style.minWidth = '106px';
                this.$refs.RefSchedule[0].$el.children[1].children[2].style.maxWidth = '106px';
                this.$refs.RefSchedule[0].$el.children[2].children[0].style.minWidth = '156px';
                this.$refs.RefSchedule[0].$el.children[2].children[0].style.maxWidth = '156px';
                this.$refs.RefSchedule[0].$el.children[2].children[1].style.minWidth = fullWidth + 'px';
                this.$refs.RefSchedule[0].$el.children[2].children[1].style.maxWidth = fullWidth + 'px';
                this.$refs.RefSchedule[0].$el.children[3].children[0].style.minWidth = '150px';
                this.$refs.RefSchedule[0].$el.children[3].children[0].style.paddingRight = '7px';
                this.$refs.RefSchedule[0].$el.children[3].children[0].style.maxWidth = '150px';
                this.$refs.RefSchedule[0].$el.children[3].children[2].style.minWidth = '100px';
                this.$refs.RefSchedule[0].$el.children[3].children[2].style.maxWidth = '100px';

                for (var i = 0; i < this.$refs.RefSchedule[0].$el.children[0].children[1].children.length; i++){
                    for(var x = 0; x < this.$refs.RefSchedule[0].$el.children[0].children[1].children[i].children.length; x++){
                        this.$refs.RefSchedule[0].$el.children[0].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule[0].$el.children[0].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule[0].$el.children[3].children[1].children[i].children[x].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule[0].$el.children[3].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule[0].$el.children[1].children[1].children[i].children[0].style.width = (newWidth - 8) + 'px';
                        this.$refs.RefSchedule[0].$el.children[1].children[1].children[i].style.paddingRight = 3 + 'px';
                        this.$refs.RefSchedule[0].$el.children[1].children[1].children[i].children[0].setAttribute('style', this.$refs.RefSchedule[0].$el.children[1].children[1].children[i].children[0].style.cssText + 'font-size: 11px !important');
                    }
                }
            },
            async toPrev(item){
                console.log(item);
                await this.$store.dispatch('updateCalendarProfessional', {id: this.actualMDID, month: item.id, no_lather_than: '', start_today: true}, {root: true});
                this.refreshSchedule++;
                var that = this;
                setTimeout(function() {
                    that.resizeSchedule();
                },0);
            },
            toDays(item){
                this.Global.windowOpen(this.Global.openSecondWindow('view_professional', {id_professional: this.actualMDID, name: this.$t('md'), date: item.day + '/' + item.month + '/' + item.year, from: 'schedule'}),'professional_data');
            },
            async toNext(item){
                await this.$store.dispatch('updateCalendarProfessional', {id: this.actualMDID, month: item.id, no_lather_than: '', start_today: true}, {root: true});
                this.refreshSchedule++;
                var that = this;
                setTimeout(function() {
                    that.resizeSchedule();
                },0);
            },
            openDetails(type){
                this.type = type === 'details' ? true : 'type' + type;

                this.Global.openPopUp('detail_popup');
            },
            async submit(){
                if(this.Global.checkRequired('form_schedule_surgery') === 'OK') {
                    const formData = new FormData(document.getElementById('form_schedule_surgery'));
                    await this.$store.dispatch('setScheduleSurgery', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                    }, {root: true});
                    this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    #label_schedule_surgical_other_staff_selector_label, #label_schedule_surgical_other_doctor_selector_label{
        width: 150px;
        text-align: end;
    }
</style>