<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="ConfigurationConsentForms.section_info[label['type']].show"
                :datablock="{
                    id: 'configuration_consent_forms_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(ConfigurationConsentForms.section_info[label['type']]['second_text']),
                    num_results: ConfigurationConsentForms.section_info[label['type']]['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import list_active from "@/view/configuration/consent_forms/list/list_active.vue";
    import list_pending from "@/view/configuration/consent_forms/list/list_pending.vue";
    import list_deleted from "@/view/configuration/consent_forms/list/list_deleted.vue";

    import {mapState} from "vuex";

    export default {
        name: "sections_consent_forms",
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['ConfigurationConsentForms']),
        },
        data(){
            return {
                view: true,
                haveData: false,
                data: [
                    {id: 1, name: this.$t('active'), component: list_active, type: 'active'},
                    {id: 2, name: this.$t('pending'), component: list_pending, type: 'pending'},
                    {id: 3, name: this.$t('deleted'), component: list_deleted, type: 'deleted'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationConsentFormsSections','',{root:true});
            this.haveData = true;
        },
    }
</script>
