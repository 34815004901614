var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"option_to_select_free_text"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('description') + '*',
                    style: 'width: 150px; min-width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: _vm.$parent.$parent.type_item + '_free_text_name',
                    name: _vm.$parent.$parent.type_item + '[free_text][description]',
                    style: 'min-width: 300px; width: 300px',
                    value: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.free_text !== undefined ? _vm.$parent.$parent.stateData.data.free_text.description : '',
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"210px","width":"210px"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.free_text !== undefined ? _vm.$parent.$parent.stateData.data.free_text.description : ''))])],_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('associate_to')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: _vm.$parent.$parent.type_item + '_free_text_associate_to',
                value: 1,
                name: _vm.$parent.$parent.type_item + '[free_text][associate_to]',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.free_text !== undefined ? _vm.$parent.$parent.stateData.data.free_text.associate_to : false,
            }}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('enhansed')))]),_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                required: false,
                disabled: _vm.$root._route.params.view == 'true' ? true : false,
                type: 'checkbox',
                id: _vm.$parent.$parent.type_item + '_free_text_enhansed',
                value: 1,
                name: _vm.$parent.$parent.type_item + '[free_text][enhansed]',
                selected: _vm.$parent.$parent.stateData.data !== undefined && _vm.$parent.$parent.stateData.data.free_text !== undefined ? _vm.$parent.$parent.stateData.data.free_text.enhansed : false,
            }}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }