import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: '',
    },
    mutations: {
        loadDataClinicOrProfessionalById(state, payload) {
            state.data = payload;
        }
    },
    actions: {
        async getDataClinicOrProfessionalById(state, item){
            var request = item.extra_type + "/" + item.type;
            if(item.id !== undefined){
                request = item.extra_type + "/" + item.type + "/" + item.id;
            }

            await axios.get(
                API_URL + request
            ).then(
                response => {
                    state.commit('loadDataClinicOrProfessionalById', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataClinicOrProfessionalByToken(state){
            await axios.get(
                API_URL + 'professional'
            ).then(
                response => {
                    state.commit('loadDataClinicOrProfessionalById', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataProfessionalBySurgery(state, item){
            await axios.get(
                API_URL + 'surgery/' + item.id + '/professional'
            ).then(
                response => {
                    state.commit('loadDataClinicOrProfessionalById', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};