var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-left":"5px"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datainput":{
                id: 'register_' + _vm.$root._route.params.type + '_privacy_data_forms_accept_conditions',
                value: 1,
                type: 'checkbox',
                name: 'register[' + _vm.$root._route.params.type + '][privacy_data_forms][politic_privacity]',
                style: 'cursor:pointer; margin-right: 10px;',
                selected: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.privacy_data_forms !== undefined ? _vm.DataClinicsProfessionals.data.privacy_data_forms.politic_privacity : false,
                required: true,
                label_required: _vm.$t('politic_privacity_2')
            },"datalabel":{
                style: '',
                class: 'T15 input_label',
                id: 'label_register_' + _vm.$root._route.params.type + '_privacy_data_forms_accept_conditions',
                text: ''
            }}}),_c('i18n',{staticClass:"T15",staticStyle:{"margin-top":"2px"},attrs:{"path":"politic_privacy","tag":"p"},scopedSlots:_vm._u([{key:"terms",fn:function(){return [_c('a',{staticClass:"T15_b link",attrs:{"href":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('terms')))])]},proxy:true}],null,false,784437949)})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datainput":{
                id: 'register_' + _vm.$root._route.params.type + '_privacy_data_forms_accept_conditions',
                value: 1,
                type: 'checkbox',
                name: 'register[' + _vm.$root._route.params.type + '][privacy_data_forms][legal_advise]',
                style: 'cursor:pointer; margin-right: 10px;',
                selected: _vm.$root._route.params.type == 'professional-second-phase' && _vm.DataClinicsProfessionals.data !== undefined && _vm.DataClinicsProfessionals.data.privacy_data_forms !== undefined ? _vm.DataClinicsProfessionals.data.privacy_data_forms.legal_advise : false,
                required: true,
                label_required: _vm.$t('legal_advice_2')
            },"datalabel":{
                style: '',
                class: 'T15 input_label',
                id: 'label_register_' + _vm.$root._route.params.type + '_privacy_data_forms_accept_conditions',
                text: ''
            }}}),_c('i18n',{staticClass:"T15",staticStyle:{"margin-top":"2px"},attrs:{"path":"legal_advice","tag":"p"},scopedSlots:_vm._u([{key:"terms",fn:function(){return [_c('a',{staticClass:"T15_b link",attrs:{"href":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('terms')))])]},proxy:true}],null,false,784437949)})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }