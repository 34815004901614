var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-4px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('configuration') + ' / ' + _vm.$t(_vm.type) + ' ' + _vm.$t(_vm.$root._route.matched[0].props.default.otherTitle)
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'view_category',
                title: _vm.$t('category'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('div',{staticStyle:{"margin-top":"15px"}},[_c('GhTableCommon',{attrs:{"extratable":{
                            id: 'holidays',
                        },"header":!_vm.$root._route.params.view ? _vm.header : _vm.header_view,"data":_vm.ConfigurationSupportHoliday.data.length == 0 ? [] : _vm.ConfigurationSupportHoliday.data},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                                    id: 'delete_holiday_' + itemProp.id,
                                    text: _vm.$t('delete'),
                                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                                    item_prop: itemProp
                                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeTemporaryHoliday', itemProp.id,{'description': itemProp.description}, _vm.$t('delete_holiday'), _vm.$t('preparing_delete_holiday'), 'remove')}}})]}}:null,(!_vm.$root._route.params.view)?{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                        id: 'btn_add_user',
                                        text: _vm.$t('add')
                                    }},on:{"click":function($event){return _vm.Global.openPopUp('add_new_holiday_popup');}}})],1)]},proxy:true}:null],null,true)})],1):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('accept')
                }},on:{"click":function($event){return _vm.submitHoliday()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1),(!_vm.$root._route.params.view)?[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                    id: 'add_new_holiday_popup',
                    title: _vm.$t('new_holiday'),
                    type: 'add',
                    style: 'width: 60%; min-width:300px;',
                },"bodyConfiguration":{
                    id: '',
                    class: '',
                    style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;'
                },"footerConfiguration":{
                    id: '',
                    class: '',
                    style: '',
                    content: ''
                }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{key:_vm.refresh,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{ref:"ref_holiday_description",attrs:{"datalabel":{
                                    text: _vm.$t('description') + '*',
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                 },"datainput":{
                                    id: 'holiday_description',
                                    name: 'holiday[description]',
                                    required: true,
                                    value: ''
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{ref:"ref_holiday_date",attrs:{"datalabel":{
                                    id: 'label_date_holiday',
                                    text: _vm.$t('date'),
                                    style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                },"datainput":{
                                    id:'holiday_date',
                                    name:'holiday[date]',
                                    value: '',
                                    required: true,
                                }}})],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                            id: 'button',
                            text: _vm.$t('save'),
                            class: '',
                            style: ''
                        },"extra-attr":"test"},on:{"click":function($event){_vm.setHoliday(); _vm.Global.closePopUp('add_new_holiday_popup');}}}),_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_close_holiday_popup',
                            text: _vm.$t('close'),
                        }},on:{"click":function($event){return _vm.Global.closePopUp('add_new_holiday_popup');}}})]},proxy:true}],null,false,4111669046)})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }