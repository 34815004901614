<template>
    <div style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('configuration') + ' / ' + $t(type) + ' ' + $t($root._route.matched[0].props.default.otherTitle),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'view_type_equipment',
                    title: $t('equipment_type')
                }">
                    <template v-slot:content>
                        <form id="form_type_equipment" v-if="haveData">
                            <div class="gh-content-global">
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('code') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'department_code',
                                                name: 'department[code]',
                                                style: 'width: 200px',
                                                value: ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.data.code !== undefined ? ConfigurationVariableEquipmentType.data.code : '',
                                                type: 'text',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.code !== undefined ? ConfigurationVariableEquipmentType.code : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'department_name',
                                                name: 'department[name]',
                                                style: 'min-width: 200px; width: 100%',
                                                value: ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.data.name !== undefined ? ConfigurationVariableEquipmentType.data.name : '',
                                                type: 'text',
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.data.name !== undefined ? ConfigurationVariableEquipmentType.data.name : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhTextarea
                                            :datalabel="{
                                                text: $t('description'),
                                                styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'department_description',
                                                name: 'department[description]',
                                                value: ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.data.description !== undefined ? ConfigurationVariableEquipmentType.data.description : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigurationVariableEquipmentType.data !== undefined && ConfigurationVariableEquipmentType.data.description !== undefined ? ConfigurationVariableEquipmentType.data.description : ''}}</label>
                                    </template>
                                </div>
                            </div>
                        </form>
                    </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton v-if="haveData && !$root._route.params.view"
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept'),
                    }"
                    @click="submitTypeEquipment();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close'),
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhButton from "fe-gh-button-lib";

    import {loadLanguageAsync} from "@/main";

    import {mapState} from "vuex";

    export default {
        name: 'view_type_equipment',
        components: {
            GhButton,
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
        },
        computed: {
            ...mapState(['ConfigurationVariableEquipmentType'])
        },
        data(){
            return {
                haveData: false,
                type: '',
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('cleanConfigurationVariableTypeEquipment', '', {root: true});

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataConfigurationVariableTypeEquipment', {id: this.$root._route.params.id}, {root: true});
            }

            this.type = this.$root._route.name.split('configuration_variable_')[1].split('_type_equipment')[0];
            this.haveData = true;
        },
        methods: {
            async submitTypeEquipment(){
                if(this.Global.checkRequired('form_type_equipment') === 'OK'){
                    const formData = new FormData(document.getElementById('form_type_equipment'));
                    await this.$store.dispatch('setDataConfigurationVariableTypeEquipment', {
                        id: this.$root._route.params.id,
                        formData: [...formData],
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            window.opener.UpdatedSelect(response);
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>